import { CREATE_TRIAL } from '../constants/index';

export const initialTrial = {
    trial_title: "",
    description: "",
    therapeutic_area: ["All"],
    indication: [],
    trial_alias: "",
    study_type: [],
    study_phase: [],
    molecule_name: "",
    pediatric_study: null,
    study_country: "",
    scenarios: [],
    primary_endpoints: [],
    secondary_endpoints: [],
    tertiary_endpoints: [],
    similarHistoricalTrials: [],
    members: [],
    studyPhaseList: [],
    studyTypeList: [],
    studyCountryList:[],
    indicationList: [], 
    studyStatusList: [],
    trialRecord:  {}, 
    study_status: [],
    userRolesList: [], 
    indicationTherapeuticMapping: {},
    toggle: true,
    saved_filters: {
        study_phase: [],
        study_type: [],
        indication: [],
        study_status: [],
        study_start_date: [2010, 2025],
        moa: [],
        pediatric: null,
        soa: "",
        toggle: true,
    },
    estimated_enrollment_count: 0,
}

const trialReducer = (state = initialTrial, action) => {
	switch(action.type){	
        case CREATE_TRIAL:
            state = {
                ...state,
                ...action.data
            }
      break
		default:			
    }
    return state
}
export default trialReducer ;