import { useEffect } from 'react'
import { decodeJwt, JWTPayload } from 'jose'
import { refreshTokens as idamRefresh, revokeTokens } from './idam-utils'
import { COGNITO_LOGOUT_URL } from './../constants';
import { applicationLogout } from './ajax-proxy';

// https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
// export interface TDTokens {
//     access_token: string,
//     expires_in: number,
//     id_token: string,
//     refresh_token?: string,
//     token_type: string
// }

/** This code snippet needs to be removed after SSO integration */
export interface TDTokens {
    AccessToken: string,
    ExpiresIn: number,
    IdToken: string,
    RefreshToken?: string,
    TokenType: string
    SSO?: boolean
}

// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
export interface TDIdToken extends JWTPayload {
    'cognito:username'?: string,
    email?: string,
    name?: string,
    given_name?: string,
    family_name?: string
}

export interface TDAccessToken extends JWTPayload {
    scope?: string,
    event_id?: string,
    username?: string
}

const splitUsername = (username): any[] => {
    return username.split(".").map((name) => (name.charAt(0).toUpperCase() + name.slice(1)));
}

export const getUsername = () => {
    let identity = getIdentity();
    if (!identity) return null;

    return identity['cognito:username'] ?? null;
}

export const getEmail = () => {
    let identity = getIdentity();
    if (!identity) return null;

    return identity.email ?? null;
}

export const getFirstName = () => {
    let identity = getIdentity();
    if (!identity) return null;

    if(identity.given_name) return identity.given_name;
    if(identity.name) return identity.name.split(' ')[0];
    if(identity.email) {
        if (identity.email.indexOf("@") === -1) return;
        let username = identity.email.split("@")[0];
        return splitUsername(username)[0];
    }
    return null;
}

export const getFullName = () => {
    let identity = getIdentity();
    if (!identity) return null;

    if(identity.name) return identity.name;
    if(identity.given_name && identity.family_name) return `${identity.given_name} ${identity.family_name}`;
    if(identity.email) {
        if (identity.email.indexOf("@") === -1) return;
        let username = identity.email.split("@")[0];
        return splitUsername(username).join(' ') ?? null;
    }
    return null;
}

export const getIdentity = () => {
    let idToken = getIdToken();
    if(!idToken) return null;

    let claims: TDIdToken = decodeJwt(idToken);
    return claims;
}

export const getAccessTokenExpiration = () => {
    let accessToken = getAccessToken();
    if(!accessToken) return null;

    let claims: TDAccessToken = decodeJwt(accessToken);
    return claims?.exp ?? null;
}

export const getIdToken = () => {
    return getTokens()?.IdToken ?? null;
}

export const getAccessToken = () => {
    return getTokens()?.AccessToken ?? null;
}

export const getRefreshToken = () => {
    return getTokens()?.RefreshToken ?? null;
}

export const getSSO = () => {
    return getTokens()?.SSO ?? null;
}

export const getTokens = () => {
    let tokenObject: TDTokens;
    try {
        tokenObject = JSON.parse(atob(localStorage.getItem('t')));
    } catch (error) {
        return null;
    }
    return tokenObject;
}

export const setTokens = (token) => {
    let tokenObject = btoa(JSON.stringify(token));
    localStorage.setItem('t', tokenObject);
}

export const clearAuth = () => {
    localStorage.removeItem('t');
}

export const isAuthenticated = async (forceTokenRefresh = false) => {
    const accessTokenExpirationTimestamp = getAccessTokenExpiration();
    let is_authenticated = true
    let token_result
    // Check if access token is expired
    if (!accessTokenExpirationTimestamp || accessTokenExpirationTimestamp * 1000 <= Date.now()) {
        // Force Token refresh if requested
        if(forceTokenRefresh) {
            token_result = await refreshTokens();
        }
        is_authenticated = forceTokenRefresh ? token_result : false;
    }

    // Check if there are no available tokens cached
    if (!getTokens()) {
        is_authenticated = false
    }

    return is_authenticated;
}

export const refreshTokens = async () => {
    const refreshToken = getRefreshToken();
    if(!refreshToken) return false;

    let refreshResponse;
    
    refreshResponse = await idamRefresh(refreshToken);
    
    if (refreshResponse.statusCode === 400) {
        console.debug(`Failure to authenticate using refresh token: ${refreshResponse.body}`);
        return false;
    }

    let authResponse;

    let obj = await refreshResponse.json();
    if(obj.status === 401) {
        console.debug('Failure to authenticate using refresh token: No tokens returned in response.')
        return false;
    }
    const altObj = Object.fromEntries(
        Object.entries(obj).map(([key, value]) =>  
            [`${key}`.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_','')}), value]
        )
    )
    altObj['SSO'] = true;
    authResponse = altObj;
    
    const finalTokens: TDTokens = Object.assign(getTokens(), authResponse)
    setTokens(finalTokens);
    return true;
}

/**
 * Inactivity timer hook. Triggers the callback once ms is reached.
 * Timer resets on load, mousedown, click, and keypress window events.
 * 
 * @param callback
 * @param {number} ms
 */
export const useInactivityTimer = (callback, ms: number) => {
    useEffect(()=> {
        let timerId;
        const events = [
            'load',
            'mousedown',
            'click',
            'keypress'
        ];

        const resetTimer = async () => {
            if(timerId !== undefined) {
                clearTimeout(timerId);
                timerId = null;
            }

            const authenticated = await isAuthenticated();
            if(authenticated) {
                timerId = window.setTimeout(callback, ms);
            }
        }

        for (let eventIdx in events) {
            window.addEventListener(events[eventIdx], resetTimer);
        }
        
        resetTimer();

        return () => {
            for (let eventIdx in events) {
                window.removeEventListener(events[eventIdx], resetTimer);
            }
        }
    },[callback, ms]);
}

export const logout = async (history) => {
    const refreshToken = getRefreshToken();

    // Token revocation of identity and refresh tokens are done in separate try/catch blocks.
    // If one fails we want to minimize any issues by revoking as much as we can.
    try {
        await applicationLogout();
    } catch (error) {}

    try {
        await revokeTokens(refreshToken);
    } catch (error) {}

    clearAuth();
    localStorage.setItem('processStep', JSON.stringify(0));
    localStorage.setItem('activeTabKey', JSON.stringify('1'));
    window.location.replace(COGNITO_LOGOUT_URL);    
}

export const getAdminRole = () => {
    let identity = getIdentity();
    if (!identity) return null;

    const identityJSON = Object.keys(identity).reduce((acc, key) => {
        acc[`"${key}"`] = identity[key];
        return acc;
      }, {});

    const groups = identityJSON["\"cognito:groups\""]
    const isAdmin = groups && groups.length > 0 && groups.includes("admin");
    return !!isAdmin;
}

export function checkValues(item) {
    return item.user_value_free_text !== "" && item.user_value_free_text !== "-" && item.user_value_free_text !== undefined && item.user_value_free_text !== null
  }

export function formatValue(item){    
    var tempStr
    if(checkValues(item)) {
    tempStr = item.user_value_free_text
    } else if (item.user_lower == 0 && item.user_upper != 0) {
        tempStr = "< "+ Number(item.user_upper?.toString().match(/^\d+(?:\.\d{0,2})?/))+ " " + item.user_units
    } else if ((item.user_lower && item.user_lower !== 0) && (item.user_upper == 0 || item.user_upper == null)) {        
        tempStr = "> "+ Number(item.user_lower?.toString().match(/^\d+(?:\.\d{0,2})?/))+ " " + item.user_units
    } else if (item.user_lower && item.user_lower != 0 && item.user_upper != 0) {
        tempStr = Number(item.user_lower.toString().match(/^\d+(?:\.\d{0,2})?/))+ " - " + Number(item.user_upper?.toString().match(/^\d+(?:\.\d{0,2})?/)) + " " + item.user_units
    } else if(item.user_value !== ""&&item.user_value !== null &&item.user_value !== undefined && !checkValues(item)){
    tempStr = item.user_value
    }  else  if(item.user_value !== ""&&item.user_value !== null &&item.user_value !== undefined&& item.user_lower == 0 && item.user_upper == 0 && item.user_units !== "") {
        tempStr =  item.user_value + " " + item.user_units    
    } else {
    tempStr = '-'
    }
    return tempStr
}

export function checkHistoryValues(item) {
    return item.historical_value_free_text !== "" && item.historical_value_free_text !== "-" && item.historical_value_free_text !== undefined && item.historical_value_free_text !== null
}

export function formatHistoryValue(item){
    // this method is called during load and edit of IE builder
    if('historical_value_free_text' in item && item['historical_value_free_text']!==null&&item['historical_value_free_text']!==undefined){
    return item['historical_value_free_text']
    }
    var tempStr
    if (item.historical_lower == 0 && item.historical_upper != 0) {
        tempStr = "< "+ Number(item.historical_upper?.toString().match(/^\d+(?:\.\d{0,2})?/))+ " " + item.historical_units
    } else if ((item.historical_lower && item.historical_lower != 0) && (item.historical_upper == 0 || item.historical_upper == null)) {
        tempStr = "> "+ Number(item.historical_lower?.toString().match(/^\d+(?:\.\d{0,2})?/))+ " " + item.historical_units
    } else if ((item.historical_lower && item.historical_lower != 0) && item.historical_upper != 0) {
        tempStr = Number(item.historical_lower.toString().match(/^\d+(?:\.\d{0,2})?/))+ " - " + Number(item.historical_upper?.toString().match(/^\d+(?:\.\d{0,2})?/)) + " " + item.historical_units
    } else if(item.historical_value !== ""&&item.historical_value !== null && item.historical_value !== undefined && !checkHistoryValues(item)){
        tempStr = item.historical_value
    }  else  if(item.historical_value !== ""&&item.historical_value !== null && item.historical_value !== undefined && item.historical_lower == 0 && item.user_upper == 0 && item.historical_units!== "") {
        tempStr = item.historical_value + " " + item.historical_units   
    } else {
        tempStr = '-'
    }
    return tempStr
}

export function formatTime(item){
    var tempStr
    if(item.user_timeframe && item.user_timeframe.trim() != ""){
    tempStr = item.user_timeframe
    } else {
    tempStr = '-'
    }
    return tempStr
}

export function formatHistoryTime(item){
    var tempStr
    if(item.historical_timeframe && item.historical_timeframe.trim() != ""){
    tempStr = item.historical_timeframe
    } else {
    tempStr = '-'
    }
    return tempStr
}