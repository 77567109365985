/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Input,
  Select,
  InputNumber
} from "antd";
import './index.scss';

const { TextArea } = Input;
const { Option } = Select;

const TrialSummary = (props) => {
  const { newTrial, handleNewTrialInputChange, handleNewTrialSelectChange, handleValidation, updateDetail, indicationTherapeuticMap} = props
  const [indicationList, setIndicationList] = useState([])
  const [hasTemplateScenario, setHasTemplateScenario] = useState(false);
  useEffect(() => {
    let therapeuticAreaVal = newTrial["therapeutic_area"]
    if (therapeuticAreaVal.length == 1 && therapeuticAreaVal[0]=="All") {
      setIndicationList(sortIndication(props.indicationList))
    } else {
      let indications = []
      therapeuticAreaVal.forEach(ta => indications = indicationTherapeuticMap[ta] != undefined ? indications.concat(indicationTherapeuticMap[ta]) : indications)
      setIndicationList(sortIndication(indications))
    }

    newTrial.scenarios?.forEach((scenario) => {
      if (scenario.is_template) {
        setHasTemplateScenario(true);
      }
    });
  }, [props])
  const sortIndication = (indicationList) => {
    return indicationList&&indicationList.sort((ind1, ind2) => (ind1.label < ind2.label) ? -1 : (ind1.label > ind2.label) ? 1 : 0);
  }

  return (
    <div className="trial-summary-container">
      <div className="trials-basic-info">
        <div className="trial-item">
          <label htmlFor="">Trial Title  <span className="required">*</span> </label>
          <Input
            style={{ width: "100%", height: 30 }}
            onBlur={(v) => handleValidation("trial_title", v)}
            onChange={(v) => handleNewTrialInputChange("trial_title", v)}
            value={newTrial["trial_title"]}
          />
          <span className="trial-summary-error">{props.trialTitleError}</span>
          <br/>
        </div>
        <div className="trial-item">
          <label htmlFor="">Description</label>
          <TextArea
            onChange={(v) => handleNewTrialInputChange("description", v)}
            value={newTrial["description"]}
            onBlur={(v) => handleValidation("description", v)}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
            <br/>
            <span className="trial-summary-error">{props.descriptionError}</span>
        </div>
        <div className="parallel-item ">
          <div className="trial-item">
            <label>Therapeutic Area  <span className="required">*</span> </label>
            <Select
              mode="multiple"
              value={newTrial["therapeutic_area"]}
              virtual={false}
              showSearch
              style={{ width: '450px'}}
              onBlur={(v) => handleValidation("therapeutic_area", v)}
              onChange={(v) =>
                handleNewTrialSelectChange("therapeutic_area", v)
              }
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.therapeuticAreaList.map((o) => {
                return (
                  <Option value={o} key={o}>
                    {o}
                  </Option>
                );
              })}
            </Select>
            <br/>
            <span className="trial-summary-error">{props.therapeuticAreaError}</span>
          </div>
          <div className="trial-item">
            <label htmlFor="">Indication  <span className="required">*</span> </label>
            <Select
              mode="multiple"
              allowClear
              showSearch
              value={newTrial["indication"]}
              style={{ width: '450px' }}
              virtual={false}
              disabled={localStorage.getItem('user_type').toUpperCase() === 'ADMIN' || !updateDetail || (newTrial?.trial_user_role === "Edit" && !hasTemplateScenario) ? false : true}
              onBlur={(v) => handleValidation("indication", v)}
              onChange={(v) => handleNewTrialSelectChange("indication", v)}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {indicationList.map((t) => {
                return (
                  <Option value={t.value} key={t.value}>
                    {t.label}
                  </Option>
                );
              })}
            </Select>
            <br/>
            {newTrial.scenarios.find(s=> s.is_template) && (
              <div className="indication-warning">
                <span className="asterisk">*</span>
                This trial has a scenario marked as template. Changing trial properties will have downstream effects!
              </div>
            )}
            <span className="trial-summary-error">{props.indicationError}</span>
          </div>
        </div>
        <div className="parallel-item">
          <div className="trial-item">
            <label htmlFor="">Trial Alias  <span className="required">*</span> </label>
            <Input
              style={{ width: '450px', height: 30 }}
              onBlur={(v) => handleValidation("trial_alias", v)}
              onChange={(e) => handleNewTrialInputChange("trial_alias", e)}
              value={newTrial["trial_alias"]}
              disabled={ !updateDetail ? false : true}
            />
            <br/>
            <span className="trial-summary-error">{props.trialAliasError}</span>
          </div>
          <div className="trial-item">
            <label htmlFor="">Study Type</label>
            <Select
              mode="multiple"
              allowClear
              showSearch
              value={newTrial["study_type"]}
              style={{ width: '450px' }}
              virtual={false}
              onChange={(v) => handleNewTrialSelectChange("study_type", v)}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.studyTypeList.map((t) => {
                return (
                  <Option value={t.value} key={t.value}>
                    {t.label}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="parallel-item">
          <div className="trial-item">
            <label htmlFor="">Molecule Name</label>
            <Input
              style={{ width: '450px', height: 30 }}
              onBlur={(v) => handleValidation("molecule_name", v)}
              onChange={(v) =>
                handleNewTrialInputChange("molecule_name", v)
              }
              value={newTrial["molecule_name"]}
            />
            <br/>
            <span className="trial-summary-error">{props.moleculeNameError}</span>
          </div>
          <div className="trial-item">
            <label htmlFor="">Study Phase</label>
            <Select
              mode="multiple"
              allowClear
              showSearch
              value={newTrial["study_phase"]}
              style={{ width: '450px' }}
              virtual={false}
              // dropdownMatchSelectWidth={false}
              onChange={(v) => handleNewTrialSelectChange("study_phase", v)}
              filterOption={(input, option) =>
                option.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {props.studyPhaseList.map((o) => {
                return (
                  <Option value={o.value} key={o.value}>
                    {o.label}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div className="parallel-item">
          <div className="trial-item">
            <label htmlFor="">Pediatric Study</label>
            <Select
                value={newTrial["pediatric_study"] === null ? "null" : newTrial["pediatric_study"].toString()}
                style={{ width: '450px' }}
                onChange={(v) =>
                handleNewTrialSelectChange("pediatric_study", v)
                }
            >
              <Option value="null">All</Option>
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </div>
          <div className="trial-item">
            <label htmlFor="">Estimated Enrollment  <span className="required">*</span> </label>
            <InputNumber
              step={1}
              style={{ width: 450, height: 30 }}
              onBlur={(v) => handleValidation("estimated_enrollment_count", v)}
              onChange={(e) => handleNewTrialInputChange("estimated_enrollment_count", e)}
              value={newTrial["estimated_enrollment_count"]}
            />
            <br/>
            <span className="trial-summary-error">{props.estimatedTotalError}</span>
          </div>
          {/* <div className="trial-item">
            <label htmlFor="">Study Country</label>
            <Select
              defaultValue="All"
              value={newTrial["study_country"]}
              style={{ width: 250 }}
              onChange={(v) =>
                handleNewTrialSelectChange("study_country", v)
              }
            >
              {props.studyCountryList.sort().map((o) => {
                return (
                  <Option value={o.value} key={o.value}>
                    {o.label}
                  </Option>
                );
              })}
            </Select>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default TrialSummary;