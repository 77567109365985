/* eslint-disable */
import {CLINICAL_TRIALS_GOV_URL} from './../../constants'
import React from 'react';
import { Table } from 'antd';
import "./index.scss";

const getClinicalTrialsGovURL = (nctID) => {
    const regexPattern = /^NCT\d{8}$/;
    if(regexPattern.test(nctID)){
        return CLINICAL_TRIALS_GOV_URL+nctID
    }
  };

const columns = [
  // {
  //   title: "NCT ID",
  //   dataIndex: "nct_id",
  //   ellipsis: true,
  // },
  {
    title: 'Study ID',
    dataIndex: 'nct_id',
    ellipsis: true,
    // width: 450,
    sorter: (a, b) => (a.nct_id < b.nct_id ? -1 : 1),
    render: nctId => <a href={getClinicalTrialsGovURL(nctId)} target="_blank">{nctId}</a>,
    width: 140,
  },
{
    title: "Sponsor",
    dataIndex: "sponsor",
    ellipsis: true,
    sorter: (a, b) => (a.sponsor < b.sponsor ? -1 : 1),
  },
  {
    title: "Indication",
    dataIndex: "indication",
    // sorter: (a, b) => (a.indication < b.indication ? -1 : 1),
    ellipsis: true,
    render: indication => indication && indication.join("; "),
  },
  {
    title: "Phase",
    dataIndex: "study_phase",
    sorter: (a, b) => (a.study_phase < b.study_phase ? -1 : 1),
    ellipsis: true,
  },
  {
    title: "MOA",
    dataIndex: "moa_data",
    sorter: (a, b) => (a.moa_data < b.moa_data ? -1 : 1),
    ellipsis: true,
    render: moa_data => moa_data && moa_data.join("; "),
  },
  {
    title: 'Status',
    dataIndex: "study_status",
    sorter: (a, b) => (a.study_status < b.study_status ? -1 : 1),
    ellipsis: true,
  },
  {
    title: "SoA",
    dataIndex: "soa_status_flag",
    sorter: (a, b) => (a.soa_status_flag < b.soa_status_flag ? -1 : 1),
    ellipsis: true,
    width: 70,
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    sorter: (a, b) => (a.start_date < b.start_date ? -1 : 1),
    ellipsis: true,
    },
  {
    title: "Pediatric",
    dataIndex: "pediatric",
    sorter: (a, b) => (a.pediatric < b.pediatric ? -1 : 1),
    ellipsis: true,
    width: 80,
  },
];

export interface SelectableTableProps {
  dataList: any;
}
 
class SelectableTable extends React.Component <SelectableTableProps>{
  
    constructor(props : SelectableTableProps){
        super(props);
        console.log(props.dataList)
    }

    state = {
      data: [],
      total: 0,
      current: 1,
      pageSize: 5,
      pageSizeOptions: ['5', '10', '20', '50', '100']
    };

    componentWillReceiveProps(props) {
      this.setState({
        data: props.dataList.map((item) => ({
          ...item,
          pediatric: item.pediatric ? "YES": "NO"
        })),
        total: props.dataList.length
      })
    }

    changePage = (page) => {
      this.setState({
        current: page
      })
    }

    onShowSizeChange = (current, pageSize) => {
      this.setState({
        current: current,
        pageSize: pageSize
      })
    }

  render() {
    return (
      <div className="view-historical-trial-list">
        <Table
        columns={columns} 
        dataSource={this.state.data} 
        pagination={{ 
          position: ['bottomRight'],
          pageSize: this.state.pageSize, 
          onChange: this.changePage,
          current: this.state.current,
          total: this.state.total,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          showSizeChanger: true, 
          onShowSizeChange: this.onShowSizeChange,
          pageSizeOptions: this.state.pageSizeOptions
        }} 
        // sticky 
        rowKey={record => record['nct_id']} />
      </div>
    );
  }
}

export default SelectableTable;