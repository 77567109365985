/* eslint-disable */
import React, { useState, useReducer, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";
import {Table, Collapse, Slider, Dropdown,Menu, Modal, Row, Col, InputNumber, Tooltip, Button, Spin, message, Input, Badge} from "antd";
import {ArrowRightOutlined, CloseOutlined, EditFilled, MinusOutlined, PlusOutlined, DownOutlined, DownloadOutlined, HistoryOutlined, FileTextFilled, RightOutlined, LeftOutlined, SearchOutlined, CheckOutlined, CarryOutFilled, DeleteOutlined} from "@ant-design/icons";
import {getStandardEvents, update_patient_burden} from "../../utils/ajax-proxy";
import ReactECharts from 'echarts-for-react';
import "./index.scss";
import EventList from '../EventList';
import FileSaver from 'file-saver';
import CriteriaOption from "../../components/CriteriaOption";
import SelectableTable from "../../components/SelectableTable";
import { getEmail } from "../../utils/auth";

import telehealth_icon from '../../assets/fax.png';
import patient_at_home_icon from '../../assets/home.png';
import hospital_icon from '../../assets/hospital.png';
import mobile_health_icon from '../../assets/mobile.png';
import pharmacy_icon from '../../assets/medical.png';
import local_lab_icon from '../../assets/local.png';
import ip_direct_icon from '../../assets/ipdirect.png';
import e_consent_icon from '../../assets/econsent.png';

const { Panel } = Collapse;

const iChartColors = ['#DADADA', '#DADADA', '#DADADA', '#DADADA', '#DADADA']
const aChartColors = ['#d04a02', '#ed7738', '#ed9d72', '#f5b795', '#f5ddcf']
const burdenColors = {active: '#E53500', inactive: '#DADADA'}
const aLabelColors = {GOOD: '#00A947', FAIR: '#0084A9', POOR: '#c33232'}
const iLabelColors = {GOOD: burdenColors.inactive, FAIR: burdenColors.inactive, POOR: burdenColors.inactive}

const CATEGORY_LABS = 'Labs';
const CATEGORY_PHYSICAL_EXAMINATION = 'Physical Examination';
const CATEGORY_PROCEDURES = 'Procedures';
const CATEGORY_QUESTIONNAIRES = 'Questionnaires';
const CATEGORY_STUDY_PROCEDURES = 'Study Procedures';

// Sets default min and max frequency for Criteria Frequency slider
const FREQUENCY_DEFAULT_MIN = 20
const FREQUENCY_DEFAULT_MAX = 100

// Sets min and max ranges for Criteria Frequency slider
const FREQUENCY_RANGE_MIN = 0
const FREQUENCY_RANGE_MAX = 100

const defaultCostValue = [
  {value: 0, name: CATEGORY_LABS},
  {value: 0, name: CATEGORY_PHYSICAL_EXAMINATION},
  {value: 0, name: CATEGORY_PROCEDURES},
  {value: 0, name: CATEGORY_QUESTIONNAIRES},
  {value: 0, name: CATEGORY_STUDY_PROCEDURES}
]

const defaultBurdenValue = [0, 0, 0, 0, 0, 0, 0, 0, 0]

const initialNumber = {
  visitNumber: 0,
  weekNumber: 0
}

const initEventCriteria = {
  'TotalCost': '0',
  'CostRate': '',
  'CostData': [],
  'BurdenData': [],
  'BurdenXAxis': [],
  'Finished': false
}

const visitDimensionalScore = [
  {Dimension: 'AnxietyInducing', Value: 5},
  {Dimension: 'HospitalDependent', Value: 25},
  {Dimension: 'PhysicallyInvasive', Value: 10},
  {Dimension: 'BloodDraw', Value: 15},
  {Dimension: 'Sedation', Value: 35},
  {Dimension: 'Injection', Value: 15},
  {Dimension: 'Urine', Value: 5},
  {Dimension: 'RequireFasting', Value: 7},
  {Dimension: 'LongerThanTwoHours', Value: 20},
  {Dimension: 'Questionnaire', Value: 5}
]

const ScheduleEvents = (props) => { 
  const endpoints = {
    "Primary Endpoints": props.newSoa.primary_endpoints,
    "Secondary Endpoints": props.newSoa.secondary_endpoints,
    "Tertiary Endpoints": props.newSoa.tertiary_endpoints,
  };
  const scenario = props.newSoa.scenarios && props.newSoa.scenarios.find(s=> s['scenario_id'] == props.scenarioId);  
  const eventsConfigure = scenario&&scenario['Schedule of Activities']
  const [hiddeTags, setHiddeTags] = useState(true)
  const [showConfigure, setShowConfigure] = useState(false)
  const [eventLib, setEventLib] = useState(6)
  const [eventPanel, setEventPanel] = useState("1")
  const [dismissEvent, setDismissEvent] = useState([])
  const [insightEvent, setInsightEvent] = useState(scenario&&scenario['soa_insights']? scenario['soa_insights']:[])
  const [activeCollapse, setActiveCollapse] = useState(['-1'])
  const [numbers, setNumbers] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { 
      visitNumber: eventsConfigure?.Visits || 0,
      ScreeningVisits: eventsConfigure?.ScreeningVisits || 0,
      TreatmentVisits: eventsConfigure?.TreatmentVisits || 0,
      FollowupVisits: eventsConfigure?.FollowupVisits || 0,
      weekNumber: eventsConfigure&&eventsConfigure.Weeks?eventsConfigure.Weeks[eventsConfigure.Weeks?.length-1]: 0
     }
  );

  const [visitNoteList, setVisitNoteList] = useState(eventsConfigure?.visitNoteList || []);

  const [editNumbers, setEditNumbers] = useState({
    screeningVisitsNumber: eventsConfigure?.ScreeningVisits || 0,
    treatmentVisitsNumber:  eventsConfigure?.TreatmentVisits || 0,
    followupVisitsNumber: eventsConfigure?.FollowupVisits || 0,
    weekNumber:eventsConfigure?.Weeks?eventsConfigure.Weeks[eventsConfigure.Weeks?.length-1]: 0
  });
  const [weeks, setWeeks] = useState([])
  const [visits, setVisits] = useState([])
  // SOA event frequency
  const [minV, setMinV] = useState(FREQUENCY_DEFAULT_MIN)
  const [maxV, setMaxV] = useState(FREQUENCY_DEFAULT_MAX)
  const [visibleSlider, setVisibleSlider] = useState(false)
  const [eventCriteria, setEventCriteria] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...initEventCriteria }
  );

  const [prevCategoryVisists, setPreviousCategoryVisits] = useState({});

  useEffect(() => {
    if(!eventsConfigure?.VisitTypes) return;
    setPreviousCategoryVisits({
      ScreeningVisits: eventsConfigure?.VisitTypes?.ScreeningVisits || 0,
      TreatmentVisits:  eventsConfigure?.VisitTypes?.TreatmentVisits || 0,
      FollowupVisits: eventsConfigure?.VisitTypes?.FollowupVisits || 0,
      weekNumber:eventsConfigure.Weeks?eventsConfigure.Weeks[eventsConfigure.Weeks?.length-1]: 0
    })
  }, [eventsConfigure?.VisitTypes])

  //Cost Per Patient Chart
  const [patientChartColor, setPatientChartColor] = useState(iChartColors)
  const [burdenChartColor, setBurdenChartColor] = useState(burdenColors.inactive)
  const [labelColors, setLabelColors] = useState(iLabelColors)
  const [costData, setCostData] = useState(defaultCostValue)
  const [costSubTitle, setCostSubTitle] = useState('')
  const [showPatientLabel, setShowPatientLabel] = useState(false)
  const [patientRate, setPatientRate] = useState('$0K\nGOOD')

  //Patient Burden Chart
  const [burdenData, setBurdenData] = useState(defaultBurdenValue)
  const [burdenSubTitle, setBurdenSubTitle] = useState('')
  const [burdenXAxis, setBurdenXAxis] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9'])
  const [showTooltip, setShowTooltip] = useState(false)
  const [submitType, setSubmitType] = useState(0)
  const [totalBurden, setTotalBurden] = useState(0)
  const [totalBurdenStatus, setTotalBurdenStatus] = useState('{GOOD|GOOD}')

  const [numberOfVisitsError, setNumberOfVisitsError] = useState("") 
  const [numberOfScreeningVisitsError, setNumberOfScreeningVisitsError] = useState("")
  const [numberOfTreatmentVisitsError, setNumberOfTreatmentVisitsError] = useState("")
  const [numberOfFollowupVisitsError, setNumberOfFollowupVisitsError] = useState("")
  const [numberOfWeeksError, setNumberOfWeeksError] = useState("")

  const [modalityOptions, setModalityOptions] = useState([])
  const [soeLibrarayPanelStatus, setSOELibraryPanelStatus] = useState(true)
  const soeInsightsToggleHandler = (status) => {
    setSOELibraryPanelStatus(status);
    setEventLib(!status ? 0 : 6)
  }

    useEffect(() => {
    }, [numberOfVisitsError, numberOfWeeksError])

  //Activities by Modality chart
  const[modalityChartData,setModalityChartData] = useState([])
  const modalityOption = {
    title:{
      show:false,
      text: 'Activities by Modality',
      x:'center',
      y:'top',
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333'
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      },
      confine:false,
      position: ['100%', '-10%']
    },
    grid: {
      left: '10%',
      right: '4%',
      top: '15%',
      bottom: '6%',
      containLabel: true
  },
    xAxis: {
      name: 'Visit Number',
      nameLocation: "middle",
      type: 'category',
      data: visits,
      nameRotate: 0, nameGap: 20,
   },
   yAxis: {
      type: 'value'
   },
    series:modalityChartData
  };

  const modality_options_dict ={
      "eSource & Wearables": {
        "icon": mobile_health_icon,
        "color": '#FFBF00'
      },
      "Telemedicine": {
        "icon": telehealth_icon,
        "color": '#75C716'
      },
      "Home Health": {
        "icon": patient_at_home_icon,
        "color": '#C9DF00'
      },
      "Local Lab & Sample Collection": {
        "icon": local_lab_icon,
        "color": '#6A86FA'
      },
      "Local Clinic": {
        "icon": pharmacy_icon,
        "color": '#FF025F'
      },
      "IP Direct Shipment": {
        "icon": ip_direct_icon,
        "color": '#83B1FA'
      },
      "eConsent": {
        "icon": e_consent_icon,
        "color": '#00A760'
      },
      "Traditional Site": {
        "icon": hospital_icon,
        "color":'#CB0047'
      },
      "": {
        "icon":'',
        "color":'white'
      }
    }

  //Event Libs
  //Original data from backend
  const [orgLabs, setOrgLabs] = useState([])
  const [orgExamination, setOrgExamination] = useState([])
  const [orgProcedures, setOrgProcedures] = useState([])
  const [orgQuestionnaires, setOrgQuestionnaires] = useState([])
  const [orgStudyProcedures, setOrgStudyProcedures] = useState([])

  //Filtered data based on the frequency and original data
  let [filteredLabs, setFilteredLabs] = useState([])
  let [filteredExamination, setFilteredExamination] = useState([])
  let [filteredQuestionnaires, setFilteredQuestionnaires] = useState([])
  let [filteredProcedures, setFilteredProcedures] = useState([])
  let [filteredStudyProcedures, setFilteredStudyProcedures] = useState([])

  //Addedd data
  let [addedLabs, setAddedLabs] = useState([])
  let [addedExamination, setAddedExamination] = useState([])
  let [addedQuestionnaires, setAddedQuestionnaires] = useState([])
  let [addedProcedures, setAddedProcedures] = useState([])
  let [addedStudyProcedures, setAddedStudyProcedures] = useState([])
  const [resetWeeks, setResetWeeks] = useState(true)

  const onStepVisit = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
    setNumbers({
      ['visitNumber']: value
    });
  }

  const onStepScreeningVisit = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
    setNumbers({
      ['ScreeningVisits']: value
    });
  }

  const onStepTreatmentVisit = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
    setNumbers({
      ['TreatmentVisits']: value
    });
  }

  const onStepFollowUpVisit = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
    setNumbers({
      ['FollowupVisits']: value
    });
  }

  const onStepWeek = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
    setNumbers({
      ['weekNumber']: value
    });
  }

  const validateVisit = (rawValue) => {
    let value = parseInt(rawValue)
    let errorMsg = ""
    if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Visits is required"}
    else if(value>100 || value<1){errorMsg = "Number of Visits should be between 1 to 100"}
    setNumberOfVisitsError(errorMsg)
    setNumbers({
      ['visitNumber']: value
    });
    return errorMsg == ""
  }

  const validateScreeningVisit = (rawValue) => {
    let value = parseInt(rawValue)
    let errorMsg = ""
    if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Treatment Visits is required"}
    else if(value>100 || value<1){errorMsg = "Number of Screening Visits should be between 1 to 100"}
    setNumberOfScreeningVisitsError(errorMsg)
    setNumbers({
      ['ScreeningVisits']: value
    });
    return errorMsg == ""
  }

  const validateTreatmentVisit = (rawValue) => {
    let value = parseInt(rawValue)
    let errorMsg = ""
    if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Screening Visits is required"}
    else if(value>100 || value<1){errorMsg = "Number of Treatment Visits should be between 1 to 100"}
    setNumberOfTreatmentVisitsError(errorMsg)
    setNumbers({
      ['TreatmentVisits']: value
    });
    return errorMsg == ""
  }

  const validateFollowUpVisit = (rawValue) => {
    let value = parseInt(rawValue)
    let errorMsg = ""
    if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Follow up Visits is required"}
    else if(value>100 || value<1){errorMsg = "Number of Follow-Up Visits should be between 1 to 100"}
    setNumberOfFollowupVisitsError(errorMsg)
    setNumbers({
      ['FollowupVisits']: value
    });
    return errorMsg == ""
  }

  const validateWeek = (rawValue) => {
    let value = parseInt(rawValue)
    let errorMsg = ""
    if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Weeks is required"}
    else if(value>1000 || value<1){errorMsg = "Number of Weeks should be between 1 to 1000"}
    setNumberOfWeeksError(errorMsg)
    setNumbers({
      ['weekNumber']: value
    });
    return errorMsg == ""
  }

  const handleVisitNoteList = (Obj) => {
    setVisitNoteList(Obj)
    let tempVisitNoteList = visitNoteList ? visitNoteList : [];
    let newData = [...tempVisitNoteList]
    if( Obj.visit_notes.trim().length == 0) {
      newData = tempVisitNoteList.filter((value, index) => Obj.visit !== value.visit);
      setVisitNoteList(newData);
      return;
    }
    const index = tempVisitNoteList.findIndex((value, index) => Obj.visit == value.visit);
    if(index > -1) {
      newData.splice(index, 1, Obj)
      setVisitNoteList(newData)
    } else {
      newData.push(Obj)
    }
    setVisitNoteList(newData);
  }
  

  const sumOfArr = (arr) => {
    if(Array.isArray(arr)){
       let  arrSum = 0
       arr.forEach((item,index) => {
        arrSum+=item
       })
       return arrSum
    }
}

  const isEventsAdded = (events) => {
    return (
    (events[CATEGORY_LABS]!=undefined && events[CATEGORY_LABS].entities.length > 0)
    || (events[CATEGORY_PHYSICAL_EXAMINATION]!=undefined && events[CATEGORY_PHYSICAL_EXAMINATION].entities.length > 0)
    || (events[CATEGORY_PROCEDURES]!=undefined && events[CATEGORY_PROCEDURES].entities.length > 0)
    || (events[CATEGORY_QUESTIONNAIRES]!=undefined && events[CATEGORY_QUESTIONNAIRES].entities.length > 0)
    || (events[CATEGORY_STUDY_PROCEDURES]!=undefined && events[CATEGORY_STUDY_PROCEDURES].entities.length > 0)
    )
  }

  const getCostData = (costData) => {
    if(costData!=undefined && costData.length < 1){
        return [
				{
					"name": CATEGORY_STUDY_PROCEDURES,
					"value": 0
				},
				{
					"name": CATEGORY_PROCEDURES,
					"value": 0
				},
				{
					"name": CATEGORY_QUESTIONNAIRES,
					"value": 0
				},
				{
					"name": CATEGORY_PHYSICAL_EXAMINATION,
					"value": 0
				},
				{
					"name": CATEGORY_LABS,
					"value": 0
				}
			]
    }else{
        return costData
    }
  }

    const getModalityOptions = (modalityOpts) =>{
        let finalModalityOptions = []
        if(modalityOpts != null && modalityOpts != undefined){
            modalityOpts.forEach((modality) => {
                if(modality.name in modality_options_dict){
                  finalModalityOptions.push({
                        key: modality.key,
                        name:  modality.name,
                        icon: modality_options_dict[modality.name].icon,
                        color: modality_options_dict[modality.name].color
                  })
                }else{
                  finalModalityOptions.push({
                        key: modality.key,
                        name:  modality.name,
                        icon: '',
                        color: 'white'
                  })
                }
            });
            finalModalityOptions.push({
                key:"",
                name:  "",
                icon:'',
                color:'white'
            })
            return finalModalityOptions;
        }else{
            return []
        }
    }

  function excluCallback(key) {
  }

  useEffect(() => {
    if(props.submitType != 0){
      setSubmitType(props.submitType)
    }
  },[props.submitType]);

  useEffect(()=>{
    if(eventsConfigure?.ScreeningVisits && eventsConfigure?.TreatmentVisits && eventsConfigure?.FollowupVisits && eventsConfigure?.Weeks){
      setNumbers({
        ScreeningVisits: eventsConfigure.ScreeningVisits,
        TreatmentVisits: eventsConfigure.TreatmentVisits,
        FollowupVisits: eventsConfigure.FollowupVisits,
        weekNumber:eventsConfigure.Weeks[eventsConfigure.Weeks.length-1]
      })
      setEditNumbers({
        screeningVisitsNumber: eventsConfigure.ScreeningVisits,
        treatmentVisitsNumber: eventsConfigure.TreatmentVisits,
        followupVisitsNumber: eventsConfigure.FollowupVisits,
        weekNumber:eventsConfigure.Weeks[eventsConfigure.Weeks.length-1]
      })
      const tmpSoAConfig = {
        [CATEGORY_LABS]:eventsConfigure[CATEGORY_LABS].entities,
        [CATEGORY_PHYSICAL_EXAMINATION]:eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION].entities,
        [CATEGORY_PROCEDURES]:eventsConfigure[CATEGORY_PROCEDURES].entities,
        [CATEGORY_QUESTIONNAIRES]:eventsConfigure[CATEGORY_QUESTIONNAIRES].entities,
        [CATEGORY_STUDY_PROCEDURES]:eventsConfigure[CATEGORY_STUDY_PROCEDURES].entities
      }
    }
  },[eventsConfigure?.ScreeningVisits, eventsConfigure?.TreatmentVisits, eventsConfigure?.FollowupVisits, eventsConfigure?.Weeks])

  useEffect(()=>{
    if(eventsConfigure?.VisitTypes?.ScreeningVisits && eventsConfigure?.VisitTypes?.TreatmentVisits && eventsConfigure?.VisitTypes?.FollowupVisits && eventsConfigure.WeekNumber){
      setNumbers({
        ScreeningVisits: eventsConfigure?.VisitTypes.ScreeningVisits,
        TreatmentVisits: eventsConfigure?.VisitTypes.TreatmentVisits,
        FollowupVisits: eventsConfigure?.VisitTypes.FollowupVisits,
        weekNumber:eventsConfigure?.Weeks[eventsConfigure.Weeks.length-1]
      })
      setEditNumbers({
        screeningVisitsNumber: eventsConfigure?.VisitTypes.ScreeningVisits,
        treatmentVisitsNumber: eventsConfigure?.VisitTypes.TreatmentVisits,
        followupVisitsNumber: eventsConfigure?.VisitTypes.FollowupVisits,
        weekNumber:eventsConfigure?.Weeks[eventsConfigure.Weeks.length-1]
      })
      const tmpSoAConfig = {
        [CATEGORY_LABS]:eventsConfigure[CATEGORY_LABS].entities,
        [CATEGORY_PHYSICAL_EXAMINATION]:eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION].entities,
        [CATEGORY_PROCEDURES]:eventsConfigure[CATEGORY_PROCEDURES].entities,
        [CATEGORY_QUESTIONNAIRES]:eventsConfigure[CATEGORY_QUESTIONNAIRES].entities,
        [CATEGORY_STUDY_PROCEDURES]:eventsConfigure[CATEGORY_STUDY_PROCEDURES].entities
      }
    }
  },[eventsConfigure?.VisitTypes])


  useEffect(() => {
    if(eventsConfigure?.visitNotesList) {
      setVisitNoteList(eventsConfigure.visitNotesList)
    }
  }, [eventsConfigure?.visitNotesList])


  useEffect(() => {
    //Verify if this is the first time to set Events
    if(props.newSoa.scenarios){
     const scenario = props.newSoa.scenarios && props.newSoa.scenarios.find(s=> s['scenario_id'] == props.scenarioId);
    const eventsConfigure = scenario&&scenario['Schedule of Activities']

    const scenarioTemp = props.newSoa.scenarios[0]
    const eventsConfigureTemp = scenarioTemp&&scenarioTemp['Schedule of Activities']
    const eventsConfigWeeksTemp = eventsConfigureTemp&&eventsConfigureTemp.Weeks

    setInsightEvent(scenario&&scenario['soa_insights']? scenario['soa_insights']:[])

    if(eventsConfigure != undefined  && eventsConfigure.Weeks != undefined){
      setHiddeTags(false)
      setShowConfigure(false)
    } else if(eventsConfigWeeksTemp == undefined){
      setShowConfigure(true)
      props.setDisableSaveFinish(true)
    }
    let cost_avg = 0;
    let burden_avg = 0;
    if(props.newSoa&&props.newSoa.therapeutic_area_average != undefined){
        cost_avg = formatCostAvg(props.newSoa&&props.newSoa.therapeutic_area_average.cost_avg, 1000) ?? 0;
        burden_avg = props.newSoa&&props.newSoa.therapeutic_area_average.burden_avg != undefined?
                Number(props.newSoa&&props.newSoa.therapeutic_area_average.burden_avg.toString().match(/^\d+(?:\.\d{0,2})?/)) : 0;
    }
    setCostSubTitle('Similar Historical\nTrials - $' + cost_avg + 'K / Patient')
    setBurdenSubTitle('Similar Historical\nTrials - ' + burden_avg)
    setModalityOptions(getModalityOptions(scenario&&scenario['modality_option']))
    
    const getStandardEventsLib = async () => {
      var resp = await getStandardEvents(props.record.trial_uuid);

      if (resp && resp != "{}") {
          const response = resp
          console.log(response)
          ReactDOM.unstable_batchedUpdates(() => {
            setOrgLabs(response[CATEGORY_LABS])
            setOrgExamination(response[CATEGORY_PHYSICAL_EXAMINATION])
            setOrgProcedures(response[CATEGORY_PROCEDURES])
            setOrgQuestionnaires(response[CATEGORY_QUESTIONNAIRES])
            setOrgStudyProcedures(response[CATEGORY_STUDY_PROCEDURES])

            //Init previous configure
            if(eventsConfigure != undefined && eventsConfigure.Labs != undefined){
              setNumbers({
                ['visitNumber']: eventsConfigure.Visits,
                ['ScreeningVisits']: eventsConfigure?.VisitTypes?.ScreeningVisits || eventsConfigure.ScreeningVisits,
                ['TreatmentVisits']: eventsConfigure?.VisitTypes?.TreatmentVisits || eventsConfigure.TreatmentVisits,
                ['FollowupVisits']: eventsConfigure?.VisitTypes?.FollowupVisits || eventsConfigure.FollowupVisits,
                ['weekNumber']: eventsConfigure.WeekNumber? eventsConfigure.WeekNumber : eventsConfigure.Weeks[eventsConfigure.Weeks.length -1]
              });
              setEditNumbers({
                'screeningVisitsNumber': eventsConfigure?.VisitTypes?.ScreeningVisits || eventsConfigure.ScreeningVisits,
                'treatmentVisitsNumber': eventsConfigure?.VisitTypes?.TreatmentVisits || eventsConfigure.TreatmentVisits,
                'followupVisitsNumber': eventsConfigure?.VisitTypes?.FollowupVisits || eventsConfigure.FollowupVisits,
                'weekNumber': eventsConfigure.WeekNumber? eventsConfigure.WeekNumber : eventsConfigure.Weeks[eventsConfigure.Weeks.length -1]
              })
              if(eventsConfigure?.visitNotesList) {
                setVisitNoteList(eventsConfigure.visitNotesList)
                }
              setWeeks(eventsConfigure.Weeks)
              props.setDisableSaveFinish(false)
              setResetWeeks(false)
              setAddedLabs(eventsConfigure[CATEGORY_LABS].entities)
              setAddedExamination(eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION].entities)
              setAddedQuestionnaires(eventsConfigure[CATEGORY_QUESTIONNAIRES].entities)
              setAddedProcedures(eventsConfigure[CATEGORY_PROCEDURES].entities)
              setAddedStudyProcedures(eventsConfigure[CATEGORY_STUDY_PROCEDURES].entities)
              // setPatientRate('{p|$'+ eventsConfigure.TotalCost +'K}\n{'+eventsConfigure.CostRate + '|' + eventsConfigure.CostRate + '}')
              setPatientRate('$'+ formatCostAvg(scenario["cost"],1000) +'K\n'+eventsConfigure.CostRate)
              setCostData(getCostData(eventsConfigure.CostData))
              setBurdenData(eventsConfigure.BurdenData)
              setTotalBurden(sumOfArr(eventsConfigure.BurdenData))
              if (eventsConfigure.patient_burden_rate != ""){
              setTotalBurdenStatus(`{${eventsConfigure.patient_burden_rate}|${eventsConfigure.patient_burden_rate}}`)
              }
              else{
              setTotalBurdenStatus('')
              }
              setBurdenXAxis(eventsConfigure.BurdenXAxis)

              //init Activities by Modality chart data
              const tmpSoAConfig = {
                [CATEGORY_LABS]:eventsConfigure[CATEGORY_LABS].entities,
                [CATEGORY_PHYSICAL_EXAMINATION]:eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION].entities,
                [CATEGORY_PROCEDURES]:eventsConfigure[CATEGORY_PROCEDURES].entities,
                [CATEGORY_QUESTIONNAIRES]:eventsConfigure[CATEGORY_QUESTIONNAIRES].entities,
                [CATEGORY_STUDY_PROCEDURES]:eventsConfigure[CATEGORY_STUDY_PROCEDURES].entities
              }
              getModalitySeriesData(tmpSoAConfig)

              if(eventsConfigure.CostRate.length > 0){
                setShowTooltip(true)
                setShowPatientLabel(true)
              }
              if(isEventsAdded(eventsConfigure)){
                  setPatientChartColor(aChartColors)
                  setBurdenChartColor(burdenColors.active)
                  setLabelColors(aLabelColors)
              }else{
                  setPatientChartColor(iChartColors)
                  setBurdenChartColor(burdenColors.inactive)
                  setLabelColors(iLabelColors)
              }

              setFilteredLabs(filterLibs(response[CATEGORY_LABS], eventsConfigure[CATEGORY_LABS].entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
              setFilteredExamination(filterLibs(response[CATEGORY_PHYSICAL_EXAMINATION], eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION].entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
              setFilteredProcedures(filterLibs(response[CATEGORY_PROCEDURES], eventsConfigure[CATEGORY_PROCEDURES].entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
              setFilteredQuestionnaires(filterLibs(response[CATEGORY_QUESTIONNAIRES], eventsConfigure[CATEGORY_QUESTIONNAIRES].entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
              setFilteredStudyProcedures(filterLibs(response[CATEGORY_STUDY_PROCEDURES], eventsConfigure[CATEGORY_STUDY_PROCEDURES].entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))

              setEventCriteria(eventsConfigure)
            } else {
              setFilteredLabs(filterLibs(response[CATEGORY_LABS], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
              setFilteredExamination(filterLibs(response[CATEGORY_PHYSICAL_EXAMINATION], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
              setFilteredProcedures(filterLibs(response[CATEGORY_PROCEDURES], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
              setFilteredQuestionnaires(filterLibs(response[CATEGORY_QUESTIONNAIRES], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
              setFilteredStudyProcedures(filterLibs(response[CATEGORY_STUDY_PROCEDURES], [],FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
            }
          });
      }
    };
    getStandardEventsLib();
    }
  }, [props.newSoa.scenarios]);

  function filterLibs(orgLibs, addedEvents, minValue, maxValue){
    let filteredLibs = orgLibs.filter((d) => {
      if( d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue){
        var index = addedEvents.findIndex((domain) => d['Standard Activity'] === domain['Standard Activity']);
        if(index > -1){
          return Object.assign(d, {
            selected: true,
            condition: addedEvents[index].condition,
            totalVisit: addedEvents[index].totalVisit})
        } else {
          return Object.assign(d, {selected: false, condition: [], totalVisit: 0})
        }
      }
    })
    return filteredLibs
  }

  const panelHeader = () => {
    return (
        <div className="event-panelHeader">
            <div>
                <div style={{color:'#333', fontSize: '18px'}}><span>Impact</span></div>
            </div>
        </div>
    );
  };

  const eventLibHeader = (name, count, key) => {
    return (
      <Row className="section-header">
        <Col span={23}><span>{name}</span><span className="count-span">{count}</span></Col>
        <Col span={1} className="collapse-icon">{activeCollapse.indexOf(key) >= 0 ?(<MinusOutlined />):(<PlusOutlined />)}</Col>
      </Row>
    );
  };

  // Add total number and Good/fair/poor with color for version 1 only
  // let totalBurdenStatus = 'GOOD'
  // let totalBurdenStatusColor = '{GOOD|GOOD}'
  // if (totalBurden > 0 && totalBurden <= 400) {
  //   totalBurdenStatus = 'GOOD'
  //   totalBurdenStatusColor = '{GOOD|GOOD}'
  // } else if (totalBurden > 400 && totalBurden <= 600) {
  //   totalBurdenStatus = '{FAIR|FAIR}'
  // } else if (totalBurden > 600){
  //   totalBurdenStatus = '{POOR|POOR}'
  // }

  useEffect(()=>{

    const getVisits = () =>{
      let visitArr = [];
      let vNumber = editNumbers.screeningVisitsNumber + editNumbers.treatmentVisitsNumber + editNumbers.followupVisitsNumber
      for (let i = 0; i <= vNumber-1; i++) {
      visitArr.push(i+1)
    }
     return visitArr
    }
    const visitArr = getVisits()

    setVisits(visitArr)
  },[editNumbers,numbers])

  let final_visit_Number = numbers.ScreeningVisits + numbers.TreatmentVisits + numbers.FollowupVisits
  let chartWidthSmaller = final_visit_Number < 11? 240 + 2 * ( 1 + final_visit_Number):220 + 20 * ( 1 + final_visit_Number)
  let chartWidthLarge = chartWidthSmaller + 250
  // Add total number and Good/fair/poor with color for version 1 only
  let final_burden
  if (totalBurden === 0 && totalBurdenStatus === ""  ){
  final_burden  = ""
  }
  else{
  final_burden = totalBurden
  }
  const burdenOption = {
    title : {
      // textAlign: 'left',
      // textVerticalAlign: 'top',
      text: '\n' + '\n' +'{p|' + final_burden + '}'+'\n' + totalBurdenStatus,
      subtext: burdenSubTitle,
      x:'left',
      y:'top',
      textStyle: {
        rich: {
          a: {
            fontSize: 16,
            fontWeight: 'bold',
            color: '#333',
          },
          p: {
            color: '#999',
            fontSize: 20,
            backgroundColor: "white"
          },
          GOOD: {
            color: labelColors.GOOD,
            fontSize: 14,
            fontWeight:'bold',
            backgroundColor: "white"
          },
          FAIR: {
            color: labelColors.FAIR,
            fontSize: 14,
            fontWeight:'bold',
            backgroundColor: "white"
          },
          POOR: {
            color: labelColors.POOR,
            fontSize: 14,
            fontWeight:'bold',
            backgroundColor: "white"
          }
        }
      },
      subtextStyle: {
        fontSize: 14,
        fontWeight: 'normal',
        color: '#999'
      }
    },
    tooltip: {
      show: showTooltip,
      trigger: 'axis',
      axisPointer: {
          type: 'shadow'
      }
    },
    grid: {
        left: 230,
        width: chartWidthSmaller,
        // right: '4%',
        top: '10%',
        bottom: '15%',
        containLabel: true
    },
    xAxis: [
        {
          type: 'category',
          name: 'Visit Number',
          data: burdenXAxis,
          nameLocation: "middle",
          nameRotate: 0, nameGap: 20,
          axisTick: {
              alignWithLabel: true
          },
          nameTextStyle: {
            // align: 'center',
            lineHeight: 20,
          },
        }
    ],
    yAxis: [
        { type: 'value', name:'Patient Burden',
        nameRotate: 90, nameGap: 40, nameLocation: "middle",
        axisLine: { lineStyle: { color: '#666666' } },
        axisLabel : { formatter : function(value) { return value; } }, }
    ],
    series: [
        {
            name: 'Patient Burden',
            type: 'bar',
            barWidth: '60%',
            barMaxWidth : 35,
            color: burdenChartColor,
            data: burdenData,
            label: {
              show: false,
              position: 'top',
              fontSize: 12,
              color: '#666'
          },
        }
    ]
  };

  const costOption = {
    title : {
      // text: 'Cost Per Patient',
      subtext: costSubTitle,
      x:'left',
      y:'top',
      textStyle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333'
      },
      subtextStyle: {
        fontSize: 14,
        fontWeight: 'normal',
        color: '#999'
      }
    },
    legend: {
      x:'right',
      y:'20%',
      orient: 'vertical',
      align: 'left',
      itemHeight: 7,
      textStyle: {
        fontSize: 12,
        color: '#333'
      },
      data: [CATEGORY_LABS, CATEGORY_PHYSICAL_EXAMINATION, CATEGORY_PROCEDURES, CATEGORY_QUESTIONNAIRES, CATEGORY_STUDY_PROCEDURES]
    },
    tooltip: {
      show: showTooltip,
      trigger: 'item',
      formatter: '{b} - ${c}',
      extraCssText:'background:#757373;color:white;font-size:8px'
    },
    series: [
      {
        type: 'pie',
        center: ['30%', '60%'],
        radius: ['50%', '70%'],
        avoidLabelOverlap: true,
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false
          }
        },
        labelLine: {
          show: false
        },
        color: patientChartColor,
        data: costData
      }
    ],
    graphic: {
      elements: [{
        type: 'text',
        left: '20.5%',
        top: '51%', 
        cursor:'default',
        invisible: !showPatientLabel,
        style: {
          text: patientRate.split('\n')?patientRate.split('\n')[0].length === 3? (`   ${patientRate.split('\n')[0]}  `):(patientRate.split('\n')[0].length === 6? (` ${patientRate.split('\n')[0]}`):(patientRate.split('\n')[0])):"",
          fontSize: 20,
          textAlign: 'center', 
          width: 30,
          height: 30,
          fill: '#848484'
        }
      },
      {
        type: 'text',
        left: '25%',
        top: '61%',
        cursor:'default',
        invisible: !showPatientLabel,
        style: {
          text: patientRate.split('\n')?patientRate.split('\n')[1]:"",
          fontSize: 12,
          textAlign: 'center',
          fontWeight:'bold',
          width: 30,
          height: 25,
          fill: patientRate.split('\n')&&patientRate.split('\n')[1]=="GOOD"?labelColors.GOOD:patientRate.split('\n')&&patientRate.split('\n')[1]=="FAIR"?labelColors.FAIR:labelColors.POOR,
        }
      }]
    }
  };

  const handleEventChange = (tmpWeeks) =>{
    if(tmpWeeks != undefined && tmpWeeks.length > 0){
      setWeeks(tmpWeeks)
    }
    setResetWeeks(false)
  }

  const statisticsPerVisit = (categories,idx)=>{
    //statistics of each column
    const modalityCollection={}
    const modalitySummary={}
    const columnConditionCollection = []

    if(categories[CATEGORY_LABS].length>0){
      categories[CATEGORY_LABS].forEach( ele=>{
        columnConditionCollection.push( ele.condition[idx])
      })
    }
    if(categories[CATEGORY_PHYSICAL_EXAMINATION].length>0){
      categories[CATEGORY_PHYSICAL_EXAMINATION].forEach( ele=>{
        columnConditionCollection.push( ele.condition[idx])
      })

    }
    if(categories[CATEGORY_PROCEDURES].length>0){
      categories[CATEGORY_PROCEDURES].forEach( ele=>{
        columnConditionCollection.push( ele.condition[idx])
      })

    }
    if(categories[CATEGORY_QUESTIONNAIRES].length>0){
      categories[CATEGORY_QUESTIONNAIRES].forEach( ele=>{
        columnConditionCollection.push( ele.condition[idx])
      })
    }
    if(categories[CATEGORY_STUDY_PROCEDURES].length>0){
      categories[CATEGORY_STUDY_PROCEDURES].forEach( ele=>{
        columnConditionCollection.push( ele.condition[idx])
      })
    }

    columnConditionCollection.filter(element=>element&&element.modality&&element.modality!=="")
    .forEach( element=>{
    if( Object.keys(modalityCollection).indexOf(element.modality.toString())<0 ){
      modalityCollection[element.modality.toString()]=[element]
    }else{
      modalityCollection[element.modality.toString()].push(element)
    }})
    modalityOptions.forEach( option=>{
     if(option.key&&option.key!==""){
      if(Object.keys(modalityCollection).indexOf(option.key.toString())==-1){
        modalitySummary[option.name]=0
      }else{
        modalitySummary[option.name]=modalityCollection[option.key.toString()].length
      }
     }
    })
    return modalitySummary
  }

  useEffect(()=>{
    const categories={
      [CATEGORY_LABS]:addedLabs,
      [CATEGORY_PHYSICAL_EXAMINATION]:addedExamination,
      [CATEGORY_PROCEDURES]:addedProcedures,
      [CATEGORY_QUESTIONNAIRES]:addedQuestionnaires,
      [CATEGORY_STUDY_PROCEDURES]:addedStudyProcedures,
    }
    getModalitySeriesData(categories)

  },[visits,addedLabs,addedExamination,addedProcedures,addedQuestionnaires,addedStudyProcedures])

  const getModalitySeriesData = (categories) =>{
    const modalitySeries = []

    const visitStacks=visits.map( (ele,idx)=>{
      return statisticsPerVisit(categories,idx)
    })
    if(visitStacks.filter(v=>v!==undefined).length==0) return
    modalityOptions.forEach((element,idx)=>{
      const data = visitStacks.map( v=>{
        return v[element.name]
      })
      modalitySeries.push({
        name: element.name,
        type: 'bar',
        stack: 'total',
        emphasis: {
          focus: 'series'
        },
        data,
        color:element.color
      })
    })

    setModalityChartData(modalitySeries)
  }

  const saveEvents = async (scheduleOfEvents) =>{
    if(submitType === 1){
      props.handleGoBack(Object.assign(scheduleOfEvents,{
        'TotalCost': eventCriteria.TotalCost,
        'CostRate': eventCriteria.CostRate,
        'CostData': costData,
        'BurdenData': burdenData,
        'BurdenXAxis': burdenXAxis,
        'Finished': eventCriteria.Finished,
        'WeekNumber': scheduleOfEvents.Weeks[scheduleOfEvents.Weeks.length-1]
      }))
      console.log("eventCriteria.TotalCost",eventCriteria.TotalCost);

      return;
    }
    if(isEventsAdded(scheduleOfEvents)){
        setPatientChartColor(aChartColors)
        setBurdenChartColor(burdenColors.active)
        setLabelColors(aLabelColors)
    }else{
        setPatientChartColor(iChartColors)
        setBurdenChartColor(burdenColors.inactive)
        setLabelColors(iLabelColors)
    }
    setWeeks(scheduleOfEvents.Weeks)
    let tempEditNumber = {
      'screeningVisitsNumber': scheduleOfEvents.ScreeningVisits,
      'treatmentVisitsNumber': scheduleOfEvents.TreatmentVisits,
      'followupVisitsNumber': scheduleOfEvents.FollowupVisits,
      'weekNumber': scheduleOfEvents.WeekNumber
    }
    let tempPreviousEditNumber = {
      'ScreeningVisits': scheduleOfEvents.ScreeningVisits,
      'TreatmentVisits': scheduleOfEvents.TreatmentVisits,
      'FollowupVisits': scheduleOfEvents.FollowupVisits,
      'weekNumber': scheduleOfEvents.WeekNumber
    }
    setEditNumbers(tempEditNumber)
    setPreviousCategoryVisits(tempPreviousEditNumber)
    const categories={
      [CATEGORY_LABS]:scheduleOfEvents[CATEGORY_LABS].entities,
      [CATEGORY_PHYSICAL_EXAMINATION]:scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities,
      [CATEGORY_PROCEDURES]:scheduleOfEvents[CATEGORY_PROCEDURES].entities,
      [CATEGORY_QUESTIONNAIRES]:scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities,
      [CATEGORY_STUDY_PROCEDURES]:scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities,
    }
    setAddedLabs(scheduleOfEvents[CATEGORY_LABS].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
    setAddedExamination(scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
    setAddedQuestionnaires(scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
    setAddedProcedures(scheduleOfEvents[CATEGORY_PROCEDURES].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
    setAddedStudyProcedures(scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
    getModalitySeriesData(categories)
    let costList ;
    let burdenList
    if(props.newSoa&&props.newSoa.therapeutic_area_average != undefined && props.newSoa&&props.newSoa.therapeutic_area_average.length > 0){
        costList = [props.newSoa&&props.newSoa.therapeutic_area_average.cost_limit_lower_quartile, props.newSoa&&props.newSoa.therapeutic_area_average.cost_limit_upper_quartile]
        burdenList = [props.newSoa&&props.newSoa.therapeutic_area_average.pb_limit_lower_quartile, props.newSoa&&props.newSoa.therapeutic_area_average.pb_limit_upper_quartile]
    }else{
        costList = [0,0];
        burdenList = [0,0];
    }

    let scenario_id = props.scenarioId
    let currentScenario = props.newSoa.scenarios && props.newSoa.scenarios.find(s=> s['scenario_id'] == props.scenarioId);
    let ts_id = currentScenario["ts_id"];
    let soa_dismiss_list = dismissEvent||[]
    
    scheduleOfEvents["visitNoteList"] = visitNoteList

    const resp = await update_patient_burden(props.record['trial_uuid'], props.scenarioId, ts_id, scheduleOfEvents, tempEditNumber, costList, burdenList, soa_dismiss_list,  getEmail())
    if (resp && Object.keys(resp).length !== 0) {
      const response = resp
      const soa = response['soa']
      let finalCost = 0;
      soa.CostData.forEach(item => {
        finalCost += item.value;
      });
      setShowPatientLabel(true)
      if(soa.CostRate) {
        // setPatientRate('{p|$'+ formatCostAvg(finalCost,1000) +'K}\n{' +soa.CostRate+ '|' + soa.CostRate + '}')
        setPatientRate('$'+ formatCostAvg(finalCost,1000) +'K\n' +soa.CostRate)
      }
      setCostData(soa.CostData.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
      }))
      setBurdenData(soa.BurdenData)
      setTotalBurden(sumOfArr(soa.BurdenData))
      setTotalBurdenStatus(`{${soa.patient_burden_rate}|${soa.patient_burden_rate}}`)
      setBurdenXAxis(soa.BurdenXAxis)
      setShowTooltip(true)
      let tempNumber = {
        'ScreeningVisits': scheduleOfEvents.ScreeningVisits,
        'TreatmentVisits': scheduleOfEvents.TreatmentVisits,
        'FollowupVisits': scheduleOfEvents.FollowupVisits,
        'weekNumber': scheduleOfEvents.WeekNumber
      }
      setNumbers(tempNumber)
      let tempTrialSOA = props.newSoa
      let tempScenario = tempTrialSOA.scenarios.find(s=> s['scenario_id'] == props.scenarioId);
      tempScenario["Schedule of Activities"] = soa
      currentScenario['Schedule of Activities'] = soa
      currentScenario['soa_insights'] = response['soa_insights']
      setInsightEvent(response['soa_insights'])
      props.setScenario(currentScenario)
      setVisitNoteList(soa.visitNoteList)
      props.createSoa(tempTrialSOA)
      message.success('Save successfully')
      if(submitType != 0){
        props.history.push({pathname: '/trials', state: {trial_id: props.newSoa&&props.newSoa['trial_uuid']}})
        localStorage.setItem('processStep', JSON.stringify(0));
        localStorage.setItem('activeTabKey', JSON.stringify('1'));
      }
    }else{
        message.error('Failed to save')
    }
  }

  function roundFun(value, n) {
      return Math.round(value*Math.pow(10,n))/Math.pow(10,n);
  }

  function formatCostAvg(totalCost, divisor){
    if(totalCost == undefined || totalCost === 0){
      return 0
    } else {
      let avg = Math.ceil(totalCost/divisor*1000)
      return roundFun(avg/1000, 2)
    }
  }

  function formatBurdenAvg(totalCost, divisor){
    if(totalCost === 0){
      return 0
    } else {
      let avg = Math.ceil(totalCost/divisor*100)
      return avg/100
    }
  }

  const callback = (key) => {
    setActiveCollapse(key)
  }

  const columns = [{
    title: 'Standard Activity',
    dataIndex: 'Standard Activity',
    key: 'Standard Activity',
    width: '100%',
    render: (_, item) => {
      return item.selected ? (
	    <span className="event-text"  onClick={(e)=> handleEvent(item)}>
        <Tooltip title={"Frequency " + Math.round(item['Frequency'] * 10000) / 100 + "%"}>
	    <div style={{width: '79%', display:'inline-block'}}>{item['Standard Activity']}</div>
        <div className="remove" style={{width: '21%', display:'inline-block'}}>Remove</div>
        </Tooltip></span>
      ) : (
	    <span className="event-text" onClick={(e)=> handleEvent(item)}>
        <Tooltip title={"Frequency " + Math.round(item['Frequency'] * 10000) / 100 + "%"}>
	    <div style={{width: '79%', display:'inline-block'}}>{item['Standard Activity']}</div>
        <div className="add" style={{width: '21%', display:'inline-block'}}>Add</div>
        </Tooltip></span>
      )
    }
  }
  ]

  const handleEvent = (item) => {
    switch(item.Categories.trim()){
      case "Labs":
        let index = filteredLabs.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        let newData = [...filteredLabs]
        const newSelectedData = [...addedLabs]

        if(item.selected){ 
          for (let key in item) {
            if (key == "endpoint") {
               delete item[key];
            }
         }  
          newData.splice(index, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
          let selectedIndex = addedLabs.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData.splice(selectedIndex, 1)
        } else {
          newData.splice(index, 1, { ...item, ...{selected: true}});
          newSelectedData.push(Object.assign(item, {selected: true}))
        }
        setFilteredLabs(newData)
        setAddedLabs(newSelectedData)
        break;

      case "Physical Examination":
        let index2 = filteredExamination.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        const newData2 = [...filteredExamination]
        const newSelectedData2 = [...addedExamination]

        if(item.selected){
          for (let key in item) {
            if (key == "endpoint") {
               delete item[key];
            }
         }
          newData2.splice(index2, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
          let selectedIndex = addedExamination.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData2.splice(selectedIndex, 1)
        } else {
          newData2.splice(index2, 1, { ...item, ...{selected: true}});
          newSelectedData2.push(Object.assign(item, {selected: true}))
        }
        setFilteredExamination(newData2)
        setAddedExamination(newSelectedData2)
        break;

      case "Procedures":
        let index3 = filteredProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        const newData3 = [...filteredProcedures]
        const newSelectedData3 = [...addedProcedures]

        if(item.selected){
          for (let key in item) {
            if (key == "endpoint") {
               delete item[key];
            }
         }
          newData3.splice(index3, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0 }});
          let selectedIndex = addedProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData3.splice(selectedIndex, 1)
        } else {
          newData3.splice(index3, 1, { ...item, ...{selected: true}});
          newSelectedData3.push(Object.assign(item, {selected: true}))
        }
        setFilteredProcedures(newData3)
        setAddedProcedures(newSelectedData3)
        break;

      case "Questionnaires":
        let index4 = filteredQuestionnaires.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        const newData4 = [...filteredQuestionnaires]
        const newSelectedData4 = [...addedQuestionnaires]

        if(item.selected){
          for (let key in item) {
            if (key == "endpoint") {
               delete item[key];
            }
         }
          newData4.splice(index4, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
          let selectedIndex = addedQuestionnaires.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData4.splice(selectedIndex, 1)
        } else {
          newData4.splice(index4, 1, { ...item, ...{selected: true}});
          newSelectedData4.push(Object.assign(item, {selected: true}))
        }
        setFilteredQuestionnaires(newData4)
        setAddedQuestionnaires(newSelectedData4)
        break;
      case "Study Procedures":
        let index5 = filteredStudyProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        const newData5 = [...filteredStudyProcedures]
        const newSelectedData5 = [...addedStudyProcedures]

        if(item.selected){
          for (let key in item) {
            if (key == "endpoint") {
               delete item[key];
            }
         }
          newData5.splice(index5, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
          let selectedIndex = addedStudyProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData5.splice(selectedIndex, 1)
        } else {
          newData5.splice(index5, 1, { ...item, ...{selected: true}});
          newSelectedData5.push(Object.assign(item, {selected: true}))
        }
        setFilteredStudyProcedures(newData5)
        setAddedStudyProcedures(newSelectedData5)
        break;
      default: break;
    }
    handleEventChange([])
  }

  const showConfigureModal = () =>{
    setShowConfigure(true)
  }

  const [windowResize, setWindowResize] = useState(true)

  useEffect(() => {
    if(showConfigure) {
      setWindowResize(false)
    } else {
      setWindowResize(true)
    } 
  }, [showConfigure])

  const handleOk = () => {
    if(!validateScreeningVisit(numbers.ScreeningVisits) || !validateTreatmentVisit(numbers.TreatmentVisits) || !validateFollowUpVisit(numbers.FollowupVisits) || !validateWeek(numbers.weekNumber)){
      return
    }
    let final_visit_number = numbers.ScreeningVisits + numbers.TreatmentVisits + numbers.FollowupVisits; 
    setShowConfigure(false)
    props.setDisableSaveFinish(false)
    setHiddeTags(false)
    // if(final_visit_number === final_edit_visit_number && numbers.weekNumber === editNumbers.weekNumber){
    //   return
    // }
    let weeksArr = [];
    // if(resetWeeks){
      let tempBurdenXAxis = []
      for(var num = 1; num <= final_visit_number; num++){
        tempBurdenXAxis.push(num)
      }
      setBurdenXAxis(tempBurdenXAxis)
      //Re-generate whole weeks when no change in SOA
      if (numbers.weekNumber < final_visit_number){
        for(var num = 0; num < final_visit_number - numbers.weekNumber + 1; num ++){
          weeksArr.push(1)
        }
        var size = weeksArr.length
        for(var num = 1; num <= final_visit_number - size; num ++){
          weeksArr.push(num + 1)
        }
      } else if (numbers.weekNumber == final_visit_number){
        for (let num = 1; num <= numbers.weekNumber; num ++){
          weeksArr.push(num)
        }
      } else {
        weeksArr.push(1)
        if (final_visit_number > 1){
          const a = (numbers.weekNumber - 1) % (final_visit_number - 1)
          const b = Math.floor((numbers.weekNumber - 1)/(final_visit_number - 1))
          let temp_num = 1
          for (let num = 1; num < final_visit_number; num ++ ) {
            if (final_visit_number - num > a) {
              weeksArr.push(temp_num + b)
              temp_num += b
            } else {
              weeksArr.push(temp_num + b + 1)
              temp_num += b + 1
            }
          }
        }
      }
    // } else {
    //   //Keep weeks options as much as possible after SOA changed
    //   let week = 0;
    //   if(numbers.visitNumber > editNumbers.visitNumber && numbers.weekNumber > weeks[weeks.length - 1]){
    //     week = Math.floor((numbers.weekNumber - weeks[weeks.length - 1]) / (numbers.visitNumber - editNumbers.visitNumber));
    //   }
    //   let sum = 0;
    //   for (var i = 1; i <= numbers.visitNumber; i++) {
    //     if(weeks.length >= i){
    //       weeksArr.push(weeks[i-1])
    //       sum = weeks[i-1]
    //     } else {
    //       sum += week;
    //       if (sum > numbers.weekNumber || i === numbers.visitNumber) sum = numbers.weekNumber;
    //       weeksArr.push(sum)
    //     }
    //   }
    // }

    setWeeks(weeksArr) 
    setEditNumbers({
      screeningVisitsNumber: numbers.ScreeningVisits,
      treatmentVisitsNumber:  numbers.TreatmentVisits,
      followupVisitsNumber: numbers.FollowupVisits,
      weekNumber: numbers.weekNumber
    })
    const scenario =  props.newSoa.scenarios && props.newSoa.scenarios.find(s=> s['scenario_id'] == props.scenarioId);
    const eventsConfigure = scenario['Schedule of Activities']
    if(isEventsAdded(eventsConfigure)){
        setPatientChartColor(aChartColors)
        setBurdenChartColor(burdenColors.active)
        setLabelColors(aLabelColors)
    }else{
        setPatientChartColor(iChartColors)
        setBurdenChartColor(burdenColors.inactive)
        setLabelColors(iLabelColors)
    }
    setEventCriteria({
      'Finished' : false
    })

    updateVisitNotes(numbers,weeksArr);
  }

  const  handleResetNotes = (obj, idx, isDelete, isAdd, newWeeks) => { 
    let tempPreviousEditNumber = {
      'ScreeningVisits': obj?.evtScreeningVisitsNumber,
      'TreatmentVisits': obj?.evtTreatmentVisitsNumber,
      'FollowupVisits': obj?.evtFollowupVisitsNumber,
      'weekNumber': obj?.evtWeekNumber
    }
    if(isDelete) {
      setVisitNoteList(DeleteVisitItem(idx, tempPreviousEditNumber, newWeeks))
    } else if(isAdd){
      setVisitNoteList(AddVisitItem(idx, tempPreviousEditNumber, newWeeks))
    }


  }


  const DeleteVisitItem = (idx, tempPreviousEditNumber, newWeeks) => {
    let notes = [...visitNoteList];
    let visitNotes = notes.map(k => k.visit_notes)
    notes.splice(idx, 1)
    visitNotes.splice(idx, 1)
    notes = notes.map((k, i) => ({...k, visit_notes: visitNotes[i], weeks: newWeeks[i], visit: i + 1}))
    setPreviousCategoryVisits(tempPreviousEditNumber)
    return notes;
  }

  const AddVisitItem = (idx, tempPreviousEditNumber, newWeeks) => {
    let notes = [...visitNoteList];
    let toBeArray =  Array.from({ length: 1 }, (v, i) => i).map((k, i) => ({visit_notes: '', weeks : newWeeks[i], visit: idx + 2 }))
    let visitNotes = notes.map(k => k.visit_notes)
    visitNotes.splice(idx+1, 0, ...toBeArray.map(k => k.visit_notes))
    notes.splice(idx+1, 0, ...toBeArray)
    notes = notes.map((k, i) => ({...k, visit_notes: visitNotes[i], weeks: newWeeks[i], visit: i + 1}))
    setPreviousCategoryVisits(tempPreviousEditNumber)
    return notes;
  }

  const handleScreeningVisits = async(numbers, Obj,weeksArr) => {
    let{ notes, categories} = Obj;
    const visitsNotes = [...visitNoteList]
    if(numbers?.ScreeningVisits > categories.ScreeningVisits) {
      if(numbers?.ScreeningVisits - categories.ScreeningVisits >= 1) {
      let length = numbers?.ScreeningVisits - categories.ScreeningVisits;
      let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => categories.ScreeningVisits + k)
       notes = addNotesCondition( m_arr, weeksArr, visitsNotes);
      }
    } else {
      let length = categories.ScreeningVisits - numbers?.ScreeningVisits ;
      let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => numbers?.ScreeningVisits + k )
      notes = deleteNotesCondition( m_arr, weeksArr, visitsNotes)
    }
    categories = {
      'ScreeningVisits': numbers?.ScreeningVisits,
      'TreatmentVisits': categories?.TreatmentVisits,
      'FollowupVisits': categories?.FollowupVisits,
      'weekNumber': numbers?.WeekNumber
    }
    return {
      notes,
      categories
    }
  }

  const handleTreatmentVisits = async(numbers, Obj,weeksArr) => {
    let{ notes, categories} = Obj
    if(numbers.TreatmentVisits > categories.TreatmentVisits) {
      if(numbers.TreatmentVisits - categories.TreatmentVisits >= 1) {
        let length = numbers?.TreatmentVisits - categories.TreatmentVisits;
        let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => categories.ScreeningVisits + categories.TreatmentVisits + k)
        notes = addNotesCondition( m_arr, weeksArr, notes);
      }
    } else {
      let length = categories.TreatmentVisits - numbers?.TreatmentVisits ;
      let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => numbers?.ScreeningVisits + numbers?.TreatmentVisits + k )
      notes = deleteNotesCondition( m_arr, weeksArr, notes)
    }
    categories = {
      'ScreeningVisits': numbers?.ScreeningVisits,
      'TreatmentVisits': numbers?.TreatmentVisits,
      'FollowupVisits': categories?.FollowupVisits,
      'weekNumber': numbers?.WeekNumber
    }
    return {
      notes,
      categories
    }
  }

  const handleFollowUpVisits = async(numbers, Obj,weeksArr) => {
    let{ notes, categories} = Obj
    if(numbers.FollowupVisits > categories.FollowupVisits) {
      if(numbers.FollowupVisits - categories.FollowupVisits >= 1) {
        let length = numbers?.FollowupVisits - categories.FollowupVisits;
        let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => categories.ScreeningVisits + categories.TreatmentVisits + categories.FollowupVisits + k);
        notes = addNotesCondition( m_arr, weeksArr, notes);
    }
    } else {
       let length = categories.FollowupVisits - numbers?.FollowupVisits ;
      let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => numbers?.ScreeningVisits + numbers?.TreatmentVisits + numbers?.FollowupVisits + k )
      notes = deleteNotesCondition( m_arr, weeksArr, notes)
    }
    categories = {
      'ScreeningVisits': numbers?.ScreeningVisits,
      'TreatmentVisits': numbers?.TreatmentVisits,
      'FollowupVisits': numbers?.FollowupVisits,
      'weekNumber': numbers?.WeekNumber
    }
    return {
      notes,
      categories
    }
  }

  const addNotesCondition = ( m_arr, weeks, visitsNotes) => {
    let notes = [...visitsNotes];
    let toBeArray =  Array.from({ length: m_arr.length }, (v, i) => i).map((k, i) => ({visit_notes: '', weeks : weeks[m_arr[k]], visit: m_arr[k] + 1 }))
    let visitNotes = notes.map(k => k.visit_notes)
    visitNotes.splice(m_arr[0], 0, ...toBeArray.map(k => k.visit_notes))
    notes.splice(m_arr[0], 0, ...toBeArray)
    notes = notes.map((k, i) => ({...k, visit_notes: visitNotes[i], weeks: weeks[i], visit: i + 1}))
    return notes;
  }

  const updateVisitNotes = async (numbers,weeksArr) => {
    let notesByVisitsObj = {
      notes: [...visitNoteList],
      categories: {...prevCategoryVisists}
      }
    notesByVisitsObj = await handleScreeningVisits(numbers, notesByVisitsObj, weeksArr)
    notesByVisitsObj = await handleTreatmentVisits(numbers, notesByVisitsObj, weeksArr)
    notesByVisitsObj = await handleFollowUpVisits(numbers, notesByVisitsObj, weeksArr) 
    setVisitNoteList(notesByVisitsObj.notes)
    setPreviousCategoryVisits(notesByVisitsObj.categories);
  }

  let getVisitData = (z) => {
    return visitNoteList?.find((k, i) => k.visit == visits[z])?.visit_notes;
  }

    useEffect(() => {
    if(visits.length == 0 || weeks.length == 0) return;
    let n_visists = visits.map((k, i) => ({visit_notes: getVisitData(i) ??  '', weeks:weeks[i], visit: visits[i] }));
    setVisitNoteList(n_visists)
  }, [visits, weeks]);


  const deleteNotesCondition = ( m_arr, weeks, visitsNotes) => {
    if(visitNoteList.length == 0) return
    let notes = [...visitsNotes];
    let visitNotes = notes.map(k => k.visit_notes)
    notes.splice(m_arr[0], m_arr.length)
    visitNotes.splice(m_arr[0], m_arr.length)
    notes = notes.map((k, i) => ({...k, visit_notes: visitNotes[i], weeks: weeks[i], visit: i + 1}))
    return notes;
  }

  const exportEvent = () =>{
    let str='Schedule of Activities\n' + ',' + ',' + 'Visit'
    for(let i = 1; i <= numbers.visitNumber; i ++){
      str += ',' + i
    }

    str += '\n' + ',' + ',' + 'Week'
    for(let w = 0; w < weeks.length; w ++){
      str += ',' + weeks[w]
    }

    str += '\n' + ',' + ',' + 'Burden'
    for(let b = 0; b < burdenData.length; b ++){
      str += ',' + burdenData[b]
    }

    str += '\n' + 'Category' + ',Activity' + ',Cost'

    str += getEventContent(CATEGORY_LABS, addedLabs)
    str += getEventContent(CATEGORY_PHYSICAL_EXAMINATION, addedExamination)
    str += getEventContent(CATEGORY_PROCEDURES, addedProcedures)
    str += getEventContent(CATEGORY_QUESTIONNAIRES, addedQuestionnaires)
    str += getEventContent(CATEGORY_STUDY_PROCEDURES, addedStudyProcedures)

    let exportContent = "\uFEFF";
      let blob = new Blob([exportContent + str], {
        type: "text/plain;charset=utf-8"
      });

      const date = Date().split(" ");
      const dateStr = date[1] + '_' + date[2] + '_' + date[3] + '_' + date[4];
      FileSaver.saveAs(blob, `SoA_${dateStr}.csv`);
  }

  function getEventContent(catrgory, events) {
    let subStr = ''
    for(const event in events){
      subStr += '\n' + catrgory + ',"' + events[event]['Standard Activity'] + '",' + events[event]['Dummy Cost']
      if(events[event]['condition'].length > 0){
        for(const idx in events[event]['condition']){
          subStr += ',' + (events[event]['condition'][idx].checked ? 'x' : '')
        }
      }
    }
    return subStr
  }

  const formatter = (value) => {
    return value+'%'
  }

  const getFrequency = (value) => {

    setMinV(value[0])
    setMaxV(value[1])

    // setFilteredLabs(filterLibs(orgLabs, addedLabs, value[0], value[1]))
    // setFilteredExamination(filterLibs(orgExamination, addedExamination, value[0], value[1]))
    // setFilteredQuestionnaires(filterLibs(orgQuestionnaires, addedQuestionnaires, value[0], value[1]))
    // setFilteredProcedures(filterLibs(orgProcedures, addedProcedures, value[0], value[1]))
    // setFilteredStudyProcedures(filterLibs(orgStudyProcedures, addedStudyProcedures, value[0], value[1]))
  }


  const onInsightClick = (item) => {
    let rawInsight = [...insightEvent]
    let resData
    rawInsight = rawInsight.filter((d) => {
     return d.activity!== item.activity
    })
    setInsightEvent(rawInsight)
    const handleInsightChange = (orgCategoryData, filteredCategoryData, addedCategoryData) => {
      let detailCategoryData
      let indexDetailCategoryData
      indexDetailCategoryData = orgCategoryData.findIndex((d) => item['activity'] == d['Standard Activity'])
      indexDetailCategoryData > -1? detailCategoryData = orgCategoryData[indexDetailCategoryData] : []
      let index = filteredCategoryData.findIndex((d) => item['activity'] == d['Standard Activity'])
      let newData = [...filteredCategoryData]
      const newSelectedData = [...addedCategoryData]
      if(item.action === "REMOVE"){
        newData.splice(index, 1, { ...detailCategoryData, ...{selected: false, condition: [], totalVisit: 0}});
        let selectedIndex = addedCategoryData.findIndex((d) => item['activity'] == d['Standard Activity'])
        newSelectedData.splice(selectedIndex, 1)
      } else {
        newData.splice(index, 1, { ...detailCategoryData, ...{selected: true}});
        if(newSelectedData.findIndex((d) => item['activity'] == d['Standard Activity']) === -1) {
          // To avoid duplicate events added
          detailCategoryData&&newSelectedData.push(Object.assign(detailCategoryData, {selected: true}))
        }
      }
      return {newData, newSelectedData}
    }
    switch(item.category.trim()){
      case "Labs":
        resData = handleInsightChange(orgLabs, filteredLabs, addedLabs)
        setFilteredLabs(resData.newData)
        setAddedLabs(resData.newSelectedData)
        break;
      case "Physical Examination":
        resData = handleInsightChange(orgExamination, filteredExamination, addedExamination)
        setFilteredExamination(resData.newData)
        setAddedExamination(resData.newSelectedData)
        break;
      case "Procedures":
        resData = handleInsightChange(orgProcedures, filteredProcedures, addedProcedures)
        setFilteredProcedures(resData.newData)
        setAddedProcedures(resData.newSelectedData)
        break;
      case "Questionnaires":
        resData = handleInsightChange(orgQuestionnaires, filteredQuestionnaires, addedQuestionnaires)
        setFilteredQuestionnaires(resData.newData)
        setAddedQuestionnaires(resData.newSelectedData)
        break;
      case "Study Procedures":
        resData = handleInsightChange(orgStudyProcedures, filteredStudyProcedures, addedStudyProcedures)
        setFilteredStudyProcedures(resData.newData)
        setAddedStudyProcedures(resData.newSelectedData)
        break;
      default: break;
    }
    handleEventChange([])
    }

  const onDismiss = (key) => {
    let rawList = [...dismissEvent]
    rawList.push(key)
    setDismissEvent(rawList)
    let rawInsight = [...insightEvent]
    rawInsight = rawInsight.filter((d) => {
     return d.activity!== key.activity
    })
    setInsightEvent(rawInsight)
  }

  // search functionality
  const [searchTxt,setSearchTxt] = useState("")
  const [visibleValue, setVisibleValue] = useState(false)

  const [searchLabs, setSearchLabs] = useState([])
  const [searchExamination, setSearchExamination] = useState([])
  const [searchQuestionnaires, setSearchQuestionnaires] = useState([])
  const [searchProcedures, setSearchProcedures] = useState([])
  const [searchStudyProcedures, setStudyProcedures] = useState([])

  const optionLabs = orgLabs.map((item, index)=>{
    return item
  })
  const optionExamination = orgExamination.map((item, index)=>{
      return item
    })
  const optionProcedures = orgProcedures.map((item, index)=>{
      return item
    })
  const optionQuestionnaires = orgQuestionnaires.map((item, index)=>{
      return item
    })
  const optionStudyProcedures = orgStudyProcedures.map((item, index)=>{
      return item
    })

  let timer = null;
  const onTextChange = (e) => {
    const val = e.target.value.toLowerCase();
    let searchLabsTemp = []
    let searchExamTemp = []
    let searchProTemp=[]
    let searchQuesTemp=[]
    let searchStudyProTemp=[]
    !visibleValue&&setVisibleValue(true)
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      setSearchTxt(val)
      searchLabsTemp = val.length>0? optionLabs.filter(
        (i) =>
          i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
        ) : []
      searchExamTemp = val.length>0? optionExamination.filter(
        (i) =>
          i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
        ): []
      searchProTemp = val.length>0?optionProcedures.filter(
        (i) =>
          i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
        ): []
      searchQuesTemp = val.length>0?optionQuestionnaires.filter(
        (i) =>
          i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
        ): []
      searchStudyProTemp = val.length>0?optionStudyProcedures.filter(
        (i) =>
          i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
        ): []
      setSearchLabs(searchLabsTemp)
      setSearchExamination(searchExamTemp)
      setSearchProcedures(searchProTemp)
      setSearchQuestionnaires(searchQuesTemp)
      setStudyProcedures(searchStudyProTemp)
    }, 200);
  }

  const onItemClick = ({ key }) => {
    setVisibleValue(true)
    let item = JSON.parse(key.slice(1))
    switch(item.Categories.trim()){
      case "Labs":
        let index = filteredLabs.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        const newData = [...filteredLabs]
        const newSelectedData = [...addedLabs]

        if(index < 0){
          newSelectedData.push(Object.assign(item, {selected: true, condition: [], totalVisit: 0}))
        }else if(newData[index].selected){
          newData.splice(index, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
          let selectedIndex = addedLabs.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData.splice(selectedIndex, 1)
        } else {
          newData.splice(index, 1, { ...item, ...{selected: true}});
          newSelectedData.push(Object.assign(item, {selected: true}))
        }
        setFilteredLabs(newData)
        setAddedLabs(newSelectedData)
        break;

      case "Physical Examination":
        let index2 = filteredExamination.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        const newData2 = [...filteredExamination]
        const newSelectedData2 = [...addedExamination]

        if(index2 < 0){
          newSelectedData2.push(Object.assign(item, {selected: true, condition: [], totalVisit: 0}))
        }else if(newData2[index2].selected){
          newData2.splice(index2, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
          let selectedIndex = addedExamination.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData2.splice(selectedIndex, 1)
        } else {
          newData2.splice(index2, 1, { ...item, ...{selected: true}});
          newSelectedData2.push(Object.assign(item, {selected: true}))
        }
        setFilteredExamination(newData2)
        setAddedExamination(newSelectedData2)
        break;

      case "Procedures":
        let index3 = filteredProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        const newData3 = [...filteredProcedures]
        const newSelectedData3 = [...addedProcedures]

        if(index3 < 0){
          newSelectedData3.push(Object.assign(item, {selected: true, condition: [], totalVisit: 0}))
        }else if(newData3[index3].selected){
          newData3.splice(index3, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
          let selectedIndex = addedProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData3.splice(selectedIndex, 1)
        } else {
          newData3.splice(index3, 1, { ...item, ...{selected: true}});
          newSelectedData3.push(Object.assign(item, {selected: true}))
        }
        setFilteredProcedures(newData3)
        setAddedProcedures(newSelectedData3)
        break;

      case "Questionnaires":
        let index4 = filteredQuestionnaires.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        const newData4 = [...filteredQuestionnaires]
        const newSelectedData4 = [...addedQuestionnaires]

        if(index4 < 0){
          newSelectedData4.push(Object.assign(item, {selected: true, condition: [], totalVisit: 0}))
        }else if(newData4[index4].selected){
          newData4.splice(index4, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
          let selectedIndex = addedQuestionnaires.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData4.splice(selectedIndex, 1)
        } else {
          newData4.splice(index4, 1, { ...item, ...{selected: true}});
          newSelectedData4.push(Object.assign(item, {selected: true}))
        }
        setFilteredQuestionnaires(newData4)
        setAddedQuestionnaires(newSelectedData4)
        break;
      case "Study Procedures":
        let index5 = filteredStudyProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
        const newData5 = [...filteredStudyProcedures]
        const newSelectedData5 = [...addedStudyProcedures]

        if(index5 < 0){
          newSelectedData5.push(Object.assign(item, {selected: true, condition: [], totalVisit: 0}))
        }else if(newData5[index5].selected){
          newData5.splice(index5, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
          let selectedIndex = addedStudyProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
          newSelectedData5.splice(selectedIndex, 1)
        } else {
          newData5.splice(index5, 1, { ...item, ...{selected: true}});
          newSelectedData5.push(Object.assign(item, {selected: true}))
        }
        setFilteredStudyProcedures(newData5)
        setAddedStudyProcedures(newSelectedData5)
        break;
      default: break;
    }
    handleEventChange([])
  }

  const handleCancel = () => {
    setShowConfigure(false)
  };

 const renderItem = (title: string, type: string, idx: any, Frequency: any) => {
  return (
   <Tooltip
   title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}
   >
    <div
      className="itemLine"
      style={{
        // display: 'flex',
        // justifyContent: 'space-between',
      }}
    >
      <span className="itemTitle" style={{ width: '80%',display: 'inline-block',overflowWrap:'break-word'}}>
        {/* {(searchTxt.length < title.length)&& title.toLowerCase().split(searchTxt)[0]} */}
        {(searchTxt.length < title.length) && compareString(title, title.toLowerCase().split(searchTxt)[0])} 
        <span className={`${
          searchTxt &&
          title.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
            ? "matched-item"
            : ""
          }`}>
            {/* {searchTxt.length - title.length === 0?title:searchTxt} */}
            {compareMiddle(title, searchTxt.length - title.length === 0?title:searchTxt)}
        </span>
        {/* {(searchTxt.length !== 0)&&(searchTxt.length < title.length)&&title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length)} */}
        { (searchTxt.length !== 0)&&(searchTxt.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length))}
      </span>
      <span style={{width: '20%',display: 'inline-block',color:"#CA4A04", marginLeft:"25px"}}>
        Add
      </span>
    </div>
    </Tooltip>
  )
};

const renderItemClick = (title: string, type: string, idx: any, Frequency: any) => {
  return (
    <div
      className="itemLine"
      style={{
        // display: 'flex',
        // justifyContent: 'space-between',
      }}
    >
    <Tooltip title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}>
      <span className="itemTitle" style={{ width: '80%',display: 'inline-block',overflowWrap:'break-word'}}>
        {/* {(searchTxt.length < title.length)&& title.toLowerCase().split(searchTxt)[0]} */}
        {(searchTxt.length < title.length) && compareString(title, title.toLowerCase().split(searchTxt)[0])} 
        <span className={`${
          searchTxt &&
          title.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
            ? "matched-item"
            : ""
          }`}>
            {/* {searchTxt.length - title.length === 0?title:searchTxt} */}
             {compareMiddle(title, searchTxt.length - title.length === 0?title:searchTxt)}
        </span>
        {/* {(searchTxt.length !== 0)&&(searchTxt.length < title.length)&&title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length)} */}
        { (searchTxt.length !== 0)&&(searchTxt.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length))}
      </span>
      <span style={{width: '20%',display: 'inline-block',color:"#3193E5", fontWeight:700, marginLeft:"25px"}}>
          <CheckOutlined />
      </span>
      </Tooltip>
    </div>
  )
};
  
const compareString = (original, sub_str) => {
  let index = original.toLocaleLowerCase().indexOf(sub_str)
  return original.substring(index, sub_str.length)
}

const compareMiddle = (original, sub_str) => {
  let index = original.toLocaleLowerCase().indexOf(sub_str);
  let secondIndex = original.toLocaleLowerCase().length == sub_str.toLocaleLowerCase().length ? original.toLocaleLowerCase().length : index+sub_str.length;
  return original.substring(index, secondIndex)
}

 const menu = (
  <Menu onClick={onItemClick}>
    {(searchTxt.length !== 0)&&searchLabs.length>0 && (<Menu.ItemGroup title="Labs">
     {
        searchLabs.slice(0, 10).map((item,idx)=>{
          if(addedLabs.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
            return <Menu.Item key={"D"+JSON.stringify(item)}>{renderItem(item['Standard Activity'],"D", idx, item.Frequency)}</Menu.Item>
          } else {
            return <Menu.Item key={"D"+JSON.stringify(item)}>{renderItemClick(item['Standard Activity'],"D", idx, item.Frequency)}</Menu.Item>
          }
        })
      }
    </Menu.ItemGroup>)}
    {(searchTxt.length !== 0)&&searchExamination.length>0 && 10-searchLabs.length>0?(<Menu.ItemGroup title="Physical Examination">
      {
        searchExamination.slice(0,10-searchLabs.length).map((item,idx)=>{
          if(addedExamination.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
            return <Menu.Item key={"M"+JSON.stringify(item)}>{renderItem(item['Standard Activity'], "M",idx, item.Frequency)}</Menu.Item>
          }else {
            return <Menu.Item key={"M"+JSON.stringify(item)}>{renderItemClick(item['Standard Activity'], "M",idx, item.Frequency)}</Menu.Item>
          }
        })
      }
    </Menu.ItemGroup>):(<></>)}
    {(searchTxt.length !== 0)&&searchProcedures.length>0 &&10-searchLabs.length-searchExamination.length>0?(<Menu.ItemGroup title="Procedures">
       {
        searchProcedures.slice(0,10-searchLabs.length-searchExamination.length).map((item,idx)=>{
          if(addedProcedures.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
            return <Menu.Item key={"I"+JSON.stringify(item)}>{renderItem(item['Standard Activity'], "I",idx, item.Frequency)}</Menu.Item>
          }else{
            return <Menu.Item key={"I"+JSON.stringify(item)}>{renderItemClick(item['Standard Activity'], "I",idx, item.Frequency)}</Menu.Item>
          }
        })
      }
    </Menu.ItemGroup>):(<></>)}
    {(searchTxt.length !== 0)&&searchQuestionnaires.length>0 && 10-searchLabs.length-searchExamination.length - searchProcedures.length>0?<Menu.ItemGroup title="Questionnaires">
       {
        searchQuestionnaires.slice(0,10-searchLabs.length-searchExamination.length- searchProcedures.length).map((item,idx)=>{
          if(addedQuestionnaires.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
            return <Menu.Item key={"L"+JSON.stringify(item)}>{renderItem(item['Standard Activity'], "L",idx, item.Frequency)}</Menu.Item>
          }else{
            return <Menu.Item key={"L"+JSON.stringify(item)}>{renderItemClick(item['Standard Activity'], "L",idx, item.Frequency)}</Menu.Item>
          }
        })
      }
    </Menu.ItemGroup>:(<></>)}
    {(searchTxt.length !== 0)&&searchStudyProcedures.length>0 && 10-searchLabs.length-searchExamination.length - searchProcedures.length - searchQuestionnaires.length>0?<Menu.ItemGroup title="Study Procedures">
       {
        searchStudyProcedures.slice(0,10-searchLabs.length-searchExamination.length - searchProcedures.length - searchQuestionnaires.length).map((item,idx)=>{
          if(addedStudyProcedures.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
            return <Menu.Item key={"S"+JSON.stringify(item)}>{renderItem(item['Standard Activity'], "S",idx, item.Frequency)}</Menu.Item>
          }else{
            return <Menu.Item key={"S"+JSON.stringify(item)}>{renderItemClick(item['Standard Activity'], "S",idx, item.Frequency)}</Menu.Item>
          }
        })
      }
    </Menu.ItemGroup>:(<></>)}
    {searchTxt.length === 0 && <div style={{cursor: "none", padding: 20}}></div>}
    {(searchTxt.length !== 0 && searchLabs.length + searchExamination.length + searchQuestionnaires.length + searchProcedures.length + searchStudyProcedures.length === 0) && <div key="no-result" style={{cursor: "none", padding: 20}}>There are no activities in the library that match your search.</div>}
  </Menu>
 );

  return (
    <div className="tab-container soa-view">
      <Spin spinning={showConfigure}>
      <Row>
        {<Col span={1} style={{maxWidth: '50px', minWidth: '50px'}}>
          <div className={`side-toolbar ${eventLib > 0 ? '' : ''}`}>
            <Tooltip placement="right" title={'Activity Library'}>
              <FileTextFilled style={{color:`${eventPanel==="1"?"#404042":"#999999"}`, fontSize: "16px", marginLeft: "15px", paddingTop: "12px", cursor: "pointer"}} onClick={()=> {
            setEventPanel("1")
            soeInsightsToggleHandler(true)
            }}/>
            </Tooltip>
            <Badge style={{marginTop: '20px'}} count={insightEvent?insightEvent.length:0} showZero={insightEvent && insightEvent.length == 0 ? true : false }>
            <Tooltip placement="right" title={'Insights'}>
              <CarryOutFilled style={{color:`${eventPanel==="2"?"#404042":"#999999"}`, fontSize: "16px", marginLeft: "15px", marginTop: "20px", paddingTop: "12px", cursor: "pointer"}} onClick={()=> {
              setEventPanel("2")
              soeInsightsToggleHandler(true)
                }}/>
             </Tooltip>
            </Badge>
            {!soeLibrarayPanelStatus &&  <div className="action-wrapper"></div>}
	          {!soeLibrarayPanelStatus &&  <span className="action-title" onClick={()=>  soeInsightsToggleHandler(true)}>
                <RightOutlined style={{color:"#CA4A04"}}/>
            </span>}
          </div>
        </Col>}
        {eventPanel==="1"&&(
        <Col span={eventLib} className="event-left-container">
          <Row style={{backgroundColor: '#F8F8F8'}}>
            <Col span={24}>
              <div className="item-header">
                <Row>
                  <Col span={20}>
                    <span>Activity Library</span>
                  </Col>
                  <Col span={2}>
                  <Tooltip title={'View Historical Trial SOA'}>
                    <HistoryOutlined className="right-icon" onClick={() => props.setVisibleSOA(true)}></HistoryOutlined>
                  </Tooltip>
                  </Col>
                  <Col span={2}>
                  {/* <Tooltip title={'Collapse Activity Library'}>
                    <CloseOutlined className="right-icon" onClick={() => setEventLib(0)}></CloseOutlined>
                  </Tooltip> */}
                      {soeLibrarayPanelStatus && <div className="action-wrapper"></div>}
                      {(soeLibrarayPanelStatus && eventLib === 6 )&&(<span className="action-title" onClick={() => soeInsightsToggleHandler(false)}>
                              <LeftOutlined style={{color:"#CA4A04"}}/>
                           </span>)}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row style={{borderBottom:'10px solid transparent'}}>
            <Col flex="none">
              <div style={{ padding: '0 10px' }}></div>
            </Col>
            <Col className="left-section">
              <Row className="head-row" style={{alignItems: 'center', margin: '10px 0', borderBottom: "1px solid #ddd"}}>
                <Col span={24}>
                   {/* search bar */}
                   <div className="searchSection">
                        <div className="content">
                          <Dropdown
                            overlay={menu}
                            overlayClassName="searchbox"
                            visible={visibleValue}
                            onVisibleChange={(visible: boolean) => {!visibleValue?setVisibleValue(true):setVisibleValue(false)}}
                          >
                            <Input
                                prefix={<SearchOutlined />}
                                style={{ width: '100%', height: 37 }}
                                allowClear
                                onChange={onTextChange}
                                onClick={e => e.preventDefault()}
                            />
                          </Dropdown>
                        </div>
                      </div>
                </Col>
              </Row>
              <Row className="head-row" style={{alignItems: 'center', marginBottom: '10px'}}>
                <Col span={16}>
                  <div className="item-option">
                    <span>Select / Unselect activities from library</span>
                  </div>
                </Col>
                <Col span={8} style={{textAlign:'right', paddingRight:'10px', fontSize:'14px'}}>
                  <Row>
                  <Col span={24}><span className="frequency">ACTIVITY FREQUENCY</span></Col>
                  </Row>
                  <Row style={{width:'100%'}}>
                  <Col span={24}>
                    <div id="freqModal" ref={null} onClick={() => setVisibleSlider(true)}>
                      <span className="label">
                        {minV}%-{maxV}%
                      </span>
                      <EditFilled className={`${visibleSlider ? 'active' : ''}`}/>
                    </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {visibleSlider ? (
              <div className="freqSection">
                <div className="title">
                  <span>Set Frequency</span>
                  <CloseOutlined
                    className="right-icon"
                    onClick={() => setVisibleSlider(false)}
                  ></CloseOutlined>
                </div>
                <br/>
                <div className="content">
                  <span>Activity Frequency</span>
                  <span style={{ float: "right", fontWeight: 'bold' }}>
                    {minV}% - {maxV}%
                  </span>
                </div>
                <Slider
                  min={FREQUENCY_RANGE_MIN}
                  max={FREQUENCY_RANGE_MAX}
                  range={{ draggableTrack: true }}
                  defaultValue={[minV, maxV]}
                  tipFormatter={formatter}
                  onAfterChange={getFrequency}
                />
              </div>
              ) : (
              <></>
              )}
              {/* {filteredLabs.length+filteredExamination.length+filteredProcedures.length+filteredQuestionnaires.length+filteredStudyProcedures.length>0?(
                <> */}
                <Row className="event-section">
                  <Col span={24}>
                  <Collapse className="eventLib" collapsible="header" onChange={callback} activeKey={activeCollapse}>
                    <Panel showArrow={false} header={eventLibHeader(CATEGORY_LABS, filteredLabs.filter((d)=>{
                     return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                    }).length, "1")} key="1">
                      {filteredLabs.filter((d)=>{
                      return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                    }).length>0 ? (
                        <Table dataSource={filteredLabs.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                        })} columns={columns} pagination={false} showHeader={false}
                        locale={{emptyText: 'No Data'}} rowKey={record => record['Standard Activity']}/>
                      ): (
                        <></>
                      )}
                    </Panel>
                  </Collapse>
                  </Col>
                </Row>
                <Row className="event-section">
                  <Col span={24}>
                  <Collapse className="eventLib" collapsible="header" onChange={callback} activeKey={activeCollapse}>
                    <Panel showArrow={false} header={eventLibHeader(CATEGORY_PHYSICAL_EXAMINATION, filteredExamination.filter((d)=>{
                     return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                    }).length, "2")} key="2">
                      {filteredExamination.filter((d)=>{
                     return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                    }).length>0 ? (
                        <Table dataSource={filteredExamination.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         })} columns={columns} pagination={false} showHeader={false}
                          locale={{emptyText: 'No Data'}} rowKey={record => record['Standard Activity']}/>
                      ): (
                        <></>
                      )}
                    </Panel>
                  </Collapse>
                  </Col>
                </Row>
                <Row className="event-section">
                  <Col span={24}>
                  <Collapse className="eventLib" collapsible="header" onChange={callback} activeKey={activeCollapse}>
                    <Panel showArrow={false} header={eventLibHeader(CATEGORY_PROCEDURES, filteredProcedures.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         }).length, "3")} key="3">
                      {filteredProcedures.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         }).length>0 ? (
                        <Table dataSource={filteredProcedures.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         })} columns={columns} pagination={false} showHeader={false}
                          locale={{emptyText: 'No Data'}} rowKey={record => record['Standard Activity']}/>
                      ): (
                        <></>
                      )}
                    </Panel>
                  </Collapse>
                  </Col>
                </Row>
                <Row className="event-section">
                  <Col span={24}>
                  <Collapse className="eventLib" collapsible="header" onChange={callback} activeKey={activeCollapse}>
                    <Panel showArrow={false} header={eventLibHeader(CATEGORY_QUESTIONNAIRES, filteredQuestionnaires.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         }).length, "4")} key="4">
                      {filteredQuestionnaires.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         }).length>0 ? (
                        <Table dataSource={filteredQuestionnaires.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         })} columns={columns} pagination={false} showHeader={false}
                          locale={{emptyText: 'No Data'}} rowKey={record => record['Standard Activity']}/>
                      ): (
                        <></>
                      )}
                    </Panel>
                  </Collapse>
                  </Col>
                </Row>
                <Row className="event-section">
                  <Col span={24}>
                  <Collapse className="eventLib" collapsible="header" onChange={callback} activeKey={activeCollapse}>
                    <Panel showArrow={false} header={eventLibHeader(CATEGORY_STUDY_PROCEDURES, filteredStudyProcedures.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         }).length, "5")} key="5">
                      {filteredStudyProcedures.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         }).length>0 ? (
                        <Table dataSource={filteredStudyProcedures.filter((d)=>{
                          return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
                         })} columns={columns} pagination={false} showHeader={false}
                          locale={{emptyText: 'No Data'}} rowKey={record => record['Standard Activity']}/>
                      ): (
                        <></>
                      )}
                    </Panel>
                  </Collapse>
                  </Col>
                </Row>
                {/* </>
              ):(
              <div className="left-section no-content" style={{fontSize:16,color:"#333",fontWeight:500, paddingTop: "20px"}}>No Events Available</div>
              )} */}
            </Col>
            <Col flex="none">
              <div style={{ padding: '0 10px' }}></div>
            </Col>
          </Row>
        </Col>
         )}
         {eventPanel==="2"&&(
           <Col span={eventLib} style={{
             backgroundColor: '#F8F8F8'
           }}>
             <Row style={{backgroundColor: '#F8F8F8'}}>
               <Col span={24}>
                 <div className="item-header">
                   <span>Insights ({insightEvent?insightEvent.length:0 })</span>
                   <div className="action-wrapper"></div>
                   {soeLibrarayPanelStatus && <div className="action-wrapper"></div>}
                   {(soeLibrarayPanelStatus && eventLib === 6 )&&(<span className="action-title" onClick={() => soeInsightsToggleHandler(false)}>
                       <LeftOutlined style={{color:"#CA4A04"}}/>
                     </span>)}
                 </div>
               </Col>
               <Col span={24} className="content-outer insight-box-wrapper">
                 { insightEvent&&insightEvent.map((o) => {
                    return o.action === "ADD"? (
                     <div className="insight-box">
                       <div className="head">SUGGESTED TO ADD</div>
                       <div className="content"><b>{o.activity}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider adding it to your scenario.</div>
                       <div className="button-area">
                         <span className="button-left" onClick={e=>onInsightClick(o)}>
                           <PlusOutlined style={{margin:"0 3px 0 0"}}/> Add Activity
                         </span>
                         <span className="button-right" onClick={e=>onDismiss(o)}>
                           <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                         </span>
                       </div>
                     </div>
                   ):
                   (
                     <div className="insight-box">
                       <div className="head">SUGGESTED TO REMOVE</div>
                       <div className="content"> <b>{o.activity}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider removing it from your scenario.</div>
                       <div className="button-area">
                         <span className="button-left" onClick={e=>onInsightClick(o)}>
                           <MinusOutlined style={{margin:"0 3px 0 0"}}/> Remove Activity
                         </span>
                         <span className="button-right" onClick={e=>onDismiss(o)}>
                           <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                         </span>
                       </div>
                     </div>
                   )
                 })}
               </Col>
             </Row>
           </Col>
           )}
        <Col 
          xxl={{span: soeLibrarayPanelStatus ? 17 : 23}}
          xl={{ span: soeLibrarayPanelStatus ? 16 : 23}}
          lg={{ span: soeLibrarayPanelStatus ? 16 : 22}}
          md={{ span: soeLibrarayPanelStatus ? 15 : 21}}
          sm={{ span: soeLibrarayPanelStatus ? 12 : 20}}
          xs={{ span: soeLibrarayPanelStatus ? 10 : 19}}
          // span={soeLibrarayPanelStatus ? 17 : 22} 
          flex="auto" className="event-right-container">
          <div style={{ padding: '10px 20px 0px 20px' }}>
            {/* <Row>
              <Col span={24}><span className="tab-title">Schedule of Activities</span></Col>
            </Row> */}
            <Row>
              {/* <Col span={9}>
                <span className="tip1-desc">
                  Use the historical event library on the left to build the Schedule of Activities..
                </span>
              </Col> */}
              <Col span={12}></Col>
             <Col span={6} className={`right${hiddeTags ? 'hidde' : ''}`}>
                <span className="center">
                Number of Visits <InputNumber disabled size="small" value={numbers.ScreeningVisits + numbers.TreatmentVisits + numbers.FollowupVisits} />
                </span>
                <EditFilled className="edit-icon" onClick={showConfigureModal}/>
              </Col>
              <Col span={6} className={`right ${hiddeTags ? 'hidde' : ''}`}>
                <span className="">
                  Number of Weeks <InputNumber disabled size="small" value={numbers.weekNumber} />&nbsp;
                </span>
                <EditFilled className="edit-icon" onClick={showConfigureModal}/>
              </Col>
              {/* <Col span={0} className={ `right ${hiddeTags ? 'hidde' : ''}`}>
                <Dropdown.Button style={{zIndex: 1, display: "none"}} size="small"
                  overlay={
                    <Menu>
                      <Menu.Item key="csv" onClick={exportEvent}>CSV</Menu.Item>
                    </Menu>
                  }
                  icon={<DownOutlined />}>
                  <DownloadOutlined />
                  EXPORT AS
                </Dropdown.Button>
              </Col> */}

              <Col span={24} className={`${hiddeTags ? 'hidde' : ''}`}>
              <Collapse defaultActiveKey={['1']} onChange={excluCallback} expandIconPosition="right" className="event-chart">
                <Panel header={panelHeader()} key="1">
                  <div style={{overflowX: 'scroll',}}>
                  <div style={{width: "1650px"}}>
                    <div style={{display:"inline-block", width: "400px", verticalAlign: "top"}}>
                      <div className="chart-title">
                        <div className="text" style={{marginLeft: 0,fontSize: 16, fontWeight: 'bold',color: '#333'}}>
                        Cost Per Patient
                        </div>
                      </div>
                      <ReactECharts option={costOption} style={{ height: 195, paddingRight: '8%'}}/>
                      <div style={{paddingLeft: '2%',marginTop: '1px', fontSize: '14px', color: '#999'}}>
                        <span>Click on each metric to filter</span>
                      </div>
                    </div>
                    <div style={{display:"inline-block", width: "550px", verticalAlign: "top"}} >
                      <div style={{width: '100%', overflowX: 'scroll' }}>
                        <div className="chart-title">
                          <div className="text" style={{marginLeft: 0,fontSize: 16, fontWeight: 'bold',color: '#333'}}>
                          Patient Burden Total
                          </div>
                        </div>
                        <ReactECharts option={burdenOption} style={{ height: 200, width: chartWidthLarge}}/>
                      </div>
                    </div>
                    <div style={{display:"inline-block", width: "600px", marginLeft: "50px", verticalAlign: "top"}}>
                      <div style={{width: '100%', overflowX: 'hidden' }}>
                          <div className="chart-title">
                              <div className="text" style={{marginLeft: 20,fontSize: 16, fontWeight: 'bold',color: '#333'}}>
                              Activities by Modality
                              </div>
                          </div>
                         <ReactECharts option={modalityOption} style={{ width: '300px', height: '185px'}}/>
                      </div>
                    </div>
                  </div>
                  </div>
                </Panel>
              </Collapse>
              </Col>

              <Col span={24} style={{height: '25px'}}></Col>

              <Col span={24}>
                <div className="event-dashboard-container">
                    <EventList
                      endpoints={endpoints}
                      saveEvents={saveEvents}
                      handleEventChange={handleEventChange}
                      numbers={editNumbers}
                      labs={addedLabs}
                      examination={addedExamination}
                      procedures={addedProcedures}
                      questionnaire={addedQuestionnaires}
                      studyProcedures={addedStudyProcedures}
                      weeks={weeks}
                      visits={visits}
                      submitType={submitType}
                      updateWeeks={setWeeks}
                      hiddeTags={hiddeTags}
                      numbersData={numbers}
                      showConfigureModal={showConfigureModal}
                      modalityOptions={modalityOptions}
                      handleEvent={handleEvent}
                      readOnlyUser={props.record.trial_user_role}
                      windowResize={windowResize}
                      visitNoteListHandler={handleVisitNoteList}
                      visitsList={visitNoteList}
                      resetNotes={handleResetNotes}
                      soeReadOnly={props.readOnly}
                      soeLibrarayPanelStatus={soeLibrarayPanelStatus}
                    />
                </div>
              </Col>
            </Row>
        </div>
        </Col>
      </Row>
       </Spin>
      <Modal visible={showConfigure} title="" closable={false} mask={false} onCancel={handleCancel} footer={null} style={{ left: '12%', top:10 }} centered={false} maskClosable={false}>
        <Row className="configure-title">
         <span>Configure Schedule Of Events Table</span>
        </Row>
        <br/>
        <Row className="modal-filed">
          <Col span={12} className="label"><span>Number of Screening Visits</span></Col>
          <Col span={12} className="input-number"><InputNumber  step={1} onStep={onStepScreeningVisit} onChange={validateScreeningVisit} value={numbers.ScreeningVisits} /></Col>
          <Col span={24} className="input-number-error"><span>{numberOfScreeningVisitsError}</span></Col>
        </Row>

        <Row className="modal-filed">
          <Col span={12} className="label"><span>Number of Treatment Visits</span></Col>
          <Col span={12} className="input-number"><InputNumber  step={1} onStep={onStepTreatmentVisit} onChange={validateTreatmentVisit} value={numbers.TreatmentVisits} /></Col>
          <Col span={24} className="input-number-error"><span>{numberOfTreatmentVisitsError}</span></Col>
        </Row>

        <Row className="modal-filed">
          <Col span={12} className="label"><span>Number of Follow-up Visits</span></Col>
          <Col span={12} className="input-number"><InputNumber  step={1} onStep={onStepFollowUpVisit} onChange={validateFollowUpVisit} value={numbers.FollowupVisits} /></Col>
          <Col span={24} className="input-number-error"><span>{numberOfFollowupVisitsError}</span></Col>
        </Row>

        <Row className="modal-filed">
          <Col span={12} className="label"><span>Number of Weeks</span></Col>
          <Col span={12} className="input-number"><InputNumber step={1} onStep={onStepWeek} onChange={validateWeek} value={numbers.weekNumber} /></Col>
          <Col span={24} className="input-number-error"><span>{numberOfWeeksError}</span></Col>
        </Row>
        <Row style={{justifyContent: 'center', paddingTop: '20px'}}>
          <Button type="primary" className="step-btn create-btn" onClick={handleOk}>CREATE</Button>
        </Row>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
  createSoa: (val) => dispatch(createActions.createSoa(val)),
});

const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
  newSoa: state.soaReducer
});
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleEvents);
