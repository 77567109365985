/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Input, Select, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import * as createActions from "../../../actions/createTrial.js";
import "./index.scss";
import { getEmail } from "../../../utils/auth";

const { Option } = Select;
const roleList = ["Trial Designer"];

const TeamMembers = (props) => {
  const { roles } = props;
  const [id, setId] = useState("");
  const [role, setRole] = useState("");
  const [users, setUsers] = useState(props.newTrial.members);
  const [memberLimitError, setMemberLimitError] = useState("")
  const [adminUser, setAdminUser] = useState();
  // const handleInputChange = (e) => {
  //   setId(e.target.value);
  // };

  const handleMemberChange = (item) => {
    setMemberLimitError("")
    setId(item);
    setRole("1")
    let admin_user = props.teamMember.filter(e => e.user == item)
    if(admin_user && admin_user.length > 0) { 
      setAdminUser(admin_user[0].user_role)
    }
  };

  const handleSelectChange = (e) => {
    setRole(e);
  };

  const getRole = (role_id) => {
    let roleName;
    switch(role_id) {
      case 1:
        roleName = 'Edit';
        break;
      case 2: 
        roleName = 'Read';
        break;
      default:
        roleName = '';
    }
    return roleName;
  }

  const handleAdd = () => {
    if(role == "") return;
    const tmp = users.slice(0);
    if (id === "") return
    if(users.length + 1 > 15){
      setMemberLimitError("Can't add any more users.")
      return
    }else{
      setMemberLimitError("")
    }
    if(!tmp.find(t=>t.name === id)){
      tmp.push({
        idx: users.length + 1,
        name:id,
        role: getRole(role),
        user_role_id: parseInt(role),
        id,
      });
    }
    // add a member just once
    let newTemp = tmp.map( (item,index)=>{
      return Object.assign(item, {idx: index + 1})
    })
    setUsers(newTemp);
    props.createTrial({
      members: newTemp,
    });
    setId("");
    setRole("");
    setAdminUser(null)
  };

  const handleRemove = (index) => {
    setMemberLimitError("")
    const tmp = users.slice(0);
    const targetIdx = tmp.findIndex((e) => e.id == index);
    tmp.splice(targetIdx, 1);
    setUsers(tmp);
    props.createTrial({
      members: tmp,
    });
  };

  const displayInitials = (email) => {
    return email.split('@')[0].split('.').map((namePart) => (namePart[0].toUpperCase())).join('');
  };

  const displayFullName = (email) => {
    return email.split('@')[0].split('.').map((namePart) => (namePart.charAt(0).toUpperCase() + namePart.slice(1))).join(' ');
  };

  return (
    <div className="team-members-container">
      <div className="add-box">
        {/* <div>
          <label>SEARCH AND ADD USERS</label>
          <Input
            allowClear
            placeholder="Enter user name"
            value={id}
            style={{ width: "100%", height: 30 }}
            onChange={(e) => handleInputChange(e)}
          />
        </div> */}
        <div>
          <label>Search and add users</label> <br />
          <Select
            defaultValue="All"
            value={id}
            showSearch
            style={{ width: "100%", height: 30 }}
            onChange={(e) => handleMemberChange(e)}
            // filterOption={(input, option) =>
            //   option.children
            //     .toLowerCase()
            //     .indexOf(input.toLowerCase()) >= 0
            // }
          >
            {props.teamMember.map((o) => {
              return (
                <Option value={o.user} key={o.user}>
                  {o.user_role && o.user_role == "admin" ? `${o.user} (${o.user_role})` : o.user} 
                </Option>
              );
            })}
          </Select>
        </div>
        <div>
          <label>Select Role</label>
          <Select
            value={getRole(parseInt(role))}
            placeholder="Select"
            style={{ width: "100%" }}
            disabled={adminUser == 'admin' ? true : false}
            onChange={handleSelectChange}
          >
            {roles.map((role) => {
              return (
                <Option value={role.value} key={role.value}>
                  {role.label}
                </Option>
              );
            })}
          </Select>
        </div>
        <Button type="primary" onClick={handleAdd}>
          Add
        </Button>
      </div>
        {memberLimitError && memberLimitError.length > 0 && ( <span style={{ color: "#d04a02"}}>{memberLimitError}</span>)}
      <div className="user-list-wrapper">
        <span className="user-list-title">
          Total Users{" "}
          <span
            className={`count count-icon ${
              users.length > 0 ? "active-count" : "inactive-count"
            }`}
          >
            {users.length}
          </span>
        </span>
        <div className="user-list">
          {users.map((u) => {
            return (
              <div className="user-item" key={u.idx}>
                <span className="icon">{u && u.name && displayInitials(u.name)}</span>
                <div>
                  <span className="name">{u && u.name && displayFullName(u.name)}</span>
                  <br />
                  <span className="id">{u && u.id? u.id : (u && u.name?u.name:'')}</span>
                </div>
                <div className="role">{u && getRole(u.user_role_id)}</div>
                {
                  // Disables the ability for a user to remove themselves when creating a trial
                  <CloseOutlined style= {{visibility: u.id&&u.name !== getEmail() ? 'visible' : 'hidden'}} onClick={() => handleRemove(u.id)} />
                }
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
});

const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(TeamMembers);
