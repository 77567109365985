const BASE_URL = process.env.REACT_APP_API_GATEWAY_URL
const COGNITO_BASE_URL = process.env.REACT_APP_COGNITO_URL
const WEB_URL = process.env.REACT_APP_WEB_URL
const IDP_PROVIDER_NAME = process.env.REACT_APP_IDP_PROVIDER_NAME
const CLIENT_ID = process.env.REACT_APP_IDP_CLIENT_ID
export const USE_EXTERNAL_IDP = process.env.REACT_APP_USE_EXTERNAL_IDP

// TD3.1 merging
// const BASE_URL = "https://d209vl3l93e04z.cloudfront.net/api"
// const WEB_URL = "http://localhost:3000"
// const IDP_PROVIDER_NAME = 'LoginWithPwCOIDC'
// const CLIENT_ID = '4tdgu6huq92okoac94smgndo2h'
// const COGNITO_BASE_URL = 'https://ict-base-td-app-dev.auth.us-east-2.amazoncognito.com'

// const BASE_URL = "https://dod629s3rlrm9.cloudfront.net/api"
// const BASE_URL = "https://drwv9j50rqfdx.cloudfront.net/api"
// const BASE_URL = "https://d25l5utc65r6qy.cloudfront.net/api"
// const BASE_URL = "https://d28ywqoz5gu3cu.cloudfront.net/api"
// TD 2.1 Test env
// const BASE_URL = "https://d260rp9mj3a6xq.cloudfront.net/api" 
// TD 2.1 Dev env
// const BASE_URL = "https://dg63rolku4uwc.cloudfront.net/api"
// const BASE_URL = "https://duabwjr32yql7.cloudfront.net/api"

// Local Test env with IDP settings
// const BASE_URL = "https://zhz12a.ctd-poc.com/api"
// const WEB_URL = "http://localhost:3000"
// const IDP_PROVIDER_NAME = 'LoginWithPwCOIDC'
// const CLIENT_ID = '7l1o9irr94ncgipdibjole7kpc'
// const COGNITO_BASE_URL = 'https://td-app-poc.auth.us-east-1.amazoncognito.com'

// Local Test env 2 with IDP settings
// const BASE_URL = 'https://dxdrcbz36wwd6.cloudfront.net/api'
// const WEB_URL = 'http://localhost:3000'
// const IDP_PROVIDER_NAME = 'LoginWithPwCOIDC'
// const CLIENT_ID = '1v8asnqpthqd8jrqkou1stcnnm'
// const COGNITO_BASE_URL = 'https://pfe-td-app-poc.auth.us-east-1.amazoncognito.com'

// Cognito configuration details
export const COGNITO_CONFIG = {
    client_id: CLIENT_ID,
    redirect_uri:`${WEB_URL}`,
    scope: `openid ${BASE_URL}`,
    response_type: 'code',
    identity_provider: IDP_PROVIDER_NAME
}

export const AUTHORIZE_URL = COGNITO_BASE_URL + "/oauth2/authorize"

export const COGNITO_LOGIN_URL = COGNITO_BASE_URL + "/login"

export const COGNITO_TOKEN_URL = COGNITO_BASE_URL + "/oauth2/token"

export const USERINFO_URL = COGNITO_BASE_URL + "/oauth2/userInfo"

export const REVOKE_URL = COGNITO_BASE_URL + "/oauth2/revoke"

export const COGNITO_LOGOUT_URL = `${COGNITO_BASE_URL}/logout?client_id=${CLIENT_ID}&logout_uri=${WEB_URL}/logout`

export const PWCID_LOGOUT_URL = `https://login-stg.pwc.com/openam/XUI/#logout&goto=${WEB_URL}`

// Required for Content Security Policy
// export const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minute timeout
export const INACTIVITY_TIMEOUT = 12 * 60 * 60 * 1000; // 12 hour timeout

export const LIST_ALL_TRIAL_URL = "/api/trial/list_all_trials"

export const GET_TRIAL_URL = "/api/trial/get_trial"

export const UPDATE_TRIAL_URL = "/api/trial/update_trial"

export const DELETE_TRIAL_URL = "/api/trial/delete_trial"

export const CALCULATE_MEAN_COST_URL = "/api/calculate_mean_cost"

export const CHECK_CREATE_TRIAL_STATUS_URL = "/api/check_create_trial_status"

export const CHECK_PATIENT_FUNNEL_STATUS_URL = "/api/check_patient_funnel_status"

export const GENERATE_CRITERIA_LIBRARY_URL = "/api/generate_criteria_library"

export const GET_CRITERIA_LIBRARY_URL = "/api/get_criteria_library"

export const GET_IE_RESOURCES_URL = "/api/get_ie_resources"

export const GET_PATIENT_FUNNEL_URL = "/api/get_patient_funnel"

export const GET_SOA_EVENT_LIBRARY_URL = "/api/get_soa_library"

export const GET_SOA_RESOURCES_URL = "/api/get_soa_resources"

export const GET_REFERENCE_DATA_URL = "/api/get_reference_data"

export const LIST_SIMILAR_HISTORICAL_TRIAL_URL = "/api/list_similar_historical_trial"

export const ADD_TRIAL_URL = "/api/trial/create_trial"

export const SAVE_TRIAL_SOA_URL = "/api/save_trial_soa"

export const LIST_MEMBERS_URL= "/api/listMembers"

export const CREATE_SCENARIO_URL = "/api/trial/create_scenario"

export const SAVE_CRITERIA_URL = "/api/save_trial_criteria"

export const SAVE_TEMPLATE_SCENARIO_URL = "/api/trial/save_template_scenario"

export const GET_TRIAL_IE_CRITERIA = "/api/trial/get_trial_ie_criteria"

export const GET_TRIAL_SOA = "/api/trial/get_trial_soa"

export const CLINICAL_TRIALS_GOV_URL = "https://clinicaltrials.gov/study/"

export const GET_TRIAL_SOA_INSIGHTS = "/api/trial/get_trial_soa_insights"

export const LOGOUT_URL = "/api/logout"