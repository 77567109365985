/* eslint-disable */
import React, { useState, useEffect, useReducer} from 'react';
import 'antd/dist/antd.css';
import './index.scss';
import { Table, Input, InputNumber, Form, Typography, Button, Popover, Modal, Collapse, message, Tooltip, Badge } from 'antd';
import {MoreOutlined, CheckOutlined, CloseOutlined, PlusCircleOutlined, CaretRightOutlined, DeleteFilled} from "@ant-design/icons";
import type { ColumnsType, TableProps } from 'antd/es/table';

const { TextArea } = Input;
const { Panel } = Collapse;
const { Text } = Typography;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = inputType === 'number' ? <InputNumber maxLength={61}/> : <Input maxLength={61}/>;
  return (
    <td {...restProps}>
      {editing ? (
        (record['splitPart'] === undefined || (record['splitPart'] === true && title === 'Values'))?(
        <Form.Item name={dataIndex} style={{margin: 0}}
          rules={[
            { required: (title == 'Eligibility Criteria'), message: `Please Input ${title}!` },
            { type: 'string' },
            { max: 60 }
          ]}>
          {inputNode}
        </Form.Item>
        ):(
          children
        )
      ) : (
        children
      )}
    </td>
  );
};

const chars = [
  'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
  'AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN','AO','AP','AQ','AR','AS','AT','AU','AV','AW','AX','AY','AZ',
  'BA','BB','BC','BD','BE','BF','BG','BH','BI','BJ','BK','BL','BM','BN','BO','BP','BQ','BR','BS','BT','BU','BV','BW','BX','BY','BZ',
  'CA','CB','CC','CD','CE','CF','CG','CH','CI','CJ','CK','CL','CM','CN','CO','CP','CQ','CR','CS','CT','CU','CV','CW','CX','CY','CZ',
  'DA','DB','DC','DD','DE','DF','DG','DH','DI','DJ','DK','DL','DM','DN','DO','DP','DQ','DR','DS','DT','DU','DV','DW','DX','DY','DZ',
  'EA','EB','EC','ED','EE','EF','EG','EH','EI','EJ','EK','EL','EM','EN','EO','EP','EQ','ER','ES','ET','EU','EV','EW','EX','EY','EZ',
  'FA','FB','FC','FD','FE','FF','FG','FH','FI','FJ','FK','FL','FM','FN','FO','FP','FQ','FR','FS','FT','FU','FV','FW','FX','FY','FZ',
  'GA','GB','GC','GD','GE','GF','GG','GH','GI','GJ','GK','GL','GM','GN','GO','GP','GQ','GR','GS','GT','GU','GV','GW','GX','GY','GZ',
  'HA','HB','HC','HD','HE','HF','HG','HH','HI','HJ','HK','HL','HM','HN','HO','HP','HQ','HR','HS','HT','HU','HV','HW','HX','HY','HZ',
]

const initialEditable = {}
const EditTable = (props) => {
  // console.log(props)

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [manuallyAdd, setManuallyAdd] = useState(false);
  const [conOrExcpKey, setConOrExcpKey] = useState();
  const [conOrExcpContent, setConOrExcpContent] = useState();
  const [conOrExp, setConOrExp] = useState('');
  const [total, setTotal] = useState(0)
  const [visible, setVisible] = useState(false);
  const [activeKey, setActiveKey] = useState('')
  const [editable, setEditable] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { ...initialEditable }
  );
  const [viewOnly, setViewOnly] = useState(false)
  const [criteriaContent, setCriteriaContent] = useState('');
  const [criteriaValue, setCriteriaValue] = useState('');
  const [criteriaTimeframe, setCriteriaTimeframe] = useState('');
  const [criteriaDetail, setCriteriaDetail] = useState('');

  useEffect(() => {
    if(props.viewOnly != undefined){
      setViewOnly(props.viewOnly)
    }
  },[props.viewOnly]);

  function formatValue(item){
      var tempStr

            if (item.lower == 0 && item.upper != 0) {
                tempStr = "< "+ Number(item.upper?.toString().match(/^\d+(?:\.\d{0,2})?/))+ " " + item.units
            } else if (item.lower != 0 && (item.upper == 0 || item.upper == null)) {
                tempStr = "> "+ Number(item.lower?.toString().match(/^\d+(?:\.\d{0,2})?/))+ " " + item.units
            } else if (item.lower != 0 && item.upper != 0) {
                tempStr = Number(item.lower.toString().match(/^\d+(?:\.\d{0,2})?/))+ " - " + Number(item.upper?.toString().match(/^\d+(?:\.\d{0,2})?/)) + " " + item.units
            } else {
                tempStr = '-'
            }
      return tempStr
    }

  useEffect(() => {
    let newList = []
    props.data.map((item, id) =>{
      if(item['splitPart'] === undefined){
      // this method is called during load of both complete trial and IE builder and edit in IE builder
        if(!('Values' in item && item['Values']!=null)){
            item['Values'] = formatValue(item)
        }
        newList.push(item)
      }

      if(item['Children']&&item['Children'].length > 0 ){

        item['Children']=item['Children'].filter((iten)=>{
          return iten['Eligibility Criteria']!==''&&iten['Timeframe']!==''&&iten['Values']!==''
        })

        item['Children'].forEach((subRecord,idx) => {
          let iten = item['Key'] + subRecord['Key']
          console.log("name value",{
           [iten + 'eligibilitycriteria']: subRecord['Eligibility Criteria'],
           [iten + 'timeframe']:subRecord['Timeframe'],
           [iten + 'values']:subRecord['Values']
         });
         form.setFieldsValue({
          [iten + 'eligibilitycriteria']: subRecord['Eligibility Criteria'],
          [iten + 'timeframe']:subRecord['Timeframe'],
          [iten + 'values']:subRecord['Values']
        });
       })
      }

      if(item['Condition Or Exception'] !== undefined && item['Condition Or Exception'] !== ''){
        newList.push({
          'Key': item.Key+'C',
          'Eligibility Criteria': item['Eligibility Criteria'] + ' [Condition/Exception]',
          'Values': item['Condition Or Exception'],
          'splitPart': true,
          'MainKey': item.Key
        })
      }
    })
    if(props.data.length > 0){
      setTotal(Number(props.data[props.data.length-1].Key))
    }

    setData(newList)
  }, [props.data])

  useEffect(() => {
    setActiveKey(props.defaultActiveKey)
  }, [props.defaultActiveKey])


  const handleSubCriteraInputChange = (key, e, record?, idx?, header?, name?) => {

    let tmpData = data.slice(0)
    const targetRecord = tmpData.find(e=> e.Key==record.Key)
    targetRecord.Children[idx][key]=e.target.value
    form.setFieldsValue({
      name: e.target.value,
    });
  }

  const isEditing = (record) => record.Key == editingKey;

  const edit = (record) => {
    if(viewOnly) return;
    if (record['Eligibility Criteria'] !== ''|| record['Values'] !== '' ||record['Timeframe'] !== '') {
      // delete the empty criteria in editing when start to edit the other criteria
      let tempData = JSON.parse(JSON.stringify(data))
      tempData = tempData.filter((item)=>{
        return item['Eligibility Criteria'] !== ''|| item['Values'] !== '' ||item['Timeframe'] !== ''
      })
      setData(tempData);
    }
    form.setFieldsValue({
      "Eligibility Criteria": '',
      Values: '',
      Timeframe: '',
      ...record,
      Frequency: (Math.round(record.Frequency * 10000) / 100).toString(),
    });
    setEditingKey(record.Key);
    setManuallyAdd(false)
  };

  const cancel = (record) => {
    setEditingKey('');
    setManuallyAdd(false);
    // For new empty criteria, cancel editing and delete it
    if(record['Eligibility Criteria'] === '' && record['Values'] === '' && record['Timeframe'] === '' ) {
      handleDelete(record)
    }else{
      setTotal(total - 1)
    }
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      // row.Frequency = parseFloat(row.Frequency) * 100  / 10000;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.Key);
      if (index > -1) {
        var domian;
        var realIndex;
        var newFileds;
        if(newData[index]['splitPart']){
          realIndex = index - 1
          domian = newData[index - 1]
          newFileds = {'Condition Or Exception': row.Values}
        } else {
          realIndex = index
          domian = newData[index]
          newFileds = Object.assign(row)
        }
        newData.splice(realIndex, 1, { ...domian, ...newFileds });
        const tempData = newData.map((item, id) => {
          return item
        })
        setData(tempData);
        setEditingKey('');
        setManuallyAdd(false);
        const temp = []
        tempData.map((it, id) =>{
          if(it['splitPart'] === undefined){
            temp.push(it)
          }
        })
        props.updateCriteria(temp, props.tableIndex)
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
        setManuallyAdd(false);
        const temp = []
        newData.map((it, id) =>{
          if(it['splitPart'] === undefined){
            temp.push(it)
          }
        })
        props.updateCriteria(temp, props.tableIndex)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleAdd = async (e) => {
    e.stopPropagation();
    // If click Add button before finishing edition, error message will show
    // if(editingKey !== '') {
    //   message.error('Please finish your edition before adding new items')
    //   return
    // }
    try{
      const row = await form.validateFields();
      const newData = {
        Key: (total + 1) + ' ',
        "Eligibility Criteria": '',
        Values: '',
        Timeframe: '',
        Frequency: 0,
      }
      setTotal(total + 1)
      setData([...data, newData])
      edit(newData)
      setManuallyAdd(true)
    } catch (errInfo) {
    console.error(" Error while adding the criteria, Error:", errInfo)
    }
  };

  const handleDelete = async (record) => {
    const newData = [...data];
    const index = newData.indexOf(record);
    setTotal(total - 1)

      if (index > -1) {
        newData.splice(index, 1);
        const tempData = newData.map((item, id) => {
          return item
        })
        setData(tempData);

        const temp = []
        tempData.map((it, id) =>{
          if(it['splitPart'] === undefined){
            temp.push(it)
          }
        })
        props.updateCriteria(temp, props.tableIndex)
      }
  };

  const deleteConditionOrException = async (record) => {
    const newData = [...data];
    const index = newData.findIndex((item) => record.Key === item.Key);
    var realIndex = index - 1
    var domian = newData[realIndex]
    var row = {'Condition Or Exception': ''}

    newData.splice(realIndex, 1, { ...domian, ...row });
    newData.splice(index, 1);
    const tempData = newData.map((item, id) => {
      return item
    })
    setData(tempData);
    setEditingKey('');
    const temp = []
    tempData.map((it, id) =>{
      if(it['splitPart'] === undefined){
        temp.push(it)
      }
    })
    props.updateCriteria(temp, props.tableIndex)
  }

  const handleAddSubCriteria = async(record) => {
    try{
      console.log('Add SubCriteria')
      const row = await form.validateFields();
      const tmpData = data.slice(0)
      const targetRecord = tmpData.find(e=> e.Key==record.Key)

      if (!targetRecord.Children) {
        let newTempData = {
          "Key": "A",
          "Eligibility Criteria": "",
          "Timeframe": "",
          "Values": "",
        }
        targetRecord.Children = [newTempData]
        setEditable({
          [targetRecord.Key + 0]:true
        })
        edit(newTempData)
      } else {
          let newTempData = {
            "Key": chars[targetRecord.Children.length],
            "Eligibility Criteria": "",
            "Timeframe": "",
            "Values": "",
          }
          targetRecord.Children.push(newTempData)
          setEditable({
          [targetRecord.Key + targetRecord.Children.length-1]:true
          })
        // edit(newTempData)
      }
      setData(tmpData)
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }

  const deleteSubCriteria = (record, idx, name) => {
    const targerval = {
      [name + 'eligibilitycriteria']: '',
      [name + 'timeframe']:'',
      [name + 'values']:''
    }
    form.setFieldsValue(targerval);

    const tmpData = data.slice(0)
    const targetRecord = tmpData.find(e=> e.Key==record.Key)
    targetRecord.Children.splice(idx, 1)
    targetRecord.Children = targetRecord.Children.map((item,index) =>{
      item.Key = chars[index]
      return item
    })

    setData(tmpData)

    const temp = []
    tmpData.map((it, id) =>{
      if(it['splitPart'] === undefined){
        temp.push(it)
      }
    })
    props.updateCriteria(temp, props.tableIndex)
  }

  const saveSubCriteria = async(record,subRecord,idx) => {
    try{
      console.log('save SubCriteria')
      const row = await form.validateFields();

      setEditable({
        [record.Key + idx]:false
      })
      const tmpData = data.slice(0)
      const targetRecord = tmpData.find(e=> e.Key==record.Key)
      targetRecord.Children[idx]= subRecord
      setData(tmpData)

      const temp = []
      tmpData.map((it, id) =>{
        if(it['splitPart'] === undefined){
          temp.push(it)
        }
      })
      props.updateCriteria(temp, props.tableIndex)
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  }

  function callback(key) {
    setActiveKey(key)
  }

  // the button at the right of panel header
  const genExtra = () => (
    !viewOnly && <PlusCircleOutlined className="right-icon" style={{marginTop: '-18px'}} onClick={(e)=>handleAdd(e)}/>
   )

  const panelHeaderSection = (header, count) => {
    return (
        <div className="{`${props.collapsible  ? '' : 'none-click'} trial-panelHeader`}">
          <div className="header-section">
            <span>{header}({count == 0 ? 0 : count})</span>
          </div>
        </div>
    );
  };

  const columns = [
    {
      title: "Eligibility Criteria",
      dataIndex: "Eligibility Criteria",
      width: '25%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return record.splitPart ? (
          <Typography.Link style={{cursor: 'default'}}>
          <div><span style={{fontSize: '14px'}}>{record['Eligibility Criteria']}</span></div>
          </Typography.Link>
        ) : (editable ? (
            <Input disabled={true} value={record["Eligibility Criteria"]}/>
        ) : (
          <>
          <Tooltip title={'Negated Entity'} placement="topLeft">
            <span style={  { background: record.negation ? '#ca4a04' : '', display: 'inline-block', padding: '7px', borderRadius: '50%' }}></span> 
            </Tooltip>
            <Typography.Link onClick={() => edit(record)} style={{display: 'inline-block',  marginLeft: '10px'}}>
            <Tooltip title={record['Eligibility Criteria']} placement="topLeft">
              <div className="criteria-ellipsis"> <span style={{fontSize: '14px'}}>
            
              {record['Eligibility Criteria']}</span></div>
            </Tooltip>
          </Typography.Link>
          </>
        ))
      }
    },{
      title: "Frequency",
      dataIndex: "Frequency",
      width: '25%',
      editable: false,
      render: (_, record) => {
        const editable = isEditing(record);
        return record.splitPart ? (
          <Typography.Link style={{cursor: 'default'}}>
          <div><span style={{fontSize: '14px'}}>{record['Frequency'] + "%"}</span></div>
          </Typography.Link>
        ) : (editable ? (
          <Input disabled={true} value={Math.round(record['Frequency'] * 10000) / 100 + "%"} />
        ) : (
          <Typography.Link onClick={() => edit(record)}>
            <Tooltip title={Math.round(record['Frequency'] * 10000) / 100 + "%"} placement="topLeft">
              <div className="criteria-ellipsis"><span style={{fontSize: '14px'}}>{Math.round(record['Frequency'] * 10000) / 100 + "%"}</span></div>
            </Tooltip>
          </Typography.Link>
        ))
      }
    },{
      title: 'Values',
      dataIndex: 'Values',
      width: '25%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
            <Input value={record["Values"]}/>
        ) : (
          <Typography.Link onClick={() => edit(record)}>
            <Tooltip title={record.Values} placement="topLeft">
              <div className="criteria-ellipsis"><span style={{fontSize: '14px'}}>{record.Values}</span></div>
            </Tooltip>
          </Typography.Link>
        )
      }
    }, {
      title: 'Timeframe',
      dataIndex: 'Timeframe',
      width: '25%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return record.splitPart ? (
          <div><span style={{fontSize: '14px'}}>{record.Timeframe}</span></div>
        ) : ( editable ? (
            <Input value={record["Timeframe"]}/>
        ) : (
          <Typography.Link onClick={() => edit(record)}>
            <Tooltip title={record.Timeframe} placement="topLeft">
              <div className="criteria-ellipsis"><span style={{fontSize: '14px'}}>{record.Timeframe}</span></div>
            </Tooltip>
          </Typography.Link>
        ))
      }
    }, {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return !viewOnly && (editable ? (
          <span style={{float:'right'}}>
            <CheckOutlined onClick={() => save(record.Key)}/> &nbsp;&nbsp;
            <CloseOutlined onClick={() => cancel(record)}/>
          </span>
        ) : (record.splitPart ? (
          <CloseOutlined style={{ color: 'red', float:'right'}} onClick={() =>deleteConditionOrException(record)}/>
        ) : (
          <span style={{float:'right',width: 67}}>
            <span style={{color:'#CA4A04',marginRight:8,cursor:'pointer'}} onClick={() => showMoreCriteriaDetails(record)}>Details</span>
            <DeleteFilled style={{color:'#BABABA',verticalAlign:'text-bottom'}} onClick={() => handleDelete(record)}/>
          </span>
        )))
      }
    }
  ];

  const showMoreCriteriaDetails = (record) => {
    setCriteriaContent(record['Eligibility Criteria'])
    setCriteriaValue(record['Values'])
    setCriteriaTimeframe(record['Timeframe'])
    setCriteriaDetail(record['Detail'])
    setVisible(true)
  }

  const mergedColumns = columns.map((col) => {
    if(col.title === "Eligibility Criteria" && manuallyAdd) {
      col.editable = true
    } else if (col.title === "Eligibility Criteria"&& !manuallyAdd){
      col.editable = false
    }
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      })
    };
  });

  const handleInputChange = (e) =>{
    setConOrExp(e.target.value)
  }

  const handleOk = async () => {
    setVisible(false)

    const newData = [...data]
    const index = newData.findIndex((item) => conOrExcpKey === item.Key)

    if(index > -1){
      const oldItem = newData[index];
      const newItem = newData[index];
      newItem['Condition Or Exception'] = conOrExp

      newData.splice(index, 1, { ...oldItem, ...newItem });
      setData(newData)
      setConOrExp(null)
    }

    const temp = []
    newData.map((it, id) =>{
      if(it['splitPart'] === undefined){
        temp.push(it)
      }
    })
    props.updateCriteria(temp, props.tableIndex)
  }

  const handleCancel = () =>{
    setVisible(false)
    // setConOrExp(null)
  }

  interface DataType {
    key: React.Key;
    title: string;
    amendment: string;
    before_text: string;
    after_text: string;
  }

  const criteriaDetailColumns: ColumnsType<DataType> = [
    {
      title: 'Study Title',
      dataIndex: 'study_title',
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.title.length - b.title.length,
    },
    {
      title: 'Amendment Summary',
      dataIndex: 'amendment_summary',
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.amendment.length - b.amendment.length,
    },
    {
      title: 'Before Text',
      dataIndex: 'before_text',
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.before_text.length - b.before_text.length,
    },
    {
      title: 'After Text',
      dataIndex: 'after_text',
      // defaultSortOrder: 'descend',
      sorter: (a, b) => a.after_text.length - b.after_text.length,
    }
  ];

  const criteriaDetailData = criteriaDetail&&criteriaDetail['amendment_details']&&criteriaDetail['amendment_details'].map((item, index) => {
    const tempItem = JSON.parse(JSON.stringify(item))
    return Object.assign(tempItem, {key:(index + 1) + ''})
  })

  const onCriteriaDetailTableChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <Form form={form} component={false}>
      <Collapse activeKey={activeKey} onChange={callback} expandIconPosition="left"
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
          <Panel header={panelHeaderSection(props.panelHeader, props.collapsible ? 0 : data.length)}
            key={props.tableIndex} forceRender={false} extra={genExtra()}>
      <Table pagination={false} showHeader={false} rowKey={record => record.Key}
        components={{ body: { cell: EditableCell } }} locale={{emptyText: 'No Data'}}
            dataSource={data} columns={mergedColumns} rowClassName="editable-row"
            expandedRowKeys={data.map(item => item.Key)}
    //          expandable={{
    //            expandedRowRender: record => {
    //              return (
    //                <>
    //                  {
    //                    record.Children && record.Children.length > 0 && record.Children.map((subRecord,idx) => {
    //                      return(
    //                        <div className="sub-criteria-wrapper" key={idx}>
    //                          <div className="serial-number">
    //                           <span>{`${record.Key}${subRecord['Key']}`}</span>
    //                          </div>
    //                          {editable[record.Key+idx] ? (
    //                            <>
    //                             <Form.Item
    //                               name={`${record.Key}${subRecord['Key']}`+'eligibilitycriteria'}
    //                               style={{margin: 0,width:'28%'}}
    //                               rules={[
    //                                 { required: true, message: `Please Input Eligibility Criteria!` },
    //                                 { max: 60 },
    //                                 { type: 'string' }
    //                               ]}
    //                             >
    //                               <Input  id={`${record.Key}${subRecord['Key']}`+'eligibilitycriteria'} value={subRecord['Eligibility Criteria']} onChange={ (e)=>handleSubCriteraInputChange('Eligibility Criteria',e,record,idx,props.header, `${record.Key}${subRecord['Key']}`+'eligibilitycriteria')}></Input>
    //                             </Form.Item>
    //                             <Form.Item
    //                               name={`${record.Key}${subRecord['Key']}`+'values'} style={{margin: 0,width:'28%'}}
    //                               rules={[
    //                                 { max: 60 },
    //                                 { type: 'string' }
    //                               ]}
    //                             >
    //                               <Input  id={`${record.Key}${subRecord['Key']}`+'values'} value={subRecord['Values']} onChange={(e) => handleSubCriteraInputChange('Values', e, record, idx, props.header, `${record.Key}${subRecord['Key']}`+'values')} />
    //                             </Form.Item>
    //                             <Form.Item
    //                               name={`${record.Key}${subRecord['Key']}`+'timeframe'} style={{margin: 0,width:'28%'}}
    //                               rules={[
    //                                 { max: 60 },
    //                                 { type: 'string' }
    //                               ]}
    //                              >
    //                               <Input  id={`${record.Key}${subRecord['Key']}`+'timeframe'} value={subRecord['Timeframe']} onChange={ (e)=>handleSubCriteraInputChange('Timeframe',e,record,idx,props.header, `${record.Key}${subRecord['Key']}`+'timeframe')} />
    //                             </Form.Item>
    //                            </>
    //                          )
    //                           : (
    //                             <>
    //                               <Tooltip title={subRecord['Eligibility Criteria']}>
    //                               <div className="sub-row-non-editable criteria-ellipsis" onClick={()=>setEditable({[record.Key+idx]:true})} >{subRecord['Eligibility Criteria']}</div>
    //                             </Tooltip>
    //                             <Tooltip title={subRecord['Values']}>
    //                               <div className="sub-row-non-editable criteria-ellipsis" onClick={()=>setEditable({[record.Key+idx]:true})}>{subRecord['Values']}</div>
    //                             </Tooltip>
    //                             <Tooltip title={subRecord['Timeframe']}>
    //                               <div className="sub-row-non-editable criteria-ellipsis" onClick={()=>setEditable({[record.Key+idx]:true})}>{subRecord['Timeframe']}</div>
    //                             </Tooltip>
    //                           </>
    //                           )}
    //                          <div className="actions">
    //                           {!viewOnly ? (<>
    //                           {editable[record.Key+idx]&& <CheckOutlined style={{ color: 'green' }} onClick={()=>saveSubCriteria(record, subRecord,idx)}/>}
    //                           <CloseOutlined style={{ color: 'red' }} onClick={() =>deleteSubCriteria(record, idx, `${record.Key}${subRecord['Key']}`)}/>
    //                           </>):null}
    //                         </div>
    //                     </div>
    //                      )
    //                    })
    //                  }
    //                </>
    //              )
    //             },
    //             rowExpandable: record => record.Children &&record.Children.length > 0,
    // }}
          />
      </Panel>
      </Collapse>
        {/* <Modal visible={visible} title="Add / Edit Condition or Exception" onOk={handleOk} onCancel={handleCancel}
          footer={[<Button key="submit" type="primary" onClick={handleOk}>Submit</Button>]}>
          <p>Add / Edit condition or exception for <b>{conOrExcpContent}</b></p>
          <span>Condition or Exception</span>
          <TextArea value={conOrExp} onChange={(e) => handleInputChange(e)} autoSize={{ minRows: 3, maxRows: 5 }}/>
        </Modal> */}
        <Modal visible={visible} title="Amendment Rate in Historical Trials" onCancel={handleCancel} footer={null} wrapClassName="criteriaDetailModal">
          <div className="criterion-value">
            <div className="item title">
              <div className="top">Eligibility Criteria</div>
              <Text
              className="bottom"
              ellipsis={true}
              >
                {criteriaContent}
              </Text>
            </div>
            <div className="item value">
              <div className="top">Value</div>
              <div className="bottom">{criteriaValue}</div>
            </div>
            <div className="item timeframe">
              <div className="top">Timeframe</div>
              <div className="bottom">{criteriaTimeframe}</div>
            </div>
          </div>
          <div className="criterion-rate">
            <span className="frequency">Historical Frequency of Amending Criterion: <b>{criteriaDetail&&criteriaDetail['par']?criteriaDetail['par']:'0%'}</b></span>
            <span className="screen-failure">Historical Screen Failure Rate associated with Criterion: <b>{criteriaDetail&&criteriaDetail['sfr']?criteriaDetail['sfr']:'0%'}</b></span>
          </div>
          <div className="criteria-table">
            <Table columns={criteriaDetailColumns} dataSource={criteriaDetailData} onChange={onCriteriaDetailTableChange} pagination={false} />
          </div>

        </Modal>
    </Form>
  );
};

export default EditTable;