/* eslint-disable */
import React, { useState, useReducer, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";
import {Table, Collapse, Slider, Dropdown,Menu, Modal, Row, Col, InputNumber, Tooltip, Button, Spin, message, Input, Badge} from "antd";
import {PlusCircleOutlined, ArrowRightOutlined, CloseOutlined, EditFilled, MinusOutlined, PlusOutlined, DownOutlined, DownloadOutlined, HistoryOutlined, FileTextFilled, RightOutlined, LeftOutlined, SearchOutlined, CheckOutlined, CarryOutFilled, DeleteOutlined} from "@ant-design/icons";
import {getStandardEvents, update_patient_burden} from "../../utils/ajax-proxy";
import ReactECharts from 'echarts-for-react';
import "./index.scss";
import NewEventList from '../NewEventList';
import FileSaver from 'file-saver';
import CriteriaOption from "../CriteriaOption/index.js";
import SelectableTable from "../SelectableTable/index.js";
import AddActivitySoa from "../AddActivitySoa";
import ActivitiesByModality from '../ActivitiesByModality'
import { getEmail } from "../../utils/auth";
import SoaAtGlance from '../../components/SoaAtGlance';
import telehealth_icon from '../../assets/fax.png';
import patient_at_home_icon from '../../assets/home.png';
import hospital_icon from '../../assets/hospital.png';
import mobile_health_icon from '../../assets/mobile.png';
import pharmacy_icon from '../../assets/medical.png';
import local_lab_icon from '../../assets/local.png';
import ip_direct_icon from '../../assets/ipdirect.png';
import e_consent_icon from '../../assets/econsent.png';

const { Panel } = Collapse;

const iChartColors = ['#DADADA', '#DADADA', '#DADADA', '#DADADA', '#DADADA']
const aChartColors = ['#d04a02', '#ed7738', '#ed9d72', '#f5b795', '#f5ddcf']
const burdenColors = {active: '#E53500', inactive: '#DADADA'}
const aLabelColors = {GOOD: '#00A947', FAIR: '#0084A9', POOR: '#c33232'}
const iLabelColors = {GOOD: burdenColors.inactive, FAIR: burdenColors.inactive, POOR: burdenColors.inactive}

const CATEGORY_LABS = 'Labs';
const CATEGORY_PHYSICAL_EXAMINATION = 'Physical Examination';
const CATEGORY_PROCEDURES = 'Procedures';
const CATEGORY_QUESTIONNAIRES = 'Questionnaires';
const CATEGORY_STUDY_PROCEDURES = 'Study Procedures';

// Sets default min and max frequency for Criteria Frequency slider
const FREQUENCY_DEFAULT_MIN = 20
const FREQUENCY_DEFAULT_MAX = 100

// Sets min and max ranges for Criteria Frequency slider
const FREQUENCY_RANGE_MIN = 0
const FREQUENCY_RANGE_MAX = 100

const defaultCostValue = [
  {value: 0, name: CATEGORY_LABS},
  {value: 0, name: CATEGORY_PHYSICAL_EXAMINATION},
  {value: 0, name: CATEGORY_PROCEDURES},
  {value: 0, name: CATEGORY_QUESTIONNAIRES},
  {value: 0, name: CATEGORY_STUDY_PROCEDURES}
]

const defaultBurdenValue = [0, 0, 0, 0, 0, 0, 0, 0, 0]

const initialNumber = {
  visitNumber: 0,
  weekNumber: 0
}

const initEventCriteria = {
  'TotalCost': '0',
  'CostRate': '',
  'CostData': [],
  'BurdenData': [],
  'BurdenXAxis': [],
  'Finished': false
}

const visitDimensionalScore = [
  {Dimension: 'AnxietyInducing', Value: 5},
  {Dimension: 'HospitalDependent', Value: 25},
  {Dimension: 'PhysicallyInvasive', Value: 10},
  {Dimension: 'BloodDraw', Value: 15},
  {Dimension: 'Sedation', Value: 35},
  {Dimension: 'Injection', Value: 15},
  {Dimension: 'Urine', Value: 5},
  {Dimension: 'RequireFasting', Value: 7},
  {Dimension: 'LongerThanTwoHours', Value: 20},
  {Dimension: 'Questionnaire', Value: 5}
]
const initialSoa = { 
  soaAddedLabs: [],
  soaAddedExamination: [],
  soaAddedProcedures: [],
  soaAddedQuestionnaires: [],
  soaAddedStudyProcedures: [],
  soaOrgLabs: [],
  soaOrgExamination: [],
  soaOrgProcedures: [],
  soaOrgQuestionnaires: [],
  soaOrgStudyProcedures: [],
  soaFilteredLabs: [],
  soaFilteredExamination: [],
  soaFilteredQuestionnaires: [],
  soaFilteredProcedures: [],
  soaFilteredStudyProcedures: [],
  soaEndpoints : [],
  soaVisits: [],
  soaWeeks: [],
  isLoaded: false,
  event_numbers: {}
}


const ScheduleActivities = (props) => { 
    const endpoints = {
        "Primary Endpoints": props.newSoa.primary_endpoints,
        "Secondary Endpoints": props.newSoa.secondary_endpoints,
        "Tertiary Endpoints": props.newSoa.tertiary_endpoints,
      };
      const scenario = props.newSoa.scenarios && props.newSoa.scenarios.find(s=> s['ts_id'] == localStorage.getItem('ts_id'));  
      const eventsConfigure = scenario&&scenario['Schedule of Activities']
      const [hiddeTags, setHiddeTags] = useState(true)
      const [showConfigure, setShowConfigure] = useState(false)
      const [eventLib, setEventLib] = useState(6)
      const [eventPanel, setEventPanel] = useState("1")
      const [dismissEvent, setDismissEvent] = useState([])
      const [insightEvent, setInsightEvent] = useState(scenario&&scenario['soa_insights']? scenario['soa_insights']:[])
      const [activeCollapse, setActiveCollapse] = useState(['-1'])
      const [numbers, setNumbers] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { 
          visitNumber: eventsConfigure?.Visits || 0,
          ScreeningVisits: eventsConfigure?.ScreeningVisits || 0,
          TreatmentVisits: eventsConfigure?.TreatmentVisits || 0,
          FollowupVisits: eventsConfigure?.FollowupVisits || 0,
          weekNumber: eventsConfigure&&eventsConfigure.Weeks?eventsConfigure.Weeks[eventsConfigure.Weeks?.length-1]: 0
         }
      );

      const [visitNoteList, setVisitNoteList] = useState(eventsConfigure?.visitNoteList || []);
    
      const [editNumbers, setEditNumbers] = useState({
        screeningVisitsNumber: eventsConfigure?.ScreeningVisits || 0,
        treatmentVisitsNumber:  eventsConfigure?.TreatmentVisits || 0,
        followupVisitsNumber: eventsConfigure?.FollowupVisits || 0,
        weekNumber:eventsConfigure?.Weeks?eventsConfigure.Weeks[eventsConfigure.Weeks?.length-1]: 0
      });
      const [weeks, setWeeks] = useState([])
      const [visits, setVisits] = useState([])
      // SOA event frequency
      const [minV, setMinV] = useState(FREQUENCY_DEFAULT_MIN)
      const [maxV, setMaxV] = useState(FREQUENCY_DEFAULT_MAX)
      const [visibleSlider, setVisibleSlider] = useState(false)
      const [eventCriteria, setEventCriteria] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { ...initEventCriteria }
      );
    
      const [prevCategoryVisists, setPreviousCategoryVisits] = useState({});
 
      useEffect(() => {
        if(!eventsConfigure?.VisitTypes) return;
          setPreviousCategoryVisits({
            ScreeningVisits: eventsConfigure?.VisitTypes?.ScreeningVisits || 0,
            TreatmentVisits:  eventsConfigure?.VisitTypes?.TreatmentVisits || 0,
            FollowupVisits: eventsConfigure?.VisitTypes?.FollowupVisits || 0,
            weekNumber:eventsConfigure.Weeks?eventsConfigure.Weeks[eventsConfigure.Weeks?.length-1]: 0
          })
      }, [eventsConfigure?.VisitTypes])
      
      //Cost Per Patient Chart
      const [patientChartColor, setPatientChartColor] = useState(iChartColors)
      const [burdenChartColor, setBurdenChartColor] = useState(burdenColors.inactive)
      const [labelColors, setLabelColors] = useState(iLabelColors)
      const [costData, setCostData] = useState(defaultCostValue)
      const [costSubTitle, setCostSubTitle] = useState('')
      const [showPatientLabel, setShowPatientLabel] = useState(false)
      const [patientRate, setPatientRate] = useState('$0K\nGOOD')
    
      //Patient Burden Chart
      const [burdenData, setBurdenData] = useState(defaultBurdenValue)
      const [burdenSubTitle, setBurdenSubTitle] = useState('')
      const [burdenXAxis, setBurdenXAxis] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9'])
      const [showTooltip, setShowTooltip] = useState(false)
      const [submitType, setSubmitType] = useState(0)
      const [totalBurden, setTotalBurden] = useState(0)
      const [totalBurdenStatus, setTotalBurdenStatus] = useState('{GOOD|GOOD}')
    
      const [numberOfVisitsError, setNumberOfVisitsError] = useState("") 
      const [numberOfScreeningVisitsError, setNumberOfScreeningVisitsError] = useState("")
      const [numberOfTreatmentVisitsError, setNumberOfTreatmentVisitsError] = useState("")
      const [numberOfFollowupVisitsError, setNumberOfFollowupVisitsError] = useState("")
      const [numberOfWeeksError, setNumberOfWeeksError] = useState("")
    
      const [modalityOptions, setModalityOptions] = useState([])
      const [soeLibrarayPanelStatus, setSOELibraryPanelStatus] = useState(false)
      const soeInsightsToggleHandler = (status) => {
        setSOELibraryPanelStatus(status);
        setEventLib(!status ? 0 : 6)
      } 


       useEffect(()=> {
        if(!props.newSoa?.scenarios) return;
        if(props.newSoa.soaVisits?.length == 0 && props.newSoa.soaWeeks?.length == 0 && Object.keys(props.newSoa?.scenarios[0]['Schedule of Activities']).length == 0) {
          setShowConfigure(true)
        }
      }, [props.newSoa])  
    
      //Activities by Modality chart
      const[modalityChartData,setModalityChartData] = useState([])
      const modalityOption = {
        title:{
          show:false,
          text: 'Activities by Modality',
          x:'center',
          y:'top',
          textStyle: {
            fontSize: 16,
            fontWeight: 'bold',
            color: '#333'
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          },
          confine:false,
          position: ['100%', '-10%']
        },
        grid: {
          left: '10%',
          right: '4%',
          top: '15%',
          bottom: '6%',
          containLabel: true
      },
        xAxis: {
          name: 'Visit Number',
          nameLocation: "middle",
          type: 'category',
          data: visits,
          nameRotate: 0, nameGap: 20,
       },
       yAxis: {
          type: 'value'
       },
        series:modalityChartData
      };
    
      const modality_options_dict ={
          "eSource & Wearables": {
            "icon": mobile_health_icon,
            "color": '#FFBF00'
          },
          "Telemedicine": {
            "icon": telehealth_icon,
            "color": '#75C716'
          },
          "Home Health": {
            "icon": patient_at_home_icon,
            "color": '#C9DF00'
          },
          "Local Lab & Sample Collection": {
            "icon": local_lab_icon,
            "color": '#6A86FA'
          },
          "Local Clinic": {
            "icon": pharmacy_icon,
            "color": '#FF025F'
          },
          "IP Direct Shipment": {
            "icon": ip_direct_icon,
            "color": '#83B1FA'
          },
          "eConsent": {
            "icon": e_consent_icon,
            "color": '#00A760'
          },
          "Traditional Site": {
            "icon": hospital_icon,
            "color":'#CB0047'
          },
          "": {
            "icon":'',
            "color":'white'
          }
        }
    
      //Event Libs
      //Original data from backend
      const [orgLabs, setOrgLabs] = useState([])
      const [orgExamination, setOrgExamination] = useState([])
      const [orgProcedures, setOrgProcedures] = useState([])
      const [orgQuestionnaires, setOrgQuestionnaires] = useState([])
      const [orgStudyProcedures, setOrgStudyProcedures] = useState([])
    
      //Filtered data based on the frequency and original data
      let [filteredLabs, setFilteredLabs] = useState([])
      let [filteredExamination, setFilteredExamination] = useState([])
      let [filteredQuestionnaires, setFilteredQuestionnaires] = useState([])
      let [filteredProcedures, setFilteredProcedures] = useState([])
      let [filteredStudyProcedures, setFilteredStudyProcedures] = useState([])
    
      //Addedd data
      let [addedLabs, setAddedLabs] = useState([])
      let [addedExamination, setAddedExamination] = useState([])
      let [addedQuestionnaires, setAddedQuestionnaires] = useState([])
      let [addedProcedures, setAddedProcedures] = useState([])
      let [addedStudyProcedures, setAddedStudyProcedures] = useState([])
      const [isUpdateMode, setIsUpdateMode] = useState(false);
      const [resetWeeks, setResetWeeks] = useState(true)
    
      const onStepVisit = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
        setNumbers({
          ['visitNumber']: value
        });
      }
    
      const onStepScreeningVisit = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
        setNumbers({
          ['ScreeningVisits']: value
        });
      }
    
      const onStepTreatmentVisit = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
        setNumbers({
          ['TreatmentVisits']: value
        });
      }
    
      const onStepFollowUpVisit = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
        setNumbers({
          ['FollowupVisits']: value
        });
      }
    
      const onStepWeek = (value: number, info: { offset: number, type: 'up' | 'down' }) => {
        setNumbers({
          ['weekNumber']: value
        });
      }
    
      const validateVisit = (rawValue) => {
        let value = parseInt(rawValue)
        let errorMsg = ""
        if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Visits is required"}
        else if(value>100 || value<1){errorMsg = "Number of Visits should be between 1 to 100"}
        setNumberOfVisitsError(errorMsg)
        setNumbers({
          ['visitNumber']: value
        });
        return errorMsg == ""
      }
    
      const validateScreeningVisit = (rawValue) => {
        let value = parseInt(rawValue)
        let errorMsg = ""
        if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Treatment Visits is required"}
        else if(value>100 || value<1){errorMsg = "Number of Screening Visits should be between 1 to 100"}
        setNumberOfScreeningVisitsError(errorMsg)
        setNumbers({
          ['ScreeningVisits']: value
        });
        return errorMsg == ""
      }
    
      const validateTreatmentVisit = (rawValue) => {
        let value = parseInt(rawValue)
        let errorMsg = ""
        if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Screening Visits is required"}
        else if(value>100 || value<1){errorMsg = "Number of Treatment Visits should be between 1 to 100"}
        setNumberOfTreatmentVisitsError(errorMsg)
        setNumbers({
          ['TreatmentVisits']: value
        });
        return errorMsg == ""
      }
    
      const validateFollowUpVisit = (rawValue) => {
        let value = parseInt(rawValue)
        let errorMsg = ""
        if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Follow up Visits is required"}
        else if(value>100 || value<1){errorMsg = "Number of Follow-Up Visits should be between 1 to 100"}
        setNumberOfFollowupVisitsError(errorMsg)
        setNumbers({
          ['FollowupVisits']: value
        });
        return errorMsg == ""
      }
    
      const validateWeek = (rawValue) => {
        let value = parseInt(rawValue)
        let errorMsg = ""
        if(rawValue===null||rawValue===NaN||(!rawValue && value!=0)){errorMsg = "Number of Weeks is required"}
        else if(value>1000 || value<1){errorMsg = "Number of Weeks should be between 1 to 1000"}
        setNumberOfWeeksError(errorMsg)
        setNumbers({
          ['weekNumber']: value
        });
        return errorMsg == ""
      }
    
      const handleVisitNoteList = (Obj) => { 
        props.updateSoaActivities({ soaVisitNotesList: Obj})
        let tempVisitNoteList = visitNoteList ? visitNoteList : [];
        let newData = [...tempVisitNoteList]
        if( Obj.visit_notes.trim().length == 0) {
          newData = tempVisitNoteList.filter((value, index) => Obj.visit !== value.visit); 
          props.updateSoaActivities({ soaVisitNotesList: newData})
          return;
        }
        const index = tempVisitNoteList.findIndex((value, index) => Obj.visit == value.visit);
        if(index > -1) {
          newData.splice(index, 1, Obj) 
          props.updateSoaActivities({ soaVisitNotesList: newData})
        } else {
          newData.push(Obj)
        }
        props.updateSoaActivities({ soaVisitNotesList: newData})
      }


  useEffect(() => {
    if(Object.keys(props.newSoa.event_numbers).length > 0) {
      const {ScreeningVisits, TreatmentVisits, FollowupVisits, weekNumber } =  props.newSoa.event_numbers 
      setNumbers({
        ScreeningVisits: ScreeningVisits,
        TreatmentVisits: TreatmentVisits,
        FollowupVisits: FollowupVisits,
        weekNumber: weekNumber
      })
      setEditNumbers({
        screeningVisitsNumber: ScreeningVisits,
        treatmentVisitsNumber: TreatmentVisits,
        followupVisitsNumber: FollowupVisits,
        weekNumber:weekNumber
      })
    }
  }, [props.newSoa?.event_numbers])
      
  useEffect(() =>{
    if(addedLabs.length>0 || addedExamination.length>0 || addedProcedures.length>0 ||addedQuestionnaires.length>0 ||addedStudyProcedures.length>0 )
    props.setDisableSaveFinish(false)
  }) 
      const sumOfArr = (arr) => {
        if(Array.isArray(arr)){
           let  arrSum = 0
           arr.forEach((item,index) => {
            arrSum+=item
           })
           return arrSum
        }
    }
    
      const isEventsAdded = (events) => {
        return (
        (events[CATEGORY_LABS]!=undefined && events[CATEGORY_LABS].entities.length > 0)
        || (events[CATEGORY_PHYSICAL_EXAMINATION]!=undefined && events[CATEGORY_PHYSICAL_EXAMINATION].entities.length > 0)
        || (events[CATEGORY_PROCEDURES]!=undefined && events[CATEGORY_PROCEDURES].entities.length > 0)
        || (events[CATEGORY_QUESTIONNAIRES]!=undefined && events[CATEGORY_QUESTIONNAIRES].entities.length > 0)
        || (events[CATEGORY_STUDY_PROCEDURES]!=undefined && events[CATEGORY_STUDY_PROCEDURES].entities.length > 0)
        )
      }
    
      const getCostData = (costData) => {
        if(costData!=undefined && costData.length < 1){
            return [
                    {
                        "name": CATEGORY_STUDY_PROCEDURES,
                        "value": 0
                    },
                    {
                        "name": CATEGORY_PROCEDURES,
                        "value": 0
                    },
                    {
                        "name": CATEGORY_QUESTIONNAIRES,
                        "value": 0
                    },
                    {
                        "name": CATEGORY_PHYSICAL_EXAMINATION,
                        "value": 0
                    },
                    {
                        "name": CATEGORY_LABS,
                        "value": 0
                    }
                ]
        }else{
            return costData
        }
      }
    
        const getModalityOptions = (modalityOpts) =>{
            let finalModalityOptions = []
            if(modalityOpts != null && modalityOpts != undefined){
                modalityOpts.forEach((modality) => {
                    if(modality.name in modality_options_dict){
                      finalModalityOptions.push({
                            key: modality.key,
                            name:  modality.name,
                            icon: modality_options_dict[modality.name].icon,
                            color: modality_options_dict[modality.name].color
                      })
                    }else{
                      finalModalityOptions.push({
                            key: modality.key,
                            name:  modality.name,
                            icon: '',
                            color: 'white'
                      })
                    }
                });
                finalModalityOptions.push({
                    key:"",
                    name:  "",
                    icon:'',
                    color:'white'
                })
                return finalModalityOptions;
            }else{
                return []
            }
        }
    
      useEffect(() => {
        if(props.submitType != 0){
          setSubmitType(props.submitType)
        }
      },[props.submitType]);
    
      useEffect(()=>{
        if(eventsConfigure?.ScreeningVisits && eventsConfigure?.TreatmentVisits && eventsConfigure?.FollowupVisits && eventsConfigure?.Weeks){  
          if(props.newSoa?.soaAddedLabs?.length > 0 || 
            props.newSoa?.soaAddedExamination?.length > 0 || 
            props.newSoa?.soaAddedProcedures?.length > 0 || 
            props.newSoa?.soaAddedQuestionnaires?.length > 0 || 
            props.newSoa?.soaAddedStudyProcedures?.length > 0) return
          setNumbers({
            ScreeningVisits: eventsConfigure.ScreeningVisits,
            TreatmentVisits: eventsConfigure.TreatmentVisits,
            FollowupVisits: eventsConfigure.FollowupVisits,
            weekNumber:eventsConfigure.Weeks[eventsConfigure.Weeks.length-1]
          })
          setEditNumbers({
            screeningVisitsNumber: eventsConfigure.ScreeningVisits,
            treatmentVisitsNumber: eventsConfigure.TreatmentVisits,
            followupVisitsNumber: eventsConfigure.FollowupVisits,
            weekNumber:eventsConfigure.Weeks[eventsConfigure.Weeks.length-1]
          })
          const tmpSoAConfig = {
            [CATEGORY_LABS]:eventsConfigure[CATEGORY_LABS].entities,
            [CATEGORY_PHYSICAL_EXAMINATION]:eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION].entities,
            [CATEGORY_PROCEDURES]:eventsConfigure[CATEGORY_PROCEDURES].entities,
            [CATEGORY_QUESTIONNAIRES]:eventsConfigure[CATEGORY_QUESTIONNAIRES].entities,
            [CATEGORY_STUDY_PROCEDURES]:eventsConfigure[CATEGORY_STUDY_PROCEDURES].entities
          }
        }
      },[eventsConfigure?.ScreeningVisits, eventsConfigure?.TreatmentVisits, eventsConfigure?.FollowupVisits, eventsConfigure?.Weeks])
    
      useEffect(()=>{
        if(eventsConfigure?.VisitTypes?.ScreeningVisits && eventsConfigure?.VisitTypes?.TreatmentVisits && eventsConfigure?.VisitTypes?.FollowupVisits && eventsConfigure.WeekNumber){
         if(props.newSoa?.soaAddedLabs?.length > 0 || 
          props.newSoa?.soaAddedExamination?.length > 0 || 
          props.newSoa?.soaAddedProcedures?.length > 0 || 
          props.newSoa?.soaAddedQuestionnaires?.length > 0 || 
          props.newSoa?.soaAddedStudyProcedures?.length > 0  ) return
          setNumbers({
            ScreeningVisits: eventsConfigure?.VisitTypes.ScreeningVisits,
            TreatmentVisits: eventsConfigure?.VisitTypes.TreatmentVisits,
            FollowupVisits: eventsConfigure?.VisitTypes.FollowupVisits,
            weekNumber:eventsConfigure?.Weeks[eventsConfigure.Weeks.length-1]
          })
          setEditNumbers({
            screeningVisitsNumber: eventsConfigure?.VisitTypes.ScreeningVisits,
            treatmentVisitsNumber: eventsConfigure?.VisitTypes.TreatmentVisits,
            followupVisitsNumber: eventsConfigure?.VisitTypes.FollowupVisits,
            weekNumber:eventsConfigure?.Weeks[eventsConfigure.Weeks.length-1]
          })
          const tmpSoAConfig = {
            [CATEGORY_LABS]:eventsConfigure[CATEGORY_LABS].entities,
            [CATEGORY_PHYSICAL_EXAMINATION]:eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION].entities,
            [CATEGORY_PROCEDURES]:eventsConfigure[CATEGORY_PROCEDURES].entities,
            [CATEGORY_QUESTIONNAIRES]:eventsConfigure[CATEGORY_QUESTIONNAIRES].entities,
            [CATEGORY_STUDY_PROCEDURES]:eventsConfigure[CATEGORY_STUDY_PROCEDURES].entities
          }
        }
      },[eventsConfigure?.VisitTypes])
    
      // useEffect(() => {
      //   if(eventsConfigure?.visitNotesList  && !props.evt_NotesList) {
      //    // setVisitNoteList(eventsConfigure.visitNotesList) 
      //    // props.updateSoaActivities({ soaVisitNotesList: eventsConfigure?.visitNotesList })
      //   }
      // }, [eventsConfigure?.visitNotesList])


      useEffect(() => {
        setWeeks(props.newSoa?.soaWeeks)
      }, [props.newSoa?.soaWeeks])

      useEffect(() => {
        //Verify if this is the first time to set Events
        if(props.newSoa.scenarios){
         const scenario = props.newSoa.scenarios && props.newSoa.scenarios.find(s=> s['ts_id'] == localStorage.getItem('ts_id'));
        const eventsConfigure = scenario&&scenario['Schedule of Activities']
    
        const scenarioTemp = props.newSoa.scenarios[0]
        const eventsConfigureTemp = scenarioTemp&&scenarioTemp['Schedule of Activities']
        const eventsConfigWeeksTemp = eventsConfigureTemp&&eventsConfigureTemp.Weeks
    
        setInsightEvent(scenario&&scenario['soa_insights']? scenario['soa_insights']:[])
        if(eventsConfigure != undefined  && eventsConfigure.Weeks != undefined){
          setHiddeTags(false)
          setShowConfigure(false)
        }
        else if(props.event_numbers) {
         let { evtFollowupVisitsNumber,  evtScreeningVisitsNumber, evtTreatmentVisitsNumber} = props.event_numbers 
          let visitCount = 0;
          visitCount = evtFollowupVisitsNumber + evtScreeningVisitsNumber + evtTreatmentVisitsNumber
           
          if(visitCount && visitCount > 3) {
            setShowConfigure(false)
          }
        }
        else if(eventsConfigWeeksTemp == undefined && !props.event_numbers){
          // setShowConfigure(true)
          props.setDisableSaveFinish(true)
        }
        let cost_avg = 0;
        let burden_avg = 0;
        if(props.newSoa&&props.newSoa.therapeutic_area_average != undefined){
            cost_avg = formatCostAvg(props.newSoa&&props.newSoa.therapeutic_area_average.cost_avg, 1000) ?? 0;
            burden_avg = props.newSoa&&props.newSoa.therapeutic_area_average.burden_avg != undefined?
                    Number(props.newSoa&&props.newSoa.therapeutic_area_average.burden_avg.toString().match(/^\d+(?:\.\d{0,2})?/)) : 0;
        }
        setCostSubTitle('Similar Historical\nTrials - $' + cost_avg + 'K / Patient')
        setBurdenSubTitle('Similar Historical\nTrials - ' + burden_avg)
        setModalityOptions(getModalityOptions(scenario&&scenario['modality_option']))
        
        const getStandardEventsLib = async () => {
          if(!props?.record?.trial_uuid) return 
          var resp = await getStandardEvents(props.record.trial_uuid);
    
          if (resp && resp != "{}") {
              const response = resp
              ReactDOM.unstable_batchedUpdates(() => {
                setOrgLabs(response[CATEGORY_LABS])
                setOrgExamination(response[CATEGORY_PHYSICAL_EXAMINATION])
                setOrgProcedures(response[CATEGORY_PROCEDURES])
                setOrgQuestionnaires(response[CATEGORY_QUESTIONNAIRES])
                setOrgStudyProcedures(response[CATEGORY_STUDY_PROCEDURES])
    
                //Init previous configure 
              let isNewScenario = props.newSoa?.soaAddedLabs?.length > 0 || props.newSoa?.soaAddedExamination?.length > 0 || props.newSoa?.soaAddedProcedures?.length > 0 || props.newSoa?.soaAddedQuestionnaires?.length > 0 ||  props.newSoa?.soaAddedStudyProcedures?.length > 0
              || props.newSoa?.soaFilteredLabs?.length > 0 || props.newSoa?.soaFilteredExamination.length > 0
              || props.newSoa?.soaFilteredProcedures?.length > 0 
              || props.newSoa?.soaFilteredQuestionnaires?.length > 0 
              || props.newSoa?.soaFilteredStudyProcedures?.length > 0 ;  
              if(eventsConfigure != undefined && eventsConfigure.Labs != undefined && !isNewScenario){  
                 const Obj = {
                  ['visitNumber']: eventsConfigure.Visits,
                  ['ScreeningVisits']: eventsConfigure?.VisitTypes?.ScreeningVisits || eventsConfigure.ScreeningVisits,
                  ['TreatmentVisits']: eventsConfigure?.VisitTypes?.TreatmentVisits || eventsConfigure.TreatmentVisits,
                  ['FollowupVisits']: eventsConfigure?.VisitTypes?.FollowupVisits || eventsConfigure.FollowupVisits,
                  ['weekNumber']: eventsConfigure.WeekNumber? eventsConfigure.WeekNumber : eventsConfigure.Weeks[eventsConfigure.Weeks.length -1]
                 } 
                 setNumbers(Obj);
                 let visitArr = [];
                 let { ScreeningVisits, TreatmentVisits, FollowupVisits } = Obj
                 let vNumber = ScreeningVisits + TreatmentVisits + FollowupVisits 
                 for (let i = 0; i <= vNumber-1; i++) {
                   visitArr.push(i+1)
                 }
                  setEditNumbers({
                    'screeningVisitsNumber': eventsConfigure?.VisitTypes?.ScreeningVisits || eventsConfigure.ScreeningVisits,
                    'treatmentVisitsNumber': eventsConfigure?.VisitTypes?.TreatmentVisits || eventsConfigure.TreatmentVisits,
                    'followupVisitsNumber': eventsConfigure?.VisitTypes?.FollowupVisits || eventsConfigure.FollowupVisits,
                    'weekNumber': eventsConfigure.WeekNumber? eventsConfigure.WeekNumber : eventsConfigure.Weeks[eventsConfigure.Weeks.length -1]
                  })     
                  if(eventsConfigure?.visitNotesList) {
                    //if(eventsConfigure?.visitNotesList && !props.evt_NotesList) {
                   setVisitNoteList(eventsConfigure.visitNotesList)
                   props.updateSoaActivities({ soaVisitNotesList: eventsConfigure?.visitNotesList })
                  }
                  setVisits(visitArr)
                  setWeeks(eventsConfigure.Weeks)
                  props.setDisableSaveFinish(false)
                  setResetWeeks(false)
                  setAddedLabs( props?.newSoa?.soaAddedLabs.length > 0 ?props.newSoa.soaAddedLabs : eventsConfigure[CATEGORY_LABS]?.entities)
                  setAddedExamination(props?.newSoa?.soaAddedExamination?.length > 0 ? props.newSoa.soaAddedExamination : eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION]?.entities)
                  setAddedQuestionnaires(props?.newSoa?.soaAddedQuestionnaires?.length > 0 ? props.newSoa.soaAddedQuestionnaires : eventsConfigure[CATEGORY_QUESTIONNAIRES]?.entities)
                  setAddedProcedures(props?.newSoa?.soaAddedProcedures?.length > 0 ? props.newSoa.soaAddedProcedures : eventsConfigure[CATEGORY_PROCEDURES]?.entities)
                  setAddedStudyProcedures(props?.newSoa?.soaAddedStudyProcedures?.length > 0 ? props.newSoa.soaAddedStudyProcedures : eventsConfigure[CATEGORY_STUDY_PROCEDURES]?.entities)
                  // setPatientRate('{p|$'+ eventsConfigure.TotalCost +'K}\n{'+eventsConfigure.CostRate + '|' + eventsConfigure.CostRate + '}')
                  setPatientRate('$'+ formatCostAvg(scenario["cost"],1000) +'K\n'+eventsConfigure.CostRate)
                  setCostData(getCostData(eventsConfigure.CostData))
                  setBurdenData(eventsConfigure.BurdenData)
                  setTotalBurden(sumOfArr(eventsConfigure.BurdenData))
                  if (eventsConfigure.patient_burden_rate != ""){
                  setTotalBurdenStatus(`{${eventsConfigure.patient_burden_rate}|${eventsConfigure.patient_burden_rate}}`)
                  }
                  else{
                  setTotalBurdenStatus('')
                  }
                  setBurdenXAxis(eventsConfigure.BurdenXAxis)
    
                  //init Activities by Modality chart data
                  const tmpSoAConfig = {
                    [CATEGORY_LABS]:eventsConfigure[CATEGORY_LABS]?.entities,
                    [CATEGORY_PHYSICAL_EXAMINATION]:eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION]?.entities,
                    [CATEGORY_PROCEDURES]:eventsConfigure[CATEGORY_PROCEDURES]?.entities,
                    [CATEGORY_QUESTIONNAIRES]:eventsConfigure[CATEGORY_QUESTIONNAIRES]?.entities,
                    [CATEGORY_STUDY_PROCEDURES]:eventsConfigure[CATEGORY_STUDY_PROCEDURES]?.entities
                  }
                  getModalitySeriesData(tmpSoAConfig)
                  if(eventsConfigure.CostRate.length > 0){
                    setShowTooltip(true)
                    setShowPatientLabel(true)
                  }
                  if(isEventsAdded(eventsConfigure)){
                      setPatientChartColor(aChartColors)
                      setBurdenChartColor(burdenColors.active)
                      setLabelColors(aLabelColors)
                  }else{
                      setPatientChartColor(iChartColors)
                      setBurdenChartColor(burdenColors.inactive)
                      setLabelColors(iLabelColors)
                  }
    
                  setFilteredLabs(filterLibs(response[CATEGORY_LABS], eventsConfigure[CATEGORY_LABS]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  setFilteredExamination(filterLibs(response[CATEGORY_PHYSICAL_EXAMINATION], eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  setFilteredProcedures(filterLibs(response[CATEGORY_PROCEDURES], eventsConfigure[CATEGORY_PROCEDURES]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  setFilteredQuestionnaires(filterLibs(response[CATEGORY_QUESTIONNAIRES], eventsConfigure[CATEGORY_QUESTIONNAIRES]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  setFilteredStudyProcedures(filterLibs(response[CATEGORY_STUDY_PROCEDURES], eventsConfigure[CATEGORY_STUDY_PROCEDURES]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  props.updateSoaActivities({  
                    soaFilteredLabs: filterLibs(response[CATEGORY_LABS], eventsConfigure[CATEGORY_LABS]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                    soaFilteredExamination: filterLibs(response[CATEGORY_PHYSICAL_EXAMINATION], eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                    soaFilteredQuestionnaires: filterLibs(response[CATEGORY_QUESTIONNAIRES], eventsConfigure[CATEGORY_QUESTIONNAIRES]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                    soaFilteredProcedures: filterLibs(response[CATEGORY_PROCEDURES], eventsConfigure[CATEGORY_PROCEDURES]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                    soaFilteredStudyProcedures: filterLibs(response[CATEGORY_STUDY_PROCEDURES], eventsConfigure[CATEGORY_STUDY_PROCEDURES]?.entities, FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                    soaWeeks : eventsConfigure.Weeks,
                    soaVisits:  visitArr,
                    soaAddedLabs: tmpSoAConfig[CATEGORY_LABS],
                    soaAddedExamination: tmpSoAConfig[CATEGORY_PHYSICAL_EXAMINATION],
                    soaAddedProcedures: tmpSoAConfig[CATEGORY_PROCEDURES],
                    soaAddedQuestionnaires: tmpSoAConfig[CATEGORY_QUESTIONNAIRES],
                    soaAddedStudyProcedures: tmpSoAConfig[CATEGORY_STUDY_PROCEDURES],
                    event_numbers: Obj,
                  });
                  setEventCriteria(eventsConfigure) 
                } else if(isNewScenario){ 
                  setWeeks(props.newSoa.soaWeeks)
                  setVisits(props.newSoa.soaVisits)
                  updateEventHandler(props.newSoa.event_numbers)
                  // setVisitNoteList(props.evt_NotesList) 
                  setFilteredLabs(props?.newSoa?.soaFilteredLabs)
                  setFilteredExamination(props?.newSoa?.soaFilteredExamination)
                  setFilteredProcedures(props?.newSoa?.soaFilteredProcedures)
                  setFilteredQuestionnaires(props?.newSoa?.soaFilteredQuestionnaires)
                  setFilteredStudyProcedures(props?.newSoa?.soaFilteredStudyProcedures)
                  setAddedLabs( props?.newSoa?.soaAddedLabs)
                  setAddedExamination(props?.newSoa?.soaAddedExamination)
                  setAddedQuestionnaires(props?.newSoa?.soaAddedQuestionnaires)
                  setAddedProcedures(props?.newSoa?.soaAddedProcedures)
                  setAddedStudyProcedures(props?.newSoa?.soaAddedStudyProcedures)
                  setVisitNoteList(props?.newSoa?.soaVisitNotesList)
                } else {
                  setFilteredLabs(filterLibs(response[CATEGORY_LABS], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  setFilteredExamination(filterLibs(response[CATEGORY_PHYSICAL_EXAMINATION], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  setFilteredProcedures(filterLibs(response[CATEGORY_PROCEDURES], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  setFilteredQuestionnaires(filterLibs(response[CATEGORY_QUESTIONNAIRES], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  setFilteredStudyProcedures(filterLibs(response[CATEGORY_STUDY_PROCEDURES], [],FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX))
                  props.updateSoaActivities({  
                    soaFilteredLabs: filterLibs(response[CATEGORY_LABS], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                    soaFilteredExamination: filterLibs(response[CATEGORY_PHYSICAL_EXAMINATION], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                    soaFilteredQuestionnaires: filterLibs(response[CATEGORY_QUESTIONNAIRES], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                    soaFilteredProcedures: filterLibs(response[CATEGORY_PROCEDURES], [], FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                    soaFilteredStudyProcedures: filterLibs(response[CATEGORY_STUDY_PROCEDURES], [],FREQUENCY_RANGE_MIN, FREQUENCY_RANGE_MAX),
                  });
                }
              });
          }
        };
        getStandardEventsLib();
        }
      }, [props.newSoa.scenarios]);
    
      const updateEventHandler = (evt_num) => {
        if(!evt_num || Object.keys(evt_num).length === 0) return;
        const { ScreeningVisits, TreatmentVisits, FollowupVisits, weekNumber} = evt_num
        setNumbers({
          ScreeningVisits: ScreeningVisits,
          TreatmentVisits: TreatmentVisits,
          FollowupVisits: FollowupVisits,
          weekNumber:weekNumber
        })
        setEditNumbers({
          screeningVisitsNumber: ScreeningVisits,
          treatmentVisitsNumber: TreatmentVisits,
          followupVisitsNumber: FollowupVisits,
          weekNumber:weekNumber
        })
        setShowConfigure(false);
        setWeeks(props.newSoa.soaWeeks) 
       // setVisitNoteList(props.evt_NotesList)
      } 

      function filterLibs(orgLibs, addedEvents, minValue, maxValue){
        let filteredLibs = orgLibs.filter((d) => {
          if( d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue){
            var index = addedEvents.findIndex((domain) => d['Standard Activity'] === domain['Standard Activity']);
            if(index > -1){
              return Object.assign(d, {
                selected: true,
                condition: addedEvents[index].condition,
                totalVisit: addedEvents[index].totalVisit})
            } else {
              return Object.assign(d, {selected: false, condition: [], totalVisit: 0})
            }
          }
        })
        return filteredLibs
      }
    
      const panelHeader = () => {
        return (
            <div className="event-panelHeader">
                <div>
                    <div style={{color:'#333', fontSize: '18px'}}><span>Impact</span></div>
                </div>
            </div>
        );
      };
    
      const eventLibHeader = (name, count, key) => {
        return (
          <Row className="section-header">
            <Col span={23}><span>{name}</span><span className="count-span">{count}</span></Col>
            <Col span={1} className="collapse-icon">{activeCollapse.indexOf(key) >= 0 ?(<MinusOutlined />):(<PlusOutlined />)}</Col>
          </Row>
        );
      };
    
      // Add total number and Good/fair/poor with color for version 1 only
      // let totalBurdenStatus = 'GOOD'
      // let totalBurdenStatusColor = '{GOOD|GOOD}'
      // if (totalBurden > 0 && totalBurden <= 400) {
      //   totalBurdenStatus = 'GOOD'
      //   totalBurdenStatusColor = '{GOOD|GOOD}'
      // } else if (totalBurden > 400 && totalBurden <= 600) {
      //   totalBurdenStatus = '{FAIR|FAIR}'
      // } else if (totalBurden > 600){
      //   totalBurdenStatus = '{POOR|POOR}'
      // }
    
      useEffect(()=>{
    
        const getVisits = () =>{
          let visitArr = [];
          let vNumber = editNumbers.screeningVisitsNumber + editNumbers.treatmentVisitsNumber + editNumbers.followupVisitsNumber
          for (let i = 0; i <= vNumber-1; i++) {
          visitArr.push(i+1)
        }
         return visitArr
        }
        const visitArr = getVisits()
    
        setVisits(visitArr)
      },[editNumbers,numbers])
    
      let final_visit_Number = numbers.ScreeningVisits + numbers.TreatmentVisits + numbers.FollowupVisits
      let chartWidthSmaller = final_visit_Number < 11? 240 + 2 * ( 1 + final_visit_Number):220 + 20 * ( 1 + final_visit_Number)
      let chartWidthLarge = chartWidthSmaller + 250
      // Add total number and Good/fair/poor with color for version 1 only
      let final_burden
      if (totalBurden === 0 && totalBurdenStatus === ""  ){
      final_burden  = ""
      }
      else{
      final_burden = totalBurden
      }
      const burdenOption = {
        title : {
          // textAlign: 'left',
          // textVerticalAlign: 'top',
          text: '\n' + '\n' +'{p|' + final_burden + '}'+'\n' + totalBurdenStatus,
          subtext: burdenSubTitle,
          x:'top',
          y:'left',
          textStyle: {
            rich: {
              a: {
                fontSize: 16,
                fontWeight: 'bold',
                color: '#333',
              },
              p: {
                color: '#999',
                fontSize: 20,
                backgroundColor: "white"
              },
              GOOD: {
                color: labelColors.GOOD,
                fontSize: 14,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              FAIR: {
                color: labelColors.FAIR,
                fontSize: 14,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              POOR: {
                color: labelColors.POOR,
                fontSize: 14,
                fontWeight:'bold',
                backgroundColor: "white"
              }
            }
          },
          subtextStyle: {
            fontSize: 14,
            fontWeight: 'normal',
            color: '#999',
            align: 'right',
            verticalAlign: 'top'
          }
        },
        tooltip: {
          show: showTooltip,
          trigger: 'axis',
          axisPointer: {
              type: 'shadow'
          }
        },
        grid: {
            left: 230,
            width: chartWidthSmaller,
            // right: '4%',
            top: '10%',
            bottom: '15%',
            containLabel: true
        },
        legend: {
            data: ['Patient Burden']
        },
        xAxis: [
            {
              type: 'category',
              name: 'Visit Number',
              data: burdenXAxis,
              nameLocation: "middle",
              nameRotate: 0, nameGap: 20,
              axisTick: {
                  alignWithLabel: true
              },
              nameTextStyle: {
                // align: 'center',
                lineHeight: 20,
              },
            }
        ],
        yAxis: [
            { type: 'value', name:'Patient Burden',
            nameRotate: 90, nameGap: 40, nameLocation: "middle",
            axisLine: { lineStyle: { color: '#666666' } },
            axisLabel : { formatter : function(value) { return value; } }, }
        ],
        series: [
            {
                name: 'Patient Burden',
                type: 'bar',
                barWidth: '60%',
                barMaxWidth : 35,
                color: burdenChartColor,
                data: burdenData,
                label: {
                  show: false,
                  position: 'top',
                  fontSize: 12,
                  color: '#666'
              },
            }
        ]
      };

      var data = [];
for (var i = 0; i < 7; i++) {
    var maxBurden = Math.floor(Math.random() * (400 - 200 + 1)) + 200;
    var medianBurden = Math.floor(Math.random() * (maxBurden - 200 + 1)) + 200;
    data.push([i, maxBurden, medianBurden]); 
}

      const burdenOption1 =  {
        title : {
          // textAlign: 'left',
          // textVerticalAlign: 'top',
          text: '\n' + '\n' +'{p|' + final_burden + '}'+'\n' + totalBurdenStatus,
          subtext: burdenSubTitle,
          x:'top',
          y:'left',
          textStyle: {
            rich: {
              a: {
                fontSize: 16,
                fontWeight: 'bold',
                color: '#333',
              },
              p: {
                color: '#999',
                fontSize: 20,
                backgroundColor: "white"
              },
              GOOD: {
                color: labelColors.GOOD,
                fontSize: 14,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              FAIR: {
                color: labelColors.FAIR,
                fontSize: 14,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              POOR: {
                color: labelColors.POOR,
                fontSize: 14,
                fontWeight:'bold',
                backgroundColor: "white"
              }
            }
          },
          subtextStyle: {
            fontSize: 14,
            fontWeight: 'normal',
            color: '#999',
            align: 'right',
            verticalAlign: 'top'
          }
        },
        tooltip: {
          show: showTooltip,
          trigger: 'item',
          axisPointer: {
              type: 'shadow'
          }
        },
        grid: {
          left: 230,
            width: chartWidthSmaller,
            // right: '4%',
            top: '10%',
            bottom: '15%',
            containLabel: true
        },
        legend: {
          data: ['Patient Burden']
      },
        // graphic: [
        //   {
        //     type: 'line',
        //     y: 40,
        //     shape: {
        //       x1: 0, y1: 0, x2: 10000, y2: 0
        //     }
        //   },
        //   {
        //       type: 'text',
        //       left: '5%',
        //       top: 60,
        //       style: {
        //           text: '154',
        //           fontSize: 36,
        //           fontWeight: 'bold'
        //       }
        //   },
        //   {
        //       type: 'text',
        //       left: '5%',
        //       top: 90,
        //       style: {
        //           text: 'GOOD',
        //           fontWeight: 'bold',
        //           fontSize: 18,
        //           fill: 'green',
        //       }
        //   },
        //   {
        //       type: 'text',
        //       right: '5%',
        //       top: 80,
        //       style: {
        //           text: 'Median from Similar Historical Trials - 357',
        //           fontSize: 16,
        //       }
        //   }
        // ],
        xAxis: [{
          type: 'category',
          name: 'Visit Number',
          data: burdenXAxis,
          nameLocation: "middle",
          nameRotate: 0, nameGap: 20,
          axisTick: {
              alignWithLabel: true
          },
          nameTextStyle: {
            // align: 'center',
            lineHeight: 20,
          },
        }],
        yAxis: [
          { type: 'value', name:'Patient Burden',
          nameRotate: 90, nameGap: 40, nameLocation: "middle",
          axisLine: { lineStyle: { color: '#666666' } },
          axisLabel : { formatter : function(value) { return value; } }, }
      ],
        series:[
          {
              type: 'bar',
              data: data.map(item => [item[0], item[1]]), // Max patient burden data
              barCategoryGap: '50%',
              barWidth: 20,
              itemStyle: {
                color: burdenChartColor
              }, 
              markPoint: {
                  symbol: 'circle', // Circular symbol for median
                  symbolSize: 20,
                  itemStyle: {
                    color: 'rgba(0, 0, 0, .6)',
                    borderColor: 'rgba(235, 235, 235, 1)',
                    borderWidth: 12
                  },
                  data: data.map(item => ({
                      coord: [item[0], item[1]], // Median data
                      value: item[2], // Median value
                      label: {
                          show: false,
                      }
                  })),
                  tooltip: {
                    trigger: 'item',
                      formatter: function (param) {
                          return 'Median Patient Burden from subsequent visits of historical trials: ' + param.value;
                      }
                  }
              }
          }
      ]
    };

      const costOption = {
        title : {
          // text: 'Cost Per Patient',
          subtext: costSubTitle,
          x:'left',
          y:'top',
          textStyle: {
            fontSize: 16,
            fontWeight: 'bold',
            color: '#333'
          },
          subtextStyle: {
            fontSize: 14,
            fontWeight: 'normal',
            color: '#999'
          }
        },
        legend: {
          x:'right',
          y:'20%',
          orient: 'vertical',
          align: 'left',
          itemHeight: 7,
          textStyle: {
            fontSize: 12,
            color: '#333'
          },
          data: [CATEGORY_LABS, CATEGORY_PHYSICAL_EXAMINATION, CATEGORY_PROCEDURES, CATEGORY_QUESTIONNAIRES, CATEGORY_STUDY_PROCEDURES]
        },
        tooltip: {
          show: showTooltip,
          trigger: 'item',
          formatter: '{b} - ${c}',
          extraCssText:'background:#757373;color:white;font-size:8px'
        },
        series: [
          {
            type: 'pie',
            center: ['30%', '60%'],
            radius: ['50%', '70%'],
            avoidLabelOverlap: true,
            label: {
              show: false,
            },
            emphasis: {
              label: {
                show: false
              }
            },
            labelLine: {
              show: false
            },
            color: patientChartColor,
            data: costData
          }
        ],
        graphic: {
          elements: [{
            type: 'text',
            left: '20.5%',
            top: '51%', 
            cursor:'default',
            invisible: !showPatientLabel,
            style: {
              text: patientRate.split('\n')?patientRate.split('\n')[0].length === 3? (`   ${patientRate.split('\n')[0]}  `):(patientRate.split('\n')[0].length === 6? (` ${patientRate.split('\n')[0]}`):(patientRate.split('\n')[0])):"",
              fontSize: 20,
              textAlign: 'center', 
              width: 30,
              height: 30,
              fill: '#848484'
            }
          },
          {
            type: 'text',
            left: '25%',
            top: '61%',
            cursor:'default',
            invisible: !showPatientLabel,
            style: {
              text: patientRate.split('\n')?patientRate.split('\n')[1]:"",
              fontSize: 12,
              textAlign: 'center',
              fontWeight:'bold',
              width: 30,
              height: 25,
              fill: patientRate.split('\n')&&patientRate.split('\n')[1]=="GOOD"?labelColors.GOOD:patientRate.split('\n')&&patientRate.split('\n')[1]=="FAIR"?labelColors.FAIR:labelColors.POOR,
            }
          }]
        }
      };
    
      const handleEventChange = (tmpWeeks) =>{
        if(tmpWeeks != undefined && tmpWeeks.length > 0){
          setWeeks(tmpWeeks)
        }
        setResetWeeks(false)
      }
    
      const statisticsPerVisit = (categories,idx)=>{
        //statistics of each column
        const modalityCollection={}
        const modalitySummary={}
        const columnConditionCollection = []
        if(categories[CATEGORY_LABS]?.length>0){
          categories[CATEGORY_LABS].forEach( ele=>{
            columnConditionCollection.push( ele.condition[idx])
          })
        }
        if(categories[CATEGORY_PHYSICAL_EXAMINATION]?.length>0){
          categories[CATEGORY_PHYSICAL_EXAMINATION].forEach( ele=>{
            columnConditionCollection.push( ele.condition[idx])
          })
    
        }
        if(categories[CATEGORY_PROCEDURES]?.length>0){
          categories[CATEGORY_PROCEDURES].forEach( ele=>{
            columnConditionCollection.push( ele.condition[idx])
          })
    
        }
        if(categories[CATEGORY_QUESTIONNAIRES]?.length>0){
          categories[CATEGORY_QUESTIONNAIRES].forEach( ele=>{
            columnConditionCollection.push( ele.condition[idx])
          })
        }
        if(categories[CATEGORY_STUDY_PROCEDURES]?.length>0){
          categories[CATEGORY_STUDY_PROCEDURES].forEach( ele=>{
            columnConditionCollection.push( ele.condition[idx])
          })
        }
    
        columnConditionCollection.filter(element=>element&&element.modality&&element.modality!=="")
        .forEach( element=>{
        if( Object.keys(modalityCollection).indexOf(element.modality.toString())<0 ){
          modalityCollection[element.modality.toString()]=[element]
        }else{
          modalityCollection[element.modality.toString()].push(element)
        }})
        modalityOptions.forEach( option=>{
         if(option.key&&option.key!==""){
          if(Object.keys(modalityCollection).indexOf(option.key.toString())==-1){
            modalitySummary[option.name]=0
          }else{
            modalitySummary[option.name]=modalityCollection[option.key.toString()].length
          }
         }
        })
        return modalitySummary
      }
    
      useEffect(()=>{
        const categories={
          [CATEGORY_LABS]:addedLabs,
          [CATEGORY_PHYSICAL_EXAMINATION]:addedExamination,
          [CATEGORY_PROCEDURES]:addedProcedures,
          [CATEGORY_QUESTIONNAIRES]:addedQuestionnaires,
          [CATEGORY_STUDY_PROCEDURES]:addedStudyProcedures,
        }
        getModalitySeriesData(categories)
    
      },[visits,addedLabs,addedExamination,addedProcedures,addedQuestionnaires,addedStudyProcedures])  
    
      const getModalitySeriesData = (categories) =>{
        const modalitySeries = []
    
        const visitStacks=visits.map( (ele,idx)=>{
          return statisticsPerVisit(categories,idx)
        })
        if(visitStacks.filter(v=>v!==undefined).length==0) return
        modalityOptions.forEach((element,idx)=>{
          const data = visitStacks.map( v=>{
            return v[element.name]
          })
          modalitySeries.push({
            name: element.name,
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data,
            color:element.color
          })
        })
    
        setModalityChartData(modalitySeries)
      }

      const [saveCostChart, setSaveCostChart] = useState(1)
      const saveEvents = async (scheduleOfEvents) =>{
        if(submitType === 1){
          props.handleGoBack(Object.assign(scheduleOfEvents,{
            'TotalCost': eventCriteria.TotalCost,
            'CostRate': eventCriteria.CostRate,
            'CostData': costData,
            'BurdenData': burdenData,
            'BurdenXAxis': burdenXAxis,
            'Finished': eventCriteria.Finished,
            'WeekNumber': scheduleOfEvents.Weeks[scheduleOfEvents.Weeks.length-1]
          }))
          console.log("eventCriteria.TotalCost",eventCriteria.TotalCost);
    
          return;
        }
        if(isEventsAdded(scheduleOfEvents)){
            setPatientChartColor(aChartColors)
            setBurdenChartColor(burdenColors.active)
            setLabelColors(aLabelColors)
        }else{
            setPatientChartColor(iChartColors)
            setBurdenChartColor(burdenColors.inactive)
            setLabelColors(iLabelColors)
        }
        setWeeks(scheduleOfEvents.Weeks)
        let tempEditNumber = {
          'screeningVisitsNumber': scheduleOfEvents.ScreeningVisits,
          'treatmentVisitsNumber': scheduleOfEvents.TreatmentVisits,
          'followupVisitsNumber': scheduleOfEvents.FollowupVisits,
          'weekNumber': scheduleOfEvents.WeekNumber
        }
        let tempPreviousEditNumber = {
          'ScreeningVisits': scheduleOfEvents.ScreeningVisits,
          'TreatmentVisits': scheduleOfEvents.TreatmentVisits,
          'FollowupVisits': scheduleOfEvents.FollowupVisits,
          'weekNumber': scheduleOfEvents.WeekNumber
        }
        setEditNumbers(tempEditNumber)
        setPreviousCategoryVisits(tempPreviousEditNumber)
        const categories={
          [CATEGORY_LABS]:scheduleOfEvents[CATEGORY_LABS].entities,
          [CATEGORY_PHYSICAL_EXAMINATION]:scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities,
          [CATEGORY_PROCEDURES]:scheduleOfEvents[CATEGORY_PROCEDURES].entities,
          [CATEGORY_QUESTIONNAIRES]:scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities,
          [CATEGORY_STUDY_PROCEDURES]:scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities,
        }
        setAddedLabs(scheduleOfEvents[CATEGORY_LABS].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
        setAddedExamination(scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
        setAddedQuestionnaires(scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
        setAddedProcedures(scheduleOfEvents[CATEGORY_PROCEDURES].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
        setAddedStudyProcedures(scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
        getModalitySeriesData(categories) 
        // props.updateSoaActivities(initialSoa)
        let costList ;
        let burdenList
        if(props.newSoa&&props.newSoa.therapeutic_area_average != undefined && props.newSoa&&props.newSoa.therapeutic_area_average.length > 0){
            costList = [props.newSoa&&props.newSoa.therapeutic_area_average.cost_limit_lower_quartile, props.newSoa&&props.newSoa.therapeutic_area_average.cost_limit_upper_quartile]
            burdenList = [props.newSoa&&props.newSoa.therapeutic_area_average.pb_limit_lower_quartile, props.newSoa&&props.newSoa.therapeutic_area_average.pb_limit_upper_quartile]
        }else{
            costList = [0,0];
            burdenList = [0,0];
        }
    
        let scenario_id = localStorage.getItem('ts_id')
        let currentScenario = props.newSoa.scenarios && props.newSoa.scenarios.find(s=> s['ts_id'] == localStorage.getItem('ts_id'));
        let ts_id = currentScenario["ts_id"];
        let soa_dismiss_list = dismissEvent||[]
        
        scheduleOfEvents["visitNoteList"] = visitNoteList
    
        const resp = await update_patient_burden(props.record['trial_uuid'], localStorage.getItem('ts_id'), ts_id, scheduleOfEvents, tempEditNumber, costList, burdenList, soa_dismiss_list, getEmail())
        if (resp && Object.keys(resp).length !== 0) {
          const response = resp
          const soa = response['soa']
          let finalCost = 0;
          soa.CostData.forEach(item => {
            finalCost += item.value;
          });
          setShowPatientLabel(true)
          if(soa.CostRate) {
            // setPatientRate('{p|$'+ formatCostAvg(finalCost,1000) +'K}\n{' +soa.CostRate+ '|' + soa.CostRate + '}')
            setPatientRate('$'+ formatCostAvg(finalCost,1000) +'K\n' +soa.CostRate)
          }
          setCostData(soa.CostData.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
          }))
          setBurdenData(soa.BurdenData)
          setTotalBurden(sumOfArr(soa.BurdenData))
          setTotalBurdenStatus(`{${soa.patient_burden_rate}|${soa.patient_burden_rate}}`)
          setBurdenXAxis(soa.BurdenXAxis)
          setShowTooltip(true)
          let tempNumber = {
            'ScreeningVisits': scheduleOfEvents.ScreeningVisits,
            'TreatmentVisits': scheduleOfEvents.TreatmentVisits,
            'FollowupVisits': scheduleOfEvents.FollowupVisits,
            'weekNumber': scheduleOfEvents.WeekNumber
          }
          setNumbers(tempNumber)
          let tempTrialSOA = props.newSoa
          let tempScenario = tempTrialSOA.scenarios.find(s=> s['ts_id'] == localStorage.getItem('ts_id'));
          tempScenario["Schedule of Activities"] = soa
          currentScenario['Schedule of Activities'] = soa
          currentScenario['soa_insights'] = response['soa_insights']
          setInsightEvent(response['soa_insights'])
          props.setScenario(currentScenario)
          setVisitNoteList(soa.visitNoteList)
          props.createSoa(tempTrialSOA)
          setSaveCostChart(Math.floor(Math.random() * 1000) + 1)
          message.success('Save successfully')
          if(submitType == 2){
            props.updateSoaActivities(initialSoa)
          }
          if(submitType == 3){
            viewImpactSummary()
          }else if(submitType != 0){
            props.history.push({pathname: '/trials', state: {trial_id: props.newSoa&&props.newSoa['trial_uuid']}})
            localStorage.setItem('processStep', JSON.stringify(0));
            localStorage.setItem('activeTabKey', JSON.stringify('1'));
          }
          setExcluActiveKey(['1'])
        }else{
            message.error('Failed to save')
        }

      }
    
      function roundFun(value, n) {
          return Math.round(value*Math.pow(10,n))/Math.pow(10,n);
      }
    
      function formatCostAvg(totalCost, divisor){
        if(totalCost == undefined || totalCost === 0){
          return 0
        } else {
          let avg = Math.ceil(totalCost/divisor*1000)
          return roundFun(avg/1000, 2)
        }
      }
    
      function formatBurdenAvg(totalCost, divisor){
        if(totalCost === 0){
          return 0
        } else {
          let avg = Math.ceil(totalCost/divisor*100)
          return avg/100
        }
      }
    
      const callback = (key) => {
        setActiveCollapse(key)
      }
    
      const columns = [{
        title: 'Standard Activity',
        dataIndex: 'Standard Activity',
        key: 'Standard Activity',
        width: '100%',
        render: (_, item) => {
          return item.selected ? (
            <span className="event-text"  onClick={(e)=> handleEvent(item)}>
            <Tooltip title={"Frequency " + Math.round(item['Frequency'] * 10000) / 100 + "%"}>
            <div style={{width: '79%', display:'inline-block'}}>{item['Standard Activity']}</div>
            <div className="remove" style={{width: '21%', display:'inline-block'}}>Remove</div>
            </Tooltip></span>
          ) : (
            <span className="event-text" onClick={(e)=> handleEvent(item)}>
            <Tooltip title={"Frequency " + Math.round(item['Frequency'] * 10000) / 100 + "%"}>
            <div style={{width: '79%', display:'inline-block'}}>{item['Standard Activity']}</div>
            <div className="add" style={{width: '21%', display:'inline-block'}}>Add</div>
            </Tooltip></span>
          )
        }
      }
      ]
    
      const handleEvent = (item) => { 
        switch(item.Categories.trim()){
          case "Labs":
            let index = filteredLabs.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
            let rFilterLab= props?.newSoa?.soaFilteredLabs.length > 0 ? [...props?.newSoa?.soaFilteredLabs] : [...filteredLabs]
            let newData = [...filteredLabs]
            const newSelectedData = [...addedLabs]
    
            if(item.selected){ 
              for (let key in item) {
                if (key == "endpoint") {
                   delete item[key];
                }
             }  
              newData.splice(index, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
              rFilterLab.splice(index, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
              let selectedIndex = addedLabs.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
              newSelectedData.splice(selectedIndex, 1)
            } else {
              newData.splice(index, 1, { ...item, ...{selected: true}});
              newSelectedData.push(Object.assign(item, {selected: true}))
            }
            setFilteredLabs(newData)
            setAddedLabs(newSelectedData)
            if(props.newSoa.soaAddedLabs.length > 0) {
              props.updateSoaActivities({
                soaAddedLabs: newSelectedData, 
                soaFilteredLabs: rFilterLab
              });
            }  
            break;
    
          case "Physical Examination":
            let index2 = filteredExamination.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
            const newData2 = [...filteredExamination]
            const newSelectedData2 = [...addedExamination]
            let rFilterExamination = props?.newSoa?.soaFilteredExamination.length > 0 ? [...props?.newSoa?.soaFilteredExamination] : [...filteredExamination]
            if(item.selected){
              for (let key in item) {
                if (key == "endpoint") {
                   delete item[key];
                }
             }
              newData2.splice(index2, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
              rFilterExamination.splice(index2, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
              let selectedIndex = addedExamination.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
              newSelectedData2.splice(selectedIndex, 1)
            } else {
              newData2.splice(index2, 1, { ...item, ...{selected: true}});
              newSelectedData2.push(Object.assign(item, {selected: true}))
            }
            setFilteredExamination(newData2)
            setAddedExamination(newSelectedData2)
            if(props.newSoa.soaAddedExamination.length > 0) {
              props.updateSoaActivities({
                soaAddedExamination: newSelectedData2, 
                soaFilteredExamination: rFilterExamination
              });
            } 
            break;
    
          case "Procedures":
            let index3 = filteredProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
            const newData3 = [...filteredProcedures]
            const newSelectedData3 = [...addedProcedures]
            let rFilterProcedure = props?.newSoa?.soaFilteredProcedures.length > 0 ? [...props?.newSoa?.soaFilteredProcedures] :  [...filteredProcedures]
            if(item.selected){
              for (let key in item) {
                if (key == "endpoint") {
                   delete item[key];
                }
             }
              newData3.splice(index3, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0 }});
              rFilterProcedure.splice(index3, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0 }});
              let selectedIndex = addedProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
              newSelectedData3.splice(selectedIndex, 1)
            } else {
              newData3.splice(index3, 1, { ...item, ...{selected: true}});
              newSelectedData3.push(Object.assign(item, {selected: true}))
            }
            setFilteredProcedures(newData3)
            setAddedProcedures(newSelectedData3)
            if(props.newSoa.soaAddedProcedures.length > 0) {
              props.updateSoaActivities({
                soaAddedProcedures: newSelectedData3, 
                soaFilteredProcedures: rFilterProcedure
              });
            }   
            break;
    
          case "Questionnaires":
            let index4 = filteredQuestionnaires.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
            const newData4 = [...filteredQuestionnaires]
            const newSelectedData4 = [...addedQuestionnaires]
            let rFilterQuestionnaire = props?.newSoa?.soaFilteredQuestionnaires?.length > 0 ? [...props?.newSoa?.soaFilteredQuestionnaires] : [...filteredQuestionnaires]
            if(item.selected){
              for (let key in item) {
                if (key == "endpoint") {
                   delete item[key];
                }
             }
              newData4.splice(index4, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
              rFilterQuestionnaire.splice(index4, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
              let selectedIndex = addedQuestionnaires.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
              newSelectedData4.splice(selectedIndex, 1)
            } else {
              newData4.splice(index4, 1, { ...item, ...{selected: true}});
              newSelectedData4.push(Object.assign(item, {selected: true}))
            }
            setFilteredQuestionnaires(newData4)
            setAddedQuestionnaires(newSelectedData4)
          if(props.newSoa.soaAddedQuestionnaires.length > 0) {
            props.updateSoaActivities({
              soaAddedQuestionnaires: newSelectedData4, 
              soaFilteredQuestionnaires: rFilterQuestionnaire
            });
          }  
            break;
          case "Study Procedures":
            let index5 = filteredStudyProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
            const newData5 = [...filteredStudyProcedures]
            const newSelectedData5 = [...addedStudyProcedures]
            let rFilterStudy = props?.newSoa?.soaFilteredStudyProcedures?.length > 0 ? [...props?.newSoa?.soaFilteredStudyProcedures] : [...filteredStudyProcedures];

            if(item.selected){
              for (let key in item) {
                if (key == "endpoint") {
                   delete item[key];
                }
             }
              newData5.splice(index5, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
              rFilterStudy.splice(index5, 1, { ...item, ...{selected: false, condition: [], totalVisit: 0}});
              let selectedIndex = addedStudyProcedures.findIndex((d) => item['Standard Activity'] == d['Standard Activity'])
              newSelectedData5.splice(selectedIndex, 1)
            } else {
              newData5.splice(index5, 1, { ...item, ...{selected: true}});
              newSelectedData5.push(Object.assign(item, {selected: true}))
            }
            setFilteredStudyProcedures(newData5)
            setAddedStudyProcedures(newSelectedData5)
            if(props.newSoa.soaAddedStudyProcedures.length > 0) {
              props.updateSoaActivities({
                soaAddedStudyProcedures: newSelectedData5, 
                soaFilteredStudyProcedures: rFilterStudy
              });
            } 
            break;
          default: break;
        }
        handleEventChange([])
      }
    
      const showConfigureModal = () =>{
        setShowConfigure(true)
      }
    
      const [windowResize, setWindowResize] = useState(true)
    
      useEffect(() => {
        if(showConfigure) {
          setWindowResize(false)
        } else {
          setWindowResize(true)
        }  
      }, [showConfigure])
    
      const handleOk = () => {
        if(!validateScreeningVisit(numbers.ScreeningVisits) || !validateTreatmentVisit(numbers.TreatmentVisits) || !validateFollowUpVisit(numbers.FollowupVisits) || !validateWeek(numbers.weekNumber)){
          return
        }
        let final_visit_number = numbers.ScreeningVisits + numbers.TreatmentVisits + numbers.FollowupVisits; 
        setShowConfigure(false)
        props.setDisableSaveFinish(false)
        setHiddeTags(false)
        props.updateSoaActivities({event_numbers: numbers})  
        // if(final_visit_number === final_edit_visit_number && numbers.weekNumber === editNumbers.weekNumber){
        //   return
        // }
        let weeksArr = [];
        // if(resetWeeks){
          let tempBurdenXAxis = []
          for(var num = 1; num <= final_visit_number; num++){
            tempBurdenXAxis.push(num)
          }
          setBurdenXAxis(tempBurdenXAxis)
          //Re-generate whole weeks when no change in SOA
          if (numbers.weekNumber < final_visit_number){
            for(var num = 0; num < final_visit_number - numbers.weekNumber + 1; num ++){
              weeksArr.push(1)
            }
            var size = weeksArr.length
            for(var num = 1; num <= final_visit_number - size; num ++){
              weeksArr.push(num + 1)
            }
          } else if (numbers.weekNumber == final_visit_number){
            for (let num = 1; num <= numbers.weekNumber; num ++){
              weeksArr.push(num)
            }
          } else {
            weeksArr.push(1)
            if (final_visit_number > 1){
              const a = (numbers.weekNumber - 1) % (final_visit_number - 1)
              const b = Math.floor((numbers.weekNumber - 1)/(final_visit_number - 1))
              let temp_num = 1
              for (let num = 1; num < final_visit_number; num ++ ) {
                if (final_visit_number - num > a) {
                  weeksArr.push(temp_num + b)
                  temp_num += b
                } else {
                  weeksArr.push(temp_num + b + 1)
                  temp_num += b + 1
                }
              }
            }
          }
        // } else {
        //   //Keep weeks options as much as possible after SOA changed
        //   let week = 0;
        //   if(numbers.visitNumber > editNumbers.visitNumber && numbers.weekNumber > weeks[weeks.length - 1]){
        //     week = Math.floor((numbers.weekNumber - weeks[weeks.length - 1]) / (numbers.visitNumber - editNumbers.visitNumber));
        //   }
        //   let sum = 0;
        //   for (var i = 1; i <= numbers.visitNumber; i++) {
        //     if(weeks.length >= i){
        //       weeksArr.push(weeks[i-1])
        //       sum = weeks[i-1]
        //     } else {
        //       sum += week;
        //       if (sum > numbers.weekNumber || i === numbers.visitNumber) sum = numbers.weekNumber;
        //       weeksArr.push(sum)
        //     }
        //   }
        // }
       setWeeks(weeksArr)
        props.updateSoaActivities({soaWeeks: weeksArr}) 
        setEditNumbers({
          screeningVisitsNumber: numbers.ScreeningVisits,
          treatmentVisitsNumber:  numbers.TreatmentVisits,
          followupVisitsNumber: numbers.FollowupVisits,
          weekNumber: numbers.weekNumber
        })
        setIsUpdateMode(true);
        const scenario =  props.newSoa.scenarios && props.newSoa.scenarios.find(s=> s['ts_id'] == localStorage.getItem('ts_id'));
        const eventsConfigure = scenario['Schedule of Activities']
        if(isEventsAdded(eventsConfigure)){
            setPatientChartColor(aChartColors)
            setBurdenChartColor(burdenColors.active)
            setLabelColors(aLabelColors)
        }else{
            setPatientChartColor(iChartColors)
            setBurdenChartColor(burdenColors.inactive)
            setLabelColors(iLabelColors)
        }
        setEventCriteria({
          'Finished' : false
        })
    
        updateVisitNotes(numbers, weeksArr);
      }
    
      const  handleResetNotes = (obj, idx, isDelete, isAdd, newWeeks) => { 
        let tempPreviousEditNumber = {
          'ScreeningVisits': obj?.evtScreeningVisitsNumber,
          'TreatmentVisits': obj?.evtTreatmentVisitsNumber,
          'FollowupVisits': obj?.evtFollowupVisitsNumber,
          'weekNumber': obj?.evtWeekNumber
        }
        setWeeks(newWeeks)
        if(isDelete) {
          props.updateSoaActivities({ soaVisitNotesList: DeleteVisitItem(idx, tempPreviousEditNumber, newWeeks) })
        } else if(isAdd){
          props.updateSoaActivities({ soaVisitNotesList: AddVisitItem(idx, tempPreviousEditNumber, newWeeks) })
        }
    
    
      }
    
    
      const DeleteVisitItem = (idx, tempPreviousEditNumber, newWeeks) => {
        let notes = [...visitNoteList];
        let visitNotes = notes.map(k => k.visit_notes)
        notes.splice(idx, 1)
        visitNotes.splice(idx, 1)
        notes = notes.map((k, i) => ({...k, visit_notes: visitNotes[i], weeks: newWeeks[i], visit: i + 1}))
        setPreviousCategoryVisits(tempPreviousEditNumber)
        return notes;
      }
    
      const AddVisitItem = (idx, tempPreviousEditNumber, newWeeks) => {
        let notes = [...visitNoteList];
        let toBeArray =  Array.from({ length: 1 }, (v, i) => i).map((k, i) => ({visit_notes: '', weeks : newWeeks[i], visit: idx + 2 }))
        let visitNotes = notes.map(k => k.visit_notes)
        visitNotes.splice(idx+1, 0, ...toBeArray.map(k => k.visit_notes))
        notes.splice(idx+1, 0, ...toBeArray)
        notes = notes.map((k, i) => ({...k, visit_notes: visitNotes[i], weeks: newWeeks[i], visit: i + 1}))
        setPreviousCategoryVisits(tempPreviousEditNumber)
        return notes;
      }
    
      const handleScreeningVisits = async(numbers, Obj,weeksArr) => {
        let{ notes, categories} = Obj;
        if(numbers?.ScreeningVisits > categories.ScreeningVisits) {
          if(numbers?.ScreeningVisits - categories.ScreeningVisits >= 1) {
          let length = numbers?.ScreeningVisits - categories.ScreeningVisits;
          let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => categories.ScreeningVisits + k)
           notes = addNotesCondition( m_arr, weeksArr, notes);
          }
        } else {
          let length = categories.ScreeningVisits - numbers?.ScreeningVisits ;
          let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => numbers?.ScreeningVisits + k )
          notes = deleteNotesCondition( m_arr, weeksArr, notes)
        }
        categories = {
          'ScreeningVisits': numbers?.ScreeningVisits,
          'TreatmentVisits': categories?.TreatmentVisits,
          'FollowupVisits': categories?.FollowupVisits,
          'weekNumber': numbers?.WeekNumber
        }
        return {
          notes,
          categories
        }
      }
    
      const handleTreatmentVisits = async(numbers, Obj,weeksArr) => {
        let{ notes, categories} = Obj
        if(numbers.TreatmentVisits > categories.TreatmentVisits) {
          if(numbers.TreatmentVisits - categories.TreatmentVisits >= 1) {
            let length = numbers?.TreatmentVisits - categories.TreatmentVisits;
            let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => categories.ScreeningVisits + categories.TreatmentVisits + k)
            notes = addNotesCondition( m_arr, weeksArr, notes);
          }
        } else {
          let length = categories.TreatmentVisits - numbers?.TreatmentVisits ;
          let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => numbers?.ScreeningVisits + numbers?.TreatmentVisits + k )
          notes = deleteNotesCondition( m_arr, weeksArr, notes)
        }
        categories = {
          'ScreeningVisits': numbers?.ScreeningVisits,
          'TreatmentVisits': numbers?.TreatmentVisits,
          'FollowupVisits': categories?.FollowupVisits,
          'weekNumber': numbers?.WeekNumber
        }
        return {
          notes,
          categories
        }
      }
    
      const handleFollowUpVisits = async(numbers, Obj,weeksArr) => {
        let{ notes, categories} = Obj
        if(numbers.FollowupVisits > categories.FollowupVisits) {
          if(numbers.FollowupVisits - categories.FollowupVisits >= 1) {
            let length = numbers?.FollowupVisits - categories.FollowupVisits;
            let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => categories.ScreeningVisits + categories.TreatmentVisits + categories.FollowupVisits + k);
            notes = addNotesCondition( m_arr, weeksArr, notes);
        }
        } else {
           let length = categories.FollowupVisits - numbers?.FollowupVisits ;
          let m_arr =  Array.from({ length: length }, (v, i) => i).map((k, i) => numbers?.ScreeningVisits + numbers?.TreatmentVisits + numbers?.FollowupVisits + k )
          notes = deleteNotesCondition( m_arr, weeksArr, notes)
        }
        categories = {
          'ScreeningVisits': numbers?.ScreeningVisits,
          'TreatmentVisits': numbers?.TreatmentVisits,
          'FollowupVisits': numbers?.FollowupVisits,
          'weekNumber': numbers?.WeekNumber
        }
        return {
          notes,
          categories
        }
      }
    
      const addNotesCondition = ( m_arr, weeks, visitsNotes) => {
        let notes = [...visitsNotes];
        let toBeArray =  Array.from({ length: m_arr.length }, (v, i) => i).map((k, i) => ({visit_notes: '', weeks : weeks[m_arr[k]], visit: m_arr[k] + 1 }))
        let visitNotes = notes.map(k => k.visit_notes)
        visitNotes.splice(m_arr[0], 0, ...toBeArray.map(k => k.visit_notes))
        notes.splice(m_arr[0], 0, ...toBeArray)
        notes = notes.map((k, i) => ({...k, visit_notes: visitNotes[i], weeks: weeks[i], visit: i + 1}))
        return notes;
      }
      useEffect(() => {
        setVisitNoteList(props.newSoa.soaVisitNotesList)
      }, [props.newSoa.soaVisitNotesList])
    
      const updateVisitNotes = async (numbers,weeksArr) => {
        let notesByVisitsObj = {
          notes: [...visitNoteList],
          categories: {...prevCategoryVisists}
          }
        notesByVisitsObj = await handleScreeningVisits(numbers, notesByVisitsObj, weeksArr)
        notesByVisitsObj = await handleTreatmentVisits(numbers, notesByVisitsObj, weeksArr)
        notesByVisitsObj = await handleFollowUpVisits(numbers, notesByVisitsObj,  weeksArr)  
        props.updateSoaActivities({ soaVisitNotesList: notesByVisitsObj.notes })
        setPreviousCategoryVisits(notesByVisitsObj.categories);
      }
    
      let getVisitData = (z) => {
        return visitNoteList?.find((k, i) => k.visit == visits[z])?.visit_notes;
      }
    
        useEffect(() => {
        if(visits?.length == 0 || weeks?.length == 0 || weeks.length !== visits.length) return;
          let n_visists = visits?.map((k, i) => ({visit_notes: getVisitData(i) ??  '', weeks:weeks[i], visit: visits[i] }));
          let arr = n_visists?.length > 0 ? n_visists : []
          setVisitNoteList(arr)
      }, [visits, weeks]);

      useEffect(() => {
        if(weeks?.length > 0) {
          props.updateSoaActivities({soaWeeks :  weeks})
        }
      }, [weeks]);
    
    
      const deleteNotesCondition = ( m_arr, weeks, visitsNotes) => {
        if(visitNoteList.length == 0) return
        let notes = [...visitsNotes];
        let visitNotes = notes.map(k => k.visit_notes)
        notes.splice(m_arr[0], m_arr.length)
        visitNotes.splice(m_arr[0], m_arr.length)
        notes = notes.map((k, i) => ({...k, visit_notes: visitNotes[i], weeks: weeks[i], visit: i + 1}))
        return notes;
      }
    
      const exportEvent = () =>{
        let str='Schedule of Activities\n' + ',' + ',' + 'Visit'
        for(let i = 1; i <= numbers.visitNumber; i ++){
          str += ',' + i
        }
    
        str += '\n' + ',' + ',' + 'Week'
        for(let w = 0; w < weeks.length; w ++){
          str += ',' + weeks[w]
        }
    
        str += '\n' + ',' + ',' + 'Burden'
        for(let b = 0; b < burdenData.length; b ++){
          str += ',' + burdenData[b]
        }
    
        str += '\n' + 'Category' + ',Activity' + ',Cost'
    
        str += getEventContent(CATEGORY_LABS, addedLabs)
        str += getEventContent(CATEGORY_PHYSICAL_EXAMINATION, addedExamination)
        str += getEventContent(CATEGORY_PROCEDURES, addedProcedures)
        str += getEventContent(CATEGORY_QUESTIONNAIRES, addedQuestionnaires)
        str += getEventContent(CATEGORY_STUDY_PROCEDURES, addedStudyProcedures)
    
        let exportContent = "\uFEFF";
          let blob = new Blob([exportContent + str], {
            type: "text/plain;charset=utf-8"
          });
    
          const date = Date().split(" ");
          const dateStr = date[1] + '_' + date[2] + '_' + date[3] + '_' + date[4];
          FileSaver.saveAs(blob, `SoA_${dateStr}.csv`);
      }
    
      function getEventContent(catrgory, events) {
        let subStr = ''
        for(const event in events){
          subStr += '\n' + catrgory + ',"' + events[event]['Standard Activity'] + '",' + events[event]['Dummy Cost']
          if(events[event]['condition'].length > 0){
            for(const idx in events[event]['condition']){
              subStr += ',' + (events[event]['condition'][idx].checked ? 'x' : '')
            }
          }
        }
        return subStr
      }
    
      const formatter = (value) => {
        return value+'%'
      }
    
      const getFrequency = (value) => {
    
        setMinV(value[0])
        setMaxV(value[1])
    
        // setFilteredLabs(filterLibs(orgLabs, addedLabs, value[0], value[1]))
        // setFilteredExamination(filterLibs(orgExamination, addedExamination, value[0], value[1]))
        // setFilteredQuestionnaires(filterLibs(orgQuestionnaires, addedQuestionnaires, value[0], value[1]))
        // setFilteredProcedures(filterLibs(orgProcedures, addedProcedures, value[0], value[1]))
        // setFilteredStudyProcedures(filterLibs(orgStudyProcedures, addedStudyProcedures, value[0], value[1]))
      }
    
    
      const onInsightClick = (item) => {
        let rawInsight = [...insightEvent]
        let resData
        rawInsight = rawInsight.filter((d) => {
         return d.activity!== item.activity
        })
        setInsightEvent(rawInsight)
        const handleInsightChange = (orgCategoryData, filteredCategoryData, addedCategoryData) => {
          let detailCategoryData
          let indexDetailCategoryData
          indexDetailCategoryData = orgCategoryData.findIndex((d) => item['activity'] == d['Standard Activity'])
          indexDetailCategoryData > -1? detailCategoryData = orgCategoryData[indexDetailCategoryData] : []
          let index = filteredCategoryData.findIndex((d) => item['activity'] == d['Standard Activity'])
          let newData = [...filteredCategoryData]
          const newSelectedData = [...addedCategoryData]
          if(item.action === "REMOVE"){
            newData.splice(index, 1, { ...detailCategoryData, ...{selected: false, condition: [], totalVisit: 0}});
            let selectedIndex = addedCategoryData.findIndex((d) => item['activity'] == d['Standard Activity'])
            newSelectedData.splice(selectedIndex, 1)
          } else {
            newData.splice(index, 1, { totalVisit: 0, condition: [], ...detailCategoryData, selected: true});
            if(newSelectedData.findIndex((d) => item['activity'] == d['Standard Activity']) === -1) {
              // To avoid duplicate events added
              detailCategoryData&&newSelectedData.push(Object.assign({ totalVisit: 0, condition: [] }, detailCategoryData, { selected: true }))
            }
          }
          return {newData, newSelectedData}
        }
        let { ScreeningVisits, TreatmentVisits, FollowupVisits } = numbers
        let visitArr = [];
          let vNumber = ScreeningVisits + TreatmentVisits + FollowupVisits; 
             for (let i = 0; i <= vNumber-1; i++) {
            visitArr.push(i+1)
          }
          props.updateSoaActivities({
            soaVisits: visitArr,
          })
        switch(item.category.trim()){
          case "Labs":
            resData = handleInsightChange(orgLabs, filteredLabs, addedLabs)
            setFilteredLabs(resData.newData)
            setAddedLabs(resData.newSelectedData)
            props.updateSoaActivities({
              soaAddedLabs: resData.newSelectedData, 
              soaFilteredLabs: resData.newData
            });

            break;
          case "Physical Examination":
            resData = handleInsightChange(orgExamination, filteredExamination, addedExamination)
            setFilteredExamination(resData.newData)
            setAddedExamination(resData.newSelectedData)
            props.updateSoaActivities({
              soaAddedExamination: resData.newSelectedData, 
              soaFilteredExamination: resData.newData
            });

            break;
          case "Procedures":
            resData = handleInsightChange(orgProcedures, filteredProcedures, addedProcedures)
            setFilteredProcedures(resData.newData)
            setAddedProcedures(resData.newSelectedData) 
            props.updateSoaActivities({
              soaAddedProcedures: resData.newSelectedData, 
              soaFilteredProcedures: resData.newData
            });
            break;
          case "Questionnaires":
            resData = handleInsightChange(orgQuestionnaires, filteredQuestionnaires, addedQuestionnaires)
            setFilteredQuestionnaires(resData.newData)
            setAddedQuestionnaires(resData.newSelectedData)
            props.updateSoaActivities({
              soaAddedQuestionnaires: resData.newSelectedData, 
              soaFilteredQuestionnaires: resData.newData
            });

            break;
          case "Study Procedures":
            resData = handleInsightChange(orgStudyProcedures, filteredStudyProcedures, addedStudyProcedures)
            setFilteredStudyProcedures(resData.newData)
            setAddedStudyProcedures(resData.newSelectedData)
            props.updateSoaActivities({
              soaAddedStudyProcedures: resData.newSelectedData, 
              soaFilteredStudyProcedures: resData.newData
            });

            break;
          default: break;
        }
        handleEventChange([])
        }
    
      const onDismiss = (key) => {
        let rawList = [...dismissEvent]
        rawList.push(key)
        setDismissEvent(rawList)
        let rawInsight = [...insightEvent]
        rawInsight = rawInsight.filter((d) => {
         return d.activity!== key.activity
        })
        setInsightEvent(rawInsight)
      }

      const handleCancel = () => {
        setShowConfigure(false)
      };

      const onViewImpactSummaryClick = () => {
        // setSubmitType(3)
        if(props.newSoa?.event_numbers?.weeks?.length > 0) {
          props.updateSoaActivities({soaWeeks :  props.newSoa.event_numbers?.weeks}) 
        }
        props.history.push({
          pathname: '/chart-summary',
          state: {
            trial_id: props.record['trial_uuid'] ,
            record: props.record,
            visits: visits,
            modalityChartData: modalityChartData
          }
        })
      };

      const viewImpactSummary = () => {
        props.updateSoaActivities(initialSoa)
        props.history.push({
          pathname: '/chart-summary',
          state: {
            trial_id: props.record['trial_uuid'] ,
            record: props.record,
            visits: visits,
            modalityChartData: modalityChartData
          }
        })
      };

      const filterAddedInsights = (insights) => insights.filter((insight) => {
        if (insight.category === CATEGORY_LABS && insight.action === "ADD") {
          return !!!addedLabs.find(item => item['Standard Activity'] === insight.activity);
        }

        if (insight.category === CATEGORY_PHYSICAL_EXAMINATION && insight.action === "ADD") {
          return !!!addedExamination.find(item => item['Standard Activity'] === insight.activity);
        }

        if (insight.category === CATEGORY_PROCEDURES && insight.action === "ADD") {
          return !!!addedProcedures.find(item => item['Standard Activity'] === insight.activity);
        }

        if (insight.category === CATEGORY_QUESTIONNAIRES && insight.action === "ADD") {
          return !!!addedQuestionnaires.find(item => item['Standard Activity'] === insight.activity);
        }

        if (insight.category === CATEGORY_STUDY_PROCEDURES && insight.action === "ADD") {
          return !!!addedStudyProcedures.find(item => item['Standard Activity'] === insight.activity);
        }

        if (insight.category === CATEGORY_LABS && insight.action === "REMOVE") {
          return !!addedLabs.find(item => item['Standard Activity'] === insight.activity);
        }

        if (insight.category === CATEGORY_PHYSICAL_EXAMINATION && insight.action === "REMOVE") {
          return !!addedExamination.find(item => item['Standard Activity'] === insight.activity);
        }

        if (insight.category === CATEGORY_PROCEDURES && insight.action === "REMOVE") {
          return !!addedProcedures.find(item => item['Standard Activity'] === insight.activity);
        }

        if (insight.category === CATEGORY_QUESTIONNAIRES && insight.action === "REMOVE") {
          return !!addedQuestionnaires.find(item => item['Standard Activity'] === insight.activity);
        }

        if (insight.category === CATEGORY_STUDY_PROCEDURES && insight.action === "REMOVE") {
          return !!addedStudyProcedures.find(item => item['Standard Activity'] === insight.activity);
        }
      });

      const [excluActiveKey, setExcluActiveKey] = useState(['1'])
      function excluCallback(key) {
        setExcluActiveKey(key)  
      }
    
    return (  
    <>
      <div className="soa-activities-container">
        <div className="scenarios-actions-container"> 
            <Col span={soeLibrarayPanelStatus ? 18 : 23} style={{paddingRight: '2%'}}>
              <Row>
                <Col span={20}>
                <div className="scenarios-heading-left"> 
              {/* <span> Scenario Impact Summary </span> */}
            
                </div>
                </Col>
                <Col span={3}>
                <div className="scenarios-heading-right"> 
                    <div className='btn-scenarios-container-header'>
                      <Button className="soa-btn"  onClick={onViewImpactSummaryClick} style={{zIndex: 1}}> View SoA Impact </Button>   
                    </div> 
                </div>
              </Col>
            </Row> 
            <Collapse activeKey={excluActiveKey} onChange={excluCallback} expandIconPosition="right" >
              <Panel header={<span style={{fontSize:'26px'}}>Scenario Impact Summary</span>} key="1" forceRender={false} >
                <Row className="chart-summary-container summary-container">
                   <Col span={11} className={`${hiddeTags ? 'hidde' : ''} chart-wrapper left-chart` }> 
                    { ( localStorage.getItem('ts_id') !== "") && ( <SoaAtGlance saveCostChart={saveCostChart} trialId={props.trial_uuid} scenarioId={localStorage.getItem('ts_id')} />)} 
                    </Col>

                      <Col span={11} className={`${hiddeTags ? 'hidde' : ''} chart-wrapper` }>
                        <div className="chart-header">
                          <div className="title" >
                          Activities by Modality
                          </div>
                        </div>
                      { modalityOptions && ( <ActivitiesByModality visits={visits} modalityChartData={modalityChartData} />)}
                      </Col>
                </Row>  
              </Panel>
            </Collapse>
         
            {props.newSoa && (<NewEventList 
              endpoints={endpoints}
              saveEvents={saveEvents}
              handleEventChange={handleEventChange}
              numbers={editNumbers}
              labs={addedLabs}
              examination={addedExamination}
              procedures={addedProcedures}
              questionnaire={addedQuestionnaires}
              studyProcedures={addedStudyProcedures}
              weeks={weeks}
              visits={visits}
              submitType={submitType}
              updateWeeks={setWeeks}
              hiddeTags={hiddeTags}
              numbersData={numbers}
              showConfigureModal={showConfigureModal}
              modalityOptions={modalityOptions}
              handleEvent={handleEvent}
              readOnlyUser={props.record.trial_user_role}
              windowResize={windowResize}
              visitNoteListHandler={handleVisitNoteList}
              // visitsList={visitNoteList}
              visitsList={props?.newSoa?.soaVisitNotesList}
              resetNotes={handleResetNotes}
              soeReadOnly={props.readOnly}
              soeLibrarayPanelStatus={soeLibrarayPanelStatus}
              orgLabs={orgLabs}
              orgExamination={orgExamination}
              orgProcedures={orgProcedures}
              orgQuestionnaires={orgQuestionnaires}
              orgStudyProcedures={orgStudyProcedures}
              filteredLabs={props?.newSoa?.soaFilteredLabs}
              filteredExamination={props?.newSoa?.soaFilteredExamination}
              filteredQuestionnaires={props?.newSoa?.soaFilteredQuestionnaires}
              filteredProcedures={props?.newSoa?.soaFilteredProcedures}
              filteredStudyProcedures={props?.newSoa?.soaFilteredStudyProcedures}
              similarHistoricalTrials= {props.nctList}
              trial_id={props.trial_uuid}
              scenarioId={localStorage.getItem('ts_id')} 
              scenarioType={props.scenarioType}
            />)}
           
            </Col>
              
            <Col span={soeLibrarayPanelStatus ? 6: 1} style={{background: '#FFFFFF'}} className="right-insight-container">
              <Row>
              {eventPanel=="2"&&(
            <Col span={20} className="left-insight" style={{
              backgroundColor: '#F8F8F8'
            }}>
              <Row style={{backgroundColor: '#F8F8F8'}}>
                <Col span={24}>
                  <div className="item-header">
                    <span>Insights ({insightEvent?filterAddedInsights(insightEvent).length:0 })</span>
                    <div className="action-wrapper"></div>
                    {soeLibrarayPanelStatus && <div className="action-wrapper"></div>}
                    {(soeLibrarayPanelStatus && eventLib === 6 )&&(<span className="action-title" onClick={() =>{setEventPanel("1"); soeInsightsToggleHandler(false)}}>
                        <LeftOutlined style={{color:"#CA4A04"}}/>
                      </span>)}
                  </div>
                </Col>
                <Col span={24} className="content-outer insight-box-wrapper">
                  { insightEvent && filterAddedInsights(insightEvent).map((o) => {
                    return o.action === "ADD" ? (
                      <div className="insight-box">
                        <div className="head">SUGGESTED TO ADD</div>
                        <div className="content"><b>{o.activity}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider adding it to your scenario.</div>
                        <div className="button-area">
                          <span className="button-left" onClick={e=>onInsightClick(o)}>
                            <PlusOutlined style={{margin:"0 3px 0 0"}}/> Add Activity
                          </span>
                          <span className="button-right" onClick={e=>onDismiss(o)}>
                            <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                          </span>
                        </div>
                      </div>
                    ):
                    (
                      <div className="insight-box">
                        <div className="head">SUGGESTED TO REMOVE</div>
                        <div className="content"> <b>{o.activity}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider removing it from your scenario.</div>
                        <div className="button-area">
                          <span className="button-left" onClick={e=>onInsightClick(o)}>
                            <MinusOutlined style={{margin:"0 3px 0 0"}}/> Remove Activity
                          </span>
                          <span className="button-right" onClick={e=>onDismiss(o)}>
                            <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </Col>
              </Row>
            </Col>
            )}

            <Col span={1} className="right-icon" style={{maxWidth: '50px', minWidth: '50px', background: '#FFFFFF'}}>
                <div className={`side-toolbar ${eventLib > 0 ? '' : ''}`}> 
                <Badge style={{marginTop: '20px'}} count={insightEvent?filterAddedInsights(insightEvent).length:0} showZero={insightEvent && filterAddedInsights(insightEvent).length == 0 ? true : false }>
                <Tooltip placement="right" title={'Insights'}>
                  <CarryOutFilled style={{color:`${eventPanel==="2"?"#404042":"#999999"}`, fontSize: "18px", marginLeft: "15px", marginTop: "20px", paddingTop: "12px", cursor: "pointer"}} onClick={()=> {
                  setEventPanel("2")
                  soeInsightsToggleHandler(true)
                    }}/>
                </Tooltip>
                </Badge>
                {!soeLibrarayPanelStatus &&  <div className="action-wrapper"></div>} 
              </div>
            </Col>
              </Row> 
           </Col>
        {/* </Row>  */}
      </div>
    </div>

    <Modal visible={showConfigure} title="" 
    closable={
       (editNumbers.screeningVisitsNumber > 0 &&
        editNumbers.treatmentVisitsNumber > 0 &&
        editNumbers.followupVisitsNumber > 0 &&
        editNumbers.weekNumber > 0)? true :false} 
        mask={false} 
        onCancel={handleCancel} 
        footer={null} 
        style={{ top: 20 }} 
        centered={false} 
        maskClosable={false}
        wrapClassName="soaModal"
        >
      <Row className="configure-title">
      <span>Configure Schedule Of Activities Table</span>
      </Row>
      <br/>
      <Row className="modal-filed">
        <Col span={12} className="label"><span>Number of Screening Visits</span></Col>
        <Col span={12} className="input-number"><InputNumber  step={1} onStep={onStepScreeningVisit} onChange={validateScreeningVisit} value={numbers.ScreeningVisits} /></Col>
        <Col span={24} className="input-number-error"><span>{numberOfScreeningVisitsError}</span></Col>
      </Row>

      <Row className="modal-filed">
        <Col span={12} className="label"><span>Number of Treatment Visits</span></Col>
        <Col span={12} className="input-number"><InputNumber  step={1} onStep={onStepTreatmentVisit} onChange={validateTreatmentVisit} value={numbers.TreatmentVisits} /></Col>
        <Col span={24} className="input-number-error"><span>{numberOfTreatmentVisitsError}</span></Col>
      </Row>

      <Row className="modal-filed">
        <Col span={12} className="label"><span>Number of Follow-up Visits</span></Col>
        <Col span={12} className="input-number"><InputNumber  step={1} onStep={onStepFollowUpVisit} onChange={validateFollowUpVisit} value={numbers.FollowupVisits} /></Col>
        <Col span={24} className="input-number-error"><span>{numberOfFollowupVisitsError}</span></Col>
      </Row>

      <Row className="modal-filed">
        <Col span={12} className="label"><span>Number of Weeks</span></Col>
        <Col span={12} className="input-number"><InputNumber step={1} onStep={onStepWeek} onChange={validateWeek} value={numbers.weekNumber} /></Col>
        <Col span={24} className="input-number-error"><span>{numberOfWeeksError}</span></Col>
      </Row>
      <Row style={{justifyContent: 'center', paddingTop: '20px'}}>
        <Button type="primary" className="step-btn create-btn" onClick={handleOk}>
          {  (editNumbers.screeningVisitsNumber > 0 &&
              editNumbers.treatmentVisitsNumber > 0 &&
              editNumbers.followupVisitsNumber > 0 &&
              editNumbers.weekNumber > 0 ) || isUpdateMode ? 'UPDATE' : 'CREATE'}
        </Button>
      </Row>
    </Modal>
    </>)
}

const mapDispatchToProps = (dispatch) => ({
    createTrial: (val) => dispatch(createActions.createTrial(val)),
    createSoa: (val) => dispatch(createActions.createSoa(val)),
    updateSoaActivities: (val) => dispatch(createActions.updateSoaActivities(val))
  });
  
  const mapStateToProps = (state) => ({
    newTrial: state.trialReducer,
    newSoa: state.soaReducer
  });
  export default connect(mapStateToProps, mapDispatchToProps)(ScheduleActivities);

 
