import { CREATE_TRIAL, CREATE_SOA, UPDATE_SOA_ACTIVITIES, UPDATE_IE_ACTIVITIES } from '../constants/index';

export function createTrial(data) {
	return{
		type:CREATE_TRIAL,
		data
	}
}

export function createSoa(data) {
	return{
		type:CREATE_SOA,
		data
	}
}


export function updateSoaActivities(payload) {
	return{
		type:UPDATE_SOA_ACTIVITIES,
		payload
	}
}

export function updateIEActivities(payload) {
	return{
		type:UPDATE_IE_ACTIVITIES,
		payload
	}
}
