/* eslint-disable */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Input, Select, Button, Tooltip, Typography } from "antd";
import { SettingOutlined, EditFilled } from "@ant-design/icons";
import moment from 'moment-timezone';
import { addStudy } from "../../utils/ajax-proxy";
import "./index.scss";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";

const { Option } = Select;
const { Paragraph, Text } = Typography;

const validateFields = ["trial_title", "therapeutic_area", "indication"];
const requiredStringFields = ["trial_title"];
const requiredListFields = ["indication","therapeutic_area"];

const TrialDetails = (props) => {

  const [editable, setEditable] = useState(false);

  //   Dictionary for storing all trial details
  const [allStudyPhase, setAllStudyPhase] = useState({});
  const [allStudyType, setAllStudyType] = useState({});
  const [allStudyCountry, setAllStudyCountry] = useState({});
  const [allIndication, setAllIndication] = useState({});

  //   Usestate for error variables
  const [trialTitleError, setTrialTitleError] = useState("");
  const [therapeuticAreaError, setTherapeuticAreaError] = useState("");
  const [indicationError, setIndicationError] = useState("");
  const [hasTemplate, setHasTemplate] = useState(false);

  const { record, onSave, onInputChange, onSelectChange, onEditDetail } = props;

  useEffect(()=> {
    const templateScenario = record.scenarios.find(s=> s.is_template)
    setHasTemplate(templateScenario ? true: false)
  },[record.scenarios])

  useEffect(() => {
      //  console.log('A field is changed')
    }, [trialTitleError, therapeuticAreaError, indicationError])

  const getTitleCase = (val) => {
      let array = val.split("_")
      let firstVal = array[0].charAt(0).toUpperCase() + array[0].slice(1);
      if(array.length < 2){ return firstVal }
      let lastVal = array[1].charAt(0).toUpperCase() + array[1].slice(1);
      return firstVal + " " + lastVal;
  };

  const circleColor = [
    { color: '#FA5927' },
    { color: '#DC6700' },
    { color: '#CA4A04' },
    { color: '#FFBD45' },
    { color: '#C33232' }
  ];

  const onEditEndpoint = (v) => {
    if(props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') return;
    // onEditDetail("edit_endpoints",v)
    props.history.push({
      pathname: '/new-trial',
      state: {
        title: "edit_endpoints", 
        data: props.record,
        // trial_id: props.location.state.trial_id,
      }
    })
  };

  const onEditSimiliarTrial = (v) => {
    if(props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') return;
    // onEditDetail("edit_similiarTrials",v)
    props.history.push({
      pathname: '/new-trial',
      state: {
        title: "edit_similiarTrials", 
        data: props.record,
        // trial_id: props.location.state.trial_id,
      }
    })
  };

  const onEditTeamMember = (v) => { 
    if(props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') return;   
    // onEditDetail("edit_teamMembers",v)
    props.history.push({
      pathname: '/new-trial',
      state: {
        title: "edit_teamMembers", 
        data: props.record,
        // trial_id: props.location.state.trial_id,
      }
    })
  }

  const [editDetailsDisable, setEditDetailsDisable] = useState(false)

  const onEdit = () => {
     // setDisabled(false)
     if(props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') return;
    //  setEditable(true);
    //  onEditDetail("edit_details", props.record)
     props.history.push({
      pathname: '/new-trial',
      state: {
        title: "edit_details", 
        data: props.record,
        // trial_id: props.location.state.trial_id,
      }
    })
     setEditDetailsDisable(false)  
  };

  useEffect(() => {
    if(props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') { 
      setEditDetailsDisable(true);
    } else {
      setEditDetailsDisable(false);
    }
  }, [props])

  useEffect(() => {
    let studyPhaseDict = {};
    let studyTypeDict = {};
    let studyCountryDict = {};
    let indicationDict = {};
    if(props.studyPhaseList != undefined && props.studyPhaseList.length > 0){
        props.studyPhaseList.map((o) => {studyPhaseDict[o.value] = o.label});
        props.studyTypeList.map((o) => {studyTypeDict[o.value] = o.label});
        props.studyCountryList.map((o) => {studyCountryDict[o.value] = o.label});
        props.indicationList.map((o) => {indicationDict[o.value] = o.label});
    }else if(props.newTrial.studyPhaseList != undefined && props.newTrial.studyPhaseList.length > 0){
        props.newTrial.studyPhaseList.map((o) => {studyPhaseDict[o.value] = o.label});
        props.newTrial.studyTypeList.map((o) => {studyTypeDict[o.value] = o.label});
        props.newTrial.studyCountryList.map((o) => {studyCountryDict[o.value] = o.label});
        props.newTrial.indicationList.map((o) => {indicationDict[o.value] = o.label});
    }
    if(props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') {
      setEditDetailsDisable(true);
    } else {
      setEditDetailsDisable(false);
    }
    setAllStudyPhase(studyPhaseDict);
    setAllStudyType(studyTypeDict);
    setAllStudyCountry(studyCountryDict);
    setAllIndication(indicationDict);
  }, [props.studyPhaseList,props.newTrial.studyPhaseList,props.record])

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('-') +
      ' ' +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(':')
    );
  }

  const handleValidation = (key, v) => {
        let errorMsg = "";
        if(requiredStringFields.includes(key) && !record[key]){
            errorMsg = getTitleCase(key) + " is required."
        }else if(requiredListFields.includes(key) && record[key].length < 1){
            errorMsg = getTitleCase(key) + " is required."
        }else if (key == "trial_title" && typeof record[key] === 'string' && record[key].trim().length > 300) {
            errorMsg = getTitleCase(key) + " must not exceed 300 characters."
        }else if (key == "indication" && record['indication'].length <1) {
          errorMsg = "Indication is required."
        }else if (key == "therapeutic_area" && record['therapeutic_area'].length <1) {
          errorMsg = "Therapeutic Area is required."
        }        
        if(key == "trial_title"){setTrialTitleError(errorMsg)}
        else if(key == "therapeutic_area"){setTherapeuticAreaError(errorMsg)}
        else if(key == "indication"){setIndicationError(errorMsg)}
        return errorMsg == ""
  };

  const handleSave = async () => {
    let allInputsValid = true;
    for(let key of validateFields){
      if(!handleValidation(key, null)){
        allInputsValid = false;
      }
    }
    let therapeuticAreaList = []
    const iterator = record["therapeutic_area"].length==1 && record["therapeutic_area"][0]=='All'?
                        Object.keys(props.indicationTherapeuticMap) : record["therapeutic_area"];
	for(let therapeuticArea of iterator){
		if(props.indicationTherapeuticMap[therapeuticArea].some(ind => record["indication"].includes(ind.value))){
			therapeuticAreaList.push(therapeuticArea)
		}
	}
	record["therapeutic_area"] = therapeuticAreaList;

    if(allInputsValid){
      record.lastUpdatedDate = moment().utc().format('YYYY-MM-DD HH:mm:ss')
      await onSave(record["trial_uuid"]);
      // setDisabled(true)
      setEditable(false);
    }
  };

  return (
    <div className="trial-detail-container">
      <div className="top">
        <div className="title">
          <span className="nct-id">{record["nct_id"] || "-"}</span>
          <br />
          <span className="trail-alias">
            {record["trial_alias"]&&record["trial_alias"].length > 70?(
              <Text
                style={{width: 800, fontSize:28, color:'#333'}}
                ellipsis={{tooltip:record["trial_alias"]}}
              >
                {record["trial_alias"] || "-"}
              </Text>
            ):(
              <span>{record["trial_alias"] || "-"}</span>
            )}
          </span>
          <span className="status">
            <i className={`${props.record.status !=="Completed" ? "in-progress-icon":'complete-icon'} my_icon`}></i>{record["status"] || "-"}
           </span>
           <span className="team-member-wrapper">
             {record["members"].map((item, index) => (
                <span className="user_tag" key={item.name?item.name:item} style={{backgroundColor: circleColor[index%5].color, zIndex: record["members"].length + 1 - index }} >
                {item.name ? item.name.split('@')[0].split('.').map((namePart) => (namePart[0].toUpperCase())).join('') : item.split('@')[0].split('.').map((namePart) => (namePart[0].toUpperCase())).join('')}
              </span>
             ))}
             {
               !(props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() === 'read') && (<span className={`user_tag button ${props.record.status==="In Progress" || "Completed" ? '': 'hidden'}`} onClick={()=>onEditTeamMember(record)}>
               <EditFilled className="icon"/>
               </span>)
             }
          </span>
          <br />
          <span className="update-time">
            Last updated {record.lastUpdatedDate?moment.utc(record.lastUpdatedDate).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'):'-'}
          </span>
        </div>
        <div>
        <Tooltip
            placement="bottom"
            color="#ffffff"
            overlayClassName="edit-detail-tooltip"
            title={
              <div className={`${editDetailsDisable ? 'action-list_disabled' : 'action-list' } `}> 
                <div onClick={onEdit}>Edit Details</div>
                <div onClick={()=>onEditEndpoint(record)}>Edit Endpoints</div>
                <div onClick={()=>onEditSimiliarTrial(record)}>Edit Similar Historical Trials</div>
                <div onClick={()=>onEditTeamMember(record)}>Edit Team Members</div>              
              </div>
            }
          >
            <SettingOutlined className={`setting-action ${props.record.status==="In Progress" ? '': 'hidden'}`}/>
          </Tooltip>
        </div>
      </div>
      <div className="info">
        <div className="info-row">
          <div className="trial-item">
            <label>Trial Title <span className="required">*</span> </label>
            {editable ? (
              <>
              <Input
                defaultValue={record["trial_title"]}
                onChange={(e) => onInputChange("trial_title", e)}
                style={{ width: 200, height: 30 }}
                onBlur={(v) => handleValidation("trial_title", v)}
              />
              </>
               
            ) : (
              <span className="readonly-value trial-title-text">
                <Text
                  style={{width: 260, fontSize:15, color:'#333'}}
                  ellipsis={{tooltip:record["trial_title"]}}
                >
                  {record["trial_title"] || "-"}
                </Text>
              </span>
            )}
          <br/>
          <span className="trial-details-error">{trialTitleError}</span>
          </div>
          <div className="trial-item">
            <label>Study Phase</label>
            {editable ? (
              <Select
                mode="multiple"
                allowClear
                showSearch
                style={{ width: 200 }}
                virtual={false}
                onChange={(e) => onSelectChange("study_phase", e)}
                // options={props.studyPhaseList}
                defaultValue={record["study_phase"].filter(i => allStudyPhase[i]!=undefined)}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {props.studyPhaseList.map((o) => {
                    return (
                      <Option value={o.value} key={o.value}>
                        {o.label}
                      </Option>
                    );
                  })}
              </Select>
            ) : (
              <span className="readonly-value">
              {!record["study_phase"] && "-"}
              {
                  typeof record["study_phase"] =='string' && record["study_phase"]
                }
                {
                  typeof record["study_phase"] == 'object' ? (
                    <>
                      {record["study_phase"] && record["study_phase"].length > 0 && record["study_phase"].map(i => allStudyPhase[i]).filter(i => i!=undefined).join(", ") }
                    </>
                  ):''
                }
            </span>
            )}
          </div>
          <div className="trial-item">
            <label>Study Type</label>
            {editable ? (
              <Select
                mode="multiple"
                allowClear
                showSearch
                style={{ width: 200 }}
                virtual={false}
                onChange={(e) => onSelectChange("study_type", e)}
                // options={props.studyTypeList}
                defaultValue={record["study_type"].filter(i => allStudyType[i]!=undefined)}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {props.studyTypeList.map((t) => {
                    return (
                      <Option value={t.value} key={t.value}>
                        {t.label}
                      </Option>
                    );
                  })}
              </Select>
            ) : (
                <span className="readonly-value">
                {!record["study_type"] && "-"}
                {
                    typeof record["study_type"] =='string' && record["study_type"]
                  }
                  {
                    typeof record["study_type"] == 'object' ? (
                      <>
                        {record["study_type"] && record["study_type"].length > 0 && record["study_type"].map(i => allStudyType[i]).filter(i => i!=undefined).join(", ") }
                      </>
                    ):''
                  }
              </span>
              )}
          </div>
          <div className="trial-item trial-item-hide">
            <label>Similar Historical Trials</label>
            {<span className="readonly-value">
              <b>{record["similar_history_count"]&&record["similar_history_count"].total||"-"}</b> Trials, <b>{record["similar_history_count"]&&record["similar_history_count"].SOA||"-"}</b> with SoA
            </span>}
            {/* <label>Study Country</label>
            {editable ? (
              <Select
                defaultValue={record["study_country"]}
                style={{ width: 200 }}
                onChange={(e) => onSelectChange("study_country", e)}
                options={props.studyCountryList}
              >
              </Select>
            ) : (
              <span className="readonly-value">{allStudyCountry[record["study_country"]] || "-"}</span>
            )} */}
          </div>
        </div>
        <div className="info-row">
          <div className="trial-item">
            <label>Pediatric Study</label>
            {editable ? (
              <Select
                value={record["pediatric_study"] === null?"null":record["pediatric_study"].toString()}
                style={{ width: 200 }}
                onChange={(e) => onSelectChange("pediatric_study", e)}
              >
                <Option value="null">All</Option>
                <Option value="true">Yes</Option>
                <Option value="false">No</Option>
              </Select>
            ) : (
              <span className="readonly-value">
                {record["pediatric_study"] === null?"All":record["pediatric_study"].toString()=="false"?"No":(record["pediatric_study"].toString()=="true"?"Yes": "All")}
              </span>
            )}
          </div>
          <div className="trial-item">
            <label>Indication <span className="required">*</span></label>

            {editable ? (
              <>
              <Select
                mode="multiple"
                allowClear
                showSearch
                style={{ width: 200 }}
                virtual={false}
                // defaultValue={record["indication"].filter(i => allIndication[i]!=undefined)}
                disabled={ typeof record["therapeutic_area"] == 'object' && record["therapeutic_area"].length > 0 ? false : true}
                value={record["indication"]}
                onChange={(v) => onSelectChange("indication", v)}
                // options={props.indicationList}
                onBlur={(v) => handleValidation("indication", v)}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {props.indicationList.map((t) => {
                    return (
                      <Option value={t.value} key={t.value}>
                        {t.label}
                      </Option>
                    );
                  })}
              </Select>
              {hasTemplate && (
                     <div className="indication-warning">
                       <span className="asterisk">*</span>
                       This trial has a scenario marked as template. Changing trial properties will have downstream effects!
                     </div>
                   )}    
               </> 
            ) : (
                <span className="readonly-value">
                  {
                    typeof record["indication"] =='string' && record["indication"]
                  }
                  {
                    typeof record["indication"] == 'object' ? (
                      <>
                        {record["indication"] && record["indication"].length > 0 && record["indication"].map(i => allIndication[i]).filter(i => i!=undefined).join(", ") }
                      </>
                    ):''
                  }
              </span>
            )}
          <br/>
          <span className="trial-details-error">{indicationError}</span>
          </div>
          <div className="trial-item">
            <label>Therapeutic Area <span className="required">*</span> </label>
            {editable ? (
              <Select
                mode="multiple"
                defaultValue={record["therapeutic_area"]}
                showSearch
                style={{ width: 200 }}
                virtual={false}
                value={record["therapeutic_area"]}
                onChange={(e) => onSelectChange("therapeutic_area", e)}
                onBlur={(v) => handleValidation("therapeutic_area", v)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {props.therapeuticAreaList.map((o) => {
                  return (
                    <Option value={o} key={o}>
                      {o}
                    </Option>
                  );
                })}
              </Select>
            ) : (
              <span className="readonly-value">
                  {
                    typeof record["therapeutic_area"] =='string' && record["therapeutic_area"]
                  }
                  {
                    typeof record["therapeutic_area"] == 'object' ? (
                      <>
                        {record["therapeutic_area"] && record["therapeutic_area"].length > 0 && record["therapeutic_area"].join(", ") }
                      </>
                    ):''
                  }
              </span>
               )}
          <br/>
          <span className="trial-details-error">{therapeuticAreaError}</span>
          </div>
          <div className="trial-item trial-item-hide">
            <label>Endpoints</label>            
            {<span className="view-endpoint">
              <b>{record["primary_endpoints"]&&record["primary_endpoints"].length}</b> Primary, <b>{record["secondary_endpoints"]&&record["secondary_endpoints"].length}</b> Secondary, <b>{record["tertiary_endpoints"]&&record["tertiary_endpoints"].length}</b> Tertiary/Exploratory
            </span>}
          </div>
        </div>
      </div>
      {editable ? (
        <div className="edit-save-btn">
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
});

const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TrialDetails));
