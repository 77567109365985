/* eslint-disable */
import React, { useState, useEffect, useReducer, useCallback, memo} from 'react';
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";
import 'jspdf-autotable';
import {Button, Collapse, Slider, Dropdown,Menu, Row, Col, Tabs, Tooltip, Spin, message, Steps,Drawer, Input, Typography, Popover, Table, Breadcrumb, Badge, Modal } from "antd";
import { getSimilarhistoricalTrialById, saveIECriteria, getIeCriteria, getCriteriaLibByNctId, getTrialSoa} from "../../utils/ajax-proxy";
import { getEmail } from "../../utils/auth";
import {withRouter } from 'react-router';
import { LoadingOutlined,  } from "@ant-design/icons";
import ReactECharts from 'echarts-for-react';
import "./index.scss";
import ScheduleActivities from '../../components/ScheduleActivities';
import IELibrary from '../../components/IELibrary';
import { isAuthenticated } from "../../utils/auth";
import {CLINICAL_TRIALS_GOV_URL} from './../../constants'
import { formatValue, formatHistoryValue, formatTime, formatHistoryTime } from '../../utils/auth'
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Step } = Steps;
const { Paragraph, Text } = Typography;

const frequencyFilter = [5, 100]
const inActiveChartColors = ['#DADADA', '#DADADA', '#DADADA', '#DADADA']
const activeChartColors = ["#6F2849",
"#9C3F5D",
"#C8586C",
"#DC7075",
"#B13E64",
"#CA5268"]
const simliarTrialStudyStartDate = { dateFrom: 1990, dateTo: 2025}

const sponsorChartColor =[
    "#6F2849",
    "#9C3F5D",
    "#C8586C",
    "#DC7075",
    "#B13E64",
    "#CA5268",
    "#DC676C",
    "#EA8170", 
    "#F19B7C",
    "#F5B68D",
    "#F6D1A9",
    "#FBE5C4"
]

const statusChartColor=[
    "#6F2849",
    "#9C3F5D",
    "#C8586C",
    "#DC7075",
    "#B13E64",
    "#CA5268",
    "#DC676C",
    "#EA8170", 
    "#F19B7C",
    "#F5B68D",
    "#F6D1A9",
    "#FBE5C4"
]

const defaultChartValue = [
  {value: 0, name: 'Labs / Tests'},
  {value: 0, name: 'Intervention'},
  {value: 0, name: 'Demographics'},
  {value: 0, name: 'Medical Condition'}
]

const initialTrial = {
    scenarios:[]
}
const initialSoa = { 
  soaAddedLabs: [],
  soaAddedExamination: [],
  soaAddedProcedures: [],
  soaAddedQuestionnaires: [],
  soaAddedStudyProcedures: [],
  soaOrgLabs: [],
  soaOrgExamination: [],
  soaOrgProcedures: [],
  soaOrgQuestionnaires: [],
  soaOrgStudyProcedures: [],
  soaFilteredLabs: [],
  soaFilteredExamination: [],
  soaFilteredQuestionnaires: [],
  soaFilteredProcedures: [],
  soaFilteredStudyProcedures: [],
  soaEndpoints : [],
  soaVisits: [],
  soaWeeks: [],
  isLoaded: false,
  event_numbers: {},
  soaVisitNotesList:[],
  scenarioId: '',
}

const initialScenario = {
    scenario_id: "",
    scenario_name: "",
    scenario_description: "",
    protocol_amendment_rate: "",
    screen_failure_rate: "",
    patient_burden: "",
    cost: "",
    "Inclusion Criteria": {},
    "Exclusion Criteria": {},
    "Enrollment Feasibility": {},
    "Schedule of Events": {},
    ts_id:''
};

const CATEGORY_LABS = 'Labs';
const CATEGORY_PHYSICAL_EXAMINATION = 'Physical Examination';
const CATEGORY_PROCEDURES = 'Procedures';
const CATEGORY_QUESTIONNAIRES = 'Questionnaires';
const CATEGORY_STUDY_PROCEDURES = 'Study Procedures';

const visitDimensionalScore = [
  {Dimension: 'AnxietyInducing', Value: 5},
  {Dimension: 'HospitalDependent', Value: 25},
  {Dimension: 'PhysicallyInvasive', Value: 10},
  {Dimension: 'BloodDraw', Value: 15},
  {Dimension: 'Sedation', Value: 35},
  {Dimension: 'Injection', Value: 15},
  {Dimension: 'Urine', Value: 5},
  {Dimension: 'RequireFasting', Value: 7},
  {Dimension: 'LongerThanTwoHours', Value: 20},
  {Dimension: 'Questionnaire', Value: 5}
]

const NewScenarioPage = (props) => {
    const isAdmin = localStorage.getItem('user_type').toUpperCase() == 'ADMIN';
    //Common cons
    const [trialTitle, setTrialTitle] = useState('')
    const [trialRecord, setTrialRecord] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { ...initialTrial }
    );
    const [scenario, setScenario] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { ...initialScenario }
    );

    const [dismissInclu, setDismissInclu] = useState([])
    const [dismissExclu, setDismissExclu] = useState([])
    const [insightInclu, setInsightInclu] = useState(scenario['ie_insights'] && scenario['ie_insights']['Inclusion'] || [])
    const [insightExclu, setInsightExclu] = useState(scenario['ie_insights'] && scenario['ie_insights']['Exclusion']||[])

    const [scenarioId, setScenarioId] = useState('')
    const [avgFileKey, setAvgFileKey] = useState('')
    const [scenarioType, setScenarioType] = useState('')
    const [activeEnrollmentTabKey, setActiveEnrollmentTabKey] = useState('1')
    const [activeTabKey, setActiveTabKey] = useState(() => {
      const storedCount = localStorage.getItem('activeTabKey');
      return storedCount ? JSON.parse(storedCount) : '1';
    })
    const [processStep, setProcessStep] = useState(() => {
      const storedCount = localStorage.getItem('processStep');
      return storedCount ? JSON.parse(storedCount) : 0;
    })
    const [submitType, setSubmitType] = useState(0)
    const [similarHistoricalTrials, setSimilarHistoricalTrials] = useState([])
    const [spinning, setSpinning] = useState(true)
    const [showChartLabel, setShowChartLabel] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)

    const [showHistorical, setShowHistorical] = useState(false)
    const [historicalTrialdata, setHistoricalTrialdata] = useState([])
    const [visibleSOA, setVisibleSOA] = useState(false)
    const [disableSaveFinish, setDisableSaveFinish] = useState(true)

    //------------------------INCLUSION CRITERIA CONST START-----------------------------
    //Original libs for filter purpose
    const [originDemographics, setOriginDemographics] = useState([])
    const [originIntervention, setOriginIntervention] = useState([])
    const [originMedCondition, setOriginMedCondition] = useState([])
    const [originLabTest, setOriginLabTest] = useState([])

    //For inclusion chart data display
    const [protocolRateData, setProtocolRateData] = useState(defaultChartValue)
    const [screenRateData, setScreenRateData] = useState(defaultChartValue)
    const [therapeutic_Amend_Avg, setTherapeutic_Amend_Avg] = useState('')
    const [therapeutic_Screen_Avg, setTherapeutic_Screen_Avg] = useState('')
    const [impactColors, setImpactColors] = useState(inActiveChartColors)
    const [amend_avg_rate, setAmend_avg_rate] = useState('')
    const [screen_avg_rate, setScreen_avg_rate] = useState('')

    const [criteria_count_inclusion, setCriteria_count_inclusion] = useState('0')
    const [criteria_count_exclusion, setCriteria_count_exclusion] = useState('0')
    const [criteria_par_amendment_count_inclusion, setCriteria_par_amendment_count_inclusion] = useState('0')
    const [criteria_par_amendment_count_exclusion, setCriteria_par_amendment_count_exclusion] = useState('0')
    const [total_par_amendment_count, setTotal_par_amendment_count ] = useState('0')
    const [par_historical_trial_average, setPar_historical_trial_average ] = useState(0)
    const [protocol_amendment_rate, setProtocol_amendment_rate ] = useState('')
    const [protocol_amendment_rate_inclusion, setProtocol_amendment_rate_inclusion ] = useState('0')
    const [protocol_amendment_rate_exclusion, setProtocol_amendment_rate_exclusion ] = useState('0')

    const [criteria_sfr_amendment_count_inclusion, setCriteria_sfr_amendment_count_inclusion] = useState('0')
    const [criteria_sfr_amendment_count_exclusion, setCriteria_sfr_amendment_count_exclusion] = useState('0')
    const [total_sfr_amendment_count, setTotal_sfr_amendment_count ] = useState('0')
    const [sfr_historical_trial_average, setSfr_historical_trial_average ] = useState(0)
    const [screen_failure_rate, setScreen_failure_rate ] = useState('')
    const [screen_failure_rate_inclusion, setScreen_failure_rate_inclusion ] = useState('0')
    const [screen_failure_rate_exclusion, setScreen_failure_rate_exclusion ] = useState('0')

    const [rollHeight, setRollHeight] = useState(true)            // Control editTable scroll height
  
    const [defaultActiveKey, setDefaultActiveKey] = useState([])  //default expanded collapse for edittable
    const [activeKey, setActiveKey] = useState([])                //To control chart collapse expanding
    const [collapsible, setCollapsible] = useState(true)// Set collapse can be click to collapse/expand or not
  
    //To store the selected inclusion criteria libs
   let [demographicsElements, setDemographicsElements] = useState([])
   let [interventionElements , setInterventionElements ] = useState([])
   let [medConditionElements, setMedConditionElements] = useState([])
   let [labTestElements, setLabTestElements] = useState([])

    // inclusion criteria data for EditTable
  let [demographicsTableData, setDemographicsTableData] = useState([])
  let [interventionTableData, setInterventionTableData] = useState([])
  let [medConditionTableData, setMedConditionTableData] = useState([])
  let [labTestTableData, setLabTestTableData] = useState([])

    //------------------------INCLUSION CRITERIA CONST END-----------------------------

    //------------------------EXCLUSION CRITERIA CONST START-----------------------------
    //Original libs for filter purpose
    const [originExcluDemographics, setOriginExcluDemographics] = useState([])
    const [originExcluIntervention, setOriginExcluIntervention] = useState([])
    const [originExcluMedCondition, setOriginExcluMedCondition] = useState([])
    const [originExcluLabTest, setOriginExcluLabTest] = useState([])

    //For exclusion chart data display
    const [excluProtocolRateData, setExcluProtocolRateData] = useState(defaultChartValue)
    const [excluScreenRateData, setExcluScreenRateData] = useState(defaultChartValue)
    const [exclu_Therapeutic_Amend_Avg, setExcluTherapeutic_Amend_Avg] = useState('Therapeutic Area Average - 40%')
    const [exclu_Therapeutic_Screen_Avg, setExcluTherapeutic_Screen_Avg] = useState('Therapeutic Area Average - 35%')
    const [excluImpactColors, setExcluImpactColors] = useState(inActiveChartColors)
    const [excluAmend_avg_rate, setExcluAmend_avg_rate] = useState('')
    const [excluScreen_avg_rate, setExcluScreen_avg_rate] = useState('')

    const [excluRollHeight, setExcluRollHeight] = useState(true)            // Control editTable scroll height
    const [excluDefaultActiveKey, setExcluDefaultActiveKey] = useState([])  //default expanded collapse for edittable
    const [excluActiveKey, setExcluActiveKey] = useState(['1'])                //To control chart collapse expanding
    const [excluCollapsible, setExcluCollapsible] = useState(true)          // Set collapse can be click to collapse/expand or not

    //To store the selected exclusion criteria libs
    let [excluDemographicsElements, setExcluDemographicsElements] = useState([])
    let [excluMedConditionElements , setExcluMedConditionElements ] = useState([])
    let [excluInterventionElements, setExcluInterventionElements] = useState([])
    let [excluLabTestElements, setExcluLabTestElements] = useState([])

    // exclusion criteria data for EditTable
  let [excluDemographicsTableData, setExcluDemographicsTableData] = useState([])
  let [excluInterventionTableData, setExcluInterventionTableData] = useState([])
  let [excluMedConditionTableData, setExcluMedConditionTableData] = useState([])
  let [excluLabTestTableData, setExcluLabTestTableData] = useState([])

    //------------------------EXCLUSION CRITERIA CONST END-----------------------------

  const [reloadPTData, setReloadPTData] = useState(false)
  const [sponsorChartData, setSponsorChartData] = useState([])
  const [statusChartData, setStatusChartData] = useState([])
  const [readOnlyAccess, setReadOnlyAccess] = useState(true) 

  useEffect(() => {
    const data = async() => {
      const response = await getTrialSoa(props.location.state.trial_id, props.location.state.scenarioId, getEmail());
      if(!response) return;
      props.createSoa(response)
    }
    props.location.state.whetherUpdate !== 'false' && data();
  },[scenarioId, processStep])

  const updateTrial = (type: number, res: number) => {
    // res: 1, update when loading page; 2, update when update criteria
    if(res == 1){
      setReloadPTData(true)
    }
    if (type == 1) {//Inclusion

      let demographicsElementsTmp = demographicsElements.map((item,index) =>{
        return Object.assign(item,{Key:(index + 1) + ''})
      })
      setDemographicsElements(demographicsElementsTmp)
      setDemographicsTableData(demographicsElementsTmp.map((item, id) =>{
        item.Key = (id + 1) + ''
        return item
      }))

      let medConditionElementsTmp = medConditionElements.map((item,index) =>{
        return Object.assign(item,{Key:(index + 1) + ''})
      })
      setMedConditionElements(medConditionElementsTmp)
      setMedConditionTableData(medConditionElementsTmp.map((item, id) =>{
        item.Key = demographicsElementsTmp.length + (id + 1) + ''
        return item
      }))


       let interventionElementsTmp = interventionElements.map((item,index) =>{
        return Object.assign(item,{Key:(index + 1) + ''})
      })
      setInterventionElements(interventionElementsTmp)
      setInterventionTableData(interventionElementsTmp.map((item, id) =>{
        item.Key = demographicsElementsTmp.length + medConditionElementsTmp.length + (id + 1) + ''
        return item
      }))


      let labTestElementsTmp = labTestElements.map((item,index) =>{
        return Object.assign(item,{Key:(index + 1) + ''})
      })
      setLabTestElements(labTestElementsTmp)
      setLabTestTableData(labTestElementsTmp.map((item, id) =>{
        item.Key = demographicsElementsTmp.length + medConditionElementsTmp.length + interventionElementsTmp.length+(id + 1) + ''
        return item
      }))


      setCollapsible(false)
      setDefaultActiveKey(['2', '3', '4', '5'])

      setRollHeight(false)
      setActiveKey(['1'])

    } else if (type == 2) {//Exclusion


      let excluDemographicsElementsTmp = excluDemographicsElements.map((item,index) =>{
        return Object.assign(item,{Key:(index + 1) + ''})
      })
      // let excluDemographicsTableDataTmp = excluDemographicsElementsTmp.filter(d => {
      //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
      // })
      setExcluDemographicsElements(excluDemographicsElementsTmp )
      setExcluDemographicsTableData(excluDemographicsElementsTmp.map((item, id) =>{
        item.Key = (id + 1) + ''
        return item
      }))



      let excluMedConditionElementsTmp = excluMedConditionElements.map((item,index) =>{
        return Object.assign(item,{Key:(index + 1) + ''})
      })
      //  let excluMedConditionTableDataTmp = excluMedConditionElementsTmp.filter(d => {
      //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
      // })

      setExcluMedConditionElements(excluMedConditionElementsTmp )
      setExcluMedConditionTableData(excluMedConditionElementsTmp.map((item, id) =>{
        item.Key = excluDemographicsElementsTmp.length + (id + 1) + ''
        return item
      }))


      let excluInterventionElementsTmp = excluInterventionElements.map((item,index) =>{
        return Object.assign(item,{Key:(index + 1) + ''})
      })
      //  let excluInterventionTableDataTmp = excluInterventionElementsTmp.filter(d => {
      //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
      //  })
      setExcluInterventionElements(excluInterventionElementsTmp )
      setExcluInterventionTableData(excluInterventionElementsTmp.map((item, id) =>{
        item.Key = excluDemographicsElementsTmp.length + excluMedConditionElementsTmp.length + (id + 1) + ''
        return item
      }))


      let excluLabTestElementsTmp = excluLabTestElements.map((item,index) =>{
        return Object.assign(item,{Key:(index + 1) + ''})
      })
      //  let excluLabTestTableDataTmp = excluLabTestElementsTmp.filter(d => {
      //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
      //  })
      setExcluLabTestElements(excluLabTestElementsTmp )
      setExcluLabTestTableData(excluLabTestElementsTmp.map((item, id) =>{
        item.Key = excluDemographicsElementsTmp.length + excluMedConditionElementsTmp.length + excluInterventionElementsTmp.length + (id + 1) + ''
        return item
      }))


      setExcluCollapsible(false)
      setExcluDefaultActiveKey(['2','3','4','5'])

      setExcluRollHeight(false)
      setExcluActiveKey(['1'])
    }
}

  useEffect(()=>{    
    // props.newIE?.demographicsElements?.length > 0&&
    setDemographicsElements( props.newIE?.demographicsElements)
    // props.newIE?.interventionElements?.length > 0&&
    setInterventionElements( props.newIE?.interventionElements)
    // props.newIE?.medConditionElements?.length > 0&&
    setMedConditionElements( props.newIE?.medConditionElements)
    // props.newIE?.labTestElements?.length > 0&&
    setLabTestElements( props.newIE?.labTestElements)

    // props.newIE?.excluDemographicsElements?.length > 0&&
    setExcluDemographicsElements( props.newIE?.excluDemographicsElements)
    // props.newIE?.excluInterventionElements?.length > 0&&
    setExcluInterventionElements( props.newIE?.excluInterventionElements)
    // props.newIE?.excluMedConditionElements?.length > 0&&
    setExcluMedConditionElements( props.newIE?.excluMedConditionElements)
    // props.newIE?.excluLabTestElements?.length > 0&& 
    setExcluLabTestElements( props.newIE?.excluLabTestElements)

    if(activeTabKey === '1') { // Inclusion Criteria
      updateTrial(1,1)
    } else if(activeTabKey === '2') { // Exclusion Criteria
      updateTrial(2,1)
    }

  },[props.newIE])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function areAllArraysEmpty(obj) {
    for (const key in obj) {
        if (obj.hasOwnProperty(key) && !Array.isArray(obj[key]) || obj[key].length !== 0) {
            return false; 
        }
    }
    return true; 
}

  function formatNumber (str){
    if(str == undefined || str == ''){
      return 0
    } else {
      return Number(str.substr(0, str.lastIndexOf('%')))
    }
  }

  const getTrialById = async () => {
    const tempScenarioId = props.location.state.scenarioId
    const resp = await getIeCriteria(props.location.state.trial_id, tempScenarioId, getEmail());  
    if (resp && Object.keys(resp).length !== 0) {
      ReactDOM.unstable_batchedUpdates(() => { 
        const tempRecord = resp
        
        if(tempRecord && tempRecord.trial_user_role && tempRecord.trial_user_role.toLowerCase() == 'read') {
          setReadOnlyAccess(true)
        }else{
          setReadOnlyAccess(false)
        }
        setTrialRecord(tempRecord)
        props.createTrial({
          trialRecord: tempRecord,
        });
        setTrialTitle(tempRecord['trial_alias'])
        if (tempRecord.similarHistoricalTrials !== undefined) {
          setSimilarHistoricalTrials(tempRecord.similarHistoricalTrials)
        }
        const tempScenarioId = props.location.state.scenarioId
        const tempScenarioType = props.location.state.scenarioType
        const tempScenario = tempRecord.scenarios.find(i => i['ts_id'] == tempScenarioId)
        setScenarioId(tempScenarioId)
        props.updateSoaActivities({scenarioId: tempScenarioId})
        setScenarioType(tempScenarioType)
        setScenario(tempScenario)
        setInsightInclu(tempScenario['ie_insights'] && tempScenario['ie_insights']['Inclusion'])
        setInsightExclu(tempScenario['ie_insights'] && tempScenario['ie_insights']['Exclusion'])
        const totalParAmendmentCount = tempScenario.total_par_amendment_count;
        const parHistoricalTrialAverage = tempRecord.par_historical_trial_average;

        const totalSfrAmendmentCount = tempScenario.total_sfr_amendment_count;
        const sfrHistoricalTrialAverage = tempRecord.sfr_historical_trial_average;

        // const screenFailureRateFlag = tempRecord.xxx
        const screenFailureRateFlag = 'true'

        setTotal_par_amendment_count(totalParAmendmentCount)
        setPar_historical_trial_average(parHistoricalTrialAverage)

        setTotal_sfr_amendment_count(totalSfrAmendmentCount)
        setSfr_historical_trial_average(sfrHistoricalTrialAverage)

        if (tempScenario['ie_criteria'] != undefined && (tempScenario['ie_criteria']['Inclusion Criteria'] != undefined || tempScenario['ie_criteria']['Exclusion Criteria'] != undefined)) {

          let scenario_ie_data = tempScenario['ie_criteria'];

          const protocolAmendmentRate = tempScenario.protocol_amendment_rate;
          setProtocol_amendment_rate(protocolAmendmentRate)

          const screenFailureRate = tempScenario.screen_failure_rate;
          setScreen_failure_rate(screenFailureRate)

          //Get inclusion chart info
          let inclu = scenario_ie_data["Inclusion Criteria"]
          let emptyCategory = {
            "protocol_amendment_rate": "",
            "patient_burden": "",
            "Entities": [],
            "protocol_amendment_rate_state": "GOOD",
            "screen_failure_rate": "",
            "screen_failure_rate_state": "GOOD"
          }

          if (inclu !== undefined) {
            if (inclu.Demographics == undefined) { inclu.Demographics = emptyCategory }
            if (inclu.Intervention == undefined) { inclu.Intervention = emptyCategory }
            if (inclu['Medical Condition'] == undefined) { inclu['Medical Condition'] = emptyCategory }
            if (inclu['Lab / Test'] == undefined) { inclu['Lab / Test'] = emptyCategory }

            const protocolAmendmentRateInclusion = inclu.protocol_amendment_rate;
            setProtocol_amendment_rate_inclusion(protocolAmendmentRateInclusion)

            const screenFailureRateInclusion = inclu.screen_failure_rate;
            setScreen_failure_rate_inclusion(screenFailureRateInclusion)

            let criteriaCountInclusion = inclu.criteria_count_inclusion
            setCriteria_count_inclusion(criteriaCountInclusion)

            let criteriaParAmendmentCountInclusion = inclu.criteria_par_amendment_count_inclusion
            setCriteria_par_amendment_count_inclusion(criteriaParAmendmentCountInclusion)

            let criteriaSfrAmendmentCountInclusion = inclu.criteria_sfr_amendment_count_inclusion
            setCriteria_sfr_amendment_count_inclusion(criteriaSfrAmendmentCountInclusion)

            demographicsElements = inclu.Demographics.Entities.map((item) => { item["Values"] = formatValue(item); return item })
            interventionElements = inclu.Intervention.Entities.map((item) => { item["Values"] = formatValue(item); return item })
            medConditionElements = inclu['Medical Condition'].Entities.map((item) => { item["Values"] = formatValue(item); return item })
            labTestElements = inclu['Lab / Test'].Entities.map((item) => { item["Values"] = formatValue(item); return item })

              setProtocolRateData([
              { value: inclu['Lab / Test'].criteria_par_amendment_count_category, name: 'Labs / Tests' },
              { value: inclu.Intervention.criteria_par_amendment_count_category, name: 'Intervention' },
              { value: inclu.Demographics.criteria_par_amendment_count_category, name: 'Demographics' },
              { value: inclu['Medical Condition'].criteria_par_amendment_count_category, name: 'Medical Condition' }
            ])
            setScreenRateData([
              { value: formatNumber(inclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests' },
              { value: formatNumber(inclu.Intervention.screen_failure_rate), name: 'Intervention' },
              { value: formatNumber(inclu.Demographics.screen_failure_rate), name: 'Demographics' },
              { value: formatNumber(inclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition' }
            ])
            var tempScoreA = ''
            var tempScoreB = ''
            let protocol_amendment_state = inclu.protocol_amendment_rate_state
            let screen_failure_state = inclu.screen_failure_rate_state
            tempScoreA = inclu.criteria_par_amendment_count_inclusion + '\n' + protocol_amendment_state
            tempScoreB = inclu.criteria_sfr_amendment_count_inclusion + '\n' + screen_failure_state
            setAmend_avg_rate(tempScoreA)
            setScreen_avg_rate(tempScoreB)
            setShowChartLabel(true)
            setImpactColors(activeChartColors)
          }
          //Get exclusion chart info
          let exclu = scenario_ie_data["Exclusion Criteria"]
          if (exclu !== undefined) {
            if (exclu.Demographics == undefined) { exclu.Demographics = emptyCategory }
            if (exclu.Intervention == undefined) { exclu.Intervention = emptyCategory }
            if (exclu['Medical Condition'] == undefined) { exclu['Medical Condition'] = emptyCategory }
            if (exclu['Lab / Test'] == undefined) { exclu['Lab / Test'] = emptyCategory }
            excluDemographicsElements = exclu.Demographics.Entities.map((item) => { item["Values"] = formatValue(item); return item })
            excluMedConditionElements = exclu['Medical Condition'].Entities.map((item) => { item["Values"] = formatValue(item); return item })
            excluInterventionElements = exclu.Intervention.Entities.map((item) => { item["Values"] = formatValue(item); return item })
            excluLabTestElements = exclu['Lab / Test'].Entities.map((item) => { item["Values"] = formatValue(item); return item })

              setExcluProtocolRateData([
              { value: exclu['Lab / Test'].criteria_par_amendment_count_category, name: 'Labs / Tests' },
              { value: exclu.Intervention.criteria_par_amendment_count_category, name: 'Intervention' },
              { value: exclu.Demographics.criteria_par_amendment_count_category, name: 'Demographics' },
              { value: exclu['Medical Condition'].criteria_par_amendment_count_category, name: 'Medical Condition' }
            ])
            setExcluScreenRateData([
              { value: formatNumber(exclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests' },
              { value: formatNumber(exclu.Intervention.screen_failure_rate), name: 'Intervention' },
              { value: formatNumber(exclu.Demographics.screen_failure_rate), name: 'Demographics' },
              { value: formatNumber(exclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition' }
            ])

            var excluTempScoreA = ''
            var excluTempScoreB = ''

            const protocolAmendmentRate = exclu.protocol_amendment_rate;
            setProtocol_amendment_rate(protocolAmendmentRate)

            const screenFailureRate = exclu.screen_failure_rate;
            setScreen_failure_rate(screenFailureRate)

            const protocolAmendmentRateExclusion = exclu.protocol_amendment_rate
            setProtocol_amendment_rate_exclusion(protocolAmendmentRateExclusion)

            const screenFailureRateExclusion = exclu.screen_failure_rate
            setScreen_failure_rate_exclusion(screenFailureRateExclusion)

            let criteriaCountExclusion = exclu.criteria_count_exclusion
            setCriteria_count_exclusion(criteriaCountExclusion)

            let criteriaParAmendmentCountExclusion = exclu.criteria_par_amendment_count_exclusion;
            setCriteria_par_amendment_count_exclusion(criteriaParAmendmentCountExclusion)

            let criteriaSfrAmendmentCountExclusion = exclu.criteria_sfr_amendment_count_exclusion;
            setCriteria_sfr_amendment_count_exclusion(criteriaSfrAmendmentCountExclusion)

            let exclu_protocol_amendment_state = exclu.protocol_amendment_rate_state
            let exclu_screen_failure_state = exclu.screen_failure_rate_state

            excluTempScoreA = exclu.criteria_par_amendment_count_exclusion + '\n' + exclu_protocol_amendment_state
            excluTempScoreB = exclu.criteria_sfr_amendment_count_exclusion + '\n' + exclu_screen_failure_state
            setExcluAmend_avg_rate(excluTempScoreA)
            setExcluScreen_avg_rate(excluTempScoreB)
            setShowChartLabel(true)
            setExcluImpactColors(activeChartColors)
          }
        }
        if (tempRecord['therapeutic_area_average']) {
          setTherapeutic_Amend_Avg('Therapeutic Area Average - ' + tempRecord['therapeutic_area_average'].protocol_amendment_rate)
          setTherapeutic_Screen_Avg('Therapeutic Area Average - ' + tempRecord['therapeutic_area_average'].screen_failure_rate)
          setExcluTherapeutic_Amend_Avg('Therapeutic Area Average - ' + tempRecord['therapeutic_area_average'].protocol_amendment_rate)
          setExcluTherapeutic_Screen_Avg('Therapeutic Area Average - ' + tempRecord['therapeutic_area_average'].screen_failure_rate)
        }
        
        (props.location.state.whetherUpdate !== 'false' || (props.location.state.whetherUpdate === 'false'&&areAllArraysEmpty(props.newIE))) && props.updateIEActivities({
          demographicsElements:demographicsElements,
          interventionElements:interventionElements,
          medConditionElements:medConditionElements,
          labTestElements:labTestElements,
          excluDemographicsElements:excluDemographicsElements ,
          excluMedConditionElements: excluMedConditionElements,
          excluInterventionElements: excluInterventionElements,
          excluLabTestElements: excluLabTestElements,
        })      

        if (tempScenario['ie_criteria'] !== {}) {
          updateTrial(1, 2)
          updateTrial(2, 2)
        }
      });
    } else {
      message.error("Error in loading the scenario")
    }
  };

  useEffect(() => {
     const checkAuthenticated = async () => {
      if (!(await isAuthenticated(true))) {
        props.history.push("/");
        localStorage.setItem('whetherSave', '')
        localStorage.setItem('processStep', JSON.stringify(0));
        localStorage.setItem('activeTabKey', JSON.stringify('1'));
      }}
    if(props.location.state.trial_id == undefined || props.location.state.trial_id == ''){
      props.history.push({pathname: '/trials'})
      localStorage.setItem('whetherSave', '')
      localStorage.setItem('processStep', JSON.stringify(0));
      localStorage.setItem('activeTabKey', JSON.stringify('1'));
    } else {
      localStorage.getItem('whetherSave')!=='true'&&getTrialById();
      // getCriteriaById();
      checkAuthenticated();
    }
  }, []);

  function roundFun(value, n) {
      return Math.round(value*Math.pow(10,n))/Math.pow(10,n);
  }

  function formatCostAvg(totalCost, divisor){
    if(totalCost === 0){
      return 0
    } else {
      let avg = Math.ceil(totalCost/divisor*1000)
      return roundFun(avg/1000, 2)
    }
  }

  const reCalculateSOA = (specificScenario, scheduleOfEvents) =>{
    let burdenMatrixList = []
    let tempBurdenXAxis = []
    for(var i =0; i< scheduleOfEvents.Visits; i ++){
      burdenMatrixList.push([0,0,0,0,0,0,0,0,0,0])
      tempBurdenXAxis.push((i+1)+'')
    }

    let labeTotalCost = 0
    for(const a in scheduleOfEvents[CATEGORY_LABS].entities) {
      labeTotalCost += Number(scheduleOfEvents[CATEGORY_LABS].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_LABS].entities[a].totalVisit
      if(scheduleOfEvents[CATEGORY_LABS].entities[a].condition.length > 0){
        for(let b = 0; b < scheduleOfEvents[CATEGORY_LABS].entities[a].condition.length; b ++){
          if(scheduleOfEvents[CATEGORY_LABS].entities[a].condition[b].checked){
            let tempBurdenMatrix = []
            burdenMatrixList[b].map((item, idx) =>{
              tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_LABS].entities[a].soaWeights[idx])
            })
            burdenMatrixList.splice(b, 1, tempBurdenMatrix)
          }
        }
      }
    }

    let examinationTotalCost = 0
    for(const a in scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities) {
      examinationTotalCost += Number(scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].totalVisit
      if(scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].condition.length > 0){
        for(let b = 0; b < scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].condition.length; b ++){
          if(scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].condition[b].checked){
            let tempBurdenMatrix = []
            burdenMatrixList[b].map((item, idx) =>{
              tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].soaWeights[idx])
            })
            burdenMatrixList.splice(b, 1, tempBurdenMatrix)
          }
        }
      }
    }

    let proceduresTotalCost = 0
    for(const a in scheduleOfEvents[CATEGORY_PROCEDURES].entities) {
      proceduresTotalCost += Number(scheduleOfEvents[CATEGORY_PROCEDURES].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].totalVisit
      if(scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].condition.length > 0){
        for(let b = 0; b < scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].condition.length; b ++){
          if(scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].condition[b].checked){
            let tempBurdenMatrix = []
            burdenMatrixList[b].map((item, idx) =>{
              tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].soaWeights[idx])
            })
            burdenMatrixList.splice(b, 1, tempBurdenMatrix)
          }
        }
      }
    }

    let questionairesTotalCost = 0
    for(const a in scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities) {
      questionairesTotalCost += Number(scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].totalVisit
      if(scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].condition.length > 0){
        for(let b = 0; b < scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].condition.length; b ++){
          if(scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].condition[b].checked){
            let tempBurdenMatrix = []
            burdenMatrixList[b].map((item, idx) =>{
              tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].soaWeights[idx])
            })
            burdenMatrixList.splice(b, 1, tempBurdenMatrix)
          }
        }
      }
    }

    let studyTotalCost = 0
    for(const a in scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities) {
      studyTotalCost += Number(scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].totalVisit
      if(scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].condition.length > 0){
        for(let b = 0; b < scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].condition.length; b ++){
          if(scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].condition[b].checked){
            let tempBurdenMatrix = []
            burdenMatrixList[b].map((item, idx) =>{
              tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].soaWeights[idx])
            })
            burdenMatrixList.splice(b, 1, tempBurdenMatrix)
          }
        }
      }
    }

    let tempBurdenData = []
    let patient_burden = 0
    for(const m in burdenMatrixList){
      const visitMatrix = burdenMatrixList[m].map((max) => {
        return max > 0 ? 1 : 0
      })
      const excessMatrix = burdenMatrixList[m].map((max) => {
        return max - 1 >= 0 ? max - 1 : 0
      })

      let currentVisitScore = 0
      for(const c in visitMatrix){
        currentVisitScore += visitMatrix[c] * visitDimensionalScore[c].Value + excessMatrix[c]
      }
      tempBurdenData.push(currentVisitScore)
      patient_burden += currentVisitScore
    }

    let patient_burden_rate = 'GOOD'
    if (patient_burden > 0 && patient_burden <= 400) {
      patient_burden_rate = 'GOOD'
    } else if (patient_burden > 400 && patient_burden <= 600) {
      patient_burden_rate = 'FAIR'
    } else if (patient_burden > 600){
      patient_burden_rate = 'POOR'
    }

    let tempCostData = [
      {value: labeTotalCost, name: CATEGORY_LABS},
      {value: examinationTotalCost, name: CATEGORY_PHYSICAL_EXAMINATION},
      {value: proceduresTotalCost, name: CATEGORY_PROCEDURES},
      {value: questionairesTotalCost, name: CATEGORY_QUESTIONNAIRES},
      {value: studyTotalCost, name: CATEGORY_STUDY_PROCEDURES}
    ]

    let totalCost = labeTotalCost + examinationTotalCost + proceduresTotalCost + questionairesTotalCost + studyTotalCost

    let costBreakdown = ''
    if(totalCost < 12826){
      costBreakdown = 'GOOD'
    } else if(totalCost < 15650){
      costBreakdown = 'FAIR'
    } else {
      costBreakdown = 'POOR'
    }

    specificScenario['Schedule of Events'] = Object.assign(scheduleOfEvents,{
      'TotalCost': '' + formatCostAvg(totalCost, 1000),
      'CostRate': costBreakdown,
      'CostData': tempCostData,
      'BurdenData': tempBurdenData,
      'BurdenXAxis': tempBurdenXAxis,
      'Finished': true,
      'patient_burden': patient_burden,
      'patient_burden_rate': patient_burden_rate
    })
    return specificScenario
  }

  const autoSaveCriteria = async () => {
    setPageLoading(true)  
    localStorage.setItem('whetherSave', '')
    const scenarioId = props.location.state.scenarioId
    props.newIE.demographicsElements.forEach(item => {   
      delete item.Values
      item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
      item.user_value_free_text === null ||  item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text

      item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

      item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
      "sfr": "NA",
      "amendment_details": []}:item.Detail = item.Detail

    })
    demographicsElements=props.newIE.demographicsElements
    
    props.newIE.interventionElements.forEach(item => {
      delete item.Values
      item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
      item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
      item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

      item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
      "sfr": "NA",
      "amendment_details": []}:item.Detail = item.Detail
    })
    interventionElements=props.newIE.interventionElements

    props.newIE.medConditionElements.forEach(item => {
      delete item.Values
      item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
      item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
      item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

      item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
      "sfr": "NA",
      "amendment_details": []}:item.Detail = item.Detail
    }) 
    medConditionElements=props.newIE.medConditionElements

    props.newIE.labTestElements.forEach(item => {
      delete item.Values
      item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
      item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
      item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

      item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
      "sfr": "NA",
      "amendment_details": []}:item.Detail = item.Detail
    }) 
    labTestElements=props.newIE.labTestElements

    props.newIE.excluDemographicsElements.forEach(item => {
      delete item.Values
      item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
      item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
      item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

      item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
      "sfr": "NA",
      "amendment_details": []}:item.Detail = item.Detail
    })  
    excluDemographicsElements=props.newIE.excluDemographicsElements

    props.newIE.excluMedConditionElements.forEach(item => {
      delete item.Values
      item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
      item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
      item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

      item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
      "sfr": "NA",
      "amendment_details": []}:item.Detail = item.Detail
    }) 
    excluMedConditionElements=props.newIE.excluMedConditionElements

    props.newIE. excluInterventionElements.forEach(item => {
      delete item.Values
      item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
      item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
      item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

      item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
      "sfr": "NA",
      "amendment_details": []}:item.Detail = item.Detail
    }) 
    excluInterventionElements=props.newIE. excluInterventionElements

    props.newIE. excluLabTestElements.forEach(item => {
      delete item.Values
      item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
      item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
      item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

      item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
      "sfr": "NA",
      "amendment_details": []}:item.Detail = item.Detail
    }) 
    excluLabTestElements=props.newIE. excluLabTestElements

    var newInclusion = {
      "Demographics": {
        "protocol_amendment_rate": '',
        "patient_burden": '',
        "Entities": demographicsElements
      },
      "Medical Condition": {
        "protocol_amendment_rate": '',
        "patient_burden": '',
        "Entities": medConditionElements
      },
      "Intervention": {
        "protocol_amendment_rate": '',
        "patient_burden": '',
        "Entities": interventionElements
      },
      "Lab / Test": {
        "protocol_amendment_rate": '',
        "patient_burden": '',
        "Entities": labTestElements
      }
    }

    var newExclusion = {
      "Demographics": {
        "protocol_amendment_rate": '',
        "patient_burden": '',
        "Entities": excluDemographicsElements
      },
      "Medical Condition": {
        "protocol_amendment_rate": '',
        "patient_burden": '',
        "Entities": excluMedConditionElements
      },
      "Intervention": {
        "protocol_amendment_rate": '',
        "patient_burden": '',
        "Entities": excluInterventionElements
      },
      "Lab / Test": {
        "protocol_amendment_rate": '',
        "patient_burden": '',
        "Entities": excluLabTestElements
      }
    }
   

    let newScenario = JSON.parse(JSON.stringify(props.newTrial.trialRecord.scenarios.find( i=> i['ts_id'] == scenarioId)));

    newScenario["ie_dismiss_list"] = {
      Inclusion: dismissInclu,
      Exclusion: dismissExclu
    }

    if(newScenario["ie_criteria"]["Inclusion Criteria"] === undefined){
      newScenario["ie_criteria"]["Inclusion Criteria"] = newInclusion
    } else {
      newScenario["ie_criteria"]["Inclusion Criteria"]['Intervention'].Entities = interventionElements
      newScenario["ie_criteria"]["Inclusion Criteria"].Demographics.Entities = demographicsElements
      newScenario["ie_criteria"]["Inclusion Criteria"]['Medical Condition'].Entities = medConditionElements
      newScenario["ie_criteria"]["Inclusion Criteria"]['Lab / Test'].Entities = labTestElements
      delete newScenario["ie_criteria"]["Inclusion Criteria"]['protocol_amendment_rate'];
      delete newScenario["ie_criteria"]["Inclusion Criteria"]['protocol_amendment_rate_state'];
      delete newScenario["ie_criteria"]["Inclusion Criteria"]['screen_failure_rate'];
      delete newScenario["ie_criteria"]["Inclusion Criteria"]['screen_failure_rate_state'];
    }

    if(newScenario["ie_criteria"]["Exclusion Criteria"] === undefined){
      newScenario["ie_criteria"]["Exclusion Criteria"] = newExclusion
    } else {
      newScenario["ie_criteria"]["Exclusion Criteria"].Demographics.Entities = excluDemographicsElements
      newScenario["ie_criteria"]["Exclusion Criteria"]['Medical Condition'].Entities = excluMedConditionElements
      newScenario["ie_criteria"]["Exclusion Criteria"].Intervention.Entities = excluInterventionElements
      newScenario["ie_criteria"]["Exclusion Criteria"]['Lab / Test'].Entities = excluLabTestElements
    delete newScenario["ie_criteria"]["Exclusion Criteria"]['protocol_amendment_rate'];
    delete newScenario["ie_criteria"]["Exclusion Criteria"]['protocol_amendment_rate_state'];
    delete newScenario["ie_criteria"]["Exclusion Criteria"]['screen_failure_rate'];
    delete newScenario["ie_criteria"]["Exclusion Criteria"]['screen_failure_rate_state'];
    }

    const newScenarioList = props.newTrial.trialRecord.scenarios.map((item, id) =>{
      if(item['ts_id'] == scenarioId){
        //If go back from SOA and SOA got edited, re-calculate SOA results for save changes
        if(item['Schedule of Events']?.Finished != undefined && !item['Schedule of Events']?.Finished){
          return reCalculateSOA(newScenario, item['Schedule of Events'])
        } else {
          return newScenario
        }
      } else {
          return item
      }
    })

    let trial_uuid = props.location.state.trial_id
    let newTrial = {"trial_uuid" : trial_uuid}
    newTrial["scenarios"] = newScenarioList

    setTrialRecord(newTrial)
    props.createTrial({
      trialRecord: newTrial,
    });
    setImpactColors(activeChartColors)
    setExcluImpactColors(activeChartColors)

    console.log("save payload - newTrial",newTrial);

    newTrial["user_name"] = getEmail()

    const resp = await saveIECriteria(newTrial);
    // setPageLoading(false)    
    if (resp && Object.keys(resp).length !== 0) {
      var currentScenario = resp.scenarios.find( i=> i['ts_id'] == scenarioId)
      setDemographicsElements([])
      setDemographicsElements( demographicsElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))

      setMedConditionElements([])
      setMedConditionElements(medConditionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));

      setInterventionElements([])
      setInterventionElements(interventionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));

      setLabTestElements([]);
      setLabTestElements(labTestElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));

      setExcluDemographicsElements([])
      setExcluDemographicsElements( excluDemographicsElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))

      setExcluMedConditionElements([])
      setExcluMedConditionElements(excluMedConditionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));

      setExcluInterventionElements([])
      setExcluInterventionElements(excluInterventionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));

      setExcluLabTestElements([]);
      setExcluLabTestElements(excluLabTestElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));

      const totalParAmendmentCount = currentScenario.total_par_amendment_count
      const totalSfrAmendmentCount = currentScenario.total_sfr_amendment_count

      setTotal_par_amendment_count(totalParAmendmentCount)
      setTotal_sfr_amendment_count(totalSfrAmendmentCount)

       if(typeof currentScenario['ie_criteria'] === "object" && Object.keys(currentScenario['ie_criteria']).length === 0 )  {
          setCriteria_count_inclusion('0')
          setCriteria_count_exclusion('0')

          setCriteria_par_amendment_count_inclusion('0')
          setCriteria_par_amendment_count_exclusion('0')
          setProtocol_amendment_rate('')

          setCriteria_sfr_amendment_count_inclusion('0')
          setCriteria_sfr_amendment_count_exclusion('0')
          setScreen_failure_rate('')

           setProtocolRateData(defaultChartValue)
           setScreenRateData(defaultChartValue)

           setShowChartLabel(false)
           setImpactColors(inActiveChartColors)
          setAmend_avg_rate('')
          setScreen_avg_rate('')

          setShowChartLabel(false)
          setExcluImpactColors(inActiveChartColors)
          setExcluAmend_avg_rate('')
          setExcluScreen_avg_rate('')
           return;
       }else{
      //Get inclusion chart info
      var inclu = currentScenario["ie_criteria"]["Inclusion Criteria"]

      let criteriaCountInclusion = inclu.criteria_count_inclusion
      setCriteria_count_inclusion(criteriaCountInclusion)

      const currCriteriaParAmendmentCountInclusion = inclu.criteria_par_amendment_count_inclusion
      setCriteria_par_amendment_count_inclusion(currCriteriaParAmendmentCountInclusion)

      const currCriteriaSfrAmendmentCountInclusion = inclu.criteria_sfr_amendment_count_inclusion
      setCriteria_sfr_amendment_count_inclusion(currCriteriaSfrAmendmentCountInclusion)

      let currProtocolAmendmentRate = inclu.protocol_amendment_rate
      setProtocol_amendment_rate(currProtocolAmendmentRate)

      let currScreenFailureRate = inclu.screen_failure_rate
      setScreen_failure_rate(currScreenFailureRate)

      const protocolAmendmentRateInclusion = inclu.protocol_amendment_rate
      setProtocol_amendment_rate_inclusion(protocolAmendmentRateInclusion)

      const screenFailureRateInclusion = inclu.screen_failure_rate
      setScreen_failure_rate_inclusion(screenFailureRateInclusion)

       setProtocolRateData([
          {value: inclu['Lab / Test'].criteria_par_amendment_count_category, name: 'Labs / Tests'},
          {value: inclu.Intervention.criteria_par_amendment_count_category, name: 'Intervention'},
          {value: inclu.Demographics.criteria_par_amendment_count_category, name: 'Demographics'},
          {value: inclu['Medical Condition'].criteria_par_amendment_count_category, name: 'Medical Condition'}
        ])
        setScreenRateData([
            {value: formatNumber(inclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests'},
            {value: formatNumber(inclu.Intervention.screen_failure_rate), name: 'Intervention'},
            {value: formatNumber(inclu.Demographics.screen_failure_rate), name: 'Demographics'},
            {value: formatNumber(inclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition'}
        ])

      var tempScoreA = ''
      var tempScoreB = ''

      let protocol_amendment_state = inclu.protocol_amendment_rate_state
      let screen_failure_state = inclu.screen_failure_rate_state

      tempScoreA = inclu.criteria_par_amendment_count_inclusion + '\n'+protocol_amendment_state
      tempScoreB = inclu.criteria_sfr_amendment_count_inclusion + '\n'+screen_failure_state

      setAmend_avg_rate(tempScoreA)
      setScreen_avg_rate(tempScoreB)

      //Get exclusion chart info
      var exclu = currentScenario["ie_criteria"]["Exclusion Criteria"]

      setExcluProtocolRateData([
      {value: exclu['Lab / Test'].criteria_par_amendment_count_category, name: 'Labs / Tests'},
      {value: exclu.Intervention.criteria_par_amendment_count_category, name: 'Intervention'},
      {value: exclu.Demographics.criteria_par_amendment_count_category, name: 'Demographics'},
      {value: exclu['Medical Condition'].criteria_par_amendment_count_category, name: 'Medical Condition'}
      ])
      setExcluScreenRateData([
      {value: formatNumber(exclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests'},
      {value: formatNumber(exclu.Intervention.screen_failure_rate), name: 'Intervention'},
      {value: formatNumber(exclu.Demographics.screen_failure_rate), name: 'Demographics'},
      {value: formatNumber(exclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition'}
      ])

      let excluProtocolAmendmentRate = exclu.protocol_amendment_rate
      setProtocol_amendment_rate(excluProtocolAmendmentRate)

      let excluScreenFailureRate = exclu.screen_failure_rate
      setScreen_failure_rate(excluScreenFailureRate)

      const protocolAmendmentRateExclusion = exclu.protocol_amendment_rate
      setProtocol_amendment_rate_exclusion(protocolAmendmentRateExclusion)

      const screenFailureRateExclusion = exclu.screen_failure_rate
      setScreen_failure_rate_exclusion(screenFailureRateExclusion)

      let criteriaCountExclusion = exclu.criteria_count_exclusion
      setCriteria_count_exclusion(criteriaCountExclusion)

      const excluCriteriaParAmendmentCountExclusion = exclu.criteria_par_amendment_count_exclusion
      setCriteria_par_amendment_count_exclusion(excluCriteriaParAmendmentCountExclusion)

      const excluCriteriaSfrAmendmentCountExclusion = exclu.criteria_sfr_amendment_count_exclusion
      setCriteria_sfr_amendment_count_exclusion(excluCriteriaSfrAmendmentCountExclusion)

      let exclu_protocol_amendment_state = exclu.protocol_amendment_rate_state
      let exclu_screen_failure_state = exclu.screen_failure_rate_state

      tempScoreA = exclu.criteria_par_amendment_count_exclusion + '\n'+exclu_protocol_amendment_state
      tempScoreB = exclu.criteria_sfr_amendment_count_exclusion + '\n'+ exclu_screen_failure_state

      setExcluAmend_avg_rate(tempScoreA)
      setExcluScreen_avg_rate(tempScoreB)

      if(currentScenario['therapeutic_area_average']){
        setTherapeutic_Amend_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].protocol_amendment_rate)
        setTherapeutic_Screen_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].screen_failure_rate)
        setExcluTherapeutic_Amend_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].protocol_amendment_rate)
        setExcluTherapeutic_Screen_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].screen_failure_rate)
      }

      setScenario(currentScenario)
      setInsightInclu(currentScenario['ie_insights'] && currentScenario['ie_insights']['Inclusion'])
      setInsightExclu(currentScenario['ie_insights'] && currentScenario['ie_insights']['Exclusion'])
      setShowChartLabel(true)

       }
      //props.updateSoaActivities(initialSoa)
      // props.updateIEActivities({})
      setExcluActiveKey(['1'])
      // message.success("Save successfully");
    }else{
      message.error("Failed to save");
    }
  }

  const autoSave = async () => {
    if(localStorage.getItem('whetherSave')) {
      if (props.newTrial.trialRecord.scenarios) {
        await autoSaveCriteria()
        await getTrialById()
      }else {
        await getTrialById()
      }
      setPageLoading(false)
      message.success("Save successfully");
      localStorage.setItem('whetherSave', '')
    }else {
      setPageLoading(false)
    }
  }

  const summaryDefaultList = async () => {     
    // const nctIdList = props.location.state.similarHistoricalTrials 
    var resp
    resp = await getCriteriaLibByNctId(props.location.state.similarHistoricalTrials, props.location.state.trial_id);
    // setPageLoading(false)
    if (resp && Object.keys(resp).length !== 0) {
      const response = resp;
      const inclusionCriteria = response.Inclusion
      for(var key in inclusionCriteria){
          if(key == "Medical Condition"){
              setOriginMedCondition(inclusionCriteria['Medical Condition'])
              
          } else if(key == "Demographics"){
              setOriginDemographics(inclusionCriteria['Demographics'])
              
          } else if(key == "Lab / Test"){
              setOriginLabTest(inclusionCriteria['Lab / Test'])
              
          } else if(key == "Intervention"){
              setOriginIntervention(inclusionCriteria['Intervention'])
              
          }
      }

      const exclusionCriteria = response.Exclusion
      for(var key in exclusionCriteria){
          if(key == "Medical Condition"){
              setOriginExcluMedCondition(exclusionCriteria['Medical Condition'])
              // setExcluMedCondition(exclusionCriteria['Medical Condition'].filter((d) => {
              //     return d.Frequency * 100 >= excluMinValue && d.Frequency * 100 <= excluMaxValue;
              // }))
          } else if(key == "Demographics"){
              setOriginExcluDemographics(exclusionCriteria['Demographics'])
              // setExcluDemographics(exclusionCriteria['Demographics'].filter((d) => {
              //     return d.Frequency * 100 >= excluMinValue && d.Frequency * 100 <= excluMaxValue;
              // }))
          } else if(key == "Lab / Test"){
              setOriginExcluLabTest(exclusionCriteria['Lab / Test'])
              // setExcluLabTest(exclusionCriteria['Lab / Test'].filter((d) => {
              //     return d.Frequency * 100 >= excluMinValue && d.Frequency * 100 <= excluMaxValue;
              // }))
          } else if(key == "Intervention"){
              setOriginExcluIntervention(exclusionCriteria['Intervention'])
              // setExcluIntervention(exclusionCriteria['Intervention'].filter((d) => {
              //     return d.Frequency * 100 >= excluMinValue && d.Frequency * 100 <= excluMaxValue;
              // }))
          }
      }
      // Auto save criteria when from ADD page
      autoSave()
    }
  };

  useEffect(() => {
      summaryDefaultList();
    }, []);

  useEffect(() => {    
    localStorage.setItem('activeTabKey', JSON.stringify(activeTabKey));
    // getTrialById();
  }, [activeTabKey]);

  useEffect(() => {    
    localStorage.setItem('processStep', JSON.stringify(processStep));
    // getTrialById();
  }, [processStep]);

    const handleCancelSimialarTrialsModal = () => { 
      setShowHistorical(false)
      setVisibleSOA(false)
    }

    const showSOAModal = async () => {
      setVisibleSOA(true)
      showHistoricalTrials(true)
    }

    useEffect(() => {
      if(props.isLoaded) {
       
        setSpinning(false)
      } 
    }, [props.isLoaded])

    const showHistoricalTrials = async (soaFlag) => {
      setShowHistorical(true)
        const resp = await getSimilarhistoricalTrialById(trialRecord.trial_uuid, soaFlag);
        if (resp && Object.keys(resp).length !== 0) {
         setSpinning(false)

          const filteredData =  resp['results'].filter((d) => {
            const date = d['start_date'].split('-')[0]
            return (
              date >= simliarTrialStudyStartDate.dateFrom && date<= simliarTrialStudyStartDate.dateTo
            );
          });
          const sponsorData = getChartData(filteredData, "sponsor");
          const statusData = getChartData(filteredData, "study_status"); 
          setSponsorChartData(sponsorData)
          setStatusChartData(statusData)
          console.log(' filteredData ', filteredData)
          setHistoricalTrialdata(filteredData)
        }

    }

    const searchHistoricalTrials = async () => {
      showHistoricalTrials(false)
    }

    const updateInclusionCriteria = (newData, index) => {
      switch(index){
        case 2:
          setDemographicsElements(newData)
          props.updateIEActivities({
            demographicsElements: newData,
          })
          break;
        case 3:
          setMedConditionElements(newData)
          props.updateIEActivities({
            medConditionElements: newData,
          })
          break;
        case 4:
          setInterventionElements(newData)
          props.updateIEActivities({
            interventionElements: newData,
          })
          break;
        default:
          setLabTestElements(newData)
          props.updateIEActivities({
            labTestElements: newData,
          })
      }
    }

    const updateExclusionCriteria = (newData, index) => {
      switch(index){
        case 2:
          setExcluDemographicsElements(newData)
          props.updateIEActivities({
            excluDemographicsElements:newData,
          })
          break;
        case 3:
          setExcluMedConditionElements(newData)
          props.updateIEActivities({
            excluMedConditionElements: newData,
          })
          break;
        case 4:
          setExcluInterventionElements(newData)
          props.updateIEActivities({
            excluInterventionElements: newData,
          })
          break;
        default:
          setExcluLabTestElements(newData)
          props.updateIEActivities({
            excluLabTestElements: newData,
          })
      }
    }

    const saveCriteria = async () => {
      setPageLoading(true)  
      localStorage.setItem('whetherSave', '')
      const scenarioId = props.location.state.scenarioId
      props.newIE.demographicsElements.forEach(item => {   
        delete item.Values
        item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
        item.user_value_free_text === null ||  item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text

        item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

        item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
        "sfr": "NA",
        "amendment_details": []}:item.Detail = item.Detail

      })
      demographicsElements=props.newIE.demographicsElements
      
      props.newIE.interventionElements.forEach(item => {
        delete item.Values
        item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
        item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
        item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

        item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
        "sfr": "NA",
        "amendment_details": []}:item.Detail = item.Detail
      })
      interventionElements=props.newIE.interventionElements

      props.newIE.medConditionElements.forEach(item => {
        delete item.Values
        item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
        item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
        item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

        item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
        "sfr": "NA",
        "amendment_details": []}:item.Detail = item.Detail
      }) 
      medConditionElements=props.newIE.medConditionElements

      props.newIE.labTestElements.forEach(item => {
        delete item.Values
        item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
        item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
        item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

        item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
        "sfr": "NA",
        "amendment_details": []}:item.Detail = item.Detail
      }) 
      labTestElements=props.newIE.labTestElements

      props.newIE.excluDemographicsElements.forEach(item => {
        delete item.Values
        item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
        item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
        item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

        item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
        "sfr": "NA",
        "amendment_details": []}:item.Detail = item.Detail
      })  
      excluDemographicsElements=props.newIE.excluDemographicsElements

      props.newIE.excluMedConditionElements.forEach(item => {
        delete item.Values
        item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
        item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
        item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

        item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
        "sfr": "NA",
        "amendment_details": []}:item.Detail = item.Detail
      }) 
      excluMedConditionElements=props.newIE.excluMedConditionElements

      props.newIE. excluInterventionElements.forEach(item => {
        delete item.Values
        item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
        item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
        item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

        item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
        "sfr": "NA",
        "amendment_details": []}:item.Detail = item.Detail
      }) 
      excluInterventionElements=props.newIE. excluInterventionElements

      props.newIE. excluLabTestElements.forEach(item => {
        delete item.Values
        item.user_value === null || item.user_value === undefined ? item.user_value = '':item.user_value = item.user_value
        item.user_value_free_text === null || item.user_value_free_text === undefined ? item.user_value_free_text = '':item.user_value_free_text = item.user_value_free_text
        item.historical_value === null || item.historical_value === undefined ? item.historical_value = '':item.historical_value = item.historical_value

        item.Detail === null || item.Detail === undefined ? item.Detail = {"par": "NA",
        "sfr": "NA",
        "amendment_details": []}:item.Detail = item.Detail
      }) 
      excluLabTestElements=props.newIE. excluLabTestElements

      var newInclusion = {
        "Demographics": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": demographicsElements
        },
        "Medical Condition": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": medConditionElements
        },
        "Intervention": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": interventionElements
        },
        "Lab / Test": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": labTestElements
        }
      }

      var newExclusion = {
        "Demographics": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": excluDemographicsElements
        },
        "Medical Condition": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": excluMedConditionElements
        },
        "Intervention": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": excluInterventionElements
        },
        "Lab / Test": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": excluLabTestElements
        }
      }
      let newScenario = JSON.parse(JSON.stringify(props.newTrial.trialRecord.scenarios.find( i=> i['ts_id'] == scenarioId)));

      newScenario["ie_dismiss_list"] = {
        Inclusion: dismissInclu,
        Exclusion: dismissExclu
      }

      if(newScenario["ie_criteria"]["Inclusion Criteria"] === undefined){
        newScenario["ie_criteria"]["Inclusion Criteria"] = newInclusion
      } else {
        newScenario["ie_criteria"]["Inclusion Criteria"]['Intervention'].Entities = interventionElements
        newScenario["ie_criteria"]["Inclusion Criteria"].Demographics.Entities = demographicsElements
        newScenario["ie_criteria"]["Inclusion Criteria"]['Medical Condition'].Entities = medConditionElements
        newScenario["ie_criteria"]["Inclusion Criteria"]['Lab / Test'].Entities = labTestElements
        delete newScenario["ie_criteria"]["Inclusion Criteria"]['protocol_amendment_rate'];
        delete newScenario["ie_criteria"]["Inclusion Criteria"]['protocol_amendment_rate_state'];
        delete newScenario["ie_criteria"]["Inclusion Criteria"]['screen_failure_rate'];
        delete newScenario["ie_criteria"]["Inclusion Criteria"]['screen_failure_rate_state'];
      }

      if(newScenario["ie_criteria"]["Exclusion Criteria"] === undefined){
        newScenario["ie_criteria"]["Exclusion Criteria"] = newExclusion
      } else {
        newScenario["ie_criteria"]["Exclusion Criteria"].Demographics.Entities = excluDemographicsElements
        newScenario["ie_criteria"]["Exclusion Criteria"]['Medical Condition'].Entities = excluMedConditionElements
        newScenario["ie_criteria"]["Exclusion Criteria"].Intervention.Entities = excluInterventionElements
        newScenario["ie_criteria"]["Exclusion Criteria"]['Lab / Test'].Entities = excluLabTestElements
      delete newScenario["ie_criteria"]["Exclusion Criteria"]['protocol_amendment_rate'];
      delete newScenario["ie_criteria"]["Exclusion Criteria"]['protocol_amendment_rate_state'];
      delete newScenario["ie_criteria"]["Exclusion Criteria"]['screen_failure_rate'];
      delete newScenario["ie_criteria"]["Exclusion Criteria"]['screen_failure_rate_state'];
      }

      const newScenarioList = props.newTrial.trialRecord.scenarios.map((item, id) =>{
        if(item['ts_id'] == scenarioId){
          //If go back from SOA and SOA got edited, re-calculate SOA results for save changes
          if(item['Schedule of Events']?.Finished != undefined && !item['Schedule of Events']?.Finished){
            return reCalculateSOA(newScenario, item['Schedule of Events'])
          } else {
            return newScenario
          }
        } else {
            return item
        }
      })

      let trial_uuid = props.location.state.trial_id
      let newTrial = {"trial_uuid" : trial_uuid}
      newTrial["scenarios"] = newScenarioList

      setTrialRecord(newTrial)
      props.createTrial({
        trialRecord: newTrial,
      });
      setImpactColors(activeChartColors)
      setExcluImpactColors(activeChartColors)

      console.log("save payload - newTrial",newTrial);

      newTrial["user_name"] = getEmail()

      const resp = await saveIECriteria(newTrial);
      setPageLoading(false)      
      if (resp && Object.keys(resp).length !== 0) {

        var currentScenario = resp.scenarios.find( i=> i['ts_id'] == scenarioId)

        setDemographicsElements([])
        setDemographicsElements( demographicsElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
  
        setMedConditionElements([])
        setMedConditionElements(medConditionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setInterventionElements([])
        setInterventionElements(interventionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setLabTestElements([]);
        setLabTestElements(labTestElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setExcluDemographicsElements([])
        setExcluDemographicsElements( excluDemographicsElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
  
        setExcluMedConditionElements([])
        setExcluMedConditionElements(excluMedConditionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setExcluInterventionElements([])
        setExcluInterventionElements(excluInterventionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setExcluLabTestElements([]);
        setExcluLabTestElements(excluLabTestElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        const totalParAmendmentCount = currentScenario.total_par_amendment_count
        const totalSfrAmendmentCount = currentScenario.total_sfr_amendment_count

        setTotal_par_amendment_count(totalParAmendmentCount)
        setTotal_sfr_amendment_count(totalSfrAmendmentCount)

         if(typeof currentScenario['ie_criteria'] === "object" && Object.keys(currentScenario['ie_criteria']).length === 0 )  {
            setCriteria_count_inclusion('0')
            setCriteria_count_exclusion('0')

            setCriteria_par_amendment_count_inclusion('0')
            setCriteria_par_amendment_count_exclusion('0')
            setProtocol_amendment_rate('')

            setCriteria_sfr_amendment_count_inclusion('0')
            setCriteria_sfr_amendment_count_exclusion('0')
            setScreen_failure_rate('')

             setProtocolRateData(defaultChartValue)
             setScreenRateData(defaultChartValue)

             setShowChartLabel(false)
             setImpactColors(inActiveChartColors)
            setAmend_avg_rate('')
            setScreen_avg_rate('')

            setShowChartLabel(false)
            setExcluImpactColors(inActiveChartColors)
            setExcluAmend_avg_rate('')
            setExcluScreen_avg_rate('')
             return;
         }else{
        //Get inclusion chart info
        var inclu = currentScenario["ie_criteria"]["Inclusion Criteria"]

        let criteriaCountInclusion = inclu.criteria_count_inclusion
        setCriteria_count_inclusion(criteriaCountInclusion)

        const currCriteriaParAmendmentCountInclusion = inclu.criteria_par_amendment_count_inclusion
        setCriteria_par_amendment_count_inclusion(currCriteriaParAmendmentCountInclusion)

        const currCriteriaSfrAmendmentCountInclusion = inclu.criteria_sfr_amendment_count_inclusion
        setCriteria_sfr_amendment_count_inclusion(currCriteriaSfrAmendmentCountInclusion)

        let currProtocolAmendmentRate = inclu.protocol_amendment_rate
        setProtocol_amendment_rate(currProtocolAmendmentRate)

        let currScreenFailureRate = inclu.screen_failure_rate
        setScreen_failure_rate(currScreenFailureRate)

        const protocolAmendmentRateInclusion = inclu.protocol_amendment_rate
        setProtocol_amendment_rate_inclusion(protocolAmendmentRateInclusion)

        const screenFailureRateInclusion = inclu.screen_failure_rate
        setScreen_failure_rate_inclusion(screenFailureRateInclusion)

         setProtocolRateData([
            {value: inclu['Lab / Test'].criteria_par_amendment_count_category, name: 'Labs / Tests'},
            {value: inclu.Intervention.criteria_par_amendment_count_category, name: 'Intervention'},
            {value: inclu.Demographics.criteria_par_amendment_count_category, name: 'Demographics'},
            {value: inclu['Medical Condition'].criteria_par_amendment_count_category, name: 'Medical Condition'}
          ])
          setScreenRateData([
              {value: formatNumber(inclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests'},
              {value: formatNumber(inclu.Intervention.screen_failure_rate), name: 'Intervention'},
              {value: formatNumber(inclu.Demographics.screen_failure_rate), name: 'Demographics'},
              {value: formatNumber(inclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition'}
          ])

        var tempScoreA = ''
        var tempScoreB = ''

        let protocol_amendment_state = inclu.protocol_amendment_rate_state
        let screen_failure_state = inclu.screen_failure_rate_state

        tempScoreA = inclu.criteria_par_amendment_count_inclusion + '\n'+protocol_amendment_state
        tempScoreB = inclu.criteria_sfr_amendment_count_inclusion + '\n'+screen_failure_state

        setAmend_avg_rate(tempScoreA)
        setScreen_avg_rate(tempScoreB)

        //Get exclusion chart info
        var exclu = currentScenario["ie_criteria"]["Exclusion Criteria"]

        setExcluProtocolRateData([
        {value: exclu['Lab / Test'].criteria_par_amendment_count_category, name: 'Labs / Tests'},
        {value: exclu.Intervention.criteria_par_amendment_count_category, name: 'Intervention'},
        {value: exclu.Demographics.criteria_par_amendment_count_category, name: 'Demographics'},
        {value: exclu['Medical Condition'].criteria_par_amendment_count_category, name: 'Medical Condition'}
        ])
        setExcluScreenRateData([
        {value: formatNumber(exclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests'},
        {value: formatNumber(exclu.Intervention.screen_failure_rate), name: 'Intervention'},
        {value: formatNumber(exclu.Demographics.screen_failure_rate), name: 'Demographics'},
        {value: formatNumber(exclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition'}
        ])

        let excluProtocolAmendmentRate = exclu.protocol_amendment_rate
        setProtocol_amendment_rate(excluProtocolAmendmentRate)

        let excluScreenFailureRate = exclu.screen_failure_rate
        setScreen_failure_rate(excluScreenFailureRate)

        const protocolAmendmentRateExclusion = exclu.protocol_amendment_rate
        setProtocol_amendment_rate_exclusion(protocolAmendmentRateExclusion)

        const screenFailureRateExclusion = exclu.screen_failure_rate
        setScreen_failure_rate_exclusion(screenFailureRateExclusion)

        let criteriaCountExclusion = exclu.criteria_count_exclusion
        setCriteria_count_exclusion(criteriaCountExclusion)

        const excluCriteriaParAmendmentCountExclusion = exclu.criteria_par_amendment_count_exclusion
        setCriteria_par_amendment_count_exclusion(excluCriteriaParAmendmentCountExclusion)

        const excluCriteriaSfrAmendmentCountExclusion = exclu.criteria_sfr_amendment_count_exclusion
        setCriteria_sfr_amendment_count_exclusion(excluCriteriaSfrAmendmentCountExclusion)

        let exclu_protocol_amendment_state = exclu.protocol_amendment_rate_state
        let exclu_screen_failure_state = exclu.screen_failure_rate_state

        tempScoreA = exclu.criteria_par_amendment_count_exclusion + '\n'+exclu_protocol_amendment_state
        tempScoreB = exclu.criteria_sfr_amendment_count_exclusion + '\n'+ exclu_screen_failure_state

        setExcluAmend_avg_rate(tempScoreA)
        setExcluScreen_avg_rate(tempScoreB)

        if(currentScenario['therapeutic_area_average']){
          setTherapeutic_Amend_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].protocol_amendment_rate)
          setTherapeutic_Screen_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].screen_failure_rate)
          setExcluTherapeutic_Amend_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].protocol_amendment_rate)
          setExcluTherapeutic_Screen_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].screen_failure_rate)
        }

        setScenario(currentScenario)
        setInsightInclu(currentScenario['ie_insights'] && currentScenario['ie_insights']['Inclusion'])
        setInsightExclu(currentScenario['ie_insights'] && currentScenario['ie_insights']['Exclusion'])
        setShowChartLabel(true)

         }
        //props.updateSoaActivities(initialSoa)
        // props.updateIEActivities({})
        setExcluActiveKey(['1'])
        message.success("Save successfully");
      }else{
        message.error("Failed to save");
      }
    }

  useEffect(() => {
    updateTableData()
  }, [demographicsElements, medConditionElements, interventionElements, labTestElements, props.newIE])

  useEffect(() => {
    updateExcluTableData()
  }, [excluDemographicsElements, excluMedConditionElements, excluInterventionElements, excluLabTestElements, props.newIE])


  const updateTableData = () => {    
    let demographicsTmp = props.newIE?.demographicsElements.map((e,idx) => {
      e.Key = (idx + 1) + ''
      return e
    })
    setDemographicsTableData(demographicsTmp)

    let medConditionTmp = props.newIE?.medConditionElements.map((e,idx) => {
      e.Key = demographicsTmp.length + (idx + 1) + ''
        return e
    })
    setMedConditionTableData(medConditionTmp)

    let interventionTmp =  props.newIE?.interventionElements.map((e,idx) => {
      e.Key = demographicsTmp.length + medConditionTmp.length + (idx + 1) + ''
        return e
    })
    setInterventionTableData(interventionTmp)

    let labTestTmp =  props.newIE?.labTestElements.map((e,idx) => {
      e.Key = demographicsTmp.length + medConditionTmp.length + interventionTmp.length + (idx + 1) + ''
        return e
    })
    setLabTestTableData(labTestTmp)
  }

  const updateExcluTableData = () => {

    let demographicsTmp = props.newIE?.excluDemographicsElements.map((e,idx) => {
      e.Key = (idx + 1) + ''
      return e
    })
    setExcluDemographicsTableData(demographicsTmp)

    let medConditionTmp = props.newIE?.excluMedConditionElements.map((e,idx) => {
      e.Key = demographicsTmp.length + (idx + 1) + ''
        return e
    })
    setExcluMedConditionTableData(medConditionTmp)

    let interventionTmp =  props.newIE?.excluInterventionElements.map((e,idx) => {
      e.Key = demographicsTmp.length + medConditionTmp.length + (idx + 1) + ''
        return e
    })
    setExcluInterventionTableData(interventionTmp)

    let labTestTmp =  props.newIE?.excluLabTestElements.map((e,idx) => {
      e.Key = demographicsTmp.length + medConditionTmp.length + interventionTmp.length + (idx + 1) + ''
        return e
    })
    setExcluLabTestTableData(labTestTmp)
  }

  useEffect(() => {
    if(activeTabKey === '1' || activeTabKey == '2') {
      if(props.newSoa?.soaWeeks?.length > 0) {
        props.updateSoaActivities({soaWeeks :  props.newSoa?.soaWeeks})
      }
      setProcessStep(0)
    }
    if(activeTabKey == '0') {
      setProcessStep(1)
    }
  }, [activeTabKey])

  const handleGoBack = (scheduleOfEvents) =>{
    let newScenario = trialRecord.scenarios.find( i=> i['ts_id'] == scenarioId)
    newScenario['Schedule of Events'] = scheduleOfEvents
    const newScenarioList = trialRecord.scenarios.map((item, id) =>{
      if(item['ts_id'] == scenarioId){
          return newScenario
      } else {
          return item
      }
    })
    let newTrial = trialRecord
    newTrial.scenarios = newScenarioList
    setTrialRecord(newTrial)
    props.createTrial({
      trialRecord: newTrial,
    });
    setProcessStep(0)
    setSubmitType(0)
  }

  const onInsightClick = (key) => {
    let rawInsight = [...insightInclu]   
    rawInsight = rawInsight.filter((d) => {
      return d.criteria !== key.criteria
    })
    
    setInsightInclu(rawInsight)
    let indexdemographicsElements = demographicsElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'])
    let detailDemographics 
    let indexDetailDemographics
    indexDetailDemographics = originDemographics.findIndex((domain) => key.criteria == domain['Text'])
    indexDetailDemographics>-1?detailDemographics = originDemographics[indexDetailDemographics]:[]
    
    let indexmedConditionElements = medConditionElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'])
    let detailmedCondition
    let indexDetailmedCondition
    indexDetailmedCondition = originMedCondition.findIndex((domain) => key.criteria == domain['Text'])
    indexDetailmedCondition>-1?detailmedCondition = originMedCondition[indexDetailmedCondition]:[]

    let indexinterventionElements = interventionElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'])
    let detailintervention 
    let indexDetailintervention
    indexDetailintervention = originIntervention.findIndex((domain) => key.criteria == domain['Text'])
    indexDetailintervention>-1?detailintervention = originIntervention[indexDetailintervention]:[]

    let indexlabTestElements = labTestElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'])
    let detaillabTest 
    let indexDetaillabTest
    indexDetaillabTest = originLabTest.findIndex((domain) => key.criteria == domain['Text'])
    indexDetaillabTest>-1?detaillabTest = originLabTest[indexDetaillabTest]:[]

    switch(key.category.charAt(0)) {
      case "D":
        if(indexdemographicsElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "historical_value_free_text": formatHistoryValue(detailDemographics),
            "user_value_free_text": formatValue(detailDemographics),
            "rawValue": detailDemographics.Value,
            "historical_timeframe": formatHistoryTime(detailDemographics),
            "user_timeframe": formatTime(detailDemographics),
            "Frequency":detailDemographics.Frequency,
            "Detail":detailDemographics.details,
            "negation":detailDemographics.negation,
          }
          demographicsElements.push(newItem)
          setDemographicsElements(demographicsElements)
        }else if (indexdemographicsElements > -1 && key.action === "REMOVE"){
          demographicsElements.splice(indexdemographicsElements, 1)
          setDemographicsElements(demographicsElements)
        }
        props.updateIEActivities({
          demographicsElements: demographicsElements,
        })
        break;
      case "M":
        if(indexmedConditionElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "historical_value_free_text": formatHistoryValue(detailmedCondition),
            "user_value_free_text": formatValue(detailmedCondition),
            "rawValue": detailmedCondition.Value,
            "historical_timeframe": formatHistoryTime(detailmedCondition),
            "user_timeframe": formatTime(detailmedCondition),
            "Frequency":detailmedCondition.Frequency,
            "Detail":detailmedCondition.details,
            "negation":detailmedCondition.negation,
          }
          medConditionElements.push(newItem)
          setMedConditionElements(medConditionElements)
        }else if (indexmedConditionElements > -1 && key.action === "REMOVE"){
          medConditionElements.splice(indexmedConditionElements, 1)
          setMedConditionElements(medConditionElements)
        }
        props.updateIEActivities({
          medConditionElements: medConditionElements,
        })
        break;
      case "I":
        if(indexinterventionElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "historical_value_free_text": formatHistoryValue(detailintervention),
            "user_value_free_text": formatValue(detailintervention),
            "rawValue": detailintervention.Value,
            "historical_timeframe": formatHistoryTime(detailintervention),
            "user_timeframe": formatTime(detailintervention),
            "Frequency":detailintervention.Frequency,
            "Detail":detailintervention.details,
            "negation":detailintervention.negation,
          }
          interventionElements.push(newItem)
          setInterventionElements(interventionElements)
        }else if (indexinterventionElements > -1 && key.action === "REMOVE"){
          interventionElements.splice(indexinterventionElements, 1)
          setInterventionElements(interventionElements)
        }
        props.updateIEActivities({
          interventionElements: interventionElements,
        })
      break;
      default:
        if(indexlabTestElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "historical_value_free_text": formatHistoryValue(detaillabTest),
            "user_value_free_text": formatValue(detaillabTest),
            "rawValue": detaillabTest.Value,
            "historical_timeframe": formatHistoryTime(detaillabTest),
            "user_timeframe": formatTime(detaillabTest),
            "Frequency":detaillabTest.Frequency,
            "Detail":detaillabTest.details,
            "negation":detaillabTest.negation,
          }
          labTestElements.push(newItem)
          setLabTestElements(labTestElements)
        }else if (indexlabTestElements > -1 && key.action === "REMOVE") {
          labTestElements.splice(indexlabTestElements, 1)
          setLabTestElements(labTestElements)
        }
        props.updateIEActivities({
          labTestElements: labTestElements,
        })
      break;
    }
    updateTrial(1, 1)
  };
 
  const onInsightExclu = (key) => {
    let rawInsight = [...insightExclu]
    rawInsight = rawInsight.filter((d) => {
      return d.criteria !== key.criteria
    })
    setInsightExclu(rawInsight)
    let indexdemographicsElements = excluDemographicsElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria']);
    let detailDemographics 
    let indexDetailDemographics
    indexDetailDemographics = originExcluDemographics.findIndex((domain) => key.criteria == domain['Text'])
    indexDetailDemographics>-1?detailDemographics = originExcluDemographics[indexDetailDemographics]:[]

    let indexmedConditionElements = excluMedConditionElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria']);
    let detailmedCondition
    let indexDetailmedCondition
    indexDetailmedCondition = originExcluMedCondition.findIndex((domain) => key.criteria == domain['Text'])
    indexDetailmedCondition>-1?detailmedCondition = originExcluMedCondition[indexDetailmedCondition]:[]

    let indexinterventionElements = excluInterventionElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria']);
    let detailintervention 
    let indexDetailintervention
    indexDetailintervention = originExcluIntervention.findIndex((domain) => key.criteria == domain['Text'])
    indexDetailintervention>-1?detailintervention = originExcluIntervention[indexDetailintervention]:[]

    let indexlabTestElements = excluLabTestElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria']);
    let detaillabTest 
    let indexDetaillabTest
    indexDetaillabTest = originExcluLabTest.findIndex((domain) => key.criteria == domain['Text'])
    indexDetaillabTest>-1?detaillabTest = originExcluLabTest[indexDetaillabTest]:[]
  
    switch(key.category.charAt(0)) {
      case "D":
        if(indexdemographicsElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "historical_value_free_text": formatHistoryValue(detailDemographics),
            "user_value_free_text": formatValue(detailDemographics),
            "rawValue": detailDemographics.Value,
            "historical_timeframe": formatHistoryTime(detailDemographics),
            "user_timeframe": formatTime(detailDemographics),
            "Frequency":detailDemographics.Frequency,
            "Detail":detailDemographics.details,
            "negation":detailDemographics.negation,
          }
          excluDemographicsElements.push(newItem)
          setExcluDemographicsElements(excluDemographicsElements)
        }else if (indexdemographicsElements > -1 && key.action === "REMOVE"){
          excluDemographicsElements.splice(indexdemographicsElements, 1)
          setExcluDemographicsElements(excluDemographicsElements)
        }
        props.updateIEActivities({
          excluDemographicsElements:excluDemographicsElements,
        })
        break;
      case "M":
        if(indexmedConditionElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "historical_value_free_text": formatHistoryValue(detailmedCondition),
            "user_value_free_text": formatValue(detailmedCondition),
            "rawValue": detailmedCondition.Value,
            "historical_timeframe": formatHistoryTime(detailmedCondition),
            "user_timeframe": formatTime(detailmedCondition),
            "Frequency":detailmedCondition.Frequency,
            "Detail":detailmedCondition.details,
            "negation":detailmedCondition.negation,
          }
          excluMedConditionElements.push(newItem)
          setExcluMedConditionElements(excluMedConditionElements)
        }else if (indexmedConditionElements > -1 && key.action === "REMOVE"){
          excluMedConditionElements.splice(indexmedConditionElements, 1)
          setExcluMedConditionElements(excluMedConditionElements)
        }
        props.updateIEActivities({
          excluMedConditionElements: excluMedConditionElements,
        })
      break;
      case "I":
        if(indexinterventionElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "historical_value_free_text": formatHistoryValue(detailintervention),
            "user_value_free_text": formatValue(detailintervention),
            "rawValue": detailintervention.Value,
            "historical_timeframe": formatHistoryTime(detailintervention),
            "user_timeframe": formatTime(detailintervention),
            "Frequency":detailintervention.Frequency,
            "Detail":detailintervention.details,
            "negation":detailintervention.negation,
          }
          excluInterventionElements.push(newItem)
          setExcluInterventionElements(excluInterventionElements)
        }else if (indexinterventionElements > -1 && key.action === "REMOVE"){
          excluInterventionElements.splice(indexinterventionElements, 1)
          setExcluInterventionElements(excluInterventionElements)
        }
        props.updateIEActivities({
          excluInterventionElements: excluInterventionElements,
        })
      break;
      default:
        if(indexlabTestElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "historical_value_free_text": formatHistoryValue(detaillabTest),
            "user_value_free_text": formatValue(detaillabTest),
            "rawValue": detaillabTest.Value,
            "historical_timeframe": formatHistoryTime(detaillabTest),
            "user_timeframe": formatTime(detaillabTest),
            "Frequency":detaillabTest.Frequency,
            "Detail":detaillabTest.details,
            "negation":detaillabTest.negation,
          }
          excluLabTestElements.push(newItem)
          setExcluLabTestElements(excluLabTestElements)
        }else if (indexlabTestElements > -1 && key.action === "REMOVE") {
          excluLabTestElements.splice(indexlabTestElements, 1)
          setExcluLabTestElements(excluLabTestElements)
        }
        props.updateIEActivities({
          excluLabTestElements: excluLabTestElements,
        })
      break;
    }
    updateTrial(2, 1)
  };

  const onDismiss = (key) => {
    let rawList = [...dismissInclu]
    rawList.push(key)
    setDismissInclu(rawList)
    let rawInsight = [...insightInclu]   
    rawInsight = rawInsight.filter((d) => {
     return d.criteria !== key.criteria
    })
    setInsightInclu(rawInsight)
  }
 
  const onDismissExclu = (key) => {
   let rawList = [...dismissExclu]
   rawList.push(key)
   setDismissExclu(rawList)
   let rawInsight = [...insightExclu]
    rawInsight = rawInsight.filter((d) => {
     return d.criteria !== key.criteria
    })
    setInsightExclu(rawInsight)
  }

  const getChartData = (datasource, key) => {
    let keyValues = [];
    datasource.forEach((e) => {
      keyValues.push(e[key]);
    });
    keyValues = Array.from(new Set(keyValues));
    const pieChartData = [];
    keyValues.forEach((v) => {
      const values = datasource.filter(
        (d) => d[key].toLowerCase() == v.toLowerCase()
      );
      pieChartData.push({
        value: values.length,
        name: v,
      });
    });
    return pieChartData;
  }

  const getOptionOne = () => {
    const optionOne = {
      title: {
        text: "By Sponsor",
        x: "5%",
        y: "top",
        textStyle: {
          fontSize: 14,
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function (params,idx) {
          const chartData = optionOne.legend.data
          const sum = chartData.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.value
          }, 0)
          let p = ((params.value / sum) * 100).toFixed(2);
          return params.name + " - " + p + "%";
        },
        position: ['5%', '10%'],
        textStyle:{
          fontSize: 12,
      },
        confine:false,
      },
      legend: {
        show:false,
        data: sponsorChartData
      },
      series: [
        {
          type: "pie",
          center: ["30%", "35%"],
          radius: ["20%", "40%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          color: sponsorChartColor,
          data: sponsorChartData.sort((a, b) => {
            return b.value - a.value;
          })
          .slice(0, 10),
        },
      ],
    };
    return optionOne;
  }
  const getOptionTwo = () => {
    const optionTwo = {
      title: {
        text: "By Status",
        x: "5%",
        y: "top",
        textStyle: {
          fontSize: 14,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function (params,idx) {
          const chartData = optionTwo.legend.data
              const sum = chartData.reduce((accumulator, currentValue) => {
               return accumulator + currentValue.value
              }, 0)
              let p = ((params.value / sum) * 100).toFixed(2);
              return params.name + " - " + p + "%";
        },
        position: ['5%', '10%'],
        textStyle:{
          fontSize: 12,
      },
        confine:false,
      },
      legend: {
        show:false,
        data: statusChartData
      },
      series: [
        {
          type: "pie",
           center: ["30%", "35%"],
          radius: ["20%", "40%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          color: statusChartColor,
          data: statusChartData.sort((a, b) => {
            return b.value - a.value;
          })
          .slice(0, 10),
        },
      ],
    };
    return optionTwo;
  }

const getClinicalTrialsGovURL = (nctID) => {
  const regexPattern = /^NCT\d{8}$/;
  if(regexPattern.test(nctID)){
      return CLINICAL_TRIALS_GOV_URL+nctID
  }
};

    const getTrialSoaData = async () => {
	    setActiveTabKey("0")
        const response = await getTrialSoa(props.location.state.trial_id, props.location.state.scenarioId, getEmail());
        if(!response) return;
        props.createSoa(response)
    }

  const columns = [
    {
      title: "NCT ID",
      dataIndex: "nct_id",
      ellipsis: true, 
      sorter: (a, b) => (a.nct_id < b.nct_id ? -1 : 1),
      render: nctId => <a style={{color: '#ca4a04'}} href={getClinicalTrialsGovURL(nctId)} target="_blank">{nctId}</a>
    },
    {
      title: "Study Title",
      dataIndex: "brief_title",
      sorter: (a, b) => (a.brief_title < b.brief_title ? -1 : 1),
      ellipsis: true
    },
    {
      title: "Sponsor",
      dataIndex: "sponsor",
      sorter: (a, b) => (a.sponsor < b.sponsor ? -1 : 1),
      ellipsis: true
    },
    {
      title: "Indication",
      dataIndex: "indication",
      ellipsis: true,
      render: indication => indication && indication.join("; "),
    },
    {
      title: "Status",
      dataIndex: "study_status",
      sorter: (a, b) => (a.study_status < b.study_status ? -1 : 1),
      ellipsis: true
    },
    {
      title: "Phase",
      dataIndex: "study_phase",
      sorter: (a, b) => (a.study_phase < b.study_phase ? -1 : 1),
      ellipsis: true,
    },
    {
      title: "SoA",
      dataIndex: "soa_status_flag",
      sorter: (a, b) => (a.soa_status_flag < b.soa_status_flag ? -1 : 1),
      ellipsis: true,
    }
  ];

    return (
        <div className="new-scenario-container">
          <Spin spinning={pageLoading} indicator={<LoadingOutlined style={{ color: "#ca4a04",fontSize: 24 }}/>}>
            <div className="scenario-header_layout"> 
              <div className="flex-container">
                <div className="flex-item-left"> 
                  <Text 
                  ellipsis={{tooltip:trialTitle}} 
                  className="home" 
                  style={{cursor: 'pointer', fontSize: '28px', maxWidth: 390}} 
                  underline={true}
                  onClick={() => {
                     props.history.push({pathname: '/trials', state: {trial_id: props.location.state.trial_id}})
                     localStorage.setItem('whetherSave', '')
                     localStorage.setItem('processStep', JSON.stringify(0));
                     localStorage.setItem('activeTabKey', JSON.stringify('1'));
                     props.updateSoaActivities(initialSoa)
                     props.updateIEActivities({
                      demographicsElements: [],
                      interventionElements: [],
                      medConditionElements: [],
                      labTestElements: [],
                      excluDemographicsElements: [],
                      excluMedConditionElements: [],
                      excluInterventionElements: [],
                      excluLabTestElements: [],
                     })
                  }}>
                      {trialTitle} 
                  </Text>
                 <span style={{fontSize: '28px'}}> | </span>
                  <Text 
                  ellipsis={{tooltip:scenario?.scenario_name}} 
                  className="home" 
                  style={{fontSize: '28px', maxWidth: 390}}>
                      {` ${scenario?.scenario_name}`}
                  </Text> 
                </div>
                <div className="flex-item-right">
                  <div className='btn-container-header'>
                    <Button className="view_similar_trials_btn" onClick={searchHistoricalTrials} style={{zIndex: 1}}> VIEW HISTORICAL TRIALS </Button>
                    {processStep === 0 ?( <Button type="primary" className="save_and_finish_btn"
                    onClick={async ()=> 
                      {
                        await saveCriteria()
                        props.history.push({pathname: '/trials', state: {trial_id: props.location.state.trial_id}})
                        localStorage.setItem('processStep', JSON.stringify(0));
                        localStorage.setItem('activeTabKey', JSON.stringify('1'));
                      }
                    } disabled={readOnlyAccess || !isAdmin && scenario?.is_template ? true : false} style={{zIndex: 1}}> SAVE AND FINISH LATER </Button>):
                    <Button type="primary" className="save_and_finish_btn"
                    onClick={()=> setSubmitType(2)} disabled={readOnlyAccess || !isAdmin && scenario?.is_template || disableSaveFinish || processStep === 0 || !props.newSoa.isLoaded? true : false} style={{zIndex: 1}}> SAVE AND FINISH LATER </Button>
                    }
                  </div> 
                </div>
              </div>
              <div className="tabs-container">
              <div className={`active-tab-name item ${activeTabKey == "1" ? "active" : ""  }`}  onClick={() => {setActiveTabKey("1")}} > 
                  <span className="tab-Name">Inclusion Criteria</span>
                </div>
                <div className={`active-tab-name item ${activeTabKey == "2" ? "active" : "" }`} onClick={() => setActiveTabKey("2")} > 
                  <span className="tab-Name">Exclusion Criteria</span>
                </div>
                <div className={`active-tab-name item ${ activeTabKey == "0" ? "active" : ""  }`} onClick={getTrialSoaData}  >
                  <span className="tab-Name">Schedule of Activities</span>
                </div>
              </div> 
            </div>

            <Tabs centered  activeKey={activeTabKey}>
                <TabPane key="1">
                  {
                    processStep === 0 && activeTabKey === '1' && excluActiveKey && (<IELibrary
                      scenario={scenario}
                      IEType={'Inclusion Criteria'}
                      insightEvent={insightInclu}
                      onDismiss={onDismiss}
                      onInsightClick={onInsightClick}
                      saveCriteria={saveCriteria}
                      updateCriteria={updateInclusionCriteria}

                      demographicsTableData={demographicsTableData}
                      medConditionTableData={medConditionTableData}
                      interventionTableData={interventionTableData}
                      labTestTableData={labTestTableData}

                      demographicsElements={props.newIE.demographicsElements}
                      interventionElements={props.newIE.interventionElements}
                      medConditionElements={props.newIE.medConditionElements}
                      labTestElements={props.newIE.labTestElements}

                      originDemographics={originDemographics}
                      originIntervention={originIntervention}
                      originMedCondition={originMedCondition}
                      originLabTest={originLabTest}

                      defaultActiveKey={defaultActiveKey}
                      collapsible={collapsible}
                      updateTrial={updateTrial}
                      readOnlyAccess={readOnlyAccess}
                      isAdmin={isAdmin}
                      protocolRateData={protocolRateData}
                      inActiveChartColors={inActiveChartColors} defaultChartValue={defaultChartValue} 
                      impactColors={impactColors}
                      amend_avg_rate={amend_avg_rate}
                      showChartLabel={showChartLabel}
                      par_historical_trial_average={par_historical_trial_average}
                      criteria_par_amendment_count={criteria_par_amendment_count_inclusion} 
                      criteria_count={criteria_count_inclusion}protocol_amendment_rate={protocol_amendment_rate}
                      sfr_historical_trial_average={sfr_historical_trial_average} criteria_sfr_amendment_count={criteria_sfr_amendment_count_inclusion} 
                      screen_failure_rate={screen_failure_rate} screen_avg_rate={screen_avg_rate}
                      screenRateData={screenRateData}
                      submitType={submitType} 
                      scenarioId={scenarioId} 
                      history={props.history} 
                      setScenario={setScenario} 
                      similarHistoricalTrials={props.location.state.similarHistoricalTrials} 
                      trial_id={props.location.state.trial_id}
                      readOnly={readOnlyAccess || !isAdmin && scenario?.is_template}
                      scenarioType={scenarioType}
                      excluActive={excluActiveKey} >
                    </IELibrary>)
                  }
                </TabPane>
                <TabPane key="2">
                {
                  processStep === 0 && activeTabKey === '2'   && excluActiveKey && (<IELibrary
                      scenario={scenario}
                      IEType={'Exclusion Criteria'}
                      insightEvent={insightExclu}
                      onDismiss={onDismissExclu}
                      onInsightClick={onInsightExclu}
                      saveCriteria={saveCriteria}
                      updateCriteria={updateExclusionCriteria}

                      demographicsTableData={excluDemographicsTableData}
                      medConditionTableData={excluMedConditionTableData}
                      interventionTableData={excluInterventionTableData}
                      labTestTableData={excluLabTestTableData}

                      demographicsElements={props.newIE.excluDemographicsElements}
                      interventionElements={props.newIE.excluInterventionElements}
                      medConditionElements={props.newIE.excluMedConditionElements}
                      labTestElements={props.newIE.excluLabTestElements}

                      originDemographics={originExcluDemographics}
                      originIntervention={originExcluIntervention}
                      originMedCondition={originExcluMedCondition}
                      originLabTest={originExcluLabTest}

                      defaultActiveKey={excluDefaultActiveKey}
                      collapsible={excluCollapsible}
                      updateTrial={updateTrial}
                      readOnlyAccess={readOnlyAccess}
                      isAdmin={isAdmin}
                      protocolRateData={excluProtocolRateData}
                      inActiveChartColors={inActiveChartColors} defaultChartValue={defaultChartValue} 
                      impactColors={excluImpactColors}
                      amend_avg_rate={excluAmend_avg_rate}
                      showChartLabel={showChartLabel}
                      par_historical_trial_average={par_historical_trial_average}
                      criteria_par_amendment_count={criteria_par_amendment_count_exclusion} 
                      criteria_count={criteria_count_exclusion}protocol_amendment_rate={protocol_amendment_rate}
                      sfr_historical_trial_average={sfr_historical_trial_average} criteria_sfr_amendment_count={criteria_sfr_amendment_count_exclusion} 
                      screen_failure_rate={screen_failure_rate} screen_avg_rate={excluScreen_avg_rate}
                      screenRateData={excluScreenRateData}
                      submitType={submitType} 
                      scenarioId={scenarioId} 
                      history={props.history} 
                      setScenario={setScenario} 
                      similarHistoricalTrials={props.location.state.similarHistoricalTrials} 
                      trial_id={props.location.state.trial_id}
                      readOnly={readOnlyAccess || !isAdmin && scenario?.is_template}
                      scenarioType={scenarioType}
                      excluActive={excluActiveKey}>
                    </IELibrary>)
                  }
                </TabPane>
                <TabPane key="0">
                  {processStep === 1 && (<> 
                    <ScheduleActivities 
                    record={trialRecord.trial_uuid ? trialRecord : props.newTrial.trialRecord} 
                    submitType={submitType} 
                    scenarioId={scenarioId} 
                    handleGoBack={handleGoBack} 
                    history={props.history} 
                    setVisibleSOA={showSOAModal} 
                    setScenario={setScenario} 
                    nctList={props.location.state.similarHistoricalTrials} 
                    setDisableSaveFinish={setDisableSaveFinish} 
                    trial_uuid={props.location.state.trial_id}
                    readOnly={readOnlyAccess || !isAdmin && scenario?.is_template}
                    scenarioType={scenarioType}
                    event_numbers={props.location.state.evtNumbers}
                    event_weeks={props.location.state.activityweeks}
                    evt_NotesList={props.location.state.activity_notesList}
                    processStep={processStep}
                    />
                  </>)}
                </TabPane>
             </Tabs>
            </Spin>

            <Modal visible={showHistorical} 
            title="View Historical Trial List" 
            centered={true} 
            onCancel={handleCancelSimialarTrialsModal} 
            footer={null} 
            zIndex={1001}
            // style={{ left: '12%', top:10 }} 
            >
              <Spin spinning={spinning} indicator={<LoadingOutlined style={{ color: "#ca4a04",fontSize: 24 }}/>} >
              <Row>
                {/* <Col span={6}>   
                  <ReactECharts option={getOptionOne()}></ReactECharts> 
                </Col>
                <Col span={6}> 
                <div className="my-legend-wrapper-soa">
                  {
                    sponsorChartData.sort((a,b)=>{
                      return b.value -a.value
                    }).slice(0,5).map( (d,idx)=>{
                      const chartData = sponsorChartData
                      const sum = chartData.reduce((accumulator, currentValue) => {
                       return accumulator + currentValue.value
                      }, 0)
                      let percent = ((d.value / sum) * 100).toFixed(2);
                      return(
                        <div className="custom-legend" >
                        <span className="my_legend" style={{'backgroundColor':sponsorChartColor[idx]}}></span><i className="my_legend_text">{`${d.name} - ${percent}%`}</i>
                      </div>
                      )
                    })
                  }
                </div>
                </Col>
                <Col span={6}>  <ReactECharts option={getOptionTwo()}></ReactECharts>  </Col>
                <Col span={6}>
                  <div className="my-legend-wrapper-soa">
                    {
                      statusChartData.sort((a,b)=>{
                        return b.value -a.value
                      }).slice(0,5).map( (d,idx)=>{
                        const chartData = statusChartData
                        const sum = chartData.reduce((accumulator, currentValue) => {
                        return accumulator + currentValue.value
                        }, 0)
                        let percent = ((d.value / sum) * 100).toFixed(2);
                        return(
                          <div className="custom-legend" >
                          <span className="my_legend" style={{'backgroundColor':statusChartColor[idx]}}></span><i className="my_legend_text">{`${d.name} - ${percent}%`}</i>
                        </div>
                        )
                      })
                    }
                  </div>
                </Col> */}
              </Row>
              <Row style={{marginTop: 50}}>
                  <Col span={24}>
                    <div className="soa-simialar-table-wrapper">
                    <Table
                  columns={columns}
                  dataSource={historicalTrialdata}
                  pagination={{
                    position: ["bottomRight"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${historicalTrialdata.length} items`,
                    defaultPageSize: 5,
                    pageSizeOptions:["5", "10", "20", "50", "100"], 
                      }} 
                    />
                    </div>
                  </Col> 
              </Row>
              </Spin>
            </Modal>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    createTrial: (val) => dispatch(createActions.createTrial(val)),
    createSoa: (val) => dispatch(createActions.createSoa(val)), 
    updateSoaActivities: (val) => dispatch(createActions.updateSoaActivities(val)),
    updateIEActivities: (val) => dispatch(createActions.updateIEActivities(val)),
});
  
const mapStateToProps = (state) => ({
newTrial: state.trialReducer,
newSoa: state.soaReducer,
newIE: state.ieReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewScenarioPage));