/* eslint-disable */
import React, { useState, useReducer, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import {
  Input,
  Select,
  message,
  Button,
  Progress,
  Spin,
  Breadcrumb,
  Drawer,
  Steps,
  Modal,
} from "antd";
import {
  PlusCircleOutlined,
  HomeOutlined,
  LoadingOutlined,
  LeftOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";
import * as historyActions from "../../actions/historyTrial";
import { initialTrial } from "../../reducers/trialReducer.js";
import TrialList from "../../components/TrialList";
import TrialDetails from "../../components/TrialDetails";
import Scenarios from "../../components/Scenarios";
import Filters from "../../components/Filters";
import ScenarioDetails from "../../components/ScenarioDetails";
import { getTrialList, addStudy, updateStudy, getGetReferenceData, getStudy, checkTrialDataPreparation, listMembers} from "../../utils/ajax-proxy";
import bgDotPic from "../../assets/dots.svg";
import { getFirstName, isAuthenticated, getUsername, getEmail } from "../../utils/auth";

import "./index.scss";

const { Option } = Select;
const { Step } = Steps;

const backgroundPic = {
  background: "url(" + bgDotPic + ")",
  backgroundRepeat: "no-repeat",
  backgroundSize: "45%",
  backgroundColor: "#dedede",
  backgroundPosition: "bottom 50px right 250px",
};

export const phase_options = [
  // "All",
  "Early Phase 1",
  "Phase 1",
  "Phase 2",
  "Phase 3",
  "Phase 4"
];

 const phase_options_withAll = [
  "All",
  "Early Phase 1",
  "Phase 1",
  "Phase 2",
  "Phase 3",
  "Phase 4"
];
export const study_types = [
  // "All",
  "Interventional",
  "Observational",
  "Patient Registries",
  "Expanded Access",
];

export const study_status = [
"Available",
"Withdrawn",
"Withheld",
"Temporarily not available",
"Recruiting",
"Active, not recruiting",
"Not recruiting",
"Not yet recruiting",
"No longer available",
"Enrolling by invitation",
"Suspended",
"Approved for marketing",
"Unknown status",
"Completed",
"Terminated"
]

const initialCount = {
  inProgress: 0,
  completed: 0,
};

const timeline = ["Trial Summary", "Trial Endpoints", "Similar Historical Trials","Team Members"]

const init = {
  trial_title: "",
  description: "",
  therapeutic_area: ["All"],
  indication: [],
  trial_alias: "",
  study_type: [],
  study_phase: [],
  members: [],
  molecule_name: "",
  pediatric_study: null,
  study_country: "",
  scenarios: [],
  CostAvg: 0,
  BurdenAvg: 0,
  soa_status_flag: "",
  dateFrom: null,
  dateTo: null,
  study_status: null,
  userRolesList: [],   
  primary_endpoints: [],
  secondary_endpoints: [],
  tertiary_endpoints: [],
  similarHistoricalTrials: [], 
  studyPhaseList: [],
  studyTypeList: [],
  studyCountryList:[],
  indicationList: [], 
  studyStatusList: [],
  indicationTherapeuticMapping: {},
  estimated_enrollment_count: 0,
  saved_filters: {
    study_phase: [],
    study_type: [],
    indication: [],
    study_status: [],
    study_start_date: [2010, 2025],
    moa: [],
    pediatric: null,
    soa: ""
  },
};

const validateFields = ["trial_alias", "trial_title", "therapeutic_area", "indication", "description", "molecule_name", "estimated_enrollment_count"];
const requiredStringFields = ["trial_alias", "trial_title", "therapeutic_area", "estimated_enrollment_count"];
const requiredListFields = ["indication"];

const TrialPortfolio = (props) => {
  const { showSearch, setShowSearch } = props;
  const username = getFirstName();
  const [showDetails, setShowDetails] = useState(localStorage.getItem('show-details')?localStorage.getItem('show-details').toUpperCase() == 'TRUE':false);
  const [status, setStatus] = useState("IN PROGRESS");
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [phase, setPhase] = useState(["All"]);
  const [area, setArea] = useState(["All"]);
  const [visible, setVisible] = useState(false);
  const [updateDetail, setUpdateDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [indicationList, setIndicationList] = useState([])
  const [indicationListAll, setIndicationListAll] = useState([])
  const [studyPhaseList, setStudyPhaseList] = useState([])
  const [rolesList, setRolesList] = useState([])
  const [studyPhaseListAll, setStudyPhaseListAll] = useState([])
  const [studyTypeList, setStudyTypeList] = useState([])
  const [studyCountryList, setStudyCountryList] = useState([])
  const [studyStatusList, setStudyStatusList] = useState([])
  const [viewScenario, setViewScenario] = useState({viewScenarioDetails: false, scnarioId: ''})
  const [indicationTherapeuticMap, setIndicationTherapeuticMap] = useState([])
  const [therapeuticAreaList, setTherapeuticAreaList] = useState([])
  const [trialAliasError, setTrialAliasError] = useState("");
  const [trialTitleError, setTrialTitleError] = useState("");
  const [therapeuticAreaError, setTherapeuticAreaError] = useState("");
  const [indicationError, setIndicationError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [moleculeNameError, setMoleculeNameError] = useState("");
  const [estimatedTotalError, setEstimatedTotalError] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [progress, setProgress] = useState(0);
  // const [dataPreparationRes, setDataPreparationRes] = useState(false)
  const [progessLoading, setProgessLoading] = useState(false)
  const [showError, setshowError] = useState(false);
  const [initialState, setInitialState] = useState(init);

  const [step, setStep] = useState(0);
  const [teamMember, setTeamMember] = useState([]);
  const [editDetailTitle, setEditDetailTitle] = useState("");

  const [trial, setTrial] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...init }
  );
  const [newTrial, setNewTrial] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...init }
  );
  const [count, setCount] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...initialCount }
  );
  const [selectedMoaData, setSelectedMoaData] = useState([])
  const [patientSegmentData, setSelectedPatientSegmentData] =  useState([])
  const [moaList, setMoaList] = useState([])
  const [patientSegmentList, setPatientSegmentList] = useState([])

   const [x, setX] = useState(0);
   const [y, setY] = useState(0);
    
   const [isLoaded, setIsLoaded] = useState(false);
   const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
   const [soaCount, setSoaCount] = useState(0)

   const [soaModal, showSOAModal] = useState(false)
   const [navigateToTeamsPage, setNavigateToTeamsPage] = useState(false)

   const [resetTrialsCount, setResetTrialsCount] = useState(false)
   const [isFetching, setIsFetching] = useState(false)

   const trialPortfolioPollingTimerId = useRef(null);
   const updatingTrialPollingTimerId = useRef(null);

   const pollingIntervalMS = 10000; // Poll every 10 seconds

   const resetTrialHandler = () => {
    setResetTrialsCount(true)
   }

   useEffect(() => {
    if(isLoaded) return;
    getPosition()
   })

    useEffect(() => { 
      setIsLoaded(true);
    }, []);

    useEffect(() => {
      if (isLoaded) {
        setIsPageLoaded(true); 
        setIsLoaded(false);
      }
    }, [isLoaded]);

  const getPosition = () => {
    let iconEle:any = document.getElementById('icon')
    let coordinates =  iconEle?.getBoundingClientRect(); 
    if(!coordinates) return
    setX(coordinates.top - 20)
    setY(coordinates.right - 100)
  }

  window.addEventListener('resize', getPosition)

  useEffect(() => { 
    if(!isPageLoaded) return; 
    getPosition()
   
  }, [isPageLoaded])
 
  useEffect(() => {
     const checkAuthenticated = async () => {
      if (!(await isAuthenticated(true))) {
        props.history.push("/");
        localStorage.setItem('processStep', JSON.stringify(0));
        localStorage.setItem('activeTabKey', JSON.stringify('1'));
      }}
    const fetchIndication = async() => {
      const resp = await getGetReferenceData();

      setIndicationList(sortIndication(resp.indication_list))
      props.createTrial({
        indicationList:resp.indication_list
      })
      setIndicationListAll(resp.indication_list)
      let indicationTherapeuticMap = resp.indication_therapeutic_mapping
      setIndicationTherapeuticMap(resp.indication_therapeutic_mapping)
      props.createTrial({
        indicationTherapeuticMapping: indicationTherapeuticMap
      })
      let therapeuticArea = Object.keys(indicationTherapeuticMap)
      therapeuticArea.unshift("All") 
      setTherapeuticAreaList(therapeuticArea.sort())
      let studyPhaseListWithAll = resp.study_phase_list.map((o) => {
        return o.label
      })
      studyPhaseListWithAll.unshift("All")        
      setStudyPhaseList(resp.study_phase_list)
      setStudyPhaseListAll(studyPhaseListWithAll)
      setStudyTypeList(resp.study_type_list)
      setStudyCountryList(resp.country_list)
      setStudyStatusList(resp.study_status_list)
      setRolesList(resp.user_roles_list)
      props.createTrial({
        studyPhaseList:resp.study_phase_list
      })
      props.createTrial({
        userRolesList:resp.user_roles_list
      })
      props.createTrial({
        studyTypeList:resp.study_type_list
      })
      props.createTrial({
        studyCountryList:resp.country_list
      })
      props.createTrial({
        studyStatusList:resp.study_status_list
      })
    }
    fetchIndication();
    createInitialState();
    checkAuthenticated();
  }, [])

  useEffect(() => {
    }, [trialAliasError, trialTitleError, therapeuticAreaError, indicationError, descriptionError, moleculeNameError])

    const getTitleCase = (val) => {
      let array = val.split("_")
      let firstVal = array[0].charAt(0).toUpperCase() + array[0].slice(1);
      if(array.length < 2){ return firstVal }
      let lastVal = array[1].charAt(0).toUpperCase() + array[1].slice(1);
      return firstVal + " " + lastVal;
    };

  const createInitialState = () => {
    init.members = [{
      name: getEmail(),
      id: getEmail(),
      idx: 1,
      role: "Edit",
      user_role_id: 1,
    }];
    setInitialState(init);
    setTrial(init);
    setNewTrial(init);
  }

  const handleValidation = (key, v) => {
        let errorMsg = "";
        if(requiredStringFields.includes(key) && !newTrial[key]){
            errorMsg = getTitleCase(key) + " is required."
        }else if(requiredListFields.includes(key) && newTrial[key].length < 1){
            errorMsg = getTitleCase(key) + " is required."
        }else if(key == "trial_alias" && !newTrial[key].match(/^[0-9a-zA-Z]+$/)){
            // errorMsg = getTitleCase(key) + " must be alphanumeric characters only."
        }else if (key == "trial_title" && typeof newTrial[key] === 'string' && newTrial[key].trim().length > 300) {
            errorMsg = getTitleCase(key) + " must not exceed 300 characters."
        }else if (key == "description" && typeof newTrial[key] === 'string' && newTrial[key].trim().length > 500) {
            errorMsg = getTitleCase(key) + " must not exceed 500 characters."
        }else if (key == "trial_alias" && typeof newTrial[key] === 'string' && newTrial[key].trim().length !== 6) {
            // errorMsg = getTitleCase(key) + " must be 6 characters."
        }else if (key == "molecule_name" && typeof newTrial[key] === 'string' && newTrial[key].trim().length > 80) {
            errorMsg = getTitleCase(key) + " must not exceed 80 characters."
        }else if (key == "therapeutic_area" && newTrial['therapeutic_area'].length <1) {
          errorMsg = "Therapeutic Area is required."
        } else if (key == "estimated_enrollment_count" &&  newTrial['estimated_enrollment_count'] > 100000) {
          errorMsg = getTitleCase(key).split(' ')[1] + " must not exceed 100000."
        } else if (key == "estimated_enrollment_count" && newTrial['estimated_enrollment_count'] < 0 ) {
          errorMsg = getTitleCase(key).split(' ')[1] + " must not be less than 0."
        } else if (key == "estimated_enrollment_count" && !/^[0-9]+$/.test(newTrial['estimated_enrollment_count'] )) {
          errorMsg = getTitleCase(key).split(' ')[1] + " must be a number."
        }
        //trial_estimated_enrollment
        if(key == "trial_alias"){setTrialAliasError(errorMsg)}
        else if(key == "trial_title"){setTrialTitleError(errorMsg)}
        else if(key == "therapeutic_area"){setTherapeuticAreaError(errorMsg)}
        else if(key == "indication"){setIndicationError(errorMsg)}
        else if(key == "description"){setDescriptionError(errorMsg)}
        else if(key == "molecule_name"){setMoleculeNameError(errorMsg)}
        else if(key == "estimated_enrollment_count"){setEstimatedTotalError(errorMsg)}
        return errorMsg == ""
      };

      const  handleFilterOptiionsChange = (selectedOption, value) => {
        if(value == "TRIAL PHASE") {
          handlePhaseChange(selectedOption)
        } else if (value == "THERAPEUTIC AREA") { 
          handleAreaChange(selectedOption)
        } 
      }

    const [applyFilter, setApplyFilter] = useState(0)
    const handleSubmitFilters = () => { 
      setFilter(v => !v) 
      setApplyFilter(Math.floor(Math.random() * 101))
    }

    const handleRemoveFilters = () => {
      setPhase(['All'])
      setArea(['All'])
      setFilter(v => !v) 
      setApplyFilter(Math.floor(Math.random() * 101))
    }

  const handlePhaseChange = (value) => {
    let newValue
    if(value.length>1 && value.includes('All')) {
      if(value.indexOf('All')===0) {
        newValue = value.filter((item)=> {
          return item !== 'All'
        })
        setPhase(newValue)
      } else {
        setPhase(['All'])
      }
    } else {
      setPhase(value)
    }
  }

  const handleAreaChange = (value) => {    
    let newValue
    if(value.length>1 && value.includes('All')) {
      if(value.indexOf('All')===0) {
        newValue = value.filter((item)=> {
          return item !== 'All'
        })
        setArea(newValue)
      } else {
        setArea(['All'])
      }
    } else {
      setArea(value)
    }
  };

  const doValidation = () => {
    let reqFieldsEntered = true
    if (step == 0 || step == 2) {
      for(let key of validateFields){
        if(!handleValidation(key, null)){
            reqFieldsEntered = false
        }
      }
    } 
    if(step == 2 && soaCount < 10 && !navigateToTeamsPage) {
      showSOAModal(true)
      reqFieldsEntered = false
    }
    return reqFieldsEntered;
  };

  const handleNextStep = async () => { 
    if(doValidation()){ 
      setStep(step + 1)
      
    if (step + 1 == 1) {
      props.createTrial(newTrial)
      // fetch team member list
      const resp = await listMembers("")
      if (resp) {
        let memberList = Object.values(JSON.parse(resp.Body))?Object.values(JSON.parse(resp.Body)).map((item,index)=>{
          // return item["user"]
          return item
        }):[]
        console.log("team member:",memberList);
        setTeamMember(memberList)
      }
    }
    if (step + 1 == 3) {
        props.createTrial(newTrial)
      }
    }
  }

  useEffect(() => {
    if(!navigateToTeamsPage) return;
    handleNextStep()
  }, [navigateToTeamsPage])

  const sleep = (time: number) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const getTrialById = async (trialId, shouldSetTrial = true) => { 
    const resp = await getStudy(trialId, null, getEmail());
    if(resp && shouldSetTrial){
      setTrial(resp);
    }
  }

  const processPolling = async(trialId, callback) => {
    try {
      if(updatingTrialPollingTimerId.current != null) {
        clearInterval(updatingTrialPollingTimerId.current);
      }
    } catch (err) {
      console.error('Error clearing timer: ', err);
    }
    let dataPreparationRes = false;
    let retryTimes = 0;
    let tempTrial = trial;
    tempTrial["status"] = "Updating"
    setTrial(tempTrial);
    updatingTrialPollingTimerId.current = setInterval(async () => {
    if (!(await isAuthenticated(true))) return;
    let _trial = await getStudy(trialId, null, getEmail());
    let study_json = _trial
    dataPreparationRes = study_json["status"].toLowerCase() !== "updating"
    retryTimes += 1;
    if (dataPreparationRes){
      clearInterval(updatingTrialPollingTimerId.current);
      getTrialById(trialId, true);
      callback()
    }
    if(retryTimes > 20) {
      clearInterval(updatingTrialPollingTimerId.current);
      message.error('No response for over 6 minutes, please call assist.')
    }
    }, pollingIntervalMS);
  }

  const fetchCurrentTrial = async (trialUid) => { 
    setIsFetching(true);
    const study = await getStudy(trialUid, null, getEmail())
    if (study) {
      let selected_trial = study
      setTrial(selected_trial);
    }
    setIsFetching(false);
  }

  const handleUpdate = async (trialUid) => {
   let updated_trial = {
    "trial_title": trial["trial_title"],
    "therapeutic_area": trial["therapeutic_area"],
    "indication": trial["indication"],
    "study_type": trial["study_type"],
    "study_phase": trial["study_phase"],
    "pediatric_study": trial["pediatric_study"],
    "study_country": trial["study_country"],
    "trial_uuid": trial["trial_uuid"]
  } 
    const resp = await updateStudy(updated_trial);

    if (resp) {
      message.success("Save successfully");
      fetchCurrentTrial(trial["trial_uuid"]);
    }else{
        message.error("Failed to save");
    }
  };

  const onViewTrial = async(e, record) => {    
    e.preventDefault();
    // let dataPreparationRes = await checkTrialDataPreparation(record['_id']);
    // console.log("dataPreparationRes",dataPreparationRes);

    try {
      if(trialPortfolioPollingTimerId.current != null) {
        clearInterval(trialPortfolioPollingTimerId.current);
      }
    } catch (err) {
      console.error('Error clearing timer: ', err);
    }

    if(record["status"].toLowerCase() == 'CREATING'.toLowerCase()) {
      message.success('Your trial has been submitted for processing. Please check later.')
      return
    }

    if(record["status"].toLowerCase() == 'UPDATING'.toLowerCase()) {
      processPolling(record['trial_uuid'], ()=>{
        message.success("Update successful");
      });
    }

    setShowDetails(true);
    localStorage.setItem('show-details', 'true')
    localStorage.setItem('trial-id', record['trial_uuid'])
    let therapeuticAreaVal = record["therapeutic_area"]
    const study = await getStudy(record['trial_uuid'], null, getEmail())
      if (study) {
        let selected_trial = study;
        setTrial(selected_trial);
        therapeuticAreaVal = selected_trial["therapeutic_area"]
      }else{
        setTrial(JSON.parse(JSON.stringify(record)));
      }
;    if(typeof therapeuticAreaVal == 'object' && therapeuticAreaVal.length > 0){
        let indications = []
        therapeuticAreaVal.forEach(ta => indications = indicationTherapeuticMap[ta] != undefined ?
                                            indications.concat(indicationTherapeuticMap[ta]) : indications)
         setIndicationList(sortIndication(indications))
    }else{
        setIndicationList(sortIndication(indicationListAll))
    }
  };

  const handleTrialInputChange = (key, e) => {
    setTrial({
      [key]: e.target.value,
    });
  };
  
  const HandleTrialDetailUpdate = async(key, v) => {
    setNewTrial(v)
    props.createTrial(v)
    // show edit details panel
    setVisible(true)
    setUpdateDetail(true)
    setIndicationList(sortIndication(indicationListAll))
    newTrial["indication"] = []
    // clear all error message for required fields
    setTrialAliasError("")
    setTrialTitleError("")
    setTherapeuticAreaError("")
    setIndicationError("")
    setDescriptionError("")
    setMoleculeNameError("")
    setEstimatedTotalError("")
    switch (key) {
      case "edit_details":
      setStep(0)
      setEditDetailTitle("Edit Details")
        break;
      case "edit_endpoints":
      setStep(1)
      setEditDetailTitle("Edit Endpoints")
        break;
      case "edit_similiarTrials":
      setStep(2)
      setEditDetailTitle("Edit Similar Historical Trials")
        break;
      case "edit_teamMembers":
      setStep(3)
      setEditDetailTitle("Edit Team Members")
      // fetch team member list
      const resp = await listMembers("")      
      if (resp) {
        let memberList = Object.values(JSON.parse(resp.Body))?Object.values(JSON.parse(resp.Body)).map((item,index)=>{
          // return item["user"]
          return item
        }):[]
        console.log("team member:",memberList);
        setTeamMember(memberList)
      }
        break;
      default:
      setStep(0)
      setEditDetailTitle("Edit Details")
        break;
    }
  };

  const handleTrialSelectChange = (key, v) => {
    if(key == 'selectedMoaData') {
      setSelectedMoaData(v)
      return;
    }
    if(key == 'patientSegmentData') {
      setSelectedPatientSegmentData(v)
      return;
    }
    if(key == "therapeutic_area"){
        handleTherapeuticAreaChange(v, trial)
    }
    setTrial({
      [key]: v,
    });
  };

  const handleTherapeuticAreaChange = (v, trialRef) => {

    if(v.length > 1 && v.includes("All")){
		const index = v.indexOf("All");
		if(index === 0) {
			v.splice(index, 1);
		}else if(index === v.length-1){
			v.splice(0, index);
		}
	}
    if(v.length == 1 && v[0]=="All"){
		setIndicationList(sortIndication(indicationListAll))
    }else{
        let indications = []
        v.forEach(ta => {indications = indicationTherapeuticMap[ta] != undefined ? indications.concat(indicationTherapeuticMap[ta]) : indications})
		setIndicationList(sortIndication(indications))
		trialRef["indication"] = trialRef["indication"].filter(ind => indications.map(i => i.value).includes(ind))
    }
  };

  const [ isFilterOpen, setFilter ] = useState(false)
  const onTextChange = (e) => {
    setSearchTxt(e.target.value)
    
  };

  const onSelectFilters = (e) => {  
    setFilter(v => !v) 
  }

  useEffect(() => {
    setShowSearch(!showDetails);
  }, [showDetails,localStorage.getItem('show-details')]);

  useEffect(() => {    
    if(localStorage.getItem('trial-id')!==''){
      setShowDetails(true);
      localStorage.setItem('show-details', 'true')
      // setTrial(props.newTrial)      
      const getTrialById = async () => {
        const resp = await getStudy(localStorage.getItem('trial-id'), null, getEmail()); 
        if(resp){
         setTrial(resp);
        }
      };
      getTrialById();
    } else {
      localStorage.getItem('show-details')&&setTrial(props.newTrial);
    }
  }, [localStorage.getItem('trial-id')]);

  useEffect(() => {
    if(!rawData) return;
    const tmpData = rawData.filter((d) => {
      // search only works for trial_alias
      let searchedFields = [
        d["trial_alias"],
      ];
      searchedFields = searchedFields.filter(Boolean);
      searchedFields = searchedFields.map((i) =>
        typeof i == "string" ? i.toLowerCase() : String(i)
      );

      if (typeof d["indication"] == 'string') {
        searchedFields.push(d["indication"])
      } else if (typeof d["indication"] == 'object') {
        searchedFields.concat(d["indication"])
      };

      const isIncluded = searchedFields.map((f) => {
        return f.search(searchTxt.toLowerCase()) != -1
      })

      let phaseRes = []
      phase.forEach((item, index)=>{
        phaseRes.push(d["study_phase"].includes(item))
      })

      let areaRes = []
      area.forEach((item, index)=>{
        areaRes.push(d["therapeutic_area"].includes(item))
      })
      
      return (
        (
          ( status === 'IN PROGRESS' || status === 'CREATING' || status === 'UPDATING' ) ?
            (
              d.status.toLowerCase() == 'IN PROGRESS'.toLowerCase() ||
              d.status.toLowerCase() == 'CREATING'.toLowerCase() ||
              d.status.toLowerCase() == 'UPDATING'.toLowerCase()
            ) :
            d.status.toLowerCase() == 'COMPLETED'.toLowerCase()
        ) &&
        (
          phase.length === 1 && phase[0] === "All" || phase.length === 0 ? true : phaseRes.includes(true)
        ) &&
        (
          area.length === 1 && area[0] === "All" || area.length === 0 ? true : areaRes.includes(true)
        ) &&
        (
          searchTxt ? isIncluded.find((e) => e == true) : true
        )
      );
    });
    setData(tmpData);
  }, [status, rawData, area, phase, searchTxt]);

  const fetchList = async () => {

  try {
      if(updatingTrialPollingTimerId.current != null && !showDetails) {
        clearInterval(updatingTrialPollingTimerId.current);
      }
    } catch (err) {
      console.error('Error clearing timer: ', err);
    }
    setLoading(true);
    const source = await getTrialList();
    localStorage.setItem('user_type', source['user_type'])
    setLoading(false);
    setRawData(source['trial_list']);
    console.log("trial list-----", source);
    const inProgressArr = source['trial_list'].filter((d) => {
      return d.status && d.status.toUpperCase() == "IN PROGRESS" || d.status && d.status.toUpperCase() == "CREATING" || d.status && d.status.toUpperCase() == "UPDATING";
    });
    const completedArr = source['trial_list'].filter((d) => {
      return d.status && d.status.toUpperCase() == "COMPLETED";
    });
    setCount({
      inProgress: inProgressArr.length,
      completed: completedArr.length,
    });
    const updatingTrials =  source['trial_list'].filter((d) => {
        return d.status && d.status.toUpperCase() == "UPDATING";
    });
    if(updatingTrials && updatingTrials.length > 0 && !showDetails){
        autoRefreshList();
    }
  };

   const autoRefreshList = async () => {
    let dataPreparationRes = false;
    let retryTimes = 0;

   try {
      if(trialPortfolioPollingTimerId.current != null) {
        clearInterval(trialPortfolioPollingTimerId.current);
      }
    } catch (err) {
      console.error('Error clearing timer: ', err);
    }

    trialPortfolioPollingTimerId.current = setInterval(async () => {
      const trialList = await getTrialList();
      localStorage.setItem('user_type', trialList['user_type'])
      const updatingTrials = trialList['trial_list'].filter((d) => {
        return d.status && d.status.toUpperCase() == "UPDATING" || d.status && d.status.toUpperCase() == 'CREATING';
      });

      dataPreparationRes = updatingTrials && updatingTrials.length < 1
      retryTimes += 1;

      if (dataPreparationRes){
        clearInterval(trialPortfolioPollingTimerId.current);
        message.success("Trial(s) in the list are processed!");
        fetchList();
      }

      if(retryTimes > 20) {
        clearInterval(trialPortfolioPollingTimerId.current);
      }
    }, pollingIntervalMS);
  };

  const fetchListWithoutLoading = async () => {
    // setLoading(true);
    const source = await getTrialList();
    // setLoading(false);
    setRawData(source);
    console.log("trial list-----", source);
    const inProgressArr = source.filter((d) => {
      return d.status && d.status.toUpperCase() == "IN PROGRESS" || d.status && d.status.toUpperCase() == "CREATING" || d.status && d.status.toUpperCase() == "UPDATING";;
    });
    const completedArr = source.filter((d) => {
      return d.status && d.status.toUpperCase() == "COMPLETED";
    });
    setCount({
      inProgress: inProgressArr.length,
      completed: completedArr.length,
    });
  };

  useEffect(() => {
    !showDetails && setTrial(initialState);
    setStatus("IN PROGRESS");
    fetchList();
  }, [showDetails]);

  const handleViewScenario = (scenarioId) =>{
    setViewScenario({viewScenarioDetails: true, scnarioId: scenarioId})
  }

  const handleBack = () =>{
    setViewScenario({viewScenarioDetails: false, scnarioId: ''})
  }

  const backToHome = () => {
    setShowDetails(false)
    localStorage.setItem('show-details', 'false')
    localStorage.setItem('trial-id', '')
    setTrial(init)
    setViewScenario({viewScenarioDetails: false, scnarioId: ''})
  }

  const onClickNewTrial = () => {
    // setVisible(true)
    props.createTrial(initialTrial)
    props.history.push({
      pathname: '/new-trial',
      state: {
        // trial_id: props.location.state.trial_id ,
        // scenarios: scenarioList,
        record: props.record
      }
    })
    setUpdateDetail(false)
    setStep(0)  
    setNewTrial(initialState); 
    setIndicationList(sortIndication(indicationListAll))
    newTrial["indication"] = []
    // clear all error message for required fields
    setTrialAliasError("")
    setTrialTitleError("")
    setTherapeuticAreaError("")
    setIndicationError("")
    setDescriptionError("")
    setMoleculeNameError("")
    setEstimatedTotalError("")
  }

  const soaSelectionHandler = (val) => {
    showSOAModal(false)
    setSoaCount(val) 
    setNavigateToTeamsPage(false)
  }

  const sortIndication = (indicationList) => {
    return indicationList&&indicationList.sort((ind1, ind2) => (ind1.label < ind2.label) ? -1 : (ind1.label > ind2.label) ? 1 : 0);
  }

  return (
    <div className="trial-portfolio-container">
      {!showDetails ? (
        <>
          <div className="upper" style={backgroundPic}>
            <span className="small-trial">MY TRIALS</span>
            <p className="title">Hello {username},</p>
            <span className="sub-title">
              Here is a glance at your trial portfolio.
            </span>
            <div className="action-part">
              <div className="status-filter">
                <div
                  className={`in-progress item ${
                    status == "IN PROGRESS" ? "active" : ""
                  }`}
                  onClick={() => setStatus("IN PROGRESS")}
                >
                  <span className="number">{count.inProgress}</span>
                  <span className="status">IN PROGRESS</span>
                </div>
                <div
                  className={`complete item ${
                    status == "COMPLETED" ? "active" : ""
                  }`}
                  onClick={() => setStatus("COMPLETED")}
                >
                  <span className="number">{count.completed}</span>
                  <span className="status">COMPLETE</span>
                </div>
              </div>
            </div>
          </div>

          <div className="list-top">
            <div className="count">
              Your {status == "COMPLETED" ? "Completed" : "Current"} Trials <span className="num">({data.length})</span>
            </div>
            <div className="filter-selector">
              {/* <div className="selector-item">
                <label>THERAPEUTIC AREA</label> <br />
                <Select
                  defaultValue="All"
                  value={area}
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{ width: 200 }}
                  onChange={handleAreaChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {therapeuticAreaList.map((o) => {
                    return (
                      <Option value={o} key={o}>
                        {o}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="selector-item">
                <label> TRIAL PHASE</label>
                <br />
                <Select
                  defaultValue="All"
                  value={phase}
                  mode="multiple"
                  allowClear
                  style={{ width: 200 }}
                  onChange={handlePhaseChange}
                >
                  {studyPhaseListAll.map((o) => {
                    return (
                      <Option value={o} key={o}>
                        {o}
                      </Option>
                    );
                  })}
                </Select>
              </div> */}
              <div className="selector-item add-new-trial">
                <div className="add-btn" onClick={() => onClickNewTrial()}>
                <PlusCircleOutlined />
                NEW TRIAL
                </div>
              </div>
            </div>
          </div>
          <div className="search-container">
            {
              showSearch && (<Input
              id="icon"
              placeholder="Search"
              prefix={<SearchOutlined />}
              className="full-search"
              onChange={onTextChange} 
              value={searchTxt}
            />  )
            } 
            <div className="filter-wrapper" style={{position:"relative",width:"30%", height:"46px",top:"-46px",left:"70%"}}>
            <div className="full-search-icon" style={{position: "absolute", top: "-10px", right: "20px"}}  onClick={onSelectFilters} > Filters </div> 
            <Filters showFilter={isFilterOpen} 
              value={[phase, area]}
              filterOptions={['TRIAL PHASE', 'THERAPEUTIC AREA']}
              filterItems={[studyPhaseListAll, therapeuticAreaList]}
              parentStyle={{position: "absolute",topX: "-10px", rightX: "20px"}} 
              filterHandler={handleFilterOptiionsChange}
              applyFilters={handleSubmitFilters}
              removeFilters={handleRemoveFilters}
            />
            </div>
          </div>
          <Spin
            spinning={loading}
            indicator={
            <>
            <LoadingOutlined style={{ color: "#ca4a04", fontSize:38 }} />
            <br/>
            {progessLoading&&<Progress percent={progress} style={{width: "300px", marginTop: "100px"}} status={progress ==100? !showError?"success":"exception":"normal"}/>}
            </>
          }
          >
            <TrialList data={data} onViewTrial={onViewTrial} />
          </Spin>
        </>
      ) : (
        <div
          style={{
            background: 'url(' + bgDotPic + ') right 400px top 30px / 40% no-repeat, linear-gradient(rgb(222, 222, 222) 250px, rgb(222, 222, 222) 10px, rgb(255, 255, 255) 50px, rgb(255, 255, 255) 51px)',
          }}
        >
          <Breadcrumb>
            <Breadcrumb.Item
              className="homepage"
              onClick={backToHome}
            >
              <span>
                <HomeOutlined />
                My Trials
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Trial Design</Breadcrumb.Item>
          </Breadcrumb>
          <div className="trial-details-wrapper">
            {viewScenario.viewScenarioDetails ? (
              <>
                <ScenarioDetails record={trial} scenarioId={viewScenario.scnarioId} handleBack={handleBack}/>
              </>
            ):(
            <>
              <TrialDetails
              indicationList={ indicationList}
              record={trial}
              onSave={handleUpdate}
              onInputChange={handleTrialInputChange}
              onSelectChange={handleTrialSelectChange}
              onEditDetail={HandleTrialDetailUpdate}
              therapeuticAreaList={therapeuticAreaList}
              studyPhaseList={studyPhaseList}
              studyTypeList={studyTypeList}
              studyCountryList={studyCountryList}
              indicationTherapeuticMap={indicationTherapeuticMap}
            />
            <Spin
              spinning={isFetching}
              indicator={<LoadingOutlined style={{ color: "#ca4a04", fontSize:38 }} />}
            >
              <Scenarios record={trial} updateRecord={(t)=>setTrial(t)} handleViewScenario={handleViewScenario} afterSave={fetchCurrentTrial} />
            </Spin>
            </>
            )}
          </div>
        </div>
      )}

    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
  fetchHistory:(val) => dispatch(historyActions.fetchHistory(val)),
});

const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
  historyTrial:state.historyReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TrialPortfolio));
