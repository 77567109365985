import React from 'react';
import ReactECharts from 'echarts-for-react'; 
import './index.scss';


const ActivitiesByModality = (props) => {
     
       const { modalityChartData, visits } = props;
        const modalityOption = {
          title:{
            show:false,
            text: 'Activities by Modality',
            x:'center',
            y:'top',
            textStyle: {
              fontSize: 16,
              fontWeight: 'bold',
              color: '#333'
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              // Use axis to trigger tooltip
              type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            },
            confine:false,
            position: ['100%', '-10%']
          },
          grid: {
            left: '10%',
            right: '4%',
            top: '15%',
            bottom: '6%',
            containLabel: true
        },
          xAxis: {
            name: 'Visit Number',
            nameLocation: "middle",
            type: 'category',
            data: visits,
            nameRotate: 0, nameGap: 20,
         },
         yAxis: {
            type: 'value'
         },
          series:modalityChartData
        };
      
    return (
        <div  style={{  marginLeft: "50px", verticalAlign: "top"}} > 
            <ReactECharts option={modalityOption} style={{ width: '80%',  height: '400px'}}/>
        </div> 
    );
  };
  
  export default ActivitiesByModality;