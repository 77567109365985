/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import bgDotPic from '../../assets/dots.svg';
import {
  Col,
  Tooltip,
  Row,
  Select,
  Modal,
  Button,
  Input,
  Breadcrumb,
  Typography,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { updateStudy, getStudy } from '../../utils/ajax-proxy';
import { getEmail, getUsername, getAdminRole } from '../../utils/auth';
import { roundToWholeNumber } from '../../utils/utils';
import { HomeOutlined } from '@ant-design/icons';
import './index.scss';
const style = { padding: '8px 0' };
const { TextArea } = Input;
const { Text } = Typography;

const CompareScenario = (props: any) => {
  const {
    location: {
      state: { scenarios, record, trial_id },
    },
  } = props;
  const [scenariosList, setScenariosList] = useState([]);
  const [scenariosNamesList, setScenariosNamesList] = useState([]);
  const [finaliseModule, setFinaliseModule] = useState({
    finalise: false,
    scenario: {},
  });
  const [rationaleMsgError, setRationaleMsgError] = useState(
    'Rationale must not exceed 500 characters'
  );

  useEffect(() => {
    if (!scenarios) return;
    setScenariosList(scenarios);
    setScenariosNamesList(scenarios.map((k) => k.scenario_name));
  }, []);

  const handleCancel = () => {
    const list = [...scenariosList];

    list.forEach(ele =>
        ele.finalized = false
    );

    setScenariosList(list);

    setFinaliseModule({ finalise: false, scenario: {} });
  };

  const handleScenarioChange = (evt, currentIndex) => {
    let arr = [...scenariosList];
    const names_arr = [...scenariosNamesList];
    const element_cIndex = arr[currentIndex];

    const targetIndex = arr.findIndex((item) => item.scenario_name == evt);
    const element_targetIndex = arr.find((item) => item.scenario_name == evt);

    arr[currentIndex] = element_targetIndex;
    arr[targetIndex] = element_cIndex;

    names_arr[currentIndex] = element_targetIndex.scenario_name;
    names_arr[targetIndex] = element_cIndex.scenario_name;

    setScenariosList(arr);
    setScenariosNamesList(names_arr);
  };

  const onRationaleChange = (e, idx) => {
    const tmpList = [...scenariosList];
    tmpList[idx].rationale = e.target.value;
    setScenariosList(tmpList);
  };

  const backToHome = () => {
    props.history.push({ pathname: '/trials' });
    localStorage.setItem('show-details', 'false');
    localStorage.setItem('trial-id', '');
  };

  const backToWorkspace = () => {
    props.history.push({ pathname: '/trials', state: { trial_id: trial_id } });
  };

  const handleCompleteModule = async () => {
    if (
      record &&
      record.trial_user_role &&
      record.trial_user_role.toLowerCase() == 'read'
    )
      return;
    let errorExists = false;
    let scenarioList = [...scenariosList];
    scenarioList &&
      scenarioList.length > 0 &&
      scenarioList
        .filter((scenario) => scenario['finalized'])
        .map(
          (scenario, index) =>
            (errorExists =
              scenario['rationale'] && scenario.rationale.length > 500
                ? true
                : false)
        );
    if (errorExists) return;
    const tempTrial = JSON.parse(JSON.stringify(record));
    tempTrial.scenarios = scenarioList;
    tempTrial.status = 'Completed';
    const trialId = tempTrial['trial_uuid'];
    const resp = await updateStudy(tempTrial);
    if (resp) {
      const study = await getStudy(trialId, null, getEmail());
      if (study) {
        const newScenarios = study.scenarios;
        setScenariosList(newScenarios);
        setFinaliseModule({ finalise: false, scenario: {} });
        props.history.push({
          pathname: '/trials',
          state: { trial_id: trialId },
        });
      }
    } else {
      message.error('Failed to save');
    }
  };

  function roundFun(value, n) {
    return Math.round(value * Math.pow(10, n)) / Math.pow(10, n);
  }

  function formatCostAvg(totalCost, divisor) {
    if (totalCost === undefined || totalCost === null || totalCost === '') {
      return '';
    } else if (totalCost === 0) {
      return 0 + 'K';
    } else {
      let avg = Math.ceil((totalCost / divisor) * 1000);
      return '$' + roundFun(avg / 1000, 2) + 'K';
    }
  }

  const finaliseScenarioHandler = (item, idx) => {
    const list = [...scenariosList];

    list.forEach((ele) => (ele.finalized = false));
    
    list[idx].finalized = true;
    
    setScenariosList(list);

    setFinaliseModule({ finalise: true, id: idx, scenario: list[idx] });
  };

  return (
    <>
      <div className='complete-scenarios-module'>
        <div
          className='upper'
          s
          style={{
            background:
              'url(' +
              bgDotPic +
              ') right 400px top 30px / 40% no-repeat, linear-gradient(rgb(222, 222, 222) 250px, rgb(222, 222, 222) 10px, rgb(255, 255, 255) 50px, rgb(255 255 255) 51px)',
            padding: '0 100px',
          }}
        >
          <Breadcrumb>
            <Breadcrumb.Item className='homepage' onClick={backToHome}>
              <span>
                <HomeOutlined />
                My Trials
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item className='homepage' onClick={backToWorkspace}>
              <span>Trial Page</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Compare Scenarios</Breadcrumb.Item>
          </Breadcrumb>

          <div className='compare-scenario-box' style={{}}>
            <div className='record-container'>
              <Text ellipsis={{ tooltip: record.trial_alias }} className='home'>
                TRIAL ID: {record.trial_alias}
              </Text>

              <cpan className='compare'>Compare Scenarios</cpan>
            </div>

            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className='compare-scenario-row'
            >
              <Col className='gutter-row compare-scenario-col' span={6}></Col>
              {scenariosNamesList &&
                scenariosList &&
                scenariosList.length > 0 &&
                scenariosList.slice(0, 3).map((item, index) => {
                  return (
                    <Col
                      className='gutter-row compare-scenario-col'
                      span={6}
                      key={index}
                    >
                      <div className='title-container'>
                        <div style={style}>
                          <Select
                            defaultValue={
                              <Text
                                style={{ width: '90%' }}
                                ellipsis={{
                                  tooltip: scenariosNamesList[index],
                                }}
                              >
                                {scenariosNamesList[index]}
                              </Text>
                            }
                            value={
                              <Text
                                style={{ width: '90%' }}
                                ellipsis={{
                                  tooltip: scenariosNamesList[index],
                                }}
                              >
                                {scenariosNamesList[index]}
                              </Text>
                            }
                            showSearch
                            className='scenario_name'
                            style={{ width: '100%', height: 30 }}
                            onChange={(e) => handleScenarioChange(e, index)}
                          >
                            {scenariosNamesList &&
                              scenariosNamesList.map((o) => {
                                return (
                                  <Option value={o} key={o}>
                                    {o}
                                  </Option>
                                );
                              })}
                          </Select>
                        </div>
                        <div style={style}>
                          Description:
                          <Text
                            style={{ width: 260 }}
                            className='trial_des'
                            ellipsis={{ tooltip: item?.scenario_description }}
                          >
                            {item?.scenario_description}
                          </Text>
                        </div>
                        <a
                          className='finalise'
                          onClick={() => finaliseScenarioHandler(item, index)}
                        >
                          FINALIZE SCENARIO
                        </a>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            <div className='metric-container '>Metrics</div>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className='compare-scenario-row'
            >
              <Col className='gutter-row compare-scenario-col' span={6}>
                <div className='metrics-indicator'>PROTOCOL AMENDMENTS</div>
                <div className='metrics-indicator'>SCREEN FAILURE RATE</div>
                <div className='metrics-indicator'>COST PER PATIENT</div>
                <div className='metrics-indicator'>
                  TOTAL COST &nbsp;
                  <Tooltip title='The total cost is the cost per patient multiplied by the estimated enrollment'>
                    <InfoCircleOutlined className='material-info' />
                  </Tooltip>
                </div>
                <div className='metrics-indicator'>PATIENT BURDEN</div>
              </Col>
              {scenariosList &&
                scenariosList.length > 0 &&
                scenariosList.slice(0, 3).map((item, i) => {
                  return (
                    <Col
                      className='gutter-row compare-scenario-col'
                      key={i}
                      span={6}
                    >
                      <Row>
                        <div style={style} className='metrics'>
                          {item?.protocol_amendment_rate ? (
                            <>
                              {' '}
                              <span className='metrics1'>
                                {item?.protocol_amendment_rate}
                              </span>
                              {/* <span className={`status ${item["protocol_amendment_rate_state"]}`}>
                            {item["protocol_amendment_rate_state"]}
                          </span> */}
                            </>
                          ) : (
                            ''
                          )}
                          {/* Future Module */}
                        </div>
                      </Row>

                      <Row>
                        <div style={style} className='metrics'>
                          {item?.screen_failure_rate ? (
                            <>
                              <span className='metrics1'>
                                {item?.screen_failure_rate}
                              </span>
                              <span
                                className={`status ${item['screen_failure_rate_state']}`}
                              >
                                {' '}
                                {item['screen_failure_rate_state']}
                              </span>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </Row>

                      <Row>
                        <div style={style} className='metrics'>
                          {item?.cost != null || item?.cost != undefined ? (
                            <>
                              <span className='metrics1'>
                                {formatCostAvg(item?.cost, 1000)}
                              </span>
                              <span className={`status ${item['cost_state']}`}>
                                {item['cost_state']}
                              </span>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </Row>

                      <Row>
                        <div style={style} className='metrics'>
                          {item?.total_enrollment_cost != null ||
                          item?.total_enrollment_cost != undefined ? (
                            <>
                              <span className='metrics1'>
                                {formatCostAvg(
                                  item?.total_enrollment_cost,
                                  1000
                                )}
                              </span>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </Row>

                      <Row>
                        <div style={style} className='metrics'>
                          {item?.dct_patient_burden != null ||
                          item?.dct_patient_burden != undefined ? (
                            <>
                              {' '}
                              <span className='metrics1'>
                                {roundToWholeNumber(item?.dct_patient_burden)}
                              </span>
                              <span
                                className={`status ${item['dct_patient_burden_state']}`}
                              >
                                {' '}
                                {item['dct_patient_burden_state']}
                              </span>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </Row>
                    </Col>
                  );
                })}
            </Row>
            <div className='metric-container '>Key Attributes</div>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              className='compare-scenario-row'
            >
              <Col className='gutter-row compare-scenario-col' span={6}>
                <div className='metrics-indicator'>INCLUSION CRITERIA</div>
                <div className='metrics-indicator'>EXCLUSION CRITERIA</div>
              </Col>
              {scenariosList &&
                scenariosList.length > 0 &&
                scenariosList.slice(0, 3).map((item, i) => {
                  return (
                    <Col
                      className='gutter-row compare-scenario-col'
                      span={6}
                      key={i}
                    >
                      <Row>
                        <div
                          className='metrics-value'
                          dangerouslySetInnerHTML={{
                            __html: `This scenario consist of <b>${
                              item?.InclusionCount ?? 0
                            }</b> Inclusion Entities.`,
                          }}
                        />
                      </Row>
                      <Row>
                        <div
                          className='metrics-value'
                          dangerouslySetInnerHTML={{
                            __html: `This scenario consist of <b>${
                              item?.ExclusionCount ?? 0
                            }</b> Exclusion Entities.`,
                          }}
                        />
                      </Row>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </div>
      </div>
      <Modal
        className='complete-module-Modal'
        title={'Finalize Scenario'}
        placement='right'
        onCancel={() => handleCancel()}
        visible={finaliseModule.finalise}
        footer={[
          <Button
            key='update'
            type='primary'
            className='submit-complete-btn'
            onClick={handleCompleteModule}
            disabled={scenariosList.findIndex((s) => s.finalized) < 0}
          >
            SUBMIT
          </Button>,
        ]}
      >
        <div className='select-scenario-wrapper'>
          <h4>{finaliseModule.scenario.scenario_name}</h4>
          <div>Description: {finaliseModule.scenario.scenario_description}</div>
          <br />
          <br />
          {finaliseModule.scenario.finalized ? (
            <div className='rationale-mark'>
              <span>Provide Rationale</span>
              <div>
                <TextArea
                  value={finaliseModule.scenario.rationale}
                  onChange={(e) => onRationaleChange(e, finaliseModule?.id)}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  allowClear
                />
              </div>
              {finaliseModule.scenario.rationale &&
              finaliseModule.scenario.rationale.length > 500 ? (
                <span style={{ color: '#d04a02' }}>{rationaleMsgError}</span>
              ) : (
                ''
              )}
              <span></span>
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default withRouter(CompareScenario);
