/* eslint-disable */
import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router';
import { Input, Dropdown, Menu, Tooltip, Row, Table, Col, Slider, Button, List, Typography, Modal, Select } from "antd";
import { SearchOutlined, CheckOutlined, PlusOutlined, MinusOutlined, CloseOutlined, ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import "./index.scss";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";
import { use } from "echarts";



const AddActivitySoa = (props) => {

const LABS = 'Labs';
const PHYSICAL_EXAMINATION = 'Physical Examination';
const PROCEDURES = 'Procedures';
const QUESTIONNAIRES = 'Questionnaires';
const STUDY_PROCEDURES = 'Study Procedures';
const initialActivity = {
  Categories:'',
  'Standard Activity': '',
  activity_id: '',
  condition: '',
  cost: '',
  historical_event : "",
  endpoint: null,
  idx: '',
  modality: [],
  selected: false,
  soaWeights: [],
  totalVisit: '', 
  visit_frequency: null, 
  Frequency: null
}
    const {
        orgLabs,
        orgExamination,
        orgProcedures,
        orgQuestionnaires,
        orgStudyProcedures, 
        addedLabs,
        addedExamination,
        addedProcedures,
        addedQuestionnaires, 
        addedStudyProcedures,
        filteredLabs,
        filteredExamination,
        filteredQuestionnaires,
        filteredProcedures,
        filteredStudyProcedures,
        trialEndpoints, 
        similarHistoricalTrials,
        trial_id,
        scenarioId,
        scenarioType,
        modalityOptions,
        evtNumbers,
        weeks,
        evtnotesList
    } = props.location.state;
    const [visibleValue, setVisibleValue] = useState(false);
    const [visibleFilterValue, setVisibleFilterValue] = useState(false);
    const [searchTxt,setSearchTxt] = useState("");
    const [searchLabs, setSearchLabs] = useState([])
    const [searchExamination, setSearchExamination] = useState([])
    const [searchQuestionnaires, setSearchQuestionnaires] = useState([])
    const [searchProcedures, setSearchProcedures] = useState([])
    const [searchStudyProcedures, setStudyProcedures] = useState([])
    const [activeCollapse, setActiveCollapse] = useState(['-1'])

    const [addedActivities, setAddedActivities] = useState([]);
    const [soaActivityAddedLabs, setSoaActivityAddedLabs] = useState([]);
    const [soaActivityAddedExamination, setSoaActivityAddedExamination] = useState([])
    const [soaActivityAddedProcedures, setSoaActivityAddedProcedures] = useState([])
    const [soaActivityAddedStudyProcedures, setSoaActivityAddedStudyProcedures] = useState([])
    const [soaActivityAddedQuestionnaires, setSoaActivityAddedQuestionnaires] = useState([])

    const [filteredActivities, setFilteredActivities] = useState([]);
    const [soaActivityFilteredLabs, setSoaActivityFilteredLabs] = useState([])
    const [soaActivityFilteredExamination, setSoaActivityFilteredExamination] = useState([])
    const [soaActivityFilteredQuestionnaires, setSoaActivityFilteredQuestionnaires] = useState([])
    const [soaActivityFilteredProcedures, setSoaActivityFilteredProcedures] = useState([])
    const [soaActivityFilteredStudyProcedures, setSoaActivityFilteredStudyProcedures] = useState([])

    const [labCount, setLabCount] = useState(0);
    const [studyCount, setStudyCount] = useState(0);
    const [procedureCount, setProcedureCount] = useState(0);
    const [questionnaireCount, setQuestionnaireCount] = useState(0);
    const [examinationCount, setExaminationCount] = useState(0);

    const [categories, setCategories] = useState([ "Labs", "Study Procedures", "Questionnaires", "Procedures", 'Physical Examination']);
    const categoryList = [ "Labs", "Study Procedures", "Questionnaires", "Procedures", 'Physical Examination' ]

   const [filterbyCategories, setFilterByCategories] = useState([ "Labs", "Study Procedures", "Questionnaires", "Procedures", 'Physical Examination'])

    const FREQUENCY_RANGE_MIN = 0
    const FREQUENCY_RANGE_MAX = 100

    // Sets default min and max frequency for Criteria Frequency slider
    const FREQUENCY_DEFAULT_MIN = 20
    const FREQUENCY_DEFAULT_MAX = 100

    const [minV, setMinV] = useState(FREQUENCY_DEFAULT_MIN)
    const [maxV, setMaxV] = useState(FREQUENCY_DEFAULT_MAX)

    const [filterByMax, setFilterByMax] = useState(FREQUENCY_DEFAULT_MAX)
    const [filterByMin, setFilterByMin] = useState(FREQUENCY_DEFAULT_MIN)

    let timer = null;

    useEffect(() => {
      let rFilterLab= props?.newSoa?.soaFilteredLabs 
      setSoaActivityFilteredLabs( rFilterLab.length > 0 ? [...rFilterLab] : [...filteredLabs]) 

       setLabCount( filteredLabs.filter((d)=>{
        return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
       }).length)
    }, [ filteredLabs ])

    useEffect(() => {
      let rFilterExamination = [...props?.newSoa?.soaFilteredExamination]
      setSoaActivityFilteredExamination(rFilterExamination.length > 0 ? [...rFilterExamination] : [...filteredExamination ]) 

      setExaminationCount( filteredExamination.filter((d)=>{
        return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
       }).length)
    }, [ filteredExamination ])

    useEffect(() => {
      let rFilterQuestionnaire = [...props?.newSoa?.soaFilteredQuestionnaires]
      setSoaActivityFilteredQuestionnaires(rFilterQuestionnaire.length > 0 ? [...rFilterQuestionnaire] : [...rFilterQuestionnaire ])  

      setQuestionnaireCount( filteredQuestionnaires.filter((d)=>{
        return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
       }).length)
    }, [ filteredQuestionnaires ])

    useEffect(() => {
      let rFilterProcedure = [...props?.newSoa?.soaFilteredProcedures]
      setSoaActivityFilteredProcedures(rFilterProcedure.length > 0 ? [...rFilterProcedure] : [...filteredProcedures ])  

      setProcedureCount( filteredProcedures.filter((d)=>{
        return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
       }).length)
    }, [ filteredProcedures])

    useEffect(() => {
      let rFilterStudy = [...props?.newSoa?.soaFilteredStudyProcedures]
      setSoaActivityFilteredStudyProcedures(rFilterStudy.length > 0 ? [...rFilterStudy] : [...filteredStudyProcedures ])  

      setStudyCount( filteredStudyProcedures.filter((d)=>{
        return d.historical_event == 'YES' && d.Frequency * 100 >= minV && d.Frequency * 100 <= maxV
       }).length)
    }, [ filteredStudyProcedures ])

    useEffect(() => {
      setSoaActivityAddedLabs( [...addedLabs])
    }, [ addedLabs])

    useEffect(() => {
      setSoaActivityAddedExamination([...addedExamination ])
    }, [ addedExamination ])

    useEffect(() => {
      setSoaActivityAddedProcedures([...addedProcedures ])
    }, [ addedProcedures ])

    useEffect(() => {
      setSoaActivityAddedStudyProcedures([...addedStudyProcedures])
    }, [ addedStudyProcedures])

    useEffect(() => {
      setSoaActivityAddedQuestionnaires( [ ...addedQuestionnaires ])
    }, [ addedQuestionnaires ])

  const [addActivityModal, setAddActivityModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(initialActivity)


    useEffect(() => {
      setFilteredActivities( 
        [...soaActivityFilteredLabs,
        ...soaActivityFilteredExamination,
        ...soaActivityFilteredQuestionnaires,
        ...soaActivityFilteredProcedures, 
        ...soaActivityFilteredStudyProcedures])
    }, [soaActivityFilteredLabs,
      soaActivityFilteredExamination,
      soaActivityFilteredQuestionnaires,
      soaActivityFilteredProcedures,
      soaActivityFilteredStudyProcedures])

    useEffect(() => {
      setAddedActivities( 
        [ ...soaActivityAddedLabs,
          ...soaActivityAddedExamination,
          ...soaActivityAddedProcedures,
          ...soaActivityAddedStudyProcedures, 
          ...soaActivityAddedQuestionnaires])
    }, [ 
        soaActivityAddedLabs,
        soaActivityAddedExamination,
        soaActivityAddedProcedures,
        soaActivityAddedStudyProcedures, 
        soaActivityAddedQuestionnaires
      ]);


    const getFrequency = (value) => {
        setMinV(value[0])
        setMaxV(value[1])
      }
    
      const formatter = (value) => {
        return value+'%'
      } 

    const optionLabs = orgLabs?.map((item, index)=>{
        return item
    });
    const optionExamination = orgExamination?.map((item, index)=>{
        return item
    });
    const optionProcedures = orgProcedures?.map((item, index)=>{
        return item
    });
    const optionQuestionnaires = orgQuestionnaires?.map((item, index)=>{
        return item
    });
    const optionStudyProcedures = orgStudyProcedures?.map((item, index)=>{
        return item
    });

    const onTextChange = (e) => {
        const val = e.target.value.toLowerCase();
        let searchLabsTemp = []
        let searchExamTemp = []
        let searchProTemp=[]
        let searchQuesTemp=[]
        let searchStudyProTemp=[]
        !visibleValue&&setVisibleValue(true);
        timer = setTimeout(function () {
            setSearchTxt(val)
            searchLabsTemp = val.length>0? optionLabs.filter(
              (i) =>
                i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
              ) : []
            searchExamTemp = val.length>0? optionExamination.filter(
              (i) =>
                i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
              ): []
            searchProTemp = val.length>0?optionProcedures.filter(
              (i) =>
                i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
              ): []
            searchQuesTemp = val.length>0?optionQuestionnaires.filter(
              (i) =>
                i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
              ): []
            searchStudyProTemp = val.length>0?optionStudyProcedures.filter(
              (i) =>
                i["Standard Activity"].toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= FREQUENCY_RANGE_MIN && i.Frequency * 100 <= FREQUENCY_RANGE_MAX
              ): []
            setSearchLabs(searchLabsTemp)
            setSearchExamination(searchExamTemp)
            setSearchProcedures(searchProTemp)
            setSearchQuestionnaires(searchQuesTemp)
            setStudyProcedures(searchStudyProTemp)
          }, 200);
    }

  const renderItem = (title: string, type: string, idx: any, Frequency: any) => {  
    return (
      <Tooltip
      title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}
      >
        <div   className="itemLine"   >
          <span className="itemTitle" style={{ width: '80%',display: 'inline-block',overflowWrap:'break-word', textOverflow: 'ellipsis'}}>
            {(searchTxt.length < title.length) && compareString(title, title.toLowerCase().split(searchTxt)[0])} 
            <span className={`${
              searchTxt &&
              title.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
                ? "matched-item"
                : ""
              }`}> 
                {compareMiddle(title, searchTxt.length - title.length === 0?title:searchTxt)}
            </span>
            { (searchTxt.length !== 0)&&(searchTxt.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length))}
          </span>
          <span style={{width: '20%',display: 'inline-block',color:"#CA4A04", marginLeft:"25px"}}>
            Add
          </span>
        </div>
        </Tooltip>
        )
  }
      
   const compareString = (original, sub_str) => {
    let index = original.toLocaleLowerCase().indexOf(sub_str)
    return original.substring(index, sub_str.length)
  }
  
  const compareMiddle = (original, sub_str) => {
    let index = original.toLocaleLowerCase().indexOf(sub_str);
    let secondIndex = original.toLocaleLowerCase().length == sub_str.toLocaleLowerCase().length ? original.toLocaleLowerCase().length : index+sub_str.length;
    return original.substring(index, secondIndex)
  }
  
  const addActivity = (e, record, idx) => {  
    setAddActivityModal(true)
    setSelectedActivity({...record, idx: idx})
  }

  const handleCancelActivity = () => {
    setAddActivityModal(false)
    setSelectedActivity(initialActivity)
    
  } 

  const [panelTotal, setPanelTotal] = useState([])
  const [panelLabsData, setLabsPanelData] = useState([])
  const [panelExaminationData, setExaminationPanelData] = useState([])
  const [panelProceduresData, setProceduresPanelData] = useState([])
  const [panelQuestionnaireData, setQuestionnairePanelData] = useState([])
  const [panelStudyProcedureData, setStudyProcedureData] = useState([])

  useEffect(() => {
    setPanelTotal([...panelLabsData, ...panelExaminationData, ...panelProceduresData, ...panelQuestionnaireData, ...panelStudyProcedureData])
  }, [panelLabsData, panelExaminationData, panelProceduresData, panelQuestionnaireData, panelStudyProcedureData])

  const handleToggleActivity = (e, evt) => {  
   const Categories = evt.Categories
      let tmpCategories, idx; 
      switch(Categories){
        case "Labs":
          let index = soaActivityFilteredLabs.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
          let addedIndex = panelLabsData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])  
          const newData = [...soaActivityFilteredLabs]
          const newSelectedData = [...soaActivityAddedLabs]
          const newPanelData = [...panelLabsData] 
          if(evt.selected){ 
            for (let key in evt) {
              if (key == "endpoint") {
                 delete evt[key];
              }
           }  
            newData.splice(index, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0}}); 
            let selectedIndex = soaActivityAddedLabs.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
            newSelectedData.splice(selectedIndex, 1)

            newPanelData.splice(addedIndex, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0}}); 
            let panelSelectedIndex =  panelLabsData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
            newPanelData.splice(panelSelectedIndex, 1)
          } else {
            newData.splice(index, 1, { ...evt, ...{selected: true}});
            newSelectedData.push(Object.assign(evt, {selected: true}))

            newPanelData.push(Object.assign(evt, {selected: true}))
          }
          setLabsPanelData(newPanelData)
          setSoaActivityAddedLabs(newSelectedData)
          setSoaActivityFilteredLabs(newData) 
          break;

        case "Physical Examination": 
          let index2 = soaActivityFilteredExamination.findIndex((d) => evt['Standard Activity'] == d['Standard Activity']);
          let addedIndex2 = panelExaminationData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])  
          const newData2 = [...soaActivityFilteredExamination];
          const newSelectedData2 = [...soaActivityAddedExamination];
          const newPanelData2 = [...panelExaminationData]
          
          if(evt.selected) {
            for (let key in evt) {
              if (key == "endpoint") {
                  delete evt[key];
              }
            }
            newData2.splice(index2, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0}});
            let selectedIndex = soaActivityAddedExamination.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
            newSelectedData2.splice(selectedIndex, 1)

            newPanelData2.splice(addedIndex2, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0}}); 
            let panelSelectedIndex =  panelExaminationData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
            newPanelData2.splice(panelSelectedIndex, 1)
          } else {
            newData2.splice(index2, 1, { ...evt, ...{selected: true}});
            newSelectedData2.push(Object.assign(evt, {selected: true}))
            newPanelData2.push(Object.assign(evt, {selected: true}))
          }
          setExaminationPanelData(newPanelData2)
          setSoaActivityFilteredExamination(newData2)
          setSoaActivityAddedExamination(newSelectedData2)
          break;

        case "Procedures":
        let index3 = soaActivityFilteredProcedures.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
        let addedIndex3 = panelProceduresData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])  
        const newData3 = [...soaActivityFilteredProcedures]
        const newSelectedData3 = [...soaActivityAddedProcedures]
        const newPanelData3 = [...panelProceduresData]
        if(evt.selected){
          for (let key in evt) {
            if (key == "endpoint") {
                delete evt[key];
            }
          }
          newData3.splice(index3, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0 }});
          let selectedIndex = soaActivityAddedProcedures.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
          newSelectedData3.splice(selectedIndex, 1)

          newPanelData3.splice(addedIndex3, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0}}); 
          let panelSelectedIndex =  panelProceduresData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
          newPanelData3.splice(panelSelectedIndex, 1)
        } else {
          newData3.splice(index3, 1, { ...evt, ...{selected: true}});
          newSelectedData3.push(Object.assign(evt, {selected: true}))
          newPanelData3.push(Object.assign(evt, {selected: true}))
        }
        setProceduresPanelData(newPanelData3)
        setSoaActivityFilteredProcedures(newData3)
        setSoaActivityAddedProcedures(newSelectedData3)
        break;

        case "Questionnaires":
          let index4 = soaActivityFilteredQuestionnaires.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
          let addedIndex4 = panelQuestionnaireData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])  
          const newData4 = [...soaActivityFilteredQuestionnaires]
          const newSelectedData4 = [...soaActivityAddedQuestionnaires]
          const newPanelData4 = [...panelQuestionnaireData]
          if(evt.selected){
            for (let key in evt) {
              if (key == "endpoint") {
                 delete evt[key];
              }
           }
            newData4.splice(index4, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0}});
            let selectedIndex = soaActivityAddedQuestionnaires.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
            newSelectedData4.splice(selectedIndex, 1)

            newPanelData4.splice(addedIndex4, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0}}); 
            let panelSelectedIndex =  panelQuestionnaireData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
            newPanelData4.splice(panelSelectedIndex, 1)
          } else {
            newData4.splice(index4, 1, { ...evt, ...{selected: true}});
            newSelectedData4.push(Object.assign(evt, {selected: true}))
            newPanelData4.push(Object.assign(evt, {selected: true}))
          }
          setQuestionnairePanelData(newPanelData4)
          setSoaActivityFilteredQuestionnaires(newData4)
          setSoaActivityAddedQuestionnaires(newSelectedData4)
          break;

        case "Study Procedures":
          // const [panelStudyProcedureData, setStudyProcedureData] = useState([])
          let index5 = soaActivityFilteredStudyProcedures.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
          let addedIndex5 = panelStudyProcedureData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])  
          const newData5 = [...soaActivityFilteredStudyProcedures]
          const newSelectedData5 = [...soaActivityAddedStudyProcedures]
          const newPanelData5 = [...panelStudyProcedureData]

          if(evt.selected){
            for (let key in evt) {
              if (key == "endpoint") {
                  delete evt[key];
              }
            }
            newData5.splice(index5, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0}});
            let selectedIndex = soaActivityAddedStudyProcedures.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
            newSelectedData5.splice(selectedIndex, 1)

            newPanelData5.splice(addedIndex5, 1, { ...evt, ...{selected: false, condition: [], totalVisit: 0}}); 
            let panelSelectedIndex =  panelStudyProcedureData.findIndex((d) => evt['Standard Activity'] == d['Standard Activity'])
            newPanelData5.splice(panelSelectedIndex, 1)
          } else {
            newData5.splice(index5, 1, { ...evt, ...{selected: true}});
            newSelectedData5.push(Object.assign(evt, {selected: true}))
            newPanelData5.push(Object.assign(evt, {selected: true}))
          }
          setStudyProcedureData(newPanelData5)
          setSoaActivityFilteredStudyProcedures(newData5)
          setSoaActivityAddedStudyProcedures(newSelectedData5)
          break; 
      }  
      setAddActivityModal(false);
      setSelectedActivity(initialActivity);
     // props.handleEventChange()
  }


    const columns = [
        {
        title: "ACTIVITIES",
        dataIndex: "Standard Activity",
        ellipsis: true, 
        sorter: (a, b) => (a['Standard Activity'] < b['Standard Activity'] ? -1 : 1), 
        },
        {
        title: "CATEGORY",
        dataIndex: "Categories",
        sorter: (a, b) => (a.Categories < b.Categories ? -1 : 1),
        ellipsis: true
        },
        {
        title: "ACTIVITY FREQUENCY",
        dataIndex: "Frequency",
        sorter: (a, b) => (a.Frequency < b.Frequency ? -1 : 1),
        ellipsis: true,
        render: (_, item) =>  Math.round(item['Frequency'] * 10000) / 100 + "%"
        
        },
        {
        title: "VISIT FREQUENCY",
        dataIndex: "visit_frequency",
        sorter: (a, b) => (a.visit_frequency < b.visit_frequency ? -1 : 1),
        ellipsis: true,
        render: (_, item) =>  Math.round(item['visit_frequency'] * 100) / 100 + "%"
        },
        {
        title: "COST PER PATIENT",
        dataIndex: "cost",
        sorter: (a, b) => (a.cost < b.cost ? -1 : 1),
        ellipsis: true,
        render: (_, item) =>  formatCostAvg(item.cost)
        },
        {
          title: '',
          dataIndex: 'operation',
          render: (_, record, index) => { 
            return record.selected ? (<div style={{float: 'right', marginRight: 30}}><span style={{paddingRight: '10px', fontSize: '16px'}}> <CheckOutlined /></span>ADDED</div>) :
            (<div className="soa-lib" style={{float: 'right', marginRight: 30}} >
            <Button  onClick={(e) => addActivity(e, record, index)} className="add-button">ADD</Button>
            </div>)
        }
      }
    ];

    function formatCostAvg(totalCost){
      if(totalCost === undefined || totalCost === null || totalCost === ''){
        return ""
      }else if(totalCost === 0){
        return 0
      } else { 
        return '$' + totalCost
      }
    }

    const renderItemClick = (title: string, type: string, idx: any, Frequency: any) => {
        return (
            <div  className="itemLine"  >
            <Tooltip title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}>
              <span className="itemTitle" style={{ width: '80%',display: 'inline-block',overflowWrap:'break-word', textOverflow: 'ellipsis'}}>
                {(searchTxt.length < title.length) && compareString(title, title.toLowerCase().split(searchTxt)[0])} 
                <span className={`${
                  searchTxt &&
                  title.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
                    ? "matched-item"
                    : ""
                  }`}> 
                     {compareMiddle(title, searchTxt.length - title.length === 0?title:searchTxt)}
                </span> 
                { (searchTxt.length !== 0)&&(searchTxt.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length))}
              </span>
              <span style={{width: '20%',display: 'inline-block',color:"#3193E5", fontWeight:700, marginLeft:"25px"}}>
                  <CheckOutlined />
              </span>
              </Tooltip>
            </div>
          )
    }; 

    const onItemClick = ({ key }) => {
      const item = JSON.parse(key);
      if(!item.selected) {
        setAddActivityModal(true); 
        setVisibleValue(false);
        setSelectedActivity(item) 
      }
     
     // handleEventChange([])
    }

     
    const menu = (
        <Menu onClick={onItemClick}>
          {(searchTxt.length !== 0)&&searchLabs.length>0 && (<Menu.ItemGroup title="Labs">
           {
            
              searchLabs.slice(0, 10).map((item,idx)=>{
                if(soaActivityAddedLabs.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
                  return <Menu.Item key={JSON.stringify({...item, selected: false})}>
                    {renderItem(item['Standard Activity'],"D", idx, item.Frequency)}
                  </Menu.Item>
                } else {
                  return <Menu.Item key={JSON.stringify({...item, selected: true})}>
                    {renderItemClick(item['Standard Activity'],"D", idx, item.Frequency)}
                  </Menu.Item>
                }
              })
            }
          </Menu.ItemGroup>)}
          {(searchTxt.length !== 0)&&searchExamination.length>0 && 10-searchLabs.length>0?(<Menu.ItemGroup title="Physical Examination">
            {
              searchExamination.slice(0,10-searchLabs.length).map((item,idx)=>{
                if(soaActivityAddedExamination.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
                  return <Menu.Item key={JSON.stringify({...item, selected: false})}>
                    {renderItem(item['Standard Activity'], "M",idx, item.Frequency)}
                  </Menu.Item>
                }else {
                  return <Menu.Item key={JSON.stringify({...item, selected: true})}>
                    {renderItemClick(item['Standard Activity'], "M",idx, item.Frequency)}
                  </Menu.Item>
                }
              })
            }
          </Menu.ItemGroup>):(<></>)}
          {(searchTxt.length !== 0)&&searchProcedures.length>0 &&10-searchLabs.length-searchExamination.length>0?(<Menu.ItemGroup title="Procedures">
             {
              searchProcedures.slice(0,10-searchLabs.length-searchExamination.length).map((item,idx)=>{
                if(soaActivityAddedProcedures.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
                  return <Menu.Item key={JSON.stringify({...item, selected: false})}>
                    {renderItem(item['Standard Activity'], "I",idx, item.Frequency)}
                  </Menu.Item>
                }else{
                  return <Menu.Item key={JSON.stringify({...item, selected: true})}>
                    {renderItemClick(item['Standard Activity'], "I",idx, item.Frequency)}
                  </Menu.Item>
                }
              })
            }
          </Menu.ItemGroup>):(<></>)}
          {(searchTxt.length !== 0)&&searchQuestionnaires.length>0 && 10-searchLabs.length-searchExamination.length - searchProcedures.length>0?<Menu.ItemGroup title="Questionnaires">
             {
              searchQuestionnaires.slice(0,10-searchLabs.length-searchExamination.length- searchProcedures.length).map((item,idx)=>{
                if(soaActivityAddedQuestionnaires.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
                  return <Menu.Item key={JSON.stringify({...item, selected: false})}>
                    {renderItem(item['Standard Activity'], "L",idx, item.Frequency)}
                  </Menu.Item>
                }else{
                  return <Menu.Item key={JSON.stringify({...item, selected: true})}>
                    {renderItemClick(item['Standard Activity'], "L",idx, item.Frequency)}
                  </Menu.Item>
                }
              })
            }
          </Menu.ItemGroup>:(<></>)}
          {(searchTxt.length !== 0)&&searchStudyProcedures.length>0 && 10-searchLabs.length-searchExamination.length - searchProcedures.length - searchQuestionnaires.length>0?<Menu.ItemGroup title="Study Procedures">
             {
              searchStudyProcedures.slice(0,10-searchLabs.length-searchExamination.length - searchProcedures.length - searchQuestionnaires.length).map((item,idx)=>{
                if(soaActivityAddedStudyProcedures.findIndex((domain) => item['Standard Activity'] == domain['Standard Activity'])<0){
                  return <Menu.Item key={JSON.stringify({...item, selected: false})}>
                    {renderItem(item['Standard Activity'], "S",idx, item.Frequency)}
                  </Menu.Item>
                }else{
                  return <Menu.Item key={JSON.stringify({...item, selected: true})}>
                    {renderItemClick(item['Standard Activity'], "S",idx, item.Frequency)}
                  </Menu.Item>
                }
              })
            }
          </Menu.ItemGroup>:(<></>)}
          {searchTxt.length === 0 && <div style={{cursor: "none", padding: 20}}></div>}
          {(searchTxt.length !== 0 && searchLabs.length + searchExamination.length + searchQuestionnaires.length + searchProcedures.length + searchStudyProcedures.length === 0) &&
           <div key="no-result" style={{cursor: "none", padding: 20}}>There are no activities in the library that match your search.</div>}
        </Menu>
    );


    const onCategorySelect = ({ key }) => {
      let item = JSON.parse(key.slice(1));
      const newData = [...categories]
      let index = newData.findIndex((d) => d == item);
      if(index < 0) {
        newData.push(item)
      } else {
        newData.splice(index, 1)
      }
      setCategories(newData)
    }

    const cancelFilterDisplay = () => {
      setVisibleFilterValue(false)
    }

    const applyFilters = () => {
      setFilterByCategories(categories)
      setVisibleFilterValue(false) 
      setFilterByMin(minV)
      setFilterByMax(maxV)
    }

    const filterMenu=(
      <div className="category-filters">
        <div className="content">
          <span>Activity Frequency</span>
          <span style={{ float: "right", fontWeight: 'bold' }}>
            {minV}% - {maxV}%
          </span>
        </div>
        <Slider
          min={FREQUENCY_RANGE_MIN}
          max={FREQUENCY_RANGE_MAX}
          range={{ draggableTrack: true }}
          defaultValue={[minV, maxV]}
          tipFormatter={formatter}
          onAfterChange={getFrequency}
        />
         <Menu onClick={onCategorySelect}>
          {categoryList.map((list_item) => (  <Menu.Item  key={"D"+JSON.stringify(list_item)}>  
          <>
          {categories.includes(list_item) ? (
             <span style={{width: '20%',display: 'inline-block',color:"#3193E5", fontWeight:700, marginLeft:"25px"}}>
             <CheckOutlined />
             </span>
          ) : (
            <span style={{width: '20%',display: 'inline-block',color:"#CA4A04", marginLeft:"25px"}}>
            X
          </span>
          )} 
      </>
      {list_item} </Menu.Item>))} 
         </Menu>
         <div>
          <Button className="cancel-filters" onClick={cancelFilterDisplay}>CANCEL</Button>
          <Button className="apply-filters" onClick={applyFilters}>APPLY</Button>
         </div>
      </div>
    )

    const onEndpointChange = (value, evt) => {  
      setSelectedActivity({...evt, endpoint: value})
      // props.handleEventChange()
    }

    const addActivityToSoa = () => {

      props.updateSoaActivities({
        soaAddedLabs: soaActivityAddedLabs,
        soaAddedExamination: soaActivityAddedExamination,
        soaAddedProcedures: soaActivityAddedProcedures,
        soaAddedQuestionnaires: soaActivityAddedQuestionnaires,
        soaAddedStudyProcedures: soaActivityAddedStudyProcedures,
        
        soaFilteredLabs: soaActivityFilteredLabs,
        soaFilteredExamination: soaActivityFilteredExamination,
        soaFilteredQuestionnaires: soaActivityFilteredQuestionnaires,
        soaFilteredProcedures: soaActivityFilteredProcedures,
        soaFilteredStudyProcedures: soaActivityFilteredStudyProcedures,
      });
      
      props.history.push({
        pathname: '/scenario',
        state: {
          trial_id: trial_id,
          scenarioId: scenarioId, 
          scenarioType: scenarioType,
          similarHistoricalTrials: similarHistoricalTrials,
          modalityOptions: modalityOptions,
          evtNumbers: evtNumbers,
          activityweeks: weeks,
          activity_notesList: evtnotesList
        }  
      }) 
    }

    const navigateBack = () => {
    addActivityToSoa()
      props.history.push({
        pathname: '/scenario',
        state: {
          trial_id: trial_id,
          scenarioId: scenarioId, 
          scenarioType: scenarioType,
          // similarHistoricalTrials: similarHistoricalTrials,
          // modalityOptions: modalityOptions,
          // evtNumbers: evtNumbers,
          // activityweeks: weeks,
          // activity_notesList: evtnotesList
        }  
      }) 
    }

    const [tableListItems, setTableListItems] = useState([])
    const [current, setCurrent] = useState(1) 

    useEffect(() => {
      setTableListItems(filteredActivities.filter((d)=>{
        return d.historical_event == 'YES' && d.Frequency * 100 >= filterByMin && d.Frequency * 100 <= filterByMax && filterbyCategories.includes(d.Categories)
      })) 
    }, [filteredActivities, filterbyCategories, filterByMax, filterByMin])

    const changePage = (page) => {
      setCurrent(page) 
    };
    
    return (
        <div className="activity-soa-container">
          <div className="activity-soa-header_layout"> 
            <div onClick={navigateBack} style={{cursor: 'pointer'}}><span style={{paddingRight: '10px'}}><ArrowLeftOutlined /></span>BACK</div>
            <h3>Activities Library 
              {/* ({labCount + studyCount + procedureCount + questionnaireCount + examinationCount}) */}
              </h3>
            {/* <p className="sub-heading">You can add activities from the library</p> */}
        </div>

        <div className="activity-soa-body_layout" > 
        <Row>
          <Col span={18} style={{padding: '2% 5% 5% 5%'}}>
            <Row> 
              <Col span={18}>
                <Dropdown
                  overlay={menu}
                  overlayClassName="searchbox"
                  className="search-soa"
                  visible={visibleValue} 
                  onVisibleChange={(visible: boolean) => {!visibleValue?setVisibleValue(true):setVisibleValue(false)}}
                >
                <Input
                    prefix={<SearchOutlined />}
                    style={{ width: '100%', height: 37 }}
                    allowClear
                    onChange={onTextChange}
                    onClick={e => e.preventDefault()} 
                    placeholder="Search by keyword" />
                   
                  </Dropdown>
              </Col>
              <Col span={6}>
              <Dropdown
                  overlay={filterMenu} 
                  className="search-soa-filters"
                  visible={visibleFilterValue} 
                  onVisibleChange={(visible: boolean) => {!visibleFilterValue?setVisibleFilterValue(true):setVisibleFilterValue(false)}}
                >
                <p>Category <DownOutlined  style={{paddingLeft: '3px'}}/></p>
                </Dropdown>
              </Col>
            </Row>
            <br />
            <Row>
              <Col span={24}>
              <Table
                  columns={columns}
                  dataSource= {tableListItems} 
                  pagination={{
                    position: ["bottomRight"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${tableListItems.length} items`,
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    pageSizeOptions:["5", "10", "20", "50", "100"],
                    onChange: changePage,
                    current: current,
                    total: tableListItems.length
                  }} 
                />
              </Col>
           
            </Row>
          </Col>
          <Col span={6} className="activities-container">
          <div className="side-panel-heading">Selected Activities
          ({panelTotal?.length})
          </div>

          <List 
            className="lists-container"
            bordered
            dataSource={panelTotal.filter((d) => d.selected)}
            renderItem={(item) => (
              <List.Item>
                <Typography.Text ><span className="category">{`${item['Standard Activity']}`}</span><span className="sub-category">{`in ${item['Categories']}`}</span> </Typography.Text> 
                <span style={{float: 'right'}} onClick={(e) => handleToggleActivity(e, item)}>
                  <CloseOutlined />
                </span> 
              </List.Item>
            )}
          />
          <div className="soa-actiity-btn-container">
            <Button onClick={addActivityToSoa} className="add-soa">ADD TO SOA</Button>
            <br />
          </div>
          </Col>
        </Row>
          
        </div>

        <Modal 
        visible={addActivityModal} className="endpoint-soa-modal" title="" closable={false} mask={false} onCancel={handleCancelActivity} footer={null} style={{ left: '12%', top:10 }} centered={false} maskClosable={false}  wrapClassName="activityDetailModal">
        <Row className="soa-configure-title">
         <span>Select Endpoint</span>
        </Row>
        <br/>
        <Row className="endpoint-modal-filed">
          <Col span={24} className="informed-consent-label"><span>{selectedActivity['Standard Activity']}</span></Col>
          <Col span={24} className="procedure-label"><span>{selectedActivity['Categories']}</span></Col>
          <Col span={24}><span><br /></span></Col>
          
          <Col span={8} className="label-header"><span>ACTIVITY FREQUENCY</span></Col>
          <Col span={8} className="label-header"><span>VISIT FREQUENCY</span></Col>
          <Col span={8} className="label-header"><span>COST PER PATIENT</span></Col>  
          <Col span={8} className="label-values"> { Math.round(selectedActivity.Frequency * 10000) / 100 + "%"}</Col>
          <Col span={8} className="label-values"><span>{ Math.round(selectedActivity['visit_frequency'] * 100) / 100 + "%"}</span></Col>
          <Col span={8} className="label-values"><span>{selectedActivity && selectedActivity?.cost !== "" && selectedActivity?.cost !== null ? `$ ${selectedActivity.cost}` : ''}</span></Col> 

          <Col span={24} ><span><br /></span></Col>
           
        </Row>
        <br />
        <Row className="soa-endpoint-selector">
          <Col span={24}>
          <div>Select Trial Endpoint</div>
          <Select
              placeholder='Search to Select'
              style={{ width: '100%', height: 37, }} dropdownMatchSelectWidth
              showSearch 
              value={selectedActivity.endpoint} 
              onChange={(value)=> onEndpointChange(value,selectedActivity)}
              virtual={false}
              options={[
                {
                  label: "No endpoint", 
                  value: "No endpoint", 
                  key:`${selectedActivity.Categories} ${selectedActivity['Standard Activity']} na`,  
                }, {
                  label: 'Primary Endpoint', 
                  options: trialEndpoints[Object.keys(trialEndpoints)[0]]?.map((k, i) => {
                    return {
                      label: k,
                      value: k,
                      key:`${selectedActivity.Categories} ${selectedActivity['Standard Activity']}  ${selectedActivity.idx}  -  ${i} - primary`
                    }
                  })
                },
                {
                  label: 'Secondary Endpoint', 
                  options: trialEndpoints[Object.keys(trialEndpoints)[1]]?.map((k, i) => {
                    return {
                      label: k,
                      value: k,
                      key:`${selectedActivity.Categories} ${selectedActivity['Standard Activity']}  ${selectedActivity.idx}  -  ${i} - secondary`
                    }
                  })
                },
                {
                  label: 'Tertiary Endpoint', 
                  options: trialEndpoints[Object.keys(trialEndpoints)[2]]?.map((k, i) => {
                    return {
                      label: k,
                      value: k,
                      key:`${selectedActivity.Categories} ${selectedActivity['Standard Activity']}  ${selectedActivity.idx}  -  ${i} - tertiary`
                    }
                  })
                },
              ]}
            />
          </Col>
        </Row>
        
        <Row style={{justifyContent: 'right', paddingTop: '20px'}}>
          <Button className="cancel-btn" onClick={handleCancelActivity}>CANCEL</Button>
          <Button className="create-btn" onClick={(e) => handleToggleActivity(e, selectedActivity)}>ADD</Button>
        </Row>
      </Modal>
           
          
        </div>    
    )
}

// export default withRouter(AddActivitySoa)
const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
  createSoa: (val) => dispatch(createActions.createSoa(val)),
  updateSoaActivities: (val) => dispatch(createActions.updateSoaActivities(val))
});
const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
  newSoa: state.soaReducer
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddActivitySoa));