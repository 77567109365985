/* eslint-disable */
import {CLINICAL_TRIALS_GOV_URL} from './../../../constants'
import React, { useState } from "react";
import { Table, Row, Col, Select, Button, Spin, Slider, Input, Switch } from "antd";
import { listStudy } from "../../../utils/ajax-proxy";
import { connect } from "react-redux";
import ReactECharts from "echarts-for-react";
import { SearchOutlined, FilterOutlined, CloseOutlined } from '@ant-design/icons';
import * as createActions from "../../../actions/createTrial.js";
import * as historyActions from "../../../actions/historyTrial";
import "./index.scss";

const { Option } = Select;

const getClinicalTrialsGovURL = (nctID) => {
    const regexPattern = /^NCT\d{8}$/;
    if(regexPattern.test(nctID)){
        return CLINICAL_TRIALS_GOV_URL+nctID
    }
  };

const columns = [
  {
    title: "Study ID",
    dataIndex: "nct_id",
    ellipsis: true,
    render: nctId => <a href={getClinicalTrialsGovURL(nctId)} target="_blank">{nctId}</a>,
    width: 140,
  },
  {
    title: "Sponsor",
    dataIndex: "sponsor",
    ellipsis: true,
  },
  {
    title: "Indication",
    dataIndex: "indication",
    ellipsis: true,
    render: indication => indication && indication.join("; "),
  },
  {
    title: "Phase",
    dataIndex: "study_phase",
    ellipsis: true,
    width: 120,
  },
  {
    title: "MOA",
    dataIndex: "moa_data",
    ellipsis: true,
    render: moa_data => moa_data && moa_data.join("; "),
  },
  {
    title: "Status",
    dataIndex: "study_status",
    ellipsis: true,
    width: 170,
  },
  {
    title: "SoA",
    dataIndex: "soa_status_flag",
    ellipsis: true,
    width: 80,
    },
  {
    title: "Start Date",
    dataIndex: "start_date",
    ellipsis: true,
    width: 173,
    },
  {
    title: "Pediatric",
    dataIndex: "pediatric",
    ellipsis: true,
    width: 110,
  },
];

interface HistoricalProps {
  createTrial?: any;
  newTrial?: any;
  indicationList?: any;
  shouldFetch?: boolean;
  fetchHistory?: any;
  historyTrial?:any;
  handleNewTrialSelectChangeHistory?:any;
  studyPhaseList?:any;
  studyTypeList?:any;
  studyStatusList?:any;
  selectedSOA?:any,
  resetTrials?:any;
  resetTrialsCount?:any;
  isUpdate: boolean;
  moaList?:any;
  loadMoaData?:any;
  patientSegmentList?:any;
  patientSegmentData?:any;
  selectedMoaData?:any;
  indicationTherapeuticMap?:any;
  isSaveTrial?:boolean
}
const min = 2010;
const max = 2025;
const marks = {
  2010: "2010",
  2015: "2015",
  2020: "2020",
  2025: "2025",
};

const sponsorChartColor =[
    // "#d04a02",
    // "#d4520d",
    // "#d85d1c",
    // "#de6c30",
    // "#e47d47",
    // "#e68d5e",
    // "#e8aa89", 
    "#6F2849",
    "#9C3F5D",
    "#C8586C",
    "#DC7075",
    "#B13E64",
    "#CA5268",
    "#DC676C",
    "#EA8170", 
    "#F19B7C",
    "#F5B68D",
    "#F6D1A9",
    "#FBE5C4"
]
const statusChartColor=[
  // "#d04a02",
  // "#d4520d",
  // "#d85d1c",
  // "#de6c30",
  // "#e47d47",
  // "#e68d5e",
  // "#e8aa89"
  "#6F2849",
  "#9C3F5D",
  "#C8586C",
  "#DC7075",
  "#B13E64",
  "#CA5268",
  "#DC676C",
  "#EA8170",  
  "#F19B7C",
  "#F5B68D",
  "#F6D1A9",
  "#FBE5C4"
]
class SimilarHistoricalTrial extends React.Component<HistoricalProps> {
  constructor(props: HistoricalProps) {
    super(props);
    this.onFindTrials = this.onFindTrials.bind(this);
    this.onCloseFilter = this.onCloseFilter.bind(this)
  }
  inputRefs = {}; 

  temp_studyPhase = this.props.newTrial.saved_filters.study_phase.length > 0 || this.props.isUpdate? this.props.newTrial.saved_filters.study_phase : this.props.newTrial.study_phase || []
  temp_studyType = this.props.newTrial.saved_filters.study_type.length > 0 || this.props.isUpdate? this.props.newTrial.saved_filters.study_type : this.props.newTrial.study_type || []
  temp_studyStatus = this.props.newTrial.saved_filters.study_status.length > 0 || this.props.isUpdate? this.props.newTrial.saved_filters.study_status : this.props.newTrial.study_status || []
  temp_indication = this.props.newTrial.indication.length + this.props.newTrial.saved_filters.indication === 0 ? [] : this.props.newTrial.saved_filters.indication.length > 0 || this.props.isUpdate? this.props.newTrial.saved_filters.indication : this.props.newTrial.indication
  temp_soaFlag = this.props.newTrial.saved_filters.soa !== "" || this.props.isUpdate? this.props.newTrial.saved_filters.soa : this.props.newTrial.soa_status_flag||""
  temp_pediatric = this.props.newTrial.saved_filters.pediatric !== null||"" || this.props.isUpdate? this.props.newTrial.saved_filters.pediatric : this.setPediatricTrial(this.props.newTrial.pediatric_study)
  temp_selectedMoa = this.props.newTrial.saved_filters.moa.length > 0 || this.props.isUpdate? this.props.newTrial.saved_filters.moa : this.props.selectedMoaData || []
  temp_dateFrom = this.props.newTrial.saved_filters?.study_start_date[0] !== 2010 || this.props.isUpdate? this.props.newTrial.saved_filters.study_start_date[0] : this.props.newTrial.dateFrom || 2010
  temp_dateTo = this.props.newTrial.saved_filters?.study_start_date[1] !== 2025 || this.props.isUpdate? this.props.newTrial.saved_filters.study_start_date[1] : this.props.newTrial.dateTo || 2025
  temp_toggle = this.props.newTrial.saved_filters?.toggle || this.props.isUpdate? this.props.newTrial.saved_filters.toggle : this.props.newTrial.toggle_indication || ''

  state = {
    current: 1,
    pageSize: 5,
    pageSizeOptions: ["5", "10", "20", "50", "100"],
    sideBar: 6,
    showFilter: true,
    studyType: this.temp_studyType,
    studyPhase: this.temp_studyPhase,
    studyStatus: this.temp_studyStatus,
    indication: this.temp_indication,
    pediatric: this.temp_pediatric,
    dateFrom: this.temp_dateFrom,
    dateTo: this.temp_dateTo,
    selectedRowKeys: this.props.newTrial.similarHistoricalTrials?.length !== 0 ? this.props.newTrial.similarHistoricalTrials : [],
    spinning: false,
    data: [],
    rawData: this.props.historyTrial.historyData,
    statusChartData: [],
    sponsorChartData: [],
    optionOne: {},
    optionTwo: {},
    showStatusChart:true,
    indicationError:"",
    soaFlag: this.temp_soaFlag,
    soaData: [],
    moaDataList: this.props.moaList,
    patientSegmentDataList: this.props.patientSegmentList,
    selectedMoa: this.temp_selectedMoa,
    selectedPs: this.props.patientSegmentData || [],
    indicationList: this.props.indicationList.length === 0?this.props.newTrial.indicationList:this.props.indicationList,
    searchValues: {
      nct_id: '',
      sponsor: '',
      indication: '',
      study_phase: '',
      moa_data: '',
      study_status: '',
      soa_status_flag: '',
      start_date: '',
      pediatric: '',
    },
    searchedData: [],
    lastChangedInput: '',
    saved_filters: {
      study_phase: this.temp_studyPhase,
        study_type: this.temp_studyType,
        indication: this.temp_indication,
        study_status: this.temp_studyStatus,
        study_start_date: [this.temp_dateFrom, this.temp_dateTo],
        moa: this.temp_selectedMoa,
        pediatric: this.temp_pediatric,
        soa: this.temp_soaFlag,
        toggle: this.temp_toggle
    }, 
    toggle_indication: this.temp_toggle,
  };

  componentDidMount() {
    this.props.historyTrial.shouldFetch && this.getHistoryList();
    this.onFindTrials(this.state.rawData, true)
    !this.props.historyTrial.shouldFetch && this.onFindTrials(this.state.rawData, false)
    let therapeuticAreaVal = this.props.newTrial["therapeutic_area"]
    const sortIndication = (indicationList) => {
      return indicationList&&indicationList.sort((ind1, ind2) => (ind1.label < ind2.label) ? -1 : (ind1.label > ind2.label) ? 1 : 0);
    }

    this.setState({
        indicationList: sortIndication(this.props.indicationList)
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedRowKeys !== this.state.selectedRowKeys) {
      this.soaHandler(this.state.rawData)
    }    
    if(prevProps.isSaveTrial !== this.props.isSaveTrial) {
      this.setState({
        spinning: true
      });
    }
  }

  setPediatricTrial(val) {
    if (typeof val !== 'string') {
      return val;
    }

    if (val === 'null') {
      return null;
    }

    return val === 'true';
  }

  getChartData(datasource, key) {
    let keyValues = [];
    datasource.forEach((e) => {
      keyValues.push(e[key]);
    });
    keyValues = Array.from(new Set(keyValues));
    const pieChartData = [];
    keyValues.forEach((v) => {
      const values = datasource.filter(
        (d) => d[key].toLowerCase() == v.toLowerCase()
      );
      pieChartData.push({
        value: values.length,
        name: v,
      });
    });
    return pieChartData;
  }

  getHistoryList = async () => { 
    this.setState({
      spinning: true,
    });
    const resp = await listStudy();
    this.setState({
      spinning: false,
    });
      if (resp) {
      const moaData  = resp.moa_data_list;
      const patientSegmentData  = resp.patient_segment_data_list;
      this.props.loadMoaData(moaData, patientSegmentData)
      this.setState({
        moaDataList: moaData,
        patientSegmentDataList: patientSegmentData
      })
      const tmpData = resp.results.map((d, idx) => {
        d.key = d["study_ref_id"];
        d.sponsor = d.sponsor || '';
        d.study_status = d.study_status || '';
        return d;
      })
      this.onFindTrials(tmpData, false)
      this.setState({
        rawData: tmpData,
      });
      this.props.fetchHistory({
        historyData: tmpData,
        shouldFetch: false
      })
    }
  };

  changePage = (page) => {
    this.setState({
      current: page,
    });
  };

  onShowSizeChange = (current, pageSize) => {
    this.setState({
      current: current,
      pageSize: pageSize,
    });
  };

  onSelectChange = (key, v) => {
    this.setState({
      [key]: v,
    });
    
    if(key==="studyStatus") {
      let newValue
      if(v.length>1 && v.includes('All')) {
        if(v.indexOf('All')===0) {
          newValue = v.filter((item)=> {
            return item !== 'All'
          })
        this.setState({
          studyStatus: newValue,
        });
        } else {
          this.setState({
            studyStatus: ['All'],
          });
        }
      } else {
        this.setState({
          studyStatus: v,
        });
      }
    }
  };

  loadSOEHandler = (historicalTrialKeys) => {
    this.setState({
      selectedRowKeys: historicalTrialKeys,
    })
    this.props.createTrial({
      similarHistoricalTrials: historicalTrialKeys, 
    });
    this.props.handleNewTrialSelectChangeHistory("similarHistoricalTrials", historicalTrialKeys)
  }

  handleLegendFormatter = (chartData, legenName) => {
    const sum = chartData.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.value
    }, 0)
    const targetVal = chartData.find(d => d.name == legenName).value
    let p = ((targetVal / sum) * 100).toFixed(2);
    return legenName + " - " + p + "%";
  }

  changeToggleHandler = (e) => {
    this.setState({
      toggle_indication: e,
    })
  }

  filterByIndication = (tempIndication, d) => { 
    if(this.state.toggle_indication) {
      return tempIndication.every(indication =>  d.indication_id.includes(indication));
    }

    let includesAllIndications = tempIndication.every(indication => d.indication_id.includes(indication)); 
    let noExtraIndications = d.indication_id.every(indication => tempIndication.includes(indication));  
    return includesAllIndications && noExtraIndications;
  }

  sameIndications = (trialIndications, stateIndications) => {
    return trialIndications.sort().toString() === stateIndications.sort().toString()
  }

  onFindTrials = (datasource, eventListener) => {
    if(!this.handleValidation("indication", null)){
        return false
    }
    let tempIndication = this.state.indication.length > 0 ? [...this.state.indication] : []
    //     if(tempIndication.indexOf('Type 2 Diabetes') > -1){
    //       tempIndication.push('Diabetes Mellitus Type 2')
    //  
    const filteredData = datasource.filter((d) => {
      const date = d['start_date'].split('-')[0]
      return (
        (this.state.selectedMoa.length > 0
          ? this.state.selectedMoa.some( item => d.moa_data?.includes(item))
          : true) &&
          (this.state.selectedPs.length > 0
            ? this.state.selectedPs.some( item => d.patient_segment_data?.includes(item))
            : true) &&
        (this.state.studyPhase.indexOf("All") == -1 && this.state.studyPhase.length > 0
          ? this.state.studyPhase.indexOf(d.study_phase_id) > -1
          : true) &&
          ( this.state.studyType.indexOf("All") == -1 && this.state.studyType.length > 0
          ? this.state.studyType.indexOf(d.study_type_id) > -1
          : true) &&
          ( this.state.studyStatus.indexOf("All") == -1 && this.state.studyStatus.length > 0
          ? this.state.studyStatus.indexOf(d["study_status_id"]) > -1
          : true) && 
        (tempIndication.length > 0
          ? this.filterByIndication(tempIndication, d)
          : true) &&
        (this.state.pediatric !== null
          ? d.pediatric === this.state.pediatric
          : true) &&
          (this.state.soaFlag != "" && this.state.soaFlag != "All"
          ? d.soa_status_flag+'' == this.state.soaFlag
          : true) &&
        date>=this.state.dateFrom && date<=this.state.dateTo
      );
    });
    const statusData = this.getChartData(filteredData, "study_status");
    const sponsorData = this.getChartData(filteredData, "sponsor");
    const historicalTrialKeys = this.props.isUpdate && !eventListener ? this.state.selectedRowKeys
                                           : filteredData.map(item => item.key);
    const soaSelectedData = filteredData.filter(k => k.soa_status_flag.toUpperCase() == 'YES');
  
    if(historicalTrialKeys.length) {
      this.loadSOEHandler(historicalTrialKeys);
    }
    
    this.soaHandler(filteredData);
    this.setState({
      data: filteredData,
      searchedData: filteredData.map((item) => ({
        ...item,
        pediatric: item.pediatric ? "YES" : "NO"
      })),
      statusChartData: statusData,
      sponsorChartData: sponsorData,
      soaData: soaSelectedData
    });

    if (this.state.studyStatus) {
      this.setState({
        showStatusChart: true
      });
    }
    
    this.props.handleNewTrialSelectChangeHistory("saved_filters", Object.assign(this.state.saved_filters,{
      moa: this.state.selectedMoa,
      study_phase: this.state.studyPhase,
      study_type: this.state.studyType,
      pediatric: this.state.pediatric,
      indication: this.state.indication,
      soa: this.state.soaFlag,
      study_status: this.state.studyStatus,
      study_start_date: [this.state.dateFrom, this.state.dateTo],
      toggle: this.state.toggle_indication
    }))
    this.props.handleNewTrialSelectChangeHistory("dateFrom", this.state.dateFrom)
    this.props.handleNewTrialSelectChangeHistory("dateTo", this.state.dateTo)
  };

  onCloseFilter = ( value ) => {
    this.setState({
      sideBar: value
    })
  }

  getOptionOne() {
    const optionOne = {
      title: {
        text: "By Sponsor",
        x: "5%",
        y: "top",
        textStyle: {
          fontSize: 14,
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function (params,idx) {
          const chartData = optionOne.legend.data
          const sum = chartData.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.value
          }, 0)
          let p = ((params.value / sum) * 100).toFixed(2);
          return params.name + " - " + p + "%";
        },
        position: ['5%', '10%'],
        textStyle:{
          fontSize: 12,
      },
        confine:false,
      },
      legend: {
        show:false,
        data: this.state.sponsorChartData
      //   x: "left",
      //   y: "60%",
      //   itemHeight: 7,
      //   textStyle: {
      //     fontSize: 8,
      //   },
      //   formatter: function (params) {
      //     const chartData = optionOne.series[0].data
      //     const sum = chartData.reduce((accumulator, currentValue) => {
      //      return accumulator + currentValue.value
      //     }, 0)
      //     const targetVal = chartData.find(d => d.name == params).value
      //     let p = ((targetVal / sum) * 100).toFixed(2);
      //     return params + " - " + p + "%";
      //   },
      },
      series: [
        {
          type: "pie",
          center: ["30%", "35%"],
          radius: ["20%", "40%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          color: sponsorChartColor,
          data: this.state.sponsorChartData.sort((a, b) => {
            return b.value - a.value;
          })
          .slice(0, 10),
        },
      ],
    };
    return optionOne;
  }

  getOptionTwo() {
    const optionTwo = {
      title: {
        text: "By Status",
        x: "5%",
        y: "top",
        textStyle: {
          fontSize: 14,
          fontWeight: "bold",
        },
      },
      tooltip: {
        trigger: "item",
        formatter: function (params,idx) {
          const chartData = optionTwo.legend.data
              const sum = chartData.reduce((accumulator, currentValue) => {
               return accumulator + currentValue.value
              }, 0)
              let p = ((params.value / sum) * 100).toFixed(2);
              return params.name + " - " + p + "%";
        },
        position: ['5%', '10%'],
        textStyle:{
          fontSize: 12,
      },
        confine:false,
      },
      legend: {
        show:false,
        data: this.state.statusChartData
      //   x: "left",
      //   y: "60%",
      //   itemHeight: 7,
      //   textStyle: {
      //     fontSize: 8,
      //   },
      //   formatter: function (params,idx) {
      //     const chartData = optionTwo.series[0].data
      //     const sum = chartData.reduce((accumulator, currentValue) => {
      //      return accumulator + currentValue.value
      //     }, 0)
      //     const targetVal = chartData.find(d => d.name == params).value
      //     let p = ((targetVal / sum) * 100).toFixed(2);
      //     return params + " - " + p + "%";
      //   },
      },
      series: [
        {
          type: "pie",
           center: ["30%", "35%"],
          radius: ["20%", "40%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          color: statusChartColor,
          data: this.state.statusChartData.sort((a, b) => {
            return b.value - a.value;
          })
          .slice(0, 10),
        },
      ],
    };
    return optionTwo;
  }

  soaHandler = (data) => {
    let selectedSOAItems = data.filter((k, i) => this.state.selectedRowKeys?.includes(k.study_ref_id) && k.soa_status_flag == 'YES') 
    this.props.selectedSOA(selectedSOAItems.length)
  }

  onRowSelectChange = (selectedRowKeys) => { 
    if(selectedRowKeys.length == 0) {
      this.props.resetTrials(true)
    } else {
      this.props.resetTrials(false)
    }
    this.setState({ selectedRowKeys });
    this.props.createTrial({
      similarHistoricalTrials: selectedRowKeys,
    });
   this.props.handleNewTrialSelectChangeHistory("similarHistoricalTrials", selectedRowKeys)
  };

  onSelectCurrentChange = (item) => { 
    if(item.length == 0) {
      this.props.resetTrials(true)
    } else {
      this.props.resetTrials(false)
    }
    let newSeletedRowKeys = [...this.state.selectedRowKeys]
    item.forEach((value) => {
      if (!newSeletedRowKeys.includes(value)) {
        newSeletedRowKeys.push(value);
      }
    });    
    this.setState({ selectedRowKeys: newSeletedRowKeys });
    this.props.createTrial({
      similarHistoricalTrials: newSeletedRowKeys,
    });
    this.props.handleNewTrialSelectChangeHistory("similarHistoricalTrials", newSeletedRowKeys)
  };

  onSelectAllChange = () => { 
    const item = [...this.state.searchedData].map((val, index)=>{
      return val.key
    })
    if(item.length == 0) {
      this.props.resetTrials(true)
    } else {
      this.props.resetTrials(false)
    }
    let newSeletedRowKeys = [...this.state.selectedRowKeys]
    item.forEach((value) => {
      if (!newSeletedRowKeys.includes(value)) {
        newSeletedRowKeys.push(value);
      }
    });    
    this.setState({ selectedRowKeys: newSeletedRowKeys });
    this.props.createTrial({
      similarHistoricalTrials: newSeletedRowKeys,
    });
    this.props.handleNewTrialSelectChangeHistory("similarHistoricalTrials", newSeletedRowKeys)
  };

  handleDateChange = (value) => {
    this.setState({
      dateFrom: value[0],
      dateTo: value[1],
    });
  };

  handleValidation = (key, v) => {
    if(key == "indication" && this.state.indication.length < 1){
        this.setState({
            indicationError:"Indication is required."
          })
        return false;
    }else{
        this.setState({
            indicationError:""
          })
    }
    return true;
  };

  handleSearchChange = (column, value) => {
    this.setState((prevState) => ({
      searchValues: {
        ...this.state.searchValues,
        [column]: value,
      },
      lastChangedInput: column,
    }), () => {
      this.filterData();
    });
  };

  filterData = () => {
    const { data } = this.state;
    const { searchValues } = this.state;
    const filtered = data.map((item) => ({
      ...item,
      pediatric: item.pediatric ? "YES" : "NO"
    })).filter((record) => {
      for (const item in searchValues) {
        const searchValue = searchValues[item].toLowerCase();
        const recordValue = record[item] && record[item].toString().toLowerCase();
        if (searchValue && recordValue && !recordValue.includes(searchValue)) {
          return false; 
        }
      }
      return true;
    });
    this.setState({
      searchedData: filtered,
    });
  };

  render() {
    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: this.onRowSelectChange,
      columnWidth: 40,
      selections: [
        // Table.SELECTION_ALL,
        {
          key: 'all_page',
          text: 'Select all data',
          onSelect: this.onSelectAllChange
        },
        {
          key: 'current_page',
          text: 'Select current page',
          onSelect: this.onSelectCurrentChange
        },
        Table.SELECTION_NONE,
      ],
      preserveSelectedRowKeys: true,
    };
    const { searchValues } = this.state;

    const searchRow = 
      <tr key="search-row" className="search-row">
        <th></th>
        {columns.map((column) => (
          <th key={column.dataIndex}>
            <div>
              <Input
                ref={(ref) => (this.inputRefs[column.dataIndex] = ref)}
                placeholder={`${column.title}`}
                prefix={<SearchOutlined style={{color: "#666"}}/>}
                value={searchValues[column.dataIndex]}
                onChange={(e) => this.handleSearchChange(column.dataIndex, e.target.value)}
                autoFocus={this.state.lastChangedInput === column.dataIndex}
              />
            </div>
          </th>
        ))}
      </tr>

    // Add a custom row below the original header row
    const CustomHeaderWrapper = ({ children }) => {      
      return <thead>
        {children}
        {searchRow}
        </thead>;
    };

    return (
      <div className="historical-trial-list">
        <Row>
          <Col span={this.state.sideBar} className="side-bar">
          {this.state.sideBar !== 6 ? (
            <div className="side-bar-close">
              <div className="filter-button" onClick={() => this.onCloseFilter(6)}>
                <FilterOutlined />
              </div>
            </div>
            ) : (
            <div className="side-bar-filter">
              <div className="filter-item filter-label">
                <span>Filters</span>
                <CloseOutlined style={{ float: 'right', cursor: 'pointer', padding: 10 }} onClick={() => this.onCloseFilter(1)}/>
              </div>
              <div className="filter-item">
                <label>STUDY PHASE</label>
                <Select
                  mode="multiple"
                  allowClear
                  value={this.state.studyPhase || ["All"]}
                  style={{ width: "100%" }}
                  virtual={false}
                  onChange={(e) => this.onSelectChange("studyPhase", e)}
                >
                  {this.props.studyPhaseList.map((o) => {
                    return (
                      <Option value={o.value} key={o.value}>
                        {o.label}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label>STUDY TYPE</label>
                <Select
                  mode="multiple"
                  allowClear
                  value={this.state.studyType || ["All"]}
                  style={{ width: "100%" }}
                  virtual={false}
                  onChange={(e) => this.onSelectChange("studyType", e)}
                >
                  {this.props.studyTypeList.map((o) => {
                    return (
                      <Option value={o.value} key={o.value}>
                        {o.label}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label>STUDY STATUS</label>
                <Select
                  mode="multiple"
                  value={this.state.studyStatus || ["All"]}
                  style={{ width: "100%" }}
                  virtual={false}
                  onChange={(e) => this.onSelectChange("studyStatus", e)}
                >
                    {/* <Option value="All" key="All">All</Option> */}
                  {this.props.studyStatusList.map((o) => {
                    return (
                      <Option value={o.value} key={o.value}>
                        {o.label}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="filter-item">
                <label>INDICATION</label>
                <Select
                  mode="multiple"
                  allowClear
                  value={this.state.indication}
                  style={{ width: "100%" }}
                  virtual={false}
                  onBlur={(v) => this.handleValidation("indication", v)}
                  onChange={(e) => this.onSelectChange("indication", e)}
                  filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                >
                  {this.props.indicationList.length === 0? 
                  this.state.indicationList.map((o) => {
                    return (
                      <Option value={o.value} key={o.value}>
                        {o.label}
                      </Option>
                    );
                  })
                  :this.props.indicationList.map((o) => {
                    return (
                      <Option value={o.value} key={o.value}>
                        {o.label}
                      </Option>
                    );
                  })
                }
                </Select>
                <br/>
                <span className="trial-historical-trial-error">{this.state.indicationError}</span>
              </div>

              <div className="filter-item indication-wrapper">
                <label>INCLUDE TRIALS WITH ADDITIONAL COINDICATIONS</label>
                <Switch onChange={(e) => this.changeToggleHandler(e)} checked={this.state.toggle_indication} />
              </div>
              <div className="filter-item">
                <label>SCHEDULE OF ACTIVITIES(SoA)</label>
                <Select
                  value={this.state.soaFlag||"All"}
                  style={{ width: "100%" }}
                  onChange={(e) => this.onSelectChange("soaFlag", e)}
                >
                    <Option value="All" key="All">All</Option>
                    <Option value="YES" key="YES">Yes</Option>
                    {/* <Option value="NO" key="NO">No</Option> */}
                </Select>
              </div>
              <div className="filter-item">
                <label>PEDIATRIC</label>
                <Select
                  value={this.state.pediatric === null ? "null" : this.state.pediatric?.toString()}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    let inputValue = e === 'null' ? null : e === 'true' ? true : false;
                    this.onSelectChange("pediatric", inputValue)}
                  }
                >
                  {/* {this.props.soa_status_flag.map((o) => {
                    return (
                      <Option value={o.value} key={o.value}>
                        {o.label}
                      </Option>
                    );
                  })} */}
                    <Option value="null" key="null">All</Option>
                    <Option value="true" key="true">Yes</Option>
                    <Option value="false" key="false">No</Option>
                </Select>
              </div>
              <div className="filter-item">
                <label>MOA</label>
                <Select
                  mode="multiple"
                  allowClear
                  value={this.state.selectedMoa || []}
                  style={{ width: "100%" }}
                  virtual={false}
                  autoClearSearchValue={false}
                  // onBlur={(v) => this.handleValidation("indication", v)}
                  onChange={(e) => this.onSelectChange("selectedMoa", e)}
                >
                  {this.state?.moaDataList?.map((o) => {
                    return (
                      <Option value={o} key={o}>
                        {o}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              {/* <div className="filter-item">
                <label>PATIENT SEGMENT</label>
                <Select
                  mode="multiple"
                  allowClear
                  value={this.state.selectedPs || []}
                  style={{ width: "100%" }}
                  virtual={false}
                  // onBlur={(v) => this.handleValidation("indication", v)}
                  onChange={(e) => this.onSelectChange("selectedPs", e)}
                >
                  { this.state?.patientSegmentDataList?.map((o) => {
                    return (
                      <Option value={o} key={o}>
                        {o}
                      </Option>
                    );
                  })}
                </Select>
              </div> */}
              <div className="filter-item">
                <label>STUDY START DATE</label>
                <Slider
                  min={min}
                  max={max}
                  range={{ draggableTrack: true }}
                  marks={marks}
                  dots
                  value={[this.state.dateFrom, this.state.dateTo]}
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-item find-trial-btn-wrapper">
                <Button onClick={()=>this.onFindTrials(this.state.rawData, true)}>
                  Find Trials
                </Button>
              </div>
            </div>
            )}
          </Col>

          <Col span={24 - this.state.sideBar} className="historical-desc">
            <Spin spinning={this.state.spinning} >
              <div className="title">Similar Historical Trials</div>
              <div className="title-desc">Refine the list of similar historical trials that you will use to design your trial.</div>
              <div className="count-wrapper">
              <div className="summary-count">
                  {this.state.data && this.state.data.length}
                  <span className="num-desc">&nbsp;Number of trials</span>
                </div>
                <div className="summary-count">
                  {this.state.soaData && this.state.soaData.length}
                  <span className="num-desc">&nbsp;Number of trials with Schedule of Activities (SoA)</span>
                </div>
              </div>

               <Row className="chart-wrapper-row">
                 <div className="chart-wrapper">
                <Col span={6}>
                  <ReactECharts option={this.getOptionOne()}></ReactECharts>
                </Col>
                <Col span={6}>
                <div className="my-legend-wrapper">
                  {
                    this.state.sponsorChartData.sort((a,b)=>{
                      return b.value -a.value
                    }).slice(0,5).map( (d,idx)=>{
                      const chartData = this.state.sponsorChartData
                      const sum = chartData.reduce((accumulator, currentValue) => {
                       return accumulator + currentValue.value
                      }, 0)
                      let percent = ((d.value / sum) * 100).toFixed(2);
                      return(
                        <div className="custom-legend" key={d.name}>
                        <span className="my_legend" style={{'backgroundColor':sponsorChartColor[idx]}}></span><i className="my_legend_text">{`${d.name} - ${percent}%`}</i>
                      </div>
                      )
                    })
                  }
                </div>
                </Col>
                <Col span={6}>  <ReactECharts option={this.getOptionTwo()}></ReactECharts>  </Col>
                <Col span={6}>

                  <div className="my-legend-wrapper">
                        {
                          this.state.statusChartData.sort((a,b)=>{
                            return b.value -a.value
                          }).slice(0,5).map( (d,idx)=>{
                            const chartData = this.state.statusChartData
                            const sum = chartData.reduce((accumulator, currentValue) => {
                            return accumulator + currentValue.value
                            }, 0)
                            let percent = ((d.value / sum) * 100).toFixed(2);
                            return(
                              <div className="custom-legend" key={d.name}>
                              <span className="my_legend" style={{'backgroundColor':statusChartColor[idx]}}></span><i className="my_legend_text">{`${d.name} - ${percent}%`}</i>
                            </div>
                            )
                          })
                        }
                      </div>
                </Col>
              </div>
              </Row>
              {
                 this.props.isUpdate &&  <div className="update-warning">All downstream scenario metrics will be affected by updating your similar trial list.</div>
               }
              <Row>
                <Table
                  columns={columns}
                  dataSource={this.state.searchedData}
                  pagination={{
                    position: ["bottomRight"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${this.state.searchedData.length} items`,
                    defaultPageSize: 5,
                    pageSizeOptions:["5", "10", "20", "50", "100"],
                    onChange: this.changePage,
                    current: this.state.current,
                    total: this.state.searchedData.length,
                    showSizeChanger: true,
                    onShowSizeChange: this.onShowSizeChange,
                    // pageSizeOptions: this.state.pageSizeOptions
                  }}
                  // sticky
                  // scroll={{y: 300}}
                  scroll={{ x: 1400 }}
                  rowSelection={rowSelection}
                  // Rewrite the header of the Table to add a custom searching row
                  components={{
                    header: {
                      wrapper: CustomHeaderWrapper,
                    },
                  }}
                />
              </Row>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
  fetchHistory:(val) => dispatch(historyActions.fetchHistory(val)),
});

const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
  historyTrial:state.historyReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimilarHistoricalTrial);
