import { CREATE_SOA, UPDATE_SOA_ACTIVITIES } from '../constants/index';

export const initialSoa = { 
    soaAddedLabs: [],
    soaAddedExamination: [],
    soaAddedProcedures: [],
    soaAddedQuestionnaires: [],
    soaAddedStudyProcedures: [],
    soaOrgLabs: [],
    soaOrgExamination: [],
    soaOrgProcedures: [],
    soaOrgQuestionnaires: [],
    soaOrgStudyProcedures: [],
    soaFilteredLabs: [],
    soaFilteredExamination: [],
    soaFilteredQuestionnaires: [],
    soaFilteredProcedures: [],
    soaFilteredStudyProcedures: [],
    soaEndpoints : [],
    soaVisits: [],
    soaWeeks: [],
    isLoaded: false,
    event_numbers: {},
    soaVisitNotesList: [],
    scenarioId: '',
}

 

const soaReducer = (state = initialSoa, action) => {
	switch(action.type){	
        case CREATE_SOA:
            state = {
                ...state,
                ...action.data
            }
      break;
        case UPDATE_SOA_ACTIVITIES:
            state = {
                ...state,
                ...action.payload
            }
        break;
		default:			
    }
    return state
}
export default soaReducer;