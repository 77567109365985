import React, {useState} from "react";
import './index.scss';
import { Tooltip, Modal, Button, Typography } from "antd";
import { CONFIDENTIALITY_STATEMENT, TERMS_OF_USE, PRIVACY_STATEMENT} from "../../utils/messages.js";

const { Link } = Typography;

const Footer = (props) => {
    const [showDisclaimer, showShowDisclaimer] = useState(false)
    const [messageTitle, setMessageTitle] = useState("Message")
    const [message, setMessage] = useState("")

    const handleOk = ( msg) => {
        let showDisclaimerTemp = showDisclaimer;
        showShowDisclaimer(!showDisclaimerTemp)
        setMessage(msg)
    }
    return (
    <div>
        <div className="footer">
         <div className="left">
            <Tooltip title={"PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates."} >
                © 2023 PwC. All rights reserved.
            </Tooltip>
         </div>
            <div className="right">
                {/* <span><div onClick={() => handleOk( CONFIDENTIALITY_STATEMENT)}>Confidentiality Statement/Disclaimers</div></span> */}
                {/* <span><div onClick={() => handleOk( TERMS_OF_USE)}>Terms of Service</div></span> */}
                <Link href="https://productcentral.products.pwc.com/us/en/legal/terms-of-use.html" target="_blank" style={{color: '#fff'}}>Terms of Service</Link>
                <span><div onClick={() => handleOk(PRIVACY_STATEMENT)}>Privacy Statement</div></span>
                {/* <span><div onClick={() => handleOk("")}>Data usage policy</div></span> */}
            </div>
        </div>

        <Modal visible={showDisclaimer} className="FooterDisclaimersModal" maskClosable={true}  onOk={handleOk} closable={false}
        footer={[
          <Button key="back" type="primary" onClick={handleOk} >
            OKAY
          </Button>
        ]}
        >
          <div><p className="message">{message}</p></div>
        </Modal>
        </div>
    )

}

export default Footer