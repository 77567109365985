/* eslint-disable */
import React, { useState, useEffect, useReducer, useContext, useRef, } from "react";
import { withRouter } from 'react-router';
import { Input, Dropdown, Menu, Tooltip, Row, Table, Col, Slider, Button, List, Typography, Modal, Select, Checkbox, Form, } from "antd";
import { ArrowLeftOutlined, DownOutlined,UpOutlined  } from "@ant-design/icons";
import "./index.scss";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";
import { formatValue, formatHistoryValue, formatTime, formatHistoryTime } from '../../utils/auth'
import SoaAtGlance from "../SoaAtGlance";
const { Text } = Typography;

const ShowProtocolAmendment = (props) => {

  const {
    paData,
    similarHistoricalTrials,
    trial_id,
    scenarioId,
    scenarioType,
  } = props.location.state;

  console.log("paData",paData);
  

  const navigateBack = () => {
    props.history.push({
      pathname: '/scenario',
      state: {
        trial_id: trial_id,
        scenarioId: scenarioId, 
        scenarioType: scenarioType,
        similarHistoricalTrials: similarHistoricalTrials,
        whetherUpdate: 'false',
      }  
    }) 
  }

  const renderColoredText = (before_text, subtracted_text, text_color) => {
    let coloredText = [];
    const sortedSubtractedTextKeys = Object.keys(subtracted_text).sort((a, b) => parseInt(a) - parseInt(b));
    const textArray = [...before_text.split(" ")]
     const indexArray = sortedSubtractedTextKeys
     textArray.forEach((item, index) => {
      if (indexArray.indexOf((index + 1).toString()) !== -1 ) {
        coloredText.push(<span key={index+item} style={{ color: text_color }}>
          {item + ' '}
         </span>)
      }  else {
        coloredText.push(<span key={index+item} style={{ color: 'black' }}>
          {item+ ' '}
         </span>)
      }
    }) 

    return coloredText;
  }

  const expandedRowRender = (record) => {
    const subColumns = [
      {
        title: 'Trial Title',
        dataIndex: 'Trial Title',
        key: 'Trial Title',
        width: "20%",
      },
      {
        title: 'Summary',
        dataIndex: 'Summary',
        key: 'Summary',
        width: "30%",
      },
      {
        title: 'Text Before',
        dataIndex: 'Text Before',
        key: 'Text Before',
        width: "30%",
      },
      {
        title: 'Text After',
        dataIndex: 'Text After',
        key: 'Text After',
        width: "30%",
      },
    ];
    const subData = [];

    for (let i = 0; i < record['NUMBER OF AMENDMENTS']; ++i) {
      let item = paData[record['CRITERIA']][i]
      subData.push({
        key: i.toString(),
        'Trial Title': item['study_title'],
        'Summary': item['amendment_summary'],
        'Text Before': renderColoredText(item['before_text'],item['subtracted_text'], 'red'),
        'Text After': renderColoredText(item['after_text'],item['added_text'], 'green'),
      });
    }

    return <Table columns={subColumns} dataSource={subData} pagination={false} className='sub-table'/>;
  };

  const columns = [{
      title: 'ENTITY',
      dataIndex: 'CRITERIA',
      key: 'CRITERIA',
      width: "45%"
    },
    {
      title: 'NUMBER OF AMENDMENTS',
      dataIndex: 'NUMBER OF AMENDMENTS',
      key: 'NUMBER OF AMENDMENTS',
      width: "50%"
    },
  ];

  const data = [];
  for (let i = 0; i < Object.keys(paData).length; ++i) {
    data.push({
      key: i.toString(),
      CRITERIA: Object.keys(paData)[i],
      'NUMBER OF AMENDMENTS': paData[Object.keys(paData)[i]].length -1,
    });
  }
  
  return (
    <div className="pa-container">
    <div className="pa-header-layout"> 
      <div onClick={navigateBack} style={{cursor: 'pointer'}}><span style={{paddingRight: '10px'}}><ArrowLeftOutlined /></span>BACK</div>
      <h3 style={{paddingTop: '10px'}}>Summary of Protocol Amendments</h3>
      <p className="sub-heading"></p>
    </div>
    <div className="pa-body-layout" > 
    <Row>
      <Col span={24} className="top-description">
        <span>You have <strong>{Object.keys(paData).length||0} inclusion/exclusion entities</strong> related to historical protocol amendments. Expand the table to see a detailed summary.
        </span>
      </Col>
      <Col span={24} className="below-table">
        <Table
          columns={columns}
          expandable={{
            expandedRowRender,
            defaultExpandedRowKeys: ['0'],
            expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpOutlined onClick={e => onExpand(record, e)} />
            ) : (
              <DownOutlined onClick={e => onExpand(record, e)} />
            )
          }}
          dataSource={data}
          pagination={false}
        />
      </Col>
    </Row>
    </div>
  </div>    
  )
}

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
  createSoa: (val) => dispatch(createActions.createSoa(val)),
  updateIEActivities: (val) => dispatch(createActions.updateIEActivities(val))
});
const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
  newIE: state.ieReducer
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowProtocolAmendment));