/* eslint-disable */
import React, { useState, } from "react";
import { withRouter } from 'react-router';
import {
  Input,
  Select,
  message,
  Button,
  Progress,
  Spin,
  Breadcrumb,
  Drawer,
  Steps
} from "antd";
import './index.scss'

const Filters = (props) => {
  const { Option } = Select;
  const { showFilter, filterOptions, filterItems, value, parentStyle: {topX, leftX}, filterHandler, applyFilters, removeFilters} = props;  function handleFilterChange(value, item) { 
   filterHandler(value, item)
  }

  function submitFilters() { 
    applyFilters()
  }

  function clearFilters() { 
    removeFilters()
  }

  

  return (
   <>
      {showFilter ?
       ( <div className="Filter" style={{top: topX+50, left: leftX-250}}>
        <div className="filter-selector">
       { filterOptions.map((item, index) => ( 
            <div key={index} className="selector-item">
              <label>{item}</label> <br />  
              <Select
                  defaultValue="All"
                  value={value[index]}
                  mode="multiple"
                  showSearch
                  style={{ width: '100%' }}
                  virtual={false}
                  onChange={(value) => handleFilterChange(value, item)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {filterItems[index] && filterItems[index].map((o) => {
                    return (
                      <Option value={o} key={o}>
                        {o}
                      </Option>
                    );
                  })}
                </Select>
            </div>))}
        </div>

        <div className="filter_btn-group">
          <Button type="primary" size="small" className="cancel-filter" onClick={clearFilters}>
            CLEAR
          </Button>

          <Button type="primary" size="small" className="apply-filter" onClick={submitFilters}>
            APPLY
          </Button>
        </div>
        </div>
      ) 
      : ''} 
        
    </>
  )
};

export default withRouter(Filters);