/* eslint-disable */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Button, Modal } from "antd";
import { PWCID_LOGOUT_URL } from './../../constants';
import background from "../../assets/background.png";
import Footer from '../../components/Footer';
import "./index.scss";

const backgroundImg = {
  background: "url(" + background + ")",
  backgroundRepeat: "no-repeat",
  overflow: "hidden",
  backgroundSize: "100%",
};

const LogoutPage = (props: any) => {
  const [showDisclaimer, showShowDisclaimer] = useState(false)
  const [message, setMessage] = useState("")
  const handleOk = (msg) => {
    let showDisclaimerTemp = showDisclaimer;
    showShowDisclaimer(!showDisclaimerTemp)
    setMessage(msg)
  }

  useEffect(() => {
    window.location.replace(PWCID_LOGOUT_URL);
  }, []);

  return (
    <>
      <div className="login__container">
        <div className="continer__left" style={backgroundImg}></div>
        <div className="continer__right">
          <div className="login__form-wrapper">
            <div className="welcome__title">
              Logging out ...
            </div>
          </div>
        </div>
        <Modal visible={showDisclaimer} className="disclaimersModal" maskClosable={true} onOk={handleOk} closable={false}
          footer={[
            <Button key="back" type="primary" onClick={handleOk} >
              OKAY
            </Button>
          ]}
        >
          <div><p className="message">{message}</p></div>
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(LogoutPage);
