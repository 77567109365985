/* eslint-disable */
import React, { useState, useEffect, useReducer, useContext, useRef, } from "react";
import { withRouter } from 'react-router';
import { Input, Dropdown, Menu, Tooltip, Row, Table, Col, Slider, Button, List, Typography, Modal, Select, Checkbox, Form, } from "antd";
import { SearchOutlined, CheckOutlined, PlusOutlined, MinusOutlined, CloseOutlined, ArrowLeftOutlined, DownOutlined } from "@ant-design/icons";
import "./index.scss";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";
import { formatValue, formatHistoryValue, formatTime, formatHistoryTime } from '../../utils/auth'
const { Text } = Typography;

const EditableContext = React.createContext(null);

const EditableDetailRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableDetailCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          // margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`,
        //   },
        // ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} style={{ width: "300px", fontSize: 14}} />
      </Form.Item>
    ) : (
      <div
        className="detail-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
        onFocus={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const AddActivityIE = (props) => {

  const initialActivity = {
    "Text": '',
    "historical_timeframe": '',
    "user_timeframe": '',
    "Frequency":null,
    "details":null,
    "negation": false,
    'criteria_category': '',
    'historical_roa': '',
    'user_roa': '',
    'historical_acuity': '',
    'user_acuity': '',
    'historical_treatment': '',
    'user_treatment': '',
    'historical_lower': 0,
    'user_lower': 0,
    'historical_units': '',
    'user_units': '',
    'historical_upper': 0,
    'user_upper': 0,
    'user_value': ''
  }

  const {
    IEType,
    originDemographics,
    originIntervention,
    originMedCondition,
    originLabTest,
    demographicsSelected,
    interventionSelected,
    medConditionSelected,
    labTestSelected,
    similarHistoricalTrials,
    trial_id,
    scenarioId,
    scenarioType,
  } = props.location.state;

  let [demographicsElementsSelect, setDemographicsElementsSelect] = useState([])
  let [interventionElementsSelect , setInterventionElementsSelect ] = useState([])
  let [medConditionElementsSelect, setMedConditionElementsSelect] = useState([])
  let [labTestElementsSelect, setLabTestElementsSelect] = useState([])

  const [visibleValue, setVisibleValue] = useState(false);
  const [visibleFilterValue, setVisibleFilterValue] = useState(false);
  const [searchTxt,setSearchTxt] = useState("");

  const [filteredActivities, setFilteredActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [panelActivities, setPanelActivities] = useState([]);

  const categoryList = [ "Demographics", "Medical Condition", "Intervention", "Lab / Test" ]
  const [categories, setCategories] = useState(categoryList);
  const [filterbyCategories, setFilterByCategories] = useState(categoryList)

  const frequencyFilter = [5, 100]
  const [minValue, setMinValue] = useState(frequencyFilter[0])  //Slider bar minimon value
  const [maxValue, setMaxValue] = useState(frequencyFilter[1])  //Slider bar maximom value
  
  const [filterByMin, setFilterByMin] = useState(frequencyFilter[0])
  const [filterByMax, setFilterByMax] = useState(frequencyFilter[1])

  //Filtered libs for display and selection purpose
  const [demographics, setDemographics] = useState([])
  const [intervention, setIntervention] = useState([])
  const [medCondition, setMedCondition] = useState([])
  const [labTest, setLabTest] = useState([])

  // search bar data
  const [searchDemographics, setSearchDemographics] = useState([])
  const [searchIntervention, setSearchIntervention] = useState([])
  const [searchMedCondition, setSearchMedCondition] = useState([])
  const [searchLabTest, setSearchLabTest] = useState([])

  const [tableListItems, setTableListItems] = useState([])
  const [current, setCurrent] = useState(1) 

  const [addActivityModal, setAddActivityModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(initialActivity)
  const [modifierActivity, setModifierActivity] = useState(initialActivity)

  const [detailData, setDetailData] = useState([
    {
      key: '1',
      name: 'Values',
      history: formatHistoryValue(selectedActivity)|| '',
      modifiers: formatValue(modifierActivity)==='-'?'': formatValue(modifierActivity),
    },
    {
      key: '2',
      name: 'Timeframe',
      history: formatHistoryTime(selectedActivity)|| '',
      modifiers: formatTime(modifierActivity)==='-'?'':formatTime(modifierActivity),
    },
    {
      key: '3',
      name: 'ROA',
      history: selectedActivity['historical_roa'] || '-',
      modifiers: modifierActivity['user_roa'] || '',
    },
    {
      key: '4',
      name: 'Acuity',
      history:selectedActivity['historical_acuity'] || '-',
      modifiers: modifierActivity['user_acuity'] || '',
    },
    {
      key: '5',
      name: 'Treatment Modifier',
      history: selectedActivity['historical_treatment'] || '-',
      modifiers: modifierActivity['user_treatment'] || '',
    },
  ]);

  useEffect(() => {
    setDetailData([
      {
        key: '1',
        name: 'Values',
        history: formatHistoryValue(selectedActivity)|| '',
        modifiers: formatValue(modifierActivity)==='-'?'':formatValue(modifierActivity),
      },
      {
        key: '2',
        name: 'Timeframe',
        history: formatHistoryTime(selectedActivity)|| '',
        modifiers: formatTime(modifierActivity)==='-'?'':formatTime(modifierActivity),
      },
      {
        key: '3',
        name: 'ROA',
        history: selectedActivity['historical_roa'] || '-',
        modifiers: modifierActivity['user_roa'] || '',
      },
      {
        key: '4',
        name: 'Acuity',
        history:selectedActivity['historical_acuity'] || '-',
        modifiers: modifierActivity['user_acuity'] || '',
      },
      {
        key: '5',
        name: 'Treatment Modifier',
        history: selectedActivity['historical_treatment'] || '-',
        modifiers: modifierActivity['user_treatment'] || '',
      },
    ])
  },[]);

  useEffect(() => {
    // console.log("----props.newIE",props.newIE);
    if(IEType==='Inclusion Criteria') {
      setDemographicsElementsSelect(props.newIE?.demographicsSelect?.length > 0? props.newIE?.demographicsSelect : demographicsSelected)
      setInterventionElementsSelect(props.newIE?.interventionElements?.length > 0? props.newIE?.interventionElements : interventionSelected)
      setMedConditionElementsSelect(props.newIE?.medConditionElements?.length > 0? props.newIE?.medConditionElements : medConditionSelected)
      setLabTestElementsSelect(props.newIE?.labTestElements?.length > 0? props.newIE?.labTestElements : labTestSelected)
    } else if(IEType==='Exclusion Criteria') {
      setDemographicsElementsSelect(props.newIE?.excluDemographicsElements?.length > 0? props.newIE?.excluDemographicsElements : demographicsSelected)
      setInterventionElementsSelect(props.newIE?.excluInterventionElements?.length > 0? props.newIE?.excluInterventionElements : interventionSelected)
      setMedConditionElementsSelect(props.newIE?.excluMedConditionElements?.length > 0? props.newIE?.excluMedConditionElements : medConditionSelected)
      setLabTestElementsSelect(props.newIE?.excluLabTestElements?.length > 0? props.newIE?.excluLabTestElements : labTestSelected)
    }
  }, [props.newIE, demographicsSelected, interventionSelected, medConditionSelected, labTestSelected])

  useEffect(() => {       
  setFilteredActivities( 
    [...demographics,
    ...intervention,
    ...medCondition,
    ...labTest])
  }, [demographics,
  intervention,
  medCondition,
  labTest])

  useEffect(() => {       
    setSelectedActivities( 
      [...demographicsSelected,
        ...interventionSelected,
        ...medConditionSelected,
        ...labTestSelected,])
        
    }, [demographicsSelected,
    interventionSelected,
    medConditionSelected,
    labTestSelected,])

  useEffect(() => {
    // filter the origin criteria with frequency 1% to 100%
    setDemographics(originDemographics.filter((d) => {
        return d.Frequency * 100 >= 1 && d.Frequency * 100 <= 100;
    }))
    setIntervention(originIntervention.filter((d) => {
        return d.Frequency * 100 >= 1 && d.Frequency * 100 <= 100;
    }))
    setMedCondition(originMedCondition.filter((d) => {
      return d.Frequency * 100 >= 1 && d.Frequency * 100 <= 100;
    }))
    setLabTest(originLabTest.filter((d) => {
        return d.Frequency * 100 >= 1 && d.Frequency * 100 <= 100;
    }))
  }, []);

  useEffect(() => {      
    setDemographicsElementsSelect( [...demographicsSelected])
  }, [ demographicsSelected ])

  useEffect(() => {      
    setInterventionElementsSelect([...interventionSelected ])
  }, [ interventionSelected ])

  useEffect(() => {
    setMedConditionElementsSelect([...medConditionSelected ])
  }, [ medConditionSelected ])

  useEffect(() => {
    setLabTestElementsSelect([...labTestSelected])
  }, [ labTestSelected ])

  useEffect(() => {
    let flag = 0
    let tableListItemTmp = filteredActivities.filter((d)=>{
      return d.Frequency * 100 >= filterByMin && d.Frequency * 100 <= filterByMax && filterbyCategories.includes(d.criteria_category)
    }).map((item,index) =>{
      switch (item.criteria_category.charAt(0)) {
        case "D":
          flag = demographicsElementsSelect.findIndex((e) => {
              return e["Eligibility Criteria"] == item.Text && e["negation"] == item.negation;
            }) 
          break;
        case "M":
          flag = medConditionElementsSelect.findIndex((e) => {
            return e["Eligibility Criteria"] == item.Text && e["negation"] == item.negation;
          }) 
          break;
        case "I":
          flag = interventionElementsSelect.findIndex((e) => {
            return e["Eligibility Criteria"] == item.Text && e["negation"] == item.negation;
          }) 
          break;
        default:
          flag = labTestElementsSelect.findIndex((e) => {
            return e["Eligibility Criteria"] == item.Text && e["negation"] == item.negation;
          }) 
          break;
      }
      return Object.assign(item,{selected:flag!= -1  ? true: false})
    })
    setTableListItems(tableListItemTmp) 
  }, [filteredActivities, filterbyCategories, filterByMin, filterByMax, demographicsElementsSelect,medConditionElementsSelect, interventionElementsSelect, labTestElementsSelect])

  // search bar data
  const minValueLimit = 0
  const maxValueLimit = 100

  const optionLabelDemographics = originDemographics.map((item, index)=>{    
    return item
  })
  const optionLabelIntervention = originIntervention.map((item, index)=>{
    return item
  })
  const optionLabelMedCondition = originMedCondition.map((item, index)=>{
    return item
  })
  const optionLabelLabTest = originLabTest.map((item, index)=>{
    return item
  })

  const getFrequency = (value) => {
    setMinValue(value[0])
    setMaxValue(value[1])
  }

  let timer = null;
  const onTextChange = (e) => {
    const val = e.target.value.toLowerCase();
    let searchDemo = []
    let searchInte = []
    let searchMed=[]
    let searchLab=[]
    !visibleValue&&setVisibleValue(true)
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      setSearchTxt(val)
      searchDemo = val.length>0? optionLabelDemographics.filter(
        (i) =>
          typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
        ) : []
      searchInte = val.length>0? optionLabelIntervention.filter(
        (i) =>
          typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
        ): []
      searchMed = val.length>0?optionLabelMedCondition.filter(
        (i) =>
          typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
        ): []
      searchLab = val.length>0?optionLabelLabTest.filter(
        (i) =>
          typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
        ): []
      setSearchDemographics(searchDemo)
      setSearchIntervention(searchInte)
      setSearchMedCondition(searchMed)
      setSearchLabTest(searchLab)
    }, 200);
    
  }

  const formatter = (value) => {
    return value+'%'
  } 

  const renderItem = (title: string, type: string, idx: any, Frequency: any, negation: any) => {
    return (
      <>
      <Tooltip title={`${negation?'Negated Entity':''}`} placement="topLeft">
      <span style={  { background: negation ? '#ca4a04' : '', marginRight: '10px', display: 'inline-block', padding: '7px', borderRadius: '50%',verticalAlign:'middle' }}></span>
      </Tooltip>
      <Tooltip
     title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}
     >
      <div
        className="itemLine"
        style={{
          display: 'inline-flex',
          justifyContent: 'space-between',
        }}
      >
        <span className="itemTitle">
          {(searchTxt.length < title.length) && compareString(title, title.toLowerCase().split(searchTxt)[0])} 
          <span className={`${
            searchTxt &&
            title.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
              ? "matched-item"
              : ""
            }`}>
             {compareMiddle(title, searchTxt.length - title.length === 0?title:searchTxt)}
          </span>
          { (searchTxt.length !== 0)&&(searchTxt.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length))}
        </span>
        <span style={{color:"#CA4A04", marginLeft:"25px"}}>
          Add
        </span>
      </div>
      </Tooltip> 
      </>
    )
  };
      
  const compareString = (original, sub_str) => {
    let index = original.toLocaleLowerCase().indexOf(sub_str)
    return original.substring(index, sub_str.length)
  }
  
  const compareMiddle = (original, sub_str) => {
    let index = original.toLocaleLowerCase().indexOf(sub_str);
    let secondIndex = original.toLocaleLowerCase().length == sub_str.toLocaleLowerCase().length ? original.toLocaleLowerCase().length : index+sub_str.length;
    return original.substring(index, secondIndex)
  }

  const handleSelectedCriteria = (item, activeType) => {
    switch(item.criteria_category.charAt(0)){
      case "D":
        var index = demographicsElementsSelect.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
        if(activeType == 1){
          if(index < 0){
            var newItem = {
              ...item,
              "Eligibility Criteria": item.Text,
              "historical_value_free_text": formatHistoryValue(item),
              "user_value_free_text": formatValue(item),
              "historical_timeframe": formatHistoryTime(item),
              "user_timeframe": formatTime(item),
              "Frequency":item.Frequency,
              "Detail":item.details,
              "negation": item.negation
            }
            demographicsElementsSelect.push(newItem)
          }
        } else {
          if(index >= 0){
            demographicsElementsSelect.splice(index, 1)
          }
        }
        break;
      case "M":
        var index = medConditionElementsSelect.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
        if(activeType == 1){
          if(index < 0){
            var newItem = {
              ...item,
              "Eligibility Criteria": item.Text,
              "historical_value_free_text": formatHistoryValue(item),
              "user_value_free_text": formatValue(item),
              "historical_timeframe": formatHistoryTime(item),
              "user_timeframe": formatTime(item),
              "Frequency":item.Frequency,
              "Detail":item.details,
              "negation": item.negation
            }
            medConditionElementsSelect.push(newItem)
          }
        } else {
          if(index >= 0){
            medConditionElementsSelect.splice(index, 1)
          }
        }
        break;
      case "I":
        var index = interventionElementsSelect.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
        if(activeType == 1){
          if(index < 0){
            var newItem = {
              ...item,
              "Eligibility Criteria": item.Text,
              "historical_value_free_text": formatHistoryValue(item),
              "user_value_free_text": formatValue(item),
              "historical_timeframe": formatHistoryTime(item),
              "user_timeframe": formatTime(item),
              "Frequency":item.Frequency,
              "Detail":item.details,
              "negation": item.negation
            }
            interventionElementsSelect.push(newItem)
          }
        } else {
          if(index >= 0){
            interventionElementsSelect.splice(index, 1)
          }
        }
        break;
      default:
        var index = labTestElementsSelect.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
        if(activeType == 1){
          if(index < 0){
            var newItem = {
              ...item,
              "Eligibility Criteria": item.Text,
              "historical_value_free_text": formatHistoryValue(item),
              "user_value_free_text": formatValue(item),
              "historical_timeframe": formatHistoryTime(item),
              "user_timeframe": formatTime(item),
              "Frequency":item.Frequency,
              "Detail":item.details,
              "negation": item.negation
            }
            labTestElementsSelect.push(newItem)
          }
        } else {
          if(index >= 0){
            labTestElementsSelect.splice(index, 1)
          }
        }
        break;
    }
  }
  
  // Add button in the list
  const addActivity = (e, record, idx) => {  
    setAddActivityModal(true)
    const tempSelect = {...record, idx: idx}
    const tempModifier = {
      ...initialActivity,
      "Text": record["Text"],
      "negation": record["negation"],
      "criteria_category": record["criteria_category"],
      "Frequency": record["Frequency"]
    }
    setSelectedActivity(tempSelect)
    setModifierActivity(tempSelect)
    setDetailData([
      {
        key: '1',
        name: 'Values',
        history: formatHistoryValue(tempSelect)|| '',
        modifiers: formatValue(tempSelect)==='-'?'':formatValue(tempSelect),
      },
      {
        key: '2',
        name: 'Timeframe',
        history: formatHistoryTime(tempSelect)|| '',
        modifiers: formatTime(tempSelect)==='-'?'':formatTime(tempSelect),
      },
      {
        key: '3',
        name: 'ROA',
        history: tempSelect['historical_roa'] || '-',
        modifiers: tempSelect['user_roa'] || '',
      },
      {
        key: '4',
        name: 'Acuity',
        history:tempSelect['historical_acuity'] || '-',
        modifiers: tempSelect['user_acuity'] || '',
      },
      {
        key: '5',
        name: 'Treatment Modifier',
        history: tempSelect['historical_treatment'] || '-',
        modifiers: tempSelect['user_treatment'] || '',
      },
    ])
  }

  const handleCancelActivity = () => {
    setAddActivityModal(false)
    setSelectedActivity(initialActivity)
    setModifierActivity(initialActivity);
  } 

  const handleOkActivity = (e, item) => { 
     
    setAddActivityModal(false);
    setSelectedActivity(initialActivity);
    setModifierActivity(initialActivity);
    const newselectedActivities = [...selectedActivities]
    var newItem = {
      ...item,
      "Eligibility Criteria": item.Text,
      "historical_value_free_text": formatHistoryValue(item),
      "user_value_free_text": formatValue(item),
      "historical_timeframe": formatHistoryTime(item),
      "user_timeframe": formatTime(item),
      "Frequency":item.Frequency,
      "Detail":item.details,
      "negation": item.negation
    }

    detailData.forEach(item => {
      if (item.name === "Timeframe") newItem.user_timeframe = item["modifiers"]?.trim()
      if (item.name === "Values") newItem.user_value_free_text = item["modifiers"]?.trim()
      if (item.name === "Acuity") newItem.user_acuity = item["modifiers"]?.trim()
      if (item.name === "ROA") newItem.user_roa = item["modifiers"]?.trim()
      if (item.name === "Treatment Modifier") newItem.user_treatment = item["modifiers"]?.trim()
    });


    newselectedActivities.push(newItem)
    setSelectedActivities(newselectedActivities)
    // add or delete criteria
    handleSelectedCriteria(newItem, 1)
    const newPanelData = [...panelActivities]
    newPanelData.push(newItem)
    setPanelActivities(newPanelData)
  }

   // Click X button in the right panel
  const removeActivity = (e, item, index) => {     
    const newPanelData = [...panelActivities]
    newPanelData.splice(index, 1)
    setPanelActivities(newPanelData)

    const newselectedActivities = [...selectedActivities]
    let targetIndex = newselectedActivities.findIndex((e) => {
      return e["Eligibility Criteria"] == item.Text && e["negation"] == item.negation;
    })
    newselectedActivities.splice(targetIndex,1)
    setSelectedActivities(newselectedActivities)
    handleSelectedCriteria(item, 0)

  }

  const columns = [
      {
      title: "ENTITY",
      dataIndex: "Text",
      key: "Text",
      ellipsis: true, 
      width: 350,
      sorter: (a, b) => (a['Text'] < b['Text'] ? -1 : 1),
      render:  (_, record, index) => {
        return <div style={{width: '100%'}}>
          <Tooltip title={`${record.negation?'Negated Entity':''}`} placement="topLeft">
          <span style={{ background: record.negation ? '#ca4a04' : '', display: 'inline-block', padding: '7px', borderRadius: '50%' , height: '14px', marginRight: '10px', verticalAlign: 'middle' }}></span> 
          </Tooltip>
          <Text
            // ellipsis={true}
            ellipsis={{tooltip: `${record.Text}`} }
            style={{display: 'inline-block', width: "90%",}}
            className="select-text"
            >
              {record.Text}
          </Text>
        </div>
      }
      },
      {
      title: "CATEGORY",
      dataIndex: "criteria_category",
      key: "criteria_category",
      width: 300,
      sorter: (a, b) => (a.criteria_category < b.criteria_category ? -1 : 1),
      ellipsis: true
      },
      {
      title: "ENTITY FREQUENCY",
      dataIndex: "Frequency",
      key: "Frequency",
      sorter: (a, b) => (a.Frequency < b.Frequency ? -1 : 1),
      ellipsis: true,
      render: (_, item) =>  Math.round(item['Frequency'] * 10000) / 100 + "%"
      
      },
      {
        title: '',
        dataIndex: 'operation',
        key: 'operation',
        render: (_, record, index) => { 
          return selectedActivities.findIndex((e) => {
            return e["Eligibility Criteria"] == record.Text && e["negation"] == record.negation;
          }) !== -1  ? 
          (<div style={{float: 'right', marginRight: 30}}>
            <span style={{paddingRight: '10px', fontSize: '16px'}}> 
            <CheckOutlined />
            </span>
            ADDED
          </div>) : 
          (<div className="criteria-lib" style={{float: 'right', marginRight: 30}}>
            <Button  onClick={(e) => addActivity(e, record, index)} className="add-button">ADD</Button>
          </div>)
      }
    }
  ];

  const renderItemClick = (title: string, type: string, idx: any, Frequency: any, negation: any) => {
    return (
      <>
      <Tooltip title={`${negation?'Negated Entity':''}`} placement="topLeft">
      <span style={  { background: negation ? '#ca4a04' : '', marginRight: '10px', display: 'inline-block', padding: '7px', borderRadius: '50%',verticalAlign:'middle' }}></span> 
      </Tooltip>
    <Tooltip
     title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}
     >
      <div
        className="itemLine"
        style={{
          display: 'inline-flex',
          justifyContent: 'space-between',
        }}
      > 
        <span className="itemTitle">
          {(searchTxt.length < title.length) && compareString(title, title.toLowerCase().split(searchTxt)[0])} 
          <span className={`${
            searchTxt &&
            title.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
              ? "matched-item"
              : ""
            }`}>
             {compareMiddle(title, searchTxt.length - title.length === 0?title:searchTxt)}
          </span>
          { (searchTxt.length !== 0)&&(searchTxt.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length))}
        </span>
        <span style={{color:"#3193E5", fontWeight:700, marginLeft:"25px"}}>
            <CheckOutlined />
        </span>
      </div>
      </Tooltip>
      </>
    )
  };

  const onItemClick = ({ key }) => {  
    let item = JSON.parse(key.slice(1))

    let selectedEle = []
      switch (item.criteria_category.charAt(0)) {
        case "D":
          selectedEle = demographicsElementsSelect
          break;
        case "M":
          selectedEle = medConditionElementsSelect
          break;
        case "I":
          selectedEle = interventionElementsSelect
          break;
        default:
          selectedEle = labTestElementsSelect
          break;
      }
   
    if(selectedEle.findIndex((e) => {
      return e["Eligibility Criteria"] == item.Text && e["negation"] == item.negation;
    }) !== -1) {
      return
      // handleOkActivity(null, item)
    } else {
      setAddActivityModal(true); 
      setVisibleValue(false);
      setSelectedActivity(item) 
      setModifierActivity(item)
      setDetailData([
        {
          key: '1',
          name: 'Values',
          history: formatHistoryValue(item)|| '',
          modifiers: formatValue(item)==='-'?'':formatValue(item),
        },
        {
          key: '2',
          name: 'Timeframe',
          history: formatHistoryTime(item)|| '',
          modifiers: formatTime(item)==='-'?'':formatTime(item),
        },
        {
          key: '3',
          name: 'ROA',
          history: item['historical_roa'] || '-',
          modifiers: item['user_roa'] || '',
        },
        {
          key: '4',
          name: 'Acuity',
          history:item['historical_acuity'] || '-',
          modifiers: item['user_acuity'] || '',
        },
        {
          key: '5',
          name: 'Treatment Modifier',
          history: item['historical_treatment'] || '-',
          modifiers: item['user_treatment'] || '',
        },
      ])
      }
  }

  const menu = (
    <Menu onClick={onItemClick}>
      {(searchTxt.length !== 0)&&searchDemographics.length>0 && (<Menu.ItemGroup title="Demographics">
        {
          searchDemographics.slice(0, 10).map((item,idx)=>{
            if(demographicsElementsSelect.findIndex((domain) => item.Text == domain['Eligibility Criteria']&& item.negation == domain['negation'])<0){
              return <Menu.Item key={"D"+JSON.stringify(item)}>{renderItem(item.Text,"D", idx, item.Frequency, item.negation)}</Menu.Item>
            } else {
              return <Menu.Item key={"D"+JSON.stringify(item)}>{renderItemClick(item.Text,"D", idx, item.Frequency, item.negation)}</Menu.Item>
            }
          })
        }
      </Menu.ItemGroup>)}
      {(searchTxt.length !== 0)&&searchMedCondition.length>0 && 10-searchDemographics.length>0?(<Menu.ItemGroup title="Medical Condition">
        {
          searchMedCondition.slice(0,10-searchDemographics.length).map((item,idx)=>{
            if(medConditionElementsSelect.findIndex((domain) => item.Text == domain['Eligibility Criteria']&& item.negation == domain['negation'])<0){
              return <Menu.Item key={"M"+JSON.stringify(item)}>{renderItem(item.Text, "M",idx, item.Frequency, item.negation)}</Menu.Item>
            }else {
              return <Menu.Item key={"M"+JSON.stringify(item)}>{renderItemClick(item.Text, "M",idx, item.Frequency, item.negation)}</Menu.Item>
            }
          })
        }
      </Menu.ItemGroup>):(<></>)}
      {(searchTxt.length !== 0)&&searchIntervention.length>0 &&10-searchDemographics.length-searchMedCondition.length>0?(<Menu.ItemGroup title="Intervention">
          {
          searchIntervention.slice(0,10-searchDemographics.length-searchMedCondition.length).map((item,idx)=>{
            if(interventionElementsSelect.findIndex((domain) => item.Text == domain['Eligibility Criteria']&& item.negation == domain['negation'])<0){
              return <Menu.Item key={"I"+JSON.stringify(item)}>{renderItem(item.Text, "I",idx, item.Frequency, item.negation)}</Menu.Item>
            }else{
              return <Menu.Item key={"I"+JSON.stringify(item)}>{renderItemClick(item.Text, "I",idx, item.Frequency, item.negation)}</Menu.Item>
            }
          })
        }
      </Menu.ItemGroup>):(<></>)}
      {(searchTxt.length !== 0)&&searchLabTest.length>0 && 10-searchDemographics.length-searchMedCondition.length - searchIntervention.length>0?<Menu.ItemGroup title="Lab / Test">
          {
          searchLabTest.slice(0,10-searchDemographics.length-searchMedCondition.length- searchIntervention.length).map((item,idx)=>{
            if(labTestElementsSelect.findIndex((domain) => item.Text == domain['Eligibility Criteria']&& item.negation == domain['negation'])<0){
              return <Menu.Item key={"L"+JSON.stringify(item)}>{renderItem(item.Text, "L",idx, item.Frequency, item.negation)}</Menu.Item>
            }else{
              return <Menu.Item key={"L"+JSON.stringify(item)}>{renderItemClick(item.Text, "L",idx, item.Frequency, item.negation)}</Menu.Item>
            }
          })
        }
      </Menu.ItemGroup>:(<></>)}
      {searchTxt.length === 0 && <div style={{cursor: "none", padding: 20}}></div>}
      {(searchTxt.length !== 0 && searchDemographics.length + searchMedCondition.length + searchIntervention.length + searchLabTest.length === 0) && <div key="no-result" style={{cursor: "none", padding: 20}}>There are no Inclusion criteria in the library that match your search.</div>}
    </Menu>
    );

  const onCategorySelect = ({ key }) => {
    let item = JSON.parse(key.slice(1));
    const newData = [...categories]
    let index = newData.findIndex((d) => d == item);
    if(index < 0) {
      newData.push(item)
    } else {
      newData.splice(index, 1)
    }
    setCategories(newData)
  }

  const cancelFilterDisplay = () => {
    setCategories(categoryList)
    setVisibleFilterValue(false) 
    setMinValue(frequencyFilter[0])
    setMaxValue(frequencyFilter[1])
    setFilterByCategories(categoryList)
    setFilterByMin(frequencyFilter[0])
    setFilterByMax(frequencyFilter[1])
  }

  const applyFilters = () => {
    setFilterByCategories(categories)
    setVisibleFilterValue(false) 
    setFilterByMin(minValue)
    setFilterByMax(maxValue)
  }

  const filterMenu=(
    <div className="category-filters">
      <div className="content">
        <span>Set Frequency</span>
        <span style={{ float: "right", fontWeight: 'bold' }}>
          {minValue}% - {maxValue}%
        </span>
      </div>
      <Slider
        range={{ draggableTrack: true }}
        value={[minValue, maxValue]}
        tipFormatter={formatter}
        onAfterChange={getFrequency}
        onChange={getFrequency}
        min={1}
      />
        <Menu onClick={onCategorySelect}>
        {categoryList.map((list_item) => (  <Menu.Item  key={"D"+JSON.stringify(list_item)}>  
        <>
        {categories.includes(list_item) ? (
            <span style={{width: '20%',display: 'inline-block',color:"#3193E5", fontWeight:700, marginLeft:"25px"}}>
            <CheckOutlined />
            </span>
        ) : (
          <span style={{width: '20%',display: 'inline-block',color:"#CA4A04", marginLeft:"25px"}}>
          X
        </span>
        )} 
    </>
    {list_item} </Menu.Item>))} 
        </Menu>
        <div>
        <Button className="cancel-filters" onClick={cancelFilterDisplay}>CANCEL</Button>
        <Button className="apply-filters" onClick={applyFilters}>APPLY</Button>
        </div>
    </div>
  )

  const addActivityToIE = () => {
    // console.log("panelActivities now",panelActivities);
    panelActivities.forEach(item => {
      handleSelectedCriteria(item, 1)
    });
    
    if(IEType==='Inclusion Criteria') {
      props.updateIEActivities({
        demographicsElements:demographicsElementsSelect ,
        interventionElements: interventionElementsSelect,
        medConditionElements: medConditionElementsSelect,
        labTestElements: labTestElementsSelect,
      })
    } else if(IEType==='Exclusion Criteria') {
      props.updateIEActivities({
        excluDemographicsElements:demographicsElementsSelect ,
        excluMedConditionElements: medConditionElementsSelect,
        excluInterventionElements: interventionElementsSelect,
        excluLabTestElements: labTestElementsSelect,
      })
    }

    localStorage.setItem('whetherSave', 'true')

    props.history.push({
      pathname: '/scenario',
      state: {
        trial_id: trial_id,
        scenarioId: scenarioId, 
        scenarioType: scenarioType,
        similarHistoricalTrials: similarHistoricalTrials,
        whetherUpdate: 'false',
      }  
    }) 
  }

  const navigateBack = () => {
    addActivityToIE()
    // props.history.push({
    //   pathname: '/scenario',
    //   state: {
    //     trial_id: trial_id,
    //     scenarioId: scenarioId, 
    //     scenarioType: scenarioType,
    //     similarHistoricalTrials: similarHistoricalTrials,
    //     whetherUpdate: 'false',
    //   }  
    // }) 
  }

  const changePage = (page) => {
    setCurrent(page) 
  };

  const detailColumns =  [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
  },
  {
    title: 'History',
    dataIndex: 'history',
    key: 'history',
    width: 320,
  },
  {
    title: 'Modifiers',
    dataIndex: 'modifiers',
    key: 'modifiers',
    width: 320,
    editable: true,
    render: (_, record) => {
      return <Input style={{ width: "300px", fontSize: 14}} value={record['modifiers']}/>
    }
  },]

  const handleSave = (row) => {
    const newData = [...detailData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    
    setDetailData(newData);
  };

  const newDetailColumns = detailColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onCopyToMyModifier = () =>{

    setDetailData([
      {
        key: '1',
        name: 'Values',
        history: formatHistoryValue(selectedActivity)|| '',
        modifiers: formatHistoryValue(selectedActivity)|| '',
      },
      {
        key: '2',
        name: 'Timeframe',
        history: formatHistoryTime(selectedActivity)|| '',
        modifiers: formatHistoryTime(selectedActivity)|| '',
      },
      {
        key: '3',
        name: 'ROA',
        history: selectedActivity['historical_roa'] || '-',
        modifiers: selectedActivity['historical_roa'] || '-',
      },
      {
        key: '4',
        name: 'Acuity',
        history:selectedActivity['historical_acuity'] || '-',
        modifiers: selectedActivity['historical_acuity'] || '-',
      },
      {
        key: '5',
        name: 'Treatment Modifier',
        history: selectedActivity['historical_treatment'] || '-',
        modifiers: selectedActivity['historical_treatment'] || '-',
      },
    ])

    setModifierActivity(selectedActivity)
  }
    
  return (
    <div className="activity-ie-container">
    <div className="activity-soa-header_layout"> 
      <div onClick={navigateBack} style={{cursor: 'pointer'}}><span style={{paddingRight: '10px'}}><ArrowLeftOutlined /></span>BACK</div>
      <h3>{IEType} 
      {/* ({filteredActivities.length}) */}
      </h3>
      {/* <p className="sub-heading">You can add criteria from the library</p> */}
    </div>

    <div className="activity-soa-body_layout" > 
    <Row>
      <Col span={18} style={{padding: '2% 5% 5% 5%'}}>
        <Row> 
          <Col span={18}>
            <Dropdown
              overlay={menu}
              overlayClassName="searchbox"
              className="search-soa"
              visible={visibleValue} 
              onVisibleChange={(visible: boolean) => {!visibleValue?setVisibleValue(true):setVisibleValue(false)}}
            >
            <Input
                prefix={<SearchOutlined />}
                style={{ width: '100%', height: 37 }}
                allowClear
                onChange={onTextChange}
                onClick={e => e.preventDefault()} 
                placeholder="Search by keyword" />
              </Dropdown>
          </Col>
          <Col span={6}>
          <Dropdown
              overlay={filterMenu} 
              className="search-soa-filters"
              visible={visibleFilterValue} 
              onVisibleChange={(visible: boolean) => {!visibleFilterValue?setVisibleFilterValue(true):setVisibleFilterValue(false)}}
            >
            <p>Category <DownOutlined  style={{paddingLeft: '3px'}}/></p>
            </Dropdown>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
          <Table
              columns={columns}
              dataSource= {tableListItems}
              rowKey={record => record.Text.toString() + record.negation.toString() + record.criteria_category.toString() + ''}  
              pagination={{
                position: ["bottomRight"],
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${tableListItems.length} items`,
                defaultPageSize: 5,
                showSizeChanger: true,
                pageSizeOptions:["5", "10", "20", "50", "100"],
                onChange: changePage,
                current: current,
                total: tableListItems.length
              }} 
            />
          </Col>
        
        </Row>
      </Col>
      <Col span={6} className="activities-container">
      <div className="side-panel-heading">Selected Entities ({panelActivities.length})
      </div>

      <List 
        className="lists-container"
        bordered
        dataSource={panelActivities}
        renderItem={(item, index) => (
          <List.Item>
            <Typography.Text ><span className="category">{`${item['Text']}`}</span><span className="sub-category">{`in ${item['criteria_category']}`}</span> </Typography.Text> 
            <span style={{float: 'right'}} onClick={(e) => removeActivity(e, item, index)}>
              <CloseOutlined />
            </span> 
          </List.Item>
        )}
      />
      <div className="soa-actiity-btn-container">
        <Button onClick={addActivityToIE} className="add-soa">ADD TO {IEType}</Button>
        <br />
      </div>
      </Col>
    </Row>
      
    </div>

    <Modal 
    visible={addActivityModal} title="" closable={false} mask={false} onCancel={handleCancelActivity} footer={null} style={{ top:10 }} centered={false} maskClosable={false} wrapClassName="criteriaDetailModal">
    
    <Row className="ie-configure-title">
    <span>Additional Details</span>
    </Row>
    {/* <br/> */}
    <Row className="endpoint-modal-filed">
      <Col span={24} className="informed-consent-label">
        <Text
          ellipsis={true}
          >
            {selectedActivity['Text']}
        </Text>
      </Col>
      <Col span={24} className="procedure-label">
      <Text
          ellipsis={true}
          >
            {selectedActivity['criteria_category']}
        </Text>
      </Col>
      <Col span={24}><span><br /></span></Col>
      <Col span={8} className="label-header"><span>ENTITY FREQUENCY</span></Col>
      <Col span={8} className="label-header"><span>NUMBER OF AMENDMENTS</span></Col>
      <Col span={8} className="label-header"><span>SCREEN FAILURE RATE</span></Col>
      <Col span={8} className="label-values"> 
      { Math.round(selectedActivity['Frequency'] * 10000) / 100 + "%"}
      </Col>
      <Col span={8} className="label-values">
        <span>
        {selectedActivity['details']&&selectedActivity['details']['number_of_amendments']||"NA"}
        </span>
      </Col>
      <Col span={8} className="label-values">
        <span>
        {selectedActivity['details']&&selectedActivity['details']['sfr']?selectedActivity['details']['sfr']:'0%'}
        </span>
      </Col> 

      <Col span={24} ><span><br /></span></Col>
    </Row>
    {/* <br /> */}
    <Row className="ie-criteria-selector">
      <Col span={24}>
      <div className="header">
        <div className="criteria"></div>
        <div className="history">
          <div className="top">Historical value</div>
          <div className="bottom">
            <Button 
            style={{marginTop: 5}}
            size="small"
            onClick={onCopyToMyModifier}
            >
              Copy historical data to My Modifiers
            </Button>
          </div>
        </div>
        <div className="modifier">
          My Modifiers
        </div>
      </div>
      <Table 
      columns={newDetailColumns} 
      dataSource={detailData} 
      pagination={false}
      showHeader={false}
      rowKey={record => record.name + record.key + ''}  
      components={{
        body: {
          row: EditableDetailRow,
          cell: EditableDetailCell,
        },
      }}
      />
      </Col>
    </Row>
    
    <Row style={{justifyContent: 'right', paddingTop: '20px'}}>
      <Button className="cancel-btn" onClick={handleCancelActivity}>CANCEL</Button>
      <Button 
      className="create-btn" 
      onClick={(e) => handleOkActivity(e, modifierActivity)}
      >ADD</Button>
    </Row>
  </Modal>  
  </div>    
  )
}

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
  createSoa: (val) => dispatch(createActions.createSoa(val)),
  updateIEActivities: (val) => dispatch(createActions.updateIEActivities(val))
});
const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
  newIE: state.ieReducer
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddActivityIE));