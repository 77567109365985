/* eslint-disable */
import React, { useState, useReducer,useEffect } from "react";
import { withRouter } from "react-router";
import { Button, Row, Col, Input, Drawer, Radio, Typography, Popover, message, Switch } from "antd";
import { InfoCircleOutlined, CheckCircleFilled, CloseOutlined} from "@ant-design/icons";
import { updateStudy,getStudy, createScenario, saveTemplateScenario} from "../../utils/ajax-proxy";
import iconProtocolDesign from "../../assets/icon-protocol-design.png";
import iconSiteSelection from "../../assets/icon-site-selection.png";
import iconTrialBudgeting from "../../assets/icon-trial-budgeting.png";
import history_icon from "../../assets/history_icon.svg"; 
import { MoreOutlined } from '@ant-design/icons';
import { getEmail, getAdminRole } from "../../utils/auth";
import { roundToWholeNumber } from '../../utils/utils';
import "./index.scss";
import moment from 'moment-timezone';

const { TextArea } = Input;
const { Text } = Typography;

const initialStates = {
  scenario_id: "",
  scenario_name: "",
  scenario_description: "",
  "Inclusion Criteria": {},
  "Exclusion Criteria": {},
  "Enrollment Feasibility": {},
  "Schedule of Events": {},
  "is_template": false
};

const initialCloneStates = {
  scenario_id: "",
  scenario_name: "",
  scenario_description: "",
};

const showWarning = () => {
  message.warning("You don't have access to edit this scenario", 2);
};

const SceneriosDashbaord = (props: any) => {
  const {record, afterSave } = props;
  const isAdmin = localStorage.getItem('user_type').toUpperCase() == 'ADMIN';

  const [newScenarioVisiable, setNewScenarioVisiable] = useState(false);
  const [completeModuleVisible, setCompleteModuleVisible] = useState(false);
  const [scenarioType, setScenarioType] = useState();
  const [scenarioId, setScenarioId] = useState('');
  const [editFlag, setEditFlag] = useState(false);
  const [protocolAmendmentAvg, setProtocolAmendmentAvg] = useState('');
  const [screenFailureAvg, setScreenFailureAvg] = useState('');
  const [CostAvg, setCostAvg] = useState('');
  const [BurdenAvg, setBurdenAvg] = useState('');
  const [CostAvgValue, setCostAvgValue] = useState('');
  const [scenario, setScenario] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...initialStates }
  );
  const [scenarioList, setScenarioList] = useState([])
  const [scenarioNameError, setScenarioNameError] = useState("");
  const [scenarioDesError, setScenarioDesError] = useState("");
  const [rationaleMsgError, setRationaleMsgError] = useState("Rationale must not exceed 500 characters");
  const [cloneScenarioObj, setCloneScenarioObj] =  useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...initialCloneStates }
  );
  const [cloneScenario, setCloneScenario] = useState(false)
  const [cloneScenarioDisplayMessages, setCloneScenarioDisplayMessages] = useState({title: 'Copy and Create New Scenario', message: 'Scenario Names must be unique. Please modify before creating a new scenario'})
  const [readOnlyUser, setReadOnlyUser] = useState(false);
  const [fromTemplate, setFromTemplate] = useState(false);
  const [isCreateDrawer, setIsCreateDrawer] = useState(false);
  const [visible, setVisible] = useState(false)
  const [popoverStates, setPopoverStates] = useState([]);

  const PopoverClick = (e, s, clone) => {
    e.stopPropagation();
    if(!clone || readOnlyUser) return;
    editScenario(s);
    setCloneScenario(true)
    setIsCreateDrawer(false)
    const newScenarioId = (props.record.scenarios.length + 1);
    setCloneScenarioObj({
      ['scenario_id']: newScenarioId,
      ['scenario_name']: s['scenario_name'],
      ['scenario_description']: s['scenario_description'],
      scenario_type: s['scenario_type'],
    });
  }

  const onSaveTemplateScenario = async(e, s, idx) => {
    e.stopPropagation();
    let requestData = {
      "trial_uuid": record['trial_uuid'],
      "scenario": s,
      "user_name":  getEmail()
    }
    handlePopoverVisibleChange(idx, false)
    const resp = await saveTemplateScenario(requestData);
    if (resp.state ==  "Success") {
      afterSave(record['trial_uuid'])
    }
  }

  useEffect(() => {

    return () => {
      message.destroy();
    };
  }, []);

  const handleVisibleChange = (val) => {
    setVisible( val )
  }

  const getScenarioInfo = (record) => { 
    if(record?.template_details?.length == 0) return;
    const { template_details: { created_by, scenario_name, trial_alias, updated_by, updated_on } } = record;
     
    const content = (
      <div>
        <p> <span style={{display: 'inline-block', width: '150px'}}>Trial Alias: </span>{trial_alias}</p>
        <p> <span style={{display: 'inline-block', width: '150px'}}>Scenario Name: </span>{scenario_name}</p>
        <p> <span style={{display: 'inline-block', width: '150px'}}>Scenario Created By: </span>{created_by}</p>
        <p> <span style={{display: 'inline-block', width: '150px'}}>Last Updated By: </span>{updated_by} </p>
        <p> <span style={{display: 'inline-block', width: '150px'}}>Last Updated On: </span>{moment(new Date(updated_on)).format('YYYY-MM-DD')}</p> 
      </div>
    )
    return content
  }
  const handlePopoverVisibleChange = (index, visible) => { 
    const newStates = [...popoverStates];
    newStates[index] = visible;
    setPopoverStates(newStates);
  };

  const getContent = (s, idx) => {
    const  content = (
      <div>
        {
          isAdmin && !s.is_template && <div style={{cursor:readOnlyUser ? 'auto' : "pointer",'paddingBottom': '6%'}} onClick={(e) => onSaveTemplateScenario(e, s, idx)}>Save as Template Scenario</div>
        }
        <div
        style={{cursor:`${props.record.status.toLowerCase() === 'updating'?"not-allowed":(readOnlyUser ? 'not-allowed' : "pointer")}`}}
        onClick={(e) => {
          if(props.record.status.toLowerCase() === 'updating')return
          PopoverClick(e, s, true);
          handlePopoverVisibleChange(idx, false)
          }}>
          Copy and Create New Scenario
        </div>
      </div>
    );
    return content
  }

  useEffect(() => {
    setScenarioList(JSON.parse(JSON.stringify(props.record.scenarios)))
    if(props.record.therapeutic_area_average != undefined){
      let protocol_amendment_avg = props.record.therapeutic_area_average.protocol_amendment_rate ?? '';
      let screen_failure_avg = props.record.therapeutic_area_average.screen_failure_rate === null? 'N/A': props.record.therapeutic_area_average.screen_failure_rate?? '';
      let cost_avg = props.record.therapeutic_area_average.cost_avg != undefined?
                                props.record.therapeutic_area_average.cost_avg : '';
      let burden_avg = props.record.therapeutic_area_average.burden_avg != undefined?
                                roundToWholeNumber(Number(props.record.therapeutic_area_average.burden_avg.toString().match(/^\d+(?:\.\d{0,2})?/)))+'' : '';
      setProtocolAmendmentAvg(protocol_amendment_avg)
      setScreenFailureAvg(screen_failure_avg)
      setCostAvg(cost_avg)
      setBurdenAvg(burden_avg)
      setCostAvgValue(formatCostAvg(cost_avg,1000))
    }
    if(props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') {
      setReadOnlyUser(true)
     }else{
      setReadOnlyUser(false)
     }
  }, [props.record.scenarios])

  function roundFun(value, n) {
      return Math.round(value*Math.pow(10,n))/Math.pow(10,n);
  }

  function formatCostAvg(totalCost, divisor){
    if(totalCost === undefined || totalCost === null || totalCost === ''){
      return ""
    }else if(totalCost === 0){
      return 0 + 'K'
    } else {
      let avg = Math.ceil(totalCost/divisor*1000)
      return '$' + roundFun(avg/1000, 2) + 'K'
    }
  }

  const editScenario = (s) =>{
    setScenarioId(s['scenario_id'])
    setScenario(s);
    setScenarioType(s['scenario_type'])
    setNewScenarioVisiable(true)
    setEditFlag(true)
  }
  const addNewScenario =(scenarioType) => {
    if( props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') return;
    const newScenarioId = '' + (props.record.scenarios.length + 1)
    setScenarioId(newScenarioId)
    setScenario({
      ['scenario_id']: newScenarioId,
      ['scenario_type']:scenarioType
    });
    setScenarioType(scenarioType)
    setNewScenarioVisiable(true)
    setEditFlag(false)
    setIsCreateDrawer(true);
  }
  const viewScenario = (s) =>{
    setIsCreateDrawer(false);
    props.handleViewScenario(s['scenario_id'])
  }

  const [duplicateScenarioError, setDuplicateScenarioError] = useState('')

  const readOnlyUserHandler = () => {
    props.history.push({
      pathname: '/scenario',
      state: {
        trial_id: props.record['trial_uuid'] ,
        scenarioId: editFlag && cloneScenario ? cloneScenarioObj['scenario_id'] : scenarioId,
        editFlag: editFlag,
        scenarioType: scenarioType,
        similarHistoricalTrials: props.record['similarHistoricalTrials']
      }
    })
    setNewScenarioVisiable(false);
    setDuplicateScenarioError('');
    setCloneScenario(false)
  }

   // This needs to be knocked out once the new UI for I/E and SOA are done
   const navigateOldUI = () => {
    
    props.history.push({
      pathname: '/old-scenario',
      state: {
        trial_id: props.record['trial_uuid'] ,
        scenarioId: editFlag && cloneScenario ? cloneScenarioObj['scenario_id'] : scenarioId,
        editFlag: editFlag,
        scenarioType: scenarioType,
        similarHistoricalTrials: props.record['similarHistoricalTrials']
      }
    })
    setNewScenarioVisiable(false);
    setDuplicateScenarioError('');
    setCloneScenario(false)
}

  const handleOk = async () => {
    let templateScenario, method;
    if(!doValidation() || !doDesValidation()) return
    let currentScenario, newCloneScenario;
    if(editFlag && !cloneScenario){
      currentScenario =  scenario;
      method = "UpdateScenario"
    } else if(editFlag && cloneScenario) {
      currentScenario =  scenario
      newCloneScenario = cloneScenarioObj
      method = "CloneScenario"
    }else {
      method = "CreateScenario"
      currentScenario = {
          scenario_id: scenario['scenario_id'],
          scenario_name: scenario['scenario_name'],
          scenario_description: scenario['scenario_description'],
          scenario_type: scenario['scenario_type'],
          "Inclusion Criteria": {},
          "Exclusion Criteria": {},
          "Enrollment Feasibility": {},
          "Schedule of Events": {},
        }
    }

      if(fromTemplate) {
        method = "CreateFromTemplate"
        templateScenario = record.scenarios.find(s=>s.is_template);
        currentScenario =  templateScenario ? JSON.parse(JSON.stringify(templateScenario)): {}
        currentScenario.is_template = false
        currentScenario["Inclusion Criteria"] = {}
        currentScenario["Exclusion Criteria"] = {}
        currentScenario["Enrollment Feasibility"] = {}
        currentScenario["Schedule of Events"] = {}
        currentScenario.ts_id = record.template_ts_id

        newCloneScenario = {
          ['scenario_id']: (props.record.scenarios.length + 1),
          ['scenario_name']: scenario['scenario_name'],
          ['scenario_description']: scenario['scenario_description'],
          scenario_type: scenario['scenario_type'],
        }
      }

      const tempTrial = props.record
      let requestData = {
      "trial_uuid": tempTrial['trial_uuid'],
      "scenario": currentScenario,
      "new_scenario": newCloneScenario,
      "user_name":  getEmail()
      }

      const resp = await createScenario(requestData, method);      
      if (resp.statusCode == 200) {
          localStorage.setItem('ts_id', resp.ts_id)
          props.history.push({
            pathname: '/scenario',
            state: {
              trial_id: props.record['trial_uuid'] ,
              scenarioId: resp.ts_id,
              editFlag: editFlag,
              scenarioType: scenarioType,
              similarHistoricalTrials: props.record['similarHistoricalTrials'],
              scenario_name: scenario['scenario_name']
            }
          })
          setNewScenarioVisiable(false);
          setDuplicateScenarioError('');
          setCloneScenario(false)
      } else if (resp.statusCode == 409) {
        localStorage.setItem('ts_id', null)
        setDuplicateScenarioError('This scenario name already exists, please enter a unique name!');
      } else {
        localStorage.setItem('ts_id', null)
        setDuplicateScenarioError('');
      }
  }


  const handleCancel = () =>{
      setNewScenarioVisiable(false)
      setScenario(initialStates)
      setScenarioNameError("")
      setDuplicateScenarioError('');
      setScenarioDesError("")
      setCloneScenario(false)
  }

  const handleInputChange = (key, e) => {
    if(cloneScenario) {
      setCloneScenarioObj({
        [key]: e.target.value,
      })
      } else {
    setScenario({
      [key]: e.target.value,
    });
    }

    if(key == "scenario_name" && e.target.value.trim().length > 100){
      setScenarioNameError("Scenario Name must not exceed 100 characters.")
    } else if (key == "scenario_name" && e.target.value.trim().length > 0 && e.target.value.trim().length < 101){
      setScenarioNameError("")
    }
  };

  const handleCheck = (e, idx) => {
    const tmpList = scenarioList.slice(0)
    if (e.target.checked) {
      tmpList.forEach(ele =>
        ele.finalized = false
      )
      tmpList[idx].finalized = true
    } else {
      tmpList[idx].finalized = false
    }
    setScenarioList(tmpList)
  }


  const onRationaleChange = (e, idx) => {
    const tmpList = scenarioList.slice(0)
    tmpList[idx].rationale = e.target.value
    setScenarioList(tmpList)

  }
  const showCompleteModule = (type) => {
    if( props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') return;
    setCompleteModuleVisible(true)
    setScenarioType(type)
    props.history.push({
       pathname: '/compare-scenario',
       state: {
         trial_id: props.record['trial_uuid'] ,
         scenarios: scenarioList,
         record: props.record
       }
      })
  }

  const handleCompleteModule = async() => {
    if( props.record && props.record.trial_user_role && props.record.trial_user_role.toLowerCase() == 'read') return;
    let errorExists = false;
    scenarioList && scenarioList.length > 0 &&  scenarioList.filter(scenario => scenario["finalized"]).map((scenario, index) =>  errorExists = scenario["rationale"] && scenario.rationale.length > 500 ? true : false)
    if(errorExists) return
    const tempTrial =JSON.parse(JSON.stringify(props.record))
    tempTrial.scenarios = scenarioList
    tempTrial.status="Completed"
    const trialId=tempTrial["trial_uuid"]
    const resp = await updateStudy(tempTrial);
    if (resp) {
      props.updateRecord(tempTrial)
      const study = await getStudy(trialId, null, getEmail()) 
      if (study) {
        const newScenarios = study.scenarios
        setScenarioList(newScenarios)
        setCompleteModuleVisible(false)
      }
    } else{
      message.error("Failed to complete module");
    }
  }

  const cancelCompleteModule = () => {
    setCompleteModuleVisible(false)
    const tmpList = scenarioList.slice(0)
    if(tmpList) {
      tmpList.forEach(ele =>
        ele.finalized = false
      )
      setScenarioList(tmpList)
    }



  }

  const handleValidation = (key, v) => {
    let errorMsg = "";

    let scenarioObj = scenario;
    if(cloneScenario){
       scenarioObj = cloneScenarioObj;
    }
    if(scenarioObj["scenario_name"].trim().length < 1){
        errorMsg = "Scenario Name is required."
    } else if (scenarioObj["scenario_name"].trim().length > 100){
      errorMsg = "Scenario Name must not exceed 100 characters."
    }
    if(key == "scenarioName"){setScenarioNameError(errorMsg)}
    return errorMsg == ""
  }

  const handleDesValidation = (key, v) => {
    let errorMsg = "";

    let scenarioObj = scenario;
    if(cloneScenario){
       scenarioObj = cloneScenarioObj;
    }
    if (scenarioObj["scenario_description"].trim().length > 500){
      errorMsg = "Description must not exceed 500 characters."
  }
    if(key == "scenarioDescription"){setScenarioDesError(errorMsg)}
    return errorMsg == ""
  }

  const doValidation = () => {
    let reqFieldsEntered = true
    if(!handleValidation("scenarioName", null)){
      reqFieldsEntered = false
  }
    return reqFieldsEntered;
  }

  const doDesValidation = () => {
    let reqFieldsEntered = true
    if(!handleDesValidation("scenarioDescription", null)){
      reqFieldsEntered = false
  }
    return reqFieldsEntered;
  }
 function emptyMetricsValidation(scenarios)
  {
    for(let i=0;i<scenarios.length;i++)
    {
    if (scenarios[i]['protocol_amendment_rate'] === null)
    {
    scenarios[i]['protocol_amendment_rate'] = ''
    scenarios[i]['protocol_amendment_rate_state'] = ''
    }
    if (scenarios[i]['screen_failure_rate'] === null)
    {
    scenarios[i]['screen_failure_rate'] = ''
    scenarios[i]['screen_failure_rate_state'] = ''
    }
    if (scenarios[i]['dct_patient_burden'] === null){
    scenarios[i]['dct_patient_burden'] = ''
    scenarios[i]['dct_patient_burden_state'] = ''
    }
    if (scenarios[i]['cost'] === null){
    scenarios[i]['cost'] = ''
    scenarios[i]['cost_state']  = ''
    }
    }
  }
  // const noEdit = props.record.scenarios.find(sce=>sce.rationale!=undefined)
  const noEdit = props.record.status === "Completed"
  if (props.record.scenarios && props.record.scenarios.length > 0){
      emptyMetricsValidation(props.record.scenarios)
  }

  const btnText = () => {
    let content;
    if(readOnlyUser) {
      content = "VIEW SCENARIO"
    } else   if(!isAdmin && scenario?.is_template && !cloneScenario){
      content = "VIEW SCENARIO"
    } else   if(isAdmin && scenario?.is_template && cloneScenario){
      content = "CREATE SCENARIO"
    } else   if(isAdmin && scenario?.is_template && !cloneScenario){
      content = "UPDATE SCENARIO"
    } else   if(editFlag && !cloneScenario){
      content = "UPDATE SCENARIO"
    } else if(editFlag && cloneScenario) {
      content = "CREATE SCENARIO"
    } else {
      content = "CREATE SCENARIO"
    }
    return content;
  }

  return (
    <div className="scenarios-container">
      <div className="container-top">What would you like to explore today?</div>
      <div className="module-wrapper">
        <div className="module-item">
          <div className="top">
            <div className="icon-placeholder">
              <img src={iconProtocolDesign} alt="" width="28px" height="28px" />
            </div>
            <div className="module-name">
              Protocol Design
              <span className="scenario-status">
                <i className={`${props.record.status!=="Completed"?"in-progress-icon":'complete-icon'} my_icon`}></i>
                {props.record.status}
              </span>
            </div>
            {props.record.scenarios && props.record.scenarios.length > 0 ? (
              <>
              {
                (props.record.status!="Completed") &&(
                  <div>
                  <Button
                    className="complete-module-btn"
                    type="link"
                    onClick={() => showCompleteModule("Protocol Design")}
                    disabled={props.record.status.toLowerCase() === 'updating' || readOnlyUser}
                  >
                    COMPARE / COMPLETE SCENARIO
                  </Button>
                  <Button
                    danger
                    onClick={() => addNewScenario("Protocol Design")}
                    disabled={props.record.status.toLowerCase() === 'updating'|| readOnlyUser}
                  >
                    CREATE SCENARIO
                  </Button>
                </div>
                )
              }
              </>
            ) : (
              <Button
                type="primary"
                onClick={() => addNewScenario("Protocol Design")}
                disabled={props.record.status?.toLowerCase() === 'updating'||localStorage.getItem('trial-id')===''}
              >
                START MODULE
              </Button>
            )}
          </div>
          {props.record.scenarios && props.record.scenarios.length > 0 ?
            (
              <div className="scenarios-list-container">
                <div className="scenarios-list">
                  <div className="scenario-item scenario-header">
                    <div className="title">
                      Scenarios (<span>{props.record.scenarios.length}</span>)
                    </div>
                    <div className="item-values col-names">
                      <div>
                        <p className="col-title">Protocol Amendments </p>
                        <p className="col-description">Percent of criteria associated with historical amendments</p>
                      </div>
                      <div>
                      <p className="col-title">Screen Failure Risk</p>
                        <p className="col-description">Criteria associated with high historical screen failure</p>
                      </div>
                      <div>
                        <p className="col-title">Patient Burden</p>
                        <p className="col-description">Expected burden per patient</p>
                      </div>
                      <div>
                        <p className="col-title">Cost</p>
                        <p className="col-description">Expected cost per patient</p>
                      </div>
                      <div>
                        <p className="col-title">Total Cost</p>
                        <p className="col-description">Expected cost for all patients</p>
                      </div>
                      <span className="clone_container"></span>

                    </div>
                  </div>
                  {noEdit==false ?(props.record.scenarios.map((s, idx) => {
                    const isTemplate = s?.is_template
                    return (
                      <div
                        className="item-wrapper"
                        style={{cursor:`${props.record.status.toLowerCase() === 'updating'?"not-allowed":"pointer"}`}}
                        key={s["scenario_id"] + idx}
                        onClick={() => {
                          if(props.record.status.toLowerCase() === 'updating')return
                          editScenario(s)
                          setIsCreateDrawer(false);
                        }}
                      >
                        <div className="scenario-item">
                          <div className="title">
                            <div className="scenario-item-wrapper">
                              <Text
                                style={{maxWidth: 180}}
                                className="trial_name"
                                ellipsis={{tooltip:s["scenario_name"]}}
                              >
                                {s["scenario_name"]}
                              </Text>
                              {!!s.finalized && (
                                  <div className="scenario_status-wrapper">
                                  <CheckCircleFilled style={{ fontSize: '17px', color: '#18D300'}} />
                                  <div className="scenario_status">Finalized</div>
                                </div>
                              )}
                            </div>
                            {isTemplate? ( <div className="template-tag">* Template Scenario</div> ):null}
                            <div>
                              <Text
                                style={{width: 290}}
                                className="trial_des"
                                ellipsis={{tooltip:s["scenario_description"]}}
                              >
                                {s["scenario_description"]}
                              </Text>
                            </div>

                            {s.finalized && (
                              <div className="rationale-content">
                                <p>Rationale</p>
                                <Text
                                    style={{width: 150}}
                                    ellipsis={{tooltip:s['rationale']}}
                                  >
                                    {s['rationale']}
                                </Text>
                              </div>
                            )}
                          </div>
                          <div className="item-values">
                            <div>
                              {s["protocol_amendment_rate"]}
                              {/* <span className={`status ${s["protocol_amendment_rate_state"]}`}>{s["protocol_amendment_rate_state"]}</span> */}
                              {/* <span style={{fontSize: 22}}>Future Module</span> */}
                            </div>
                            <div>
                              {s["screen_failure_rate"]!== null?s["screen_failure_rate"]:""}
                               <span className={`status ${s["screen_failure_rate_state"]}`}>{s["screen_failure_rate"]!== null?s["screen_failure_rate_state"]:""}</span>
                            </div>
                            <div>
                              {s["dct_patient_burden"]!== null ?  (<span>{roundToWholeNumber(s["dct_patient_burden"])}</span>): ""}
                               <span className={`status ${s["dct_patient_burden_state"]}`}>{s["dct_patient_burden_state"]}</span>
                            </div>
                            <div>
                            <span>{formatCostAvg(s["cost"],1000)}</span>
                               <span className={`status ${s["cost_state"]}`}>{s["cost_state"]}</span>
                            </div>
                            <div>
                              <span>{formatCostAvg(s["total_enrollment_cost"],1000)}</span>  
                            </div>

                            <span className="clone_container">
                              <Popover placement="bottom"  content={getContent(s, idx)} 
                                visible={popoverStates[idx] || false}
                                onVisibleChange={(visible) => handlePopoverVisibleChange(idx, visible)}
                              >
                                <MoreOutlined onClick={(e) => e.stopPropagation()}/>
                              </Popover>
                         </span>

                            {/* <div>
                              {
                                noEdit==undefined ? (
                                  <Button
                                    size="small"
                                    onClick={() => editScenario(s)}
                                  >
                                    EDIT SCENARIO
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    onClick={() => viewScenario(s)}
                                  >
                                    VIEW SCENARIO
                                  </Button>
                                )
                              }
                            </div> */}
                          </div>

                        </div>
                      </div>
                    );
                  })):(props.record.scenarios.map((s, idx) => {
                    return (
                      <div className="item-wrapper" style={{cursor:"pointer"}} onClick={() => viewScenario(s)} key={s["scenario_id"] + idx}>
                        <div className="scenario-item">
                          <div className="title">
                            <div className="scenario-item-wrapper">
                              <Text
                                style={{width: 180}}
                                className="trial_name"
                                ellipsis={{tooltip:s["scenario_name"]}}
                              >
                                {s["scenario_name"]}
                              </Text>
                              {!!s.finalized && (
                                  <div className="scenario_status-wrapper">
                                  <CheckCircleFilled style={{ fontSize: '17px', color: '#18D300'}} />
                                  <div className="scenario_status">Finalized</div>
                                </div>
                              )}
                            </div>
                            {s.is_template? ( <div className="template-tag">* Template Scenario</div> ):null}
                            <div>
                              <Text
                                style={{width: 150}}
                                className="trial_des"
                                ellipsis={{tooltip:s["scenario_description"]}}
                              >
                                {s["scenario_description"]}
                              </Text>
                            </div>

                            {s.finalized && (
                              <div className="rationale-content">
                                <p>Rationale</p>
                                <Text
                                    style={{width: 150}}

                                    ellipsis={{tooltip:s['rationale']}}
                                  >
                                    {s['rationale']}
                                </Text>
                              </div>
                            )}
                          </div>
                          <div className="item-values">
                            <div>
                              {s["protocol_amendment_rate"]}
                              <span className={`status ${s["protocol_amendment_rate_state"]}`}>
                                {/* {s["protocol_amendment_rate_state"]} */}
                                </span>                          
                              {/* <span style={{fontSize: 22}}>Future Module</span> */}
                            </div>
                            <div>
                              {s["screen_failure_rate"]!== null?(<span>{s["screen_failure_rate"]}</span>):""}
                               <span className={`status ${s["screen_failure_rate_state"]}`}>{s["screen_failure_rate_state"]}</span>
                               {/* {s["screen_failure_rate"]==="0%"?"":s["screen_failure_rate"]}
                               <span className={`status ${s["screen_failure_rate_state"]}`}>{s["screen_failure_rate"]==="0%"?"":s["screen_failure_rate_state"]}</span> */}
                            </div>
                            <div>
                              {s["dct_patient_burden"]!== null?(<span>{roundToWholeNumber(s["dct_patient_burden"])}</span>):""}
                               <span className={`status ${s["dct_patient_burden_state"]}`}>{s["dct_patient_burden_state"]}</span>
                            </div>
                            <div>
                            <span>{formatCostAvg(s["cost"],1000)}</span>
                               <span className={`status ${s["cost_state"]}`}>{s["cost_state"]}</span>
                            </div>
                            <div>
                            <span>{formatCostAvg(s["total_enrollment_cost"],1000)}</span> 
                            </div>
                              <span className="clone_container"></span>
                          </div>

                        </div>
                      </div>
                    );
                  }))}
                </div>
              </div>
            )
           : (
            <div className="bottom">Design Inclusion / Exclusion Criteria and Schedule of Events</div>
          )}
          {
             props.record.scenarios.length>0 && props.record['therapeutic_area_average'] !==undefined && (
              <div className="item-wrapper average-item">
                <div className="scenario-item">
                  <div className="title average-title" style={{width: 300}}>
                    Similar Historical Trials &nbsp;<InfoCircleOutlined className="material-info"/>
                  </div>
                  <div className="item-values average">
                    {/* 'Future Module' */}
                    {/* <div>{protocolAmendmentAvg}</div> */}
                    <div></div>
                    <div>{screenFailureAvg}</div>
                    <div>{BurdenAvg}</div>
                    <div>{CostAvgValue}</div>
                    <div></div>
                    <span className="clone_container"></span>
                    {/* <div></div> */}
                  </div>
                </div>
              </div>
            )
          }
        </div>

        <div className="module-item" style={{backgroundColor:"#F2F2F2"}}>
          <div className="top">
            <div className="icon-placeholder">
              <img src={iconSiteSelection} alt="" width="28px" height="28px" />
            </div>
            <div className="module-name">Country / Site Selection</div>
            <Button style={{cursor:"not-allowed", backgroundColor:"#CCCCCC", borderColor:"#CCCCCC", color: "#fff"}}>
              FUTURE MODULE
            </Button>
          </div>
          <div className="bottom">Select countries to support study strategy and execution</div>
        </div>

        <div className="module-item" style={{backgroundColor:"#F2F2F2"}}>
          <div className="top">
            <div className="icon-placeholder">
              <img src={iconTrialBudgeting} alt="" width="28px" height="28px" />
            </div>
            <div className="module-name">Trial Budgeting</div>
            <Button style={{cursor:"not-allowed", backgroundColor:"#CCCCCC", borderColor:"#CCCCCC", color: "#fff"}}>
              FUTURE MODULE
            </Button>
          </div>
          <div className="bottom">Select countries to support study strategy and execution</div>
        </div>

      </div>

      <Drawer
        title={!cloneScenario ? scenarioType + " - Scenario Builder" : `${cloneScenarioDisplayMessages.title} - ${cloneScenarioObj["scenario_name"] }` }
        placement="right"
        closable={true}
        onClose={handleCancel}
        visible={newScenarioVisiable}
        footer={[
          <Button
            key="cancel"
            type="text"
            onClick={handleCancel}
          >
            CANCEL
          </Button>,
          <Button key="submit" type="primary" onClick={readOnlyUser ? readOnlyUserHandler : handleOk} className="create-update-btn">
            {btnText()}
          </Button>,
        //    <Button key="submit" type="primary" onClick={readOnlyUser ? navigateOldUI : handleOkOldUI} className="create-btn">
        //     SCENARIO OLD 
        //  </Button>,
        ]}
      >
        <Row style={{ minHeight: "300px" }}>
          <Col span={24}>
            <Row>
              <h5>Scenario Details</h5>
            </Row>
            <br />
           {cloneScenario && ( <><Row>
              <div style={{backgroundColor:'#F9EAD4', color: '#933401', width: '100%', padding: '2%'}}>{cloneScenarioDisplayMessages.message}</div>
            </Row>
            <br />
            </>
           )}
          {readOnlyUser || (!isAdmin && scenario?.is_template && !cloneScenario) && ( <><Row>
              <div style={{backgroundColor:'#F9EAD4', color: '#933401', width: '100%', padding: '2%'}}>You don't have access to edit this scenario</div>
            </Row>
            <br />
            </>
           )}
            <Row>
              <span>Scenario Name </span>
              <span style={{ color: "#d04a02", fontSize: 12, marginTop: 7, lineHeight: '12px'}}>&nbsp;*</span>
            </Row>
            <Row>
              <Input
                onChange={(e) => handleInputChange("scenario_name", e)}
                value={cloneScenario ? cloneScenarioObj["scenario_name"] : scenario["scenario_name"]}
                // maxLength={100}
                onBlur={(v) => handleValidation("scenarioName", v)}
                disabled = {readOnlyUser || (!isAdmin && scenario?.is_template && !cloneScenario)}
              />
              <br/>
                <span className="name-error" style={{ color: "#d04a02"}}>{scenarioNameError}</span>
                {duplicateScenarioError && duplicateScenarioError.length > 0 && ( <span className="name-error" style={{ color: "#d04a02"}}>{duplicateScenarioError}</span>)}

            </Row>
            <br />
            <Row>
              <span>Description</span>
            </Row>
            <Row>
              <TextArea
                value={cloneScenario ? cloneScenarioObj["scenario_description"] : scenario["scenario_description"]}
                onChange={(e) => handleInputChange("scenario_description", e)}
                autoSize={{ minRows: 3, maxRows: 5 }}
                // maxLength={500}
                onBlur={(v) => handleDesValidation("scenarioDescription", v)}
                disabled = {readOnlyUser || (!isAdmin && scenario?.is_template && !cloneScenario)}
              />
              <br/>
              <span className="name-error" style={{ color: "#d04a02"}}>{scenarioDesError}</span>
            </Row>
            {
              record.template_ts_id && isCreateDrawer? (
                <Row className="history-container">
                  <Col span={22}>
                    <div className="switch-container">
                    Would you like to copy content from Template Scenario?
                    <Switch checked={fromTemplate} onChange={(checked)=>setFromTemplate(checked)} />
                  </div></Col>
                  <Col span={2}>
                 
                  <Popover 
                    content={getScenarioInfo(record)} 
                    title={<>An admin user has designated this scenario as the Template Scenario for the current indication <CloseOutlined style={{paddingTop: '5px', float: 'right', cursor: 'pointer'}}onClick={()=> setVisible(false)}/> </>}
                    trigger="click"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                  >
                    <div>
                    <img src={history_icon} alt="" className="history_icon" width="32px" height="32px"/>
                    </div>
                  
                  </Popover>
                  </Col>
                 
                </Row>
              ):null
            }
          </Col>
        </Row>
      </Drawer>

      <Drawer
        className="complete-module-drawer"
        title={"Complete Module:" + scenarioType}
        placement="right"
        onClose={() => setCompleteModuleVisible(false)}
        visible={completeModuleVisible}
        footer={[
          <Button
            key="cancel"
            type="text"
            onClick={cancelCompleteModule}
            style={{ float: "left" }}
          >
            CANCEL
          </Button>,
          <Button key="update"
            type="primary"
            className="submit-complete-btn"
            onClick={handleCompleteModule}
            disabled={scenarioList.findIndex(s=>s.finalized)<0}
          >
            COMPLETE MODULE
          </Button>,
        ]}
      >
        <div className="module-tip">
          Note: Once marked as completed, you will not be able to edit this
          trial further.
        </div>
        <div className="select-scenario-wrapper">
          <div className="scenario-table-header">
            <div className="scenario-col scenario-name">Select Scenario</div>
            <div className="scenario-col scenario-rate">Protocol Amendment Risk</div>
            <div className="scenario-col scenario-rate">Screen Failure Risk</div>
            <div className="scenario-col scenario-rate">Patient Burden</div>
            <div className="scenario-col scenario-rate">Cost</div>
          </div>
          <div className="scenario-table-body">
            {scenarioList.map((scenario, idx) => {
              return (
                <div key={`scenario_${idx}`}
                  className={`scenario-table-row-wrapper ${
                    scenario.hasOwnProperty("rationale") ? "checked" : ""
                  }`}
                >
                  <div className="scenario-table-row">
                    <div
                      className={`scenario-col scenario-name ${
                        scenario.hasOwnProperty("rationale") ? "checked" : ""
                      }`}
                    >
                      <div>
                        <Radio
                          checked={scenario.finalized}
                          onChange={(e) => handleCheck(e, idx)}
                        >
                          <Text
                            style={{maxWidth: 120}}
                            className="scenario-name-checkbox"
                            ellipsis={{tooltip:scenario['scenario_name']}}
                          >
                            {scenario["scenario_name"]}
                          </Text>
                        </Radio>
                      </div>
                      <span className="scenario-desc">
                        <Text
                          style={{maxWidth: 120}}
                          className="scenario-des-checkbox"
                          ellipsis={{tooltip:scenario["scenario_description"]}}
                        >
                          {scenario["scenario_description"]}
                        </Text>
                      </span>
                    </div>
                    <div className="scenario-col" style={{fontSize: 24}}>
                      {scenario["protocol_amendment_rate"]}
                      {/* Future Module */}
                       <span className={`status ${scenario["protocol_amendment_rate_state"]}`}>
                         {/* {scenario["protocol_amendment_rate_state"]} */}
                         </span>

                    </div>
                    <div className="scenario-col">
                      {scenario['screen_failure_rate']}
                      <span className={`status ${scenario["screen_failure_rate_state"]}`}> {scenario['screen_failure_rate_state']}</span>
                    </div>
                    <div className="scenario-col">
                      {roundToWholeNumber(scenario['dct_patient_burden'])}
                     <span className={`status ${scenario["dct_patient_burden_state"]}`}> {scenario['dct_patient_burden_state']}</span>
                    </div>
                    <div className="scenario-col">
                      {typeof scenario["cost"] === "number"?`$${scenario["cost"]}`:``}
                       <span className={`status ${scenario["cost_state"]}`}>{scenario['cost_state']}</span>
                    </div>
                  </div>
                  {scenario.finalized ? (
                    <div className="rationale-mark">
                      <span>Provide Rationale</span>
                      <div>
                        <TextArea
                          value={scenario.rationale}
                          onChange={(e) => onRationaleChange(e, idx)}
                          autoSize={{ minRows: 3, maxRows: 5 }}
                          // maxLength={500}
                          allowClear
                        />
                      </div>
                      {scenario.rationale && scenario.rationale.length > 500 ? (<span style={{ color: "#d04a02"}}>{rationaleMsgError}</span>) : ''}
                      <span></span>
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default withRouter(SceneriosDashbaord);
