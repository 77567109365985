/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router";
import { Row, Col, message, Table } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ReactECharts from 'echarts-for-react';
import { getTrialSoaInsights } from "../../utils/ajax-proxy";
import SoaAtGlance from '../../components/SoaAtGlance';
import ActivitiesByModality from '../../components/ActivitiesByModality';
import './index.scss';
import { roundToTwoDecimals } from '../../utils/utils';

const ChartSummary = (props:any) => {

  const { location: { state: { record, trial_id, visits, modalityChartData, totalBurdenStatus, burdenChartColor, totalBurden, burdenSubTitle, labelColors, burdenData, showTooltip, chartWidthSmaller, burdenXAxis }}} = props;


  const [ DCTonPB, setDCTonPB ] = useState({ pb: { total_dct_pb: 0,total_pb: 0 }, pb_insights_data: []})
  const [ PBbyCategory, setPBbyCategory ] = useState({ pb: { total_dct_pb: 0,total_pb: 0, total_historic_pb: 0 }, pb_insights_data: []})
  const [ DCTonPBInsightsData, setDCTonPBInsightsData ] = useState([])
  const [ PBbyCategoryInsightsData, setPBbyCategoryInsightsData ] = useState([])
  const [ costPerPatientByCategoryData, setCostPerPatientbyCategoryData ] = useState([])
  const [ countOfSOAByCategoryData, setCountOfSOAByCategoryData ] = useState([])
  const [ PBVisit, setPBVisit ] = useState( { total_dct_pb_per_visit: [], total_dct_pb: 0, sht_total_pb_median: 0, sht_total_pb_median_first_visit: 0, 
    sht_total_pb_median_visits: 0, dct_pb_state: ""})
    const [ dimensionBurden, setDimensionBurden ] = useState([]);

  useEffect(() => {
    fetchChartData()
  }, [])

  const fetchChartData = async () => { 
   
    const res = await getTrialSoaInsights(trial_id, record['scenarios'][0]['scenario_id'],"Category", true, true)
    if (res  && Object.keys(res).length !== 0) {
      const response = res
      setPBbyCategory(response)
      setPBbyCategoryInsightsData(response['pb_insights_data'])
    }else{
      message.error('Failed to fetch chart data')
    }

    const resp = await getTrialSoaInsights(trial_id, record['scenarios'][0]['scenario_id'],"Dimension", true, false)
    if (resp  && Object.keys(resp).length !== 0) {
      const response = resp
      const insightData = response['pb_insights_data']?.map((item, index)=>{
        const diff = item.patient_burden - item.dct_patient_burden
        item.patient_burden = diff
        return item
      })
      setDCTonPB(response)
      setDCTonPBInsightsData(padArrayToLength(insightData, 8))
    }else{
      message.error('Failed to fetch chart data')
    }

    const resd = await getTrialSoaInsights(trial_id, record['scenarios'][0]['scenario_id'],"CountOfSOAByCategory", false, true)
    if (resd  && Object.keys(resd).length !== 0) {
      const response = resd
      setCountOfSOAByCategoryData(response['soa_by_category'])
    }else{
      message.error('Failed to fetch chart data')
    }

    const resdvisit = await getTrialSoaInsights(trial_id, record['scenarios'][0]['scenario_id'],"Visit", false, true)
    if (resdvisit  && Object.keys(resdvisit).length !== 0) {
      const response = resdvisit;
      setPBVisit(response['pb_insights_data'])      
    }else{
      message.error('Failed to fetch chart data')
    }

    const respDimension = await getTrialSoaInsights(trial_id, record['scenarios'][0]['scenario_id'], "dimensionWithHist", false, true)
    if (respDimension  && Object.keys(respDimension).length !== 0) {
      const response = respDimension;
      setDimensionBurden(response['pb_insights_data'])
    }else{
      message.error('Failed to fetch chart data')
    }
  }

  const padArrayToLength = (arr, length)=> {
    if (arr === undefined) return
    if (arr.length >= length) {
      return arr; 
    }
    const newArray = [...arr]; 
    while (newArray.length < length) {
      newArray.push({
        soa_insight_type: "Dimension",
        dimension_name: "",
        patient_burden: 0,
        dct_patient_burden: 0,
      });
    }
    return newArray;
  }

  const backToHome = () => {
    props.history.goBack()
  }

  // placeholder for other charts
  const option = {}

  const PBbyActivityCategoryOption = {
    grid: {
      top: '10%',
      left: '20%',
      right: '13%',
      bottom: '15%'
    },
    xAxis: {
      type: 'value',
      name: 'Patient Burden',
      nameLocation: 'center',
      nameGap: 30,
      axisLabel: {
        textStyle: {
          align: 'center'
        },
        lineStyle: {
          opacity: 0.5
        }
      }
    },
    yAxis: {
      type: 'category',
      // data: [
      //   'Procedures',
      //   'Questionnaires',
      //   'Study Procedures',
      //   'Physical Examination',
      //   'Labs'
      // ],
      data: PBbyCategoryInsightsData&&PBbyCategoryInsightsData.map(item => item.activity_category),
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.5
        }
      },
      axisLabel: {
        textStyle: {
          wordWrap: 'break-word'
        },
        margin: 12
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    // legend: {
    //   orient: 'horizontal',
    //   right: 10,
    // },
    series: [
      {
        type: 'scatter',
        name: 'SoA',
        symbolSize: 20,
        // data: [15, 15, 15, 9, 15],
        data: PBbyCategoryInsightsData&&PBbyCategoryInsightsData.map(item => Math.round(item.dct_patient_burden))||[],
        label: {
          show: true,
          formatter: (d) => `${d.value}`,
          fontWeight: 1000
        },
        itemStyle: {
          color: '#F27A26',
        }
      },
      {
        type: 'scatter',
        name: 'Historical Median',
        symbolSize: 20,
        // data: [5, 5, 5, 12, 5],
        data: PBbyCategoryInsightsData&&PBbyCategoryInsightsData.map(item => Math.round(item.historic_patient_burden))||[],
        label: {
          show: true,
          formatter: (d) => `${d.value}`,
          fontWeight: 1000
        },
        itemStyle: {
          color: '#FBD0B3'
        }
      }
    ]
  }

  const DCTonPBOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{c}'
      // axisPointer: {
      //   type: 'shadow',
      // }
    },
    legend: {
      show:false,
    },
    grid: {
      top: 0,
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      splitLine: {
        show: false,
      }
    },
    yAxis: {
      type: 'category',
      inverse: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      data: DCTonPBInsightsData&&DCTonPBInsightsData.map(item => item.dimension_name)
    },
    series: [
      {
        name: 'total patient burden with DCT',
        type: 'bar',
        stack: 'total',
        color: '#6F2849',
        label: {
          show: false
        },
        itemStyle: {
          borderRadius: [10,0,0,10]
        },
        emphasis: {
          // focus: 'series'
        },
        data: DCTonPBInsightsData&&DCTonPBInsightsData.map(item => roundToTwoDecimals(item.dct_patient_burden))||[]
      },
      {
        name: 'total patient burden without DCT',
        type: 'bar',
        stack: 'total',
        color: '#FBE5C4',
        label: {
          show: false
        },
        itemStyle: {
          borderRadius: [0,10,10,0]
        },
        barMaxWidth: 15,
        emphasis: {
          // focus: 'series'
        },
       data: DCTonPBInsightsData&&DCTonPBInsightsData.map(item => roundToTwoDecimals(item.patient_burden))||[]
      },   
    ]
  }

  const costPatientByCategoryOption = {
    grid: {
      top: '10%',
      left: '20%',
      right: '13%',
      bottom: '15%'
    },
    xAxis: {
      type: 'value',
      name: 'Cost per patient',
      nameLocation: 'center',
      nameGap: 30,
      axisLabel: {
        textStyle: {
          align: 'center'
        },
        lineStyle: {
          opacity: 0.5
        }
      }
    },
    yAxis: {
      type: 'category',
      // data: [
      //   'Procedures',
      //   'Questionnaires',
      //   'Study Procedures',
      //   'Physical Examination',
      //   'Labs'
      // ],
      data: costPerPatientByCategoryData&&costPerPatientByCategoryData.map(item => item.activity_category),
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.5
        }
      },
      axisLabel: {
        textStyle: {
          wordWrap: 'break-word'
        },
        margin: 12
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    // legend: {
    //   orient: 'horizontal',
    //   right: 10,
    // },
    series: [
      {
        type: 'scatter',
        name: 'SoA',
        symbolSize: 20,
        // data: [15, 15, 15, 9, 15],
        data: costPerPatientByCategoryData&&costPerPatientByCategoryData.map(item => Math.round(item.dct_patient_burden))||[],
        label: {
          show: true,
          formatter: (d) => `$${d.value}`,
          fontWeight: 1000
        },
        itemStyle: {
          color: '#F27A26',
        }
      },
      {
        type: 'scatter',
        name: 'Historical Median',
        symbolSize: 20,
        // data: [5, 5, 5, 12, 5],
        data: costPerPatientByCategoryData&&costPerPatientByCategoryData.map(item => Math.round(item.historic_patient_burden))||[],
        label: {
          show: true,
          formatter: (d) => `$${d.value}`,
          fontWeight: 1000
        },
        itemStyle: {
          color: '#FBD0B3'
        }
      }
    ]
  }

  const countSOAbyCategoryOption = {
    grid: {
      top: '10%',
      left: '20%',
      right: '13%',
      bottom: '15%'
    },
    xAxis: {
      type: 'value',
      name: 'SoA Activities',
      nameLocation: 'center',
      nameGap: 30,
      axisLabel: {
        textStyle: {
          align: 'center'
        },
        lineStyle: {
          opacity: 0.5
        }
      }
    },
    yAxis: {
      type: 'category',
      // data: [
      //   'Procedures',
      //   'Questionnaires',
      //   'Study Procedures',
      //   'Physical Examination',
      //   'Labs'
      // ],
      data: countOfSOAByCategoryData&&countOfSOAByCategoryData.map(item => item.activity_category),
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.5
        }
      },
      axisLabel: {
        textStyle: {
          wordWrap: 'break-word'
        },
        margin: 12
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    // legend: {
    //   orient: 'horizontal',
    //   right: 10,
    // },
    series: [
      {
        type: 'scatter',
        name: 'SoA',
        symbolSize: 20,
        // data: [15, 15, 15, 9, 15],
        data: countOfSOAByCategoryData&&countOfSOAByCategoryData.map(item => Math.round(item.soa_count))||[],
        label: {
          show: true,
          formatter: (d) => `${d.value}`,
          fontWeight: 1000
        },
        itemStyle: {
          color: '#F27A26',
        }
      },
      {
        type: 'scatter',
        name: 'Historical Median',
        symbolSize: 20,
        // data: [5, 5, 5, 12, 5],
        data: countOfSOAByCategoryData&&countOfSOAByCategoryData.map(item => Math.round(item.historic_soa_count))||[],
        label: {
          show: true,
          formatter: (d) => `${d.value}`,
          fontWeight: 1000
        },
        itemStyle: {
          color: '#FBD0B3'
        }
      }
    ]
  }
 
   const visitChartOption = { 
    grid: {
      top: 150,
      left: '10%',
      right: '5%'
    },
    graphic: [
      {
        type: 'line',
        y: 40,
      },
      {
          type: 'text',
          left: '5%',
          top: 30,
          style: {
              text :   Math.round(PBVisit?.total_dct_pb)??'0',
              fontSize: 36,
              fontWeight: 'bold'
          }
      },
      {
          type: 'text',
          left: '5%',
          top: 70,
          style: {
              text: PBVisit?.dct_pb_state || '',
              fontWeight: 'bold',
              fontSize: 18,
              fill: PBVisit?.dct_pb_state ==='GOOD'?'#00A947':PBVisit?.dct_pb_state ==='FAIR'?'#0084A9':PBVisit?.dct_pb_state ==='POOR'?'#c33232':'#848484',
          }
      },
      {
          type: 'text',
          right: '5%',
          top: 50,
          style: {
              text: PBVisit ? `Median from Similar Historical Trials - ${PBVisit?.sht_total_pb_median ?Math.round(PBVisit?.sht_total_pb_median): '0'}` : '',
              fontSize: 16,
          }
      }
    ], 
    xAxis: {
        type: 'category',
        name: 'Visit Number', 
        data: Array.from(PBVisit?.total_dct_pb_per_visit ?? [], (element) => element.visit_id.toString()) ,
        nameLocation: 'middle',
        nameGap: 30,
        nameRotate: 0,
    },
    yAxis: {
        type: 'value',
        name: 'Patient Burden',
        min: 0,
        max:  Math.round(PBVisit.sht_total_pb_median_first_visit + PBVisit.sht_total_pb_median_visits),
        nameLocation: 'middle',
        nameGap: 45,
    },
    tooltip: {
      show: true,
      trigger: 'item',
      formatter: function (param, index) {
          return Math.round(param?.data[1] ?? '0').toString();
      }
    },
    series: [
        {
            type: 'bar',
            data: PBVisit?.total_dct_pb_per_visit?.map((item, index) =>  [item.visit_index, Math.round(item['dct_patient_burden'])]),
            itemStyle: {
              color: '#FF6C40'
            },
            markPoint: {
                symbol: 'diamond',
                symbolSize: PBVisit?.total_dct_pb_per_visit?.length > 20 ? 5 : 12, 
                itemStyle: {
                color: 'black',
                backgroundColor: 'black',
                borderColor: 'black',
              },
                data: PBVisit?.total_dct_pb_per_visit?.map((item, idx) => ({ 
                  coord: [item.visit_index, item.visit_id == 1 ? Math.round(PBVisit?.sht_total_pb_median_first_visit) :  Math.round(PBVisit?.sht_total_pb_median_visits)],
                  value: item.visit_id == 1 ? `Median Patient Burden from the first visit of similar trials: ${PBVisit?.sht_total_pb_median_first_visit?Math.round(PBVisit?.sht_total_pb_median_first_visit):'0'}` : `Median Patient Burden from subsequent visits of historical trials: ${Math.round(PBVisit?.sht_total_pb_median_visits)?Math.round(PBVisit?.sht_total_pb_median_visits):'0'}`,
                  label: {
                      show: false,
                      color: '#fff', 
                      shadowColor: 'transparent' ,
                      fontSize: '0px'
                  }, 
              })),
                tooltip: {
                  show: true,
                  trigger: 'item',
                    formatter: function (param, index) { 
                        return param.value;
                    }
                }
            }
        }
    ]
};

  const columns = [
    {
      title: "PATIENT BURDEN",
      dataIndex: "dimension_name",
      ellipsis: true, 
      width: 220,
      sorter: (a, b) => (a.dimension_name < b.dimension_name ? -1 : 1),
    },
    {
      title: "SCENARIO VALUE",
      dataIndex: "scenario_value",
      ellipsis: true,
      render: item => <span>{Math.round(item)}</span>,
      sorter: (a, b) => a.scenario_value - b.scenario_value, 
    },
    {
      title: "HISTORICAL MEDIAN",
      dataIndex: "historical_median",
      ellipsis: true, 
      render: item => <span>{Math.round(item)}</span>,
      sorter: (a, b) => a.historical_median - b.historical_median, 
    },
    {
      title: "DIFFERENCE",
      dataIndex: "difference",
      ellipsis: true, 
      render: item => <span style={{color: parseInt(item) >= 0 ?  'red' : 'green'}}>{Math.round(item)}</span>,
      sorter: (a, b) => a.difference - b.difference, 
    } 
  ];
 
  return (
    <Row className="chart-summary-container">
      <Col 
      span={24} 
      style={{backgroundColor: '#DEDEDE', padding: '1% 6% 1%', fontFamily: 'Helvetica Neue'}}
      >
        <div 
          style={{cursor: 'pointer', fontSize: 14, fontWeight: 500, fontFamily: 'Helvetica Neue', marginTop: 10}}
          onClick={backToHome}
        >
          <ArrowLeftOutlined style={{verticalAlign: 'baseline', marginRight: 5,}}/>
          BACK
        </div>
        <h3 style={{marginTop: 10, fontFamily: 'Helvetica Neue'}}>SoA Impact</h3>
        {/* <p className="sub-heading" style={{fontSize: 14, fontFamily: 'Helvetica Neue'}}>Description text goes here</p> */}
      </Col>
      <Col span={11} className="chart-wrapper"> 
        <SoaAtGlance trialId={trial_id} scenarioId={record['scenarios'][0]['scenario_id']} />
      </Col> 
      <Col span={11} className="chart-wrapper">
        <div className="chart-header">
          <div className="title">
          Patient Burden by Visit
          </div>
        </div>
        <div className="chart-container">
          <ReactECharts style={{height: '400px'}} option={visitChartOption}></ReactECharts> 
        </div>                  
      </Col> 
      <Col span={11} className="chart-wrapper patient-burden-by-activity-category">
        <div className="chart-header">
          <div className="title">
            Patient Burden by Activity Category
          </div>
          <div className="legend">
            <span>
              <span className="circle" style={{backgroundColor: '#F27A26'}}></span>
              SOA
            </span>
            <span>
              <span className="circle" style={{backgroundColor: '#FBD0B3'}}></span>
              Historical Median
            </span>
          </div>
        </div>
        <div className="chart-container">
          <ReactECharts option={PBbyActivityCategoryOption}></ReactECharts> 
        </div>                  
      </Col>
      <Col span={11} className="chart-wrapper">
        <div className="chart-header">
          <div className="title">
          Patient burden by Dimension
          </div>
        </div>
        <div className="chart-container" style={{padding: '5px 18px' }}> 
          <Table
                  columns={columns}
                  dataSource={dimensionBurden} 
                  pagination={{
                    position: ["bottomRight"],
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${dimensionBurden.length} items`,
                    defaultPageSize: 5, 
                  }}  
                />
        </div>                  
      </Col> 
      <Col span={11} className="chart-wrapper dct-impact-on-patient-burden">
        <div className="chart-header">
          <div className="title">
            DCT Impact on Patient Burden
          </div>
        </div>
        <div className="chart-container">
          <div className="left-part">
            <div className="item-wrapper item-wrapper-top">
              <div className="number">
                {Math.round(DCTonPB.pb?.total_dct_pb)??'0'}
              </div>
              <div className="text">
                total patient burden with DCT
              </div>
            </div>
            <div className="item-wrapper item-wrapper-bottom">
              <div className="number">
                {Math.round(DCTonPB.pb?.total_pb)??'0'}
              </div>
              <div className="text">
                total patient burden without DCT
              </div>
            </div>
          </div>
          <div className="middle"> </div>
          <div className="right-part">
            <div className="title">
              Patient Burden Reduction by Dimension
            </div>
            <div className="chart-content">
            {
             DCTonPBInsightsData&&DCTonPBInsightsData.length > 0 && DCTonPBInsightsData.reduce((accumulator, currentValue) => {
              return accumulator + currentValue.dct_patient_burden;
            }, 0) > 0 ? 
                <ReactECharts option={DCTonPBOption}></ReactECharts> : (
                <div className="blank">
                  Based on your current activity and modality selections, none of the patient burden dimensions have a reduction in Patient Burden due to the application of DCT capabilities. Try adding DCT capabilities to further reduce your patient burden.
                </div>
              )
            } 
            </div>
          </div>
        </div>                  
      </Col>

      <Col span={11} className="chart-wrapper count-soa-by-activity-category">
        <div className="chart-header">
          <div className="title">
            Count of SoA Activities by Activity Category
          </div>
          <div className="legend">
            <span>
              <span className="circle" style={{backgroundColor: '#F27A26'}}></span>
              SOA
            </span>
            <span>
              <span className="circle" style={{backgroundColor: '#FBD0B3'}}></span>
              Historical Median
            </span>
          </div>
        </div>
        <div className="chart-container">
          <ReactECharts option={countSOAbyCategoryOption}></ReactECharts> 
        </div>                  
      </Col>

      {/* -------------- Activities by Modality Chart--------- */}
      <Col span={11} className="chart-wrapper">
        <div className="chart-header">
          <div className="title">
            Activities by Modality
          </div>

        </div>
        <div className="chart-container">
        { visits && modalityChartData  && ( <ActivitiesByModality visits={visits} modalityChartData={modalityChartData} />)}
        </div>
      </Col>
  </Row>
  )
}

export default withRouter(ChartSummary);