import { COGNITO_CONFIG, COGNITO_LOGIN_URL, AUTHORIZE_URL, COGNITO_TOKEN_URL, REVOKE_URL, USE_EXTERNAL_IDP } from "../constants";
const { client_id, redirect_uri, scope, response_type, identity_provider } = COGNITO_CONFIG;

export const idamLogin = () => {
   // URL for Hosted UI,
  let url = `${COGNITO_LOGIN_URL}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scope}`

  if(USE_EXTERNAL_IDP == 'yes'){
    // URL for SSO
    url = `${AUTHORIZE_URL}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&identity_provider=${identity_provider}&scope=${scope}`;
  }
  window.location.href = url;
};

export const retrieveIdamToken = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  if (!code) return;
  const response = await getAccessToken(code);
  if (response) {
    return response.json();
  }
  return null;
};

export const getAccessToken = async (code) => {
  const data = {
    grant_type: "authorization_code",
    client_id,
    redirect_uri,
    code,
  };

  const response = await fetch(COGNITO_TOKEN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(data),
  });
  return response;
};

export const revokeTokens = async (refreshToken) => {
  const data = {
    token: refreshToken,
    client_id,
  };

  const response = await fetch(REVOKE_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(data),
  });
  return response;
};

export const refreshTokens = async (refreshToken) => {
  const data = {
    grant_type: "refresh_token",
    client_id,
    redirect_uri,
    refresh_token: refreshToken
  };

  const response = await fetch(COGNITO_TOKEN_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(data),
  });
  return response;
};

export const getUserInfo = async (accessToken) => {
  const response = await fetch(REVOKE_URL, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return response;
};
