/* eslint-disable */
import React, { useState, useReducer, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import {
  message,
  Button,
  Spin,
  Steps,
  Modal,
} from "antd";
import {
  LoadingOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import moment from 'moment-timezone';
import * as createActions from "../../actions/createTrial.js";
import * as historyActions from "../../actions/historyTrial";
import { initialTrial } from "../../reducers/trialReducer.js";
import TrialEndpoints from "../../components/NewTrialSteps/TrialEndpoints";
import TeamMembers from '../../components/NewTrialSteps/TeamMembers';
import SimilarHistoricalTrials from '../../components/NewTrialSteps/SimilarHistoricalTrials';
import TrialSummary from '../../components/NewTrialSteps/TrialSummary';
import { addStudy, updateStudy, getGetReferenceData, getStudy, listMembers} from "../../utils/ajax-proxy";
import { isAuthenticated, getEmail } from "../../utils/auth";
import "./index.scss";

const { Step } = Steps;

export const phase_options = [
  "Early Phase 1",
  "Phase 1",
  "Phase 2",
  "Phase 3",
  "Phase 4"
];
export const study_types = [
  "Interventional",
  "Observational",
  "Patient Registries",
  "Expanded Access",
];
export const study_status = [
  "Available",
  "Withdrawn",
  "Withheld",
  "Temporarily not available",
  "Recruiting",
  "Active, not recruiting",
  "Not recruiting",
  "Not yet recruiting",
  "No longer available",
  "Enrolling by invitation",
  "Suspended",
  "Approved for marketing",
  "Unknown status",
  "Completed",
  "Terminated"
]
const timeline = ["Trial Summary", "Trial Endpoints", "Similar Historical Trials","Team Members"]
const validateFields = ["trial_alias", "trial_title", "therapeutic_area", "indication", "description", "molecule_name", "estimated_enrollment_count"];
const requiredStringFields = ["trial_alias", "trial_title", "therapeutic_area", "estimated_enrollment_count"];
const requiredListFields = ["indication"];
const init = {
  trial_title: "",
  description: "",
  therapeutic_area: ["All"],
  indication: [],
  trial_alias: "",
  study_type: [],
  study_phase: [],
  members: [],
  status: "",
  lastUpdatedDate: "",
  similar_history_count: null,
  molecule_name: "",
  pediatric_study: null,
  study_country: "",
  scenarios: [],
  CostAvg: 0,
  BurdenAvg: 0,
  soa_status_flag: "",
  toggle_indication: true,
  dateFrom: null,
  dateTo: null,
  study_status: null,
  userRolesList: [],   
  primary_endpoints: [],
  secondary_endpoints: [],
  tertiary_endpoints: [],
  similarHistoricalTrials: [], 
  studyPhaseList: [],
  studyTypeList: [],
  studyCountryList:[],
  indicationList: [], 
  studyStatusList: [],
  indicationTherapeuticMapping: {},
  estimated_enrollment_count: 0, 
  saved_filters: {
    study_phase: [],
    study_type: [],
    indication: [],
    study_status: [],
    study_start_date: [2010, 2025],
    moa: [],
    pediatric: null,
    soa: "",
    toggle: true,
  },
};

const NewTrial = (props) => {
  const [updateDetail, setUpdateDetail] = useState(false);
  const [drawerloading, setDrawerloading] = useState(false);
  const [indicationList, setIndicationList] = useState([])
  const [indicationListAll, setIndicationListAll] = useState([])
  const [studyPhaseList, setStudyPhaseList] = useState([])
  const [rolesList, setRolesList] = useState([])
  const [studyTypeList, setStudyTypeList] = useState([])
  const [studyCountryList, setStudyCountryList] = useState([])
  const [studyStatusList, setStudyStatusList] = useState([])
  const [indicationTherapeuticMap, setIndicationTherapeuticMap] = useState([])
  const [therapeuticAreaList, setTherapeuticAreaList] = useState([])
  const [trialAliasError, setTrialAliasError] = useState("");
  const [trialTitleError, setTrialTitleError] = useState("");
  const [therapeuticAreaError, setTherapeuticAreaError] = useState("");
  const [indicationError, setIndicationError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [moleculeNameError, setMoleculeNameError] = useState("");
  const [estimatedTotalError, setEstimatedTotalError] = useState("");
  const [initialState, setInitialState] = useState(init);
  const [step, setStep] = useState(0);
  const [teamMember, setTeamMember] = useState([]);
  const [editDetailTitle, setEditDetailTitle] = useState("");
  const [newTrial, setNewTrial] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...init }
  );
  const [selectedMoaData, setSelectedMoaData] = useState([])
  const [patientSegmentData, setSelectedPatientSegmentData] =  useState([])
  const [moaList, setMoaList] = useState([])
  const [patientSegmentList, setPatientSegmentList] = useState([])
  const [soaCount, setSoaCount] = useState(0)
  const [soaModal, showSOAModal] = useState(false)
  const [navigateToTeamsPage, setNavigateToTeamsPage] = useState(false)
  const [resetTrialsCount, setResetTrialsCount] = useState(false)
  const pollingIntervalMS = 10000; // Poll every 10 seconds
  const [isSaveTrial, setIsSaveTrial] = useState(false)

  const resetTrialHandler = () => {
  setResetTrialsCount(true)
  }

  useEffect(() => {
    const checkAuthenticated = async () => {
      if (!(await isAuthenticated(true))) {
        props.history.push("/");
        localStorage.setItem('processStep', JSON.stringify(0));
        localStorage.setItem('activeTabKey', JSON.stringify('1'));
    }}
    let fetchIndication
    if (props.newTrial.indicationList.length === 0) {
      fetchIndication = async() => {
        const resp = await getGetReferenceData();

        setIndicationList(sortIndication(resp.indication_list))
        props.createTrial({
          indicationList:resp.indication_list
        })
        setIndicationListAll(resp.indication_list)
        let indicationTherapeuticMap = resp.indication_therapeutic_mapping
        setIndicationTherapeuticMap(resp.indication_therapeutic_mapping)
        props.createTrial({
          indicationTherapeuticMapping: indicationTherapeuticMap
        })
        let therapeuticArea = Object.keys(indicationTherapeuticMap)
        therapeuticArea.unshift("All") 
        setTherapeuticAreaList(therapeuticArea.sort())
        let studyPhaseListWithAll = resp.study_phase_list.map((o) => {
          return o.label
        })
        studyPhaseListWithAll.unshift("All")         
        setStudyPhaseList(resp.study_phase_list)
        setStudyTypeList(resp.study_type_list)
        setStudyCountryList(resp.country_list)
        setStudyStatusList(resp.study_status_list)
        setRolesList(resp.user_roles_list)
        props.createTrial({
          studyPhaseList:resp.study_phase_list
        })
        props.createTrial({
          userRolesList:resp.user_roles_list
        })
        props.createTrial({
          studyTypeList:resp.study_type_list
        })
        props.createTrial({
          studyCountryList:resp.country_list
        })
        props.createTrial({
          studyStatusList:resp.study_status_list
        })
      }
    } else {      
      fetchIndication = () => {
        setIndicationList(sortIndication(props.newTrial.indicationList))
        setIndicationListAll(props.newTrial.indicationList)
        let indicationTherapeuticMap = props.newTrial.indicationTherapeuticMapping
        setIndicationTherapeuticMap(props.newTrial.indicationTherapeuticMapping)
        let therapeuticArea = Object.keys(indicationTherapeuticMap)
        therapeuticArea.unshift("All") 
        setTherapeuticAreaList(therapeuticArea.sort())
        let studyPhaseListWithAll = props.newTrial.studyPhaseList && props.newTrial.studyPhaseList.map((o) => {
          return o.label
        })
        studyPhaseListWithAll.unshift("All")        
        setStudyPhaseList(props.newTrial.studyPhaseList)
        setStudyTypeList(props.newTrial.studyTypeList)
        setStudyCountryList(props.newTrial.studyCountryList)
        setStudyStatusList(props.newTrial.studyStatusList)
        setRolesList(props.newTrial.userRolesList)
      }
    }
    fetchIndication();
    createInitialState();
    checkAuthenticated();
  }, [props.location.state])

  const sortIndication = (indicationList) => {
    if (indicationList.length === 0){
      return indicationList
    } else {
      return indicationList.sort((ind1, ind2) => (ind1.label < ind2.label) ? -1 : (ind1.label > ind2.label) ? 1 : 0);
    }
  }

  const getTitleCase = (val) => {
    let array = val.split("_")
    let firstVal = array[0].charAt(0).toUpperCase() + array[0].slice(1);
    if(array.length < 2){ return firstVal }
    let lastVal = array[1].charAt(0).toUpperCase() + array[1].slice(1);
    return firstVal + " " + lastVal;
  };

  const createInitialState = () => {
    init.members = [{
      name: getEmail(),
      id: getEmail(),
      idx: 1,
      role: "Edit",
      user_role_id: 1,
    }];
    setInitialState(init);
    setNewTrial(init);
  }

  const handleValidation = (key, v) => {
    let errorMsg = "";
    if(requiredStringFields.includes(key) && newTrial[key]?.length <= 0){
        errorMsg = getTitleCase(key) + " is required."
    }else if(requiredListFields.includes(key) && newTrial[key].length < 1){
        errorMsg = getTitleCase(key) + " is required."
    }else if(key == "trial_alias" && !newTrial[key].match(/^[0-9a-zA-Z]+$/)){
        // errorMsg = getTitleCase(key) + " must be alphanumeric characters only."
    }else if (key == "trial_title" && typeof newTrial[key] === 'string' && newTrial[key].trim().length > 300) {
        errorMsg = getTitleCase(key) + " must not exceed 300 characters."
    }else if (key == "description" && typeof newTrial[key] === 'string' && newTrial[key].trim().length > 500) {
        errorMsg = getTitleCase(key) + " must not exceed 500 characters."
    }else if (key == "trial_alias" && typeof newTrial[key] === 'string' && newTrial[key].trim().length !== 6) {
        // errorMsg = getTitleCase(key) + " must be 6 characters."
    }else if (key == "molecule_name" && typeof newTrial[key] === 'string' && newTrial[key].trim().length > 80) {
        errorMsg = getTitleCase(key) + " must not exceed 80 characters."
    }else if (key == "therapeutic_area" && newTrial['therapeutic_area'].length <1) {
      errorMsg = "Therapeutic Area is required."
    } else if (key == "estimated_enrollment_count" &&  newTrial['estimated_enrollment_count'] > 100000) {
      errorMsg = getTitleCase(key).split(' ')[1] + " must not exceed 100000."
    } else if (key == "estimated_enrollment_count" && newTrial['estimated_enrollment_count'] < 0 ) {
      errorMsg = getTitleCase(key).split(' ')[1] + " must not be less than 0."
    } else if (key == "estimated_enrollment_count" && !/^[0-9]+$/.test(newTrial['estimated_enrollment_count'] )) {
      errorMsg = getTitleCase(key).split(' ')[1] + " must be a number."
    }
    if(key == "trial_alias"){setTrialAliasError(errorMsg)}
    else if(key == "trial_title"){setTrialTitleError(errorMsg)}
    else if(key == "therapeutic_area"){setTherapeuticAreaError(errorMsg)}
    else if(key == "indication"){setIndicationError(errorMsg)}
    else if(key == "description"){setDescriptionError(errorMsg)}
    else if(key == "molecule_name"){setMoleculeNameError(errorMsg)}
    else if(key == "estimated_enrollment_count"){setEstimatedTotalError(errorMsg)}
    return errorMsg == ""
  };

  const doValidation = () => { 
    let reqFieldsEntered = true
    if (step == 0 || step == 2) {
      for(let key of validateFields){
        if(!handleValidation(key, null)){
            reqFieldsEntered = false
        }
      }
    } 

    if(step == 2 && soaCount < 10 && !navigateToTeamsPage ) { 
      if(isSaveTrial && updateDetail) {
        reqFieldsEntered = true 
        showSOAModal(false)
      } else {
        reqFieldsEntered = false
        showSOAModal(true)
      } 
    }
    return reqFieldsEntered;
  };

  useEffect(() => {
    if(isSaveTrial && updateDetail) {
      handleDetailUpdate();
    }
  }, [isSaveTrial])



  const handleNextStep = async () => { 
    if(doValidation()){ 
      setStep(step + 1)
      
    if (step + 1 == 1) {
      props.createTrial(newTrial)
      // fetch team member list
      const resp = await listMembers("")
      if (resp) {
        let memberList = Object.values(resp)?Object.values(resp).map((item,index)=>{
          // return item["user"]
          return item
        }):[]
        console.log("team member:",memberList);
        setTeamMember(memberList)
      }
    }
    if (step + 1 == 3) {
        props.createTrial(newTrial)
      }
    }
  }

  const handleChange = () => {
    showSOAModal(false)
    setNavigateToTeamsPage(false)
  }

  const handleContinue = () => { 
    if(updateDetail) {
      showSOAModal(false)
      setIsSaveTrial(true) 
      return;
    }
    showSOAModal(false)
    setNavigateToTeamsPage(true) 
  }

  useEffect(() => {
    if(!navigateToTeamsPage) return;
    handleNextStep()
  }, [navigateToTeamsPage])

  useEffect(() => {
    props.location.state&&props.location.state.title&&HandleTrialDetailUpdate(props.location.state.title, props.location.state.data)
  }, [props.location.state])

  const sleep = (time: number) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const handleOk = async () => {
    setDrawerloading(true)
    localStorage.setItem('show-details', 'true')
    let tempTrial = {...props.newTrial}
    tempTrial['status'] = 'Creating'
    tempTrial['lastUpdatedDate'] = moment().utc().format('YYYY-MM-DD HH:mm:ss')
    tempTrial['similar_history_count'] = {total: props.newTrial.similarHistoricalTrials.length, SOA: soaCount}
    props.createTrial(tempTrial)
    const create_trial = JSON.parse(JSON.stringify(props.newTrial))
    delete create_trial.saved_filters
    const resp = await addStudy(create_trial, props.newTrial.saved_filters);
    console.log('Create trial response: ' + resp)
    // detect if trial data preparation is ready: 1. criteria lib with frequency and average; 2. mean cost
    if (resp) {
      const trialId = resp.trial_uuid;
      let dataPreparationRes = false
      let retryTimes = 0
      setDrawerloading(false)
      props.history.push({pathname: '/trials', state: {trial_id: trialId}})      
      message.success('Your trial has been submitted for processing. Please check later.')
      setResetTrialsCount(false);
      setNavigateToTeamsPage(true)
      setSoaCount(0);
      setNewTrial(props.newTrial);            
      setStep(0)

	  let showDetail = true;
      let isDiffTrial = false;
      do {
        if (!(await isAuthenticated(true))) return;
        let currentTrialId = localStorage.getItem('trial-id')
        isDiffTrial = currentTrialId != '' && trialId != currentTrialId;
        let created_trial = await getStudy(trialId, null, getEmail())
        let study_json = created_trial
        dataPreparationRes = study_json["status"] == "In Progress"? true : false;
        retryTimes += 1
        if (dataPreparationRes && !isDiffTrial){
          message.success("Created Successfully");
          localStorage.setItem('trial-id', trialId)
          props.fetchHistory({
            shouldFetch: true,
            historyData:[]
          })
        }
        await sleep(pollingIntervalMS);
        showDetail = localStorage.getItem('show-details')?localStorage.getItem('show-details').toUpperCase() == 'TRUE':false
       } while (showDetail && !isDiffTrial && !dataPreparationRes && retryTimes <= 20);
      if (!dataPreparationRes && retryTimes > 20){
        message.error('No response for over 6 minutes, please call assist.')
      }
    }
  };

  const handleDetailUpdate = async () => {
    if(doValidation()){ 
      localStorage.setItem('trial-id', '')
      let tempTrial = {...props.newTrial}
      tempTrial['status'] = 'Updating'
      tempTrial['lastUpdatedDate'] = moment().utc().format('YYYY-MM-DD HH:mm:ss')
      tempTrial['similar_history_count'] = {total: props.newTrial.similarHistoricalTrials?.length, SOA: soaCount}
      props.createTrial(tempTrial)
      const {trial_uuid, trial_title, trial_alias, description, therapeutic_area,indication,study_phase,study_type,molecule_name, pediatric_study
        ,members, similarHistoricalTrials, primary_endpoints, secondary_endpoints, tertiary_endpoints, estimated_enrollment_count, saved_filters } = props.newTrial
      let payload: any = {
        trial_uuid:  trial_uuid,
	      user_name: getEmail()
      }
      switch (editDetailTitle) {
        case "Edit Details":
          payload.trial_title = trial_title;
          payload.trial_alias = trial_alias;
          payload.description = description;
          payload.therapeutic_area = therapeutic_area;
          payload.indication = indication
          payload.study_phase = study_phase;
          payload.study_type = study_type;
          payload.molecule_name = molecule_name;
          payload.pediatric_study = pediatric_study;
          payload.estimated_enrollment_count = estimated_enrollment_count;  
          break;
        case "Edit Endpoints":
          payload.primary_endpoints = primary_endpoints;
          payload.secondary_endpoints = secondary_endpoints;
          payload.tertiary_endpoints = tertiary_endpoints;
          break;
        case "Edit Similar Historical Trial":
          payload.similarHistoricalTrials = similarHistoricalTrials;
          payload.saved_filters = saved_filters;
          break;
        case "Edit Team Members":
            payload.members = members;
          break;
      }
      const resp = await updateStudy(payload);
      setUpdateDetail(false)
      props.history.push("/trials");
      setResetTrialsCount(false)
      if (resp) {
        const trialId = resp.trial_uuid;
        let dataPreparationRes = false
        let retryTimes = 0
        message.success('Your changes have been submitted for processing. Please check later.')      
        setStep(0)
        props.fetchHistory({
          shouldFetch: true,
          historyData:[]
        })
        let showDetail = true;
        let isDiffTrial = false;
        do {       
          if (!(await isAuthenticated(true))) return;
          let currentTrialId = localStorage.getItem('trial-id')
          isDiffTrial = currentTrialId != '' && trialId != currentTrialId;
          let _trial = await getStudy(trialId, null, getEmail());
          let study_json = _trial
          dataPreparationRes = study_json["status"] == "In Progress"? true : false;
          retryTimes += 1
          if (dataPreparationRes && !isDiffTrial){
            // fetchList();
            message.success("Update Successful");
            localStorage.setItem('trial-id', trialId)
            props.fetchHistory({
              shouldFetch: true,
              historyData:[]
            })
          }
          await sleep(pollingIntervalMS);
          showDetail = localStorage.getItem('show-details')?localStorage.getItem('show-details').toUpperCase() == 'TRUE':false
          } while (showDetail && !isDiffTrial && !dataPreparationRes && retryTimes <= 20);
        if (!dataPreparationRes && retryTimes > 20){
          message.error('No response for over 6 minutes, please call assist.')
        }
      }else{
          message.error("Failed to update");
      }
    }
  };

  const handleCancel = () => {
    setStep(0)
    setTrialAliasError("")
    setTrialTitleError("")
    setTherapeuticAreaError("")
    setIndicationError("")
    setDescriptionError("")
    setMoleculeNameError("")
    setEstimatedTotalError("")
    // setVisible(false);
    setNewTrial(initialState);
    setIndicationList(sortIndication(indicationListAll))
    setSelectedMoaData([])
    setSelectedPatientSegmentData([])
    setResetTrialsCount(false);
    setNavigateToTeamsPage(true)
    setSoaCount(0);
    props.createTrial(initialTrial)
    props.fetchHistory({
        shouldFetch: true,
        historyData:[]
      })
    props.history.push("/trials");
  };

  const HandleTrialDetailUpdate = async(key, v) => {
    setNewTrial(v)
    props.createTrial(v)
    setUpdateDetail(true)
    setIndicationList(sortIndication(indicationListAll))
    newTrial["indication"] = []
    // clear all error message for required fields
    setTrialAliasError("")
    setTrialTitleError("")
    setTherapeuticAreaError("")
    setIndicationError("")
    setDescriptionError("")
    setMoleculeNameError("")
    setEstimatedTotalError("")
    switch (key) {
      case "edit_details":
      setStep(0)
      setEditDetailTitle("Edit Details")
        break;
      case "edit_endpoints":
      setStep(1)
      setEditDetailTitle("Edit Endpoints")
        break;
      case "edit_similiarTrials":
      setStep(2)
      setEditDetailTitle("Edit Similar Historical Trial")
        break;
      case "edit_teamMembers":
      setStep(3)
      setEditDetailTitle("Edit Team Members")
      // fetch team member list
      const resp = await listMembers("")      
      if (resp) {
        let memberList = Object.values(resp)?Object.values(resp).map((item,index)=>{
          // return item["user"]
          return item
        }):[]
        setTeamMember(memberList)
      }
        break;
      default:
      setStep(0)
      setEditDetailTitle("Edit Details")
        break;
    }
  };

  const moaDataHandler = (moa, pSegment) => {
    setMoaList(moa)
    setPatientSegmentList(pSegment)
  }

  const handleNewTrialInputChange = (key, e) => {
    if (key === "estimated_enrollment_count") {
      setNewTrial({
        [key]: e,
      });
      props.createTrial({
        [key]: e,
      })
    } else {
      setNewTrial({
        [key]: e.target.value,
      });
      props.createTrial({
        [key]: e.target.value,
      })
    }
  };

  const handleNewTrialSelectChange = (key, v) => {
    if(key == "therapeutic_area"){
        handleTherapeuticAreaChange(v, newTrial)
    }
    setNewTrial({
      [key]: v,
    });
    props.createTrial({
      [key]: v,
    })
  };

  const handleTherapeuticAreaChange = (v, trialRef) => {
    if(v.length > 1 && v.includes("All")){
		const index = v.indexOf("All");
		if(index === 0) {
			v.splice(index, 1);
		}else if(index === v.length-1){
			v.splice(0, index);
		}
	}
    if(v.length == 1 && v[0]=="All"){
		setIndicationList(sortIndication(indicationListAll))
    }else{
        let indications = []
        v.forEach(ta => {indications = indicationTherapeuticMap[ta] != undefined ? indications.concat(indicationTherapeuticMap[ta]) : indications})
		setIndicationList(sortIndication(indications))
		trialRef["indication"] = trialRef["indication"].filter(ind => indications.map(i => i.value).includes(ind))
    }
  };

  const soaSelectionHandler = (val) => {
    showSOAModal(false)
    setSoaCount(val) 
    setNavigateToTeamsPage(false)
  }

  return (
    <div className="create-new-trial-container">
      <div className="new-trial-wrapper">
        <div className="new-trial-content">
          <div className="trial-title">{updateDetail?editDetailTitle:"New Trial"}</div>
          <div className="trial-content">
            {!updateDetail&&<div className="nav-bar">
              <Steps
                current={step} progressDot={(dot, { status, index }) => (
                <span>
                  {dot}
                </span>
              )}>
                {timeline.map((t, idx) =><Step title={t} key={t}/>)}
              </Steps>
            </div>}
            <div className={`center-content ${step === 2 ? 'similar-table' : ''}`}>
              {step==0 && <>
                <div className="title">
                  <div className="text">Trial Summary</div>
                </div>
                <TrialSummary 
                handleNewTrialInputChange={handleNewTrialInputChange} 
                handleNewTrialSelectChange={ handleNewTrialSelectChange} 
                newTrial={newTrial} 
                indicationList={indicationList}
                therapeuticAreaList={therapeuticAreaList}
                handleValidation={handleValidation}
                trialAliasError={trialAliasError}
                trialTitleError={trialTitleError}
                therapeuticAreaError={therapeuticAreaError} 
                indicationError={indicationError} 
                studyPhaseList={studyPhaseList}
                studyTypeList={studyTypeList} 
                studyCountryList={studyCountryList} 
                descriptionError={descriptionError}
                moleculeNameError={moleculeNameError}
                updateDetail={updateDetail}
                indicationTherapeuticMap={indicationTherapeuticMap}
                estimatedTotalError={estimatedTotalError}
                />
              </>
              }
              {step==1 && <>
                <div className="title">
                  <div className="text">Trial Endpoints</div>
                  <div className="des"></div>
                </div>
                <TrialEndpoints 
                handleNewTrialEndpointsAdd={ handleNewTrialSelectChange}
                newTrial={newTrial} 
                />
              </>
              }
              {step == 2 && <>
                {/* <div className="title">
                  <div className="text">Choose Similar Historical Trials</div>
                  <div className="des">Select historical trials from by sponsors or by study to build your scenarios</div>
                </div> */}
                <SimilarHistoricalTrials
                selectedMoaData={selectedMoaData}
                patientSegmentData={patientSegmentData}
                loadMoaData={moaDataHandler}
                moaList={moaList}
                patientSegmentList={patientSegmentList}
                indicationList={ indicationListAll} handleNewTrialSelectChangeHistory={ handleNewTrialSelectChange}
                studyPhaseList={studyPhaseList} studyTypeList={studyTypeList} studyStatusList={studyStatusList} selectedSOA={soaSelectionHandler}
                resetTrials={resetTrialHandler} resetTrialsCount={resetTrialsCount} isUpdate={updateDetail}
                indicationTherapeuticMap={indicationTherapeuticMap}
                isSaveTrial={isSaveTrial}
                />
              </>}
              {step==3 &&  
              <Spin spinning={drawerloading}
              indicator={<LoadingOutlined style={{ color: "#ca4a04" }}/>} >
                <div className="title">
                  <div className="text">Add Team Members</div>
                </div>
                <TeamMembers teamMember={teamMember} roles={rolesList}/> 
              </Spin>
              }
            </div>
          </div>
        </div> 
        <div className="new-trial-footer">
            <div className="left-action">
              <Button size="small" type="text" className="cancel-btn" onClick={handleCancel}>CANCEL</Button>
              {!updateDetail && step > 0 && step <=(timeline.length-1)? <span className="go-prev-step" onClick={()=>setStep(step-1)}><LeftOutlined />{ timeline[step-1]}</span>:null}
            </div>
            {!updateDetail&&
              <Button  size="small" type="primary" className="create-update-btn" onClick={step>=(timeline.length-1)?handleOk:handleNextStep}>
                {
                  // step>=(timeline.length-1)? "Create Trial":`Next Step: ${timeline[step+1]}`
                  step>=(timeline.length-1)? "CREATE":"CONTINUE"
                }
              </Button>
            }
            {updateDetail&&
              <Button  size="small" type="primary" className="create-update-btn" onClick={handleDetailUpdate}>
                UPDATE
              </Button>
            }
        </div>
      </div>

     {soaModal && ( <Modal visible={soaModal} className="SimialarHistoryModal"  onOk={handleContinue} onCancel={handleChange}
        footer={[
          <Button key="back"  onClick={handleChange} >
            NO, CHANGE
          </Button>,
          <Button key="submit" type="primary" onClick={handleContinue} >
            YES, CONTINUE
          </Button>,
          ,
        ]}
        >
          <h1> Are you sure?</h1>
          <div>There are less than 10 trials in your Similar Historical Trial list that have Schedule of Activities data.</div>
        </Modal>)}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
  fetchHistory:(val) => dispatch(historyActions.fetchHistory(val)),
});

const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
  historyTrial:state.historyReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewTrial));
