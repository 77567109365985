/* eslint-disable */
import React, { useState, useReducer, useEffect } from "react";
import { withRouter } from "react-router";
import { Tabs, Row, Col, message, Typography} from 'antd';
import { ArrowLeftOutlined} from "@ant-design/icons";
import "./index.scss";
import NewEditTable from "../../components/NewEditTable";
import EventList from '../EventList';
import {getTrialSoa, getIeCriteria} from "../../utils/ajax-proxy";
import { getEmail } from "../../utils/auth";

import telehealth_icon from '../../assets/fax.png';
import patient_at_home_icon from '../../assets/home.png';
import hospital_icon from '../../assets/hospital.png';
import mobile_health_icon from '../../assets/mobile.png';
import pharmacy_icon from '../../assets/medical.png';
import local_lab_icon from '../../assets/local.png';
import ip_direct_icon from '../../assets/ipdirect.png';
import e_consent_icon from '../../assets/econsent.png';

const { TabPane } = Tabs;
const { Text } = Typography;

const initialNumber = {
    screeningVisitsNumber: 0,
    treatmentVisitsNumber: 0,
	followupVisitsNumber: 0,
    weekNumber: 0
}

const CATEGORY_LABS = 'Labs';
const CATEGORY_PHYSICAL_EXAMINATION = 'Physical Examination';
const CATEGORY_PROCEDURES = 'Procedures';
const CATEGORY_QUESTIONNAIRES = 'Questionnaires';
const CATEGORY_STUDY_PROCEDURES = 'Study Procedures';

const defaultActiveKey = [2, 3, 4, 5, 6, 7, 8, 9]

const ScenarioDetails = (props: any) => {
  const [scenario, setScenario] = useState({}); 
  // inclusion criteria data for NewEditTable
  let [demographicsTableData, setDemographicsTableData] = useState([])
  let [interventionTableData, setInterventionTableData] = useState([])
  let [medConditionTableData, setMedConditionTableData] = useState([])
  let [labTestTableData, setLabTestTableData] = useState([])

  // exclusion criteria data for NewEditTable
  let [excluDemographicsTableData, setExcluDemographicsTableData] = useState([])
  let [excluInterventionTableData, setExcluInterventionTableData] = useState([])
  let [excluMedConditionTableData, setExcluMedConditionTableData] = useState([])
  let [excluLabTestTableData, setExcluLabTestTableData] = useState([])

  //Addedd data 
  let [addedLabs, setAddedLabs] = useState([])
  let [addedExamination, setAddedExamination] = useState([])
  let [addedQuestionnaires, setAddedQuestionnaires] = useState([])
  let [addedProcedures, setAddedProcedures] = useState([])
  let [addedStudyProcedures, setAddedStudyProcedures] = useState([])

  const [numbers, setNumbers] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...initialNumber }
  );
  const [weeks, setWeeks] = useState([])
  const [activeTabKey, setActiveTabKey] = useState('1')
  const [reloadSOA, setReloadSOA] = useState(true)

  const [modalityOptions, setModalityOptions] = useState([])
  const [soeScenarioDetails, setSoeScenarioDetails] = useState({});
  const [visitNotesList, setVisitNotesList] = useState([])
  const [endpoints, setEndpoints] = useState({})

  const changeActiveTabKey = (activeKey) => {
    setActiveTabKey(activeKey)
  }

  useEffect(() => {
    const scenario = props.record.scenarios.find((e) => e['scenario_id'] === props.scenarioId)
    if(scenario !== undefined){
        if (scenario.ie_criteria == undefined) {
            const getTrialById = async () => {
                localStorage.setItem('ts_id', scenario['ts_id'])
                const resp = await getIeCriteria(scenario["trial_uuid"], props.scenarioId, getEmail());
                if(resp){
                    const tempRecord = resp.scenarios.find((e) => e['scenario_id'] === props.scenarioId)
                    setScenario(tempRecord)                    
                    if(tempRecord["ie_criteria"]['Inclusion Criteria']!== undefined ){
                        tempRecord["ie_criteria"]['Inclusion Criteria'].Demographics&&setDemographicsTableData(tempRecord["ie_criteria"]['Inclusion Criteria'].Demographics.Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                        tempRecord["ie_criteria"]['Inclusion Criteria'].Intervention&&setInterventionTableData(tempRecord["ie_criteria"]['Inclusion Criteria'].Intervention.Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                        tempRecord["ie_criteria"]['Inclusion Criteria']['Medical Condition']&&setMedConditionTableData(tempRecord["ie_criteria"]['Inclusion Criteria']['Medical Condition'].Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                        tempRecord["ie_criteria"]['Inclusion Criteria']['Lab / Test']&&setLabTestTableData(tempRecord["ie_criteria"]['Inclusion Criteria']['Lab / Test'].Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                    }
                    if(tempRecord["ie_criteria"]['Exclusion Criteria']!== undefined ){ 
                        tempRecord["ie_criteria"]['Exclusion Criteria'].Demographics&&setExcluDemographicsTableData(tempRecord["ie_criteria"]['Exclusion Criteria'].Demographics.Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                        tempRecord["ie_criteria"]['Exclusion Criteria'].Intervention&&setExcluInterventionTableData(tempRecord["ie_criteria"]['Exclusion Criteria'].Intervention.Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                        tempRecord["ie_criteria"]['Exclusion Criteria']['Medical Condition']&&setExcluMedConditionTableData(tempRecord["ie_criteria"]['Exclusion Criteria']['Medical Condition'].Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                        tempRecord["ie_criteria"]['Exclusion Criteria']['Lab / Test']&&setExcluLabTestTableData(tempRecord["ie_criteria"]['Exclusion Criteria']['Lab / Test'].Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                    }
                }else{
                    message.error("Error in loading the scenario")
                }
            };
            getTrialById();
        } else {
            setScenario(scenario)
            if(scenario["ie_criteria"]['Inclusion Criteria']!== undefined ){
                scenario["ie_criteria"]['Inclusion Criteria'].Demographics&&setDemographicsTableData(scenario["ie_criteria"]['Inclusion Criteria'].Demographics.Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                scenario["ie_criteria"]['Inclusion Criteria'].Intervention&&setInterventionTableData(scenario["ie_criteria"]['Inclusion Criteria'].Intervention.Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                scenario["ie_criteria"]['Inclusion Criteria']['Medical Condition']&&setMedConditionTableData(scenario["ie_criteria"]['Inclusion Criteria']['Medical Condition'].Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                scenario["ie_criteria"]['Inclusion Criteria']['Lab / Test']&&setLabTestTableData(scenario["ie_criteria"]['Inclusion Criteria']['Lab / Test'].Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
            }
            if(scenario["ie_criteria"]['Exclusion Criteria']!== undefined ){ 
                scenario["ie_criteria"]['Exclusion Criteria'].Demographics&&setExcluDemographicsTableData(scenario["ie_criteria"]['Exclusion Criteria'].Demographics.Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                scenario["ie_criteria"]['Exclusion Criteria'].Intervention&&setExcluInterventionTableData(scenario["ie_criteria"]['Exclusion Criteria'].Intervention.Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                scenario["ie_criteria"]['Exclusion Criteria']['Medical Condition']&&setExcluMedConditionTableData(scenario["ie_criteria"]['Exclusion Criteria']['Medical Condition'].Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
                scenario["ie_criteria"]['Exclusion Criteria']['Lab / Test']&&setExcluLabTestTableData(scenario["ie_criteria"]['Exclusion Criteria']['Lab / Test'].Entities.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
            }
        }
    }
  },[props.record, props.scenarioId]);

  useEffect(()=>{
    if(reloadSOA){
        const getSoaData = async() => { 
            if(!scenario["trial_uuid"] || !props.scenarioId  || !getEmail()) return
            const response = await getTrialSoa(scenario["trial_uuid"], props.scenarioId, getEmail()); 
            if(!response) return; 
            const endpoints = {
                "Primary Endpoints": response.primary_endpoints,
                "Secondary Endpoints": response.secondary_endpoints,
                "Tertiary Endpoints": response.tertiary_endpoints,
              };
            setEndpoints(endpoints)
            let nScenario = response?.scenarios.find((e) => e['scenario_id'] === props.scenarioId)  
            setModalityOptions(getModalityOptions(nScenario['modality_option'])) 
            setSoeScenarioDetails(nScenario)
            setSoeScenarioDetails({
                'cost_state': nScenario?.cost_state,
                'cost': nScenario?.cost,
                'dct_patient_burden': nScenario?.dct_patient_burden,
                'dct_patient_burden_state': nScenario?.dct_patient_burden_state,
                'total_enrollment_cost': nScenario?.total_enrollment_cost
            })
            const eventsConfigure = nScenario['Schedule of Activities'] === undefined ? {} : nScenario['Schedule of Activities']
            if(eventsConfigure != undefined){
                setNumbers({
                    ['screeningVisitsNumber']: eventsConfigure?.VisitTypes?.ScreeningVisits ?? 0,
                    ['treatmentVisitsNumber']: eventsConfigure?.VisitTypes?.TreatmentVisits ?? 0,
	                ['followupVisitsNumber']: eventsConfigure?.VisitTypes?.FollowupVisits ?? 0,
                    ['weekNumber']: eventsConfigure.Weeks !== undefined ? eventsConfigure.Weeks[eventsConfigure.Weeks.length -1] : 0
                });
                setWeeks(eventsConfigure.Weeks ?? [])
                eventsConfigure[CATEGORY_LABS] != undefined && eventsConfigure[CATEGORY_LABS].entities.length>0 &&setAddedLabs(eventsConfigure[CATEGORY_LABS].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
                eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION] != undefined && eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION].entities.length>0 && setAddedExamination(eventsConfigure[CATEGORY_PHYSICAL_EXAMINATION].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
                eventsConfigure[CATEGORY_QUESTIONNAIRES] != undefined && eventsConfigure[CATEGORY_QUESTIONNAIRES].entities.length>0 &&setAddedQuestionnaires(eventsConfigure[CATEGORY_QUESTIONNAIRES].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
                eventsConfigure[CATEGORY_PROCEDURES] != undefined && eventsConfigure[CATEGORY_PROCEDURES].entities.length>0 &&setAddedProcedures(eventsConfigure[CATEGORY_PROCEDURES].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
                eventsConfigure[CATEGORY_STUDY_PROCEDURES] != undefined && eventsConfigure[CATEGORY_STUDY_PROCEDURES].entities.length>0 &&setAddedStudyProcedures(eventsConfigure[CATEGORY_STUDY_PROCEDURES].entities.sort( (a, b) => a['Standard Activity'] < b['Standard Activity'] ? -1 : 1))
                setVisitNotesList(eventsConfigure.visitNotesList ?? [])
                
            }
            setReloadSOA(false)
          }
          getSoaData();
    }
  },[activeTabKey, scenario])

  const modality_options_dict ={
    "eSource & Wearables": {
      "icon": mobile_health_icon,
      "color": '#FFBF00'
    },
    "Telemedicine": {
      "icon": telehealth_icon,
      "color": '#75C716'
    },
    "Home Health": {
      "icon": patient_at_home_icon,
      "color": '#C9DF00'
    },
    "Local Lab & Sample Collection": {
      "icon": local_lab_icon,
      "color": '#6A86FA'
    },
    "Local Clinic": {
      "icon": pharmacy_icon,
      "color": '#FF025F'
    },
    "IP Direct Shipment": {
      "icon": ip_direct_icon,
      "color": '#83B1FA'
    },
    "eConsent": {
      "icon": e_consent_icon,
      "color": '#00A760'
    },
    "Traditional Site": {
      "icon": hospital_icon,
      "color":'#CB0047'
    },
    "": {
      "icon":'',
      "color":'white'
    }
  }

  const getModalityOptions = (modalityOpts) =>{
    let finalModalityOptions = []
    if(modalityOpts != null && modalityOpts != undefined){
        modalityOpts.forEach((modality) => {
            if(modality.name in modality_options_dict){
              finalModalityOptions.push({
                    key: modality.key,
                    name:  modality.name,
                    icon: modality_options_dict[modality.name].icon,
                    color: modality_options_dict[modality.name].color
              })
            }else{
              finalModalityOptions.push({
                    key: modality.key,
                    name:  modality.name,
                    icon: '',
                    color: 'white'
              })
            }
        });
        finalModalityOptions.push({
            key:"",
            name:  "",
            icon:'',
            color:'white'
        })
        return finalModalityOptions;
    }else{
        return []
    }
}

  return (
    <>
        <div className="trial-detail-container">
            <div className="scenario-info">
                <span className="go-back" onClick={props.handleBack}><ArrowLeftOutlined />&nbsp;BACK</span>
                <span className="scenario-type">
                    <Text
                    style={{maxWidth: 900}}
                    ellipsis={{tooltip:scenario['scenario_name']}}
                    >
                        {scenario['scenario_type']}&nbsp;:&nbsp;{scenario['scenario_name']}
                    </Text>
                </span>
                <span className="scenario-desc">
                    <Text
                        style={{maxWidth: 900}}
                        ellipsis={{tooltip:scenario['scenario_description']}}
                      >
                       {scenario['scenario_description']}
                    </Text>
                </span>
            </div>
            <div className="info">
                <div className="info-row">
                <div className="scenario-section">
                    <span className="section-title">Protocol Amendment Risk</span>
                    <span className="section-value">{scenario["protocol_amendment_rate"]}</span>
                    {/* <span className="section-value">Future Module</span> */}
                    <span className={`section-level ${scenario["protocol_amendment_rate_state"]}`}>
                        {/* {scenario["protocol_amendment_rate_state"]} */}
                    </span>
                </div>
                <div className="scenario-section">
                    <span className="section-title">Screen Failure Rate</span>
                    <span className="section-value">{scenario["screen_failure_rate"]}</span>
                    <span className={`section-level ${scenario["screen_failure_rate_state"]}`}>{scenario["screen_failure_rate_state"]}</span>
                </div>
                <div className="scenario-section">
                    <span className="section-title">Patient Burden</span>
                    <span className="section-value">{soeScenarioDetails["dct_patient_burden"] ? Math.round(soeScenarioDetails["dct_patient_burden"]) : ''}</span>
                    <span className={`section-level ${soeScenarioDetails["dct_patient_burden_state"]}`}>{soeScenarioDetails["dct_patient_burden_state"]}</span>
                </div>
                <div className="scenario-section">
                    <span className="section-title">Cost</span>
                    <span className="section-value">{typeof soeScenarioDetails["cost"] === "number"?`$${soeScenarioDetails["cost"]}`:``}</span>
                    <span className={`section-level ${soeScenarioDetails["cost_state"]}`}>{soeScenarioDetails["cost_state"]}</span>
                </div>
                <div className="scenario-section">
                    <span className="section-title">Total Cost</span>
                    <span className="section-value">{typeof soeScenarioDetails["total_enrollment_cost"] === "number"?`$${soeScenarioDetails["total_enrollment_cost"]}`:``}</span> 
                </div>
                </div>
            </div>
        </div>
        <div className={`rationale-info ${scenario['rationale'] === undefined ? 'hidde' : ''}`}>
            <span className="title">Rationale</span>
            <span className="content">
                <Text
                style={{maxWidth: 900}}
                ellipsis={{tooltip:scenario['rationale']}}
                >
                    {scenario['rationale']}
                </Text>
            </span>
        </div>
        <div className="scenario-details-tab">
            <Tabs onChange={changeActiveTabKey} activeKey={activeTabKey} centered>
                <TabPane tab="INCLUSION / EXCLUSION CRITERIA" key="1">
                <div className="criteria-container">
                    <div className="criteria-inner">
                        <div className="criteria-type">
                            <span>Inclusion Criteria</span>
                        </div>
                        <div className="criteria-header">
                        <Row>
                            {/* <Col span={1}></Col> */}
                            {/* <Col span={2}><div className="col-item">S/No.</div></Col> */}
                            <Col span={4}><div className="col-item">Entity</div></Col>
                            <Col span={4}><div className="col-item">Historial Entity Frequency</div></Col>
                            <Col span={3}><div className="col-item">Values</div></Col>
                            <Col span={3}><div className="col-item">Timeframe</div></Col>
                            <Col span={3}><div className="col-item">ROA</div></Col>
                            <Col span={3}><div className="col-item">Acuity</div></Col>
                            <Col span={3}><div className="col-item">Treatment Modifier</div></Col>
                        </Row>
                        </div>
                        <div className="criteria-table none-click">
                            <NewEditTable tableIndex={2}                                
                                data={demographicsTableData} defaultActiveKey={defaultActiveKey}
                                collapsible={false} panelHeader={"Demographics"} viewOnly={true}/>
                            <NewEditTable tableIndex={3}
                                data={medConditionTableData} defaultActiveKey={defaultActiveKey}
                                collapsible={false} panelHeader={"Medical Condition"} viewOnly={true}/>
                            <NewEditTable tableIndex={4} 
                                data={interventionTableData} defaultActiveKey={defaultActiveKey}
                                collapsible={false} panelHeader={"Intervention"} viewOnly={true}/>
                            <NewEditTable tableIndex={5} 
                                data={labTestTableData} defaultActiveKey={defaultActiveKey}
                                collapsible={false} panelHeader={"Lab / Test"} viewOnly={true}/>
                        </div>
                        <div className="criteria-type">
                            <span>Exclusion Criteria</span>
                        </div>
                        <div className="criteria-header">
                            <Row>
                                {/* <Col span={1}></Col>
                                <Col span={2}><div className="col-item">S/No.</div></Col> */}
                                <Col span={4}><div className="col-item">Entity</div></Col>
                                <Col span={4}><div className="col-item">Historial Entity Frequency</div></Col>
                                <Col span={3}><div className="col-item">Values</div></Col>
                                <Col span={3}><div className="col-item">Timeframe</div></Col>
                                <Col span={3}><div className="col-item">ROA</div></Col>
                                <Col span={3}><div className="col-item">Acuity</div></Col>
                                <Col span={3}><div className="col-item">Treatment Modifier</div></Col>
                            </Row>
                        </div>
                        <div className="criteria-table none-click">
                            <NewEditTable tableIndex={6}                                
                                data={excluDemographicsTableData} defaultActiveKey={defaultActiveKey}
                                collapsible={false} panelHeader={"Demographics"} viewOnly={true}/>
                            <NewEditTable tableIndex={7}
                                data={excluMedConditionTableData} defaultActiveKey={defaultActiveKey}
                                collapsible={false} panelHeader={"Medical Condition"} viewOnly={true}/>
                            <NewEditTable tableIndex={8} 
                                data={excluInterventionTableData} defaultActiveKey={defaultActiveKey}
                                collapsible={false} panelHeader={"Intervention"} viewOnly={true}/>
                            <NewEditTable tableIndex={9} 
                                data={excluLabTestTableData} defaultActiveKey={defaultActiveKey}
                                collapsible={false} panelHeader={"Lab / Test"} viewOnly={true}/>
                        </div>
                    </div>
                </div>
                </TabPane>
                <TabPane tab="SCHEDULE OF ACTIVITIES" key="2">
                    <div>
                  <EventList
                    completed={true}
                    numbers={numbers}
                    labs={addedLabs}
                    examination={addedExamination}
                    procedures={addedProcedures}
                    questionnaire={addedQuestionnaires}
                    studyProcedures={addedStudyProcedures}
                    weeks={weeks}
                    submitType={0}
                    viewOnly={true}
                    modalityOptions={modalityOptions}
                    visitsList={visitNotesList}
                    endpoints={endpoints}
                />
                    </div>
                </TabPane>
            </Tabs>
        </div>
    </>
  );
};

export default withRouter(ScenarioDetails);
