/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import * as createActions from "../../../actions/createTrial.js";

import "./index.scss";

const TrialEndpoits = (props: any) => {
  const [primary, setPrimary] = useState(props.newTrial.primary_endpoints);
  const [secondary, setSecondary] = useState(
    props.newTrial.secondary_endpoints
  );
  const [tertiary, setTertiary] = useState(props.newTrial.tertiary_endpoints);
  const [primaryValue, setPrimaryValue] = useState("");
  const [secondaryValue, setSecondaryValue] = useState("");
  const [tertiaryValue, setTertiaryValue] = useState("");
  const [peValidationError, setPeValidationError] = useState("");
  const [seValidationError, setSeValidationError] = useState("");
  const [teValidationError, setTeValidationError] = useState("");

  const handleChange = (key, e) => {
    const val = e.target.value;
    if (key == "primary") setPrimaryValue(val);
    if (key == "secondary") setSecondaryValue(val);
    if (key == "tertiary") setTertiaryValue(val);
  };

  useEffect(() => {
    if (primaryValue.replace(/(^\s*)|(\s*$)/g, "") != "" || secondaryValue.replace(/(^\s*)|(\s*$)/g, "") != ""
    || tertiaryValue.replace(/(^\s*)|(\s*$)/g, "") != ""){
      setPeValidationError("")
      setSeValidationError("")
      setTeValidationError("")
    }else if (primaryValue.trim().length < 400 || secondaryValue.trim().length < 400
    || tertiaryValue.trim().length < 400){
      setPeValidationError("")
      setSeValidationError("")
      setTeValidationError("")
    }
  },[primaryValue, secondaryValue, tertiaryValue]);

  const handleAdd = (key) => {
    if (key == "primary") {
      if (primaryValue.replace(/(^\s*)|(\s*$)/g, "") === ""){
        setPeValidationError("Endpoint content is required.")
        setSeValidationError("")
        setTeValidationError("")
        return
      }else if(primaryValue.trim().length > 400){
        setPeValidationError("Endpoint content must not exceed 400 characters.")
        setSeValidationError("")
        setTeValidationError("")
        return
      }
      const tmp = primary.slice(0);
      tmp.push(primaryValue);
      setPrimary(tmp);
      setPrimaryValue("");
      props.handleNewTrialEndpointsAdd( 'primary_endpoints', tmp)
      props.createTrial({
        primary_endpoints: tmp,
      });
    } else if (key == "secondary") {
      if (secondaryValue.replace(/(^\s*)|(\s*$)/g, "") === ""){
        setPeValidationError("")
        setSeValidationError("Endpoint content is required.")
        setTeValidationError("")
        return
      }else if(secondaryValue.trim().length > 400){
        setPeValidationError("")
        setSeValidationError("Endpoint content must not exceed 400 characters.")
        setTeValidationError("")
        return
      }
      const tmp = secondary.slice(0);
      tmp.push(secondaryValue);
      setSecondary(tmp);
      setSecondaryValue("");
      props.handleNewTrialEndpointsAdd( 'secondary_endpoints', tmp)
      props.createTrial({
        secondary_endpoints: tmp,
      });
    } else if (key == "tertiary") {
      if (tertiaryValue.replace(/(^\s*)|(\s*$)/g, "") === ""){
        setPeValidationError("")
        setSeValidationError("")
        setTeValidationError("Endpoint content is required.")
        return
      }else if(tertiaryValue.trim().length > 400){
        setPeValidationError("")
        setSeValidationError("")
        setTeValidationError("Endpoint content must not exceed 400 characters.")
        return
      }
      const tmp = tertiary.slice(0);
      tmp.push(tertiaryValue);
      setTertiary(tmp);
      setTertiaryValue("");
      props.handleNewTrialEndpointsAdd( 'tertiary_endpoints', tmp)
      props.createTrial({
        tertiary_endpoints: tmp,
      });
    }
  };

  const handleRemove = (key, idx) => {
    if (key == "primary") {
      const tmp = primary.slice(0);
      tmp.splice(idx, 1);
      setPrimary(tmp);
      props.createTrial({
        primary_endpoints: tmp,
      });
    } else if (key == "secondary") {
      const tmp = secondary.slice(0);
      tmp.splice(idx, 1);
      setSecondary(tmp);
      props.createTrial({
        secondary_endpoints: tmp,
      });
    } else if (key == "tertiary") {
      const tmp = tertiary.slice(0);
      tmp.splice(idx, 1);
      setTertiary(tmp);
      props.createTrial({
        tertiary_endpoints: tmp,
      });
    }
  };

  return (
    <div className="trial-endpoints-cotainer">
      <div className="endpoints-item">
        <div className="add-box">
          <span className="endpoint-title">Primary Endpoints</span>
          <span
            className={`count count-icon ${
              primary.length > 0 ? "active-count" : "inactive-count"
            }`}
          >
            {primary.length}
          </span>
          <div className="add-endpoint">
            <Input
              allowClear
              placeholder="Add Trial Endpoints"
              value={primaryValue}
              style={{ width: 200, height: 30 }}
              onChange={(e) => handleChange("primary", e)}
            />
            <Button type="primary" onClick={() => handleAdd("primary")}>
              ADD
            </Button>
          </div>
          <p className="endpoint-validation-error">{peValidationError}</p>
        </div>

        <div className="endpoint-list">
          {primary.map((p, idx) => {
            return (
              <div className="list-item" key={idx}>
                <span>{p}</span>
                <CloseOutlined onClick={() => handleRemove("primary", idx)} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="endpoints-item">
        <div className="add-box">
          <span className="endpoint-title">Secondary Endpoints</span>
          <span
            className={`count count-icon ${
              secondary.length > 0 ? "active-count" : "inactive-count"
            }`}
          >
            {secondary.length}
          </span>
          <div className="add-endpoint">
            <Input
              allowClear
              placeholder="Add Trial Endpoints"
              value={secondaryValue}
              style={{ width: 200, height: 30 }}
              onChange={(e) => handleChange("secondary", e)}
            />
            <Button type="primary" onClick={() => handleAdd("secondary")}>
              ADD
            </Button>
          </div>
          <p className="endpoint-validation-error">{seValidationError}</p>
        </div>

        <div className="endpoint-list">
          {secondary.map((s, idx) => {
            return (
              <div className="list-item" key={idx}>
                <span>{s}</span>
                <CloseOutlined onClick={() => handleRemove("secondary", idx)} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="endpoints-item">
        <div className="add-box">
          <span className="endpoint-title">Tertiary/Exploratory Endpoints</span>
          <span
            className={`count count-icon ${
              tertiary.length > 0 ? "active-count" : "inactive-count"
            }`}
          >
            {tertiary.length}
          </span>
          <div className="add-endpoint">
            <Input
              allowClear
              placeholder="Add Trial Endpoints"
              value={tertiaryValue}
              style={{ width: 200, height: 30 }}
              onChange={(e) => handleChange("tertiary", e)}
            />
            <Button type="primary" onClick={() => handleAdd("tertiary")}>
              ADD
            </Button>
          </div>
          <p className="endpoint-validation-error">{teValidationError}</p>
        </div>

        <div className="endpoint-list">
          {tertiary.map((t, idx) => {
            return (
              <div className="list-item" key={idx}>
                <span>{t}</span>
                <CloseOutlined onClick={() => handleRemove("tertiary", idx)} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
});

const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
});
export default connect(mapStateToProps, mapDispatchToProps)(TrialEndpoits);
