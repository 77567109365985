import React from "react";
import { Provider } from "react-redux";
import configureStore from "./store/index.js";
import { Router, Switch, Route, Redirect } from "react-router-dom";

import history from "./history";

import LandingPage from "./pages/LandingPage";
import LogoutPage from "./pages/Logout";
import Layout from "./pages/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/global.scss";
import "./App.css";
import { useInactivityTimer, logout } from "./utils/auth";
import { INACTIVITY_TIMEOUT } from "./constants";

const store = configureStore();

function App() {
  useInactivityTimer(() => logout(history), INACTIVITY_TIMEOUT);
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch> 
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/logout">
            <LogoutPage />
          </Route>
          <Route exact path="/overview">
            <Layout />
          </Route>
          <Route exact path="/new-trial">
            <Layout />
          </Route>
          <Route exact path="/protocol-sections">
             <Layout />
          </Route>
          <Route exact path="/trials">
            <Layout />
          </Route>
          <Route exact path="/literature">
            <Layout/>
          </Route>
          <Route exact path="/scenario">
            <Layout />
          </Route>
          <Route exact path="/compare-scenario">
             <Layout />
          </Route>
          <Route exact path="/add-activity-soa">
            <Layout />
          </Route>
          <Route exact path="/add-activity-ie">
            <Layout />
          </Route>
          <Route exact path="/show-pa">
            <Layout />
          </Route>
          <Route exact path="/chart-summary">
             <Layout />
          </Route> 
          <Route exact path="/old-scenario">
             <Layout />
          </Route>

         
          <Route exact path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
