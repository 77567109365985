import React, { useEffect, useState, } from "react";
import { Col, Row, Typography, Tabs, Radio } from "antd"; 
import { ArrowLeftOutlined, UpOutlined } from "@ant-design/icons"; 
import "./index.scss"
const { Text } = Typography;
const { TabPane } = Tabs;

const ScenarioLeftPanel = (props) => {
   const { 
    trialTitle, 
    scenario, 
    activeTabKey,  
    tabChangeHandler,
    IEScheduleChangeHandler,
    scheduleEvent,
    onClickPrevious,
    processStep
} = props;

    
    const onTabChange = (activeKey) => { 
      tabChangeHandler(activeKey) 
    } 

   
    
    const onScheduleChange = (value) => {  
        IEScheduleChangeHandler(value)  
        if(value === 1) { 
          tabChangeHandler(null) 
        }   
    }
    
    const onClickBackBtn = () => {
      onClickPrevious()
    }
    
    return (
     <>
        <Col span={3} className="left_process-container" style={{maxWidth: '160px', minWidth: '160px', 
        height: scheduleEvent ? '100%' : 'auto',
          //height: 'auto', 
          minHeight: 'calc(100vh - 88px)'}}>
            <Row className="tab-container">
              <Col span={24}>
                <Row className="item-translate">
                    <Col flex="auto">
                      <span>
                        <Text
                          style={{maxWidth: 150}}
                          className="trialTitle"
                          ellipsis={{tooltip:trialTitle}}
                        >
                          {trialTitle?trialTitle:'-'}
                        </Text>
                      </span>
                      
                    </Col>
                  </Row>
                <Row className="item-translate scenario-name-container">
                    <Col flex="auto" className="name">
                      <Text
                        style={{maxWidth: 150}}
                        className="scenario-name"
                        ellipsis={{tooltip:scenario['scenario_name']}}
                      >
                        {scenario['scenario_name']}
                      </Text>
                    </Col>
                </Row>
                </Col>
            </Row>
             <Row className="tab-container"> 
                <span style={{display:'inline-block'}} className="tabFont ie-schedule-selection" 
                onClick={() => onScheduleChange(0)}
                > Add Inclusion / Exclusion Criteria <span className="icon-holder"> <UpOutlined />  </span></span> 
              </Row>
            <Row className="left-panel-row">
              <Tabs onChange={onTabChange} activeKey={activeTabKey} centered  tabPosition={'left'}> 
                <TabPane className="tabFont" tab="Inclusion Criteria" key="1"></TabPane>
                <TabPane className="tabFont" tab="Exclusion Criteria" key="2"></TabPane>
              </Tabs>
            </Row>
            <Row>
              <span 
                className={`tabFont ie-schedule-selection schedule-events ${processStep == 1 ? "schedule-active" : ""}  `} onClick={() => onScheduleChange(1)}>Schedule of Activities</span>
            </Row>
            <Row>
            <div className="action-title" onClick={onClickBackBtn} >
              <ArrowLeftOutlined /> &nbsp;Back to trial Page
          </div>
          </Row>
        </Col>
      </>
    )
  };
  
  export default ScenarioLeftPanel;