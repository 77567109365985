import { LIST_ALL_TRIAL_URL, GET_TRIAL_URL, UPDATE_TRIAL_URL, DELETE_TRIAL_URL, CHECK_CREATE_TRIAL_STATUS_URL, CHECK_PATIENT_FUNNEL_STATUS_URL, GET_CRITERIA_LIBRARY_URL, GET_IE_RESOURCES_URL, GET_PATIENT_FUNNEL_URL, GET_SOA_EVENT_LIBRARY_URL, GET_SOA_RESOURCES_URL, GET_REFERENCE_DATA_URL, LIST_SIMILAR_HISTORICAL_TRIAL_URL, ADD_TRIAL_URL, SAVE_TRIAL_SOA_URL, LIST_MEMBERS_URL, CREATE_SCENARIO_URL, SAVE_CRITERIA_URL, GET_TRIAL_IE_CRITERIA, GET_TRIAL_SOA, SAVE_TEMPLATE_SCENARIO_URL, GET_TRIAL_SOA_INSIGHTS, LOGOUT_URL} from '../constants'
import { getAccessToken, isAuthenticated } from './auth';

export const get = async (endpoint, useAuth = false) => {
  // Check if authenticated
  if (useAuth && !(await isAuthenticated(true))) {
  return Promise.reject('Not authenticated');
  }
  const headers = {
    'Access-Control-Request-Method': 'GET',
    'Content-Type': 'application/json'
  };
  if (useAuth) {
    headers['Authorization'] = `Bearer ${getAccessToken()}`;
  };
  const response = await fetch(endpoint, {
    method: 'GET',
    headers: headers
  });
  // If API responds with an unauthorized exception, check for authentication and attempt to refresh token. Otherwise, reject request.
  if (response.status === 401 && !(await isAuthenticated(true))) {
    return Promise.reject('Not authenticated');
  }
  if (response.status !== 200) {
    return Promise.reject('No response');
  }
  return await response.json();
};
export const post = async (endpoint, data = {}, useAuth = false) => {
  // Check if authenticated
  if (useAuth && !(await isAuthenticated(true))) {
  return Promise.reject('Not authenticated');
  }
  const headers = {
    'Access-Control-Request-Method': 'POST',
    'Content-Type': 'application/json'
  };
  if (useAuth) {
    headers['Authorization'] = `Bearer ${getAccessToken()}`;
  };
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data)
  });

  // If API responds with an unauthorized exception, check for authentication and attempt to refresh token. Otherwise, reject request.
  if (response.status === 401 && !(await isAuthenticated(true))) {
    return Promise.reject('Not authenticated');
  }
  return await response.json();
};

export const getTrialList = async () => {
  return await get(LIST_ALL_TRIAL_URL, true);
}

export const DeleteTrialList = async (id) => {
  const data = {
    "module": "criteria",
    "method": "delStudy",
    "body": {
      "id": id
    }
  };
  return await post(DELETE_TRIAL_URL, data, true);
}

export const addStudy = async (create_trial:any, saved_filters:any) => {
  const data = {
    "module": "criteria",
    "method": "addStudy",
    "body": {
      "create_trial": create_trial,
      "saved_filters": saved_filters,
    }
  };
  return await post(ADD_TRIAL_URL, data, true);
}

export const checkTrialDataPreparation = async (trialId) => {
  const data = {
    "checkTrialDataPreparation": {
      "trialId": trialId
  }};
  return await post(CHECK_CREATE_TRIAL_STATUS_URL, data, true);
}

export const getCriteriaLibByNctId = async (nct_list, trialId) => {
  const data = {
    "trial_uuid": trialId
  };
  return await post(GET_CRITERIA_LIBRARY_URL, data, true);
}

export const updateStudy = async (params:any) => { 
  const data = {
    "module": "criteria",
    "method": "updateStudy",
    "body": params
  };
  return await post(UPDATE_TRIAL_URL, data, true);
}

export const listStudy = async () => {
  const data = {
    "studies": "",
    "method": "list"
  };
  return await post(LIST_SIMILAR_HISTORICAL_TRIAL_URL, data, true);
}

export const getSimilarhistoricalTrialById = async (trialId, soaFlag) => {
  const data = {
    "method": "list",
    "trial_uuid": trialId,
    "soaFlag": soaFlag
  };
  return await post(LIST_SIMILAR_HISTORICAL_TRIAL_URL, data, true);
}

export const getStudy = async (trial_uuid: String, scenario_id=null, username) => {
  const data = {
    "module": "criteria",
    "method": "getStudy",
    "body": {
      "trial_uuid": trial_uuid,
      "scenario_id": scenario_id,
      "user_name": username
    }
  };
  return await post(GET_TRIAL_URL, data, true);
}

export const getGetReferenceData = async () => {
  const data = {
    "studies": "",
    "method": "listIndication"
  };
  return await post(GET_REFERENCE_DATA_URL, data, true);
}

export const getStandardEvents = async (trial_uuid) => {
  const data = {
    "trial_uuid": trial_uuid
  };
  return await post(GET_SOA_EVENT_LIBRARY_URL, data, true);
}

export const getPatientFunnelData = async (params) => {
  const data = {
    "searchPatientFunnel": params
  };
  return await post(GET_PATIENT_FUNNEL_URL, data, true);
}

export const getSOAResource = async (nctids) => {
  const data = {
    "findSoaItem": {
      "nctList": nctids
    }
  };
  return await post(GET_SOA_RESOURCES_URL, data, true);
}

export const getIEResource = async (nctids, trialId) => {
  const data = {
    "findIeItem": {
      "nctList": nctids,
      "trialId": trialId
    }
  };
  return await post(GET_IE_RESOURCES_URL, data, true);
}

export const checkTrialPatientFunnelData = async (trialId) => {
  const data = {
    "checkTrialPatientFunnelData": {
      "trialId": trialId
    }
  };
  return await post(CHECK_PATIENT_FUNNEL_STATUS_URL, data, true);
}

export const update_patient_burden = async (trial_id, scenario_id, ts_id, soa, editNumbers, costLimits, burdenLimits, soa_dismiss_list, username) => {
  const data = {
    "trial_uuid": trial_id,
    "user_name":  username,
    "scenario_id": scenario_id,
    "ts_id": ts_id,
    "soa": soa,
    "editNumbers": editNumbers,
    "cost_limits": costLimits,
    "burden_limits": burdenLimits,
    "soa_dismiss_list":soa_dismiss_list,
   
  };
  return await post(SAVE_TRIAL_SOA_URL, data, true);
}

export const listMembers = async (user_pool_id) => {
  const data = {
    "userPoolId": user_pool_id
  };
  return await post(LIST_MEMBERS_URL, data, true);
}

export const createScenario = async (params:any, method?: string ) => {
  const data = {
    "module": "criteria",
    "method": method || "CreateScenario",
    "body": params,
   
  };
  return await post(CREATE_SCENARIO_URL, data, true);
}

export const saveIECriteria = async (params:any) => {
  const data = {
    "module": "criteria",
    "method": "updateStudy",
    "body": params
  };
  return await post(SAVE_CRITERIA_URL, data, true);
}


export const getIeCriteria = async (trial_uuid: String, scenario_id=null, username) => {
  const data = { 
    "body": {
      "trial_uuid": trial_uuid,
      "ts_id": localStorage.getItem('ts_id'),
      "user_name": username
    }
  };
  return await post(GET_TRIAL_IE_CRITERIA, data, true);
}

export const getTrialSoa = async (trial_uuid: String, scenario_id=null, username) => {
  const data = { 
    "body": {
      "trial_uuid": trial_uuid,
      "ts_id": localStorage.getItem('ts_id'),
      "user_name": username
    }
  };
  return await post(GET_TRIAL_SOA, data, true);
}

export const saveTemplateScenario = async (params:any) => {
  const data = {
    "module": "criteria",
    "method": "SaveTemplateScenario",
    "body": params
  };
  return await post(SAVE_TEMPLATE_SCENARIO_URL, data, true);
}

export const getTrialSoaInsights = async (trial_uuid: String, scenario_id=null, soa_insight_type, required_pb=true, require_historic=false) => {
  const data = { 
    "body": {
      "trial_uuid": trial_uuid,
      "ts_id": localStorage.getItem('ts_id'),
      "soa_insight_type": soa_insight_type,
      "required_pb": required_pb,
      "require_historic": require_historic
    }
  };
  return await post(GET_TRIAL_SOA_INSIGHTS , data, true);
}

export const applicationLogout = async () => {
  return await post(LOGOUT_URL, {}, true);
}