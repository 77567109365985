/* eslint-disable */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Form, Input, Button, Checkbox, Modal, Typography } from "antd";
import background from "../../assets/background.png";
import Footer from '../../components/Footer';
import "./index.scss";
import { idamLogin, retrieveIdamToken } from '../../utils/idam-utils';
import { PRIVACY_STATEMENT} from "../../utils/messages.js";
import { TDTokens, setTokens } from '../../utils/auth'
import pwcLogo from "../../assets/pwc-outline-logo-white.png";

const { Link } = Typography;

const backgroundImg = {
  background: "url(" + background + ")",
  backgroundRepeat: "no-repeat",
  overflow: "hidden",
  backgroundSize: "100%",
};

const LandingPage = (props: any) => {
  const [form] = Form.useForm();
  const [whetherAcknowledge, setWhetherAcknowledge] = useState(true)
  const [showDisclaimer, showShowDisclaimer] = useState(false)
  const [message, setMessage] = useState("")
  const [loggedin, setLoggedin] = useState(false)
  const handleOk = (msg) => {
    let showDisclaimerTemp = showDisclaimer;
    showShowDisclaimer(!showDisclaimerTemp)
    setMessage(msg)
  }

  useEffect(() => {
    const getAllTokens = async () => {
      let response = await retrieveIdamToken();
      if (response) {

        /** object key manipulation logic needs to be knocked out after SSO integration */
        let obj = response;
        const altObj = Object.fromEntries(
          Object.entries(obj).map(([key, value]) =>
            [`${key}`.replace(/(?:_| |\b)(\w)/g, function ($1) { return $1.toUpperCase().replace('_', '') }), value]
          )
        )
        altObj['SSO'] = true;
        setTokens(altObj);
        setLoggedin(true);
      }
    }
    getAllTokens()
  }, [])

  useEffect(() => {
    if (!loggedin) return;
    props.history.push("/trials");
    localStorage.setItem('show-details', 'false')
    localStorage.setItem('trial-id', '')
  }, [loggedin])

  const usernameLogin = async (values: any) => {
    idamLogin()
  };


  const onChange = () => {
    setWhetherAcknowledge(!whetherAcknowledge)
  }

  return (
    <>
      <div className="login__container">
        <div className="continer__left" style={backgroundImg}></div>
        <div className="continer__right">
          <div className="login__form-wrapper">
          <div > <img className= "pwc-logo" src={pwcLogo}  /> </div>
            <div className="welcome__title">
              Welcome to <br />
              Intelligent Clinical Trial Design
            </div>

            <div className="login__form">
              <Form
                name="normal_login"
                className="login-form"
                onFinish={usernameLogin}
                size="small"
                form={form}
              >
                {/* <label htmlFor="username" className="form__label">
                  Email
                </label>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      type: "email",
                      message: "Please input a valid email!",
                    },
                  ]}
                  className="input__form-name"
                >
                  <Input size="small" />
                </Form.Item> */}

                <Form.Item style={{ marginTop: "40px" }}>
                  <Checkbox onChange={onChange} className="checkbox-acknowledge-wrapper">
                  </Checkbox>
                  <div className="checkbox-acknowledge">
                    I have read and acknowledge the
                    <span className="link-acknowledge" onClick={() => handleOk(PRIVACY_STATEMENT)}> Privacy Statement </span>
                    and have read and agree to the
                    {/* <span className="link-acknowledge" onClick={() => handleOk( TERMS_OF_USE)}> Terms of Service</span> */}
                    <Link href="https://productcentral.products.pwc.com/us/en/legal/terms-of-use.html" target="_blank" className="link-acknowledge" style={{ color: "#d04a02" }}> Terms of Service</Link>
                    .
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-button"
                    disabled={whetherAcknowledge}
                  >
                    LOGIN
                  </Button>
                  {/* <div className="forget__password">Forgot password?</div> */}
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        <Modal visible={showDisclaimer} className="disclaimersModal" maskClosable={true} onOk={handleOk} closable={false}
          footer={[
            <Button key="back" type="primary" onClick={handleOk} >
              OKAY
            </Button>
          ]}
        >
          <div><p className="message">{message}</p></div>
        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(LandingPage);
