/* eslint-disable */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Layout, Popconfirm, Tabs, Menu, Input, Breadcrumb } from "antd";
import { SearchOutlined, HomeOutlined } from "@ant-design/icons";
import TrialPortfolio from "../TrialPortfolio";
import FooterCompt from '../../components/Footer';
import ScenarioPage from '../ScenarioPage';
import NewScenarioPage from '../NewScenarioPage';
import Literature from '../Literature';
import CompareScenario from '../CompareScenario';
import NewTrial from '../NewTrial';
import ChartSummary from '../ChartSummary';
import { getFullName, getEmail } from "../../utils/auth";
import { logout } from "../../utils/auth";
import AddActivitySoa from '../../components/AddActivitySoa'
import AddActivityIE from '../../components/AddActivityIE'
import ShowProtocolAmendment from '../../components/ShowProtocolAmendment'
import iconPwcLogo from "../../assets/pwc-logotype-lockup.png";

import "./index.scss";

const { Header, Content ,Footer} = Layout;

const GlobalLayout = (props: any) => {
  const [searchTxt, setSearchTxt] = useState("");
  const [showSearch, setShowSearch] = useState(false)
  const [current, setCurrent] = useState("trial")
  const email = getEmail();
  const fullName = getFullName() ?? "";
  const initials = fullName.split(' ').map((name) => name[0]).join('');
  let content: any;

  // const onTextChange = (e) => {
  //   setSearchTxt(e.target.value);
  // };

  function confirm() {
    logout(props.history);
    localStorage.setItem('show-details', 'false')
    localStorage.setItem('trial-id', '')
  }

  const renderContent = (searchTxt = "") => {
    const current = props.location.pathname;
    switch (current) {
      case "/trials":
        content = <TrialPortfolio keyWords={searchTxt} showSearch={ showSearch} setShowSearch={(v)=>setShowSearch(v)}/>;
        break;
      case "/scenario":
        content = (
          // <ScenarioPage/>
          <NewScenarioPage/>
        );
        break;
      case "/new-trial":
        content = (
          <NewTrial/>
        );
        break;
      case "/literature":
        content = (
          <Literature/>
        );
        break;
      case "/compare-scenario":
      content = (
       <CompareScenario/>
      );
         break;
      case "/add-activity-soa":
        content = (
          <AddActivitySoa />
        )
        break;
      case "/add-activity-ie":
        content = (
          <AddActivityIE />
        )
        break;
      case "/show-pa":
        content = (
          <ShowProtocolAmendment />
        )
        break;
      case "/chart-summary":
        content = (
        <ChartSummary/>
        );
        break;
      case "/old-scenario":
        content = (
          <ScenarioPage/> 
        );
        break;
      default:
        break;
    }
    return content;
  };
  const handleClick = (e) => {
    setCurrent(e.key)
    props.history.push('/'+e.key)
    window.location.reload()
    localStorage.setItem('processStep', JSON.stringify(0));
    localStorage.setItem('activeTabKey', JSON.stringify('1'));
  }

  return (
    <Layout className="intell-trial-layout">
      <Header>
        <div className="header-left">
        <div className="pwc-logo-placeholder">
            <img className= "pwc-logo-image" src={iconPwcLogo}  />
        </div>
          <div className="system__info">
            <span className="system__info_title" 
            // onClick={()=>{
            //   props.history.push('/trials')
            //   localStorage.setItem('processStep', JSON.stringify(0));
            //   localStorage.setItem('activeTabKey', JSON.stringify('1'));}}
            >
                Intelligent Clinical Trial Design
              </span>
          </div>
          {/* <div className="menu-box">
            <Menu mode="horizontal" defaultSelectedKeys={['trials']} selectedKeys={[current]} onClick={handleClick}>
              <Menu.Item key="trials">My Trials</Menu.Item>
              <Menu.Item key="literature">Literature Search</Menu.Item>
            </Menu>
          </div> */}
        </div>
        <div className="header-right">
          {/* {
            showSearch && <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ width: 200, height: 30 }}
            onChange={onTextChange}
            value={searchTxt}
          />
          }       */}
          <div className="user__info">
            <Popconfirm
              className="logout__container"
              title={
                <div className="info__pop">
                  <div className="user__tag">
                    {initials}
                  </div>
                  <div className="username">
                    <div>{fullName}</div>
                    <span>{email}</span>
                  </div>
                </div>
              }
              icon={null}
              placement="bottomLeft"
              onConfirm={confirm}
              okText="Sign out"
              okType="text"
              cancelText={<div className="cancel_btn"></div>}
            >
              <div className="user__tag">
                {initials}
              </div>
            </Popconfirm>
          </div>
        </div>
      </Header>
      <Content>
        {/* <Breadcrumb>
            <Breadcrumb.Item
              className="homepage"
              // onClick={() => setShowDetails(false)}
            >
              <span>
                <HomeOutlined />
                My Trials
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Trial Design</Breadcrumb.Item>
          </Breadcrumb> */}
        {renderContent(searchTxt)}
      </Content>
       <Footer><FooterCompt/></Footer>
    </Layout>
  );
};

export default withRouter(GlobalLayout);
