import { combineReducers } from 'redux';
import trialReducer from './trialReducer';
import historyReducer from './historyReducer';
import soaReducer from './soaReducer';
import ieReducer from './ieReducer';

export default combineReducers({
    trialReducer,
    historyReducer,
    soaReducer,
    ieReducer
})
