import React, { useEffect, useState, } from "react";
import { Col, Row, Breadcrumb, Button, Dropdown, Menu } from "antd"; 
import { DownOutlined,DownloadOutlined, MenuFoldOutlined} from "@ant-design/icons";
import "./index.scss";

const IEScenarioPanel = (props) => {  
    const { scenario, processStep, activeTabKey, handleExport, setActiveTabKey, setProcessStep, onClickPrevious, onClickMyTrials, setSubmitType, tabChangeHandler, disableSaveFinish, readOnly} = props;
    const [ titleDescription, setTitleDescription] = useState({title:'', description: ''})

    useEffect(() => {  
        setTitleDescription({
            title: activeTabKey == 1 ? 'Add Inclusion Criteria': 'Add Exclusion Criteria', 
            description: activeTabKey == 1 ? 'Use the historical trial library on the left to build the I/E criteria for your trial scenario.' :
            'Use the historical trial library on the left to build the I/E criteria for your scenario.'
        }) 
    }, [activeTabKey])

    useEffect(() => {  
        if (processStep == 1) {
            setTitleDescription({
                title: 'Schedule of Activities', 
                description: 'Use the historical activity library on the left to build the Schedule of Activities.'
            })
        } 
    }, [processStep])
    
     return (
      <>
        <div className="export-container">
          <Row className="export-row export-navigations">
          <Col span={24} className="breadcrumbs-container"> 
          <Breadcrumb>
            <Breadcrumb.Item
              className="homepage"
              onClick={onClickMyTrials}
            >
              <span>
                My Trials
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item  className="homepage"
             onClick={onClickPrevious}>Trial Page</Breadcrumb.Item>
            <Breadcrumb.Item> {scenario['scenario_name']} </Breadcrumb.Item>
          </Breadcrumb>
          </Col>
          </Row>
          <Row className="export-row">
          <Col span={1} className="breadcrumbs"> <MenuFoldOutlined className="menu" style={{ fontSize: '26px' }}/> </Col>
            <Col span={9}>
              
               <Row>
                        <Col span={24}><span className="tab-title">{titleDescription['title']}</span></Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                        <span className="tip1-desc">
                         {titleDescription['description']}
                        </span>
                        </Col>
                      </Row>
            </Col>
            <Col span={processStep === 0 ? 14 : 14}>
            {activeTabKey === '1'&& processStep === 0 &&(
                    <>
                        <Button type="primary" className="step-btn" onClick={() => {
                          setActiveTabKey('2')
                          tabChangeHandler("2")
                        }}>
                            NEXT:EXCLUSION CRITERIA
                        </Button>
                    </>
                )}
                {activeTabKey === '2'&& processStep === 0&&
                    <>
                        <Button type="primary" className="step-btn" onClick={() => {
                          setProcessStep(1)
                          tabChangeHandler(null)
                        }}>
                            NEXT:ADD SCHEDULE OF ACTIVITIES
                        </Button>
                        <Button className="view-btn step-btn" onClick={() => {
                          setActiveTabKey('1')
                          tabChangeHandler("1")
                      }}>
                            PREV:INCLUSION CRITERIA
                        </Button>
                    </>}
                    {processStep === 1&&
                      <>
                      <Button type="primary" className="step-btn" disabled={disableSaveFinish||readOnly? true : false} onClick={()=> setSubmitType(2)}>
                           SAVE AND FINISH LATER
                       </Button>
                      <Button className="view-btn step-btn" onClick={() => {
                        setProcessStep(0)
                        setActiveTabKey('2')
                        tabChangeHandler("2")
                      }
                      }>
                          PREV:EXCLUSION CRITERIA
                      </Button>
                      </>}
            </Col>
            {/* {processStep === 0 &&(
                 <Col span={0} style={{paddingRight: '20px', display: "none"}}>
                 <Dropdown.Button style={{zIndex: 1}}
                   overlay={
                     <Menu>
                       <Menu.Item key="pdf" onClick={() => handleExport('pdf')}>PDF</Menu.Item>
                       <Menu.Item key="csv" onClick={() => handleExport('csv')}>CSV</Menu.Item>
                     </Menu>
                   }
                   icon={<DownOutlined />}>
                   <DownloadOutlined />
                   EXPORT AS
                 </Dropdown.Button>
               </Col>
            )}  */}
          </Row>
        </div>
       </>
     )
   };
   
   export default IEScenarioPanel;