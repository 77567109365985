/* eslint-disable */
import React, { useState, useEffect, useReducer, useCallback, memo} from 'react';
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import FileSaver from 'file-saver';
import {Button, Collapse, Slider, Dropdown,Menu, Row, Col, Tabs, Tooltip, Spin, message, Steps,Drawer, Input, Typography, Popover, Table, Breadcrumb, Badge } from "antd";
import type { ColumnsType } from 'antd/es/table';
import {updateStudy, getSimilarhistoricalTrialById, getCriteriaLibByNctId, getSOAResource, getIEResource, getPatientFunnelData, checkTrialPatientFunnelData, saveIECriteria, getIeCriteria, getTrialSoa} from "../../utils/ajax-proxy";
import { getEmail, getUsername, getAdminRole } from "../../utils/auth";
import {withRouter } from 'react-router';
import {LeftOutlined, HistoryOutlined, CloseOutlined, EditFilled, DownOutlined,DownloadOutlined, LoadingOutlined, ArrowRightOutlined, SearchOutlined, CheckOutlined,MinusOutlined,PlusOutlined, RightOutlined, FileTextFilled, CarryOutFilled, MenuFoldOutlined, HomeOutlined, DeleteOutlined } from "@ant-design/icons";
import ReactECharts from 'echarts-for-react';
import "./index.scss";
import CriteriaOption from "../../components/CriteriaOption";
import EditTable from "../../components/EditTable";
import SelectableTable from "../../components/SelectableTable";
import ScheduleEvents from "../../components/ScheduleEvents";
import { isAuthenticated } from "../../utils/auth";
import ScenarioLeftPanel from '../../components/ScenarioLeftPanel'
import IEScenarioPanel from '../../components/IEScenarioPanel';
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Step } = Steps;
const { Paragraph, Text } = Typography;

const frequencyFilter = [5, 100]
const inActiveChartColors = ['#DADADA', '#DADADA', '#DADADA', '#DADADA']
// const activeChartColors = ['#E53500', '#F27A26', '#F5924D', '#FBD0B3']
const activeChartColors = [
"#6F2849", "#9C3F5D", "#C8586C", "#DC7075", "#B13E64", "#CA5268", "#DC676C", "#EA8170", "#F19B7C", "#F5B68D", "#F6D1A9", "#FBE5C4"]
const simliarTrialStudyStartDate = { dateFrom: 1990, dateTo: 2025}

const colorList = {
  'AMERICAN INDIAN/ALASKA NATIVE': '#CA4A04',
  'ASIAN': '#E84F22',
  'BLACK/AFRICAN AMERICAN': '#F27A26',
  'HISPANIC/LATINO': '#E68700',
  'MULTI RACE ETHNICITY': '#F5924D',
  'NATIVE HAWAIIAN/OTHER PACIFIC ISLANDER': '#CA4A044D',
  'OTHER': '#FBD6BD',
  'UNKNOWN': '#FBD0B3',
  'WHITE': '#FDECE0',
  "HIGHLIGHTED":'#FDECE0',
  "NOT HIGHLIGHTED": '#E84F22',
  "ACTIVE LEGEND": '#000000'
}


const panelHeader = () => {
    return (
        <div className="trial-panelHeader">
            <div>
                <div className="bar-desc"><span>Impact</span></div>
                <div className="item-desc"><div className="bar-item item1"></div><span>Labs / Tests</span></div>
                <div className="item-desc"><span className="bar-item item2"></span><span>Intervention</span></div>
                <div className="item-desc"><span className="bar-item item3"></span><span>Demographics</span></div>
                <div className="item-desc"><span className="bar-item item4"></span><span>Medical Condition</span></div>
            </div>
        </div>
    );
};

const defaultChartValue = [
  {value: 0, name: 'Labs / Tests'},
  {value: 0, name: 'Intervention'},
  {value: 0, name: 'Demographics'},
  {value: 0, name: 'Medical Condition'}
]

const initialTrial = {
    scenarios:[]
}
const initialScenario = {
    scenario_id: "",
    scenario_name: "",
    scenario_description: "",
    protocol_amendment_rate: "",
    screen_failure_rate: "",
    patient_burden: "",
    cost: "",
    "Inclusion Criteria": {},
    "Exclusion Criteria": {},
    "Enrollment Feasibility": {},
    "Schedule of Events": {}
};

let resultdata = [80, 100, 200, 250, 300, 400, 475, 500];
let femaleFreq = [20, 24, 25, 30, 35, 49, 52, 55];
let raceFreq = [5, 8, 9, 10, 15, 20, 24, 25];

const CATEGORY_LABS = 'Labs';
const CATEGORY_PHYSICAL_EXAMINATION = 'Physical Examination';
const CATEGORY_PROCEDURES = 'Procedures';
const CATEGORY_QUESTIONNAIRES = 'Questionnaires';
const CATEGORY_STUDY_PROCEDURES = 'Study Procedures';

const visitDimensionalScore = [
  {Dimension: 'AnxietyInducing', Value: 5},
  {Dimension: 'HospitalDependent', Value: 25},
  {Dimension: 'PhysicallyInvasive', Value: 10},
  {Dimension: 'BloodDraw', Value: 15},
  {Dimension: 'Sedation', Value: 35},
  {Dimension: 'Injection', Value: 15},
  {Dimension: 'Urine', Value: 5},
  {Dimension: 'RequireFasting', Value: 7},
  {Dimension: 'LongerThanTwoHours', Value: 20},
  {Dimension: 'Questionnaire', Value: 5}
]

const ScenarioPage = (props) => {

    const isAdmin = localStorage.getItem('user_type').toUpperCase() == 'ADMIN';
    //Common cons
    const [trialTitle, setTrialTitle] = useState('')
    const [trialRecord, setTrialRecord] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { ...initialTrial }
    );
    const [scenario, setScenario] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { ...initialScenario }
    );

    const [dismissInclu, setDismissInclu] = useState([])
    const [dismissExclu, setDismissExclu] = useState([])
    const [insightInclu, setInsightInclu] = useState(scenario['ie_insights'] && scenario['ie_insights']['Inclusion'] || [])
    const [insightExclu, setInsightExclu] = useState(scenario['ie_insights'] && scenario['ie_insights']['Exclusion']||[])

    const [scenarioId, setScenarioId] = useState('')
    const [avgFileKey, setAvgFileKey] = useState('')
    const [scenarioType, setScenarioType] = useState('')
    const [activeEnrollmentTabKey, setActiveEnrollmentTabKey] = useState('1')
    const [activeTabKey, setActiveTabKey] = useState(() => {
      const storedCount = localStorage.getItem('activeTabKey');
      return storedCount ? JSON.parse(storedCount) : '1';
    })
    const [processStep, setProcessStep] = useState(() => {
      const storedCount = localStorage.getItem('processStep');
      return storedCount ? JSON.parse(storedCount) : 0;
    })
    const [submitType, setSubmitType] = useState(0)
    const [similarHistoricalTrials, setSimilarHistoricalTrials] = useState([])
    const [spinning, setSpinning] = useState(false)
    const [showChartLabel, setShowChartLabel] = useState(false)
    const [pageLoading, setPageLoading] = useState(true)

    const [showHistorical, setShowHistorical] = useState(false)
    const [historicalTrialdata, setHistoricalTrialdata] = useState([])
    const [freqColor, setFreqColor] = useState('#EF7A57')
    const [freqFontColor, setFreqFontColor] = useState('#fff')
    const [totalData, setTotalData] = useState([])
    const [freqData, setFreqdata] = useState([])
    const [chartTitle, setChartTitle] = useState('Patients Eligible - 80K(16% of Dataset)')
    const [visibleSOA, setVisibleSOA] = useState(false)
    const [soaResource, setSOAResource] = useState([])
    const [ieResource, setIEResource] = useState('')
    const [avgResource, setAvgResource] = useState(false)
    const [inclusionResourceAvg, setInclusionResourceAvg] = useState([])
    const [exclusionResourceAvg, setExclusionResourceAvg] = useState([])
    const [disableSaveFinish, setDisableSaveFinish] = useState(true)

    //------------------------INCLUSION CRITERIA CONST START-----------------------------
    //Original libs for filter purpose
    const [originDemographics, setOriginDemographics] = useState([])
    const [originIntervention, setOriginIntervention] = useState([])
    const [originMedCondition, setOriginMedCondition] = useState([])
    const [originLabTest, setOriginLabTest] = useState([])

    const [searchDemographics, setSearchDemographics] = useState([])
    const [searchIntervention, setSearchIntervention] = useState([])
    const [searchMedCondition, setSearchMedCondition] = useState([])
    const [searchLabTest, setSearchLabTest] = useState([])
    //Filtered libs for display and selection purpose
    const [demographics, setDemographics] = useState([])
    const [intervention, setIntervention] = useState([])
    const [medCondition, setMedCondition] = useState([])
    const [labTest, setLabTest] = useState([])

    //For inclusion chart data display
    const [protocolRateData, setProtocolRateData] = useState(defaultChartValue)
    const [screenRateData, setScreenRateData] = useState(defaultChartValue)
    const [therapeutic_Amend_Avg, setTherapeutic_Amend_Avg] = useState('')
    const [therapeutic_Screen_Avg, setTherapeutic_Screen_Avg] = useState('')
    const [impactColors, setImpactColors] = useState(inActiveChartColors)
    const [amend_avg_rate, setAmend_avg_rate] = useState('')
    const [screen_avg_rate, setScreen_avg_rate] = useState('')

    const [criteria_count_inclusion, setCriteria_count_inclusion] = useState('0')
    const [criteria_count_exclusion, setCriteria_count_exclusion] = useState('0')
    const [criteria_par_amendment_count_inclusion, setCriteria_par_amendment_count_inclusion] = useState('0')
    const [criteria_par_amendment_count_exclusion, setCriteria_par_amendment_count_exclusion] = useState('0')
    const [total_par_amendment_count, setTotal_par_amendment_count ] = useState('0')
    const [par_historical_trial_average, setPar_historical_trial_average ] = useState(0)
    const [protocol_amendment_rate, setProtocol_amendment_rate ] = useState('')
    const [protocol_amendment_rate_inclusion, setProtocol_amendment_rate_inclusion ] = useState('0')
    const [protocol_amendment_rate_exclusion, setProtocol_amendment_rate_exclusion ] = useState('0')

    const [criteria_sfr_amendment_count_inclusion, setCriteria_sfr_amendment_count_inclusion] = useState('0')
    const [criteria_sfr_amendment_count_exclusion, setCriteria_sfr_amendment_count_exclusion] = useState('0')
    const [total_sfr_amendment_count, setTotal_sfr_amendment_count ] = useState('0')
    const [sfr_historical_trial_average, setSfr_historical_trial_average ] = useState(0)
    const [screen_failure_rate, setScreen_failure_rate ] = useState('')
    const [screen_failure_rate_inclusion, setScreen_failure_rate_inclusion ] = useState('0')
    const [screen_failure_rate_exclusion, setScreen_failure_rate_exclusion ] = useState('0')

    const [rollHeight, setRollHeight] = useState(true)            // Control editTable scroll height
    const [visible, setVisible] = useState(false)                 // Control libs filter slider bar display or not
    const [minValue, setMinValue] = useState(frequencyFilter[0])  //Slider bar minimon value
    const [maxValue, setMaxValue] = useState(frequencyFilter[1])  //Slider bar maximom value
    const [defaultActiveKey, setDefaultActiveKey] = useState([])  //default expanded collapse for edittable
    const [activeKey, setActiveKey] = useState([])                //To control chart collapse expanding
    const [collapsible, setCollapsible] = useState(true)// Set collapse can be click to collapse/expand or not
    const [criteriaLib, setCriteriaLib] = useState(6)
    const [inclusionLibrarayPanelStatus, setInclusionLibraryPanelStatus] = useState(true);
    const [exclusionLibrarayPanelStatus, setExclusionLibraryPanelStatus] = useState(true)
    const [inclusionPanel, setInclusionPanel] = useState("1")
    const [exclusionPanel, setExclusionPanel] = useState("1")
    const IeCriteriaInsightsToggleHandler = (status) => {
      setInclusionLibraryPanelStatus(status);
      setCriteriaLib(!status ? 0 : 6)
    }
    const ExclusionCriteriaInsightsToggleHandler = (status) => { 
      setExclusionLibraryPanelStatus(status)
      setExcluCriteriaLib(!status ? 0 : 6)
    }
    //To store the selected inclusion criteria libs
   let [demographicsElements, setDemographicsElements] = useState([])
   let [interventionElements , setInterventionElements ] = useState([])
   let [medConditionElements, setMedConditionElements] = useState([])
   let [labTestElements, setLabTestElements] = useState([])

    // inclusion criteria data for EditTable
  let [demographicsTableData, setDemographicsTableData] = useState([])
  let [interventionTableData, setInterventionTableData] = useState([])
  let [medConditionTableData, setMedConditionTableData] = useState([])
  let [labTestTableData, setLabTestTableData] = useState([])

  const [searchTxt,setSearchTxt] = useState("")
  const [searchTxtExclu,setSearchTxtExclu] = useState("")

  const [visibleValue, setVisibleValue] = useState(false)
  const [visibleValueExclu, setVisibleValueExclu] = useState(false)

    //------------------------INCLUSION CRITERIA CONST END-----------------------------

    //------------------------EXCLUSION CRITERIA CONST START-----------------------------
    //Original libs for filter purpose
    const [originExcluDemographics, setOriginExcluDemographics] = useState([])
    const [originExcluIntervention, setOriginExcluIntervention] = useState([])
    const [originExcluMedCondition, setOriginExcluMedCondition] = useState([])
    const [originExcluLabTest, setOriginExcluLabTest] = useState([])

    const [searchDemographicsExclu, setSearchDemographicsExclu] = useState([])
    const [searchInterventionExclu, setSearchInterventionExclu] = useState([])
    const [searchMedConditionExclu, setSearchMedConditionExclu] = useState([])
    const [searchLabTestExclu, setSearchLabTestExclu] = useState([])

    //Filtered libs for display and selection purpose
    const [excluDemographics, setExcluDemographics] = useState([])
    const [excluMedCondition, setExcluMedCondition] = useState([])
    const [excluIntervention, setExcluIntervention] = useState([])
    const [excluLabTest, setExcluLabTest] = useState([])

    //For exclusion chart data display
    const [excluProtocolRateData, setExcluProtocolRateData] = useState(defaultChartValue)
    const [excluScreenRateData, setExcluScreenRateData] = useState(defaultChartValue)
    const [exclu_Therapeutic_Amend_Avg, setExcluTherapeutic_Amend_Avg] = useState('Therapeutic Area Average - 40%')
    const [exclu_Therapeutic_Screen_Avg, setExcluTherapeutic_Screen_Avg] = useState('Therapeutic Area Average - 35%')
    const [excluImpactColors, setExcluImpactColors] = useState(inActiveChartColors)
    const [excluAmend_avg_rate, setExcluAmend_avg_rate] = useState('')
    const [excluScreen_avg_rate, setExcluScreen_avg_rate] = useState('')

    const [excluRollHeight, setExcluRollHeight] = useState(true)            // Control editTable scroll height
    const [excluVisible, setExcluVisible] = useState(false);                // Control libs filter slider bar display or not
    const [excluMinValue, setExcluMinValue] = useState(frequencyFilter[0])  // Slider bar minimum value
    const [excluMaxValue, setExcluMaxValue] = useState(frequencyFilter[1])  // Slider bar maximum value
    const [excluDefaultActiveKey, setExcluDefaultActiveKey] = useState([])  //default expanded collapse for edittable
    const [excluActiveKey, setExcluActiveKey] = useState([])                //To control chart collapse expanding
    const [excluCollapsible, setExcluCollapsible] = useState(true)          // Set collapse can be click to collapse/expand or not
    const [excluCriteriaLib, setExcluCriteriaLib] = useState(6)

    //To store the selected exclusion criteria libs
    let [excluDemographicsElements, setExcluDemographicsElements] = useState([])
    let [excluMedConditionElements , setExcluMedConditionElements ] = useState([])
    let [excluInterventionElements, setExcluInterventionElements] = useState([])
    let [excluLabTestElements, setExcluLabTestElements] = useState([])

    // exclusion criteria data for EditTable
  let [excluDemographicsTableData, setExcluDemographicsTableData] = useState([])
  let [excluInterventionTableData, setExcluInterventionTableData] = useState([])
  let [excluMedConditionTableData, setExcluMedConditionTableData] = useState([])
  let [excluLabTestTableData, setExcluLabTestTableData] = useState([])

    //------------------------EXCLUSION CRITERIA CONST END-----------------------------

    const [eliPatient, setEliPatient] = useState(0)
  const [rateEliPatient, setRateEliPatient] = useState('')
  const [rateFeEliPatient, setRateFeEliPatient] = useState('')
  const [enrollCriteriaLib, setEnrollCriteriaLib] = useState([])
  const [enrollCriteriaLibBelow, setEnrollCriteriaLibBelow] = useState([])
  const [finalEthnicityData, setFinalEthnicityData] = useState([])
  const [ethLegendColor, setEthLegendColor] = useState([])

  const [eliPatientChartTitle, setEliPatientChartTitle] = useState('')
  const [eliPatientResultData, setEliPatientResultData] = useState([])
  const [fePatientChartTitle, setFePatientChartTitle] = useState('')
  const [fePatientResultData, setFePatientResultData] = useState([])
  const [ethPatientChartTitle, setEthPatientChartTitle] = useState('')
  const [ethPatientResultData, setEthPatientResultData] = useState([])

  const [loadPatientFunnel, setLoadPatientFunnel] = useState(false)
  const [reloadPTData, setReloadPTData] = useState(false)
  const [initPTData, setInitPTData] = useState(true)
  const [funnelChartheight, setFunnelChartheight] = useState(200)
  const [readOnlyAccess, setReadOnlyAccess] = useState(true)
  // const [funnelChartheightBelow, setFunnelChartheightBelow] = useState(200)
  const [funnelChartheightOverlap, setFunnelChartheightOverlap] = useState(100) 

  useEffect(() => {
    const data = async() => {
      const response = await getTrialSoa(props.location.state.trial_id, props.location.state.scenarioId, getEmail()); 
      if(!response) return; 
      props.createSoa(response)
    }
    data();
  },[scenarioId])

  const getTrialById = async () => {
    const tempScenarioId = props.location.state.scenarioId 
    const resp = await getIeCriteria(props.location.state.trial_id, tempScenarioId, getEmail());  
    if (resp) {
      ReactDOM.unstable_batchedUpdates(() => {
        console.log("getlist", resp);
        const tempRecord = resp
        if(tempRecord && tempRecord.trial_user_role && tempRecord.trial_user_role.toLowerCase() == 'read') {
          setReadOnlyAccess(true)
        }else{
          setReadOnlyAccess(false)
        }
        setTrialRecord(tempRecord)
        setTrialTitle(tempRecord['trial_alias'])
        if (tempRecord.similarHistoricalTrials !== undefined) {
          setSimilarHistoricalTrials(tempRecord.similarHistoricalTrials)
        }
        const tempScenarioId = props.location.state.scenarioId
        const tempScenarioType = props.location.state.scenarioType
        const tempScenario = tempRecord.scenarios.find(i => i['scenario_id'] == tempScenarioId)
        setScenarioId(tempScenarioId)
        setScenarioType(tempScenarioType)
        setScenario(tempScenario)
        setInsightInclu(tempScenario['ie_insights'] && tempScenario['ie_insights']['Inclusion'])
        setInsightExclu(tempScenario['ie_insights'] && tempScenario['ie_insights']['Exclusion'])
        const totalParAmendmentCount = tempScenario.total_par_amendment_count;
        const parHistoricalTrialAverage = tempRecord.par_historical_trial_average;

        const totalSfrAmendmentCount = tempScenario.total_sfr_amendment_count;
        const sfrHistoricalTrialAverage = tempRecord.sfr_historical_trial_average;

        // const screenFailureRateFlag = tempRecord.xxx
        const screenFailureRateFlag = 'true'

        setTotal_par_amendment_count(totalParAmendmentCount)
        setPar_historical_trial_average(parHistoricalTrialAverage)

        setTotal_sfr_amendment_count(totalSfrAmendmentCount)
        setSfr_historical_trial_average(sfrHistoricalTrialAverage)

        if (tempScenario['ie_criteria'] != undefined && (tempScenario['ie_criteria']['Inclusion Criteria'] != undefined || tempScenario['ie_criteria']['Exclusion Criteria'] != undefined)) {

          let scenario_ie_data = tempScenario['ie_criteria'];

          const protocolAmendmentRate = tempScenario.protocol_amendment_rate;
          setProtocol_amendment_rate(protocolAmendmentRate)

          const screenFailureRate = tempScenario.screen_failure_rate;
          setScreen_failure_rate(screenFailureRate)

          //Get inclusion chart info
          let inclu = scenario_ie_data["Inclusion Criteria"]
          let emptyCategory = {
            "protocol_amendment_rate": "",
            "patient_burden": "",
            "Entities": [],
            "protocol_amendment_rate_state": "GOOD",
            "screen_failure_rate": "",
            "screen_failure_rate_state": "GOOD"
          }

          if (inclu !== undefined) {
            if (inclu.Demographics == undefined) { inclu.Demographics = emptyCategory }
            if (inclu.Intervention == undefined) { inclu.Intervention = emptyCategory }
            if (inclu['Medical Condition'] == undefined) { inclu['Medical Condition'] = emptyCategory }
            if (inclu['Lab / Test'] == undefined) { inclu['Lab / Test'] = emptyCategory }

            const protocolAmendmentRateInclusion = inclu.protocol_amendment_rate;
            setProtocol_amendment_rate_inclusion(protocolAmendmentRateInclusion)

            const screenFailureRateInclusion = inclu.screen_failure_rate;
            setScreen_failure_rate_inclusion(screenFailureRateInclusion)

            let criteriaCountInclusion = inclu.criteria_count_inclusion
            setCriteria_count_inclusion(criteriaCountInclusion)

            let criteriaParAmendmentCountInclusion = inclu.criteria_par_amendment_count_inclusion
            setCriteria_par_amendment_count_inclusion(criteriaParAmendmentCountInclusion)

            let criteriaSfrAmendmentCountInclusion = inclu.criteria_sfr_amendment_count_inclusion
            setCriteria_sfr_amendment_count_inclusion(criteriaSfrAmendmentCountInclusion)

            demographicsElements = inclu.Demographics.Entities.map((item) => { item["Values"] = formatValue(item); return item })
            interventionElements = inclu.Intervention.Entities.map((item) => { item["Values"] = formatValue(item); return item })
            medConditionElements = inclu['Medical Condition'].Entities.map((item) => { item["Values"] = formatValue(item); return item })
            labTestElements = inclu['Lab / Test'].Entities.map((item) => { item["Values"] = formatValue(item); return item })

            setProtocolRateData([
              { value: formatNumber(inclu['Lab / Test'].protocol_amendment_rate), name: 'Labs / Tests' },
              { value: formatNumber(inclu.Intervention.protocol_amendment_rate), name: 'Intervention' },
              { value: formatNumber(inclu.Demographics.protocol_amendment_rate), name: 'Demographics' },
              { value: formatNumber(inclu['Medical Condition'].protocol_amendment_rate), name: 'Medical Condition' }
            ])
            setScreenRateData([
              { value: formatNumber(inclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests' },
              { value: formatNumber(inclu.Intervention.screen_failure_rate), name: 'Intervention' },
              { value: formatNumber(inclu.Demographics.screen_failure_rate), name: 'Demographics' },
              { value: formatNumber(inclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition' }
            ])
            var tempScoreA = ''
            var tempScoreB = ''
            let protocol_amendment_state = inclu.protocol_amendment_rate_state
            let screen_failure_state = inclu.screen_failure_rate_state
            tempScoreA = '{p|' + inclu.criteria_par_amendment_count_inclusion + '}\n{' + protocol_amendment_state.toLowerCase() + '|' + protocol_amendment_state + '}'
            tempScoreB = '{p|' + inclu.criteria_sfr_amendment_count_inclusion + '}\n{' + screen_failure_state.toLowerCase() + '|' + screen_failure_state + '}'
            setAmend_avg_rate(tempScoreA)
            setScreen_avg_rate(tempScoreB)
            setShowChartLabel(true)
            console.log(' activeChartColors ', activeChartColors)
            setImpactColors(activeChartColors)
          }
          //Get exclusion chart info
          let exclu = scenario_ie_data["Exclusion Criteria"]
          if (exclu !== undefined) {
            if (exclu.Demographics == undefined) { exclu.Demographics = emptyCategory }
            if (exclu.Intervention == undefined) { exclu.Intervention = emptyCategory }
            if (exclu['Medical Condition'] == undefined) { exclu['Medical Condition'] = emptyCategory }
            if (exclu['Lab / Test'] == undefined) { exclu['Lab / Test'] = emptyCategory }
            excluDemographicsElements = exclu.Demographics.Entities.map((item) => { item["Values"] = formatValue(item); return item })
            excluMedConditionElements = exclu['Medical Condition'].Entities.map((item) => { item["Values"] = formatValue(item); return item })
            excluInterventionElements = exclu.Intervention.Entities.map((item) => { item["Values"] = formatValue(item); return item })
            excluLabTestElements = exclu['Lab / Test'].Entities.map((item) => { item["Values"] = formatValue(item); return item })

            setExcluProtocolRateData([
              { value: formatNumber(exclu['Lab / Test'].protocol_amendment_rate), name: 'Labs / Tests' },
              { value: formatNumber(exclu.Intervention.protocol_amendment_rate), name: 'Intervention' },
              { value: formatNumber(exclu.Demographics.protocol_amendment_rate), name: 'Demographics' },
              { value: formatNumber(exclu['Medical Condition'].protocol_amendment_rate), name: 'Medical Condition' }
            ])
            setExcluScreenRateData([
              { value: formatNumber(exclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests' },
              { value: formatNumber(exclu.Intervention.screen_failure_rate), name: 'Intervention' },
              { value: formatNumber(exclu.Demographics.screen_failure_rate), name: 'Demographics' },
              { value: formatNumber(exclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition' }
            ])

            var excluTempScoreA = ''
            var excluTempScoreB = ''

            const protocolAmendmentRate = exclu.protocol_amendment_rate;
            setProtocol_amendment_rate(protocolAmendmentRate)

            const screenFailureRate = exclu.screen_failure_rate;
            setScreen_failure_rate(screenFailureRate)

            const protocolAmendmentRateExclusion = exclu.protocol_amendment_rate
            setProtocol_amendment_rate_exclusion(protocolAmendmentRateExclusion)

            const screenFailureRateExclusion = exclu.screen_failure_rate
            setScreen_failure_rate_exclusion(screenFailureRateExclusion)

            let criteriaCountExclusion = exclu.criteria_count_exclusion
            setCriteria_count_exclusion(criteriaCountExclusion)

            let criteriaParAmendmentCountExclusion = exclu.criteria_par_amendment_count_exclusion;
            setCriteria_par_amendment_count_exclusion(criteriaParAmendmentCountExclusion)

            let criteriaSfrAmendmentCountExclusion = exclu.criteria_sfr_amendment_count_exclusion;
            setCriteria_sfr_amendment_count_exclusion(criteriaSfrAmendmentCountExclusion)

            let exclu_protocol_amendment_state = exclu.protocol_amendment_rate_state
            let exclu_screen_failure_state = exclu.screen_failure_rate_state

            excluTempScoreA = '{p|' + exclu.criteria_par_amendment_count_exclusion + '}\n{' + exclu_protocol_amendment_state.toLowerCase() + '|' + exclu_protocol_amendment_state + '}'
            excluTempScoreB = '{p|' + exclu.criteria_sfr_amendment_count_exclusion + '}\n{' + exclu_screen_failure_state.toLowerCase() + '|' + exclu_screen_failure_state + '}'
            setExcluAmend_avg_rate(excluTempScoreA)
            setExcluScreen_avg_rate(excluTempScoreB)
            setShowChartLabel(true)
            setExcluImpactColors(activeChartColors)
          }
        }
        if (tempRecord['therapeutic_area_average']) {
          setTherapeutic_Amend_Avg('Therapeutic Area Average - ' + tempRecord['therapeutic_area_average'].protocol_amendment_rate)
          setTherapeutic_Screen_Avg('Therapeutic Area Average - ' + tempRecord['therapeutic_area_average'].screen_failure_rate)
          setExcluTherapeutic_Amend_Avg('Therapeutic Area Average - ' + tempRecord['therapeutic_area_average'].protocol_amendment_rate)
          setExcluTherapeutic_Screen_Avg('Therapeutic Area Average - ' + tempRecord['therapeutic_area_average'].screen_failure_rate)
        }
        if (tempScenario['ie_criteria'] !== {}) {
          updateTrial(1, 2)
          updateTrial(2, 2)
        }
      });
    } else {
      message.error("Error in loading the scenario")
    }
  };

  const getCriteriaById = async () => {
    const scenarioId = props.location.state.trial_id
    const resp = await getCriteriaLibByNctId(props.location.state.trial_id, scenarioId);
    if(!resp || Object.keys(resp).length == 0) return;  
  };

  const getTrialCriteriaById = async () => {
    const scenarioId = props.location.state.trial_id
    const resp = await getCriteriaLibByNctId(props.location.state.trial_id, scenarioId);
    console.log("gettrialcriteria",JSON.parse(resp.body));
  };

  useEffect(() => {
     const checkAuthenticated = async () => {
      if (!(await isAuthenticated(true))) {
        props.history.push("/");
        localStorage.setItem('processStep', JSON.stringify(0));
        localStorage.setItem('activeTabKey', JSON.stringify('1'));
      }}
    if(props.location.state.trial_id == undefined || props.location.state.trial_id == ''){
      props.history.push({pathname: '/trials'})
      localStorage.setItem('processStep', JSON.stringify(0));
      localStorage.setItem('activeTabKey', JSON.stringify('1'));
    } else {
      getTrialById();
      getCriteriaById();
      checkAuthenticated();
    }
  }, []);

  useEffect(() => {

      const summaryDefaultList = async () => {
        const nctIdList = props.location.state.similarHistoricalTrials
        console.log("nctIdList",nctIdList);

        var resp
        // if(nctIdList != undefined && nctIdList instanceof Array && nctIdList.length > 0){
          console.log("input",props.location.state.similarHistoricalTrials);
          resp = await getCriteriaLibByNctId(props.location.state.similarHistoricalTrials, props.location.state.trial_id);
          // console.log("criteria",resp);
        // } else {
        //   resp = await getSummaryDefaultList();
        //   console.log("default",resp);
        // }
        setPageLoading(false)
          if (resp  && Object.keys(resp).length !== 0) {
//               setAvgFileKey(resp.csvKey)
              // const response = JSON.parse(resp.body)
              const response = resp;
              console.log("criteria result: ", response);
              const inclusionCriteria = response.Inclusion
              for(var key in inclusionCriteria){
                  if(key == "Medical Condition"){
                      setOriginMedCondition(inclusionCriteria['Medical Condition'])
                      setMedCondition(inclusionCriteria['Medical Condition'].filter((d) => {
                          return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
                      }))
                  } else if(key == "Demographics"){
                      setOriginDemographics(inclusionCriteria['Demographics'])
                      setDemographics(inclusionCriteria['Demographics'].filter((d) => {
                          return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
                      }))
                  } else if(key == "Lab / Test"){
                      setOriginLabTest(inclusionCriteria['Lab / Test'])
                      setLabTest(inclusionCriteria['Lab / Test'].filter((d) => {
                          return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
                      }))
                  } else if(key == "Intervention"){
                      setOriginIntervention(inclusionCriteria['Intervention'])
                      setIntervention(inclusionCriteria['Intervention'].filter((d) => {
                          return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
                      }))
                  }
              }

              const exclusionCriteria = response.Exclusion
              for(var key in exclusionCriteria){
                  if(key == "Medical Condition"){
                      setOriginExcluMedCondition(exclusionCriteria['Medical Condition'])
                      setExcluMedCondition(exclusionCriteria['Medical Condition'].filter((d) => {
                          return d.Frequency * 100 >= excluMinValue && d.Frequency * 100 <= excluMaxValue;
                      }))
                  } else if(key == "Demographics"){
                      setOriginExcluDemographics(exclusionCriteria['Demographics'])
                      setExcluDemographics(exclusionCriteria['Demographics'].filter((d) => {
                          return d.Frequency * 100 >= excluMinValue && d.Frequency * 100 <= excluMaxValue;
                      }))
                  } else if(key == "Lab / Test"){
                      setOriginExcluLabTest(exclusionCriteria['Lab / Test'])
                      setExcluLabTest(exclusionCriteria['Lab / Test'].filter((d) => {
                          return d.Frequency * 100 >= excluMinValue && d.Frequency * 100 <= excluMaxValue;
                      }))
                  } else if(key == "Intervention"){
                      setOriginExcluIntervention(exclusionCriteria['Intervention'])
                      setExcluIntervention(exclusionCriteria['Intervention'].filter((d) => {
                          return d.Frequency * 100 >= excluMinValue && d.Frequency * 100 <= excluMaxValue;
                      }))
                  }
              }
          }
      };
      summaryDefaultList();
    }, []);

  useEffect(() => {
    localStorage.setItem('activeTabKey', JSON.stringify(activeTabKey));
    getTrialById();
  }, [activeTabKey]);

  useEffect(() => {
    localStorage.setItem('processStep', JSON.stringify(processStep));
    getTrialById();
  }, [processStep]);

    const eChartsRef = React.useRef(null as any);
    const eChartsBelowRef = React.useRef(null as any);

    function getCatorgoryIndex(index, list){
      if(list[index]['Medical Condition'] != undefined){
          return 0;
      } else if(list[index]['Demographics'] != undefined){
          return 1;
      } else if(list[index]['Lab / Test'] != undefined){
          return 2;
      } else if(list[index]['Intervention'] != undefined){
          return 3;
      }
    }


    const formatter = (value) => {
      return value+'%'
    }

  const getFrequency = (value) => {

        setMinValue(value[0])
        setMaxValue(value[1])

        setMedCondition(originMedCondition.filter((d) => {
            return d.Frequency * 100 >= value[0] && d.Frequency * 100 <= value[1];
        }))
        setDemographics(originDemographics.filter((d) => {
            return d.Frequency * 100 >= value[0] && d.Frequency * 100 <= value[1];
        }))
        setLabTest(originLabTest.filter((d) => {
            return d.Frequency * 100 >= value[0] && d.Frequency * 100 <= value[1];
        }))
        setIntervention(originIntervention.filter((d) => {
            return d.Frequency * 100 >= value[0] && d.Frequency * 100 <= value[1];
        }))
    }

    const getExcluFrequency = (value) => {
      setExcluMinValue(value[0])
      setExcluMaxValue(value[1])

      setExcluMedCondition(originExcluMedCondition.filter((d) => {
          return d.Frequency * 100 >= value[0] && d.Frequency * 100 <= value[1];
      }))
      setExcluDemographics(originExcluDemographics.filter((d) => {
          return d.Frequency * 100 >= value[0] && d.Frequency * 100 <= value[1];
      }))
      setExcluLabTest(originExcluLabTest.filter((d) => {
          return d.Frequency * 100 >= value[0] && d.Frequency * 100 <= value[1];
      }))
      setExcluIntervention(originExcluIntervention.filter((d) => {
          return d.Frequency * 100 >= value[0] && d.Frequency * 100 <= value[1];
      }))
    }

    const handleOptionSelect = (item, activeType, id, key) =>{
      switch(id){
        case 0:
          var index = demographicsElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
          if(activeType == 1){
            if(index < 0){
              var newItem = {
                "Eligibility Criteria": item.Text,
                "Values": formatValue(item),
                "Timeframe": formatTime(item),
                "Frequency":item.Frequency,
                "Detail":item.details,
                "negation": item.negation
              }
              demographicsElements.push(newItem)
            }
          } else {
            if(index >= 0){
              demographicsElements.splice(index, 1)
            }
          }
          break;
        case 1:
          var index = medConditionElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
          if(activeType == 1){
            if(index < 0){
              var newItem = {
                "Eligibility Criteria": item.Text,
                "Values": formatValue(item),
                "Timeframe": formatTime(item),
                "Frequency":item.Frequency,
                "Detail":item.details,
                "negation": item.negation
              }
              medConditionElements.push(newItem)
            }
          } else {
            if(index >= 0){
              medConditionElements.splice(index, 1)
            }
          }
          break;
        case 2:
          var index = interventionElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
          if(activeType == 1){
            if(index < 0){
              var newItem = {
                "Eligibility Criteria": item.Text,
                "Values": formatValue(item),
                "Timeframe": formatTime(item),
                "Frequency":item.Frequency,
                "Detail":item.details,
                "negation": item.negation
              }
              interventionElements.push(newItem)
            }
          } else {
            if(index >= 0){
              interventionElements.splice(index, 1)
            }
          }
          break;
        default:
          var index = labTestElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
          if(activeType == 1){
            if(index < 0){
              var newItem = {
                "Eligibility Criteria": item.Text,
                "Values": formatValue(item),
                "Timeframe": formatTime(item),
                "Frequency":item.Frequency,
                "Detail":item.details,
                "negation": item.negation
              }
              labTestElements.push(newItem)
            }
          } else {
            if(index >= 0){
              labTestElements.splice(index, 1)
            }
          }
          break;
      }

      if(activeTabKey === '1') { // Inclusion Criteria
        updateTrial(1,1)
      } else if(activeTabKey === '2') { // Exclusion Criteria
        updateTrial(2,1)
      }
    }

    function formatValue(item){
      // this method is called during load and edit of IE builder
      if('Values' in item && item['Values']!=null){
         return item['Values']
      }
      var tempStr
        if (item.lower == 0 && item.upper != 0) {
            tempStr = "< "+ Number(item.upper?.toString().match(/^\d+(?:\.\d{0,2})?/))+ " " + item.units
        } else if (item.lower != 0 && (item.upper == 0 || item.upper == null)) {
            tempStr = "> "+ Number(item.lower?.toString().match(/^\d+(?:\.\d{0,2})?/))+ " " + item.units
        } else if (item.lower != 0 && item.upper != 0) {
            tempStr = Number(item.lower.toString().match(/^\d+(?:\.\d{0,2})?/))+ " - " + Number(item.upper?.toString().match(/^\d+(?:\.\d{0,2})?/)) + " " + item.units
        } else {
            tempStr = '-'
        }
      return tempStr
    }

    function formatTime(item){
      var tempStr
      if(item.time_frame && item.time_frame.trim() != ""){
        tempStr = item.time_frame
      } else {
        tempStr = '-'
      }
      return tempStr
    }

    const handleExcluOptionSelect = (item, activeType, id, key) =>{
      switch(id){
        case 0:
          var index = excluDemographicsElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
          if(activeType == 1){
            if(index < 0){
              var newItem = {
                "Eligibility Criteria": item.Text,
                "Values": formatValue(item),
                "Timeframe": formatTime(item),
                "Frequency":item.Frequency,
                "Detail":item.details,
                "negation": item.negation,
              }
              excluDemographicsElements.push(newItem)
            }
          } else {
            if(index >= 0){
              excluDemographicsElements.splice(index,1)
            }
          }
          break;
        case 1:
          var index = excluMedConditionElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
          if(activeType == 1){
            if(index < 0){
              var newItem = {
                "Eligibility Criteria": item.Text,
                "Values": formatValue(item),
                "Timeframe": formatTime(item),
                "Frequency":item.Frequency,
                "Detail":item.details,
                "negation": item.negation,
              }
              excluMedConditionElements.push(newItem)
            }
          } else {
            if(index >= 0){
              excluMedConditionElements.splice(index,1)
            }
          }
          break;
        case 2:
          var index = excluInterventionElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
          if(activeType == 1){
            if(index < 0){
              var newItem = {
                "Eligibility Criteria": item.Text,
                "Values": formatValue(item),
                "Timeframe": formatTime(item),
                "Frequency":item.Frequency,
                "Detail":item.details,
                "negation": item.negation,
              }
              excluInterventionElements.push(newItem)
            }
          } else {
            if(index >= 0){
              excluInterventionElements.splice(index,1)
            }
          }
          break;
        default:
          var index = excluLabTestElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation']);
          if(activeType == 1){
            if(index < 0){
              var newItem = {
                "Eligibility Criteria": item.Text,
                "Values": formatValue(item),
                "Timeframe": formatTime(item),
                "Frequency":item.Frequency,
                "Detail":item.details,
                "negation": item.negation,
              }
              excluLabTestElements.push(newItem)
            }
          } else {
            if(index >= 0){
              excluLabTestElements.splice(index,1)
          }
        }
        break;
      }

      if(activeTabKey === '1') { // Inclusion Criteria
        updateTrial(1,1)
      } else if(activeTabKey === '2') { // Exclusion Criteria
        updateTrial(2,1)
      }
    }

    const updateTrial = (type: number, res: number) => {
      // res: 1, update when loading page; 2, update when update criteria
      if(res == 1){
        setReloadPTData(true)
      }
      if (type == 1) {//Inclusion

        let demographicsElementsTmp = demographicsElements.map((item,index) =>{
          return Object.assign(item,{Key:(index + 1) + ''})
        })
        // let demographicsTableDataTmp = demographicsElementsTmp.filter(d => {
        //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
        // })
        setDemographicsElements(demographicsElementsTmp)
        setDemographicsTableData(demographicsElementsTmp.map((item, id) =>{
          item.Key = (id + 1) + ''
          return item
        }))
        console.log("demographicsElementsTmp",demographicsElementsTmp);

        let medConditionElementsTmp = medConditionElements.map((item,index) =>{
          return Object.assign(item,{Key:(index + 1) + ''})
        })
        // let medConditionTableDataTmp = medConditionElementsTmp.filter(d => {
        //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
        // })
        setMedConditionElements(medConditionElementsTmp)
        setMedConditionTableData(medConditionElementsTmp.map((item, id) =>{
          item.Key = demographicsElementsTmp.length + (id + 1) + ''
          return item
        }))
        console.log("medConditionElementsTmp",medConditionElementsTmp);


         let interventionElementsTmp = interventionElements.map((item,index) =>{
          return Object.assign(item,{Key:(index + 1) + ''})
        })
        //  let interventionTableDataTmp = interventionElementsTmp.filter(d => {
        //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
        // })
        setInterventionElements(interventionElementsTmp)
        setInterventionTableData(interventionElementsTmp.map((item, id) =>{
          item.Key = demographicsElementsTmp.length + medConditionElementsTmp.length + (id + 1) + ''
          return item
        }))
        console.log("interventionElementsTmp",interventionElementsTmp);


        let labTestElementsTmp = labTestElements.map((item,index) =>{
          return Object.assign(item,{Key:(index + 1) + ''})
        })
        // let labTestTableDataTmp = labTestElementsTmp.filter(d => {
        //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
        // })
        setLabTestElements(labTestElementsTmp)
        setLabTestTableData(labTestElementsTmp.map((item, id) =>{
          item.Key = demographicsElementsTmp.length + medConditionElementsTmp.length + interventionElementsTmp.length+(id + 1) + ''
          return item
        }))
        console.log("labTestElementsTmp",labTestElementsTmp);


        setCollapsible(false)
        setDefaultActiveKey(['2', '3', '4', '5'])

        setRollHeight(false)
        setActiveKey(['1'])

      } else if (type == 2) {//Exclusion


        let excluDemographicsElementsTmp = excluDemographicsElements.map((item,index) =>{
          return Object.assign(item,{Key:(index + 1) + ''})
        })
        // let excluDemographicsTableDataTmp = excluDemographicsElementsTmp.filter(d => {
        //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
        // })
        setExcluDemographicsElements(excluDemographicsElementsTmp )
        setExcluDemographicsTableData(excluDemographicsElementsTmp.map((item, id) =>{
          item.Key = (id + 1) + ''
          return item
        }))



        let excluMedConditionElementsTmp = excluMedConditionElements.map((item,index) =>{
          return Object.assign(item,{Key:(index + 1) + ''})
        })
        //  let excluMedConditionTableDataTmp = excluMedConditionElementsTmp.filter(d => {
        //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
        // })

        setExcluMedConditionElements(excluMedConditionElementsTmp )
        setExcluMedConditionTableData(excluMedConditionElementsTmp.map((item, id) =>{
          item.Key = excluDemographicsElementsTmp.length + (id + 1) + ''
          return item
        }))


        let excluInterventionElementsTmp = excluInterventionElements.map((item,index) =>{
          return Object.assign(item,{Key:(index + 1) + ''})
        })
        //  let excluInterventionTableDataTmp = excluInterventionElementsTmp.filter(d => {
        //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
        //  })
        setExcluInterventionElements(excluInterventionElementsTmp )
        setExcluInterventionTableData(excluInterventionElementsTmp.map((item, id) =>{
          item.Key = excluDemographicsElementsTmp.length + excluMedConditionElementsTmp.length + (id + 1) + ''
          return item
        }))


        let excluLabTestElementsTmp = excluLabTestElements.map((item,index) =>{
          return Object.assign(item,{Key:(index + 1) + ''})
        })
        //  let excluLabTestTableDataTmp = excluLabTestElementsTmp.filter(d => {
        //   return d.Frequency * 100 >= minValue && d.Frequency * 100 <= maxValue;
        //  })
        setExcluLabTestElements(excluLabTestElementsTmp )
        setExcluLabTestTableData(excluLabTestElementsTmp.map((item, id) =>{
          item.Key = excluDemographicsElementsTmp.length + excluMedConditionElementsTmp.length + excluInterventionElementsTmp.length + (id + 1) + ''
          return item
        }))


        setExcluCollapsible(false)
        setExcluDefaultActiveKey(['2','3','4','5'])

        setExcluRollHeight(false)
        setExcluActiveKey(['1'])
      }
  }


    function callback(key) {
      if(key.indexOf("1") < 0){
        setRollHeight(true)
      } else {
        setRollHeight(false)
      }
      setActiveKey(key)
    }

    function excluCallback(key) {
      if(key.indexOf("1") < 0){
        setExcluRollHeight(true)
      } else {
        setExcluRollHeight(false)
      }
      setExcluActiveKey(key)
    }

    const amendmentRateoption = {
    //   title : {
    //     text: 'Protocol Amendment',
    //     subtext: therapeutic_Amend_Avg,
    //     x:'left',
    //     y:'top',
    //     textStyle: {
    //       fontSize: 16,
    //       fontWeight: 'bold',
    //       color: '#333'
    //     },
    //     subtextStyle: {
    //         fontSize: 14,
    //         fontWeight: 'normal',
    //         color: '#999'
    //     }
    //   },
      tooltip: {
        trigger: 'item',
        formatter: '{b} - {d}%',
        extraCssText:'background:#757373;color:white;font-size:8px'
      },
      series: [
        {
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['50%', '80%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: function () {
              if(showChartLabel){
                return amend_avg_rate
              } else {
                return ''
              }
            },
            emphasis: '',
            rich: {
              p: {
                color: '#848484',
                fontSize: 30,
                backgroundColor: "white"
              },
              good: {
                color: '#00A947',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              fair: {
                color: '#0084A9',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              poor: {
                color: '#c33232',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              }
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          color: impactColors,
          data: protocolRateData
        }
      ]
    };

    const screenFailureOption = {
  //     title : {
  //       text: 'Screen Failure Risk',
  //       subtext: therapeutic_Screen_Avg,
  //       x:'left',
  //       y:'center',
  //       textStyle: {
  //         fontSize: 16,
  //         fontWeight: 'bold',
  //         color: '#333'
  //       },
  //       subtextStyle: {
  //         fontSize: 14,
  //         fontWeight: 'normal',
  //         color: '#999'
  //       }
  //     },
      tooltip: {
        trigger: 'item',
        formatter: '{b} - {d}%',
        extraCssText:'background:#757373;color:white;font-size:8px'
      },
      series: [
        {
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['50%', '80%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: function () {
              if(showChartLabel){
                return screen_avg_rate
              } else {
                return '-'
              }
            },
            emphasis: '',
            rich: {
              p: {
                color: '#848484',
                fontSize: 30,
                backgroundColor: "white"
              },
              good: {
                color: '#00A947',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              fair: {
                color: '#0084A9',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              poor: {
                color: '#c33232',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              }
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          color: impactColors,
          data: screenRateData
        }
      ]
    };

    const pieChartNullOption = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} - {d}%',
        extraCssText:'background:#757373;color:white;font-size:8px'
      },
      series: [
        {
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['50%', '80%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: function () {
              return '{p|' + '-' +'}'
            },
            emphasis: '',
            rich: {
              p: {
                color: '#848484',
                fontSize: 30,
                backgroundColor: "white"
              }
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          color: inActiveChartColors,
          data: defaultChartValue
        }
      ]
    };

    const excluAmendmentRateoption = {
  //     title : {
  //       text: 'Protocol Amendment',
  //       subtext: exclu_Therapeutic_Amend_Avg,
  //       x:'left',
  //       y:'center',
  //       textStyle: {
  //         fontSize: 16,
  //         fontWeight: 'bold',
  //         color: '#333'
  //       },
  //       subtextStyle: {
  //         fontSize: 14,
  //         fontWeight: 'normal',
  //         color: '#999'
  //       }
  //     },

      tooltip: {
        trigger: 'item',
        formatter: '{b} - {d}%',
        extraCssText:'background:#757373;color:white;font-size:8px'
      },
      series: [
        {
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['50%', '80%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: function () {
              if(showChartLabel){
                return excluAmend_avg_rate
              } else {
                return ''
              }
            },
            emphasis: '',
            rich: {
              p: {
                color: '#848484',
                fontSize: 30,
                backgroundColor: "white"
              },
              good: {
                color: '#00A947',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              fair: {
                color: '#0084A9',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              poor: {
                color: '#c33232',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              }
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          color: excluImpactColors,
          data: excluProtocolRateData
        }
      ]
    };

    const excluScreenFailureOption = {
  //     title : {
  //       text: 'Screen Failure Risk',
  //       subtext: exclu_Therapeutic_Screen_Avg,
  //       x:'left',
  //       y:'center',
  //       textStyle: {
  //         fontSize: 16,
  //         fontWeight: 'bold',
  //         color: '#333'
  //       },
  //       subtextStyle: {
  //           fontSize: 14,
  //           fontWeight: 'normal',
  //           color: '#999'
  //       }
  //     },

      tooltip: {
        trigger: 'item',
        formatter: '{b} - {d}%',
        extraCssText:'background:#757373;color:white;font-size:8px'
      },
      series: [
        {
          type: 'pie',
          center: ['40%', '50%'],
          radius: ['50%', '80%'],
          avoidLabelOverlap: false,
          label: {
            show: true,
            position: 'center',
            formatter: function () {
              if(showChartLabel){
                return excluScreen_avg_rate
              } else {
                return ''
              }
            },
            emphasis: '',
            rich: {
              p: {
                color: '#848484',
                fontSize: 30,
                backgroundColor: "white"
              },
              good: {
                color: '#00A947',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              fair: {
                color: '#0084A9',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              },
              poor: {
                color: '#c33232',
                fontSize: 12,
                fontWeight:'bold',
                backgroundColor: "white"
              }
            }
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: true
          },
          color: excluImpactColors,
          data: excluScreenRateData
        }
      ]
    };

     // Enrollment Feasibility chart data
     const raceOption = {
      tooltip: {
        trigger: 'item',
        formatter: '{b} - {c} - {d}%'
      },
      // legend: {
      //   x: '40%',
      //   y: '10%',
      //   orient: 'vertical',
      //   itemHeight: 7,
      //   itemWidth: 7,
      //   textStyle: {
      //     fontSize: 9
      //   },
      //   formatter: function(name) {
      //     let data = raceOption.series[0].data;
      //     let total = 0
      //     for(const d in data){
      //       total += data[d].value
      //     }
      //     let p = 0
      //     for (let i = 0, l = data.length; i < l; i++) {
      //         if (data[i].name == name) {
      //           if(data[i].value >0){
      //             const p = (data[i].value/total * 100).toFixed(2)
      //             return name + ' - ' + p + '%';
      //           }else{
      //             return name + ' - 0'
      //           }
      //         }
      //     }
      //   }
      // },
      series: [
        {
          name: 'Race & Ethnicity',
          type: 'pie',
          center: ['20%', '45%'],
          radius: ['30%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false
          },
          color: ethLegendColor,
          data: finalEthnicityData
        }
      ]
    };

    const eliPatientOption = {
      title : {
  //       text: eliPatientChartTitle,
  //       x:'40%',
  //       y:'top',
  //       textStyle: {
  //         fontSize: 18,
  //         fontWeight: 'bold',
  //         color: '#333'
  //       },
        show: false
        // show: (!showLegend)
      },
      // legend: {
      //   show: showLegend
      // },
      grid: {
          left: '3%',
          right: '4%',
          top: 0,
          bottom: 0,
          containLabel: true
      },
      xAxis: {
          type: 'value',
          axisLabel: {
              show: false
          },
          splitLine:{
              show:false
          },
          axisLine: {
            show: false
          },
          axisTick: {
              show: false
          },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // Use axis to trigger tooltip
          type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: function(params){
          // eligible patient chart won't show percentage value
          if(params.length == 2){
            return params[0].axisValue + ': ' + params[0].value
          }
          let total = 0
          for(let id=0; id<params.length; id ++){
            if(params[id].seriesName != 'Total'){
              total += params[id].value
            }
          }
          let html=`
            <div>
            <div>${params[0].axisValue}</div>
            <table>${params.map((item)=>
              item.seriesName != 'Total'?`
              <tr>
                <td>
                  <span style="display:inline-block; width:10px;
                    height:10px;background-color:${item.color};"></span>
                    ${item.seriesName}:
                </td>
                <td><span style="margin-left:10px;">${item.value}</span></td>
                <td>
                  <span style="margin-left:10px;">
                    ${item.value > 0? ((item.value / total) * 100).toFixed(2) + '%':0}
                </span></td>
              </tr>`:'').join("")}
            </table>
            <div>Total: ${total}</div>
            </div>`
          return html
        }
      },
      yAxis: {
          type: 'category',
          axisLabel: {
            // show: false,
            formatter: (value: any) => {
              return value.length > 15 && value.indexOf(":") > 15? `{a|${value.slice(0, 15)}... ${value.slice(value.indexOf(":"))}}` : `{a|${value}}`
            },
            rich: {
              a: {
              },
            }
          },
          axisLine: {
              show: false
          },
          axisTick: {
              show: false
          },
          data: enrollCriteriaLib
      },
      series: eliPatientResultData,
      backgroundColor: "#fff"
    };


    const resultOption = {
      title : {
        text: chartTitle,
        x:'40%',
        y:'top',
        textStyle: {
          fontSize: 18,
          fontWeight: 'bold',
          color: '#333'
        }
      },
      grid: {
          left: '3%',
          right: '4%',
          top: '8%',
          bottom: '3%',
          containLabel: true
      },
      xAxis: {
          type: 'value',
          axisLabel: {
              show: false
          },
          splitLine:{
              show:false
          },
          axisLine: {
            show: false
          },
          axisTick: {
              show: false
          },
      },
      yAxis: {
          type: 'category',
          axisLine: {
              show: false
          },
          axisTick: {
              show: false
          },
          data: [
          'HbA1c - ≧ 7.0% and ≦ 9.0%',
          'Fasting C-peptide - ≧ 0.8 ng/mL',
          'TSH - Normal or clinically euthyroid',
          'Meformin - Stable dose',
          'Type 2 Diabetes',
          'Stable body weight - Not charged by more than 5%',
          'Gender - Men or nonpregnant women',
          'Age - >18']
      },
      series: [{
              name: 'Direct',
              type: 'bar',
              stack: 'total',
              color: freqColor,
              label: {
                  show: true,
                  formatter: function(p) {
                      return p.data.freq+'%'
                  },
                  position: 'insideRight',
                  color: freqFontColor
              },
              data: freqData
          }, {
              name: 'total',
              type: 'bar',
              stack: 'total',
              barWidth:'20px',
              color: '#E84F22',
              label: {
                  show: true,
                  formatter: function(p) {
                      return p.data.total
                  },
                  position: 'insideRight'
              },
              data: totalData
          }
      ]
  };

  const fePatientOption = {
    title : {
      text: fePatientChartTitle,
      x:'40%',
      y:'top',
      textStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333'
      },
      show: false
      // show: (!showLegend)
    },
    // legend: {
    //   show: showLegend
    // },
    grid: {
        left: '3%',
        right: '4%',
        top: 0,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'value',
        axisLabel: {
            show: false
        },
        splitLine:{
            show:false
        },
        axisLine: {
          show: false
        },
        axisTick: {
            show: false
        },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      },
      formatter: function(params){
        // eligible patient chart won't show percentage value
        if(params.length == 2){
          return params[0].axisValue + ': ' + params[0].value
        }
        let total = 0
        for(let id=0; id<params.length; id ++){
          if(params[id].seriesName != 'Total'){
            total += params[id].value
          }
        }
        let html=`
          <div>
          <div>${params[0].axisValue}</div>
          <table>${params.map((item)=>
            item.seriesName != 'Total'?`
            <tr>
              <td>
                <span style="display:inline-block; width:10px;
                  height:10px;background-color:${item.color};"></span>
                  ${item.seriesName}:
              </td>
              <td><span style="margin-left:10px;">${item.value}</span></td>
              <td>
                <span style="margin-left:10px;">
                  ${item.value > 0? ((item.value / total) * 100).toFixed(2) + '%':0}
              </span></td>
            </tr>`:'').join("")}
          </table>
          <div>Total: ${total}</div>
          </div>`
        return html
      }
    },
    yAxis: {
        type: 'category',
        axisLabel: {
          formatter: (value: any) => {
            return value.length > 15 && value.indexOf(":") > 15? value.slice(0, 15) + '...'+ value.slice(value.indexOf(":")) : value
          }
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        data: enrollCriteriaLib
    },
    series: fePatientResultData,
    backgroundColor: "#fff"
  };

  const ethPatientOption = {
    title : {
      text: ethPatientChartTitle,
      x:'40%',
      y:'1%',
      textStyle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#333'
      },
      show: false
      // show: (!showLegend)
    },
    // legend: {
    //   show: showLegend
    // },
    grid: {
        left: '3%',
        right: '4%',
        top: 0,
        bottom: 0,
        containLabel: true
    },
    xAxis: {
        type: 'value',
        axisLabel: {
            show: false
        },
        splitLine:{
            show:false
        },
        axisLine: {
          show: false
        },
        axisTick: {
            show: false
        },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        // Use axis to trigger tooltip
        type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
      },
      formatter: function(params){
        // eligible patient chart won't show percentage value
        // if(params.length == 2){
        //   return params[0].axisValue + ': ' + params[0].value
        // }
        let total = 0
        for(let id=0; id<params.length; id ++){
          if(params[id].seriesName != 'Total'){
            total += params[id].value
          }
        }
        let html=`
          <div>
          <div>${params[0].axisValue}</div>
          <table>${params.map((item)=>
            item.seriesName != 'Total'?`
            <tr>
              <td>
                <span style="display:inline-block; width:10px;
                  height:10px;background-color:${item.color};"></span>
                  ${item.seriesName}:
              </td>
              <td><span style="margin-left:10px;">${item.value}</span></td>
              <td>
                <span style="margin-left:10px;">
                  ${item.value > 0? ((item.value / total) * 100).toFixed(2) + '%':0}
              </span></td>
            </tr>`:'').join("")}
          </table>
          <div>Total: ${total}</div>
          </div>`
        return html
      }
    },
    yAxis: {
        type: 'category',
        axisLabel: {
          formatter: (value: any) => {
            return value.length > 15 && value.indexOf(":") > 15? value.slice(0, 15) + '...'+ value.slice(value.indexOf(":")) : value
          }
        },
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        data: enrollCriteriaLib
    },
    series: ethPatientResultData,
    backgroundColor: "#fff"
  };

  const sleep = (time: number) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const getPatientFunnel = async () => {
    if (!initPTData && !reloadPTData){
      return
    }

    setLoadPatientFunnel(true)
    let doReSearch = false
    let response
    if(initPTData && !reloadPTData){
      response = await checkTrialPatientFunnelData(props.location.state.trial_id+scenarioId)
      if(!response){
        doReSearch = true
      }
    }else {
      doReSearch = true
    }
    if(doReSearch){
      let requestBody = {
        'trialId': props.location.state.trial_id+scenarioId,
        'requestBody':{
          'inclusion': {
            'demographicsElements': demographicsElements,
            'medConditionElements': medConditionElements,
            'interventionElements': interventionElements,
            'labTestElements': labTestElements
          },
          'exclusion': {
            'demographicsElements': excluDemographicsElements,
            'medConditionElements': excluMedConditionElements,
            'interventionElements': excluInterventionElements,
            'labTestElements': excluLabTestElements
          }
        }
      }
      getPatientFunnelData(requestBody)
    }
    let tryTimes = 0
    while(!response && tryTimes <= 20){
      if(doReSearch){
        await sleep(5000);
      }
      if (!(await isAuthenticated(true))) return;
      tryTimes += 1
      response = await checkTrialPatientFunnelData(props.location.state.trial_id+scenarioId)
    }
    if (!response && tryTimes > 20){
      message.error('No response for searching patient funnel over 100 seconds, please call assist.')
      return
    }
    if (response) {
      const resp = JSON.parse(response.body)
      console.log("getPatientFunnelData:",JSON.parse(response.body));

      // set data for tab info
      setEliPatient(resp['eli_patient'])
      setRateEliPatient(resp['rate_eli_patient'])

      setRateFeEliPatient(resp['rate_fe_eli_patient'])

      const tempColor = []
      for(const e in resp['ethnicity_legend']){
        tempColor.push(colorList[resp['ethnicity_legend'][e].name])
      }
      setEthLegendColor(tempColor)
      const templegend = []
      for (const val of resp['ethnicity_legend']) {
        templegend.push(Object.assign(val, {"selected":false}))
      }
      setFinalEthnicityData(templegend)

      // Set criteria lib data as yAxis -- Common
      setEnrollCriteriaLib(resp['criteria'])
      // setEnrollCriteriaLib(resp['criteria'].slice(0,resp['in_item_len']))
      // setEnrollCriteriaLibBelow(resp['criteria'].slice(resp['in_item_len']))
      setFunnelChartheight(40 * resp['criteria'].length)
      setFunnelChartheightOverlap((40 * resp['criteria'].length)/resp['criteria'].length *(resp['in_item_len']))
      // setFunnelChartheightBelow(40 * (resp['criteria'].length-resp['in_item_len']))
      let totalData = {
        name: 'Total',
        type: 'bar',
        stack: 'Total',
        barGap: '-100%',
        label: {
            normal: {
                show: true,
                position: 'right',
                textStyle: { color: '#000' },
                formatter: function(v) {
                    return v.value
                }
            }
        },
        itemStyle: {
            normal: {
                color: 'rgba(128, 128, 128, 0)',
                borderWidth: 1,
            }
        },
        data: resp['count']
      }

      // Set data for chart Eligible Patient
      setEliPatientChartTitle('Patients Eligible - ' + resp['eli_patient'] + '(' + resp['rate_eli_patient'] + ' of Dataset)')
      const tempEliPatientSeriesData = []
      tempEliPatientSeriesData.push({
        name: 'Eligible Patient',
        type: 'bar',
        stack: 'total',
        // barWidth:'24px',
        color: '#E84F22',
        label: {
            show: false,
            // formatter: function(p) {
            //     return p.data
            // },
            // position: 'insideRight'
        },
        data: resp['count'],
        areaStyle:{
          color:'#fff',
          opacity: 1
       }
      })
      tempEliPatientSeriesData.push(totalData)
      setEliPatientResultData(tempEliPatientSeriesData)

      // Set data for chart Femaile patients eligible
      setFePatientChartTitle('Female patients eligible - ' + resp['rate_fe_eli_patient'])
      const feEliPatient = resp['count_females']
      let mEliPatient = feEliPatient.map((item, id) =>{
        return resp['count'][id] - item
      })
      const tempFeEliPatientSeriesData = []
      tempFeEliPatientSeriesData.push({
          name: 'Female',
          type: 'bar',
          stack: 'total',
          color: '#EF7A57',
          label: {show: false},
          emphasis: {focus: 'series'},
          data: feEliPatient
      })
      tempFeEliPatientSeriesData.push({
          name: 'Male',
          type: 'bar',
          stack: 'total',
          color: '#E84F22',
          label: {show: false},
          emphasis: {focus: 'series'},
          data: mEliPatient
      })
      tempFeEliPatientSeriesData.push(totalData)
      setFePatientResultData(tempFeEliPatientSeriesData)

      // Set data for chart Race & Ethnicities
      let defaultEnthRate = ''
      let defaultEth = ''
      if(resp['final_ethnicity'].length > 0){
        defaultEnthRate = ((resp['final_ethnicity_count'][0] / resp['eli_patient']) * 100).toFixed(2)
        defaultEth = resp['final_ethnicity'][0]
        setEthPatientChartTitle('Race & Ethnicity - ' + defaultEth + ' - ' + defaultEnthRate + '%')
      } else {
        // setEthPatientChartTitle('Race & Ethnicity - ' + resp['final_ethnicity'][0] +  ' - 0')
        resp['ethnicity_legend']&& resp['ethnicity_legend'][0]&&setEthPatientChartTitle('Race & Ethnicity - ' + resp['ethnicity_legend'][0].name +  ' - ' + resp['ethnicity_legend'][0].percent + '%')
      }

      const tempEthPatientSeriesData = []
      for(const ckey in resp){
        if (ckey.startsWith('percent_') && ckey != 'percent_females'){
          const cnKey = ckey.replace('percent_', '')
          tempEthPatientSeriesData.push({
            name: cnKey,
            type: 'bar',
            stack: 'total',
            color: colorList[cnKey],
            label: {show: false},
            emphasis: {focus: 'series'},
            data: resp[cnKey]
          })
        }
      }
      tempEthPatientSeriesData.push(totalData)
      setEthPatientResultData(tempEthPatientSeriesData)
      setActiveEnrollmentTabKey('1')

    }
    setLoadPatientFunnel(false)
    setReloadPTData(false)
    setInitPTData(false)
  }

  const switchTabkey = (key) =>{
    setActiveEnrollmentTabKey(key)
    if(key === '1'){
      setChartTitle('Patients Eligible - 80K(16% of Dataset)')
      let tempData = [];
      for(let i = 0, l = resultdata.length; i < l; i++){
          var num = resultdata[i] + 100;
          tempData.push({'total': resultdata[i]+'K', 'value':num});
      }
      setTotalData(tempData)
      setFreqdata([])
    } else if(key === '2'){
      setChartTitle('Female patients eligible - 20%')
      setFreqColor('#EF7A57')
      setFreqFontColor('#fff')

      let tempData = [];
      for(let i = 0, l = resultdata.length; i < l; i++){
          var num = resultdata[i] * (100 - femaleFreq[i]) / 100;
          tempData.push({'total': resultdata[i]+'K', 'value':num});
      }
      setTotalData(tempData)

      let tempData2 = [];
      for(let i = 0, l = resultdata.length; i < l; i++){
          var num = resultdata[i] * femaleFreq[i] / 100;
          tempData2.push({'freq': femaleFreq[i], 'value' : num+100});
      }
      setFreqdata(tempData2)
    } else {
      setChartTitle('Race & Ethnicity - Afican American - 5%')
      setFreqColor('#FDECE0')
      setFreqFontColor('#333')

      let tempData = [];
      for(let i = 0, l = resultdata.length; i < l; i++){
          var num = resultdata[i] * (100 - raceFreq[i]) / 100;
          tempData.push({'total': resultdata[i]+'K', 'value':num});
      }
      setTotalData(tempData)

      let tempData2 = [];
      for(let i = 0, l = resultdata.length; i < l; i++){
          var num = resultdata[i] * raceFreq[i] / 100;
          tempData2.push({'freq': raceFreq[i], 'value' : num+100});
      }
      setFreqdata(tempData2)
    }
  }

    const handleCancel = () => {
      setShowHistorical(false)
      setVisibleSOA(false)
    }

    const showSOAModal = async () => {
      setVisibleSOA(true)
      showHistoricalTrials(true)
    }

    const showHistoricalTrials = async (soaFlag) => {
      setShowHistorical(true)

        setSpinning(true)
        const resp = await getSimilarhistoricalTrialById(trialRecord.trial_uuid, soaFlag);
        if (resp && Object.keys(resp).length !== 0) {
          setSpinning(false)

          const filteredData = resp['results'].filter((d) => {
            const date = d['start_date'].split('-')[0]
            return (
              date >= simliarTrialStudyStartDate.dateFrom && date<= simliarTrialStudyStartDate.dateTo
            );
          });
          setHistoricalTrialdata(filteredData)
        }

    }

    const searchHistoricalTrials = async () => {
      showHistoricalTrials(false)
    }

    const handleExport = (fileType) => {
      switch(fileType){
        case 'csv':
          csvExport();
          break;
        case 'pdf':
          pdfMake()
          break;
        default: break;
      }
    }

    const csvExport = async () => {
      let str='';

      //Get Inclusion Criteria Data
      str += 'Inclusion Criteria';
      str += '\n' + 'Category' + ',' + 'S/No.' + ',' + 'Eligibility Criteria' + ',' + 'Values' + ','
          + 'Timeframe' + ',' + 'Condition Or Exception';
      var serialNum = 0
      str += getCSVContent('Demographics', demographicsElements, serialNum)
      serialNum += demographicsElements.length
      str += getCSVContent('Medical Condition', medConditionElements, serialNum)
      serialNum += medConditionElements.length
      str += getCSVContent('Intervention', interventionElements, serialNum)
      serialNum += interventionElements.length
      str += getCSVContent('Lab / Test', labTestElements, serialNum)

      //Get Exclusion Criteria Data
      str += '\n\n\n' + 'Exclusion Criteria';
      str += '\n' + 'Category' + ',' + 'S/No.' + ',' + 'Eligibility Criteria' + ',' + 'Values' + ','
          + 'Timeframe' + ',' + 'Condition Or Exception';
      serialNum = 0
      str += getCSVContent('Demographics', excluDemographicsElements, serialNum)
      serialNum += excluDemographicsElements.length
      str += getCSVContent('Medical Condition', excluMedConditionElements, serialNum)
      serialNum += excluMedConditionElements.length
      str += getCSVContent('Intervention', excluInterventionElements, serialNum)
      serialNum += excluInterventionElements.length
      str += getCSVContent('Lab / Test', excluLabTestElements, serialNum)

      let exportContent = "\uFEFF";
      let blob = new Blob([exportContent + str], {
        type: "text/plain;charset=utf-8"
      });

      const date = Date().split(" ");
      const dateStr = date[1] + '_' + date[2] + '_' + date[3] + '_' + date[4];
      FileSaver.saveAs(blob, `Inclusion_Exclusion_Criteria_${dateStr}.csv`);
    };

    function getCSVContent(category, elements, serialNum){
      let str='';
      for(const a in elements) {
        serialNum = serialNum + 1
        str += '\n'+ category + ',' + serialNum + ',' + elements[a]['Eligibility Criteria'] + ','
          + elements[a].Values + ',' + elements[a].Timeframe + ','
          + (elements[a]['Condition Or Exception'] == undefined ? '': elements[a]['Condition Or Exception'])
        if(elements[a].Children != undefined && elements[a].Children.length > 0){
          const subCriteria = elements[a].Children
          var subSerialNum;
          for(const aa in subCriteria){
            subSerialNum = serialNum + subCriteria[aa].Key
            str += '\n' + category + ',' + subSerialNum + ',' + subCriteria[aa]['Eligibility Criteria']
            + ',' + subCriteria[aa].Values + ',' + subCriteria[aa].Timeframe
          }
        }
      }

      return str;
    }

    const updateInclusionCriteria = (newData, index) => {
      switch(index){
        case 2:
          setDemographicsElements(newData)
          break;
        case 3:
          setMedConditionElements(newData)
          break;
        case 4:
          setInterventionElements(newData)
          break;
        default:
          setLabTestElements(newData)
      }
    }

    const updateExclusionCriteria = (newData, index) => {
      switch(index){
        case 2:

          setExcluDemographicsElements(newData)
          break;
        case 3:

          setExcluMedConditionElements(newData)
          break;
        case 4:

          setExcluInterventionElements(newData)
          break;
        default:

          setExcluLabTestElements(newData)
      }
    }

    function keepUpdatedTrialInfo () {
      //Keep criteria before goes to schedule of Events
      let newScenario = trialRecord.scenarios.find( i=> i['scenario_id'] == scenarioId)

        var newInclusion = {
          "Demographics": {
            "protocol_amendment_rate": '',
            "patient_burden": '',
            "Entities": demographicsElements
          },
          "Medical Condition": {
            "protocol_amendment_rate": '',
            "patient_burden": '',
            "Entities": medConditionElements
          },
          "Intervention": {
            "protocol_amendment_rate": '',
            "patient_burden": '',
            "Entities": interventionElements
          },
          "Lab / Test": {
            "protocol_amendment_rate": '',
            "patient_burden": '',
            "Entities": labTestElements
          }
        }

        var newExclusion = {
          "Demographics": {
            "protocol_amendment_rate": '',
            "patient_burden": '',
            "Entities": excluDemographicsElements
          },
          "Medical Condition": {
            "protocol_amendment_rate": '',
            "patient_burden": '',
            "Entities": excluMedConditionElements
          },
          "Intervention": {
            "protocol_amendment_rate": '',
            "patient_burden": '',
            "Entities": excluInterventionElements
          },
          "Lab / Test": {
            "protocol_amendment_rate": '',
            "patient_burden": '',
            "Entities": excluLabTestElements
          }
        }

      if(newScenario["Inclusion Criteria"].Demographics === undefined){
        newScenario["Inclusion Criteria"] = newInclusion
        newScenario["Exclusion Criteria"] = newExclusion
      } else {
        newScenario["Inclusion Criteria"].Demographics.Entities = demographicsElements
        newScenario["Inclusion Criteria"]['Medical Condition'].Entities = medConditionElements
        newScenario["Inclusion Criteria"].Intervention.Entities = interventionElements
        newScenario["Inclusion Criteria"]['Lab / Test'].Entities = labTestElements

        newScenario["Exclusion Criteria"].Demographics.Entities = excluDemographicsElements
        newScenario["Exclusion Criteria"]['Medical Condition'].Entities = excluMedConditionElements
        newScenario["Exclusion Criteria"].Intervention.Entities = excluInterventionElements
        newScenario["Exclusion Criteria"]['Lab / Test'].Entities = excluLabTestElements
      }

      const newScenarioList = trialRecord.scenarios.map((item, id) =>{
        if(item['scenario_id'] == scenarioId){
            return newScenario
        } else {
            return item
        }
      })

      let newTrial = trialRecord
      newTrial.scenarios = newScenarioList

      setTrialRecord(newTrial)
      props.createTrial({
        trialRecord: newTrial,
      });
    }

    const saveCriteria = async () => {
      setPageLoading(true)

      var newInclusion = {
        "Demographics": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": demographicsElements
        },
        "Medical Condition": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": medConditionElements
        },
        "Intervention": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": interventionElements
        },
        "Lab / Test": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": labTestElements
        }
      }

      var newExclusion = {
        "Demographics": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": excluDemographicsElements
        },
        "Medical Condition": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": excluMedConditionElements
        },
        "Intervention": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": excluInterventionElements
        },
        "Lab / Test": {
          "protocol_amendment_rate": '',
          "patient_burden": '',
          "Entities": excluLabTestElements
        }
      }
      let newScenario = JSON.parse(JSON.stringify(trialRecord.scenarios.find( i=> i['scenario_id'] == scenarioId)));

      newScenario["ie_dismiss_list"] = {
        Inclusion: dismissInclu,
        Exclusion: dismissExclu
      }

      if(newScenario["ie_criteria"]["Inclusion Criteria"] === undefined){
        newScenario["ie_criteria"]["Inclusion Criteria"] = newInclusion
      } else {
        newScenario["ie_criteria"]["Inclusion Criteria"]['Intervention'].Entities = interventionElements
        newScenario["ie_criteria"]["Inclusion Criteria"].Demographics.Entities = demographicsElements
        newScenario["ie_criteria"]["Inclusion Criteria"]['Medical Condition'].Entities = medConditionElements
        newScenario["ie_criteria"]["Inclusion Criteria"]['Lab / Test'].Entities = labTestElements
        delete newScenario["ie_criteria"]["Inclusion Criteria"]['protocol_amendment_rate'];
        delete newScenario["ie_criteria"]["Inclusion Criteria"]['protocol_amendment_rate_state'];
        delete newScenario["ie_criteria"]["Inclusion Criteria"]['screen_failure_rate'];
        delete newScenario["ie_criteria"]["Inclusion Criteria"]['screen_failure_rate_state'];
      }

      if(newScenario["ie_criteria"]["Exclusion Criteria"] === undefined){
        newScenario["ie_criteria"]["Exclusion Criteria"] = newExclusion
      } else {
        newScenario["ie_criteria"]["Exclusion Criteria"].Demographics.Entities = excluDemographicsElements
        newScenario["ie_criteria"]["Exclusion Criteria"]['Medical Condition'].Entities = excluMedConditionElements
        newScenario["ie_criteria"]["Exclusion Criteria"].Intervention.Entities = excluInterventionElements
        newScenario["ie_criteria"]["Exclusion Criteria"]['Lab / Test'].Entities = excluLabTestElements
      delete newScenario["ie_criteria"]["Exclusion Criteria"]['protocol_amendment_rate'];
      delete newScenario["ie_criteria"]["Exclusion Criteria"]['protocol_amendment_rate_state'];
      delete newScenario["ie_criteria"]["Exclusion Criteria"]['screen_failure_rate'];
      delete newScenario["ie_criteria"]["Exclusion Criteria"]['screen_failure_rate_state'];
      }

      const newScenarioList = trialRecord.scenarios.map((item, id) =>{
        if(item['scenario_id'] == scenarioId){
          //If go back from SOA and SOA got edited, re-calculate SOA results for save changes
          if(item['Schedule of Events']?.Finished != undefined && !item['Schedule of Events']?.Finished){
            return reCalculateSOA(newScenario, item['Schedule of Events'])
          } else {
            return newScenario
          }
        } else {
            return item
        }
      })

      let trial_uuid = props.location.state.trial_id
      let newTrial = {"trial_uuid" : trial_uuid}
      newTrial["scenarios"] = newScenarioList

      setTrialRecord(newTrial)
      props.createTrial({
        trialRecord: newTrial,
      });
      setImpactColors(activeChartColors)
      setExcluImpactColors(activeChartColors)
      //Update record
      newTrial["user_name"] = getEmail()
      const resp = await saveIECriteria(newTrial);
      setPageLoading(false)
      if (resp && Object.keys(resp).length !== 0) {

        var currentScenario = resp.scenarios.find( i=> i['scenario_id'] == scenarioId)
        console.log("saveIECriteriaList", currentScenario)

        setDemographicsElements([])
        setDemographicsElements( demographicsElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
  
        setMedConditionElements([])
        setMedConditionElements(medConditionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setInterventionElements([])
        setInterventionElements(interventionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setLabTestElements([]);
        setLabTestElements(labTestElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setExcluDemographicsElements([])
        setExcluDemographicsElements( excluDemographicsElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1))
  
        setExcluMedConditionElements([])
        setExcluMedConditionElements(excluMedConditionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setExcluInterventionElements([])
        setExcluInterventionElements(excluInterventionElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));
  
        setExcluLabTestElements([]);
        setExcluLabTestElements(excluLabTestElements.sort( (a, b) => a['Eligibility Criteria'] < b['Eligibility Criteria'] ? -1 : 1));

        const totalParAmendmentCount = currentScenario.total_par_amendment_count
        const totalSfrAmendmentCount = currentScenario.total_sfr_amendment_count

        setTotal_par_amendment_count(totalParAmendmentCount)
        setTotal_sfr_amendment_count(totalSfrAmendmentCount)

         if(typeof currentScenario['ie_criteria'] === "object" && Object.keys(currentScenario['ie_criteria']).length === 0 )  {
             setCriteria_count_inclusion('0')
            setCriteria_count_exclusion('0')

            setCriteria_par_amendment_count_inclusion('0')
            setCriteria_par_amendment_count_exclusion('0')
            setProtocol_amendment_rate('')

            setCriteria_sfr_amendment_count_inclusion('0')
            setCriteria_sfr_amendment_count_exclusion('0')
            setScreen_failure_rate('')

             setProtocolRateData(defaultChartValue)
             setScreenRateData(defaultChartValue)

             setShowChartLabel(false)
             setImpactColors(inActiveChartColors)
            setAmend_avg_rate('')
            setScreen_avg_rate('')

            setShowChartLabel(false)
            setExcluImpactColors(inActiveChartColors)
            setExcluAmend_avg_rate('')
            setExcluScreen_avg_rate('')
             return;
         }else{
        //Get inclusion chart info
        var inclu = currentScenario["ie_criteria"]["Inclusion Criteria"]

        let criteriaCountInclusion = inclu.criteria_count_inclusion
        setCriteria_count_inclusion(criteriaCountInclusion)

        const currCriteriaParAmendmentCountInclusion = inclu.criteria_par_amendment_count_inclusion
        setCriteria_par_amendment_count_inclusion(currCriteriaParAmendmentCountInclusion)

        const currCriteriaSfrAmendmentCountInclusion = inclu.criteria_sfr_amendment_count_inclusion
        setCriteria_sfr_amendment_count_inclusion(currCriteriaSfrAmendmentCountInclusion)

        let currProtocolAmendmentRate = inclu.protocol_amendment_rate
        setProtocol_amendment_rate(currProtocolAmendmentRate)

        let currScreenFailureRate = inclu.screen_failure_rate
        setScreen_failure_rate(currScreenFailureRate)

        const protocolAmendmentRateInclusion = inclu.protocol_amendment_rate
        setProtocol_amendment_rate_inclusion(protocolAmendmentRateInclusion)

        const screenFailureRateInclusion = inclu.screen_failure_rate
        setScreen_failure_rate_inclusion(screenFailureRateInclusion)

        setProtocolRateData([
            {value: formatNumber(inclu['Lab / Test'].protocol_amendment_rate), name: 'Labs / Tests'},
            {value: formatNumber(inclu.Intervention.protocol_amendment_rate), name: 'Intervention'},
            {value: formatNumber(inclu.Demographics.protocol_amendment_rate), name: 'Demographics'},
            {value: formatNumber(inclu['Medical Condition'].protocol_amendment_rate), name: 'Medical Condition'}
          ])
          setScreenRateData([
              {value: formatNumber(inclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests'},
              {value: formatNumber(inclu.Intervention.screen_failure_rate), name: 'Intervention'},
              {value: formatNumber(inclu.Demographics.screen_failure_rate), name: 'Demographics'},
              {value: formatNumber(inclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition'}
          ])

        var tempScoreA = ''
        var tempScoreB = ''

        let protocol_amendment_state = inclu.protocol_amendment_rate_state
        let screen_failure_state = inclu.screen_failure_rate_state

        tempScoreA = '{p|' + inclu.criteria_par_amendment_count_inclusion + '}\n{'+protocol_amendment_state.toLowerCase()+'|'+protocol_amendment_state+'}'
        tempScoreB = '{p|' + inclu.criteria_sfr_amendment_count_inclusion + '}\n{'+screen_failure_state.toLowerCase()+'|'+screen_failure_state+'}'

        setAmend_avg_rate(tempScoreA)
        setScreen_avg_rate(tempScoreB)

        //Get exclusion chart info
        var exclu = currentScenario["ie_criteria"]["Exclusion Criteria"]

        setExcluProtocolRateData([
              {value: formatNumber(exclu['Lab / Test'].protocol_amendment_rate), name: 'Labs / Tests'},
              {value: formatNumber(exclu.Intervention.protocol_amendment_rate), name: 'Intervention'},
              {value: formatNumber(exclu.Demographics.protocol_amendment_rate), name: 'Demographics'},
              {value: formatNumber(exclu['Medical Condition'].protocol_amendment_rate), name: 'Medical Condition'}
            ])
            setExcluScreenRateData([
                {value: formatNumber(exclu['Lab / Test'].screen_failure_rate), name: 'Labs / Tests'},
                {value: formatNumber(exclu.Intervention.screen_failure_rate), name: 'Intervention'},
                {value: formatNumber(exclu.Demographics.screen_failure_rate), name: 'Demographics'},
                {value: formatNumber(exclu['Medical Condition'].screen_failure_rate), name: 'Medical Condition'}
            ])

        let excluProtocolAmendmentRate = exclu.protocol_amendment_rate
        setProtocol_amendment_rate(excluProtocolAmendmentRate)

        let excluScreenFailureRate = exclu.screen_failure_rate
        setScreen_failure_rate(excluScreenFailureRate)

        const protocolAmendmentRateExclusion = exclu.protocol_amendment_rate
        setProtocol_amendment_rate_exclusion(protocolAmendmentRateExclusion)

        const screenFailureRateExclusion = exclu.screen_failure_rate
        setScreen_failure_rate_exclusion(screenFailureRateExclusion)

        let criteriaCountExclusion = exclu.criteria_count_exclusion
        setCriteria_count_exclusion(criteriaCountExclusion)

        const excluCriteriaParAmendmentCountExclusion = exclu.criteria_par_amendment_count_exclusion
        setCriteria_par_amendment_count_exclusion(excluCriteriaParAmendmentCountExclusion)

        const excluCriteriaSfrAmendmentCountExclusion = exclu.criteria_sfr_amendment_count_exclusion
        setCriteria_sfr_amendment_count_exclusion(excluCriteriaSfrAmendmentCountExclusion)

        let exclu_protocol_amendment_state = exclu.protocol_amendment_rate_state
        let exclu_screen_failure_state = exclu.screen_failure_rate_state

        tempScoreA = '{p|' + exclu.criteria_par_amendment_count_exclusion + '}\n{'+exclu_protocol_amendment_state.toLowerCase()+'|'+exclu_protocol_amendment_state+'}'
        tempScoreB = '{p|' + exclu.criteria_sfr_amendment_count_exclusion + '}\n{'+exclu_screen_failure_state.toLowerCase()+'|'+exclu_screen_failure_state+'}'

        setExcluAmend_avg_rate(tempScoreA)
        setExcluScreen_avg_rate(tempScoreB)

        if(currentScenario['therapeutic_area_average']){
          setTherapeutic_Amend_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].protocol_amendment_rate)
          setTherapeutic_Screen_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].screen_failure_rate)
          setExcluTherapeutic_Amend_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].protocol_amendment_rate)
          setExcluTherapeutic_Screen_Avg('Therapeutic Area Average - ' + currentScenario['therapeutic_area_average'].screen_failure_rate)
        }

        setScenario(currentScenario)
        setInsightInclu(currentScenario['ie_insights'] && currentScenario['ie_insights']['Inclusion'])
        setInsightExclu(currentScenario['ie_insights'] && currentScenario['ie_insights']['Exclusion'])
        setShowChartLabel(true)
        }
        message.success("Save successfully");
      }else{
        message.error("Failed to save");
      }
    }

    function formatNumber (str){
      if(str == undefined || str == ''){
        return 0
      } else {
        return Number(str.substr(0, str.lastIndexOf('%')))
      }
    }

    const reCalculateSOA = (specificScenario, scheduleOfEvents) =>{
      let burdenMatrixList = []
      let tempBurdenXAxis = []
      for(var i =0; i< scheduleOfEvents.Visits; i ++){
        burdenMatrixList.push([0,0,0,0,0,0,0,0,0,0])
        tempBurdenXAxis.push((i+1)+'')
      }

      let labeTotalCost = 0
      for(const a in scheduleOfEvents[CATEGORY_LABS].entities) {
        labeTotalCost += Number(scheduleOfEvents[CATEGORY_LABS].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_LABS].entities[a].totalVisit
        if(scheduleOfEvents[CATEGORY_LABS].entities[a].condition.length > 0){
          for(let b = 0; b < scheduleOfEvents[CATEGORY_LABS].entities[a].condition.length; b ++){
            if(scheduleOfEvents[CATEGORY_LABS].entities[a].condition[b].checked){
              let tempBurdenMatrix = []
              burdenMatrixList[b].map((item, idx) =>{
                tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_LABS].entities[a].soaWeights[idx])
              })
              burdenMatrixList.splice(b, 1, tempBurdenMatrix)
            }
          }
        }
      }

      let examinationTotalCost = 0
      for(const a in scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities) {
        examinationTotalCost += Number(scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].totalVisit
        if(scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].condition.length > 0){
          for(let b = 0; b < scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].condition.length; b ++){
            if(scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].condition[b].checked){
              let tempBurdenMatrix = []
              burdenMatrixList[b].map((item, idx) =>{
                tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_PHYSICAL_EXAMINATION].entities[a].soaWeights[idx])
              })
              burdenMatrixList.splice(b, 1, tempBurdenMatrix)
            }
          }
        }
      }

      let proceduresTotalCost = 0
      for(const a in scheduleOfEvents[CATEGORY_PROCEDURES].entities) {
        proceduresTotalCost += Number(scheduleOfEvents[CATEGORY_PROCEDURES].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].totalVisit
        if(scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].condition.length > 0){
          for(let b = 0; b < scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].condition.length; b ++){
            if(scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].condition[b].checked){
              let tempBurdenMatrix = []
              burdenMatrixList[b].map((item, idx) =>{
                tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_PROCEDURES].entities[a].soaWeights[idx])
              })
              burdenMatrixList.splice(b, 1, tempBurdenMatrix)
            }
          }
        }
      }

      let questionairesTotalCost = 0
      for(const a in scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities) {
        questionairesTotalCost += Number(scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].totalVisit
        if(scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].condition.length > 0){
          for(let b = 0; b < scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].condition.length; b ++){
            if(scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].condition[b].checked){
              let tempBurdenMatrix = []
              burdenMatrixList[b].map((item, idx) =>{
                tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_QUESTIONNAIRES].entities[a].soaWeights[idx])
              })
              burdenMatrixList.splice(b, 1, tempBurdenMatrix)
            }
          }
        }
      }

      let studyTotalCost = 0
      for(const a in scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities) {
        studyTotalCost += Number(scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a]['Dummy Cost']) * scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].totalVisit
        if(scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].condition.length > 0){
          for(let b = 0; b < scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].condition.length; b ++){
            if(scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].condition[b].checked){
              let tempBurdenMatrix = []
              burdenMatrixList[b].map((item, idx) =>{
                tempBurdenMatrix.push(item + scheduleOfEvents[CATEGORY_STUDY_PROCEDURES].entities[a].soaWeights[idx])
              })
              burdenMatrixList.splice(b, 1, tempBurdenMatrix)
            }
          }
        }
      }

      let tempBurdenData = []
      let patient_burden = 0
      for(const m in burdenMatrixList){
        const visitMatrix = burdenMatrixList[m].map((max) => {
          return max > 0 ? 1 : 0
        })
        const excessMatrix = burdenMatrixList[m].map((max) => {
          return max - 1 >= 0 ? max - 1 : 0
        })

        let currentVisitScore = 0
        for(const c in visitMatrix){
          currentVisitScore += visitMatrix[c] * visitDimensionalScore[c].Value + excessMatrix[c]
        }
        tempBurdenData.push(currentVisitScore)
        patient_burden += currentVisitScore
      }

      let patient_burden_rate = 'GOOD'
      if (patient_burden > 0 && patient_burden <= 400) {
        patient_burden_rate = 'GOOD'
      } else if (patient_burden > 400 && patient_burden <= 600) {
        patient_burden_rate = 'FAIR'
      } else if (patient_burden > 600){
        patient_burden_rate = 'POOR'
      }

      let tempCostData = [
        {value: labeTotalCost, name: CATEGORY_LABS},
        {value: examinationTotalCost, name: CATEGORY_PHYSICAL_EXAMINATION},
        {value: proceduresTotalCost, name: CATEGORY_PROCEDURES},
        {value: questionairesTotalCost, name: CATEGORY_QUESTIONNAIRES},
        {value: studyTotalCost, name: CATEGORY_STUDY_PROCEDURES}
      ]

      let totalCost = labeTotalCost + examinationTotalCost + proceduresTotalCost + questionairesTotalCost + studyTotalCost

      let costBreakdown = ''
      if(totalCost < 12826){
        costBreakdown = 'GOOD'
      } else if(totalCost < 15650){
        costBreakdown = 'FAIR'
      } else {
        costBreakdown = 'POOR'
      }

      specificScenario['Schedule of Events'] = Object.assign(scheduleOfEvents,{
        'TotalCost': '' + formatCostAvg(totalCost, 1000),
        'CostRate': costBreakdown,
        'CostData': tempCostData,
        'BurdenData': tempBurdenData,
        'BurdenXAxis': tempBurdenXAxis,
        'Finished': true,
        'patient_burden': patient_burden,
        'patient_burden_rate': patient_burden_rate
      })
      return specificScenario
    }

    function roundFun(value, n) {
        return Math.round(value*Math.pow(10,n))/Math.pow(10,n);
    }

    function formatCostAvg(totalCost, divisor){
      if(totalCost === 0){
        return 0
      } else {
        let avg = Math.ceil(totalCost/divisor*1000)
        return roundFun(avg/1000, 2)
      }
    }

    const pdfMake = async () =>{
      const pdf = new jsPDF('p', 'pt');
      const tableColumn = ['Category', 'S/No.', 'Eligibility Criteria', 'Values', 'Timeframe','Condition Or Exception'];

      var serialNum = 0
      var tableRows = formatColumns('Demographics', demographicsElements, serialNum)
      serialNum += demographicsElements.length
      tableRows = tableRows.concat(formatColumns('Medical Condition', medConditionElements, serialNum))
      serialNum += medConditionElements.length
      tableRows = tableRows.concat(formatColumns('Intervention', interventionElements, serialNum))
      serialNum += interventionElements.length
      tableRows = tableRows.concat(formatColumns('Lab / Test', labTestElements, serialNum))

      serialNum = 0
      var serialNum = 0
      var excluTableRows = formatColumns('Demographics', excluDemographicsElements, serialNum)
      serialNum += excluDemographicsElements.length
      excluTableRows = excluTableRows.concat(formatColumns('Medical Condition', excluMedConditionElements, serialNum))
      serialNum += excluMedConditionElements.length
      excluTableRows = excluTableRows.concat(formatColumns('Intervention', excluInterventionElements, serialNum))
      serialNum += excluInterventionElements.length
      excluTableRows = excluTableRows.concat(formatColumns('Lab / Test', excluLabTestElements, serialNum))

      pdf.text("Inclusion Criteria", 14, 20);
      pdf['autoTable']({
        columns: tableColumn,
        body: tableRows
      });

      pdf.addPage()
      pdf.text("Exclusion Criteria", 14, 20);
      pdf['autoTable']({
        columns: tableColumn,
        body: excluTableRows
      });

      const date = Date().split(" ");
      const dateStr = date[1] + '_' + date[2] + '_' + date[3] + '_' + date[4];
      pdf.save(`Inclusion_Criteria_${dateStr}.pdf`);
    };

    function formatColumns(catogory, list, serialNo){
      var tempColumn = []
      for(const a in list) {
        serialNo ++
        const item = [catogory, serialNo, list[a]['Eligibility Criteria'], list[a]['Values'], list[a]['Timeframe'],
                      list[a]['Condition Or Exception'] == undefined ? '': list[a]['Condition Or Exception']]
        tempColumn.push(item)
        if(list[a]['Children'] != undefined && list[a]['Children'].length > 0){
          const subCriteria = list[a]['Children']
          var subSerialNum;
          for(const aa in subCriteria){
            subSerialNum = serialNo + subCriteria[aa].Key
            const subItem = [catogory, subSerialNum, subCriteria[aa]['Eligibility Criteria'],
                              subCriteria[aa]['Values'], subCriteria[aa]['Timeframe'], '']
            tempColumn.push(subItem)
          }
        }
      }
      return tempColumn
    }

  useEffect(() => {
    updateTableData()

  }, [demographicsElements, medConditionElements, interventionElements, labTestElements])

  useEffect(() => {
    updateExcluTableData()
  }, [excluDemographicsElements, excluMedConditionElements, excluInterventionElements, excluLabTestElements])




  const updateTableData = () => {

    let demographicsTmp = demographicsElements.map((e,idx) => {
      e.Key = (idx + 1) + ''
      return e
    })
    setDemographicsTableData(demographicsTmp)

    let medConditionTmp = medConditionElements.map((e,idx) => {
      e.Key = demographicsTmp.length + (idx + 1) + ''
        return e
    })
    setMedConditionTableData(medConditionTmp)

    let interventionTmp =  interventionElements.map((e,idx) => {
      e.Key = demographicsTmp.length + medConditionTmp.length + (idx + 1) + ''
        return e
    })
    setInterventionTableData(interventionTmp)

    let labTestTmp =  labTestElements.map((e,idx) => {
      e.Key = demographicsTmp.length + medConditionTmp.length + interventionTmp.length + (idx + 1) + ''
        return e
    })
    setLabTestTableData(labTestTmp)
  }

  const updateExcluTableData = () => {

    let demographicsTmp = excluDemographicsElements.map((e,idx) => {
      e.Key = (idx + 1) + ''
      return e
    })
    setExcluDemographicsTableData(demographicsTmp)

    let medConditionTmp = excluMedConditionElements.map((e,idx) => {
      e.Key = demographicsTmp.length + (idx + 1) + ''
        return e
    })
    setExcluMedConditionTableData(medConditionTmp)

    let interventionTmp =  excluInterventionElements.map((e,idx) => {
      e.Key = demographicsTmp.length + medConditionTmp.length + (idx + 1) + ''
        return e
    })
    setExcluInterventionTableData(interventionTmp)

    let labTestTmp =  excluLabTestElements.map((e,idx) => {
      e.Key = demographicsTmp.length + medConditionTmp.length + interventionTmp.length + (idx + 1) + ''
        return e
    })
    setExcluLabTestTableData(labTestTmp)
  }

  const changeActiveTabKey = (activeKey) => {
    setActiveTabKey(activeKey)
    if(activeKey === '3'){
      keepUpdatedTrialInfo()
    }
    if(activeKey === '1' || activeKey == '2') {
      setProcessStep(0)
    }
  }

  const onInclusionChartClick = (e) =>{
    if(e.name === 'Medical Condition'){
      setDefaultActiveKey(['3'])
    } else if(e.name === 'Labs / Tests'){
      setDefaultActiveKey(['5'])
    } else if(e.name === 'Intervention'){
      setDefaultActiveKey(['4'])
    } else if(e.name === 'Demographics'){
      setDefaultActiveKey(['2'])
    }
  }

  const onExclusionChartClick = (e) =>{
    if(e.name === 'Medical Condition'){
      setExcluDefaultActiveKey(['3'])
    } else if(e.name === 'Labs / Tests'){
      setExcluDefaultActiveKey(['5'])
    } else if(e.name === 'Intervention'){
      setExcluDefaultActiveKey(['4'])
    } else if(e.name === 'Demographics'){
      setExcluDefaultActiveKey(['2'])
    }
  }

   // Click the legend of pie chart to change the bar chart accordingly
   const onClickLegend = (value, percent) =>{
    let tempEthPatientSeriesData = []
    let tempName = ""
    let tempPercent = ""
    let tempFirstData =  []
    let tempOtherData =  []
    tempEthPatientSeriesData = JSON.parse(JSON.stringify(ethPatientResultData))
    for(const val of tempEthPatientSeriesData){
      if (val.name === value){
        val.color = colorList["HIGHLIGHTED"]
        tempName = val.name
        tempPercent = percent
        tempFirstData.push(val)
      } else {
        val.color = colorList["NOT HIGHLIGHTED"]
        tempOtherData.push(val)
      }
    }
    // highlight the legend which is clicked
    for(const val of finalEthnicityData) {
      if(val.name === value) {
        val.selected = true
      } else {
        val.selected = false
      }
    }
    setFinalEthnicityData(finalEthnicityData)
    // remove the category to the beginning of bar chart
    setEthPatientResultData([...tempFirstData,...tempOtherData])
    if (eChartsRef && eChartsRef.current) {
      ethPatientOption.series[0].color = tempFirstData[0].color
      ethPatientOption.series[0].data = tempFirstData[0].data
      ethPatientOption.series[0].emphasis = tempFirstData[0].emphasis
      ethPatientOption.series[0].label = tempFirstData[0].label
      ethPatientOption.series[0].name = tempFirstData[0].name
      ethPatientOption.series[0].stack = tempFirstData[0].stack
      ethPatientOption.series[0].type = tempFirstData[0].type
      eChartsRef.current?.getEchartsInstance().setOption(ethPatientOption);
    }
    if (eChartsBelowRef && eChartsBelowRef.current) {
      ethPatientOption.series[0].color = tempFirstData[0].color
      ethPatientOption.series[0].data = tempFirstData[0].data
      ethPatientOption.series[0].emphasis = tempFirstData[0].emphasis
      ethPatientOption.series[0].label = tempFirstData[0].label
      ethPatientOption.series[0].name = tempFirstData[0].name
      ethPatientOption.series[0].stack = tempFirstData[0].stack
      ethPatientOption.series[0].type = tempFirstData[0].type
      eChartsBelowRef.current?.getEchartsInstance().setOption(ethPatientOption);
    }
    // Change the title  of chart according to the legend clicked
    setEthPatientChartTitle('Race & Ethnicity - ' + tempName +  ' - ' + tempPercent + '%')
  }


  const handleGoBack = (scheduleOfEvents) =>{
    let newScenario = trialRecord.scenarios.find( i=> i['scenario_id'] == scenarioId)
    newScenario['Schedule of Events'] = scheduleOfEvents
    const newScenarioList = trialRecord.scenarios.map((item, id) =>{
      if(item['scenario_id'] == scenarioId){
          return newScenario
      } else {
          return item
      }
    })
    let newTrial = trialRecord
    newTrial.scenarios = newScenarioList
    setTrialRecord(newTrial)
    props.createTrial({
      trialRecord: newTrial,
    });
    setProcessStep(0)
    setSubmitType(0)
  }

  const downloadSOA = async () => {
    let tempResource = []
    if(soaResource.length == 0){
      setSpinning(true)
      const resp = await getSOAResource(similarHistoricalTrials);
      if (resp) {
        setSpinning(false)
        setSOAResource(JSON.parse(resp.body).soaItemList)
        tempResource = JSON.parse(resp.body).soaItemList
      }
    } else {
      tempResource = soaResource
    }

    //export
    let str = 'SOA';
    str += '\n' + 'NCT ID' + ',' + 'Category' + ',' + 'Raw Activity' + ',' + 'Standardized'
    for(const id in tempResource){
      str += '\n' + tempResource[id].nctID + ',"' + tempResource[id].category +  '","' + tempResource[id].raw + '","'
        + tempResource[id].standardized + '"'
    }

    let exportContent = "\uFEFF";
    let blob = new Blob([exportContent + str], {
      type: "text/plain;charset=utf-8"
    });

    const date = Date().split(" ");
    const dateStr = date[1] + '_' + date[2] + '_' + date[3] + '_' + date[4];
    FileSaver.saveAs(blob, `SOA_Resource_${dateStr}.csv`);
  }

  const downloadIE = async () => {
    if(ieResource == ''){
      setSpinning(true)
      const resp = await getIEResource(similarHistoricalTrials, props.location.state.trial_id);
      if (resp) {
        // var num = resp.body.lastIndexOf('/')+1
        let fileName = resp.body
        downloadFile(fileName)
      }
    } else {
      downloadFile(ieResource)
    }

    function downloadFile(fileName) {
      window.open(fileName, '_blank')
      setSpinning(false)
      setIEResource(fileName)
    }
  }

  const downloadAverage = async () => {
    // var num = avgFileKey.lastIndexOf('/')+1
    let fileName = avgFileKey
    window.open(fileName, '_blank')
    // let tempInclusionResourceAvg = []
    // let tempExclusionResourceAvg = []
    // if(!avgResource){
    //   setSpinning(true)
    //   const resp = await getAverage(similarHistoricalTrials);
    //   if (resp.statusCode == 200) {
    //     setSpinning(false)
    //     setAvgResource(true)
    //     setInclusionResourceAvg(JSON.parse(resp.body).inResult)
    //     setExclusionResourceAvg(JSON.parse(resp.body).exResult)
    //     tempInclusionResourceAvg = JSON.parse(resp.body).inResult
    //     tempExclusionResourceAvg = JSON.parse(resp.body).exResult
    //   }
    // } else {
    //   tempInclusionResourceAvg = inclusionResourceAvg
    //   tempExclusionResourceAvg = exclusionResourceAvg
    // }

    // //export
    // let str = 'I/E' + ','  + 'NCT ID' + ','+ 'Category' + ',' + 'Raw Entity' + ',' + 'Standardized Entity' + ',' + 'Snomed' + ',' + 'Average Value' + ',' + 'Average Lower Limit' + ',' + 'Average Upper Limit' + ',' + 'Units'
    // for(const standardized in tempInclusionResourceAvg){
    //   for(const criteria in tempInclusionResourceAvg[standardized]){
    //     str += '\n' + 'INCLUSION' + ',"'
    //                 + tempInclusionResourceAvg[standardized][criteria].nct + '","'
    //                 + tempInclusionResourceAvg[standardized][criteria].category + '","'
    //                 + tempInclusionResourceAvg[standardized][criteria].raw + '","'
    //                 + tempInclusionResourceAvg[standardized][criteria].standardized + '","'
    //                 + tempInclusionResourceAvg[standardized][criteria].snomed + '","'
    //                 + (tempInclusionResourceAvg[standardized][criteria].avg_value === 0?"":tempInclusionResourceAvg[standardized][criteria].avg_value) + '","'
    //                 + (tempInclusionResourceAvg[standardized][criteria].avg_lower === 0? "":tempInclusionResourceAvg[standardized][criteria].avg_lower) + '","'
    //                 + (tempInclusionResourceAvg[standardized][criteria].avg_upper ===0?"":tempInclusionResourceAvg[standardized][criteria].avg_upper)  + '","'
    //                 + tempInclusionResourceAvg[standardized][criteria].units + '"'
    //   }
    // }
    // for(const standardized in tempExclusionResourceAvg){
    //   for(const criteria in tempExclusionResourceAvg[standardized]){
    //     str += '\n' + 'EXCLUSION' + ',"'
    //     + tempExclusionResourceAvg[standardized][criteria].nct + '","'
    //     + tempExclusionResourceAvg[standardized][criteria].category + '","'
    //     + tempExclusionResourceAvg[standardized][criteria].raw + '","'
    //     + tempExclusionResourceAvg[standardized][criteria].standardized + '","'
    //     + tempExclusionResourceAvg[standardized][criteria].snomed + '","'
    //     + (tempExclusionResourceAvg[standardized][criteria].avg_value === 0? "":tempExclusionResourceAvg[standardized][criteria].avg_value) + '","'
    //     + (tempExclusionResourceAvg[standardized][criteria].avg_lower === 0?"":tempExclusionResourceAvg[standardized][criteria].avg_lower) + '","'
    //     + (tempExclusionResourceAvg[standardized][criteria].avg_upper===0?"":tempExclusionResourceAvg[standardized][criteria].avg_upper) + '","'
    //     + tempExclusionResourceAvg[standardized][criteria].units +  '"'
    //   }
    // }

    // let exportContent = "\uFEFF";
    // let blob = new Blob([exportContent + str], {
    //   type: "text/plain;charset=utf-8"
    // });

    // const date = Date().split(" ");
    // const dateStr = date[1] + '_' + date[2] + '_' + date[3] + '_' + date[4];
    // FileSaver.saveAs(blob, `IE_Average_${dateStr}.csv`);
  }

  // search bar data
  const minValueLimit = 0
  const maxValueLimit = 100
  const optionLabelDemographics = originDemographics.map((item, index)=>{
    return item
  })
 const optionLabelIntervention = originIntervention.map((item, index)=>{
    return item
  })
 const optionLabelMedCondition = originMedCondition.map((item, index)=>{
    return item
  })
 const optionLabelLabTest = originLabTest.map((item, index)=>{
    return item
  })

 let timer = null;
 const onTextChange = (e) => {
   const val = e.target.value.toLowerCase();
   let searchDemo = []
   let searchInte = []
   let searchMed=[]
   let searchLab=[]
   !visibleValue&&setVisibleValue(true)
   if (timer !== null) {
     clearTimeout(timer);
   }
   timer = setTimeout(function () {
     setSearchTxt(val)
     searchDemo = val.length>0? optionLabelDemographics.filter(
       (i) =>
         typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
       ) : []
     searchInte = val.length>0? optionLabelIntervention.filter(
       (i) =>
         typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
       ): []
     searchMed = val.length>0?optionLabelMedCondition.filter(
       (i) =>
         typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
       ): []
     searchLab = val.length>0?optionLabelLabTest.filter(
       (i) =>
         typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
       ): []
     setSearchDemographics(searchDemo)
     setSearchIntervention(searchInte)
     setSearchMedCondition(searchMed)
     setSearchLabTest(searchLab)
   }, 200);
 }

 const optionLabelDemographicsExclu = originExcluDemographics.map((item, index)=>{
   return item
 })
const optionLabelInterventionExclu = originExcluIntervention.map((item, index)=>{
   return item
 })
const optionLabelMedConditionExclu = originExcluMedCondition.map((item, index)=>{
   return item
 })
const optionLabelLabTestExclu = originExcluLabTest.map((item, index)=>{
   return item
 })

 let timerExclu = null;
 const onExcluTextChange = (e) => {
   const val = e.target.value.toLowerCase();
   let searchDemoEx = []
   let searchInterEx = []
   let searchMedEx=[]
   let searchLabEx=[]
   !visibleValueExclu&&setVisibleValueExclu(true)
   if ( timerExclu !== null) {
     clearTimeout(timerExclu);
   }
   timerExclu = setTimeout(function () {
       setSearchTxtExclu(val);
       searchDemoEx = val.length>0?optionLabelDemographicsExclu.filter(
         (i) =>
           typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
         ): []
       searchInterEx = val.length>0?optionLabelInterventionExclu.filter(
         (i) =>
           typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
         ): []
       searchMedEx = val.length>0?optionLabelMedConditionExclu.filter(
         (i) =>
           typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
         ): []
       searchLabEx = val.length>0?optionLabelLabTestExclu.filter(
         (i) =>
           typeof i.Text === 'string'&&i.Text.toLowerCase().indexOf(val.toLowerCase()) > -1 && i.Frequency * 100 >= minValueLimit && i.Frequency * 100 <= maxValueLimit
         ): []
       setSearchDemographicsExclu(searchDemoEx)
       setSearchInterventionExclu(searchInterEx)
       setSearchMedConditionExclu(searchMedEx)
       setSearchLabTestExclu(searchLabEx)
   }, 200);
 }

 const onItemClick = ({ key }) => {
   setVisibleValue(true)
   let indexdemographicsElements = demographicsElements.findIndex((domain) =>  JSON.parse(key.slice(1)).Text == domain['Eligibility Criteria'] &&  JSON.parse(key.slice(1)).negation == domain['negation']);
   let indexmedConditionElements = medConditionElements.findIndex((domain) =>  JSON.parse(key.slice(1)).Text == domain['Eligibility Criteria'] &&  JSON.parse(key.slice(1)).negation == domain['negation']);
   let indexinterventionElements = interventionElements.findIndex((domain) =>  JSON.parse(key.slice(1)).Text == domain['Eligibility Criteria'] &&  JSON.parse(key.slice(1)).negation == domain['negation']);
   let indexlabTestElements = labTestElements.findIndex((domain) =>  JSON.parse(key.slice(1)).Text == domain['Eligibility Criteria'] &&  JSON.parse(key.slice(1)).negation == domain['negation']);
   //  if key includes in [], delete; if not includes, push []
   switch(key.charAt(0)) {
     case "D":
       if(indexdemographicsElements < 0){
         var newItem = {
           "Eligibility Criteria": JSON.parse(key.slice(1)).Text,
           "Values": formatValue(JSON.parse(key.slice(1))),
           "rawValue": JSON.parse(key.slice(1)).Value,
           "Timeframe": formatTime(JSON.parse(key.slice(1))),
           "Frequency":JSON.parse(key.slice(1)).Frequency,
           "Detail":JSON.parse(key.slice(1)).details,
           "negation":JSON.parse(key.slice(1)).negation
         }
         demographicsElements.push(newItem)
         setDemographicsElements(demographicsElements)
       }else {
         demographicsElements.splice(indexdemographicsElements, 1)
         setDemographicsElements(demographicsElements)
       }
       break;
     case "M":
       if(indexmedConditionElements < 0){
         var newItem = {
           "Eligibility Criteria": JSON.parse(key.slice(1)).Text,
           "Values": formatValue(JSON.parse(key.slice(1))),
           "rawValue": JSON.parse(key.slice(1)).Value,
           "Timeframe": formatTime(JSON.parse(key.slice(1))),
           "Frequency":JSON.parse(key.slice(1)).Frequency,
           "Detail":JSON.parse(key.slice(1)).details,
           "negation":JSON.parse(key.slice(1)).negation
         }
         medConditionElements.push(newItem)
         setMedConditionElements(medConditionElements)
       }else {
         medConditionElements.splice(indexmedConditionElements, 1)
         setMedConditionElements(medConditionElements)
       }
     break;
     case "I":
       if(indexinterventionElements < 0){
         var newItem = {
           "Eligibility Criteria": JSON.parse(key.slice(1)).Text,
           "Values": formatValue(JSON.parse(key.slice(1))),
           "rawValue": JSON.parse(key.slice(1)).Value,
           "Timeframe": formatTime(JSON.parse(key.slice(1))),
           "Frequency":JSON.parse(key.slice(1)).Frequency,
           "Detail":JSON.parse(key.slice(1)).details,
           "negation":JSON.parse(key.slice(1)).negation
         }
         interventionElements.push(newItem)
         setInterventionElements(interventionElements)
       }else {
         interventionElements.splice(indexinterventionElements, 1)
         setInterventionElements(interventionElements)
       }
     break;
     default:
       if(indexlabTestElements < 0){
         var newItem = {
           "Eligibility Criteria": JSON.parse(key.slice(1)).Text,
           "Values": formatValue(JSON.parse(key.slice(1))),
           "rawValue": JSON.parse(key.slice(1)).Value,
           "Timeframe": formatTime(JSON.parse(key.slice(1))),
           "Frequency":JSON.parse(key.slice(1)).Frequency,
           "Detail":JSON.parse(key.slice(1)).details,
           "negation":JSON.parse(key.slice(1)).negation
         }
         labTestElements.push(newItem)
         setLabTestElements(labTestElements)
       }else {
         labTestElements.splice(indexlabTestElements, 1)
         setLabTestElements(labTestElements)
       }
     break;
   }
   updateTrial(1, 1)
};

 const onItemClickExclu = ({ key }) => {
   setVisibleValueExclu(true)
   let indexdemographicsElements = excluDemographicsElements.findIndex((domain) => JSON.parse(key.slice(1)).Text == domain['Eligibility Criteria'] &&  JSON.parse(key.slice(1)).negation == domain['negation']);
   let indexmedConditionElements = excluMedConditionElements.findIndex((domain) => JSON.parse(key.slice(1)).Text == domain['Eligibility Criteria'] &&  JSON.parse(key.slice(1)).negation == domain['negation']);
   let indexinterventionElements = excluInterventionElements.findIndex((domain) => JSON.parse(key.slice(1)).Text == domain['Eligibility Criteria'] &&  JSON.parse(key.slice(1)).negation == domain['negation']);
   let indexlabTestElements = excluLabTestElements.findIndex((domain) => JSON.parse(key.slice(1)).Text == domain['Eligibility Criteria'] &&  JSON.parse(key.slice(1)).negation == domain['negation']);
   //  if key includes in [], delete; if not includes, push []
   switch(key.charAt(0)) {
     case "D":
       if(indexdemographicsElements < 0){
         var newItem = {
           "Eligibility Criteria": JSON.parse(key.slice(1)).Text,
           "Values": formatValue(JSON.parse(key.slice(1))),
           "rawValue": JSON.parse(key.slice(1)).Value,
           "Timeframe": formatTime(JSON.parse(key.slice(1))),
           "Frequency":JSON.parse(key.slice(1)).Frequency,
           "Detail":JSON.parse(key.slice(1)).details,
           "negation":JSON.parse(key.slice(1)).negation
         }
         excluDemographicsElements.push(newItem)
         setExcluDemographicsElements(excluDemographicsElements)
       }else {
         excluDemographicsElements.splice(indexdemographicsElements, 1)
         setExcluDemographicsElements(excluDemographicsElements)
       }
       break;
     case "M":
       if(indexmedConditionElements < 0){
         var newItem = {
           "Eligibility Criteria": JSON.parse(key.slice(1)).Text,
           "Values": formatValue(JSON.parse(key.slice(1))),
           "rawValue": JSON.parse(key.slice(1)).Value,
           "Timeframe": formatTime(JSON.parse(key.slice(1))),
           "Frequency":JSON.parse(key.slice(1)).Frequency,
           "Detail":JSON.parse(key.slice(1)).details,
           "negation":JSON.parse(key.slice(1)).negation
         }
         excluMedConditionElements.push(newItem)
         setExcluMedConditionElements(excluMedConditionElements)
       }else {
         excluMedConditionElements.splice(indexmedConditionElements, 1)
         setExcluMedConditionElements(excluMedConditionElements)
       }
     break;
     case "I":
       if(indexinterventionElements < 0){
         var newItem = {
           "Eligibility Criteria": JSON.parse(key.slice(1)).Text,
           "Values": formatValue(JSON.parse(key.slice(1))),
           "rawValue": JSON.parse(key.slice(1)).Value,
           "Timeframe": formatTime(JSON.parse(key.slice(1))),
           "Frequency":JSON.parse(key.slice(1)).Frequency,
           "Detail":JSON.parse(key.slice(1)).details,
           "negation":JSON.parse(key.slice(1)).negation
         }
         excluInterventionElements.push(newItem)
         setExcluInterventionElements(excluInterventionElements)
       }else {
         excluInterventionElements.splice(indexinterventionElements, 1)
         setExcluInterventionElements(excluInterventionElements)
       }
     break;
     default:
       if(indexlabTestElements < 0){
         var newItem = {
           "Eligibility Criteria": JSON.parse(key.slice(1)).Text,
           "Values": formatValue(JSON.parse(key.slice(1))),
           "rawValue": JSON.parse(key.slice(1)).Value,
           "Timeframe": formatTime(JSON.parse(key.slice(1))),
           "Frequency":JSON.parse(key.slice(1)).Frequency,
           "Detail":JSON.parse(key.slice(1)).details,
           "negation":JSON.parse(key.slice(1)).negation
         }
         excluLabTestElements.push(newItem)
         setExcluLabTestElements(excluLabTestElements)
       }else {
         excluLabTestElements.splice(indexlabTestElements, 1)
         setExcluLabTestElements(excluLabTestElements)
       }
     break;
   }
   updateTrial(2, 1)
};

const compareString = (original, sub_str) => {
  let index = original.toLocaleLowerCase().indexOf(sub_str)
  return original.substring(index, sub_str.length)
}

const compareMiddle = (original, sub_str) => {
  let index = original.toLocaleLowerCase().indexOf(sub_str);
  let secondIndex = original.toLocaleLowerCase().length == sub_str.toLocaleLowerCase().length ? original.toLocaleLowerCase().length : index+sub_str.length;
  return original.substring(index, secondIndex)
}

const renderItem = (title: string, type: string, idx: any, Frequency: any, negation: any) => {
   return (
    <Tooltip
    title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}
    >
     <span style={  { background: negation ? '#ca4a04' : '', marginRight: '10px', display: 'inline-block', padding: '7px', borderRadius: '50%' }}></span> 
     <div
       className="itemLine"
       style={{
         display: 'inline-flex',
         justifyContent: 'space-between',
       }}
     >

       <span className="itemTitle">
         {/* {(searchTxt.length < title.length)&& title.toLowerCase().split(searchTxt)[0]} */}
         {(searchTxt.length < title.length) && compareString(title, title.toLowerCase().split(searchTxt)[0])} 
         <span className={`${
           searchTxt &&
           title.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
             ? "matched-item"
             : ""
           }`}>
            {/* {searchTxt.length - title.length === 0?title:searchTxt} */}
            {compareMiddle(title, searchTxt.length - title.length === 0?title:searchTxt)}
         </span>
         {/* {(searchTxt.length !== 0)&&(searchTxt.length < title.length)&&title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length)} */}
         { (searchTxt.length !== 0)&&(searchTxt.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length))}
       </span>
       <span style={{color:"#CA4A04", marginLeft:"25px"}}>
         Add
       </span>
     </div>
     </Tooltip>
   )
 };

const renderItemClick = (title: string, type: string, idx: any, Frequency: any, negation: any) => {
   return (
   <Tooltip
    title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}
    >
     <span style={  { background: negation ? '#ca4a04' : '', marginRight: '10px', display: 'inline-block', padding: '7px', borderRadius: '50%' }}></span> 
     <div
       className="itemLine"
       style={{
         display: 'inline-flex',
         justifyContent: 'space-between',
       }}
     > 
       <span className="itemTitle">
         {/* {(searchTxt.length < title.length)&& title.toLowerCase().split(searchTxt)[0]} */}
         {(searchTxt.length < title.length) && compareString(title, title.toLowerCase().split(searchTxt)[0])} 
         <span className={`${
           searchTxt &&
           title.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
             ? "matched-item"
             : ""
           }`}>
            {/* {searchTxt.length - title.length === 0?title:searchTxt} */}
            {compareMiddle(title, searchTxt.length - title.length === 0?title:searchTxt)}
         </span>
         {/* {(searchTxt.length !== 0)&&(searchTxt.length < title.length)&&title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length)} */}
         { (searchTxt.length !== 0)&&(searchTxt.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxt)[0]+(searchTxt.length - title.length === 0?title:searchTxt)+'').length))}
       </span>
       <span style={{color:"#3193E5", fontWeight:700, marginLeft:"25px"}}>
           <CheckOutlined />
       </span>
     </div>
     </Tooltip>
   )
 };

const renderItemExclu = (title: string, type: string, idx: any, Frequency: any, negation: any) => {
   return (
    <Tooltip
    title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}
    >
     <span style={  { background: negation ? '#ca4a04' : '', marginRight: '10px', display: 'inline-block', padding: '7px', borderRadius: '50%' }}></span> 
     <div
       className="itemLine"
       style={{
         display: 'inline-flex',
         justifyContent: 'space-between',
       }}
     >
       <span className="itemTitle">
         {/* {(searchTxtExclu.length < title.length)&& title.toLowerCase().split(searchTxtExclu)[0]} */}
         {(searchTxtExclu.length < title.length) && compareString(title, title.toLowerCase().split(searchTxtExclu)[0])} 
         <span className={`${
           searchTxtExclu &&
           title.toLowerCase().indexOf(searchTxtExclu.toLowerCase()) > -1
             ? "matched-item"
             : ""
           }`}>
            {/* {searchTxtExclu.length - title.length === 0?title:searchTxtExclu} */}
            {compareMiddle(title, searchTxtExclu.length - title.length === 0?title:searchTxtExclu)}
         </span>
         {/* {(searchTxtExclu.length !== 0)&&(searchTxtExclu.length < title.length)&&title.toLowerCase().slice((title.toLowerCase().split(searchTxtExclu)[0]+(searchTxtExclu.length - title.length === 0?title:searchTxtExclu)+'').length)} */}
         { (searchTxtExclu.length !== 0)&&(searchTxtExclu.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxtExclu)[0]+(searchTxtExclu.length - title.length === 0?title:searchTxtExclu)+'').length))}
       </span>
       <span style={{color:"#CA4A04", marginLeft:"25px"}}>
         Add
       </span>
     </div>
     </Tooltip>
   )
 };

const renderItemClickExclu = (title: string, type: string, idx: any, Frequency: any, negation: any) => {
   return (
    <Tooltip
    title={"Frequency " + Math.round(Frequency * 10000) / 100 + "%"}
    >
     <span style={  { background: negation ? '#ca4a04' : '', marginRight: '10px', display: 'inline-block', padding: '7px', borderRadius: '50%' }}></span> 
     <div
       className="itemLine"
       style={{
         display: 'inline-flex',
         justifyContent: 'space-between',
       }}
     >
       <span className="itemTitle">
         {/* {(searchTxtExclu.length < title.length)&& title.toLowerCase().split(searchTxtExclu)[0]} */}
         {(searchTxtExclu.length < title.length) && compareString(title, title.toLowerCase().split(searchTxtExclu)[0])} 
         <span className={`${
           searchTxtExclu &&
           title.toLowerCase().indexOf(searchTxtExclu.toLowerCase()) > -1
             ? "matched-item"
             : ""
           }`}>
            {/* {searchTxtExclu.length - title.length === 0?title:searchTxtExclu} */}
            {compareMiddle(title, searchTxtExclu.length - title.length === 0?title:searchTxtExclu)}
         </span>
         {/* {(searchTxtExclu.length !== 0)&&(searchTxtExclu.length < title.length)&&title.toLowerCase().slice((title.toLowerCase().split(searchTxtExclu)[0]+(searchTxtExclu.length - title.length === 0?title:searchTxtExclu)+'').length)} */}
         { (searchTxtExclu.length !== 0)&&(searchTxtExclu.length < title.length) && compareMiddle(title, title.toLowerCase().slice((title.toLowerCase().split(searchTxtExclu)[0]+(searchTxtExclu.length - title.length === 0?title:searchTxtExclu)+'').length))}
       </span>
       <span style={{color:"#3193E5", fontWeight:700, marginLeft:"25px"}}>
           <CheckOutlined />
       </span>
     </div>
     </Tooltip>
   )
 };

 const menu = (
 <Menu onClick={onItemClick}>
   {(searchTxt.length !== 0)&&searchDemographics.length>0 && (<Menu.ItemGroup title="Demographics">
    {
       searchDemographics.slice(0, 10).map((item,idx)=>{
         if(demographicsElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation'])<0){
           return <Menu.Item key={"D"+JSON.stringify(item)}>{renderItem(item.Text,"D", idx, item.Frequency, item.negation)}</Menu.Item>
         } else {
           return <Menu.Item key={"D"+JSON.stringify(item)}>{renderItemClick(item.Text,"D", idx, item.Frequency, item.negation)}</Menu.Item>
         }
       })
     }
   </Menu.ItemGroup>)}
   {(searchTxt.length !== 0)&&searchMedCondition.length>0 && 10-searchDemographics.length>0?(<Menu.ItemGroup title="Medical Condition">
     {
       searchMedCondition.slice(0,10-searchDemographics.length).map((item,idx)=>{
         if(medConditionElements.findIndex((domain) => item.Text == domain['Eligibility Criteria']  && item.negation == domain['negation'])<0){
           return <Menu.Item key={"M"+JSON.stringify(item)}>{renderItem(item.Text, "M",idx, item.Frequency, item.negation)}</Menu.Item>
         }else {
           return <Menu.Item key={"M"+JSON.stringify(item)}>{renderItemClick(item.Text, "M",idx, item.Frequency, item.negation)}</Menu.Item>
         }
       })
     }
   </Menu.ItemGroup>):(<></>)}
   {(searchTxt.length !== 0)&&searchIntervention.length>0 &&10-searchDemographics.length-searchMedCondition.length>0?(<Menu.ItemGroup title="Intervention">
      {
       searchIntervention.slice(0,10-searchDemographics.length-searchMedCondition.length).map((item,idx)=>{
         if(interventionElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation'])<0){
           return <Menu.Item key={"I"+JSON.stringify(item)}>{renderItem(item.Text, "I",idx, item.Frequency, item.negation)}</Menu.Item>
         }else{
           return <Menu.Item key={"I"+JSON.stringify(item)}>{renderItemClick(item.Text, "I",idx, item.Frequency, item.negation)}</Menu.Item>
         }
       })
     }
   </Menu.ItemGroup>):(<></>)}
   {(searchTxt.length !== 0)&&searchLabTest.length>0 && 10-searchDemographics.length-searchMedCondition.length - searchIntervention.length>0?<Menu.ItemGroup title="Lab / Test">
      {
       searchLabTest.slice(0,10-searchDemographics.length-searchMedCondition.length- searchIntervention.length).map((item,idx)=>{
         if(labTestElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation'])<0){
           return <Menu.Item key={"L"+JSON.stringify(item)}>{renderItem(item.Text, "L",idx, item.Frequency, item.negation)}</Menu.Item>
         }else{
           return <Menu.Item key={"L"+JSON.stringify(item)}>{renderItemClick(item.Text, "L",idx, item.Frequency, item.negation)}</Menu.Item>
         }
       })
     }
   </Menu.ItemGroup>:(<></>)}
   {searchTxt.length === 0 && <div style={{cursor: "none", padding: 20}}></div>}
   {(searchTxt.length !== 0 && searchDemographics.length + searchMedCondition.length + searchIntervention.length + searchLabTest.length === 0) && <div key="no-result" style={{cursor: "none", padding: 20}}>There are no Inclusion criteria in the library that match your search.</div>}
 </Menu>
);

 const menuExclu = (
   <Menu onClick={onItemClickExclu}>
     {(searchTxtExclu.length !== 0)&&searchDemographicsExclu.length>0&&(<Menu.ItemGroup title="Demographics">
     {
         searchDemographicsExclu.slice(0, 10).map((item,idx)=>{
           if(excluDemographicsElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation'])<0){
             return <Menu.Item key={"D"+JSON.stringify(item)}>{renderItemExclu(item.Text, "D", idx, item.Frequency, item.negation)}</Menu.Item>
           } else {
             return <Menu.Item key={"D"+JSON.stringify(item)}>{renderItemClickExclu(item.Text,"D", idx, item.Frequency, item.negation)}</Menu.Item>
           }
         })
       }
     </Menu.ItemGroup>)}
     {(searchTxtExclu.length !== 0)&& searchMedConditionExclu.length>0&&10-searchDemographicsExclu.length>0?(<Menu.ItemGroup title="Medical Condition">
       {
       searchMedConditionExclu.slice(0,10-searchDemographicsExclu.length).map((item,idx)=>{
           if(excluMedConditionElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation'])<0){
             return <Menu.Item key={"M"+JSON.stringify(item)}>{renderItemExclu(item.Text, "M", idx, item.Frequency, item.negation)}</Menu.Item>
           }else {
             return <Menu.Item key={"M"+JSON.stringify(item)}>{renderItemClickExclu(item.Text, "M",idx, item.Frequency, item.negation)}</Menu.Item>
           }
         })
       }
     </Menu.ItemGroup>):(<></>)}
    {(searchTxtExclu.length !== 0)&& searchInterventionExclu.length>0&&10-searchDemographicsExclu.length-searchMedConditionExclu.length>0?( <Menu.ItemGroup title="Intervention">
       {
       searchInterventionExclu.slice(0,10-searchDemographicsExclu.length-searchMedConditionExclu.length).map((item,idx)=>{
           if(excluInterventionElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation'])<0){
             return <Menu.Item key={"I"+JSON.stringify(item)}>{renderItemExclu(item.Text, "I" ,idx, item.Frequency, item.negation)}</Menu.Item>
           }else{
             return <Menu.Item key={"I"+JSON.stringify(item)}>{renderItemClickExclu(item.Text, "I", idx, item.Frequency, item.negation)}</Menu.Item>
           }
         })
       }
     </Menu.ItemGroup>):(<></>)}
     {(searchTxtExclu.length !== 0)&& searchLabTestExclu.length>0&&10-searchDemographicsExclu.length-searchMedConditionExclu.length - searchInterventionExclu.length>0?(<Menu.ItemGroup title="Lab / Test">
       {
       searchLabTestExclu.slice(0,10-searchDemographicsExclu.length-searchMedConditionExclu.length - searchInterventionExclu.length).map((item,idx)=>{
           if(excluLabTestElements.findIndex((domain) => item.Text == domain['Eligibility Criteria'] && item.negation == domain['negation'])<0){
             return <Menu.Item key={"L"+JSON.stringify(item)}>{renderItemExclu(item.Text, "L", idx, item.Frequency, item.negation)}</Menu.Item>
           }else{
             return <Menu.Item key={"L"+JSON.stringify(item)}>{renderItemClickExclu(item.Text, "L", idx, item.Frequency, item.negation)}</Menu.Item>
           }
         })
       }
     </Menu.ItemGroup>):(<></>)}
    {searchTxtExclu.length === 0 && <div style={{cursor: "none", padding: 20}}></div>}
    {(searchTxtExclu.length !== 0 && searchDemographicsExclu.length + searchMedConditionExclu.length + searchInterventionExclu.length + searchLabTestExclu.length === 0) && <div key="no-result" style={{cursor: "none", padding: 20}}>There are no Exclusion criteria in the library that match your search.</div>}
   </Menu>
 );

     // update UI to new wireframe
  const [activeCollapse, setActiveCollapse] = useState([''])

  const eventLibHeader = (name, count, key) => {
    return (
      <Row className="section-header">
        <Col span={23}><span>{name}</span><span className="count-span">{count}</span></Col>
        <Col span={1} className="collapse-icon">{activeCollapse.indexOf(key) >= 0 ?(<MinusOutlined />):(<PlusOutlined />)}</Col>
      </Row>
    );
  };

  const criteriaCallback = (key) => {
    setActiveCollapse(key)
  }

  const onStepChange = (value: number) => {
    setProcessStep(value);
    value === 0 && setActiveTabKey('1')
  };

  interface DataType {
    key: number;
    category: string;
    criteria: string;
    rate: number;
  }

  const columnsPAR: ColumnsType<DataType> = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      key: 'criteria',
    },
    {
      title: 'Amendment Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: val => (Math.round(val * 10000) / 100 + "%")
    }
  ];

  const columnsSFR: ColumnsType<DataType> = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      key: 'criteria',
    },
    {
      title: 'Screen Failure',
      dataIndex: 'rate',
      key: 'rate',
      render: val => (Math.round(val * 10000) / 100 + "%")
    }
  ];

  const onInsightClick = (key) => { 
    let rawInsight = [...insightInclu]   
    let itemIndex = rawInsight.findIndex((d) => {
      return d.criteria == key.criteria && d.negation == key.negation
    })
    let items = rawInsight.splice(itemIndex, 1);
    setInsightInclu(rawInsight)
    let indexdemographicsElements = demographicsElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'] && key.negation == domain['negation'])
    let detailDemographics 
    let indexDetailDemographics
    indexDetailDemographics = originDemographics.findIndex((domain) => key.criteria == domain['Text'] && key.negation == domain['negation'])
    indexDetailDemographics>-1?detailDemographics = originDemographics[indexDetailDemographics]:[]
    
    let indexmedConditionElements = medConditionElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'] && key.negation == domain['negation'])
    let detailmedCondition
    let indexDetailmedCondition
    indexDetailmedCondition = originMedCondition.findIndex((domain) => key.criteria == domain['Text'] && key.negation == domain['negation'])
    indexDetailmedCondition>-1?detailmedCondition = originMedCondition[indexDetailmedCondition]:[]

    let indexinterventionElements = interventionElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'] && key.negation == domain['negation'])
    let detailintervention 
    let indexDetailintervention
    indexDetailintervention = originIntervention.findIndex((domain) => key.criteria == domain['Text'] && key.negation == domain['negation'])
    indexDetailintervention>-1?detailintervention = originIntervention[indexDetailintervention]:[]

    let indexlabTestElements = labTestElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'] && key.negation == domain['negation'])
    let detaillabTest 
    let indexDetaillabTest
    indexDetaillabTest = originLabTest.findIndex((domain) => key.criteria == domain['Text'] && key.negation == domain['negation'])
    indexDetaillabTest>-1?detaillabTest = originLabTest[indexDetaillabTest]:[]

    switch(key.category.charAt(0)) {
      case "D":
        if(indexdemographicsElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "Values": formatValue(detailDemographics),
            "rawValue": detailDemographics.Value,
            "Timeframe": formatTime(detailDemographics),
            "Frequency":detailDemographics.Frequency,
            "Detail":detailDemographics.details,
            "negation": detailDemographics.negation
          }
          demographicsElements.push(newItem)
          setDemographicsElements(demographicsElements)
        }else if (indexdemographicsElements > -1 && key.action === "REMOVE"){
          demographicsElements.splice(indexdemographicsElements, 1)
          setDemographicsElements(demographicsElements)
        }
        break;
      case "M":
        if(indexmedConditionElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "Values": formatValue(detailmedCondition),
            "rawValue": detailmedCondition.Value,
            "Timeframe": formatTime(detailmedCondition),
            "Frequency":detailmedCondition.Frequency,
            "Detail":detailmedCondition.details,
            "negation": detailmedCondition.negation
          }
          medConditionElements.push(newItem)
          setMedConditionElements(medConditionElements)
        }else if (indexmedConditionElements > -1 && key.action === "REMOVE"){
          medConditionElements.splice(indexmedConditionElements, 1)
          setMedConditionElements(medConditionElements)
        }
        break;
      case "I":
        if(indexinterventionElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "Values": formatValue(detailintervention),
            "rawValue": detailintervention.Value,
            "Timeframe": formatTime(detailintervention),
            "Frequency":detailintervention.Frequency,
            "Detail":detailintervention.details,
            "negation": detailintervention.negation
          }
          interventionElements.push(newItem)
          setInterventionElements(interventionElements)
        }else if (indexinterventionElements > -1 && key.action === "REMOVE"){
          interventionElements.splice(indexinterventionElements, 1)
          setInterventionElements(interventionElements)
        }
      break;
      default:
        if(indexlabTestElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "Values": formatValue(detaillabTest),
            "rawValue": detaillabTest.Value,
            "Timeframe": formatTime(detaillabTest),
            "Frequency":detaillabTest.Frequency,
            "Detail":detaillabTest.details,
            "negation": detaillabTest.negation
          }
          labTestElements.push(newItem)
          setLabTestElements(labTestElements)
        }else if (indexlabTestElements > -1 && key.action === "REMOVE") {
          labTestElements.splice(indexlabTestElements, 1)
          setLabTestElements(labTestElements)
        }
      break;
    }
    updateTrial(1, 1)
  };
 
  const onInsightExclu = (key) => {
    let rawInsight = [...insightExclu]
    rawInsight = rawInsight.filter((d) => {
      return d.criteria !== key.criteria
    })
    setInsightExclu(rawInsight)
    let indexdemographicsElements = excluDemographicsElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'] && key.negation == domain['negation']);
    let detailDemographics 
    let indexDetailDemographics
    indexDetailDemographics = originExcluDemographics.findIndex((domain) => key.criteria == domain['Text'] && key.negation == domain['negation'])
    indexDetailDemographics>-1?detailDemographics = originExcluDemographics[indexDetailDemographics]:[]

    let indexmedConditionElements = excluMedConditionElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'] && key.negation == domain['negation']);
    let detailmedCondition
    let indexDetailmedCondition
    indexDetailmedCondition = originExcluMedCondition.findIndex((domain) => key.criteria == domain['Text'] && key.negation == domain['negation'])
    indexDetailmedCondition>-1?detailmedCondition = originExcluMedCondition[indexDetailmedCondition]:[]

    let indexinterventionElements = excluInterventionElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'] && key.negation == domain['negation']);
    let detailintervention 
    let indexDetailintervention
    indexDetailintervention = originExcluIntervention.findIndex((domain) => key.criteria == domain['Text'] && key.negation == domain['negation'])
    indexDetailintervention>-1?detailintervention = originExcluIntervention[indexDetailintervention]:[]

    let indexlabTestElements = excluLabTestElements.findIndex((domain) => key.criteria == domain['Eligibility Criteria'] && key.negation == domain['negation']);
    let detaillabTest 
    let indexDetaillabTest
    indexDetaillabTest = originExcluLabTest.findIndex((domain) => key.criteria == domain['Text'] && key.negation == domain['negation'])
    indexDetaillabTest>-1?detaillabTest = originExcluLabTest[indexDetaillabTest]:[]
  
    switch(key.category.charAt(0)) {
      case "D":
        if(indexdemographicsElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "Values": formatValue(detailDemographics),
            "rawValue": detailDemographics.Value,
            "Timeframe": formatTime(detailDemographics),
            "Frequency":detailDemographics.Frequency,
            "Detail":detailDemographics.details,
            "negation": detailDemographics.negation
          }
          excluDemographicsElements.push(newItem)
          setExcluDemographicsElements(excluDemographicsElements)
        }else if (indexdemographicsElements > -1 && key.action === "REMOVE"){
          excluDemographicsElements.splice(indexdemographicsElements, 1)
          setExcluDemographicsElements(excluDemographicsElements)
        }
        break;
      case "M":
        if(indexmedConditionElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "Values": formatValue(detailmedCondition),
            "rawValue": detailmedCondition.Value,
            "Timeframe": formatTime(detailmedCondition),
            "Frequency":detailmedCondition.Frequency,
            "Detail":detailmedCondition.details,
            "negation": detailmedCondition.negation
          }
          excluMedConditionElements.push(newItem)
          setExcluMedConditionElements(excluMedConditionElements)
        }else if (indexmedConditionElements > -1 && key.action === "REMOVE"){
          excluMedConditionElements.splice(indexmedConditionElements, 1)
          setExcluMedConditionElements(excluMedConditionElements)
        }
      break;
      case "I":
        if(indexinterventionElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "Values": formatValue(detailintervention),
            "rawValue": detailintervention.Value,
            "Timeframe": formatTime(detailintervention),
            "Frequency":detailintervention.Frequency,
            "Detail":detailintervention.details,
            "negation": detailintervention.negation
          }
          excluInterventionElements.push(newItem)
          setExcluInterventionElements(excluInterventionElements)
        }else if (indexinterventionElements > -1 && key.action === "REMOVE"){
          excluInterventionElements.splice(indexinterventionElements, 1)
          setExcluInterventionElements(excluInterventionElements)
        }
      break;
      default:
        if(indexlabTestElements < 0 && key.action === "ADD"){
          var newItem = {
            "Eligibility Criteria": key.criteria,
            "Values": formatValue(detaillabTest),
            "rawValue": detaillabTest.Value,
            "Timeframe": formatTime(detaillabTest),
            "Frequency":detaillabTest.Frequency,
            "Detail":detaillabTest.details,
            "negation": detaillabTest.negation
          }
          excluLabTestElements.push(newItem)
          setExcluLabTestElements(excluLabTestElements)
        }else if (indexlabTestElements > -1 && key.action === "REMOVE") {
          excluLabTestElements.splice(indexlabTestElements, 1)
          setExcluLabTestElements(excluLabTestElements)
        }
      break;
    }
    updateTrial(2, 1)
  };

  const onDismiss = (key) => {
    let rawList = [...dismissInclu]
    rawList.push(key)
    setDismissInclu(rawList)
    let rawInsight = [...insightInclu]   
    rawInsight = rawInsight.filter((d) => {
     return d.criteria !== key.criteria
    })
    setInsightInclu(rawInsight)
  }
 
  const onDismissExclu = (key) => {
   let rawList = [...dismissExclu]
   rawList.push(key)
   setDismissExclu(rawList)
   let rawInsight = [...insightExclu]
    rawInsight = rawInsight.filter((d) => {
     return d.criteria !== key.criteria
    })
    setInsightExclu(rawInsight)
  }

  const detailContent = (detailData) => {
    switch (detailData) {
      case 'inclusionPAR':
        return (
          <Table className="criteria-detail-table" columns={columnsPAR} dataSource={scenario['highest_amendment_rate']&&scenario['highest_amendment_rate']['Inclusion']?scenario['highest_amendment_rate']['Inclusion']:[]} pagination={false} size={'small'}/>
          )
        break;
      case 'inclusionSFR':
        return (
          <Table columns={columnsSFR} dataSource={scenario['highest_screen_failure_rate']&&scenario['highest_screen_failure_rate']['Inclusion']?scenario['highest_screen_failure_rate']['Inclusion']:[]} pagination={false} size={'small'}/>
          )
        break;
      case 'exclusionPAR':
        return (
          <Table columns={columnsPAR} dataSource={scenario['highest_amendment_rate']&&scenario['highest_amendment_rate']['Exclusion']?scenario['highest_amendment_rate']['Exclusion']:[]} pagination={false} size={'small'}/>
          )
        break;
      case 'exclusionSFR':
        return (
          <Table columns={columnsSFR} dataSource={scenario['highest_screen_failure_rate']&&scenario['highest_screen_failure_rate']['Exclusion']?scenario['highest_screen_failure_rate']['Exclusion']:[]} pagination={false} size={'small'}/>
          )
        break;
      default:
        break;
    }
  }

    return (
    <div className="scenario-container">
      <Spin spinning={pageLoading} indicator={<LoadingOutlined style={{ color: "#ca4a04",fontSize: 24 }}/>}>
      {/* <div>
        <Row className="process-container">
            <Col flex="100px" className="center">
                <div className="action-title" onClick={()=>props.history.push({pathname: '/trials', state: {trial_id: props.location.state.trial_id}})}>
                    <LeftOutlined /> &nbsp;Trial Page
                </div>
            </Col>
            <Col flex="240px" className="scenario-header center">
              <Row>
              <Col span={24}>
                <Row className="item-translate">
                    <Col flex="auto">
                      <span>
                        <Text
                          style={{width: 90, fontSize:15, color:'#fff'}}
                          ellipsis={{tooltip:trialTitle}}
                        >
                          {trialTitle?trialTitle:'-'}
                        </Text>
                      </span>
                      <span>
                        &nbsp;:&nbsp;{scenarioType}
                      </span>
                    </Col>
                  </Row>
                <Row className="item-translate">
                    <Col flex="auto" className="name">
                      <Text
                        style={{width: 217, fontSize:16, fontWeight: 'bold', color:'#fff'}}
                        ellipsis={{tooltip:scenario['scenario_name']}}
                      >
                        {scenario['scenario_name']}
                      </Text>
                    </Col>
                </Row>
                </Col>
                </Row>
            </Col>
            <Col flex="420px" className="center" style={{paddingLeft: '10px'}}>
                <Steps
                  progressDot
                  current={processStep}
                  size="small"
                  onChange={onStepChange}
                >
                    <Step title="Add Inclusion / Exclusion Criteria"/>
                    <Step title="Add Schedule of Events"/>
                </Steps>
            </Col> */}
            {/* delete this Col for showing  Enrollment Feasibility*/}
             {/* <Col flex="auto" className={`center ${ collapsible ? "" : "" }`} >
                {activeTabKey === '1'&& processStep === 0 &&(
                    <>
                        <Button type="primary" className="step-btn" onClick={() => setActiveTabKey('2')}>
                            NEXT:EXCLUSION CRITERIA
                        </Button>
                    </>
                )}
                {activeTabKey === '2'&& processStep === 0&&
                    <>
                        <Button type="primary" className="step-btn" onClick={() => setProcessStep(1)}>
                            NEXT:ADD SCHEDULE OF ACTIVITIES
                        </Button>
                        <Button className="view-btn step-btn" onClick={() => setActiveTabKey('1')}>
                            PREV:INCLUSION CRITERIA
                        </Button>
                    </>}
                    {processStep === 1&&
                      <>
                      <Button type="primary" className="step-btn"  onClick={()=> setSubmitType(2)}>
                          SAVE AND FINISH LATER
                      </Button>
                      <Button className="view-btn step-btn" onClick={() => {
                        setProcessStep(0)
                        setActiveTabKey('2')
                      }
                      }>
                          PREV:EXCLUSION CRITERIA
                      </Button>
                      </>}
            </Col> */}
            {/* <Col span={10} className={`center ${ collapsible ? "none-click" : "" }`} >
                {activeTabKey === '1'?(
                    <>
                        <Button type="primary" className="step-btn" onClick={() => setActiveTabKey('2')}>
                            NEXT:EXCLUSION CRITERIA
                        </Button>
                    </>
                ):(activeTabKey === '2'?(
                    <>
                        <Button type="primary" className="step-btn" onClick={() => changeActiveTabKey('3')}>
                            NEXT:ENROLLMENT FEASIBILITY
                        </Button>
                        <Button className="view-btn step-btn" onClick={() => setActiveTabKey('1')}>
                            PREV:INCLUSION CRITERIA
                        </Button>
                    </>
                ):(processStep === 0?(

                    <>
                        <Button type="primary" className="step-btn" onClick={() => setProcessStep(1)}>
                            NEXT:ADD SCHEDULE OF ACTIVITIES
                        </Button>
                        <Button className="view-btn step-btn" onClick={() => setActiveTabKey('2')}>
                            PREV:EXCLUSION CRITERIA
                        </Button>
                    </>
                ):(
                    <>
                        <Button type="primary" className="step-btn"  onClick={()=> setSubmitType(2)}>
                            SAVE AND FINISH LATER
                        </Button>
                        <Button className="view-btn step-btn" onClick={() => setSubmitType(1)}>
                            PREV:ENROLLMENT FEASIBILITY
                        </Button>
                    </>
                )))}
            </Col> */}

        {/* </Row>
      </div> */}
      {processStep === 0 && (
        <Row>
          <ScenarioLeftPanel 
          trialTitle={trialTitle} 
          scenario={scenario} 
          activeTabKey={activeTabKey}
          processStep={processStep}
          collapsible={collapsible}
          tabChangeHandler={changeActiveTabKey}
          IEScheduleChangeHandler={onStepChange}
          scheduleEvent={false}
          onClickPrevious={()=>{
            props.history.push({pathname: '/trials', state: {trial_id: props.location.state.trial_id}})
            localStorage.setItem('processStep', JSON.stringify(0));
            localStorage.setItem('activeTabKey', JSON.stringify('1'));
        }} 
          />
          <Col 
            //span={21}
            xxl={{span: 21 }}
            xl={{ span: 20 }}
            lg={{ span: 20 }}
            md={{ span: 16 }}
            sm={{ span: 16 }}
            xs={{ span: 16 }}
            >
          <Row className="ie-container">
          <IEScenarioPanel 
            scenario={scenario}
            processStep={processStep}
            activeTabKey={activeTabKey}
            handleExport={handleExport}
            setActiveTabKey={setActiveTabKey}
            setProcessStep={setProcessStep}
            onClickMyTrials={()=>{
              props.history.push({pathname: '/trials'})
              localStorage.setItem('processStep', JSON.stringify(0))
              localStorage.setItem('activeTabKey', JSON.stringify('1'))
              localStorage.setItem('show-details', 'false')
              localStorage.setItem('trial-id', '')
            }}
            onClickPrevious={()=>{
              props.history.push({pathname: '/trials', state: {trial_id: props.location.state.trial_id}})
              localStorage.setItem('processStep', JSON.stringify(0))
              localStorage.setItem('activeTabKey', JSON.stringify('1'))
            }}
            setSubmitType={setSubmitType}
            tabChangeHandler={changeActiveTabKey}
            disableSaveFinish={disableSaveFinish}
            readOnly={readOnlyAccess || !isAdmin && scenario?.is_template}
          />
        <div className="tab-container">
          <Tabs 
            // onChange={changeActiveTabKey} 
            activeKey={activeTabKey} centered>
            <TabPane 
            //tab="Inclusion Criteria" 
            key="1">
              <Row>
              {<Col span={1} style={{maxWidth: '50px', minWidth: '50px'}}>
                  <div className={`side-toolbar ${ activeTabKey != "1" ? '' : ''}`}>
                  <Tooltip placement="right" title={'Inclusion Criteria Library'}>
                    <FileTextFilled style={{color:`${inclusionPanel==="1"?"#404042":"#999999"}`, fontSize: "16px", marginLeft: "15px", paddingTop: "12px", cursor: "pointer"}} onClick={()=> {
                      setInclusionPanel("1")
                      setCriteriaLib(6)
                    }}/>
                    </Tooltip>
                    <Badge style={{marginTop: '20px'}} count={insightInclu?insightInclu.length:0} showZero={insightInclu && insightInclu.length == 0 ? true : false }>
                    <Tooltip placement="right" title={'Insights'}>
                    <CarryOutFilled style={{color:`${inclusionPanel==="2"?"#404042":"#999999"}`, fontSize: "16px", marginLeft: "15px", marginTop: "20px", paddingTop: "12px", cursor: "pointer"}} onClick={()=> {
                      setInclusionPanel("2")
                      setCriteriaLib(6)
                     }}/>
                     </Tooltip>
                     </Badge>
                     {!inclusionLibrarayPanelStatus &&  <div className="action-wrapper"></div> }
                     {!inclusionLibrarayPanelStatus && <span className="action-title" onClick={()=> IeCriteriaInsightsToggleHandler(true)}>
                        <RightOutlined style={{color:"#CA4A04"}}/>
                    </span>}
                  </div>
                </Col>}
                {inclusionPanel==="1"&&(<Col span={criteriaLib} style={{
                  backgroundColor: '#F8F8F8',
                  // maxWidth: '340px', 
                  // minWidth: '340px'
                }}>
                  <Row style={{backgroundColor: '#F8F8F8'}}>
                    <Col span={24}>
                      <div className="item-header">
                        <span>Inclusion Criteria Library</span>
                        {/* <Tooltip title={'Collapse Inclusion Criteria Library'}> */}
                          {/* <CloseOutlined className="right-icon" onClick={() => setCriteriaLib(0)}></CloseOutlined> */}
                          {inclusionLibrarayPanelStatus && <div className="action-wrapper"></div>}
                          {(inclusionLibrarayPanelStatus && criteriaLib === 6) &&(<span className="action-title" onClick={() => IeCriteriaInsightsToggleHandler(false)}>
                              <LeftOutlined style={{color:"#CA4A04"}}/>
                           </span>)}
                          {/* </Tooltip> */}
                        <Tooltip title={'View Historical Trial List'}>
                          <HistoryOutlined className="right-icon" onClick={searchHistoricalTrials}></HistoryOutlined>
                        </Tooltip>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{borderBottom:'10px solid #F8F8F8'}}>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                    <Col className="left-section">
                      <Row className="head-row" style={{alignItems: 'center', marginBottom: '10px'}}>
                        <Col span={16}>
                          <div className="item-option">
                            <span>Select criteria to add to Trial</span>
                          </div>
                        </Col>
                        <Col span={8} style={{textAlign:'right'}}>
                          <Row>
                          <Col span={24}><span className="frequency">CRITERIA FREQUENCY</span></Col>
                          </Row>
                          <Row style={{width:'100%'}}>
                          <Col span={24}>
                            <div id="freqModal" ref={null} onClick={() => setVisible(true)}>
                              <span className="label">
                                {minValue}%-{maxValue}%
                              </span>
                              <EditFilled className={`${visible ? 'active' : ''}`}/>
                            </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {visible ? (
                      <div className="freqSection">
                        <div className="title">
                          {/* <span>Set Frequency</span> */}
                          <CloseOutlined
                            className="right-icon"
                            onClick={() => setVisible(false)}
                          ></CloseOutlined>
                        </div>
                        <br/>
                        <div className="content">
                          <span>Criteria Frequency</span>
                          <span style={{ float: "right", fontWeight: 'bold' }}>
                            {minValue}% - {maxValue}%
                          </span>
                        </div>
                        <Slider
                          range={{ draggableTrack: true }}
                          defaultValue={[minValue, maxValue]}
                          tipFormatter={formatter}
                          onAfterChange={getFrequency}
                          min={1}
                        />
                      </div>
                      ) : (
                      <></>
                      )}


                     {/* search bar */}
                     <div className="searchSection">
                        <div className="content">
                          <Dropdown
                            overlay={menu}
                            overlayClassName="searchbox"
                            visible={visibleValue}
                            onVisibleChange={(visible: boolean) => {!visibleValue?setVisibleValue(true):setVisibleValue(false)}}
                          >
                            <Input
                                prefix={<SearchOutlined />}
                                style={{ width: '100%', height: 37 }}
                                allowClear
                                onChange={onTextChange}
                                onClick={e => e.preventDefault()}
                            />
                          </Dropdown>
                        </div>
                      </div>
                      <Row>
                        <Col span={24}>
                          <div className="content-outer content-sidebar">
                            <div className="content-over">
                              <Collapse className="eventLib library box" collapsible="header" onChange={criteriaCallback} activeKey={activeCollapse}>
                                <Panel showArrow={false} header={eventLibHeader("Demographics", demographics.length, "1")} key="1">
                                  {demographics.length>0 ? (
                                      <div className="library box select-option-wrapper">
                                      {demographics.sort(function(m,n){ var a = m["Frequency"]; var b = n["Frequency"]; return b-a;}).map((demographic, idx) => {
                                        return (
                                          <Row>
                                          <CriteriaOption
                                            selectedEle = {demographicsElements}
                                            minValue={minValue}
                                            maxValue={maxValue}
                                            key={`demographic_${idx}`}
                                            demographic={demographic}
                                            index={0}
                                            idx={idx}
                                            handleOptionSelect={handleOptionSelect}
                                            />
                                          </Row>
                                        );
                                      })}
                                    </div>
                                  ): (
                                    <></>
                                  )}
                                </Panel>
                              </Collapse>

                              <Collapse className="eventLib library box" collapsible="header" onChange={criteriaCallback} activeKey={activeCollapse}>
                                <Panel showArrow={false} header={eventLibHeader("Medical Condition", medCondition.length, "2")} key="2">
                                  {medCondition.length>0 ? (
                                      <div className="library box select-option-wrapper">
                                      {medCondition.sort(function(m,n){ var a = m["Frequency"]; var b = n["Frequency"]; return b-a;}).map((medCon, idx) => {
                                        return (
                                          <Row>
                                          <CriteriaOption
                                            selectedEle = {medConditionElements}
                                            minValue={minValue}
                                            maxValue={maxValue}
                                            key={`medCon_${idx}`}
                                            demographic={medCon}
                                            index={1}
                                            idx={idx}
                                            handleOptionSelect={handleOptionSelect}
                                            />
                                          </Row>
                                        );
                                      })}
                                    </div>
                                  ): (
                                    <></>
                                  )}
                                </Panel>
                              </Collapse>

                              <Collapse className="eventLib library box" collapsible="header" onChange={criteriaCallback} activeKey={activeCollapse}>
                              <Panel showArrow={false} header={eventLibHeader("Intervention", intervention.length, "3")} key="3">
                                {intervention.length>0 ? (
                                      <div className="library box select-option-wrapper">
                                      {intervention.sort(function(m,n){ var a = m["Frequency"]; var b = n["Frequency"]; return b-a;}).map((intervent, idx) => {
                                        return (
                                          <Row>
                                          <CriteriaOption
                                            selectedEle = {interventionElements}
                                            minValue={minValue}
                                            maxValue={maxValue}
                                            key={`intervent_${idx}`}
                                            demographic={intervent}
                                            index={2}
                                            idx={idx}
                                            handleOptionSelect={handleOptionSelect}
                                            />
                                          </Row>
                                        );
                                      })}
                                    </div>
                                ): (
                                  <></>
                                )}
                              </Panel>
                            </Collapse>

                              <Collapse className="eventLib library box lastOne" collapsible="header" onChange={criteriaCallback} activeKey={activeCollapse}>
                                <Panel showArrow={false} header={eventLibHeader("Lab / Test", labTest.length, "4")} key="4">
                                  {labTest.length>0 ? (
                                      <div className="library box lastOne select-option-wrapper">
                                      {labTest.sort(function(m,n){ var a = m["Frequency"]; var b = n["Frequency"]; return b-a;}).map((lib, idx) => {
                                        return (
                                          <Row>
                                          <CriteriaOption
                                            selectedEle = {labTestElements}
                                            minValue={minValue}
                                            maxValue={maxValue}
                                            key={`lib_${idx}`}
                                            demographic={lib}
                                            index={3}
                                            idx={idx}
                                            handleOptionSelect={handleOptionSelect}
                                            />
                                          </Row>
                                        );
                                      })}
                                    </div>
                                  ): (
                                    <></>
                                  )}
                                </Panel>
                              </Collapse>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                  </Row>
                  </Col>)}
                {inclusionPanel==="2"&&(<Col span={criteriaLib} style={{
                  backgroundColor: '#F8F8F8'
                }}>
                  <Row style={{backgroundColor: '#F8F8F8'}}>
                    <Col span={24}>
                      <div className="item-header">
                      <span>Insights ({insightInclu?insightInclu.length:0 })</span>
                        <div className="action-wrapper"></div>
                        {criteriaLib === 6 &&(<span className="action-title" onClick={() => setCriteriaLib(0)}>
                            <LeftOutlined style={{color:"#CA4A04"}}/>
                          </span>)}
                      </div>
                    </Col>
                    <Col span={24} className="content-outer insight-box-wrapper">
                      { insightInclu&&insightInclu.map((o) => {
                         return o.action === "ADD"? (
                          <div className="insight-box">
                            <div className="head">SUGGESTED TO ADD</div>
                            <div className="content"><b>{o.criteria}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider adding it to your scenario.</div>
                            <div className="button-area">
                              <span className="button-left" onClick={e=>onInsightClick(o)}>
                                <PlusOutlined style={{margin:"0 3px 0 0"}}/> Add Criteria
                              </span>
                              <span className="button-right" onClick={e=>onDismiss(o)}>
                                <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                              </span>
                            </div>
                          </div>
                        ):
                        (
                          <div className="insight-box">
                            <div className="head">SUGGESTED TO REMOVE</div>
                            <div className="content"> <b>{o.criteria}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider removing it from your scenario.</div>
                            <div className="button-area">
                              <span className="button-left" onClick={e=>onInsightClick(o)}>
                                <MinusOutlined style={{margin:"0 3px 0 0"}}/> Remove Criteria
                              </span>
                              <span className="button-right" onClick={e=>onDismiss(o)}>
                                <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    </Col>
                  </Row>
                </Col>)}
                <Col 
                xxl={{span: inclusionLibrarayPanelStatus ? 17 : 23}}
                xl={{ span: inclusionLibrarayPanelStatus ? 16 : 22}}
                lg={{ span: inclusionLibrarayPanelStatus ? 16 : 22}}
                md={{ span: inclusionLibrarayPanelStatus ? 15 : 21}}
                sm={{ span: inclusionLibrarayPanelStatus ? 12 : 20}}
                xs={{ span: inclusionLibrarayPanelStatus ? 10 : 19}}
                flex="auto"  className={`${ collapsible ? "none-click" : "" } main-content-right`}>
                  <Row style={{ paddingTop: '10px' }}>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                    <Col flex="auto" className="right-content-scroll">
                      <Row>
                        <Col span={24}>
                        <div className="option-item">
                          <div className="collapse-section-wrapper">
                            <Collapse activeKey={activeKey} onChange={callback} expandIconPosition="right" >
                              <Panel header={panelHeader()} key="1" forceRender={false} >
                                <div className="chart-container">
                                  <p className="chart-title"><b>Protocol Amendment</b></p>
                                  {par_historical_trial_average !== 0 ?
                                  (
                                  <>
                                    <p className="chart-description"><strong>{criteria_par_amendment_count_inclusion}/{criteria_count_inclusion}</strong> criteria associated with historical amendments</p>
                                    <div className="center-box">
                                      <div className="pie-width">
                                        <ReactECharts
                                          option={amendmentRateoption}
                                          style={{ height: 140}}
                                          onEvents={{'click': onInclusionChartClick}}/>
                                      </div>
                                      <div className="copy-width">
                                        <p className="label"><strong>{protocol_amendment_rate_inclusion}</strong> of the criteria in your scenario have been amended frequently in the past, compared to <strong>{Math.round(par_historical_trial_average * 10000) / 100 + "%"}</strong> in similar historical trials</p>
                                        <Popover className="detail" content={detailContent('inclusionPAR')}  placement="bottom" overlayClassName="detail-table-wrapper">See Detail</Popover>
                                      </div>
                                    </div>
                                  </>
                                  ) : (
                                    <>
                                    <p className="chart-description"></p>
                                    <div className="center-box">
                                      <div className="pie-width">
                                        <ReactECharts
                                          option={pieChartNullOption}
                                          style={{ height: 140}}
                                          onEvents={{'click': onInclusionChartClick}}/>
                                      </div>
                                      <div className="copy-width">
                                        <p className="label" style={{color: '#919191',paddingTop:'20px'}}>Upcoming Feature</p>
                                      </div>
                                    </div>
                                    </>
                                  )}
                                </div>
                                <div className="chart-container box">
                                  <p className="chart-title"><b>Screen Failure</b></p>
                                  {sfr_historical_trial_average !== 0 && sfr_historical_trial_average !== null?
                                  (<>
                                  <p className="chart-description"><strong>{criteria_sfr_amendment_count_inclusion}/{criteria_count_inclusion}</strong> criteria associated with high screen failure</p>
                                  {/* <p>{therapeutic_Screen_Avg}</p> */}
                                  <div className="center-box">
                                    <div className="pie-width">
                                      <ReactECharts
                                        option={screenFailureOption}
                                        style={{ height: 140}}
                                        onEvents={{'click': onInclusionChartClick}}/>
                                    </div>
                                    <div className="copy-width">
                                      <p className="label"><strong>{screen_failure_rate_inclusion}</strong> of the criteria in your scenario have been associated with high screen failure, compared to <strong>{Math.round(sfr_historical_trial_average * 10000) / 100 + "%"}</strong> in similar historical trials</p>
                                      <Popover className="detail" content={detailContent('inclusionSFR')}  placement="bottom" overlayClassName="detail-table-wrapper">See Detail</Popover>
                                    </div>
                                  </div>
                                  </>):
                                  (<>
                                    <p className="chart-description"></p>
                                    <div className="center-box">
                                      <div className="pie-width">
                                        <ReactECharts
                                          option={pieChartNullOption}
                                          style={{ height: 140}}
                                          onEvents={{'click': onInclusionChartClick}}/>
                                      </div>
                                      <div className="copy-width">
                                        <p className="label" style={{color: '#919191',paddingTop:'20px'}}>Screen Failure Rate is not available based on your Similar Historical Trial List</p>
                                      </div>
                                    </div>
                                    </>
                                  )
                                  }
                                </div>
                              </Panel>
                            </Collapse>
                          </div>
                        </div>
                        </Col>
                      </Row>
                      <Row className="impact-summary-wrapper">
                        <Col span={24}>
                          <div className="impact-summary">
                            <span>Inclusion Criteria</span>
                            {activeTabKey === '3'? (
                                <></>
                              ) : (
                                <Button type="primary" onClick={saveCriteria} disabled={readOnlyAccess || !isAdmin && scenario?.is_template ? true : false} style={{zIndex: 1}}>
                                  Save
                                </Button>
                              )}
                          </div>
                          </Col>
                      </Row>
                      <Row>
                        <Col span={24} >
                          <div className="collapse-container">
                          <div className="content-outer">
                            <div id="inclusion-criteria"
                              className={`collapse-inner ${rollHeight == true ? "taller" : ""} ${collapsible == true ? "collapsed" : ""}`}>
                              <div className="criteria-list">
                                <div className="list-columns">
                                  <Row>
                                    {/* <Col span={1} style={{border:'1px solid green'}}><div className="col-item"></div></Col> */}
                                    <Col span={6}><div className="col-item">Eligibility Criteria</div></Col>
                                    <Col span={6}><div className="col-item">Criteria Frequency</div></Col>
                                    <Col span={6}><div className="col-item" style={{paddingLeft: "10px"}}>Values</div></Col>
                                    <Col span={6}><div className="col-item" style={{paddingLeft: "10px"}}>Timeframe</div></Col>
                                    <Col span={2}><div className="col-item"></div></Col>
                                    {/* <Col span={2} style={{border:'1px solid green'}}><div className="col-item"></div></Col> */}
                                  </Row>
                                </div>
                              </div>
                              <div className="sectionPanel">
                                  <EditTable updateCriteria={updateInclusionCriteria} tableIndex={2}
                                    data={demographicsTableData}
                                    defaultActiveKey={defaultActiveKey}
                                    collapsible={collapsible} panelHeader={"Demographics"} updateTrial={() => updateTrial(1,1)}
                                  />
                                  <EditTable updateCriteria={updateInclusionCriteria} tableIndex={3}
                                    data={medConditionTableData}
                                    defaultActiveKey={defaultActiveKey}
                                    collapsible={collapsible} panelHeader={"Medical Condition"} updateTrial={() => updateTrial(1,1)}
                                  />
                              <EditTable updateCriteria={updateInclusionCriteria} tableIndex={4}
                                    data={interventionTableData}
                                    defaultActiveKey={defaultActiveKey}
                                    collapsible={collapsible} panelHeader={"Intervention"} updateTrial={() => updateTrial(1,1)}
                                  />
                              <EditTable updateCriteria={updateInclusionCriteria} tableIndex={5}
                                    data={labTestTableData}
                                    defaultActiveKey={defaultActiveKey}
                                    collapsible={collapsible} panelHeader={"Lab / Test"} updateTrial={() => updateTrial(1,1)}/>
                              </div>
                            </div>
                          </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            <TabPane 
            //tab="Exclusion Criteria" 
            key="2" disabled={collapsible}>
              <Row>
                {<Col span={1} style={{maxWidth: '50px', minWidth: '50px'}}>
                  <div className={`side-toolbar ${excluCriteriaLib > 0 || activeTabKey != "2" ? '' : ''}`} >
                  <Tooltip placement="right" title={'Exclusion Criteria Library'}> 
                  <FileTextFilled style={{color:`${exclusionPanel==="1"?"#404042":"#999999"}`, fontSize: "16px", marginLeft: "15px", paddingTop: "12px", cursor: "pointer"}} onClick={()=> {
                    setExclusionPanel("1")
                    setExcluCriteriaLib(6)
                  }}/>
                    </Tooltip>
                  <Badge style={{marginTop: '20px'}} count={insightExclu?insightExclu.length:0} showZero={insightExclu && insightExclu.length == 0 ? true : false }>
                  <Tooltip placement="right" title={'Insights'}>
                  <CarryOutFilled style={{color:`${exclusionPanel==="2"?"#404042":"#999999"}`, fontSize: "16px", marginLeft: "15px", marginTop: "20px", paddingTop: "12px", cursor: "pointer"}} onClick={()=> {
                    setExclusionPanel("2")
                    setExcluCriteriaLib(6)
                  }}/>
                  </Tooltip>
                  </Badge>
                    {!exclusionLibrarayPanelStatus &&  <div className="action-wrapper"></div>}
                    {!exclusionLibrarayPanelStatus  && <span className="action-title" onClick={()=> ExclusionCriteriaInsightsToggleHandler(true)}>
                      <RightOutlined style={{color:"#CA4A04"}}/>
                  </span>  }
                </div>
                </Col>}
                {exclusionPanel==="1"&&(<Col span={excluCriteriaLib} style={{
                  backgroundColor: '#F8F8F8',
                  // maxWidth: '340px', 
                  // minWidth: '340px'
                  }}>
                  <Row style={{backgroundColor: '#F8F8F8'}}>
                    <Col span={24}>
                      <div className="item-header">
                        <span>Exclusion Criteria Library</span>
                        {/* <Tooltip title={'Collapse Exclusion Criteria Library'}> */}
                          {/* <CloseOutlined className="right-icon" onClick={() => setExcluCriteriaLib(0)}></CloseOutlined> */}
                          {exclusionLibrarayPanelStatus && <div className="action-wrapper"></div>}
                          {(exclusionLibrarayPanelStatus && excluCriteriaLib === 6 )&&(<span className="action-title" onClick={() => ExclusionCriteriaInsightsToggleHandler(false)}>
                              <LeftOutlined style={{color:"#CA4A04"}}/>
                           </span>)}
                        {/* </Tooltip> */}
                        <Tooltip title={'View Historical Trial List'}>
                          <HistoryOutlined className="right-icon" onClick={searchHistoricalTrials}></HistoryOutlined>
                        </Tooltip>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{borderBottom:'10px solid #F8F8F8'}}>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                    <Col className="left-section">
                      <Row className="head-row" style={{alignItems: 'center', marginBottom: '10px'}}>
                        <Col span={16}>
                          <div className="item-option">
                            <span className="tip">Select criteria to add to Trial</span>
                          </div>
                        </Col>
                        <Col span={8} style={{textAlign:'right'}}>
                          <Row>
                          <Col span={24}><span className="frequency">CRITERIA FREQUENCY</span></Col>
                          </Row>
                          <Row style={{width:'100%'}}>
                          <Col span={24}>
                            <div id="freqModal" ref={null} onClick={() => setExcluVisible(true)}>
                              <span className="label">
                                {excluMinValue}%-{excluMaxValue}%
                              </span>
                              <EditFilled className={`${excluVisible ? 'active' : ''}`}/>
                            </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      {excluVisible ? (
                      <div className="freqSection">
                        <div className="title">
                          {/* <span>Set Frequency</span> */}
                          <CloseOutlined
                            className="right-icon"
                            onClick={() => setExcluVisible(false)}
                          ></CloseOutlined>
                        </div>
                        <br/>
                        <div className="content">
                          <span>Criteria Frequency</span>
                          <span style={{ float: "right", fontWeight: 'bold' }}>
                            {excluMinValue}% - {excluMaxValue}%
                          </span>
                        </div>
                        <Slider
                          range={{ draggableTrack: true }}
                          defaultValue={[excluMinValue, excluMaxValue]}
                          tipFormatter={formatter}
                          onAfterChange={getExcluFrequency}
                          min={1}
                        />
                      </div>
                      ) : (
                      <></>
                      )}


                     {/* search bar */}
                     <div className="searchSection">
                        <div className="content">
                          <Dropdown
                            overlay={menuExclu}
                            overlayClassName="searchbox"
                            visible={visibleValueExclu}
                            onVisibleChange={(visible: boolean) => {!visibleValueExclu?setVisibleValueExclu(true):setVisibleValueExclu(false)}}
                          >
                            <Input
                                prefix={<SearchOutlined />}
                                style={{ width: '100%', height: 37 }}
                                allowClear
                                onChange={onExcluTextChange}
                                onClick={e => e.preventDefault()}
                            />
                          </Dropdown>
                        </div>
                      </div>
                      <Row>
                        <Col span={24}>
                          <div className="content-outer content-sidebar">
                            <div className="content-over">
                              <Collapse className="eventLib library box" collapsible="header" onChange={criteriaCallback} activeKey={activeCollapse}>
                                <Panel showArrow={false} header={eventLibHeader("Demographics", excluDemographics.length, "5")} key="5">
                                  {excluDemographics.length>0 ? (
                                      <div className="library box select-option-wrapper">
                                      {excluDemographics.sort(function(m,n){ var a = m["Frequency"]; var b = n["Frequency"]; return b-a;}).map((demographic, idx) => {
                                        const activeType = excluDemographicsElements.find(e=> e['Eligibility Criteria']==demographic.Text) ?1:0
                                        return (
                                          <CriteriaOption
                                            selectedEle = {excluDemographicsElements}
                                            minValue={excluMinValue}
                                            maxValue={excluMaxValue}
                                            key={`demographic_${idx}`}
                                            demographic={demographic}
                                            index={0}
                                            idx={idx}
                                            handleOptionSelect={handleExcluOptionSelect}
                                          ></CriteriaOption>
                                        );
                                      })}
                                    </div>
                                     ): (
                                    <></>
                                  )}
                                </Panel>
                              </Collapse>

                              <Collapse className="eventLib library box" collapsible="header" onChange={criteriaCallback} activeKey={activeCollapse}>
                                <Panel showArrow={false} header={eventLibHeader("Medical Condition", excluMedCondition.length, "6")} key="6">
                                  {excluMedCondition.length>0 ? (
                                      <div className="library box select-option-wrapper">
                                      {excluMedCondition.sort(function(m,n){ var a = m["Frequency"]; var b = n["Frequency"]; return b-a;}).map((medCon, idx) => {
                                        return (
                                          <CriteriaOption
                                            selectedEle = {excluMedConditionElements}
                                            minValue={excluMinValue}
                                            maxValue={excluMaxValue}
                                            key={`medCon_${idx}`}
                                            demographic={medCon}
                                            index={1}
                                            idx={idx}
                                            handleOptionSelect={handleExcluOptionSelect}
                                          ></CriteriaOption>
                                        );
                                      })}
                                    </div>
                                     ): (
                                    <></>
                                  )}
                                </Panel>
                              </Collapse>

                              <Collapse className="eventLib library box" collapsible="header" onChange={criteriaCallback} activeKey={activeCollapse}>
                              <Panel showArrow={false} header={eventLibHeader("Intervention", excluIntervention.length, "7")} key="7">
                                {excluIntervention.length>0 ? (
                                  <div className="library box select-option-wrapper">
                                  {excluIntervention.sort(function(m,n){ var a = m["Frequency"]; var b = n["Frequency"]; return b-a;}).map((intervent, idx) => {

                                    return (
                                      <CriteriaOption
                                        selectedEle = {excluInterventionElements}
                                        minValue={excluMinValue}
                                        maxValue={excluMaxValue}
                                        key={`intervent_${idx}`}
                                        demographic={intervent}
                                        index={2}
                                        idx={idx}
                                        handleOptionSelect={handleExcluOptionSelect}
                                      ></CriteriaOption>
                                    );
                                  })}
                                </div>
                                  ): (
                                <></>
                              )}
                              </Panel>
                            </Collapse>

                              <Collapse className="eventLib library box lastOne" collapsible="header" onChange={criteriaCallback} activeKey={activeCollapse}>
                                <Panel showArrow={false} header={eventLibHeader("Lab / Test", excluLabTest.length, "8")} key="8">
                                  {excluLabTest.length>0 ? (
                                        <div className="library box lastOne select-option-wrapper">
                                        {excluLabTest.sort(function(m,n){ var a = m["Frequency"]; var b = n["Frequency"]; return b-a;}).map((lib, idx) => {
                                          return (
                                            <CriteriaOption
                                             selectedEle = {excluLabTestElements}
                                              minValue={excluMinValue}
                                              maxValue={excluMaxValue}
                                              key={`lib_${idx}`}
                                              demographic={lib}
                                              index={3}
                                              idx={idx}
                                              handleOptionSelect={handleExcluOptionSelect}
                                            ></CriteriaOption>
                                          );
                                        })}
                                      </div>
                                     ): (
                                    <></>
                                  )}
                                </Panel>
                              </Collapse>
                            </div>
                          </div>
                        </Col>
                      </Row>


                    </Col>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                  </Row>
                  </Col>)}
                {exclusionPanel==="2"&&(<Col span={excluCriteriaLib} style={{
                  backgroundColor: '#F8F8F8'
                }}>
                  <Row style={{backgroundColor: '#F8F8F8'}}>
                    <Col span={24}>
                      <div className="item-header">
                        <span>Insights ({insightExclu?insightExclu.length:0})</span>
                        <div className="action-wrapper"></div>
                        {excluCriteriaLib === 6 &&(<span className="action-title" onClick={() => setExcluCriteriaLib(0)}>
                            <LeftOutlined style={{color:"#CA4A04"}}/>
                          </span>)}
                      </div>
                    </Col>
                    <Col span={24} className="content-outer insight-box-wrapper">
                      {insightExclu&&insightExclu.map((o) => {
                         return o.action === "ADD"? (
                          <div className="insight-box">
                            <div className="head">SUGGESTED TO ADD</div>
                            <div className="content"><b>{o.criteria}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider adding it to your scenario.</div>
                            <div className="button-area">
                              <span className="button-left" onClick={e=>onInsightExclu(o)}>
                                <PlusOutlined style={{margin:"0 3px 0 0"}}/> Add Criteria
                              </span>
                              <span className="button-right" onClick={e=>onDismissExclu(o)}>
                                <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                              </span>
                            </div>
                          </div>
                        ):
                        (
                          <div className="insight-box">
                            <div className="head">SUGGESTED TO REMOVE</div>
                            <div className="content"> <b>{o.criteria}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider removing it from your scenario.</div>
                            <div className="button-area">
                              <span className="button-left" onClick={e=>onInsightExclu(o)}>
                                <MinusOutlined style={{margin:"0 3px 0 0"}}/> Remove Criteria
                              </span>
                              <span className="button-right" onClick={e=>onDismissExclu(o)}>
                                <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    </Col>
                  </Row>
                </Col>)}
                <Col 
                  xxl={{span: exclusionLibrarayPanelStatus ? 17 : 23}}
                  xl={{ span: exclusionLibrarayPanelStatus ? 16 : 22}}
                  lg={{ span: exclusionLibrarayPanelStatus ? 16 : 22}}
                  md={{ span: exclusionLibrarayPanelStatus ? 15 : 21}}
                  sm={{ span: exclusionLibrarayPanelStatus ? 12 : 20}}
                  xs={{ span: exclusionLibrarayPanelStatus ? 10 : 19}}
                  flex="auto" className={`${ excluCollapsible ? "none-click" : "" } main-content-right`}>
                  <Row style={{ paddingTop: '10px' }}>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                    <Col flex="auto" className="right-content-scroll">
                      {/* <Row>
                        <Col span={24}><span className="tab-title">Add Exclusion Criteria</span></Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                        <span className="tip1-desc">
                          Use the historical trial library on the left to build the
                          I/E criteria for your scenario.
                        </span>
                        </Col>
                      </Row> */}
                      <Row>
                        <Col span={24}>
                        <div className="option-item">
                          <div>
                            <Collapse activeKey={excluActiveKey} onChange={excluCallback} expandIconPosition="right">
                              <Panel header={panelHeader()} key="1" forceRender={false} >
                                <div className="chart-container">
                                  <p className="chart-title"><b>Protocol Amendment</b></p>
                                  {par_historical_trial_average !== 0 ? 
                                  (
                                    <>
                                    <p className="chart-description"><strong>{criteria_par_amendment_count_exclusion}/{criteria_count_exclusion}</strong> criteria associated with historical amendments</p>
                                    <div className="center-box">
                                      <div className="pie-width">
                                      <ReactECharts
                                        option={excluAmendmentRateoption}
                                        style={{ height: 140}}
                                        onEvents={{'click': onExclusionChartClick}}/>
                                      </div>
                                      <div className="copy-width">
                                        <p className="label"><strong>{protocol_amendment_rate_exclusion}</strong> of the criteria in your scenario have been amended frequently in the past, compared to <strong>{Math.round(par_historical_trial_average * 10000) / 100 + "%"}</strong> in similar historical trials</p>
                                        <Popover className="detail" content={detailContent('exclusionPAR')}  placement="bottom" overlayClassName="detail-table-wrapper">See Detail</Popover>
                                      </div>
                                    </div>
                                    </>
                                  ) : (
                                    <>
                                    <p className="chart-description"></p>
                                    <div className="center-box">
                                      <div className="pie-width">
                                      <ReactECharts
                                        option={pieChartNullOption}
                                        style={{ height: 140}}
                                        onEvents={{'click': onExclusionChartClick}}/>
                                      </div>
                                      <div className="copy-width">
                                      <p className="label" style={{color: '#919191',paddingTop:'20px'}}>Upcoming Feature</p>
                                      </div>
                                    </div>
                                    </>
                                  )}
                                </div>
                                <div className="chart-container box">
                                  <p className="chart-title"><b>Screen Failure</b></p>
                                  {sfr_historical_trial_average !== 0 && sfr_historical_trial_average !== null? (
                                    <>
                                    <p className="chart-description"><strong>{criteria_sfr_amendment_count_exclusion}/{criteria_count_exclusion}</strong> criteria associated with high screen failure</p>
                                  {/* <p>{therapeutic_Screen_Avg}</p> */}
                                  <div className="center-box">
                                    <div className="pie-width">
                                    <ReactECharts
                                      option={excluScreenFailureOption}
                                      style={{ height: 140}}
                                      onEvents={{'click': onExclusionChartClick}}/>
                                    </div>
                                    <div className="copy-width">
                                      <p className="label"><strong>{screen_failure_rate_exclusion}</strong> of the criteria in your scenario have been associated with high screen failure, compared to <strong>{Math.round(sfr_historical_trial_average * 10000) / 100 + "%"}</strong> in similar historical trials</p>
                                      <Popover className="detail" content={detailContent('exclusionSFR')}  placement="bottom" overlayClassName="detail-table-wrapper">See Detail</Popover>
                                    </div>
                                  </div>
                                    </>
                                  ):(
                                    <>
                                    <p className="chart-description"></p>
                                  <div className="center-box">
                                    <div className="pie-width">
                                    <ReactECharts
                                      option={pieChartNullOption}
                                      style={{ height: 140}}
                                      onEvents={{'click': onExclusionChartClick}}/>
                                    </div>
                                    <div className="copy-width">
                                    <p className="label" style={{color: '#919191',paddingTop:'20px'}}>Screen Failure Rate is not available based on your Similar Historical Trial List</p>
                                    </div>
                                    </div>
                                    </>
                                  )}
                                </div>
                              </Panel>
                            </Collapse>
                          </div>
                        </div>
                        </Col>
                      </Row>
                      <Row className="impact-summary-wrapper">
                        <Col span={24}>
                          <div className="impact-summary">
                            <span>Exclusion Criteria</span>
                            {activeTabKey === '3'? (
                              <></>
                            ) : (
                              <Button type="primary" onClick={saveCriteria} disabled={readOnlyAccess || !isAdmin && scenario?.is_template ? true : false} style={{zIndex: 1}}>
                                Save
                              </Button>
                            )}
                          </div>
                          </Col>
                      </Row>
                      <Row>
                        <Col span={24} >
                          <div className="collapse-container">
                          <div className="content-outer">
                            <div id="inclusion-criteria"
                              className={`collapse-inner ${excluRollHeight == true ? "taller" : ""} ${excluCollapsible == true ? "collapsed" : ""}`}>
                              <div className="criteria-list">
                                <div className="list-columns">
                                  <Row>
                                    <Col span={6}><div className="col-item">Eligibility Criteria</div></Col>
                                    <Col span={6}><div className="col-item">Criteria Frequency</div></Col>
                                    <Col span={6}><div className="col-item" style={{paddingLeft: "10px"}}>Values</div></Col>
                                    <Col span={6}><div className="col-item" style={{paddingLeft: "10px"}}>Timeframe</div></Col>
                                    <Col span={2}><div className="col-item"></div></Col>
                                  </Row>
                                </div>
                              </div>
                              <div className="sectionPanel">
                              <EditTable updateCriteria={updateExclusionCriteria} tableIndex={2}
                                      data={excluDemographicsTableData} defaultActiveKey={excluDefaultActiveKey}
                                      collapsible={excluCollapsible} panelHeader={"Demographics"}/>
                              <EditTable updateCriteria={updateExclusionCriteria} tableIndex={3}
                                      data={excluMedConditionTableData} defaultActiveKey={excluDefaultActiveKey}
                                      collapsible={excluCollapsible} panelHeader={"Medical Condition"}/>
                              <EditTable updateCriteria={updateExclusionCriteria} tableIndex={4}
                                      data={excluInterventionTableData} defaultActiveKey={excluDefaultActiveKey}
                                      collapsible={excluCollapsible} panelHeader={"Intervention"}/>
                              <EditTable updateCriteria={updateExclusionCriteria} tableIndex={5}
                                      data={excluLabTestTableData} defaultActiveKey={excluDefaultActiveKey}
                                      collapsible={excluCollapsible} panelHeader={"Lab / Test"}/>
                              </div>
                            </div>
                          </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </TabPane>
            {/* <TabPane tab="Enrollment Feasibility" key="3" disabled={collapsible}>
            <Row>
                <Col span={5}>
                </Col>
                <Col span={14}>
                  <Row style={{ paddingTop: '10px' }}>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                    <Col flex="auto" className="enrollment-right-section">
                      <Row>
                        <Col span={24}><span className="tab-title">Enrollment Feasibility</span></Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                        <span className="tip1-desc">
                          View the impact of selected inclusion exclusion criteria on propspective patient enrollment.
                        </span>
                        </Col>
                      </Row>
                      <Row style={{paddingTop: 20}}>
                        <Col span={24}>
                          <span className="chart-title">My Protocol</span>
                        </Col>
                      </Row>
                      <Row className="enroll-tab">
                        <Col span={7} className={`chart-tab ${activeEnrollmentTabKey === '1' ? 'active' : ''}`} onClick={() => switchTabkey('1')}>
                          <Row><Col className="tab-item">
                            <Row className="tab-desc">Patients Eligible&nbsp;
                              {activeEnrollmentTabKey === '1'?(<CaretRightOutlined />):(<></>)}</Row>
                            <Row className="sub-tab-title">80K</Row>
                            <Row className="tab-desc">16% of Dataset</Row>
                          </Col></Row>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={7} className={`chart-tab ${activeEnrollmentTabKey === '2' ? 'active' : ''}`} onClick={() => switchTabkey('2')}>
                          <Row><Col className="tab-item" span={24}>
                            <Row className="tab-desc">Female patients eligible&nbsp;
                                {activeEnrollmentTabKey === '2'?(<CaretRightOutlined />):(<></>)}</Row>
                            <Row className="sub-tab-title">20%</Row>
                          </Col></Row>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={8} className={`chart-tab ${activeEnrollmentTabKey === '3' ? 'active' : ''}`} onClick={() => switchTabkey('3')}>
                          <Row><Col className="tab-item chart" span={24}>
                            <Row className="tab-desc">Race & Ethnicity&nbsp;
                                {activeEnrollmentTabKey === '3'?(<CaretRightOutlined />):(<></>)}</Row>
                            <Row><Col span={24}><ReactECharts option={raceOption} style={{ height: 100}}></ReactECharts></Col></Row>
                          </Col></Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="result-chart">
                          <ReactECharts option={resultOption} style={{ height: 350}}></ReactECharts>
                        </Col>
                      </Row>
                    </Col>
                    <Col flex="none">
                      <div style={{ padding: '0 10px' }}></div>
                    </Col>
                  </Row>
                </Col>
                <Col span={5}></Col>
              </Row>
            </TabPane> */}
          </Tabs>
        </div>
      </Row>
      </Col>
        </Row>
     

      ) }
      {processStep === 1 && (
        <Row> 
          <Col>
            <ScenarioLeftPanel 
            trialTitle={trialTitle} 
            scenario={scenario} 
            activeTabKey={activeTabKey}
            processStep={processStep}
            collapsible={collapsible}
            tabChangeHandler={changeActiveTabKey}
            IEScheduleChangeHandler={onStepChange}
            scheduleEvent={true}
            onClickPrevious={()=>{
              props.history.push({pathname: '/trials', state: {trial_id: props.location.state.trial_id}})
              localStorage.setItem('processStep', JSON.stringify(0));
              localStorage.setItem('activeTabKey', JSON.stringify('1'));
            }}
            />
             </Col>
            <Col 
            //span={21}
              xxl={{span: 21 }}
              xl={{ span: 20 }}
              lg={{ span: 20 }}
              md={{ span: 16 }}
              sm={{ span: 16 }}
              xs={{ span: 16 }}>
            <div className="ie-container">
              <IEScenarioPanel 
              scenario={scenario}
              processStep={processStep}
              activeTabKey={activeTabKey}
              handleExport={handleExport}
              setActiveTabKey={setActiveTabKey}
              setProcessStep={setProcessStep}
              onClickMyTrials={()=>{
                props.history.push({pathname: '/trials'})
                localStorage.setItem('processStep', JSON.stringify(0))
                localStorage.setItem('activeTabKey', JSON.stringify('1'))
                localStorage.setItem('show-details', 'false')
                localStorage.setItem('trial-id', '')
              }}
              onClickPrevious={()=>{
                props.history.push({pathname: '/trials', state: {trial_id: props.location.state.trial_id}})
                localStorage.setItem('processStep', JSON.stringify(0));
                localStorage.setItem('activeTabKey', JSON.stringify('1'));
              }}
              setSubmitType={setSubmitType}
              tabChangeHandler={changeActiveTabKey}
              disableSaveFinish={disableSaveFinish}
              readOnly={readOnlyAccess || !isAdmin && scenario?.is_template}
            />
            {/* <div className="export-container">
          <Row>
            
            <Col span={24} className="finish-btn-group">
            <>
              <Button type="primary" className="step-btn"  onClick={()=> setSubmitType(2)}>
                  SAVE AND FINISH LATER
              </Button>
              <Button className="view-btn step-btn" onClick={() => {
                setProcessStep(0)
                setActiveTabKey('2')
              }
              }>
                  PREV:EXCLUSION CRITERIA
              </Button>
              </>
            </Col>
          </Row>
        </div> */}
              <ScheduleEvents 
              record={trialRecord?trialRecord:props.newTrial.trialRecord} 
              submitType={submitType} 
              scenarioId={scenarioId} 
              handleGoBack={handleGoBack} 
              history={props.history} 
              setVisibleSOA={showSOAModal} 
              setScenario={setScenario} 
              nctList={props.location.state.similarHistoricalTrials} 
              setDisableSaveFinish={setDisableSaveFinish} 
              trial_uuid={props.location.state.trial_id}
              readOnly={readOnlyAccess || !isAdmin && scenario?.is_template}
              />
            </div>
          </Col>
        </Row>
             
      )}
      </Spin>
      <Drawer title="Historical Trial List" placement="right" onClose={handleCancel} visible={showHistorical}>
        <Spin spinning={spinning} indicator={<LoadingOutlined style={{ color: "#ca4a04",fontSize: 24 }}/>} >
        <Row>
            <Col span={24} style={{paddingBottom: '10px'}}>
              {/* {visibleSOA ? (
                <Button type="primary" onClick={downloadSOA} style={{float: 'right'}}>VIEW SOURCE</Button>
              ) : (
                <>
                  <Button type="primary" onClick={downloadIE} style={{float: 'right'}}>VIEW SOURCE</Button>
                  <Button onClick={downloadAverage} style={{float: 'right', marginRight: '15px', color: '#ca4a04'}}><span style={{color: '#ca4a04'}}>VIEW AVERAGE</span></Button>
                </>
              )} */}
            </Col>
        </Row>
        <Row>
            <Col span={24}><SelectableTable dataList={historicalTrialdata} /></Col>
        </Row>
        </Spin>
      </Drawer>
    </div>

    );

}

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
  createSoa: (val) => dispatch(createActions.createSoa(val)),
  
});

const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
  newSoa: state.soaReducer
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScenarioPage));
