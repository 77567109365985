export const getURLSearchParams = (locationSearch: string) => {
  const result: any = {};
  new URLSearchParams(locationSearch).forEach((v, k) => {
    result[k] = v;
  });
  return result;
};

export function _objToStrMap(obj) {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
}

export const _jsonToMap = (jsonStr: string) => {
  return _objToStrMap(JSON.parse(jsonStr));
};

export const getUrlParams = (key: string) => {
  var reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i');
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

export const randomString = function (length) {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const roundToTwoDecimals = (input) => {
  if (!input) {
    return input;
  } else {
    const number = Number(input);
    if (Number.isFinite(number) && !Number.isInteger(number)) {
      return Math.round(number * 100) / 100;
    } else {
      return number;
    }
  }
};


export const roundToWholeNumber = (input) => {
  if (!input) {
    return input;
  } else {
    const number = Number(input);
    if (Number.isFinite(number) && !Number.isInteger(number)) {
      return Math.round(number);
    } else {
      return number;
    }
  }
};