import React, { useEffect, useState } from 'react';
import { getTrialSoaInsights } from "../../utils/ajax-proxy";
import { message } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import './index.scss';
import { roundToTwoDecimals } from '../../utils/utils';


const SoaAtGlance = (props) => {
    const { trialId, scenarioId, saveCostChart } = props
    const [ SOAByAtGlance, setSOAByAtGlance ] = useState({ pb_insights_data: { number_of_activities_soa: 0, number_of_activities_median: 0,
        number_of_visits_soa:0, number_of_visits_median: 0, patient_burden_soa: 0, patient_burden_median: 0, cost_soa: 0, cost_median: 0}})
     useEffect(() => {
        fetchChartData()
      }, [trialId, scenarioId, saveCostChart ])

    const fetchChartData = async () => {  
      if(!scenarioId || scenarioId == "") return;
        const resglance = await getTrialSoaInsights(trialId, scenarioId,"SoAatGlance", false, true) 
        if (resglance  && Object.keys(resglance).length !== 0 && resglance.message !== "Error processing request" && resglance.pb_insights_data !== null) {
          const response = resglance
          setSOAByAtGlance(response)
        }else{
          message.error('Failed to fetch SoA at a Glance chart data')
        }
      }

    return (
        <div className=' soa-at-glance '>
        <div className="chart-header">
        <div className="title">
        SoA at a Glance 
        {/* <div className="icon-holder"><CheckCircleOutlined className="check-icon" style={{ background: '#97E095', color: 'white', borderRadius: '20px' }} /></div> */}
        </div>
      </div>
      <div className="chart-container  ">
      <div className="left-part">
      <p className="item-wrapper-top-para">Number of Activities</p>
        <div className="item-wrapper item-wrapper-top"> 
          <div className="number">
            {roundToTwoDecimals(SOAByAtGlance?.pb_insights_data?.number_of_activities_soa??'0') }
          </div>
          <div className="text"> Activities in This SoA </div>
        </div>
        <div className="item-wrapper item-wrapper-bottom">
          <div className="number">
            {roundToTwoDecimals(SOAByAtGlance?.pb_insights_data?.number_of_activities_median??'0') }
          </div>
          <div className="text"> Median in Similar Historical Trials </div>
        </div>
      </div>
      <div className="middle"> </div>
      <div className="left-part">
        <p className="item-wrapper-top-para">Number of Visits</p>
        <div className="item-wrapper item-wrapper-top">
          <div className="number">
            {roundToTwoDecimals(SOAByAtGlance?.pb_insights_data?.number_of_visits_soa??'0') }
          </div>
          <div className="text"> Visits in This SoA </div>
        </div>
        <div className="item-wrapper item-wrapper-bottom">
          <div className="number">
            {roundToTwoDecimals(SOAByAtGlance?.pb_insights_data?.number_of_visits_median??'0') }
          </div>
          <div className="text"> Median in Similar Historical Trials </div>
        </div>
      </div>
      <div className="middle"> </div>
      <div className="left-part">
        <p className="item-wrapper-top-para">Patient Burden</p>
        <div className="item-wrapper item-wrapper-top">
          <div className="number">
            {Math.round(SOAByAtGlance?.pb_insights_data?.patient_burden_soa??'0') }
          </div>
          <div className="text"> Total Patient Burden in This SoA </div>
        </div>
        <div className="item-wrapper item-wrapper-bottom">
          <div className="number">
            {Math.round(SOAByAtGlance?.pb_insights_data?.patient_burden_median??'0') }
          </div>
          <div className="text"> Median in Similar Historical Trials </div>
        </div>
      </div>
      <div className="middle"> </div>
      <div className="left-part">
        <p className="item-wrapper-top-para">Cost per Patient</p>
        <div className="item-wrapper item-wrapper-top">
          <div className="number">
            {  ` $${roundToTwoDecimals(SOAByAtGlance?.pb_insights_data?.cost_soa??'0').toLocaleString().toLocaleString()}` }
          </div>
          <div className="text"> Total Cost per Patient in This SoA </div>
        </div>
        <div className="item-wrapper item-wrapper-bottom">
          <div className="number">
            {  `$${roundToTwoDecimals(SOAByAtGlance?.pb_insights_data?.cost_median ??'0').toLocaleString()}` }
          </div>
          <div className="text"> Median in Similar Historical Trials </div>
        </div>
      </div> 
    </div> 
    </div>
    );
  };
  
  export default SoaAtGlance;