/* eslint-disable */
import React, { useState, useReducer, useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import * as createActions from "../../actions/createTrial.js";
import {Table, Row, Col, Tooltip, Button, Badge, Popover, Collapse} from "antd";
import type { ColumnsType } from 'antd/es/table';
import {PlusCircleOutlined, MinusOutlined, PlusOutlined, LeftOutlined, CarryOutFilled, DeleteOutlined} from "@ant-design/icons";
import ReactECharts from 'echarts-for-react';
import "./index.scss";
import EditTable from "../../components/NewEditTable";
const { Panel } = Collapse;
const IELibrary = (props) => { 

  const {
    scenario, 
    IEType, 
    saveCriteria, 
    readOnlyAccess, 
    isAdmin, 
    protocolRateData, 
    inActiveChartColors, 
    defaultChartValue, 
    impactColors, 
    amend_avg_rate, 
    showChartLabel,
    par_historical_trial_average,
    criteria_par_amendment_count, 
    criteria_count, 
    protocol_amendment_rate, 
    sfr_historical_trial_average, 
    criteria_sfr_amendment_count, 
    screen_failure_rate, 
    screen_avg_rate, 
    screenRateData, 
    updateCriteria,
    defaultActiveKey, 
    collapsible, 
    updateTrial, 
    demographicsTableData, 
    medConditionTableData, 
    interventionTableData, 
    labTestTableData, 
    insightEvent, 
    onDismiss, 
    onInsightClick, 
    demographicsElements,
    interventionElements,
    medConditionElements,
    labTestElements,
    originDemographics,
    originIntervention, 
    originMedCondition, 
    originLabTest,
    excluActive
  } = props
  
  const [eventLib, setEventLib] = useState(6)
  const [eventPanel, setEventPanel] = useState("1")
  
  const [iELibrarayPanelStatus, setIELibraryPanelStatus] = useState(false)

  const ieInsightsToggleHandler = (status) => {
    setIELibraryPanelStatus(status);
    setEventLib(!status ? 0 : 6)
  }

  const labelColors = {GOOD: '#00A947', FAIR: '#0084A9', POOR: '#c33232'}

  const amendmentRateoption = {
    tooltip: {
      show: showChartLabel,
      trigger: 'item',
      formatter: '{b} - {d}%',
      extraCssText:'background:white;color:#757373;font-size:12px'
    },
    series: [
      {
        type: 'pie',
        center: ['45%', '55%'],
        radius: ['60%', '90%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        // label: {
        //   show: true,
        //   position: 'center',
        //   formatter: function () {
        //     if(showChartLabel){
        //       return amend_avg_rate
        //     } else {
        //       return ''
        //     }
        //   },
        //   emphasis: '',
        //   rich: {
        //     p: {
        //       color: '#848484',
        //       fontSize: 30,
        //       backgroundColor: "white"
        //     },
        //     good: {
        //       color: '#00A947',
        //       fontSize: 12,
        //       fontWeight:'bold',
        //       backgroundColor: "white"
        //     },
        //     fair: {
        //       color: '#0084A9',
        //       fontSize: 12,
        //       fontWeight:'bold',
        //       backgroundColor: "white"
        //     },
        //     poor: {
        //       color: '#c33232',
        //       fontSize: 12,
        //       fontWeight:'bold',
        //       backgroundColor: "white"
        //     }
        //   }
        // },
        emphasis: {
          disabled: true,
          label: {
            show: true,
            fontSize: '12',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        color: impactColors,
        data: protocolRateData
      }
    ],
    graphic: {
      elements: [{
        type: 'text',
        left: amend_avg_rate.split('\n')[0].length === 0||1?'42.5%':'36.5%',
        top: '47%', 
        cursor:'default',
        // invisible: !showChartLabel,
        invisible: false,
        style: {
          text: amend_avg_rate.split('\n')?
            (amend_avg_rate.split('\n')[0] === ''?'-': amend_avg_rate.split('\n')[0]):'-',
          fontSize: 30,
          textAlign: 'center', 
          width: 30,
          height: 30,
          fill: '#848484'
        }
      },
      // {
      //   type: 'text',
      //   left: '39%',
      //   top: '60%',
      //   cursor:'default',
      //   invisible: !showChartLabel,
      //   style: {
      //     text: amend_avg_rate.split('\n')?amend_avg_rate.split('\n')[1]:"",
      //     fontSize: 12,
      //     textAlign: 'center',
      //     fontWeight:'bold',
      //     width: 30,
      //     height: 25,
      //     fill: amend_avg_rate.split('\n')&&amend_avg_rate.split('\n')[1]=="GOOD"?
      //     labelColors.GOOD:
      //     amend_avg_rate.split('\n')&&amend_avg_rate.split('\n')[1]=="FAIR"?
      //     labelColors.FAIR:
      //     labelColors.POOR,
      //   }
      // }
    ]
    },
  };

  const screenFailureOption = {
    tooltip: {
      show: showChartLabel,
      trigger: 'item',
      formatter: '{b} - {d}%',
      extraCssText:'background:white;color:#757373;font-size:12px'
    },
    series: [
      {
        type: 'pie',
        center: ['45%', '55%'],
        radius: ['60%', '90%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        emphasis: {
          disabled: true,
          label: {
            show: true,
            fontSize: '12',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        color: impactColors,
        data: screenRateData
      }
    ],
    graphic: {
      elements: [{
        type: 'text',
        left: screen_avg_rate.split('\n')[0].length === 0||1?'42.5%':'36.5%',
        top: '43%', 
        cursor:'default',
        // invisible: !showChartLabel,
        invisible: false,
        style: {
          text: screen_avg_rate.split('\n')?
            (screen_avg_rate.split('\n')[0] === ''?'-': screen_avg_rate.split('\n')[0]):'-',
          fontSize: 30,
          textAlign: 'center', 
          width: 30,
          height: 30,
          fill: '#848484'
        }
      },
      {
        type: 'text',
        left: '39%',
        top: '60%',
        cursor:'default',
        invisible: !showChartLabel,
        style: {
          text: screen_avg_rate.split('\n')?screen_avg_rate.split('\n')[1]:"",
          fontSize: 12,
          textAlign: 'center',
          fontWeight:'bold',
          width: 30,
          height: 25,
          fill: screen_avg_rate.split('\n')&&screen_avg_rate.split('\n')[1]=="GOOD"?
          labelColors.GOOD:
          screen_avg_rate.split('\n')&&screen_avg_rate.split('\n')[1]=="FAIR"?
          labelColors.FAIR:
          labelColors.POOR,
        }
      }]
    },
  };

  const pieChartNullOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{b} - {d}%',
      extraCssText:'background:white;color:#757373;font-size:12px'
    },
    series: [
      {
        type: 'pie',
        center: ['45%', '55%'],
        radius: ['60%', '90%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
        },
        emphasis: {
          disabled: true,
          label: {
            show: true,
            fontSize: '12',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        color: inActiveChartColors,
        data: defaultChartValue,
      }
    ],
    graphic: {
      elements: [{
        type: 'text',
        left: '42.5%',
        top: '45%', 
        cursor:'default',
        invisible: false,
        style: {
          text: '-',
          fontSize: 30,
          textAlign: 'center', 
          width: 30,
          height: 30,
          fill: '#848484'
        }
      }]
    },
  };

  interface DataType {
    key: number;
    category: string;
    criteria: string;
    rate: number;
  }

  const columnsPAR: ColumnsType<DataType> = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      key: 'criteria',
    },
    {
      title: 'Amendment Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: val => (Math.round(val * 10000) / 100 + "%")
    }
  ];

  const columnsSFR: ColumnsType<DataType> = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Entity',
      dataIndex: 'criteria',
      key: 'criteria',
    },
    {
      title: 'Screen Failure',
      dataIndex: 'rate',
      key: 'rate',
      render: val => (Math.round(val * 10000) / 100 + "%")
    }
  ];

  const detailContent = (detailData) => {
    switch (detailData) {
      case 'PAR':
        return 
      case 'SFR':
        return (
          <Table columns={columnsSFR} dataSource={scenario&&scenario['highest_screen_failure_rate']&&scenario['highest_screen_failure_rate'][IEType.slice(0,9)]?scenario['highest_screen_failure_rate'][IEType.slice(0,9)]:[]} pagination={false} size={'small'}/>
          )
        break;
      default:
        break;
    }
  }

  const showIEPage = () => {
    if(IEType==='Inclusion Criteria') {
      props.updateIEActivities({
        demographicsElements:demographicsElements,
        interventionElements:interventionElements,
        medConditionElements:medConditionElements,
        labTestElements:labTestElements,
      })
    } else if(IEType==='Exclusion Criteria') {
      props.updateIEActivities({
        excluDemographicsElements:demographicsElements ,
        excluMedConditionElements: medConditionElements,
        excluInterventionElements: interventionElements,
        excluLabTestElements: labTestElements,
      })
    }
    
    props.history.push({
      pathname: '/add-activity-ie',
      state: {
        IEType,
        originDemographics,
        originIntervention,
        originMedCondition,
        originLabTest,

        demographicsSelected:demographicsElements,
        interventionSelected:interventionElements,
        medConditionSelected:medConditionElements,
        labTestSelected:labTestElements,
        
        similarHistoricalTrials: props.similarHistoricalTrials,
        trial_id: props.trial_id,
        scenarioId: props.scenarioId, 
        scenarioType: props.scenarioType,
        }
      })
  }

  const onClickPARDetail = () => {
    props.history.push({
      pathname: '/show-pa',
      state: {
        paData: scenario&&scenario['amendment_details']&&scenario['amendment_details']?scenario['amendment_details']:{},
        similarHistoricalTrials: props.similarHistoricalTrials,
        trial_id: props.trial_id,
        scenarioId: props.scenarioId, 
        scenarioType: props.scenarioType,
        }
      })
  }

  const filterAddedInsights = (insights) => insights.filter((insight) => {
    
    if (insight.category === "Demographics" && insight.action === "ADD"
    ) {
      return !!!demographicsTableData.find(item => item['Eligibility Criteria'] === insight.criteria && item. negation === insight.negation
      );
    }

    if (insight.category === "Medical Condition" && insight.action === "ADD") {
      return !!!medConditionTableData.find(item => item['Eligibility Criteria'] === insight.criteria && item. negation === insight.negation
      );
    }

    if (insight.category === "Intervention" && insight.action === "ADD") {
      return !!!interventionTableData.find(item => item['Eligibility Criteria'] === insight.criteria && item. negation === insight.negation
      );
    }

    if (insight.category === 'Lab / Test' && insight.action === "ADD") {
      return !!!labTestTableData.find(item => item['Eligibility Criteria'] === insight.criteria && item. negation === insight.negation
      );
    }

    if (insight.category === "Demographics" && insight.action === "REMOVE"
    ) {
      return !!demographicsTableData.find(item => item['Eligibility Criteria'] === insight.criteria && item. negation === insight.negation
      );
    }

    if (insight.category === "Medical Condition" && insight.action === "REMOVE") {
      return !!medConditionTableData.find(item => item['Eligibility Criteria'] === insight.criteria && item. negation === insight.negation
      );
    }

    if (insight.category === "Intervention" && insight.action === "REMOVE") {
      return !!interventionTableData.find(item => item['Eligibility Criteria'] === insight.criteria && item. negation === insight.negation
      );
    }

    if (insight.category === 'Lab / Test' && insight.action === "REMOVE") {
      return !!labTestTableData.find(item => item['Eligibility Criteria'] === insight.criteria && item. negation === insight.negation
      );
    }
  });

  const [excluActiveKey, setExcluActiveKey] = useState([])
  function excluCallback(key) {
    setExcluActiveKey(key)  
  }

  useEffect(() => { 
    setExcluActiveKey(excluActive)
  }, [excluActive])

  return (  
  <>
    <div className="ie-library-container">
      <Row className="scenarios-actions-container">
        <Col span={iELibrarayPanelStatus ? 18 : 23} className="left-content-container" style={{paddingRight: '2%'}}>
          <div className="scenarios-heading-left"> 
            {/* <span> Scenario Impact Summary </span> */}
          </div>
          <Collapse activeKey={excluActiveKey} onChange={excluCallback} expandIconPosition="right" >
              <Panel header={<span style={{fontSize:'26px'}}>Scenario Impact Summary</span>} key="1" forceRender={false} >
              <Row className="summary-container">
              <Col span={11} className="chart-wrapper">
                <div className="chart-header">
                  <div className="title">
                  Protocol Amendment
                  </div>
                </div>
                <div className="chart-container">
                {par_historical_trial_average !== 0 && par_historical_trial_average !== null?
                  (
                  <>
                    <p className="description"><strong>{criteria_par_amendment_count}/{criteria_count} </strong> entities associated with historical amendments</p>
                    <div className="center-box">
                      <div className="pie-width">
                        <ReactECharts
                          option={amendmentRateoption}
                          style={{ height: 190}}
                          // onEvents={{'click': onInclusionChartClick}}
                          />
                      </div>
                      <div className="copy-width">
                        {/* <p className="label"><strong>{protocol_amendment_rate||"0"}</strong> of the criteria in your scenario have been amended frequently in the past, compared to <strong>{Math.round(par_historical_trial_average * 10000) / 100 + "%"}</strong> in similar historical trials</p> */}
                        <p className="label"><strong>{protocol_amendment_rate||"0"} </strong>of the entities you selected are associated with a protocol amendment in similar historical trials</p>
                        <span 
                        className="detail" 
                        onClick={onClickPARDetail} 
                        >
                          See Details
                        </span>
                      </div>
                    </div>
                  </>
                  ) : (
                    <>
                    <p className="description"></p>
                    <div className="center-box">
                      <div className="pie-width">
                        <ReactECharts
                          option={pieChartNullOption}
                          style={{ height: 190}}
                          // onEvents={{'click': onInclusionChartClick}}
                          />
                      </div>
                      <div className="copy-width">
                        <p className="label" style={{color: '#919191',paddingTop:'20px'}}>Protocol Amendment Rate is not available based on your Similar Historical Trial List</p>
                      </div>
                    </div>
                    </>
                  )}
                  <div className="legend">
                    <div className="item-desc">
                      <div className="item">
                        <div className="bar-item item1"></div>
                        <span>Labs / Tests</span>
                      </div>
                      <div className="item">
                        <span className="bar-item item2"></span>
                        <span>Intervention</span>
                      </div>
                    </div>
                    <div className="item-desc">
                      <div className="item">
                        <span className="bar-item item3"></span>
                        <span>Demographics</span>
                      </div>
                      <div className="item">
                        <span className="bar-item item4"></span>
                        <span>Medical Condition</span>
                      </div>
                    </div>
                  </div>
                </div>                  
              </Col> 
              <Col span={11} className="chart-wrapper">
                <div className="chart-header">
                  <div className="title">
                  Screen Failure
                  </div>
                </div>
                <div className="chart-container">
                {sfr_historical_trial_average !== 0 && sfr_historical_trial_average !== null?
                  (<>
                  <p className="description"><strong>{criteria_sfr_amendment_count}/{criteria_count}</strong> entities associated with high screen failure</p>
                  {/* <p>{therapeutic_Screen_Avg}</p> */}
                  <div className="center-box">
                    <div className="pie-width">
                      <ReactECharts
                        option={screenFailureOption}
                        style={{ height: 190}}
                        // onEvents={{'click': onInclusionChartClick}}
                        />
                    </div>
                    <div className="copy-width">
                      <p className="label"><strong>{screen_failure_rate||"0"}</strong> of the entities in your scenario have been associated with high screen failure, compared to <strong>{Math.round(sfr_historical_trial_average * 10000) / 100 + "%"}</strong> in similar historical trials</p>
                      <Popover className="detail" content={detailContent('SFR')}  placement="bottom" overlayClassName="detail-table-wrapper">See Details</Popover>
                    </div>
                  </div>
                  </>):
                  (<>
                    <p className="description"></p>
                    <div className="center-box">
                      <div className="pie-width">
                        <ReactECharts
                          option={pieChartNullOption}
                          style={{ height: 190}}
                          // onEvents={{'click': onInclusionChartClick}}
                          />
                      </div>
                      <div className="copy-width">
                        <p className="label" style={{color: '#919191',paddingTop:'20px'}}>Screen Failure Rate is not available based on your Similar Historical Trial List</p>
                      </div>
                    </div>
                    </>
                  )
                  }
                  <div className="legend">
                    <div className="item-desc">
                      <div className="item">
                        <div className="bar-item item1"></div>
                        <span>Labs / Tests</span>
                      </div>
                      <div className="item">
                        <span className="bar-item item2"></span>
                        <span>Intervention</span>
                      </div>
                    </div>
                    <div className="item-desc">
                      <div className="item">
                        <span className="bar-item item3"></span>
                        <span>Demographics</span>
                      </div>
                      <div className="item">
                        <span className="bar-item item4"></span>
                        <span>Medical Condition</span>
                      </div>
                    </div>
                  </div>
                </div>                  
              </Col> 
            </Row> 
          </Panel>
        </Collapse>
          <Row className="table-container">
            <Col span={24} className="container-top">
              <div className="scenarios-heading-left">
                <span>{IEType}</span>
              </div>
              <div className="scenarios-heading-right">
                <div className='btn-scenarios-container-header'>
                  <Button 
                    className="ie-btn" 
                    onClick={showIEPage} 
                    style={{zIndex: 1}}> 
                    <PlusCircleOutlined className="ie-activity-icon" /> ADD CRITERIA 
                  </Button>
                  <Button 
                    type="primary" 
                    onClick={saveCriteria} 
                    disabled={readOnlyAccess || !isAdmin && scenario?.is_template ? true : false} 
                    className="save_ie_btn"
                    style={{zIndex: 1}}>
                      SAVE
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={24} className="event-dashboard" style={{ height: "700px", overflowX: "scroll", overflowY: "scroll" }}>
              <div className="collapse-inner">
                <div className="criteria-list">
                  <div className="list-columns">
                    <Row>
                        <Col span={4}><div className="col-item">Entity</div></Col>
                        <Col span={4}><div className="col-item">Historial Entity Frequency</div></Col>
                        <Col span={3}><div className="col-item">Values</div></Col>
                        <Col span={3}><div className="col-item">Timeframe</div></Col>
                        <Col span={3}><div className="col-item">RoA</div></Col>
                        <Col span={3}><div className="col-item">Acuity</div></Col>
                        <Col span={3}><div className="col-item">Treatment Modifier</div></Col>
                        <Col span={2}><div className="col-item">Actions</div></Col>
                    </Row>
                  </div>
                </div>
                <div className="sectionPanel">
                    <EditTable updateCriteria={updateCriteria} tableIndex={2}
                      data={demographicsTableData}
                      defaultActiveKey={defaultActiveKey}
                      collapsible={collapsible} panelHeader={"Demographics"} 
                    />
                    <EditTable updateCriteria={updateCriteria} tableIndex={3}
                      data={medConditionTableData}
                      defaultActiveKey={defaultActiveKey}
                      collapsible={collapsible} panelHeader={"Medical Condition"} 
                        />
                    <EditTable updateCriteria={updateCriteria} tableIndex={4}
                          data={interventionTableData}
                          defaultActiveKey={defaultActiveKey}
                          collapsible={collapsible} panelHeader={"Intervention"} 
                        />
                    <EditTable updateCriteria={updateCriteria} tableIndex={5}
                          data={labTestTableData}
                          defaultActiveKey={defaultActiveKey}
                          collapsible={collapsible} panelHeader={"Lab / Test"} />
                </div>
              </div>
            </Col>
          </Row>
          <br />
        </Col>
        <Col span={iELibrarayPanelStatus ? 6: 1} className="right-insight-container" style={{background: '#FFFFFF'}}>
          <Row>
            {eventPanel=="2"&&(
            <Col span={20} className="left-insight" style={{
              backgroundColor: '#F8F8F8'
            }}>
              <Row style={{backgroundColor: '#F8F8F8'}}>
                <Col span={24}>
                  <div className="item-header">
                    <span>Insights ({insightEvent?filterAddedInsights(insightEvent).length:0 })</span>
                    <div className="action-wrapper"></div>
                    {iELibrarayPanelStatus && <div className="action-wrapper"></div>}
                    {(iELibrarayPanelStatus && eventLib === 6 )&&(<span className="action-title" onClick={() =>{setEventPanel("1"); ieInsightsToggleHandler(false)}}>
                        <LeftOutlined style={{color:"#CA4A04"}}/>
                      </span>)}
                  </div>
                </Col>
                <Col span={24} className="content-outer insight-box-wrapper">
                  {/* { insightEvent&&insightEvent.map((o) => { */}
                    { insightEvent && filterAddedInsights(insightEvent).map((o) => {
                    return o.action === "ADD"? (
                      <div className="insight-box">
                        <div className="head">SUGGESTED TO ADD</div>
                        <div className="content">
                        <span style={  { background: o.negation ? '#ca4a04' : '', display: o.negation ?'inline-block':'none', padding: '6px', borderRadius: '50%', marginRight: '2px'}}></span> <b>{o.criteria}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider adding it to your scenario.
                        </div>
                        <div className="button-area">
                          <span className="button-left" onClick={e=>onInsightClick(o)}>
                            <PlusOutlined style={{margin:"0 3px 0 0"}}/> Add Criteria
                          </span>
                          <span className="button-right" onClick={e=>onDismiss(o)}>
                            <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                          </span>
                        </div>
                      </div>
                    ):
                    (
                      <div className="insight-box">
                        <div className="head">SUGGESTED TO REMOVE</div>
                        <div className="content"> 
                        <span style={  { background: o.negation ? '#ca4a04' : '', display: o.negation ?'inline-block':'none', padding: '6px', borderRadius: '50%', marginRight: '2px'}}></span><b>{o.criteria}</b> occurs in <b>{Math.round(o.frequency * 10000) / 100 + "%"}</b> of similar trials. Consider removing it from your scenario.
                        </div>
                        <div className="button-area">
                          <span className="button-left" onClick={e=>onInsightClick(o)}>
                            <MinusOutlined style={{margin:"0 3px 0 0"}}/> Remove Criteria
                          </span>
                          <span className="button-right" onClick={e=>onDismiss(o)}>
                            <DeleteOutlined style={{margin:"0 3px 0 0"}} /> Dismiss
                          </span>
                        </div>
                      </div>
                    )
                  })}
                </Col>
              </Row>
            </Col>
            )}
            <Col span={1} className="right-icon" style={{maxWidth: '50px', minWidth: '50px', background: '#FFFFFF'}}>
              <div className={`side-toolbar ${eventLib > 0 ? '' : ''}`}> 
                <Badge style={{marginTop: '20px'}} count={insightEvent?filterAddedInsights(insightEvent).length:0} showZero={insightEvent && filterAddedInsights(insightEvent).length == 0 ? true : false }>
                <Tooltip placement="right" title={'Insights'}>
                  <CarryOutFilled style={{color:`${eventPanel==="2"?"#404042":"#999999"}`, fontSize: "18px", marginLeft: "15px", marginTop: "20px", paddingTop: "12px", cursor: "pointer"}} onClick={()=> {
                  setEventPanel("2")
                  ieInsightsToggleHandler(true)
                    }}/>
                </Tooltip>
                </Badge>
                {!iELibrarayPanelStatus &&  <div className="action-wrapper"></div>} 
              </div>
            </Col>
          </Row> 
        </Col>
      </Row>
    </div>
  </>)
}

const mapDispatchToProps = (dispatch) => ({
  createTrial: (val) => dispatch(createActions.createTrial(val)),
  createSoa: (val) => dispatch(createActions.createSoa(val)),
  updateSoaActivities: (val) => dispatch(createActions.updateSoaActivities(val)),
  updateIEActivities: (val) => dispatch(createActions.updateIEActivities(val))
});

const mapStateToProps = (state) => ({
  newTrial: state.trialReducer,
  newSoa: state.soaReducer,
  newIE: state.ieReducer
});
export default connect(mapStateToProps, mapDispatchToProps)(IELibrary);

 
