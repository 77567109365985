/* eslint-disable */
import React, { useState, useEffect, useReducer, useRef } from "react";
import { Button, Collapse, Select, Input, Radio,Space,message, Tooltip, Popover, Typography } from "antd";
// import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { FileAddFilled } from "@ant-design/icons";
import { withRouter } from 'react-router';
import {
  CaretUpOutlined,
  CaretDownOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  DeleteFilled
} from "@ant-design/icons";
import "./index.scss";

const { Panel } = Collapse;
const { TextArea } = Input;
const { Text } = Typography;

const LABS = 'Labs';
const PHYSICAL_EXAMINATION = 'Physical Examination';
const PROCEDURES = 'Procedures';
const QUESTIONNAIRES = 'Questionnaires';
const STUDY_PROCEDURES = 'Study Procedures';

const EventList = (props) => {
  const {soeReadOnly, numbers : { screeningVisitsNumber, treatmentVisitsNumber, followupVisitsNumber, weekNumber } }= props;
  const trialEndpoints=props.endpoints
  const viewOnly = props.viewOnly ||false;
  const [sort,setSort] = useState("")
  const [weeks, setWeeks] = useState([])
  const [visits, setVisits] = useState([])
  const [weeksFlex, setWeeksFlex] = useState(1)
  const [visitsFlex, setVisitsFlex] = useState(1)
  const [expandKeys, setExpandKeys] = useState([])
  let [labs, setLabs] = useState(props.labs||[]);
  let [examination, setExamination] = useState(props.examination||[])
  let [procedures, setProcedures] = useState(props.procedures||[])
  let [questionnaire, setQuestionnaire] = useState(props.questionnaire||[])
  let [studyProcedures, setStudyProcedures] = useState(props.studyProcedures||[])
  const [currentModality, setCurrentModality] = useState('')
  const [rowModality, setRowModality] =  useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  );
  const [columnModality, setColumnModality] =  useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {}
  );

  const [eventNumbers, setEventNumbers] = useState({evtScreeningVisitsNumber: 0,
    evtTreatmentVisitsNumber:  0,
    evtFollowupVisitsNumber:  0,
    evtWeekNumber: 0});

  const modalityOptions = props.modalityOptions

  useEffect( ()=>{
    const expandKeysTmp = JSON.parse(JSON.stringify(expandKeys))
    if (props.labs && props.labs.length>0) expandKeysTmp.push('1')
    if (props.examination && props.examination.length>0) expandKeysTmp.push('2')
    if (props.procedures && props.procedures.length>0) expandKeysTmp.push('3')
    if (props.questionnaire && props.questionnaire.length>0) expandKeysTmp.push('4')
    if (props.studyProcedures && props.studyProcedures.length>0) expandKeysTmp.push('5')
    setExpandKeys(Array.from(new Set(expandKeysTmp)))

  },[props.labs, props.examination, props.procedures,props.questionnaire,props.studyProcedures])

  useEffect(() => {
    if(props.viewOnly) setExpandKeys(['1','2','3','4','5'])
  }, [props.viewOnly])

  useEffect(() => {
    setEventNumbers({
      evtScreeningVisitsNumber: screeningVisitsNumber || 0,
      evtTreatmentVisitsNumber:  treatmentVisitsNumber|| 0,
      evtFollowupVisitsNumber: followupVisitsNumber || 0,
      evtWeekNumber: weekNumber || 0,
    })
  }, [props.numbers])

  const [notesList, setNotesList] = useState([])
  const [editShow, setEditShow] = useState([])

  useEffect(() => {
    if(!props.visitsList) return;
    let newData = [...props.visitsList]
    let newArr = newData.map(k => ({...k, edit: false}))
    let tempNotesList = []
    let notes_list = newData.map(k => tempNotesList[k.visit-1] =  k.visit_notes)
    setEditShow(newArr)
    setNotesList(tempNotesList)
  }, [props.visitsList])

  const addVisitNoteList = (id, visitId, weekId) => {
    if(notesList[id] == undefined) return
    const { visitNoteListHandler, visitsList } = props;
    let note = notesList[id];
    let list = visitsList
    let duplicate_item = list?.find((val, i) => val.visit_notes !== "" && val.visit_notes == note.trim())
    if(duplicate_item  && duplicate_item?.visit !== visitId) {
      message.error('Duplicate notes cannot be saved')
      return
    };
    visitNoteListHandler({
      "visit_notes": notesList[id],
      "weeks": weekId,
      "visit": visitId,
    });
  }

  const addVisitNotes = (evt, idx) => {
    const note = evt.target.value;

    const notesArr = [...notesList]
    notesArr[idx] = note
    setNotesList(notesArr)

    let newData = [...editShow]
    newData.map(k => ({...k, edit: false}))
    setEditShow(newData)
  }

  const getNotesValue = (idx, visit, week) => {
    if(props?.visitsList?.length == 0) return;
    let list = props.visitsList
    let item = list?.filter((val, i) => val.visit == visit)
    return item?.[0]?.visit_notes;
  }

  const showEditBtn = (idx, visit, week) => {
    let newData = [...editShow]
    const index = newData.findIndex((value, index) => visit == value.visit);
    if(index > -1) {
      newData[index].edit = true;
    }
    setEditShow(newData);
  }

  const popOverContent =( idx, visit, week, viewOnly) => {
    let newData = [...editShow]
    const index = newData.findIndex((value, index) => visit == value.visit);
    let value = getNotesValue(idx, visit, week);
    if(viewOnly) {
      return value ?
        <div style={{width: '160px', height: '100px',  padding: '8px', backgroundColor: '#282f34', color: 'white'}}>
          <div style={{height: '80%'}}>
          {value}
          </div>
        </div>
        :
        null
    }
    return (
      <div style={{width: '160px', height: '100px', padding: '8px', backgroundColor: '#282f34', color: 'white'}}>
      <div style={{backgroundColor: 'white', height: '80%'}}>
      <TextArea rows={2} maxLength={20} defaultValue={value} onChange={(e) => addVisitNotes(e, idx)} showCount={true} />
      </div>
      <a onClick={() => addVisitNoteList(idx, visit, week)}>Add Note </a>
    </div>
    )}

 
  const printContent = (visitIndex, visits) => {
    let { evtScreeningVisitsNumber, evtTreatmentVisitsNumber, evtFollowupVisitsNumber } = eventNumbers;
    const actualVisistsArr = [...visits]
    let content, width, displayText;
    switch(visitIndex) {
      case 1:
        content = actualVisistsArr.splice(0, evtScreeningVisitsNumber);
        displayText= 'SCREENING VISITS'
        break;
      case 2:
        content = actualVisistsArr.splice(evtScreeningVisitsNumber, evtTreatmentVisitsNumber);
        displayText= 'TREATMENT VISITS'
        break;
      case 3:
        content = actualVisistsArr.splice( evtScreeningVisitsNumber+evtTreatmentVisitsNumber, evtFollowupVisitsNumber)
        displayText= 'FOLLOW-UP VISITS'
        break;

    }
    width = content.length > 0 ? content.length : 1;
    return {
      content,
      width,
      displayText
      };
  }

  useEffect(() => {
    setWeeks(props.weeks)
    setWeeksFlex(props.weeks !== undefined ? 1/(props.weeks.length+1) : 0)
    getVisits()
  }, [screeningVisitsNumber, treatmentVisitsNumber, followupVisitsNumber, weekNumber])

  useEffect(()=>{
    setWeeks(props.weeks)
    setWeeksFlex(props.weeks !== undefined ? 1/(props.weeks.length+1) : 0)
    updateCondition()
    handleResize()
  },[props.weeks, props.numbers])

  const getCondition = (category) =>{
    let tmpCategory = [category].slice(0)[0];

    let vNumber = screeningVisitsNumber + treatmentVisitsNumber + followupVisitsNumber
    tmpCategory.forEach((ele) => {
      if(!ele.condition || ele.condition.length==0||ele.condition.length!=vNumber){
        let condition = [];
        visits.forEach((e, idx) => {
          condition.push({
            visits: e,
            weeks:weeks[idx],
            modality:ele.condition.length >= e ? ele.condition[e-1].modality : ''
          });
        });
        const totalVisit = condition.filter(e=>e.modality && e.modality!=="").length
        ele.condition = condition;
        ele.totalVisit = totalVisit;
      }
    });
    return tmpCategory
  }

  const updateCondition = () =>{
    let { evtScreeningVisitsNumber, evtTreatmentVisitsNumber, evtFollowupVisitsNumber, evtWeekNumber } = eventNumbers;

    const addVisits = (category, idx, step) =>{
      let tmpCategory = JSON.parse(JSON.stringify(category))
      insertCondition(tmpCategory, idx, weeks, step)
      return tmpCategory
    }

    const removeVisits = (category, idx, step) =>{
      let tmpCategory = JSON.parse(JSON.stringify(category))
      deleteCondition(tmpCategory, idx, step)
      return tmpCategory
    }

    const handleScreeningVisits = async (eventNumbers) => {
      let newObj
      let tmpLabs = []
      let tmpExamination =[]
      let tmpProcedures = []
      let tmpQuestionnaire = []
      let tmpStudyProcedures = []
      if(screeningVisitsNumber > evtScreeningVisitsNumber){
        let idx
        let step
        step = screeningVisitsNumber - evtScreeningVisitsNumber
        idx = evtScreeningVisitsNumber - 1
        if(step === 0) return {
          eventNumber: eventNumbers.eventNumber,
          labs: labs,
          examination: examination,
          procedures: procedures,
          questionnaire: questionnaire,
          studyProcedures: studyProcedures,
        }
        tmpLabs = addVisits(labs, idx, step)
        tmpExamination = addVisits(examination, idx, step)
        tmpProcedures = addVisits(procedures, idx, step)
        tmpQuestionnaire = addVisits(questionnaire, idx, step)
        tmpStudyProcedures = addVisits(studyProcedures, idx, step)
        newObj = {
          evtScreeningVisitsNumber: screeningVisitsNumber,
          evtTreatmentVisitsNumber: evtTreatmentVisitsNumber,
          evtFollowupVisitsNumber:  evtFollowupVisitsNumber,
          evtWeekNumber: weeks.length + step,
        };
      }else{
        let idx
        let step
        step = evtScreeningVisitsNumber - screeningVisitsNumber
        idx = evtScreeningVisitsNumber - step
        if(step === 0) return {
          eventNumber: eventNumbers.eventNumber,
          labs: labs,
          examination: examination,
          procedures: procedures,
          questionnaire: questionnaire,
          studyProcedures: studyProcedures,
        }
        tmpLabs = removeVisits(labs, idx, step)
        tmpExamination = removeVisits(examination, idx, step)
        tmpProcedures = removeVisits(procedures, idx, step)
        tmpQuestionnaire = removeVisits(questionnaire, idx, step)
        tmpStudyProcedures = removeVisits(studyProcedures, idx, step)
        newObj = {
          evtScreeningVisitsNumber: screeningVisitsNumber,
          evtTreatmentVisitsNumber: evtTreatmentVisitsNumber,
          evtFollowupVisitsNumber:  evtFollowupVisitsNumber,
          evtWeekNumber: weeks.length - step,
        };
      }
      return {
        eventNumber: newObj,
        labs: tmpLabs,
        examination: tmpExamination,
        procedures: tmpProcedures,
        questionnaire: tmpQuestionnaire,
        studyProcedures: tmpStudyProcedures,
      };
    }

    const handleTreatmentVisits = async (eventNumbers) => {
      let newObj
      let tmpLabs = []
      let tmpExamination =[]
      let tmpProcedures = []
      let tmpQuestionnaire = []
      let tmpStudyProcedures = []
      let { evtScreeningVisitsNumber, evtTreatmentVisitsNumber, evtFollowupVisitsNumber, evtWeekNumber } = eventNumbers.eventNumber;
      let {labs, examination, procedures, questionnaire, studyProcedures} = eventNumbers;
      if(treatmentVisitsNumber > evtTreatmentVisitsNumber){
        let idx
        let step
        step = treatmentVisitsNumber - evtTreatmentVisitsNumber
        idx = screeningVisitsNumber + evtTreatmentVisitsNumber - 1
        if(step === 0) return {
          eventNumber: eventNumbers.eventNumber,
          labs: labs,
          examination: examination,
          procedures: procedures,
          questionnaire: questionnaire,
          studyProcedures: studyProcedures,
        }
        tmpLabs = addVisits(labs, idx, step)
        tmpExamination = addVisits(examination, idx, step)
        tmpProcedures = addVisits(procedures, idx, step)
        tmpQuestionnaire = addVisits(questionnaire, idx, step)
        tmpStudyProcedures = addVisits(studyProcedures, idx, step)
        newObj = {
          evtScreeningVisitsNumber: evtScreeningVisitsNumber,
          evtTreatmentVisitsNumber: treatmentVisitsNumber,
          evtFollowupVisitsNumber: evtFollowupVisitsNumber,
          evtWeekNumber: evtWeekNumber + step,
        };
      }else{
        let idx
        let step
        step = evtTreatmentVisitsNumber - treatmentVisitsNumber
        idx = screeningVisitsNumber + evtTreatmentVisitsNumber - step
        if(step === 0) return {
          eventNumber: eventNumbers.eventNumber,
          labs: labs,
          examination: examination,
          procedures: procedures,
          questionnaire: questionnaire,
          studyProcedures: studyProcedures,
        }
        tmpLabs = removeVisits(labs, idx, step)
        tmpExamination = removeVisits(examination, idx, step)
        tmpProcedures = removeVisits(procedures, idx, step)
        tmpQuestionnaire = removeVisits(questionnaire, idx, step)
        tmpStudyProcedures = removeVisits(studyProcedures, idx, step)
        newObj = {
          evtScreeningVisitsNumber: evtScreeningVisitsNumber,
          evtTreatmentVisitsNumber: treatmentVisitsNumber,
          evtFollowupVisitsNumber: evtFollowupVisitsNumber,
          evtWeekNumber: evtWeekNumber - step,
        };
      }
      return {
        eventNumber: newObj,
        labs: tmpLabs,
        examination: tmpExamination,
        procedures: tmpProcedures,
        questionnaire: tmpQuestionnaire,
        studyProcedures: tmpStudyProcedures,
      };
    }

    const handleFollowupVisits = async (eventNumbers) => {
      let newObj
      let tmpLabs = []
      let tmpExamination =[]
      let tmpProcedures = []
      let tmpQuestionnaire = []
      let tmpStudyProcedures = []
      let { evtScreeningVisitsNumber, evtTreatmentVisitsNumber, evtFollowupVisitsNumber, evtWeekNumber } = eventNumbers.eventNumber;
      let {labs, examination, procedures, questionnaire, studyProcedures} = eventNumbers;
      if(followupVisitsNumber > evtFollowupVisitsNumber){
        let idx
        let step
        step = followupVisitsNumber - evtFollowupVisitsNumber
        idx = screeningVisitsNumber + treatmentVisitsNumber + evtFollowupVisitsNumber - 1
        if(step === 0) return {
          eventNumber: eventNumbers.eventNumber,
          labs: labs,
          examination: examination,
          procedures: procedures,
          questionnaire: questionnaire,
          studyProcedures: studyProcedures,
        }
        tmpLabs = addVisits(labs, idx, step)
        tmpExamination = addVisits(examination, idx, step)
        tmpProcedures = addVisits(procedures, idx, step)
        tmpQuestionnaire = addVisits(questionnaire, idx, step)
        tmpStudyProcedures = addVisits(studyProcedures, idx, step)
        newObj = {
          evtScreeningVisitsNumber: evtScreeningVisitsNumber,
          evtTreatmentVisitsNumber: evtTreatmentVisitsNumber,
          evtFollowupVisitsNumber: followupVisitsNumber,
          evtWeekNumber: evtWeekNumber + step,
        };
      }else{
        let idx
        let step
        step = evtFollowupVisitsNumber - followupVisitsNumber
        idx = screeningVisitsNumber + treatmentVisitsNumber +  evtFollowupVisitsNumber - step
        if(step === 0) return {
          eventNumber: eventNumbers.eventNumber,
          labs: labs,
          examination: examination,
          procedures: procedures,
          questionnaire: questionnaire,
          studyProcedures: studyProcedures,
        }
        tmpLabs = removeVisits(labs, idx, step)
        tmpExamination = removeVisits(examination, idx, step)
        tmpProcedures = removeVisits(procedures, idx, step)
        tmpQuestionnaire = removeVisits(questionnaire, idx, step)
        tmpStudyProcedures = removeVisits(studyProcedures, idx, step)
        newObj = {
          evtScreeningVisitsNumber: evtScreeningVisitsNumber,
          evtTreatmentVisitsNumber: evtTreatmentVisitsNumber,
          evtFollowupVisitsNumber: followupVisitsNumber,
          evtWeekNumber: evtWeekNumber - step,
        };
      }
      return {
        eventNumber: newObj,
        labs: tmpLabs,
        examination: tmpExamination,
        procedures: tmpProcedures,
        questionnaire: tmpQuestionnaire,
        studyProcedures: tmpStudyProcedures,
      };
    }

    const handleResetThreeVisits = async () => {
      let updatedEventNumbers = {
        eventNumber: { ...eventNumbers },
        labs: [...labs],
        examination: [...examination],
        procedures: [...procedures],
        questionnaire: [...questionnaire],
        studyProcedures: [...studyProcedures]
      }
      updatedEventNumbers = await handleScreeningVisits(updatedEventNumbers)&&JSON.parse(JSON.stringify(await handleScreeningVisits(updatedEventNumbers)));
      updatedEventNumbers = await handleTreatmentVisits(updatedEventNumbers)&&JSON.parse(JSON.stringify(await handleTreatmentVisits(updatedEventNumbers)));
      updatedEventNumbers = await handleFollowupVisits(updatedEventNumbers)&&JSON.parse(JSON.stringify(await handleFollowupVisits(updatedEventNumbers)));
      setEventNumbers(updatedEventNumbers.eventNumber);
      setLabs(updatedEventNumbers.labs);
      setExamination(updatedEventNumbers.examination);
      setProcedures(updatedEventNumbers. procedures);
      setQuestionnaire(updatedEventNumbers.questionnaire);
      setStudyProcedures(updatedEventNumbers.studyProcedures);
    }

    if(evtScreeningVisitsNumber + evtTreatmentVisitsNumber + evtFollowupVisitsNumber === 0 || screeningVisitsNumber === evtScreeningVisitsNumber &&  treatmentVisitsNumber === evtTreatmentVisitsNumber && followupVisitsNumber === evtFollowupVisitsNumber
      && weekNumber == evtWeekNumber ){
      let tmpLabs = []
      let tmpExamination =[]
      let tmpProcedures = []
      let tmpQuestionnaire = []
      let tmpStudyProcedures = []
      // If no change on visit numbers, use previous logic directly
      tmpLabs = resetCondition(labs)
      tmpExamination =resetCondition(examination)
      tmpProcedures = resetCondition(procedures)
      tmpQuestionnaire =resetCondition(questionnaire)
      tmpStudyProcedures =  resetCondition(studyProcedures)
      setLabs(tmpLabs);
      setExamination(tmpExamination);
      setProcedures(tmpProcedures);
      setQuestionnaire(tmpQuestionnaire);
      setStudyProcedures(tmpStudyProcedures);
    }else{
      // If visit number changes, reset three kinds of visits with condition
      handleResetThreeVisits()
    }

  }

  const resetCondition = (category) =>{
    let tmpCategory = [category].slice(0)[0];

    tmpCategory.forEach((ele) => {
        let condition = [];
        let vNumber = screeningVisitsNumber + treatmentVisitsNumber + followupVisitsNumber
	      for(let e = 1; e <= vNumber; e ++){
          condition.push({
            visits: e,
            weeks:weeks[e-1],
            modality: ele.condition.length >= e ? ele.condition[e-1].modality : '',
          });
        }
        const totalVisit = condition.filter(e=>e.modality&&e.modality!=="").length
        ele.condition = condition;
        ele.totalVisit = totalVisit;
    });
    return tmpCategory
  }

  useEffect(() => {
    //initial condition
    let tmpLabs = getCondition(props.labs)
    let tmpExamination =getCondition(props.examination)
    let tmpProcedures = getCondition(props.procedures)
    let tmpQuestionnaire =getCondition(props.questionnaire)
    let tmpStudyProcedures =  getCondition(props.studyProcedures)
    setLabs(tmpLabs);
    setExamination(tmpExamination);
    setProcedures(tmpProcedures);
    setQuestionnaire(tmpQuestionnaire);
    setStudyProcedures(tmpStudyProcedures);
    handleResize();
  }, [
    props.labs,
    props.studyProcedures,
    props.procedures,
    props.questionnaire,
    props.examination,
    screeningVisitsNumber,
    treatmentVisitsNumber,
    followupVisitsNumber,
    weekNumber,
  ]);

  const getInitCodition = () =>{
    let initCodition = [];
    visits.forEach((e, idx) => {
      initCodition.push({
          visits: e,
          weeks: weeks[idx],
          modality:""
        });
      });
      return initCodition
  }

  useEffect(() => {
    if(props.submitType != 0){
      onSave()
    }
  }, [props.submitType])

  // exportEvent in new UI
  // useEffect(() => {
  //   if (props.viewOnly ) return;
  //   props.exportEvent()
  // })

  const onEndpointChange = (value, evt,idx) => {
    const Categories = evt.Categories
    let tmpCategories;
    switch (Categories){
      case LABS:
        tmpCategories = labs.slice(0);
        tmpCategories[idx].endpoint = value
        setLabs(tmpCategories)
        break
      case PHYSICAL_EXAMINATION:
        tmpCategories = examination.slice(0);
        tmpCategories[idx].endpoint = value;
        setExamination(tmpCategories)
        break
      case PROCEDURES:
        tmpCategories = procedures.slice(0);
        tmpCategories[idx].endpoint = value;
        setProcedures(tmpCategories)
        break
      case QUESTIONNAIRES:
        tmpCategories = questionnaire.slice(0);
        tmpCategories[idx].endpoint = value;
        setQuestionnaire(tmpCategories)
        break
      case STUDY_PROCEDURES:
        tmpCategories = studyProcedures.slice(0);
        tmpCategories[idx].endpoint = value;
        setStudyProcedures(tmpCategories);
        break
      default:
        tmpCategories = labs.slice(0);
        tmpCategories[idx].endpoint = value;
        setLabs(tmpCategories)
    }
    props.handleEventChange()
  }

  const toggleChecked = (evt, idx) => {
    const { Categories, condition } = evt;
    const tmpCon = condition.slice(0);
    tmpCon[idx].checked = !tmpCon[idx].checked;
    const totalVisitTmp = condition.filter(c => c.checked).length
    switch (Categories) {
      case LABS:
        const tmpLabs = labs.slice(0);
        const targetLab = tmpLabs.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetLab.condition = tmpCon;
        targetLab.totalVisit = totalVisitTmp
        setLabs(tmpLabs);
        break;

      case PHYSICAL_EXAMINATION:
        const tmpExamination = examination.slice(0);
        const targetEaxm = tmpExamination.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetEaxm.condition = tmpCon;
        targetEaxm.totalVisit = totalVisitTmp
        setExamination(tmpExamination);
        break;

      case PROCEDURES:
        const tmpProcedures= procedures.slice(0);
        const targetProcedure = tmpProcedures.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetProcedure.condition = tmpCon;
        targetProcedure.totalVisit = totalVisitTmp
        setProcedures(tmpProcedures);
        break;

       case QUESTIONNAIRES:
        const tmpQuestionnaire= questionnaire.slice(0);
        const targetQuestionnaire = tmpQuestionnaire.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetQuestionnaire.condition = tmpCon;
        targetQuestionnaire.totalVisit = totalVisitTmp
        setQuestionnaire(tmpQuestionnaire);
        break;

       case STUDY_PROCEDURES:
        const tmpStudyProcedures= studyProcedures.slice(0);
        const targetStudyProcedure=tmpStudyProcedures.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetStudyProcedure.condition = tmpCon;
        targetStudyProcedure.totalVisit = totalVisitTmp
        setStudyProcedures(tmpStudyProcedures);
        break;

      default:
    }
    props.handleEventChange()
  };

  const updateAllCategories = (condition, Categories,evt, tmpCon) =>{

    const totalVisitTmp = condition.filter(c => c.modality && c.modality!=="").length
    switch (Categories) {
      case LABS:
        const tmpLabs = labs.slice(0);
        const targetLab = tmpLabs.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetLab.condition = tmpCon;
        targetLab.totalVisit = totalVisitTmp
        setLabs(tmpLabs);
        break;

      case PHYSICAL_EXAMINATION:
        const tmpExamination = examination.slice(0);
        const targetEaxm = tmpExamination.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetEaxm.condition = tmpCon;
        targetEaxm.totalVisit = totalVisitTmp
        setExamination(tmpExamination);
        break;

      case PROCEDURES:
        const tmpProcedures= procedures.slice(0);
        const targetProcedure = tmpProcedures.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetProcedure.condition = tmpCon;
        targetProcedure.totalVisit = totalVisitTmp
        setProcedures(tmpProcedures);
        break;

       case QUESTIONNAIRES:
        const tmpQuestionnaire= questionnaire.slice(0);
        const targetQuestionnaire = tmpQuestionnaire.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetQuestionnaire.condition = tmpCon;
        targetQuestionnaire.totalVisit = totalVisitTmp
        setQuestionnaire(tmpQuestionnaire);
        break;

       case STUDY_PROCEDURES:
        const tmpStudyProcedures= studyProcedures.slice(0);
        const targetStudyProcedure=tmpStudyProcedures.find(
          (ele) => ele["Standard Activity"] == evt["Standard Activity"]
        )
        targetStudyProcedure.condition = tmpCon;
        targetStudyProcedure.totalVisit = totalVisitTmp
        setStudyProcedures(tmpStudyProcedures);
        break;
      default:
    }

  }

  const onModalityChange = (e,evt, idx,value) => {
    console.log("value",value);

    const { Categories, condition } = evt;
    let tmpCon = condition.slice(0);

    setCurrentModality(e.target.value)
    tmpCon[idx].modality = e.target.value
    updateAllCategories(condition, Categories,evt, tmpCon)
  };

  const renderVisit = () => {
    let visits = [];
    let vNumber =screeningVisitsNumber + treatmentVisitsNumber + followupVisitsNumber 
	  for (var i = 1; i <= vNumber; i++) {
      visits.push(<div className="td" key={i}>{i}</div>);
    }
    return <>{visits}</>;
  };

  const getVisits = () =>{
    let visitArr = [];
    let vNumber = screeningVisitsNumber + treatmentVisitsNumber + followupVisitsNumber 
	  for (let i = 0; i <= vNumber-1; i++) {
      visitArr.push(i+1)
    }
    setVisits(visitArr)
    setVisitsFlex(visitArr.length/(visitArr.length+1))
  }




  const endpointsSelector = (evt,idx) => {
    return (
      <>
      {/* { <Select
        showSearch
        style={{ width: "100%" }}
        placeholder="Search to Select"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 && option.searchable !=="false"
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
        value={evt.endpoint}
        onChange={(value)=> onEndpointChange(value,evt,idx)}
        virtual={false}
      >
          <Option value="No endpoint" key={evt.Categories + evt['Standard Activity'] + "na"} searchable="true">No Endpoint</Option>

          {Object.keys(trialEndpoints).map((k, idx) => {

          return (
          <>
            <Option value={k} key={evt.Categories + evt['Standard Activity'] + idx} disabled searchable="false">{k}</Option>
            {trialEndpoints[k].map((endpoint, i) => {
              return (
                <Option value={endpoint} key={evt.Categories + evt['Standard Activity'] + idx + '-' + i} searchable="true">{endpoint}</Option>
              );
            })}
          </>
          );})}
      </Select> } */}
      <Select
        placeholder='Search to Select'
        style={{ width: '100%', height: 37, }} dropdownMatchSelectWidth
        showSearch 
        value={evt.endpoint}
        onChange={(value)=> onEndpointChange(value,evt,idx)}
        virtual={false}
        options={[
          {
            label: "No endpoint", 
            value: "No endpoint", 
            key:`${evt.Categories} ${evt['Standard Activity']} na`,  
          }, {
            label: 'Primary Endpoint', 
            options: trialEndpoints[Object.keys(trialEndpoints)[0]].map((k, i) => {
              return {
                label: k,
                value: k,
                key:`${evt.Categories} ${evt['Standard Activity']}  ${idx}  -  ${i} - primary`
              }
            })
          },
          {
            label: 'Secondary Endpoint', 
            options: trialEndpoints[Object.keys(trialEndpoints)[1]].map((k, i) => {
              return {
                label: k,
                value: k,
                key:`${evt.Categories} ${evt['Standard Activity']}  ${idx}  -  ${i} - secondary`
              }
            })
          },
          {
            label: 'Tertiary Endpoint', 
            options: trialEndpoints[Object.keys(trialEndpoints)[2]].map((k, i) => {
              return {
                label: k,
                value: k,
                key:`${evt.Categories} ${evt['Standard Activity']}  ${idx}  -  ${i} - tertiary`
              }
            })
          },
        ]}
      />
    </>
    );
  };

  const onWeekChange = (e,idx) => {
    const tmpWeeks = weeks.slice(0)
    let value = parseInt(e.target.value)
    if(value===NaN){value=1}
    if(value>1000){value=1000}
    if(value<=0){value=1}

    tmpWeeks[idx] = value
    setWeeks(tmpWeeks)
    setWeeksFlex(1/(tmpWeeks.length+1))
    props.handleEventChange(tmpWeeks)
  }

  const getTotalCost = (array) => {
    return array.filter(currentValue=>currentValue['cost'] != undefined).reduce(function (accumulator, currentValue) {
      return accumulator + Number(currentValue['cost'])*Number(currentValue['totalVisit'])
    }, 0)
  }

  const onSort = (a,b) => {
    if (sort == "ascend") return (a['cost'] - b['cost'])
    if (sort == "descend") return (b['cost'] - a['cost'])
    return 0
  }

  const onAddEvent = (e,key) =>{
    e.stopPropagation();
    let temp;
    let expandPanel=expandKeys.slice(0)
    let initCodition=getInitCodition()
    switch (key){
      case LABS:
        expandPanel.push("1")
        temp=labs.slice(0)
        temp.push({
          "Standard Activity": "",
          "Categories": LABS,
          "Count": 0,
          "cost": 0,
          "condition":initCodition,
          "nct_id":[],
          "Custom":true,
          "totalVisit":0,
          "soaWeights": [0,0,0,0,0,0,0,0,0,0],
        })

        setLabs(temp)
        break;

      case PHYSICAL_EXAMINATION:
        expandPanel.push("2")
        temp=examination.slice(0)
        temp.push({
          "Standard Activity": "",
          "Categories": PHYSICAL_EXAMINATION,
          "Count": 0,
          "cost": 0,
          "condition":initCodition,
          "nct_id":[],
          "Custom":true,
          "totalVisit":0,
          "soaWeights": [0,0,0,0,0,0,0,0,0,0],
        })
        setExamination(temp)
        break;

      case PROCEDURES:
        expandPanel.push("3")
        temp=procedures.slice(0)
        temp.push({
          "Standard Activity": "",
          "Categories": PROCEDURES,
          "Count": 0,
          "cost": 0,
          "condition":initCodition,
          "nct_id":[],
          "Custom":true,
          "totalVisit":0,
          "soaWeights": [0,0,0,0,0,0,0,0,0,0],
        })
        setProcedures(temp)
        break;

      case QUESTIONNAIRES:
        expandPanel.push("4")
        temp=questionnaire.slice(0)
        temp.push({
          "Standard Activity": "",
          "Categories": QUESTIONNAIRES,
          "Count": 0,
          "cost": 0,
          "condition":initCodition,
          "nct_id":[],
          "Custom":true,
          "totalVisit":0,
          "soaWeights": [0,0,0,0,0,0,0,0,0,0],
        })
        setQuestionnaire(temp)
        break;

      case STUDY_PROCEDURES:
        expandPanel.push("5")
        temp=studyProcedures.slice(0)
        temp.push({
          "Standard Activity": "",
          "Categories": STUDY_PROCEDURES,
          "Count": 0,
          "cost": 0,
          "condition":initCodition,
          "nct_id":[],
          "Custom":true,
          "totalVisit":0,
          "soaWeights": [0,0,0,0,0,0,0,0,0,0],
        })
        setStudyProcedures(temp)
        break;
        default:

      }
      expandPanel = Array.from(new Set(expandPanel))
      setExpandKeys(expandPanel)
      props.handleEventChange()
  }

  const onCustomEventNameChange = (e,category, idx,key)=>{
    let temp;
    let value = e.target.value;
    // upper limit 10000
    if(value>10000){value=10000}
    switch (category){
      case LABS:
        temp=labs.slice(0)
        temp[idx][key] = value
        setLabs(temp)
        break;

      case PHYSICAL_EXAMINATION:
        temp=examination.slice(0)
        temp[idx][key] = value
        setExamination(temp)
        break;

      case PROCEDURES:
        temp=procedures.slice(0)
        temp[idx][key] = value
        setProcedures(temp)
        break;

      case QUESTIONNAIRES:
        temp=questionnaire.slice(0)
        temp[idx][key] = value
        setQuestionnaire(temp)
        break;

      case STUDY_PROCEDURES:
        temp=studyProcedures.slice(0)
        temp[idx][key] = value
        setStudyProcedures(temp)
        break;
      }
      props.handleEventChange()
  }

  const onRemoveCustomEvent=(category,idx) =>{
    let temp;
    switch (category){
      case LABS:
        temp=labs.slice(0)
        temp.splice(idx,1)
        setLabs(temp)
        break;

        case PHYSICAL_EXAMINATION:
          temp=examination.slice(0)
          temp.splice(idx,1)
          setExamination(temp)
          break;

        case PROCEDURES:
          temp=procedures.slice(0)
          temp.splice(idx,1)
          setProcedures(temp)
          break;

        case QUESTIONNAIRES:
          temp=questionnaire.slice(0)
          temp.splice(idx,1)
          setQuestionnaire(temp)
          break;

        case STUDY_PROCEDURES:
          temp=studyProcedures.slice(0)
          temp.splice(idx,1)
          setStudyProcedures(temp)
          break;
      }
      props.handleEventChange()
  }

  const onSave = async () => {
    const scheduleOfEvents = {
      "Labs": {
        entities: labs,
        totalCost: getTotalCost(labs)
        },
      "Physical Examination": {
        entities: examination,
        totalCost: getTotalCost(examination)
        },
      "Procedures": {
        entities: procedures,
        totalCost:  getTotalCost(procedures)
        },
      "Questionnaires": {
        entities: questionnaire,
        totalCost: getTotalCost(questionnaire)
        },
      "Study Procedures": {
        entities: studyProcedures,
        totalCost: getTotalCost(studyProcedures)
      },
      "Weeks":weeks,
      "ScreeningVisits": eventNumbers.evtScreeningVisitsNumber,
      "TreatmentVisits": eventNumbers.evtTreatmentVisitsNumber,
      "FollowupVisits" : eventNumbers.evtFollowupVisitsNumber,
      "WeekNumber": weeks[weeks.length - 1]
    }
    props.saveEvents(scheduleOfEvents)
  }

  const showConfigureModal = () =>{
    props.showConfigureModal()
  }

  function callback(key) {
    setExpandKeys(key)
  }

  const onColumnModalityChange =(e,idx)=>{
    setColumnModality({ [idx]: e.target.value})
  }

  const getAvaliableModalityNames = (modality) =>{
    let avaliableModalityNames=modality.map( (m,idx)=>{
      return {[modalityOptions[idx].name]:m}
    })
    .filter(ele=>Object.values(ele)[0]!==0 ) //0 means not avaliable
    .map( item=>Object.keys(item)[0])
    return avaliableModalityNames
  }

  const updateColumnModality = (tmpCategories, columnId, isOptimized=false, descModality=[]) =>{

    tmpCategories.forEach( e=>{
      const condition= e.condition||[]
      let avaliableModalityNames = getAvaliableModalityNames(e.modality||[0, 0, 0, 0, 0, 0, 0, 0])
      if(isOptimized&& Boolean(condition[columnId].modality)){
        // if the most common modality is not avaliable for this event, then use second common one...
        for(let i=0; i<descModality.length; i++){
          if(avaliableModalityNames.indexOf(descModality[i])>-1){
            condition[columnId].modality=descModality[i]
            break;
          }
        }
      }
      if(!isOptimized&& Boolean(condition[columnId].modality)) {
        // only if the selected modality is one of its avaliable modalities.
       if(avaliableModalityNames.indexOf(columnModality[columnId])>-1) condition[columnId].modality=columnModality[columnId]
      }
    })
    return tmpCategories
  }

  const updateColumn = (idx) =>{
	let selectedModality = columnModality[idx]!=undefined?columnModality[idx]:''
	let selectedModalityIndex = selectedModality!=undefined && selectedModality!=''?selectedModality-1:''

    const tmpLabs = JSON.parse(JSON.stringify(labs))
	tmpLabs.forEach(criteria => {
		if((selectedModality==''||criteria.modality[selectedModalityIndex]==1) && criteria.condition[idx] && criteria.condition[idx].modality!=""){
			criteria.condition[idx].modality = selectedModality
		}
        criteria.totalVisit = criteria.condition.filter(c => c.modality && c.modality!=="").length
    });
	setLabs(tmpLabs);

    const tmpExamination = JSON.parse(JSON.stringify(examination))
	tmpExamination.forEach(criteria => {
		if((selectedModality==''||criteria.modality[selectedModalityIndex]==1) && criteria.condition[idx] && criteria.condition[idx].modality!=""){
			criteria.condition[idx].modality = selectedModality
		}
        criteria.totalVisit = criteria.condition.filter(c => c.modality && c.modality!=="").length
    });
	setExamination(tmpExamination);

    const tmpProcedures = JSON.parse(JSON.stringify(procedures))
	tmpProcedures.forEach(criteria => {
		if((selectedModality==''||criteria.modality[selectedModalityIndex]==1) && criteria.condition[idx] && criteria.condition[idx].modality!=""){
			criteria.condition[idx].modality = selectedModality
		}
        criteria.totalVisit = criteria.condition.filter(c => c.modality && c.modality!=="").length
    });
	setProcedures(tmpProcedures);

    const tmpQuestionnaire = JSON.parse(JSON.stringify(questionnaire))
	tmpQuestionnaire.forEach(criteria => {
		if((selectedModality==''||criteria.modality[selectedModalityIndex]==1) && criteria.condition[idx] && criteria.condition[idx].modality!=""){
			criteria.condition[idx].modality = selectedModality
		}
        criteria.totalVisit = criteria.condition.filter(c => c.modality && c.modality!=="").length
    });
	setQuestionnaire(tmpQuestionnaire);

    const tmpStudyProcedures = JSON.parse(JSON.stringify(studyProcedures))
	tmpStudyProcedures.forEach(criteria => {
		if((selectedModality==''||criteria.modality[selectedModalityIndex]==1) && criteria.condition[idx] && criteria.condition[idx].modality!=""){
			criteria.condition[idx].modality = selectedModality
		}
        criteria.totalVisit = criteria.condition.filter(c => c.modality && c.modality!=="").length
    });
	setStudyProcedures(tmpStudyProcedures);

	props.handleEventChange()
  }


  const onApplyToColumn=(idx)=>{
    let tmpLabs = labs.slice(0);
    tmpLabs = updateColumnModality(tmpLabs,idx)
    setLabs(tmpLabs)

    let tmpExamination = examination.slice(0);
    tmpExamination =updateColumnModality(tmpExamination,idx)
    setExamination(tmpExamination)

    let tmpProcedures = procedures.slice(0);
    tmpProcedures =updateColumnModality(tmpProcedures,idx)
    setProcedures(tmpProcedures )

    let tmpQuestionnaire = questionnaire.slice(0);
    tmpQuestionnaire =updateColumnModality(tmpQuestionnaire,idx)
    setQuestionnaire(tmpQuestionnaire)

    let tmpStudyProcedures = studyProcedures.slice(0);
    tmpStudyProcedures =updateColumnModality(tmpStudyProcedures,idx)
    setStudyProcedures(tmpStudyProcedures)
    props.handleEventChange()

  }

  const onOptimize=(idx)=>{
    // select the most commom modality across the visit.
    setColumnModality({
      [idx]:""
    })
    const modalityCollection={}
    const modalitySummary={}
    const columnConditionCollection = []
    labs.forEach( ele=>{
      columnConditionCollection.push( ele.condition[idx])
    })
    examination.forEach( ele=>{
      columnConditionCollection.push( ele.condition[idx])
    })
    procedures.forEach( ele=>{
      columnConditionCollection.push( ele.condition[idx])
    })
    questionnaire.forEach( ele=>{
      columnConditionCollection.push( ele.condition[idx])
    })
    studyProcedures.forEach( ele=>{
      columnConditionCollection.push( ele.condition[idx])
    })

    columnConditionCollection.filter(element=>element.modality&&element.modality!=="")
    .forEach( element=>{
    if( Object.keys(modalityCollection).indexOf(element.modality)<0 ){
      modalityCollection[element.modality]=[element]
    }else{
      modalityCollection[element.modality].push(element)
    }
    console.log("modalityCollection",modalityCollection);

    modalityOptions.forEach( option=>{
      if(Object.keys(modalityCollection).indexOf(option.name)==-1){
        modalitySummary[option.name]=0
      }else{
        modalitySummary[option.name]=modalityCollection[option.name].length
      }
    })
    //find the modality with most number
    const descModality = Object.keys(modalitySummary)
    descModality.sort( (a,b)=>{
      return modalitySummary[b] - modalitySummary[a]
    })

    // fill the blank
    let tmpLabs = {};
    tmpLabs=updateColumnModality(JSON.parse(JSON.stringify(labs)),idx,true,descModality)
    setLabs(tmpLabs)

    let tmpExamination = {};
    tmpExamination =updateColumnModality(JSON.parse(JSON.stringify(examination)),idx,true,descModality)
    setExamination(tmpExamination)

    let tmpProcedures = {};
    tmpProcedures =updateColumnModality(JSON.parse(JSON.stringify(procedures)),idx,true,descModality)
    setProcedures(tmpProcedures )

    let tmpQuestionnaire = {};
    tmpQuestionnaire =updateColumnModality(JSON.parse(JSON.stringify(questionnaire)),idx,true,descModality)
    setQuestionnaire(tmpQuestionnaire)

    let tmpStudyProcedures = {};
    tmpStudyProcedures =updateColumnModality(JSON.parse(JSON.stringify(studyProcedures)),idx,true,descModality)
    setStudyProcedures(tmpStudyProcedures)
  })
  }

  const onRowModalityChange =(e,evt)=>{
    setRowModality({ [evt["Standard Activity"]]: e.target.value})
  }
  const onApplyToRow = (evt,fillAll=false) =>{
    const { Categories, condition } = evt;
    let tmpCon = condition.slice(0);
    tmpCon.forEach(element => {
      if(fillAll){
        element.modality= rowModality[evt["Standard Activity"]]
      }else{
        element.modality= Boolean(element.modality)?rowModality[evt["Standard Activity"]]:''
      }
    });
    updateAllCategories(condition, Categories,evt, tmpCon)
    props.handleEventChange()
  }

  const insertCondition = (category, idx, weeks, step) => {
    category.forEach (cat => {
      const tempCondition = JSON.parse(JSON.stringify(cat.condition))
      for (let i = 0; i < step; i++) {
        const newCondition = {
          visits: idx + i + 2,
          weeks: weeks[idx],
          modality: ''
        }
        tempCondition.splice(idx + i + 1, 0, newCondition)
      }
      tempCondition.map((c,i) => c.visits = i + 1)
      cat.condition = tempCondition
    })
    return category
  }

  const insertColumn=((idx)=>{
    // update visits
    let { evtScreeningVisitsNumber, evtTreatmentVisitsNumber, evtFollowupVisitsNumber } = eventNumbers;
    let { type } = getVisitsType(idx);
    const temp = visits.slice(0)
    temp.push(visits.length+1)
    setVisits(temp)
    setVisitsFlex(temp.length/(temp.length+1))
    // update weeks
    const newWeeks = weeks.slice(0)
    let newWeek = weeks[idx]
    newWeeks.splice(idx+1,0,newWeek)
    setWeeks(newWeeks)
    setWeeksFlex(1/(newWeeks.length+1))
    const newObj = {
      evtScreeningVisitsNumber:  type == "screening" ? evtScreeningVisitsNumber + 1 : evtScreeningVisitsNumber,
      evtTreatmentVisitsNumber:  type == "treatment" ? evtTreatmentVisitsNumber + 1 : evtTreatmentVisitsNumber,
      evtFollowupVisitsNumber: type == "followup" ? evtFollowupVisitsNumber + 1 : evtFollowupVisitsNumber,
      evtWeekNumber: newWeeks.length,
    }
    setEventNumbers(newObj);
    // update all conditions
    setLabs(  insertCondition(labs,idx,weeks,1) )
    setExamination(insertCondition(examination,idx,weeks,1))
    setProcedures( insertCondition(procedures,idx,weeks,1))
    setQuestionnaire( insertCondition(questionnaire,idx,weeks,1))
    setStudyProcedures( insertCondition(studyProcedures,idx,weeks,1))
    let isDelete = false;
    let isAdd = true;
    props.resetNotes(newObj, idx, isDelete, isAdd, newWeeks)
  })

  const deleteCondition=(category, idx, step)=>{
    category.forEach(cat => {
      const tempCondition = JSON.parse(JSON.stringify(cat.condition))
      for (let i = 0; i < step; i++) {
        tempCondition.splice(idx, 1)
      }
      tempCondition.map( (c,i) => c.visits = i + 1)
      cat.condition = tempCondition
      cat.totalVisit = cat.condition.filter(c => c.modality && c.modality!=="").length
    })
    return category
  }

  const getVisitsType = (idx) => {
    let { evtScreeningVisitsNumber, evtTreatmentVisitsNumber, evtFollowupVisitsNumber } = eventNumbers;
    let type;
    const screeningVisitHolders =  [...visits].splice(0, evtScreeningVisitsNumber)
    const treatmentVisitHolders =  [...visits].splice(evtScreeningVisitsNumber, evtTreatmentVisitsNumber)
    const followUpVisitHolders =  [...visits].splice(evtScreeningVisitsNumber + evtTreatmentVisitsNumber, evtFollowupVisitsNumber)
    if(screeningVisitHolders.includes(idx + 1)) {
      type="screening"
    } else if(treatmentVisitHolders.includes(idx + 1)) {
      type="treatment"
    } else if(followUpVisitHolders.includes(idx + 1)) {
      type="followup"
    }
    return {
      type,
      screeningVisitHolders,
      treatmentVisitHolders,
      followUpVisitHolders
    };
  }

  const deleteColumn=((idx)=>{
    // update visits
    let { evtScreeningVisitsNumber, evtTreatmentVisitsNumber, evtFollowupVisitsNumber } = eventNumbers;

    let { type } = getVisitsType(idx);
    if( (type == "screening" && evtScreeningVisitsNumber <= 1) || (type == "treatment" && evtTreatmentVisitsNumber <= 1) || (type == "followup" && evtFollowupVisitsNumber <= 1)) {
      return
    }
    const temp = visits.slice(0)
	  temp.pop()
    setVisits(temp)
    setVisitsFlex(temp.length/(temp.length+1))
    // update weeks
    const newWeeks = weeks.slice(0)
	  newWeeks.splice(idx, 1)
    const newObj = {
      evtScreeningVisitsNumber:  type == "screening" ? evtScreeningVisitsNumber - 1 : evtScreeningVisitsNumber,
      evtTreatmentVisitsNumber:  type == "treatment" ? evtTreatmentVisitsNumber - 1 : evtTreatmentVisitsNumber,
      evtFollowupVisitsNumber: type == "followup" ? evtFollowupVisitsNumber - 1 : evtFollowupVisitsNumber,
      evtWeekNumber: newWeeks.length,
    }
    setEventNumbers(newObj);
    setWeeks(newWeeks)
    setWeeksFlex(1/(newWeeks.length+1))
    // update all conditions
    setLabs(deleteCondition(labs, idx, 1))
    setExamination(deleteCondition(examination, idx, 1))
    setProcedures(deleteCondition(procedures, idx, 1))
    setQuestionnaire(deleteCondition(questionnaire, idx, 1))
    setStudyProcedures(deleteCondition(studyProcedures, idx, 1))
    let isDelete = true;
    let isAdd = false;
    props.resetNotes(newObj, idx, isDelete, isAdd, newWeeks)
  })

  const ModalityList = (props) =>{
  const {category,evt,idx,value,rowModality,columnModality,event} = props
    return(
      <div className="modality-list-container">
        <CloseCircleOutlined />
        <Radio.Group
        onChange={props.isRowBatch?(e)=>onRowModalityChange(e,evt): props.isColumnBatch?(e)=>onColumnModalityChange(e,idx): (e)=>onModalityChange(e,evt,idx,value)}
        value={
          props.isRowBatch?
          rowModality[evt['Standard Activity']]:
          props.isColumnBatch?
          (columnModality[idx]?columnModality[idx]:"") :
           //currentModality
          Number(value)
        }
        >
        <Space direction="vertical">
        {(props.isColumnBatch ||  props.isRowBatch ) && modalityOptions.map( (m,idx)=>{
            return (
              <div className="modality-item" key={idx}>
              <Radio
              value={m.key}
              disabled={evt?.modality&&evt.modality[idx]==0?true:false
              }>
                <img src={m.icon} alt=""/> {m.name.length > 0 ? m.name : "Clear"}
              </Radio>
            </div>
            )
        })}
        {!props.isColumnBatch && !props.isRowBatch && modalityOptions.map( (m,idx)=>{
            return (
              <div className="modality-item" key={idx}>
              <Radio
              value={m.key}
              disabled={evt?.modality&&evt.modality[idx]==0?true:false
              }>
                <img src={m.icon} alt=""/> {m.name.length > 0 ? m.name : "Clear"}
              </Radio>
            </div>
            )
        })}
        </Space>
      </Radio.Group>
      {
        props.isColumnBatch&&(
          <div className="action-group">
            <div className="whole action" onClick={()=>updateColumn(idx)}>Update Selected Activities</div>
            {/* <div className="batch action" onClick={()=>onOptimize(idx)}>Optimize to Align</div> */}
            <div className="insert-column action" onClick={()=>insertColumn(idx)}>Add Visit After</div>
            {visits.length>1 ? (
               <div className="insert-column action" onClick={()=>deleteColumn(idx)}>Remove Visit</div>
            ): (
                <div className="insert-column action is-disabled" onClick={()=>deleteColumn(idx)}>Remove Visit</div>
            )}
          </div>
        )
      }
      {
        props.isRowBatch && (
        <div className="action-group">
          <div className="whole action" onClick={()=>onApplyToRow(evt)}>Update Selected Visits</div>
          <div className="batch action" onClick={()=>onApplyToRow(evt,true)}>Update All Visits</div>
        </div>
        )
      }
      </div>
    )
  }

  const generatePanel = (panelData) => {
    const { key, type, data } = panelData;

    const name = `${type} (${data.length})`
    const cost = getTotalCost(data)

    return (
      <Panel
        className="collapse-container"
        forceRender={true}
        header={
          <div className="event-panel-head">
            <div className="event-title e-row">
              <div className="name">
                <span style={{ width: "186px", display: "inline-block" }}>
                  {name}
                </span>

                {!viewOnly && <span className="add-event" ></span>}
              </div>
              <div className="cost">
                $ {cost}
              </div>
              <div></div>
            </div>
            <div className="event-title-right"></div>
          </div>
        }
        key={key}
      >
        <div className="shedule-of-event-panel-body">
          {data.sort((a, b) => {
            return onSort(a, b)
          })
            .map((evt, idx) => {
              return (
                <div className="event-item" key={`${type.toLowerCase()}_${idx}`}>
                  <div className="events-wrapper e-row" style={{ position: "sticky", width: '795px', backgroundColor: "#FFF", left: 0, top: 0, zIndex: 99 }}>
                    <div className={`${evt.Custom ? "custom-event " : ""}my-event-td td f-3`}>
                      {
                        viewOnly ? evt["Standard Activity"] : (
                          <>
                            {
                              !evt.Custom ? (
                                <Tooltip title={evt["Standard Activity"]}>
                                  <DeleteFilled style={{ color: '#BABABA', marginRight: '5px' }} onClick={() => props.handleEvent(evt)} />
                                  {evt["Standard Activity"]}
                                </Tooltip>
                              ) : (
                                <>
                                  <MinusCircleOutlined onClick={() => onRemoveCustomEvent(LABS, idx)} /> <Input value={evt["Standard Activity"]} onChange={(e) => onCustomEventNameChange(e, LABS, idx, "Standard Activity")} />
                                </>
                              )
                            }
                          </>
                        )
                      }
                    </div>
                    <div className="frequency-td td f-2">
                      <span>{Math.round(evt.Frequency * 10000) / 100 + "%"}</span>
                    </div>
                    <div className="visit-td td f-2">
                      <span>{Math.round(evt.visit_frequency * 100) / 100 + "%"}</span>
                    </div>
                    <div className="screening-frequency-td td f2">
                      {Math.floor(evt.totalVisit / (screeningVisitsNumber + treatmentVisitsNumber + followupVisitsNumber) * 10000) / 100 + "%"}
                      </div>
                    <div className="endpoint-td td f-2">
                      {
                        viewOnly ?
                          <Tooltip placement="top" title={evt.endpoint}>
                            <span className="endpoint-span"> {evt.endpoint}</span>
                          </Tooltip>
                          : endpointsSelector(evt, idx)
                      }
                    </div>
                    <div className="cost-td td f-2-small"> 
                      {
                        viewOnly ? evt["cost"] == null ? `` : `$ ${evt["cost"]}` : (
                          <>
                            {
                              !evt.Custom ? evt["cost"] == null ? `` : `$${evt["cost"]}` : <>$ <Input value={evt["cost"]} type="number" onChange={(e) => onCustomEventNameChange(e, type, idx, "cost")} /></>
                            }
                          </>
                        )
                      }
                    </div>
                    <div className="visits-td td f-1-small">{evt.totalVisit}</div>
                    <div className="status-row e-row" style={{paddingLeft: 0}}>
                      <div className="colunm td" style={{ flex: 0, paddingLeft: 0 }}>
                        {viewOnly ? <PlusCircleOutlined /> : <Tooltip
                              title={
                                <ModalityList
                                  evt={evt}
                                  category={type}
                                  isRowBatch={true}
                                  rowModality={rowModality} />
                              }
                              color="#ffffff"
                            >
                              <PlusCircleOutlined />
                            </Tooltip>
                          }
                      </div>
                    </div>
                  </div>
                  <div className="status-row e-row">
                    
                    {/* <div className="status-row e-row"> */}
                      {evt.condition.length > 0 &&
                        evt.condition.map((con, idx) => {
                          const targetItem = modalityOptions.find(m => m.key == con.modality)
                          return viewOnly ? (
                            <div className="td" key={`${type.toLowerCase()}_event_${idx}`}>
                              <span
                                className={`${viewOnly ? 'viewOnly' : ''} incon-wrapper`}
                              >
                                {Boolean(con.modality) && con.modality !== "" ? <img src={targetItem.icon} /> : <PlusCircleOutlined />}
                              </span>
                            </div>
                          ) : (
                            <div className="td" key={`${type.toLowerCase()}_event_${idx}`}>
                              <span className="incon-wrapper" >
                                <Tooltip
                                  title={<ModalityList evt={evt} idx={idx} value={con.modality} />}
                                  color="#ffffff"
                                >
                                  {Boolean(con.modality) ? <img src={targetItem.icon} /> : <PlusCircleOutlined />}
                                </Tooltip>
                              </span>
                            </div>
                          );
                        })}
                    {/* </div> */}
                  </div>
                </div>
              );
            })}
        </div>
      </Panel>
    )
  }

  const eventListHead = useRef();
  const [ collapseWidth, setCollapseWidth ] = useState(null);
  const getWidth = () => {
    const divElement: HTMLDivElement = eventListHead.current;
    return divElement?.offsetWidth;
  }

  const handleResize = () => {
    setCollapseWidth(getWidth());
  };

  useEffect(() => {
    if(eventListHead.current) {
      handleResize();
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [eventListHead])

  useEffect(() => {
    setTimeout(handleResize, 500);
  }, [])

  useEffect(() =>{ 
    handleResize();
  }, [props.windowResize,props.soeLibrarayPanelStatus])

  const panelMapping = [
    { key: "1", type: LABS, data: labs },
    { key: "2", type: PHYSICAL_EXAMINATION, data: examination },
    { key: "3", type: PROCEDURES, data: procedures },
    { key: "4", type: QUESTIONNAIRES, data: questionnaire },
    { key: "5", type: STUDY_PROCEDURES, data: studyProcedures },
  ];

  return (
    <div className="event-list-container" style={{position: "relative"}}>
      <div className="container-top">
        <span className="title">Schedule of Activities</span>
        {
          !viewOnly&&(
            <Button type="primary" size="small"
            disabled={ props.readOnlyUser&&props.readOnlyUser.toLowerCase() === 'read' || soeReadOnly ? true : false }
            onClick={onSave}
            className="saveSOE">
            Save
            </Button>
          )
        }
      </div>
      <div className="event-dashboard" style={{ height: "500px", overflow: "scroll scroll" }} >
        <div className="dashboard-head" style={{ top: "0px", position: "sticky", zIndex: 100, backgroundColor: "#FFF" }}>
          <div className="event-list-head" ref={eventListHead}>
          <div className="head-row" style={{position:"relative"}}>
          <div style={{ position: "sticky", 
          width: "795px", 
          left: "0", top: "0", backgroundColor: "#FFF", zIndex: 100, borderRight: "1px solid #eeeeee"}}> 
                <div className="visit-row e-row number" 
                style={{ position: "relative", 
                float: 'right',
                borderBottom: '1px solid #eee',
                borderRight: 'none',
                borderTop: 'none',
                width: "50px", height: "33px" }}>
                  <div className="colunm td"></div>
                </div>
              </div>
              <div className="visit-row e-row number" style={{ height: "33px" }}>
                {
                   [1, 2, 3].map((v, i)=>{
                    return printContent(v, visits)?.width !== 1? <div className="td" style={{fontSize: '10px', flex:`${printContent(v, visits)?.width} 0`, minWidth: `${41*printContent(v, visits)?.width}px`}}> {printContent(v, visits)?.displayText} </div> : <div className="td" style={{fontSize: '8px', flex:`${printContent(v, visits)?.width} 0`, minWidth: '41px'}}> {printContent(v, visits)?.displayText} </div>
                  })
                }
              </div>
            </div>
            <div className="head-row" style={{ position: "relative" }}>
              <div style={{ position: "sticky", width: "795px", left: "0", top: "0", backgroundColor: "#FFF", zIndex: 100, borderRight: "1px solid #eeeeee"}}>
                {/* <div className="colunm-row e-row" style={{ position: "relative", left: "0", top: "0", width: "634px", height: "33px" }}></div> */}
                <div className="visit-row e-row number" style={{ position: "relative", paddingLeft: "745px", width: "50px", height: "33px" }}>
                  <div className="colunm td" >Visits</div>
                </div>
              </div>
              <div className="visit-row e-row number" style={{ height: "33px" }}>
                {
                  visits.map((v) => {
                    return <div className="td num-cell"  style={{ backgroundColor: "#FFF" }} key={v}>{v}</div>
                  })
                }
              </div>
            </div>
            <div className="head-row" style={{ position: "relative" }}>
              <div className="colunm-row week-row e-row" style={{ position: "sticky", left: "0", top: "0", width: "795px", height: "132px", backgroundColor: "#FFF", zIndex: 100 }}>
                <div className="f-2-new" style={{ width: "233px", height: "100%", }}>My Activities</div>
                <div className="f-3" style={{ width: "92.8px", height: "100%", paddingLeft: "2px" }}>Historical Activity Frequency</div>
                <div className="f-3" style={{ width: "92.8px", height: "100%", paddingLeft: "2px" }}>Historical Visit Frequency</div>
                <div className="f-2-small" style={{ width: "85.4px", height: "100%", }}>Scenario Visit Frequency</div>
                <div className="f-3" style={{ width: "83.46px", height: "100%", }}>Trial Endpoint</div>
                <div className="f-1-small sortable-item" style={{ width: "110.8px", height: "100%", }}>Cost per patient
                  <span className="sort-icon-wrapper">
                    <CaretUpOutlined onClick={() => setSort("ascend")} style={{ color: sort == "ascend" ? "#ca4a04" : "rgb(85,85,85)" }} />
                    <CaretDownOutlined onClick={() => setSort("descend")} style={{ color: sort == "descend" ? "#ca4a04" : "rgb(85,85,85)" }} />
                  </span>
                </div>
                <div className="f-2-small" style={{ width: "56.4px", height: "100%", }}>Total Visits</div>
                <div className="head-bottom-container" style={{ height: "132px" }}>
                  <div className="e-row number" style={{ border: "0px" }}>
                    <div className="colunm td row-title" style={{ flex: 0 }}>Weeks</div>
                  </div>
                </div>
              </div>
              <div className="head-bottom-container" style={{ height: "99px" }}>
                <div className="e-row number">
                  {/* <div className="colunm td row-title" style={{ flex: 0 }}>Weeks</div> */}
                  <div className="week-row-wrapper" style={{ flex: 1 }}>
                    <div className="weeks-container">
                      {
                        weeks.map((week, idx) => {
                          return viewOnly ?
                            <span className="td num-cell" key={`week_span_${idx}`}>{week}</span> :
                            <Input type="number" className="td cell-input" key={`week_${idx}`} value={week} onChange={(e) => onWeekChange(e, idx)} />
                        })
                      }
                    </div>
                    <div className="visit-row e-row number" style={{ height: "55px", position: "relative" }}>
                        {
                          weeks.length > 0 && visits.length > 0 && visits.map((v, i)=> {
                            const notesValue = getNotesValue(i, v, weeks[i]);
                            const popoverContent = popOverContent(i, v, weeks[i],viewOnly);
                            return popoverContent ? (
                              <div className="td num-cell" key={v}>
                                {!notesValue ? (
                                  <Popover style={{ backgroundColor: '#282f34'}} content={() => popoverContent} placement="bottom" overlayClassName="visit-tooltip">
                                    <FileAddFilled />
                                  </Popover>
                                ) : (
                                  <p>
                                    <Popover style={{ backgroundColor: '#282f34'}} content={() => popoverContent} placement="bottom" overlayClassName="visit-tooltip" trigger="click">
                                      <Text 
                                      style={{width: "90%"}}
                                      ellipsis={{tooltip:notesValue}}
                                      >
                                        {notesValue}
                                      </Text>
                                    </Popover>
                                  </p>
                                )}
                            </div>
                            ):(
                              <div className="td num-cell" key={v}>
                                <Text 
                                  style={{width: "90%"}}
                                  ellipsis={{tooltip:notesValue}}
                                  >
                                    {notesValue}
                                  </Text>
                              </div>
                            )
                          })
                        }
                    </div>
                    <div className="column-action-container">
                      {
                        visits.map((visit, idx) => {
                          return viewOnly ? (
                            <div className="td num-cell" key={idx}>
                              <PlusCircleOutlined />
                            </div>
                          ) : (
                            <div className="td num-cell" key={idx}>
                              <Tooltip
                                title={
                                  <ModalityList
                                    idx={idx}
                                    isColumnBatch={true}
                                    columnModality={columnModality}
                                    insertColumn={insertColumn}
                                    deleteColumn={deleteColumn}
                                  />
                                }
                                color="#ffffff"
                              >
                                <PlusCircleOutlined />
                              </Tooltip>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Collapse className="clearfix" style={{ width: collapseWidth ?? `${collapseWidth}px` }} defaultActiveKey={["1"]} activeKey={expandKeys} onChange={callback}>
          {
            panelMapping.map(panel => {
              return generatePanel(panel);
            })
          }
        </Collapse>
      </div>
    </div>
  );
};

export default withRouter(EventList);
