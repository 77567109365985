import { UPDATE_IE_ACTIVITIES } from '../constants/index';

export const initialIE = { 
    demographicsElements: [],
    interventionElements: [],
    medConditionElements: [],
    labTestElements: [],
    excluDemographicsElements: [],
    excluMedConditionElements: [],
    excluInterventionElements: [],
    excluLabTestElements: [],
}

const ieReducer = (state = initialIE, action) => {
	switch(action.type){
        case UPDATE_IE_ACTIVITIES:
            state = {
                ...state,
                ...action.payload
            }
        break;
		default:			
    }
    return state
}
export default ieReducer;