/* eslint-disable */
import React, { useState, } from "react";
import { withRouter } from 'react-router';
import { Collapse, Modal, Typography} from "antd";
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import "./index.scss";
const { Panel } = Collapse;
const { Text } = Typography;

const panelHeader = (props, record) => {

  return (
    <div className="trial-panelHeader">
      <div>
        {record["trial_alias"]&&record["trial_alias"].length > 30?(
          <Text 
            style={{width: 200, fontSize:14, color:'#333'}}
            ellipsis={{tooltip:record["trial_alias"]}}
            className="trial-panel-trialTitle"
          >
            {record["trial_alias"] || "-"}
          </Text>
        ):(
          <span>{record["trial_alias"] || "-"}</span>
        )}
      <br/>
      <span className="update-time">Last updated {record.sortorderdate?moment.utc(record.sortorderdate).tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'):'-'}</span>
      </div>
      <div>{record["molecule_name"] || "-"}</div>
      <div>
        {!record["study_phase"] && "-"}
        {
            typeof record["study_phase"] =='string' && record["study_phase"]
          }
          {
            typeof record["study_phase"] == 'object' ? (
              <>
                {record["study_phase"] && record["study_phase"].length > 0 && record["study_phase"].map((i, idx) => {
                  return idx < record["study_phase"].length - 1 ? i + ',' : i
                })}
              </>
            ):''
          } 
      </div>

      <div>
       <span className="header-word-wrap" >
        {record["therapeutic_area"] && record["therapeutic_area"].length > 0 && record["therapeutic_area"].map((i, idx) => {
                  return idx < record["therapeutic_area"].length - 1 ? i + ', ' : i
         })}
        </span>
      </div>
      <div>
        <span className="status" >
          <i className={`status-tag ${record["status"].toLowerCase() == "completed" ? 'completed' : 'in-progress'}`}></i> {record["status"] || "-"}
        </span>
      </div>
      <div>
      <span className="view-btn" onClick={(e)=>props.onViewTrial(e,record)}>VIEW TRIAL</span>
      </div>
    </div>
  );
};

const panelContent = (record, onClick) => {
  return (
    <div className="trial-panelBody">
      <div>
        <span className="key">Trial Title</span><br/>
        <span className="value"> 
        {record["trial_title"]&&record["trial_title"].length > 30?(
          <Text 
            style={{width: 180, fontSize:12, color:'#333'}}
            ellipsis={{tooltip:record["trial_title"]}}
            className="trial-body-trialTitle"
          >
            {record["trial_title"] || "-"}
          </Text>
        ):(
          <span>{record["trial_title"] || "-"}</span>
        )}
        </span>
      </div>
      <div>
        <span className="key">Indication</span><br/>
        <span className="value">
          {
            typeof record["indication"] =='string' && record["indication"]
          }
          {
            typeof record["indication"] == 'object' ? (
              <>
                {record["indication"] && record["indication"].length > 0 && record["indication"].map((i, idx) => {
                  return idx < record["indication"].length - 1 ? i + ',' : i
                })}
              </>
            ):''
          }           
      </span>
      </div>
      <div>
        <span className="key">Study Type</span><br/>
        <span className="value">
           {!record["study_type"] && "-"}
           {
            typeof record["study_type"] =='string' && record["study_type"]
          }
          {
            typeof record["study_type"] == 'object' ? (
              <>
                {record["study_type"] && record["study_type"].length > 0 && record["study_type"].map((i, idx) => {
                  return idx < record["study_type"].length - 1 ? i + ',' : i
                })}
              </>
            ):''
          } 
           </span>    
        </div>
      <div>
        <span className="key">Pediatric Study</span><br/>
        <span className="value">
          {record["pediatric_study"] === null?"All":record["pediatric_study"].toString()=="false"?"No":(record["pediatric_study"].toString()=="true"?"Yes": "All")}
        </span>      
      </div>
      <div>
        {/* <span className="key">Study Country</span><br/>
        <span className="value"> {record["study_country"] || "-"}</span> */}
      </div>
      <div>
      </div>
    </div>
  );
};

const TrialList = (props: any) => {
  const { data } = props
  const [phase, setPhase] = useState('All')
  const [area, setArea] = useState('')
  const [visible, setVisible] = useState(false)

  const handlePhaseChange = (value) => {
    setPhase(value)
  };
  const handleCountryChange = (value) => {
    setArea(value)
  }
  const showModal =() =>{
    setVisible(true)
  }

  return (
    <div className="trail-list-container">
      <div className="trails-list">
        <div className="list-columns">
          <div className="col-item">TRIAL ALIAS</div>
          <div className="col-item">MOLECULE NAME</div>
          <div className="col-item">STUDY PHASE</div>
          <div className="col-item">THERAPEUTIC AREA</div>
          {/* <div className="col-item">MODULE STATUS</div> */}
          <div className="col-item">TRIAL STATUS</div>
          <div className="col-item"></div>
        </div>
        <div>
          <Collapse
            expandIconPosition="right"
            expandIcon={({ isActive }) => <DownOutlined style={{ color: '#CA4A04' }} rotate={isActive ? 180 : 0} />}          >
            {data.length > 0 && data.sort((a, b) => new Date(b.sortorderdate).getTime() - new Date(a.sortorderdate).getTime()).map((d, idx) => {
              return (
                <Panel header={panelHeader(props,d)} key={idx}>
                  {panelContent(d, showModal)}
                </Panel>
              );
            })}
            {
              data.length==0&& <div className="no-data">You have not created any trials in this therapeutic area or phase.</div>
            }
          </Collapse>
        </div>
          </div>
        <Modal title="Endpoints(12)" visible={visible} onOk={()=>setVisible(true)} onCancel={()=>setVisible(false)}>
          <p>Some contents...</p>
          <p>Some contents...</p>
          <p>Some contents...</p>
      </Modal>  
    </div>
  );
};

export default withRouter(TrialList);
