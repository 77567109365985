/* eslint-disable */
import React, { useState, useEffect, useReducer, useContext, useRef,} from 'react';
import 'antd/dist/antd.css';
import './index.scss';
import { Table, Input, InputNumber, Form, Typography, Button, Popover, Modal, Collapse, message, Tooltip, Badge, Row, Col, Checkbox } from 'antd';
import {MoreOutlined, CheckOutlined, CloseOutlined, PlusCircleOutlined, CaretRightOutlined, DeleteFilled} from "@ant-design/icons";
import type { ColumnsType, TableProps } from 'antd/es/table';
import { formatValue, formatHistoryValue, formatTime, formatHistoryTime } from '../../utils/auth'

const { TextArea } = Input;
const { Panel } = Collapse;
const { Text } = Typography;

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = inputType === 'number' ? <InputNumber maxLength={61}/> : <Input maxLength={61}/>;
  return (
    <td {...restProps}>
      {editing ? (
        (record['splitPart'] === undefined || (record['splitPart'] === true && title === 'Values'))?(
        <Form.Item name={dataIndex} style={{margin: 0}}
          rules={[
            { required: (title == 'Eligibility Criteria'), message: `Please Input ${title}!` },
            { type: 'string' },
            { max: 60 }
          ]}>
          {inputNode}
        </Form.Item>
        ):(
          children
        )
      ) : (
        children
      )}
    </td>
  );
};

const chars = [
  'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z',
  'AA','AB','AC','AD','AE','AF','AG','AH','AI','AJ','AK','AL','AM','AN','AO','AP','AQ','AR','AS','AT','AU','AV','AW','AX','AY','AZ',
  'BA','BB','BC','BD','BE','BF','BG','BH','BI','BJ','BK','BL','BM','BN','BO','BP','BQ','BR','BS','BT','BU','BV','BW','BX','BY','BZ',
  'CA','CB','CC','CD','CE','CF','CG','CH','CI','CJ','CK','CL','CM','CN','CO','CP','CQ','CR','CS','CT','CU','CV','CW','CX','CY','CZ',
  'DA','DB','DC','DD','DE','DF','DG','DH','DI','DJ','DK','DL','DM','DN','DO','DP','DQ','DR','DS','DT','DU','DV','DW','DX','DY','DZ',
  'EA','EB','EC','ED','EE','EF','EG','EH','EI','EJ','EK','EL','EM','EN','EO','EP','EQ','ER','ES','ET','EU','EV','EW','EX','EY','EZ',
  'FA','FB','FC','FD','FE','FF','FG','FH','FI','FJ','FK','FL','FM','FN','FO','FP','FQ','FR','FS','FT','FU','FV','FW','FX','FY','FZ',
  'GA','GB','GC','GD','GE','GF','GG','GH','GI','GJ','GK','GL','GM','GN','GO','GP','GQ','GR','GS','GT','GU','GV','GW','GX','GY','GZ',
  'HA','HB','HC','HD','HE','HF','HG','HH','HI','HJ','HK','HL','HM','HN','HO','HP','HQ','HR','HS','HT','HU','HV','HW','HX','HY','HZ',
]

const initialEditable = {}

const EditableContext = React.createContext(null);

const EditableDetailRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableDetailCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          // margin: 0,
        }}
        name={dataIndex}
        // rules={[
        //   {
        //     required: true,
        //     message: `${title} is required.`,
        //   },
        // ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} style={{ width: "300px", fontSize: 14}} />
      </Form.Item>
    ) : (
      <div
        className="detail-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
        onFocus={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const EditTable = (props) => {

  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [manuallyAdd, setManuallyAdd] = useState(false);
  const [conOrExcpKey, setConOrExcpKey] = useState();
  const [conOrExcpContent, setConOrExcpContent] = useState();
  const [conOrExp, setConOrExp] = useState('');
  const [total, setTotal] = useState(0)
  const [visible, setVisible] = useState(false);
  const [activeKey, setActiveKey] = useState('')
  const [editable, setEditable] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        { ...initialEditable }
  );
  const [viewOnly, setViewOnly] = useState(false)
  const [itemRecord, setItemRecord] = useState({});
  const [currentHistoryData, setCurrentHistoryData] = useState({});

  const [detailData, setDetailData] = useState([
    {
      key: '1',
      name: 'Values',
      history: formatHistoryValue(currentHistoryData)|| '-',
      modifiers: formatValue(itemRecord)==='-'?'':formatValue(itemRecord),
    },
    {
      key: '2',
      name: 'Timeframe',
      history: formatHistoryTime(currentHistoryData)|| '-',
      modifiers: formatTime(itemRecord)==='-'?'':formatTime(itemRecord),
    },
    {
      key: '3',
      name: 'ROA',
      history: currentHistoryData['historical_roa'] || '-',
      modifiers: itemRecord['user_roa'] || '',
    },
    {
      key: '4',
      name: 'Acuity',
      history:currentHistoryData['historical_acuity'] || '-',
      modifiers: itemRecord['user_acuity'] || '',
    },
    {
      key: '5',
      name: 'Treatment Modifier',
      history: currentHistoryData['historical_treatment'] || '-',
      modifiers: itemRecord['user_treatment'] || '',
    },
  ]);

  useEffect(() => {
    setDetailData([
      {
        key: '1',
        name: 'Values',
        history: formatHistoryValue(currentHistoryData)|| '',
        modifiers: formatValue(itemRecord)==='-'?'':formatValue(itemRecord),
      },
      {
        key: '2',
        name: 'Timeframe',
        history: formatHistoryTime(currentHistoryData)|| '',
        modifiers: formatTime(itemRecord)==='-'?'':formatTime(itemRecord),
      },
      {
        key: '3',
        name: 'ROA',
        history: currentHistoryData['historical_roa'] || '-',
        modifiers: itemRecord['user_roa'] || '',
      },
      {
        key: '4',
        name: 'Acuity',
        history:currentHistoryData['historical_acuity'] || '-',
        modifiers: itemRecord['user_acuity'] || '',
      },
      {
        key: '5',
        name: 'Treatment Modifier',
        history: currentHistoryData['historical_treatment'] || '-',
        modifiers: itemRecord['user_treatment'] || '',
      },
    ])
  },[]);

  useEffect(() => {
    if(props.viewOnly != undefined){
      setViewOnly(props.viewOnly)
    }
  },[props.viewOnly]);

  

  useEffect(() => {
    let newList = []
    props.data.map((item, id) =>{
      if(item['splitPart'] === undefined){
      // this method is called during load of both complete trial and IE builder and edit in IE builder
        if(!('user_value_free_text' in item && item['user_value_free_text']!=null && item['user_value_free_text']!=undefined && item['user_value_free_text'] == "-")){
            item['user_value_free_text'] = formatValue(item)
        }
        newList.push(item)
      }

      if(item['Children']&&item['Children'].length > 0 ){

        item['Children']=item['Children'].filter((iten)=>{
          return iten['Eligibility Criteria']!==''&&iten['user_timeframe']!==''&&iten['user_value_free_text']!==''
        })

        item['Children'].forEach((subRecord,idx) => {
          let iten = item['Key'] + subRecord['Key']
          
         form.setFieldsValue({
          [iten + 'eligibilitycriteria']: subRecord['Eligibility Criteria'],
          [iten + 'timeframe']:subRecord['user_timeframe'],
          [iten + 'values']:subRecord['user_value_free_text']
        });
       })
      }

      if(item['Condition Or Exception'] !== undefined && item['Condition Or Exception'] !== ''){
        newList.push({
          'Key': item.Key+'C',
          'Eligibility Criteria': item['Eligibility Criteria'] + ' [Condition/Exception]',
          'user_value_free_text': item['Condition Or Exception'],
          'splitPart': true,
          'MainKey': item.Key
        })
      }
    })
    if(props.data.length > 0){
      setTotal(Number(props.data[props.data.length-1].Key))
    }

    setData(newList)
  }, [props.data])

  useEffect(() => {
    setActiveKey(props.defaultActiveKey)
  }, [props.defaultActiveKey])


  // const handleSubCriteraInputChange = (key, e, record?, idx?, header?, name?) => {

  //   let tmpData = data.slice(0)
  //   const targetRecord = tmpData.find(e=> e.Key==record.Key)
  //   targetRecord.Children[idx][key]=e.target.value
  //   form.setFieldsValue({
  //     name: e.target.value,
  //   });
  // }

  const isEditing = (record) => record.Key == editingKey;

  const edit = (record) => {
    if(viewOnly) return;
    if (record['Eligibility Criteria'] !== ''|| record['user_value_free_text'] !== '' ||record['user_timeframe'] !== '') {
      // delete the empty criteria in editing when start to edit the other criteria
      let tempData = JSON.parse(JSON.stringify(data))
      tempData = tempData.filter((item)=>{
        return item['Eligibility Criteria'] !== ''|| item['user_value_free_text'] !== '' ||item['user_timeframe'] !== ''
      })
      setData(tempData);
    }
    form.setFieldsValue({
      "Eligibility Criteria": '',
      user_value_free_text: '',
      user_timeframe: '',
      ...record,
      Frequency: (Math.round(record.Frequency * 10000) / 100).toString(),
    });
    setEditingKey(record.Key);
    setManuallyAdd(false)
  };

  const cancel = (record) => {
    setEditingKey('');
    setManuallyAdd(false);
    // For new empty criteria, cancel editing and delete it
    if(record['Eligibility Criteria'] === '' && record['user_value_free_text'] === '' && record['user_timeframe'] === '' ) {
      handleDelete(record)
    }else{
      setTotal(total - 1)
    }
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      // row.Frequency = parseFloat(row.Frequency) * 100  / 10000;
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.Key);
      if (index > -1) {
        var domian;
        var realIndex;
        var newFileds;
        if(newData[index]['splitPart']){
          realIndex = index - 1
          domian = newData[index - 1]
          newFileds = {'Condition Or Exception': row.user_value_free_text}
        } else {
          realIndex = index
          domian = newData[index]
          newFileds = Object.assign(row)
        }
        newData.splice(realIndex, 1, { ...domian, ...newFileds });
        const tempData = newData.map((item, id) => {
          return item
        })
        setData(tempData);
        setEditingKey('');
        setManuallyAdd(false);
        const temp = []
        tempData.map((it, id) =>{
          if(it['splitPart'] === undefined){
            temp.push(it)
          }
        })
        props.updateCriteria(temp, props.tableIndex)
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
        setManuallyAdd(false);
        const temp = []
        newData.map((it, id) =>{
          if(it['splitPart'] === undefined){
            temp.push(it)
          }
        })
        props.updateCriteria(temp, props.tableIndex)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleAdd = async (e,category) => {
    e.stopPropagation();
    // If click Add button before finishing edition, error message will show
    // if(editingKey !== '') {
    //   message.error('Please finish your edition before adding new items')
    //   return
    // }
    try{
      const row = await form.validateFields();
      const newData = {
        Key: (total + 1) + ' ',
        "Eligibility Criteria": '',
        user_value_free_text: '',
        user_timeframe: '',
        Frequency: 0,
        criteria_category:category,
        user_acuity: '',
        negation: false,
        user_roa: '',
        user_treatment: '',
        user_value: '',
      }
      setTotal(total + 1)
      setData([...data, newData])
      edit(newData)
      setManuallyAdd(true)
    } catch (errInfo) {
    console.error(" Error while adding the criteria, Error:", errInfo)
    }
  };

  const handleDelete = async (record) => {
    const newData = [...data];
    const index = newData.indexOf(record);
    setTotal(total - 1)

      if (index > -1) {
        newData.splice(index, 1);
        const tempData = newData.map((item, id) => {
          return item
        })
        setData(tempData);

        const temp = []
        tempData.map((it, id) =>{
          if(it['splitPart'] === undefined){
            temp.push(it)
          }
        })
        props.updateCriteria(temp, props.tableIndex)
      }
  };

  const deleteConditionOrException = async (record) => {
    const newData = [...data];
    const index = newData.findIndex((item) => record.Key === item.Key);
    var realIndex = index - 1
    var domian = newData[realIndex]
    var row = {'Condition Or Exception': ''}

    newData.splice(realIndex, 1, { ...domian, ...row });
    newData.splice(index, 1);
    const tempData = newData.map((item, id) => {
      return item
    })
    setData(tempData);
    setEditingKey('');
    const temp = []
    tempData.map((it, id) =>{
      if(it['splitPart'] === undefined){
        temp.push(it)
      }
    })
    props.updateCriteria(temp, props.tableIndex)
  }

  // const handleAddSubCriteria = async(record) => {
  //   try{
  //     console.log('Add SubCriteria')
  //     const row = await form.validateFields();
  //     const tmpData = data.slice(0)
  //     const targetRecord = tmpData.find(e=> e.Key==record.Key)

  //     if (!targetRecord.Children) {
  //       let newTempData = {
  //         "Key": "A",
  //         "Eligibility Criteria": "",
  //         "Timeframe": "",
  //         "user_value_free_text": "",
  //       }
  //       targetRecord.Children = [newTempData]
  //       setEditable({
  //         [targetRecord.Key + 0]:true
  //       })
  //       edit(newTempData)
  //     } else {
  //         let newTempData = {
  //           "Key": chars[targetRecord.Children.length],
  //           "Eligibility Criteria": "",
  //           "Timeframe": "",
  //           "user_value_free_text": "",
  //         }
  //         targetRecord.Children.push(newTempData)
  //         setEditable({
  //         [targetRecord.Key + targetRecord.Children.length-1]:true
  //         })
  //       // edit(newTempData)
  //     }
  //     setData(tmpData)
  //   } catch (errInfo) {
  //     console.log('Validate Failed:', errInfo);
  //   }
  // }

  // const deleteSubCriteria = (record, idx, name) => {
  //   const targerval = {
  //     [name + 'eligibilitycriteria']: '',
  //     [name + 'timeframe']:'',
  //     [name + 'values']:''
  //   }
  //   form.setFieldsValue(targerval);

  //   const tmpData = data.slice(0)
  //   const targetRecord = tmpData.find(e=> e.Key==record.Key)
  //   targetRecord.Children.splice(idx, 1)
  //   targetRecord.Children = targetRecord.Children.map((item,index) =>{
  //     item.Key = chars[index]
  //     return item
  //   })

  //   setData(tmpData)

  //   const temp = []
  //   tmpData.map((it, id) =>{
  //     if(it['splitPart'] === undefined){
  //       temp.push(it)
  //     }
  //   })
  //   props.updateCriteria(temp, props.tableIndex)
  // }

  // const saveSubCriteria = async(record,subRecord,idx) => {
  //   try{
  //     console.log('save SubCriteria')
  //     const row = await form.validateFields();

  //     setEditable({
  //       [record.Key + idx]:false
  //     })
  //     const tmpData = data.slice(0)
  //     const targetRecord = tmpData.find(e=> e.Key==record.Key)
  //     targetRecord.Children[idx]= subRecord
  //     setData(tmpData)

  //     const temp = []
  //     tmpData.map((it, id) =>{
  //       if(it['splitPart'] === undefined){
  //         temp.push(it)
  //       }
  //     })
  //     props.updateCriteria(temp, props.tableIndex)
  //   } catch (errInfo) {
  //     console.log('Validate Failed:', errInfo);
  //   }
  // }

  function callback(key) {
    setActiveKey(key)
  }

  // the button at the right of panel header
  const genExtra = (category) => (
    !viewOnly && <PlusCircleOutlined className="right-icon" style={{marginTop: '-18px'}} onClick={(e)=>handleAdd(e,category)}/>
   )

  const panelHeaderSection = (header, count) => {
    return (
        <div className={`${props.collapsible  ? '' : 'none-click'} trial-panelHeader`}>
          <div className="header-section">
            <span>{header}({count == 0 ? 0 : count})</span>
          </div>
        </div>
    );
  };

  const columns = [
    {
      title: "Eligibility Criteria",
      dataIndex: "Eligibility Criteria",
      width: '14.5%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return record.splitPart ? (
          <Typography.Link style={{cursor: 'default'}}>
          <div><span style={{fontSize: '14px'}}>{record['Eligibility Criteria']}</span></div>
          </Typography.Link>
        ) : (editable ? (
            <Input disabled={true} value={record["Eligibility Criteria"]}/>
        ) : (
          <>
            <Tooltip title={`${record.negation?'Negated Entity':''}`} placement="topLeft">
            <span style={  { background: record.negation ? '#ca4a04' : '', display: 'inline-block', padding: '7px', borderRadius: '50%'}}></span> 
            </Tooltip>
            <Typography.Link 
            // onClick={() => edit(record)}
            style={{display: 'inline-block',  marginLeft: '10px'}}>
            <Tooltip title={record['Eligibility Criteria']} placement="topLeft">
              <div className="criteria-ellipsis"> <span style={{fontSize: '14px'}}>
            
              {record['Eligibility Criteria']}</span></div>
            </Tooltip>
          </Typography.Link>
          </>
        ))
      }
    },{
      title: "Frequency",
      dataIndex: "Frequency",
      width: '14.5%',
      editable: false,
      render: (_, record) => {
        const editable = isEditing(record);
        return record.splitPart ? (
          <Typography.Link style={{cursor: 'default'}}>
          <div><span style={{fontSize: '14px'}}>{record['Frequency'] + "%"}</span></div>
          </Typography.Link>
        ) : (editable ? (
          <Input disabled={true} value={Math.round(record['Frequency'] * 10000) / 100 + "%"} />
        ) : (
          <Typography.Link 
          // onClick={() => edit(record)}
          >
            <Tooltip title={Math.round(record['Frequency'] * 10000) / 100 + "%"} placement="topLeft">
              <div className="criteria-ellipsis"><span style={{fontSize: '14px'}}>{Math.round(record['Frequency'] * 10000) / 100 + "%"}</span></div>
            </Tooltip>
          </Typography.Link>
        ))
      }
    },{
      title: 'Values',
      dataIndex: 'user_value_free_text',
      width: '12%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Input value={formatValue(record)}/>
        ) : (
          <Typography.Link 
          // onClick={() => edit(record)}
          >
            <Tooltip title={formatValue(record)===''?'-':formatValue(record)} placement="topLeft">
              <div className="criteria-ellipsis"><span style={{fontSize: '14px'}}>{formatValue(record)===''?'-':formatValue(record)}</span></div>
            </Tooltip>
          </Typography.Link>
        )
      }
    }, {
      title: 'Timeframe',
      dataIndex: 'user_timeframe',
      width: '12%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return record.splitPart ? (
          <div><span style={{fontSize: '14px'}}>{formatTime(record)}</span></div>
        ) : ( editable ? (
            <Input value={formatTime(record)}/>
        ) : (
          <Typography.Link 
          // onClick={() => edit(record)}
          >
            <Tooltip title={formatTime(record)} placement="topLeft">
              <div className="criteria-ellipsis"><span style={{fontSize: '14px'}}>{formatTime(record)}</span></div>
            </Tooltip>
          </Typography.Link>
        ))
      }
    },
     {
      title: 'ROA',
      dataIndex: 'user_roa',
      width: '12%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return record.splitPart ? (
          <div><span style={{fontSize: '14px'}}>{record.user_roa ? record.user_roa : '-'}</span></div>
        ) : ( editable ? (
            <Input value={record["user_roa"]}/>
        ) : (
          <Typography.Link 
          // onClick={() => edit(record)}
          >
            <Tooltip title={record.user_roa ? record.user_roa : '-'} placement="topLeft">
              <div className="criteria-ellipsis"><span style={{fontSize: '14px'}}>{record.user_roa ? record.user_roa : '-'}</span></div>
            </Tooltip>
          </Typography.Link>
        ))
      }
    },
    , {
      title: 'ACUITY',
      dataIndex: 'user_acuity',
      width: '12%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return record.splitPart ? (
          <div><span style={{fontSize: '14px'}}>{record.user_acuity ? record.user_acuity : '-'}</span></div>
        ) : ( editable ? (
            <Input value={record["user_acuity"]}/>
        ) : (
          <Typography.Link 
          // onClick={() => edit(record)}
          >
            <Tooltip title={record.user_acuity ? record.user_acuity : '-'} placement="topLeft">
              <div className="criteria-ellipsis"><span style={{fontSize: '14px'}}>{record.user_acuity ? record.user_acuity : '-'}</span></div>
            </Tooltip>
          </Typography.Link>
        ))
      }
    },
    , {
      title: 'TREATMENT MODIFIER',
      dataIndex: 'user_treatment',
      width: '12%',
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        return record.splitPart ? (
          <div><span style={{fontSize: '14px'}}>{record.user_treatment ? record.user_treatment : '-'}</span></div>
        ) : ( editable ? (
            <Input value={record["user_treatment"]}/>
        ) : (
          <Typography.Link 
          // onClick={() => edit(record)}
          >
            <Tooltip title={record.user_treatment ? record.user_treatment : '-'} placement="topLeft">
              <div className="criteria-ellipsis"><span style={{fontSize: '14px'}}>{record.user_treatment ? record.user_treatment : '-'}</span></div>
            </Tooltip>
          </Typography.Link>
        ))
      }
    }, {
      title: 'operation',
      dataIndex: 'operation',
      width: !viewOnly? '9%':'0%',
      className: !viewOnly? 'operation':'not-show',
      // fixed: 'right',
      render: (_, record) => {
        const editable = isEditing(record);
        return !viewOnly && (editable ? (
          <span style={{float:'right'}}>
            <CheckOutlined onClick={() => save(record.Key)}/> &nbsp;&nbsp;
            <CloseOutlined onClick={() => cancel(record)}/>
          </span>
        ) : (record.splitPart ? (
          <CloseOutlined style={{ color: 'red', float:'right'}} onClick={() =>deleteConditionOrException(record)}/>
        ) : (
          <span style={{float:'right',width: 67}}>
            <span style={{color:'#CA4A04',marginRight:8,cursor:'pointer'}} onClick={() => showMoreCriteriaDetails(record)}>Details</span>
            <DeleteFilled style={{color:'#BABABA',verticalAlign:'text-bottom'}} onClick={() => handleDelete(record)}/>
          </span>
        )))
      }
    }
  ];

  const showMoreCriteriaDetails = (record) => {

    const newData = [...data];
    const index = newData.findIndex((item) => record.Key === item.Key);
    setCurrentHistoryData(newData[index])
    setItemRecord(newData[index])
    setDetailData([
      {
        key: '1',
        name: 'Values',
        history: formatHistoryValue(newData[index])|| '',
        modifiers: formatValue(newData[index])==='-'?'':formatValue(newData[index]),
      },
      {
        key: '2',
        name: 'Timeframe',
        history: formatHistoryTime(newData[index])|| '',
        modifiers: formatTime(newData[index])==='-'? '':formatTime(newData[index]),
      },
      {
        key: '3',
        name: 'ROA',
        history: newData[index]['historical_roa'] || '-',
        modifiers: newData[index]['user_roa'] || '',
      },
      {
        key: '4',
        name: 'Acuity',
        history:newData[index]['historical_acuity'] || '-',
        modifiers: newData[index]['user_acuity'] || '',
      },
      {
        key: '5',
        name: 'Treatment Modifier',
        history: newData[index]['historical_treatment'] || '-',
        modifiers: newData[index]['user_treatment'] || '',
      },
    ])
    
    setVisible(true)
  }

  const mergedColumns = columns.map((col) => {
    if(col.title === "Eligibility Criteria" && manuallyAdd) {
      col.editable = true
    } else if (col.title === "Eligibility Criteria"&& !manuallyAdd){
      col.editable = false
    }
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      })
    };
  });

  const handleInputChange = (e) =>{
    setConOrExp(e.target.value)
  }

  const handleOk = async () => {
    setVisible(false)

    const newData = [...data]
    const index = newData.findIndex((item) => conOrExcpKey === item.Key)

    if(index > -1){
      const oldItem = newData[index];
      const newItem = newData[index];
      newItem['Condition Or Exception'] = conOrExp

      newData.splice(index, 1, { ...oldItem, ...newItem });
      setData(newData)
      setConOrExp(null)
    }

    const temp = []
    newData.map((it, id) =>{
      if(it['splitPart'] === undefined){
        temp.push(it)
      }
    })
    props.updateCriteria(temp, props.tableIndex)
  }

  const handleCancel = () =>{
    
    setVisible(false)
    setItemRecord({})
  }

  const onCopyToMyModifier = () =>{
    
    setDetailData([
      {
        key: '1',
        name: 'Values',
        history: formatHistoryValue(currentHistoryData)|| '',
        modifiers: formatHistoryValue(currentHistoryData)==='-'?'':formatHistoryValue(currentHistoryData),
      },
      {
        key: '2',
        name: 'Timeframe',
        history: formatHistoryTime(currentHistoryData)|| '',
        modifiers: formatHistoryTime(currentHistoryData)==='-'?'':formatHistoryTime(currentHistoryData),
      },
      {
        key: '3',
        name: 'ROA',
        history: currentHistoryData['historical_roa'] || '-',
        modifiers: currentHistoryData['historical_roa'] || '',
      },
      {
        key: '4',
        name: 'Acuity',
        history:currentHistoryData['historical_acuity'] || '-',
        modifiers: currentHistoryData['historical_acuity'] || '',
      },
      {
        key: '5',
        name: 'Treatment Modifier',
        history: currentHistoryData['historical_treatment'] || '-',
        modifiers: currentHistoryData['historical_treatment'] || '',
      },
    ])

    setItemRecord(currentHistoryData)

  }

  const handleOkActivity = (e, itemRecord) => {

    const newItem = {
      ...itemRecord,
      user_lower: 0,
      user_units: "",
      user_upper: 0,
      "user_value": "",
    }

    detailData.forEach(item => {
      if (item.name === "Timeframe") newItem.user_timeframe = item["modifiers"].trim()
      if (item.name === "Values") newItem.user_value_free_text = item["modifiers"].trim()
      if (item.name === "Acuity") newItem.user_acuity = item["modifiers"].trim()
      if (item.name === "ROA") newItem.user_roa = item["modifiers"].trim()
      if (item.name === "Treatment Modifier") newItem.user_treatment = item["modifiers"].trim()
    });

    setItemRecord(newItem)
    
    setVisible(false)
    // save edit to table 
    detailSave(newItem)
  }

  const detailSave = async (newItem) => {
    try {
      const key = newItem.Key
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.Key);
      if (index > -1) {
        var domian;
        var realIndex;
        var newFileds;
        if(newData[index]['splitPart']){
          realIndex = index - 1
          domian = newData[index - 1]
          newFileds = {'Condition Or Exception': newItem.user_value_free_text}
        } else {
          realIndex = index
          domian = newData[index]
          newFileds = Object.assign(newItem)
        }
        newData.splice(realIndex, 1, { ...domian, ...newFileds });
        const tempData = newData.map((item, id) => {
          return item
        })
        setData(tempData);
        setEditingKey('');
        setManuallyAdd(false);
        const temp = []
        tempData.map((it, id) =>{
          if(it['splitPart'] === undefined){
            temp.push(it)
          }
        })
        props.updateCriteria(temp, props.tableIndex)
      } else {
        newData.push(newItem);
        setData(newData);
        setEditingKey('');
        setManuallyAdd(false);
        const temp = []
        newData.map((it, id) =>{
          if(it['splitPart'] === undefined){
            temp.push(it)
          }
        })
        
        props.updateCriteria(temp, props.tableIndex)
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  interface DataType {
    key: React.Key;
    title: string;
    amendment: string;
    before_text: string;
    after_text: string;
  }

  const criteriaDetailData = itemRecord["Detail"]&&itemRecord["Detail"]['amendment_details']&&itemRecord["Detail"]['amendment_details'].map((item, index) => {
    const tempItem = JSON.parse(JSON.stringify(item))
    return Object.assign(tempItem, {key:(index + 1) + ''})
  })


  const detailColumns =  [{
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 200,
  },
  {
    title: 'History',
    dataIndex: 'history',
    key: 'history',
    width: 320,
  },
  {
    title: 'Modifiers',
    dataIndex: 'modifiers',
    key: 'modifiers',
    width: 320,
    editable: true,
    render: (_, record) => {
      return <Input style={{ width: "300px", fontSize: 14}} value={record['modifiers']}/>
    }
  },]

  const handleSave = (row) => {
    const newData = [...detailData];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    
    setDetailData(newData);
  };

  const newDetailColumns = detailColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Collapse 
      className="edit-table-wrapper"
      activeKey={activeKey} 
      onChange={callback} 
      expandIconPosition="left"
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      >
      <Panel 
      header={panelHeaderSection(props.panelHeader, props.collapsible ? 0 : data.length)}
      showArrow={!viewOnly}
      key={props.tableIndex} 
      forceRender={false} 
      extra={genExtra(props.panelHeader)}>
      <Table pagination={false} 
        // scroll={{ x: 1500 }}
        showHeader={false} 
        rowKey={record => record.Key}
        components={{ body: { cell: EditableCell } }} 
        locale={{emptyText: 'No Data'}}
        dataSource={data} columns={mergedColumns} rowClassName="editable-row"
        expandedRowKeys={data.map(item => item.Key)}
      />
      </Panel>
      </Collapse>
     
      <Modal 
        visible={visible} 
        title="" 
        closable={false} 
        mask={false}
        onCancel={handleCancel} 
        footer={null} 
        style={{ top:10 }} 
        centered={false} 
        maskClosable={false}
        wrapClassName="criteriaDetailModal"
      >
        <Row className="ie-configure-title">
        <span>Additional Details</span>
        </Row>
        {/* <br/> */}
        <Row className="endpoint-modal-filed">
          <Col span={24} className="informed-consent-label">
            <Text
              ellipsis={true}
              >
                {itemRecord['Eligibility Criteria']}
            </Text>
          </Col>
          <Col span={24} className="procedure-label">
          <Text
              ellipsis={true}
              >
                {itemRecord['criteria_category']}
            </Text>
          </Col>
          <Col span={24}><span><br /></span></Col>
          
          <Col span={8} className="label-header"><span>ENTITY FREQUENCY</span></Col>
          <Col span={8} className="label-header"><span>NUMBER OF AMENDMENTS</span></Col>
          <Col span={8} className="label-header"><span>SCREEN FAILURE RATE</span></Col>  
          <Col span={8} className="label-values"> 
          { Math.round(itemRecord['Frequency'] * 10000) / 100 + "%"}
          </Col>
          <Col span={8} className="label-values">
            <span>
            {itemRecord['Detail']&&itemRecord['Detail']['number_of_amendments']||"NA"}
            </span>
          </Col>
          <Col span={8} className="label-values">
            <span>
            {itemRecord['Detail']&&itemRecord['Detail']['sfr']?itemRecord['Detail']['sfr']:'0%'}
            </span>
          </Col> 

          <Col span={24} ><span><br /></span></Col>
          
        </Row>
        {/* <br /> */}
        <Row className="ie-criteria-selector">
          <Col span={24}>
          <div className="header">
            <div className="criteria"></div>
            <div className="history">
              <div className="top">Historical value</div>
              <div className="bottom">
              <Button 
              style={{marginTop: 5}}
              size="small"
              onClick={onCopyToMyModifier}
              >
                Copy historical data to My Modifiers
              </Button>
              </div>
            </div>
            <div className="modifier">
              My Modifiers
            </div>
          </div>
          <Table 
          columns={newDetailColumns} 
          dataSource={detailData} 
          pagination={false}
          showHeader={false}
          components={{
              body: {
                row: EditableDetailRow,
                cell: EditableDetailCell,
              },
            }}
          />
          </Col>
        </Row>
        
        <Row style={{justifyContent: 'right', paddingTop: '5px'}}>
          <Button className="cancel-btn" 
          onClick={handleCancel}
          >
            CANCEL
          </Button>
          <Button className="create-btn" 
          onClick={(e) => handleOkActivity(e, itemRecord)}
          >
            SUBMIT
          </Button>
        </Row>
      </Modal>
    </Form>
  );
};

export default EditTable;